import Constants from "@/resources/Constants";

export default {
  methods: {
    setPageTitle(title) {
      if (title) {
        document.title = `${title} | ${Constants.defaultPageTitle}`;
      }
    },
  },
};
