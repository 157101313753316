var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mso-chart-of-account-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
        { name: 'home', label: 'Home' },
        { name: 'msoChartOfAccounts', label: 'MSO Chart of Accounts' },
        {
          name: 'msoChartOfAccountEditor',
          label: _vm.selectedMsoChartOfAccount.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedMsoChartOfAccount,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},_vm._l((_vm.msoChartOfAccountsInfoInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedMsoChartOfAccount[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoChartOfAccount[input.id] = $event}}})],1)}),0)]),(_vm.selectedMsoChartOfAccount._id !== 'NEW')?_c('EditorSection',{attrs:{"title":"Associated Chart of Accounts","bus":_vm.bus}},[_c('SearchTable',{attrs:{"entity":_vm.Entities.CHARTOFACCOUNT,"hideFilters":true,"preFilter":_vm.preFilter}})],1):_vm._e(),(_vm.selectedMsoChartOfAccount._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedMsoChartOfAccount.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedMsoChartOfAccount}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }