import _ from "lodash";
import store from "../../store/index";
import Constants from "@/resources/Constants";

export const normalizeEntityBeforeSaving = function (
  entityToNormalize,
  stores
) {
  const entity = JSON.parse(JSON.stringify(entityToNormalize));

  if (entity.employmentDate === "") {
    delete entity.employmentDate;
  }
  if (entity.safeAccessStartDate === "") {
    delete entity.safeAccessStartDate;
  }
  if (entity.safeAccessEndDate === "") {
    delete entity.safeAccessEndDate;
  }
  if (entity.verificationExpirationDate === "") {
    delete entity.verificationExpirationDate;
  }
  if (entity.verificationDate === "") {
    delete entity.verificationDate;
  }
  if (entity.password === "*********") {
    delete entity.password;
  }
  if (entity.traceabilitySystem && entity.traceabilitySystem.code) {
    entity.traceabilitySystem = entity.traceabilitySystem.code;
  }

  entity.role = entity.role ?? "";

  if (entity.store === undefined) {
    entity.store = _.find(stores, (s) => s.isAccessGranted);
  }
  if (entity.stores && entity.stores.length > 0) {
    entity.stores = entity.stores.map((s) => {
      return { store: s.store._id, role: s.role };
    });
  }
  delete entity.verifiedBy;
  delete entity.organization;
  delete entity.createdBy;
  delete entity.updatedBy;
  delete entity.terminatedBy;

  if (entity.noteList.length > 0) {
    entity.noteList.map(
      (n) =>
        (n.noteType =
          n.noteType && n.noteType.code ? n.noteType.code : undefined)
    );
  }
  return entity;
};

export const populateStores = function (selectedEmployee, stores) {
  const storesLocal = JSON.parse(JSON.stringify(stores));
  const storesLocalArray = [];
  storesLocal.forEach((sl) => {
    const storeLocal = {
      store: sl,
      isAccessGranted: false,
      roles: [],
      role: undefined,
    };

    const employeeStore = _.find(
      selectedEmployee.stores,
      (es) => es.store._id === storeLocal.store._id
    );

    if (employeeStore) {
      // storeLocal.isAccessGranted = true;
      // storeLocal.roles = employeeStore.roles;
      // const organizationRole = _.filter(
      //   Constants.listOfStoreRoles,
      //   (sr) => sr.code === employeeStore.roles[0]
      // );
      // if (organizationRole.length > 0) {
      //   storeLocal.role = organizationRole[0];
      // } else {
      //   storeLocal.role = Constants.listOfStoreRoles[0];
      // }
    }

    storesLocalArray.push(storeLocal);
  });
  return storesLocalArray;
};
