<template>
  <div
    class="check-button"
    :style="isRadio ? 'cursor: auto;' : ''"
    @click="$emit('click')"
    :class="{ 'check-button--disabled': disabled }"
  >
    <span class="check-button__unchecked-icon" v-show="!toggleVariable">
      <i v-show="!isRadio" class="fa fa-square" aria-hidden="true"></i>
      <i v-show="isRadio" class="fas fa-circle"></i>
    </span>

    <span class="check-button__checked-icon" v-show="toggleVariable">
      <i v-show="!isRadio" class="fas fa-check-square" aria-hidden="true"></i>
      <i v-show="isRadio" class="fas fa-check-circle" aria-hidden="true"></i>
    </span>
    <div class="text-spacer-0"></div>
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: ["toggleVariable", "label", "isRadio", "disabled"],
  data() {
    return {};
  },
};
</script>
