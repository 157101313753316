import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
const defaultOrganization = {
  _id: "NEW",
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  phone: "",
  site: "",
  email: "",
  enforceMso: false,
};

const state = {
  organization: { ...defaultOrganization },
  loadingStatus: {
    saveOrganization: false,
  },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setOrganization: (state, payload) => {
    if (payload._id === "NEW") state.organization = { ...defaultOrganization };
    else state.organization = { ...defaultOrganization, ...payload };
  },
};

const getters = {
  organization: (state) => state.organization,
  loadingStatus: (state) => state.loadingStatus,
};

const actions = {
  saveOrganization: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "saveOrganization", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveOrganization,
      payload.organization,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveOrganization", status: false });
        var organization = {};

        if (response) {
          organization = response.data.body;
          commit("setOrganization", organization);

          if (payload.positiveCallback) {
            payload.positiveCallback(organization);
          }
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getOrganizationById: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getOrganizationById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getOrganizaitonById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getOrganizationById",
          status: false,
        });
        var organization = {};

        if (response) {
          organization = response.data.body;
          commit("setOrganization", organization);
          if (payload.positiveCallback) {
            payload.positiveCallback(organization);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
