<template>
  <div id="label-printer-selection-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Label Printer Selection</span>
      <div class="padded left">
        <div v-if="labelPrintersList.length > 0" class="printers">
          <BaseBtn
            v-for="lp in labelPrintersList"
            :key="lp._id"
            :label="lp.name"
            :colorType="lp.isSelected ? 5 : undefined"
            @click="() => selectLabelPrinter(lp)"
          />
        </div>
        <div v-else>No label printers for this store</div>
      </div>

      <div class="button-wrapper padded space-between">
        <div class="button-info" @click="handleNegativeAnswer">Cancel</div>
        <div
          class="button-secondary"
          :disabled="selectedPrinter ? false : true"
          @click="performSelect"
        >
          Select
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "LabelPrinterSelectionPopUp",
  data() {
    return {
      labelPrintersList: [],
      isBlurred: false,
      selectedPrinter: undefined,
    };
  },
  components: {
    BaseBtn,
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  mounted: function () {
    if (
      this.currentStore.labelPrinters &&
      this.currentStore.labelPrinters.length > 0
    ) {
      this.labelPrintersList = JSON.parse(
        JSON.stringify(this.currentStore.labelPrinters)
      );

      if (this.selectedLabelPrinter) {
        const lp = this.labelPrintersList.find(
          (lp) => lp._id == this.selectedLabelPrinter._id
        );
        lp.isSelected = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
      labelPrinterSelectionPopUp: "labelPrinterSelectionPopUp",
      selectedLabelPrinter: "selectedLabelPrinter",
    }),
  },
  methods: {
    ...mapActions({
      setLabelPrinterSelectionPopUp: "setLabelPrinterSelectionPopUp",
      setSelectedLabelPrinter: "setSelectedLabelPrinter",
    }),
    handleNegativeAnswer: function () {
      this.setLabelPrinterSelectionPopUp({ isOpen: false });
    },
    performSelect() {
      this.labelPrinterSelectionPopUp.positiveCallback({
        selectedLabelPinter: this.selectedPrinter || this.selectedLabelPrinter,
      });
      this.setLabelPrinterSelectionPopUp({ isOpen: false });
    },
    selectLabelPrinter(lp) {
      this.labelPrintersList.forEach((lp) => {
        this.$set(lp, "isSelected", false);
      });
      this.$set(lp, "isSelected", true);
      this.selectedPrinter = lp;

      this.setSelectedLabelPrinter(lp);
    },
  },
};
</script>

<style lang="scss" scoped>
.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.printers {
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;
    .button-secondary {
      margin-bottom: 10px;
    }
  }
}
</style>
