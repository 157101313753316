<template>
  <div class="compare-to">
    <p class="title">Please select the location where you are performing the inventory count:</p>
    <div class="compare-to-body">
      <div v-if="isLoading">
        <i class="fas fa-spin fa-circle-notch"></i> Loading...
      </div>
      <div v-if="!isLoading" class="input-method">
        <div class="input-method-option" @click="() => productType = PRODUCT_TYPE.CANNABIS"
          :class="{ 'input-method-option--selected': productType == PRODUCT_TYPE.CANNABIS }">Cannabis</div>
        <div class="input-method-option" @click="() => productType = PRODUCT_TYPE.NON_CANNABIS"
          :class="{ 'input-method-option--selected': productType == PRODUCT_TYPE.NON_CANNABIS }">Non Cannabis</div>
        <div class="input-method-option" @click="() => productType = PRODUCT_TYPE.ALL"
          :class="{ 'input-method-option--selected': productType == PRODUCT_TYPE.ALL }">All</div>
      </div>
      <Dropdown v-if="!isLoading" class="compare-to-body__selection" v-model="compareTo" :options="options"
        optionLabel="name" optionValue="id" aria-label="Select" placeholder="Select one option"
        @change="compareToHandler" />
    </div>
    <div class="compare-to-footer">
      <BaseBtn label="Select" @click="selectHandler" :disabled="isSelectDisabled" />
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import { mapGetters, mapActions } from 'vuex';
import BaseBtn from "@/components/Base/Button/Button.vue";

const PRODUCT_TYPE = {
  CANNABIS: 'cannabis',
  NON_CANNABIS: 'non-cannabis',
  ALL: 'all',
}

export default {
  components: {
    Dropdown,
    BaseBtn
  },
  data() {
    return {
      compareTo: null,
      compareToSelected: {},
      options: [],
      productType: PRODUCT_TYPE.CANNABIS,
      PRODUCT_TYPE: PRODUCT_TYPE
    }
  },
  computed: {
    ...mapGetters({
      getInventoryCount: 'inventoryCountModule/getInventoryCount',
      loadingStatus: 'inventoryCountModule/loadingStatus'
    }),
    isLoading() {
      return this.loadingStatus.getCompareToOptions
    },
    isSelectDisabled() {
      return this.compareTo == null
    },
  },
  methods: {
    ...mapActions({
      setCompareTo: 'inventoryCountModule/setCompareTo',
      getCompareToOptions: 'inventoryCountModule/getCompareToOptions'
    }),
    compareToHandler({ event, value }) {
      const option = this.options.find((o) => o.id == value);
      this.compareToSelected = {
        id: option.id,
        name: option.name,
        productType: this.productType
      }
    },
    async selectHandler() {
      this.compareToSelected.productType = this.productType
      await this.setCompareTo(this.compareToSelected)
      this.$router.replace({
        path: this.$route.path,
        query: { id: this.getInventoryCount._id }
      })
    },
    preselectCompareOption() {
      // setting working inventory as default selection at load
      const workingInventoryOption = this.options.find(o => o.id == 'working-inventory')
      if (workingInventoryOption) {
        this.compareToSelected = {
          id: workingInventoryOption.id,
          name: workingInventoryOption.name,
          productType: this.productType
        }
        this.compareTo = workingInventoryOption.id
      }
    }
  },
  mounted() {
    this.getCompareToOptions()
      .then((val) => {
        this.options = val;
        this.preselectCompareOption()
      })
  }
}
</script>

<style lang="scss" scoped>
@import './compareTo';
</style>
