<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Cannabis Expiring"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'inventoryReport', label: 'Inventory' },
            {
              name: 'inventoryExpiring',
              label: 'Cannabis Expiring',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByTime="true"
          @submit-filter="performGetReport"
          :isLoading="inventoryTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr>
                  <th align="left">Supplier</th>
                  <th align="left">Group</th>
                  <th align="left">Grams</th>
                  <th align="left">Product</th>
                  <th align="left">Batch #</th>
                  <th align="left">Received</th>
                  <th align="left">Expires</th>
                  <th align="right">Quantity</th>
                  <th align="right">Dollar Value</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                  <td>{{ record.supplier }}</td>
                  <td>{{ record.group }}</td>
                  <td>{{ record.grams }}</td>
                  <td>{{ record.name }}</td>
                  <td class="batch">{{ record.batchId }}</td>
                  <td>{{ record.date }}</td>
                  <td>{{ record.dateExpiration }}</td>
                  <td align="right"> {{ record.quantityOnHand }}</td>
                  <td align="right"> {{ Utilities.formatPrice(record.dollarValue) }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="7">Totals</td>
                <td align="right">
                  {{ reportData.total.quantityOnHand }}
                </td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.dollarValue) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%"><hr class="line1" /></td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Cannabis Expiring");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        inventoryTransactions: "reports/inventoryTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getInventoryTransactions",
      }),
      performGetReport(filter) {
        let reportType = "inventoryExpiring";
        this.getSalesTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryExpiring");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_inventoryExpiring.scss";