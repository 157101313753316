<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Sales Orders for Product Id"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'salesReport', label: 'Sales' },
            {
              name: 'salesOrderStatusProductId',
              label: 'Sales Orders for Product Id',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByText="true"
          :filterByTime="true"
          @submit-filter="performGetReport"
          :isLoading="salesTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr><td colspan="3">Sales Orders for Product Id </td></tr>
                <tr><td> </td></tr><tr><td> </td></tr>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Employee</th>
                  <th align="left">Order Code</th>
                  <th align="left">Customer</th>
                  <th align="left">Product Id</th>
                  <th align="left">Name</th>
                  <th align="left">Batch #</th>
                  <th align="right">Quantity</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                  <td>{{ record.date }}</td>
                  <td>{{ record.employee }}</td>
                  <td>{{ record.orderCode }}</td>
                  <td>{{ record.customer }}</td>
                  <td>{{ record.productId }}</td>
                  <td>{{ record.name }}</td>
                  <td>{{ record.batchId }}</td>
                  <td align="right">
                    {{ record.quantity }}
                  </td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="7">Total</td>
                <td align="right">
                  {{ reportData.total.quantity }}
                </td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";
  import ReportHelper from "@/lib/report/index.js";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
        filterText: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Sales Orders for Product Id");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        salesTransactions: "reports/salesTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getSalesTransactions",
      }),
      performGetReport(filter) {
        if (filter.filterText.trim() != "")
        {
          let reportType = "salesOrderStatusProductId";
          this.getSalesTransactions({
            reportType,
            ...filter,
            positiveCallback: (data) => {
              this.reportData = data;
            },
          });
        }
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "SalesOrderStatusProductId");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_salesOrderStatusProductId.scss";
  </style>