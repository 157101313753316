var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-component-wrapper"},[_c('div',{staticClass:"bordered"},[_c('div',{class:'table-container' + ' table-full-screen'},[_c('div',{staticClass:"table__pagination--wrapper",attrs:{"id":"table-pagination"}},[(_vm.selectable)?_c('div',{staticClass:"table__pagination--wrapper",staticStyle:{"flex-grow":"10"}},[_c('div',{staticStyle:{"display":"flex","gap":"1rem"}},[_c('BaseBtn',{on:{"click":function($event){return _vm.showSelectedItems()}}},[_vm._v(" Show Selected ")]),_c('BaseBtn',{on:{"click":function($event){return _vm.clearSelectedItems()}}},[_vm._v(" Clear Selected ")])],1)]):_vm._e(),(!_vm.selectable)?_c('div',{staticStyle:{"flex-grow":"10"}}):_vm._e(),(_vm.enableCardniality)?_c('div',{staticClass:"table__pagination__cardinality"},[_vm._v(" "+_vm._s(_vm.internalHasForcedItems ? _vm.internalData.length : _vm.cardinality)+" ITEMS ")]):_c('div'),(_vm.pageNumber && !_vm.internalHasForcedItems)?_c('div',{staticClass:"table__pagination flex"},[(_vm.firstArrowEnabled)?_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isFirstDisabled || _vm.searchTableLoadingStatus.isFilterLoading
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-double-left']},on:{"click":function($event){return _vm.emitPageChange('first', _vm.isFirstDisabled)}}}):_vm._e(),_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isPrevDisabled || _vm.searchTableLoadingStatus.isFilterLoading
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-left']},on:{"click":function($event){return _vm.emitPageChange('prev', _vm.isPrevDisabled)}}}),_c('span',{staticClass:"table__pagination__result-total"},[_vm._v(_vm._s(_vm.currentPageNumber)+" of "+_vm._s(_vm.pageNumber))]),_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isNextDisabled || _vm.searchTableLoadingStatus.isFilterLoading
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-right']},on:{"click":function($event){return _vm.emitPageChange('next', _vm.isNextDisabled)}}}),(_vm.lastArrowsEnalbled)?_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isLastDisabled || _vm.searchTableLoadingStatus.isFilterLoading
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-double-right']},on:{"click":function($event){return _vm.emitPageChange('last', _vm.isLastDisabled)}}}):_vm._e()],1):_vm._e(),(!_vm.enableCardniality && !_vm.pageNumber && !_vm.internalHasForcedItems)?_c('div',{staticClass:"table__pagination flex"},[_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isPrevDisabled || _vm.searchTableLoadingStatus.isFilterLoading
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-left']},on:{"click":function($event){return _vm.emitPageChange('prev', _vm.isPrevDisabled)}}}),_c('span',{staticClass:"table__pagination__result-total"},[_vm._v("Page "+_vm._s(_vm.currentPageNumber))]),_c('font-awesome-icon',{class:'table__pagination__icon ' +
      (_vm.isNextDisabled || _vm.searchTableLoadingStatus.isFilterLoading || _vm.rowCount < 20
        ? 'table__pagination__disabled'
        : 'table__pagination__active'),attrs:{"icon":['fas', 'angle-right']},on:{"click":function($event){return _vm.emitPageChange('next', _vm.isNextDisabled || _vm.rowCount < 20)}}})],1):_vm._e(),_c('div',{staticClass:"table__pagination flex config-dropdown"},[_c('MultiSelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOptions),expression:"showOptions"}],staticStyle:{"max-width":"10rem"},attrs:{"options":_vm.listedColumnHeaders,"optionLabel":"title","display":"chips","placeholder":"Toggle Columns"},model:{value:(_vm.optionColumnHeaders),callback:function ($$v) {_vm.optionColumnHeaders=$$v},expression:"optionColumnHeaders"}}),_c('font-awesome-icon',{staticClass:"config-icon",attrs:{"icon":['fas', 'cog']},on:{"click":function () { return _vm.showOptions = !_vm.showOptions; }}})],1)]),_c('div',{staticClass:"table-wrapper",style:(_vm.tableHeight)},[(_vm.searchTableLoadingStatus.isFilterLoading)?_c('div',{staticClass:"table__loading-overlay"}):_vm._e(),_c('table',{staticClass:"table-element"},[_c('thead',[_c('tr',{staticClass:"header",class:{
      'header--disabled': _vm.searchTableLoadingStatus.isFilterLoading,
    }},_vm._l((_vm.displayColumnHeaders),function(header,index){return _c('th',{key:("table-column-header-" + (header.id) + "-" + (header.title)),staticClass:"header-cell header-cell--button",class:_vm.getStickyClass(index, header, true),style:([_vm.getStickyStyle(index, header), { width: _vm.columnWidths[index] + 'px' }]),on:{"click":function () { return _vm.handleSortChange(header); }}},[(header.type !== 'button')?_c('div',{class:("table__content table__content--size-" + (header.size))},[_vm._v(" "+_vm._s(header.title)+" "+_vm._s(_vm.generateIcon(header))+" ")]):_c('div',{staticClass:"table__header-button"}),_c('div',{staticClass:"resizer",staticStyle:{"flex-grow":"10"},on:{"mousedown":function($event){return _vm.initResize($event, index)},"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"resizer-icon"},[_vm._v("|")])])])}),0)]),_c('tbody',_vm._l((_vm.internalData),function(item){return _c('tr',{key:item._id,staticClass:"row",class:{
      'table--loading': _vm.searchTableLoadingStatus.isFilterLoading,
    }},_vm._l((_vm.displayColumnHeaders),function(header,index){return _c('td',{key:header.id + '_' + header.title,staticClass:"cell",class:_vm.getStickyClass(index, header),style:([_vm.getStickyStyle(index, header), { width: _vm.columnWidths[index] + 'px' }])},[(header.type === 'button' && header.action === 'navigateToEntity')?_c('router-link',{attrs:{"to":_vm.getRouteToEntity(_vm.entity, item)}},[_c('BaseBtn',{staticClass:"table-row-button",class:{ 'table-row-button--big': _vm.bigIcons },style:({ width: _vm.columnWidths[index] + 'px' }),attrs:{"label":header.title,"type":"plain-primary responsive-column form-height small","data-cy":"search-select-button","iconGroup":"fas","iconName":header.iconName,"ariaLabel":header.ariaLabel,"disabled":_vm.isButtonDisabled(header, item)}})],1):(header.type === 'button')?_c('BaseBtn',{staticClass:"table-row-button",class:{ 'table-row-button--big': _vm.bigIcons },style:({ width: _vm.columnWidths[index] + 'px' }),attrs:{"label":header.title,"type":"plain-primary responsive-column form-height small","data-cy":"search-select-button","iconGroup":"fas","iconName":header.iconName,"ariaLabel":header.ariaLabel,"disabled":_vm.isButtonDisabled(header, item)},on:{"click":function () { return _vm.handleButtonOnClick(item, header.action, header); }}}):(header.link)?_c('div',{class:("table__content table__content--size-" + (header.size)),style:({ width: _vm.columnWidths[index] + 'px' })},[(_vm.getItemValue(item, header) !== '-')?_c('a',{staticClass:"internal-link",attrs:{"target":"_blank","href":header.url + _vm.getNestedValue(item, header.urlId)}},[_vm._v(" "+_vm._s(_vm.getItemValue(item, header))+" ")]):_c('div',[_vm._v("-")])]):(header.type === 'checkbox' && header.action === 'selectItem')?_c('div',{class:("table__content table__content--size-" + (header.size)),staticStyle:{"width":"1.5rem"},style:({ width: _vm.columnWidths[index] + 'px' })},[_c('CheckBox',{attrs:{"value":item.isSelected},on:{"click":function () {
        item.isSelected = !item.isSelected;
        if (_vm.internalSelectedItems.find(function (i) { return i._id === item._id; })) {
          _vm.internalSelectedItems = _vm.internalSelectedItems.filter(function (i) { return i._id !== item._id; });
        } else {
          _vm.internalSelectedItems.push({ _id: item._id, item: item });
        }
        _vm.$emit('itemsSelected', _vm.internalSelectedItems.map(function (i) { return i.item; }));
      }}})],1):(header.type === 'checkbox')?_c('div',{class:("table__content table__content--size-" + (header.size)),style:({ width: _vm.columnWidths[index] + 'px' })},[_c('check-mark',{staticClass:"table-row-button",attrs:{"toggleVariable":_vm.getItemValue(item, header) == 'true'}})],1):(header.type === 'label')?_c('div',{class:("table__content table__content--size-" + (header.size)),style:({ width: _vm.columnWidths[index] + 'px' })},[_c('div',{staticClass:"table__pagination__label"},[_c('div',{staticClass:"table__pagination__label-content"},[(header.iconGroup && header.iconName)?_c('font-awesome-icon',{attrs:{"icon":[header.iconGroup, header.iconName]}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getItemValue(item, header))+" ")],1)])]):_c('div',{class:("table__content table__content--size-" + (header.size)),style:({ width: _vm.columnWidths[index] + 'px' })},[_c('span',[_vm._v(_vm._s(_vm.getItemValue(item, header) || "-"))])])],1)}),0)}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }