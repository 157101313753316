var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Scheduled Disposals","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'inventoryReport', label: 'Inventory' },
      {
        name: 'inventoryDisposalScheduled',
        label: 'Scheduled Disposals',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',[_vm._v(_vm._s(record.dateScheduled))]),_c('td',{staticClass:"batch"},[_vm._v(_vm._s(record.batchId))]),_c('td',[_vm._v(_vm._s(record.quantity))]),_c('td',[_vm._v(_vm._s(record.reason))]),_c('td',[_vm._v(_vm._s(record.type))]),_c('td',[_vm._v(_vm._s(record.method))]),_c('td',[_vm._v(_vm._s(record.otherReason))]),_c('td',[_vm._v(_vm._s(record.detailedDescription))])])})],2),_vm._m(1)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date Scheduled")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Disposal Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Batch #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Quantity")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Reason")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Type")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Method")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Short Description")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Detailed Description")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }