var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mso-coupon-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
        { name: 'home', label: 'Home' },
        { name: 'msoCoupons', label: 'MsoCoupon' },
        {
          name: 'msoCouponEditor',
          label: _vm.selectedMsoCoupon.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedMsoCoupon,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":_vm.toggleSearchModal}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered",class:{
          'bordered__highlighted--danger': _vm.selectedMsoCoupon.isArchived,
        },staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.selectedMsoCoupon.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"ARCHIVED"}}):_vm._e()],1),_c('div',{staticClass:"mso-coupon-editor__grid"},[_c('div',{staticClass:"mso-coupon-editor__grid__column"},[_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.name,"inputData":_vm.getInput('name'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.name = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.code,"inputData":_vm.getInput('code'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.code = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.minSpending,"inputData":_vm.getInput('minSpending'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.minSpending = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.customerOnly,"inputData":_vm.getInput('customerOnly'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.customerOnly = $event}}}),_c('div',{staticClass:"\n                mso-coupon-editor__grouped-inputs\n                bordered__highlighted--neutral\n              ",class:{
                'bordered__highlighted--danger':
                  _vm.selectedMsoCoupon.discountPercentage &&
                  _vm.selectedMsoCoupon.discountAmount,
              },staticStyle:{"position":"relative"}},[_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.discountPercentage,"inputData":_vm.getInput('discountPercentage'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.discountPercentage = $event}}}),_c('div',{staticClass:"mso-coupon-editor__or-break"},[_vm._v("OR")]),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.discountAmount,"inputData":_vm.getInput('discountAmount'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.discountAmount = $event}}}),(
                  _vm.selectedMsoCoupon.discountPercentage &&
                  _vm.selectedMsoCoupon.discountAmount &&
                  _vm.isEdit
                )?_c('div',{staticClass:"mso-coupon-editor__validation-text"},[_vm._v(" You can only have discount amount or discount percentage ")]):_vm._e()],1)],1),_c('div',{staticClass:"mso-coupon-editor__grid__column"},[_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.isOneTimeUse,"inputData":_vm.getInput('isOneTimeUse'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.isOneTimeUse = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.isForCannabisOnly,"inputData":_vm.getInput('isForCannabisOnly'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.isForCannabisOnly = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.isActive,"inputData":_vm.getInput('isActive'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.isActive = $event}}}),_c('div',{staticClass:"\n                mso-coupon-editor__grouped-inputs\n                bordered__highlighted--neutral\n              ",class:{
                'bordered__highlighted--danger':
                  _vm.selectedMsoCoupon.maximumQuantity <
                  _vm.selectedMsoCoupon.minimumQuantity,
              },staticStyle:{"position":"relative"}},[_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.minimumQuantity,"inputData":_vm.getInput('minimumQuantity'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.minimumQuantity = $event}}}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.maximumQuantity,"inputData":_vm.getInput('maximumQuantity'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.maximumQuantity = $event}}}),(
                  _vm.selectedMsoCoupon.maximumQuantity <
                    _vm.selectedMsoCoupon.minimumQuantity && _vm.isEdit
                )?_c('div',{staticClass:"mso-coupon-editor__validation-text"},[_vm._v(" Maximum quantity must be greater or equal to minimum quantity ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"mso-coupon-editor__divider"}),_c('Input',{attrs:{"model":_vm.selectedMsoCoupon.notes,"inputData":_vm.getInput('notes'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedMsoCoupon.notes = $event}}})],1)]),(_vm.selectedMsoCoupon._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedMsoCoupon.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedMsoCoupon}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }