<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Sales Recap By Employee"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'salesReport', label: 'Sales' },
            {
              name: 'salesRecapOrderEmployee',
              label: 'Sales Recap By Employee',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByTime="true"
          @submit-filter="performGetReport"
          :isLoading="salesTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <div
              v-for="[employeeId, employee] in Object.entries(
                reportData.employees
              )"
              :key="employeeId"
              class="report-body__employee-container"
            >
              <table id="table-report">
                <thead>
                  <tr class="report-body__row report-body__row--header"></tr>
                  <tr>
                    <th align="left">Date</th>
                    <th align="left">Empl</th>
                    <th align="right">Sub Total</th>
                    <th align="right">Discount</th>
                    <th align="right">Line Total</th>
                    <th align="right">Tax</th>
                    <th align="right">Delivery</th>
                    <th align="right">Total</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="report-body__row"></tr>
                  <tr v-for="(record, index) in employee.days" :key="index">
                    <td>{{ record.date }}</td>
                    <td>{{ record.employee }}</td>
                    <td align="right">
                      {{ Utilities.formatPrice(record.subTotal) }}
                    </td>
                    <td align="right">
                      {{ Utilities.formatPrice(record.discount) }}
                    </td>
                    <td align="right">
                      {{ Utilities.formatPrice(record.lineTotal) }}
                    </td>
                    <td align="right">{{ Utilities.formatPrice(record.tax) }}</td>
                    <td align="right">
                      {{ Utilities.formatPrice(record.freight) }}
                    </td>
                    <td align="right">
                      {{ Utilities.formatPrice(record.total) }}
                    </td>
                  </tr>
                </tbody>
                <tr class="skip-export">
                  <td colspan="100%">
                    <hr class="line1" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2">Totals</td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.subTotal) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.discount) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.lineTotal) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.tax) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.freight) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(employee.total.total) }}
                  </td>
                </tr>
                <tr class="skip-export"></tr>
                <tr class="skip-export">
                  <td colspan="100%">
                    <hr class="line1" />
                  </td>
                </tr>
              </table>
            </div>
            <div
              class="table__no-data-message"
              v-if="
                (loaded = true && Object.keys(reportData.employees).length === 0)
              "
            >
              No Data
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";
  import ReportHelper from "@/lib/report/index.js";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
        loaded: false,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Sales Recap By Employee");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        salesTransactions: "reports/salesTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getSalesTransactions: "reports/getSalesTransactions",
      }),
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(
          table,
          "SalesRecapOrderEmployee.csv"
        );
      },
      performGetReport(filter) {
        let reportType = "salesRecapOrderEmployee";
        this.loaded = false;
        this.getSalesTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
            this.loaded = true;
          },
        });
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_salesRecapOrderEmployee.scss";
  </style>