<template>
  <div>
    <PageHeader
      v-if="!isComponent"
      title="Reports"
      :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
      ]"
    />
    <div class="home responsive-menu">
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Inventory Status</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button
              :to="{ name: 'inventoryExpiring' }"
              type="plain-primary responsive-column"
              label="Cannabis Expiring"
            />
            <inline-button
              :to="{ name: 'inventoryCount' }"
              type="plain-primary responsive-column"
              label="Cannabis Inventory"
            />
            <inline-button
              :to="{ name: 'inventoryCountNc' }"
              type="plain-primary responsive-column"
              label="Non Cannabis Inventory"
            />
            <inline-button
              :to="{ name: 'inventoryDisposalScheduled' }"
              type="plain-primary responsive-column"
              label="Scheduled Disposals"
            />
          </div>
        </div>
      </div>
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Inventory Management</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button
              :to="{ name: 'inventoryOnHand' }"
              type="plain-primary responsive-column"
              label="On Hand"
            />
            <inline-button
              :to="{ name: 'inventoryReorderStatus' }"
              type="plain-primary responsive-column"
              label="Inventory Reorder Status"
            />
            <inline-button
              :to="{ name: 'inventoryAging' }"
              type="plain-primary responsive-column"
              label="Aging"
            />
            <inline-button
              :to="{ name: 'inventoryForecast' }"
              type="plain-primary responsive-column"
              label="Forecast"
            />
            <inline-button
              :to="{ name: 'inventoryBuyerGroup' }"
              type="plain-primary responsive-column"
              label="Buyer Sales for Group"
            />
            <inline-button
              :to="{ name: 'inventoryBuyerProduct' }"
              type="plain-primary responsive-column"
              label="Buyer Sales for Product"
            />
            <inline-button
              :to="{ name: 'inventoryBuyerSupplierGroup' }"
              type="plain-primary responsive-column"
              label="Buyer Sales for Supplier & Group"
            />
          </div>
        </div>
      </div>
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>State Reports</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button
              :to="{ name: 'inventoryAdjustment' }"
              type="plain-primary responsive-column"
              label="Adjustments"
            />
            <inline-button
              :to="{ name: 'inventoryDisposal' }"
              type="plain-primary responsive-column"
              label="Disposals"
            />
            <inline-button
              :to="{ name: 'stateInventory' }"
              type="plain-primary responsive-column"
              label="State Inventory Check"
            />
            <inline-button
              :to="{ name: 'inventoryTransfer' }"
              type="plain-primary responsive-column"
              label="Transfers"
            />
            <inline-button
              :to="{ name: 'inventoryVoid' }"
              type="plain-primary responsive-column"
              label="Voids"
            />

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportRoles } from "@/constants/roles";
import PageHeader from "@/components/Header/Header.vue";

export default {
  name: "reports",
  data() {
    return {
      reportRoles,
    };
  },
  components: { PageHeader },
  created: function () {
    this.setPageTitle("Reports");
  },
};
</script>

<style lang="scss"></style>