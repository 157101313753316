<template>
  <div class="customer-info elevated-0 bordered">
    <div v-show="customer[field.id]" v-for="field in getFields" :key="field.id" class="customer-info__row">
      <div class="customer-info__field">{{ field.label }}</div>
      <div>
        {{ customer[field.id] }}{{ field.suffix }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerInfo",
  data() {
    return {
      fields: [
        {
          id: "email",
          label: "Email",
        },
        {
          id: "name",
          label: "Name",
        },
        {
          id: "preferredName",
          label: "Preferred Name",
        },
        {
          id: "pronoun",
          label: "Pronoun",
        },
        {
          id: "lastName",
          label: "Last Name",
        },
        {
          id: "accountType",
          label: "Account Type",
        },
      ],
    };
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
    schema: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {},
  computed: {
    getFields() {
      if (this.schema.length > 0) {
        return this.schema
      } else {
        return this.fields
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./_customer-info.scss";
</style>
