<template>
  <div :aria-label="ariaLabel" class="inline-button" :class="getClass" @click="clicked">
    <span v-if="isLoading">
      <i class="fas fa-spin fa-circle-notch"></i>
    </span>
    <div v-if="!isLoading" class="inline-button__body" :class="['center', { 'space-between': icon && label }]">
      <div v-if="icon">
        <i :class="icon"></i>
      </div>
      <div v-if="label">{{ label }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "InlineButton",
  props: {
    isLoading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
      default: "plain-primary",
    },
    to: {
      type: Object,
      default: undefined,
    },
    ariaLabel: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    getClass() {
      let typeString = this.type;
      if (this.disabled) {
        typeString += " disabled";
      }

      var modifiers = typeString.split(" ");
      modifiers = modifiers.join(" inline-button--");
      return "inline-button--" + modifiers;
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit("click");
        if (this.to) {
          router.push(this.to);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_inlineButton.scss";
</style>
