var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Inventory Reorder Status","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'inventoryReport', label: 'Inventory' },
      {
        name: 'inventoryReorderStatus',
        label: 'Inventory Reorder Status',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"isLoading":_vm.inventoryTransactions,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_vm._m(0),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.reportData.total.datePeriod))])]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.store))]),_c('td',[_vm._v(_vm._s(record.productId))]),_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',[_vm._v(_vm._s(record.group))]),_c('td',[_vm._v(_vm._s(record.name))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.quantityOnHand))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.stockMinimum))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.storeDefaultQuantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.quantityToReorder))])])})],2),_vm._m(4),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.reportData.total.quantityOnHand))]),_c('td',{attrs:{"align":"right"}},[_vm._v("-")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.storeDefaultQuantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.quantityToReorder))])]),_c('tr',{staticClass:"skip-export"}),_vm._m(5)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Inventory Reorder Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Store")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Product Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Group")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Name")]),_c('th',{attrs:{"align":"right"}},[_vm._v("On Hand")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Stock Minimum")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Default Qty")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Reorder")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }