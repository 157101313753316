<template>
  <div class="flight-editor-items responsive-menu">
    <div>
      <div class="flight-editor-items__row">
        <BaseBtn
          data-cy="edit-items-button"
          iconGroup="fas"
          iconName="plus"
          @click="() => (showSearchModal = !showSearchModal)"
          :disabled="!isEdit"
        />
        <div class="flight-editor-items__totals">
          <div class="flight-editor-items__totals--total">
            <div>Sub Total:</div>
            <div class="flight-totals-value">${{ subTotal }}</div>
          </div>
          <div class="flight-editor-items__totals--total">
            <div>Discount Total:</div>
            <div
              class="flight-totals-value"
              :class="{ 'flight-editor-items__error': discountTotal < 0 }"
            >
              ${{ discountTotal }}
            </div>
          </div>
          <div class="flight-editor-items__totals--total">
            <div>Total:</div>
            <div class="flight-totals-value">${{ lineTotal }}</div>
          </div>
        </div>
      </div>
      <div class="flight-editor-items__divider" />
      <div class="table-container">
        <table class="table-element">
          <thead>
            <tr class="header">
              <th
                v-for="header in columnHeaders"
                :key="`table-column-header-${header.id}-${header.title}`"
                class="header-cell"
              >
                {{ header.type === "button" ? "" : header.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="row"
              v-for="item in selectedFlight.items"
              :key="item._id"
            >
              <td class="cell">
                <BaseBtn
                  data-cy="delete-flight-item-button"
                  iconGroup="fas"
                  iconName="trash"
                  @click="() => removeItem(item.item._id)"
                  :disabled="!isEdit"
                  :colorType="1"
                  class="flight-editor-items__delete-button"
                />
              </td>
              <td class="cell">{{ getItemValue(item, "item._id") }}</td>
              <td class="cell">{{ getItemValue(item, "item.batchId") }}</td>
              <td class="cell">
                {{ getItemValue(item, "item.supplier.name") }}
              </td>
              <td class="cell">
                {{ getItemValue(item, "item.product.name") }}
              </td>
              <td class="cell">
                <BaseInput
                  v-if="isEdit"
                  controlType="number"
                  :isSmall="true"
                  v-model="item.quantity"
                  @change="formatFlight"
                />
                <template v-if="!isEdit">{{ item.quantity }}</template>
              </td>
              <td class="cell">${{ getItemValue(item, "unitCost") }}</td>
              <td class="cell">${{ getItemValue(item, "subTotal") }}</td>
              <td class="cell">${{ getItemValue(item, "discount") }}</td>
              <td class="cell">${{ getItemValue(item, "lineTotal") }}</td>
              <td class="cell">
                {{ getItemValue(item, "item.product.cannabisGramsUnit") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="toggleSearchModal"
      @select-item="selectItem"
      :entity="Entities.ITEM"
      id="selectedItem"
      title="Select Items for Flight"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="searchColumnHeaders"
      :additionalFilters="additionalFilters"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";

import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";

import { columnHeaders, defaultSelectedItem } from "./inputs";
import { filterCriteria, searchColumnHeaders } from "../../../Item/inputs";

export default {
  name: "FlightItems",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      flights: [],
      flightsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.FLIGHT,
      showSearchModal: false,
      columnHeaders,
      Entities,
      selectedItem: {},
      isEditTable: false,
      filterCriteria,
      searchColumnHeaders,
      defaultSelectedItem,
    };
  },
  props: {
    isEdit: Boolean,
  },
  components: {
    BaseBtn,
    BaseInput,
  },
  mounted: function () {},
  computed: {
    ...mapGetters({
      flight: "flightModule/flight",
      store: "storeModule/store",
    }),
    selectedFlight: {
      get() {
        return this.calculateTotals(this.flight);
      },
      set(entity) {
        return this.calculateTotals(entity);
      },
    },
    discountTotal() {
      let sum = 0;
      if (this.flight.items?.length > 0)
        this.flight.items.forEach((item) => {
          sum += parseFloat(item.discount);
        });
      return Math.round(sum);
    },
    lineTotal() {
      let sum = 0;
      if (this.flight.items?.length > 0)
        this.flight.items.forEach((item) => {
          sum += parseFloat(item.lineTotal);
        });
      return Math.round(sum);
    },
    subTotal() {
      let sum = 0;
      if (this.flight.items?.length > 0)
        this.flight.items.forEach((item) => {
          sum += parseFloat(item.subTotal || 0);
        });
      return sum;
    },
    additionalFilters() {
      if (this.store.isWorkingInventoryOnlyEnabled)
        return {
          //flightIsWorkingInventory: true,
          $or: [
            { batchType: this.selectedFlight.saleType },
            { batchType: "medical-and-recreational" },
          ],
        };
      else return {};
    },
  },
  methods: {
    ...mapActions({
      getFlightById: "flightModule/getFlightById",
    }),
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    editItemsTable: function () {
      this.isEditTable = !this.isEditTable;
    },
    calculateTotals: function (flight) {
      if (!flight?.items || flight?.items?.length === 0) return flight;
      let result = flight.items.map((item) => {
        let unitCost =
          flight.saleType === "medical"
            ? item.item?.product?.medicalPrice
            : item.item?.product?.recreationalPrice;
        const subTotal = parseFloat(unitCost || 0) * parseFloat(item.quantity);
        return {
          ...item,
          subTotal: subTotal,
          unitCost: unitCost,
        };
      });
      let sum = 0;
      result.forEach((item) => {
        sum += parseFloat(item.subTotal);
      });
      const discountPercentage = sum !== 0 ? flight.salePrice / sum : 0;

      result = result.map((item) => {
        return {
          ...item,
          discount: parseFloat(
            parseFloat(item.subTotal) -
              parseFloat(discountPercentage * item.subTotal)
          ).toFixed(2),
          lineTotal: parseFloat(discountPercentage * item.subTotal).toFixed(2),
        };
      });
      flight.items = result;
      return flight;
    },
    selectItem: function (entity, item) {
      if (
        entity === "selectedItem" &&
        !this.selectedFlight.items.find((c) => c.item?._id === item._id)
      ) {
        const copiedObject = JSON.parse(JSON.stringify(defaultSelectedItem));
        const result = {
          ...copiedObject,
          item: {
            supplier: item.purchaseOrder.externalFields.supplier,
            product: item.product,
            batchId: item.batchId,
            quantityAvailable: item.quantityAvailable,
            _id: item._id,
          },
          unitCost: 0,
          subTotal: 0,
        };
        this.selectedFlight.items.unshift(result);
        this.selectedFlight = this.calculateTotals(this.selectedFlight);
      }
    },
    getItemValue: function (item, id) {
      let result = _.get(item, id);
      if (result === undefined || result === null) return "-";
      return result;
    },
    removeItem: function (id) {
      const index = this.selectedFlight.items.findIndex(
        (c) => c.item?._id === id
      );
      if (index > -1) {
        this.selectedFlight.items.splice(index, 1);
        this.selectedFlight = this.calculateTotals(this.selectedFlight);
      }
    },
    formatFlight: function () {
      this.selectedFlight = this.calculateTotals(this.selectedFlight);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_flight-items.scss";
</style>
