import { render, staticRenderFns } from "./Fulfillment.vue?vue&type=template&id=14c47017&scoped=true&"
import script from "./Fulfillment.vue?vue&type=script&lang=js&"
export * from "./Fulfillment.vue?vue&type=script&lang=js&"
import style0 from "./Fulfillment.vue?vue&type=style&index=0&id=14c47017&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c47017",
  null
  
)

export default component.exports