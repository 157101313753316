<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Voided Sales Orders" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesOrderStatusVoided',
          label: 'Voided Sales Orders',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Date Voided</th>
                <th align="left">Employee</th>
                <th align="left">Order Code</th>
                <th align="left">Source</th>
                <th align="left">Customer</th>
                <th align="left">Description</th>
                <th align="left">Batch #</th>
                <th align="right">Quantity</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td>{{ record.dateString }}</td>
                <td>{{ record.employee }}</td>
                <td>{{ record.orderCode }}</td>
                <td>{{ record.source }}</td>
                <td>{{ record.customer }}</td>
                <td>{{ record.description }}</td>
                <td class="batch">{{ record.batchId }}</td>
                <td align="right">
                  {{ record.quantity }}
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="7">Total</td>
              <td align="right">
                {{ reportData.total.quantity }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("State Voided Sales Orders");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(
      filter
    ) {
      let reportType = "salesOrderStatusVoided";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesOrderStatusVoided");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesOrderStatusVoided.scss";
</style>
