export const columnHeaders = [
  {
    id: "delete",
    type: "button",
    action: "delete",
    position: "sticky",
    iconName: "trash",
    ariaLabel: "Delete",
    colorType: 1,
  },
  {
    title: "Id",
    id: "item._id",
    link: true,
    url: "/items/itemEditor?id=",
    urlId: "item._id",
  },
  {
    title: "Batch #",
    id: "item.batchId",
  },
  {
    title: "Supplier",
    id: "item.supplier.name",
    link: true,
    url: "/suppliers/supplierEditor?id=",
    urlId: "supplier._id",
  },
  {
    title: "Product",
    id: "item.product.name",
    link: true,
    url: "/products/productEditor?id=",
    urlId: "product._id",
  },
  {
    title: "Quantity",
    id: "quantity",
    type: "input",
  },
  {
    title: "Unit Cost",
    id: "unitCost",
    prefix: "$",
    type: "calculate",
    calculate: (item, selectedEntity) => {
      if (selectedEntity.saleType?.code === "medical")
        return item.item.product.medicalPrice;
      return 0;
    },
  },
  {
    title: "Sub Total",
    id: "subTotal",
  },
  {
    title: "Discount",
    id: "discount",
    type: "calculate",
    calculate: (item, selectedEntity) => {
      let total = 0;
      selectedEntity.items.forEach((c) => {
        total += c.subTotal;
      });
      return selectedEntity.salePrice - total;
    },
  },
  {
    title: "Line Total",
    id: "lineTotal",
  },
  {
    title: "Grams",
    id: "grams",
  },
];

export const defaultSelectedItem = {
  item: {
    supplier: {},
    product: {},
    batchId: undefined,
    quantityAvalaiable: undefined,
    _id: "NEW",
  },
  quantity: 1,
  unitCost: 0,
  subTotal: 0,
  discount: 0,
  lineTotal: 0,
  grams: 0,
};
