<template>
  <div class="container-label">
    <div class="container-label bordered" :class="`container-label--${type}`">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ContainerLabel",
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
  },
};
</script>

<style lang="scss">
.container-label {
  position: absolute;
  top: 2.5px; //-5.5px;
  left: 50%;
  padding: 5px;
  font-size: $text-size-1;
  font-weight: bold;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  box-sizing: border-box;

  &--primary {
    background-color: $dark-accent-color;
  }
  &--danger {
    background-color: $dark-danger-color;
  }
}

.light .container-label {
  color: white;
}

.light .container-label--primary {
  background-color: $light-accent-color;
}

.light .container-label--danger {
  background-color: $light-danger-color;
}
</style>
