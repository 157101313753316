import Apis from "@/services/Apis";
import io from "socket.io-client";

export default {
  generateSocket() {
    const basePath = process.env.NODE_ENV === "production" ? Apis.backendBaseUrl.replace("/api/", "") : "http://localhost:8083";
    return io(basePath, {
      reconnectionDelayMax: 10000,
      resource: "/api/usersSocket",
      path: "/api/usersSocket",
      query: {
        token: localStorage.getItem("token"),
        storeId: localStorage.getItem("storeId"),
      },
      transports: ["websocket"],
    });
  },
};
