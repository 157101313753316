export const normalizeEntityBeforeSaving = function (msoProduct) {
  const entity = JSON.parse(JSON.stringify(msoProduct));

  entity.effects = selectIdOnly(entity.effects);
  entity.flavors = selectIdOnly(entity.flavors);
  entity.tags = selectIdOnly(entity.tags);
  entity.terpenes = selectIdOnly(entity.terpenes);

  return entity;
};

const selectIdOnly = (values) => {
  if (!values || values.length == 0) return [];
  return values.filter(v => v).map((v) => v._id);
};
