<template>
  <div class="mso-products responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'msoMenu', label: 'MSO Menu' },
      ]" :entity="entity" :hideAddButton="true">
        <BaseBtn @click="() => showPreview = !showPreview">
          Preview Menu
        </BaseBtn>
        <BaseBtn @click="() => showPriorityPopUp = !showPriorityPopUp">
          Set Priority
        </BaseBtn>
      </PageHeader>
      <SearchTable :entity="entity" :layoutEntity="layoutEntity" :sort="sort" @sortChangeEvent="updateSort"
        :filterButtons="filterButtons" @filterButtonsChange="updateFilterButtons" :enableAdditionalFilter="true"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" :selectable="true" @itemsSelected="(items) => itemsSelected = items"
        :clearSelected="clearSelected">
        <div style="display: flex; gap: .5rem;">
          <inline-button
              :type="entity == entities.MSOSUPPLIER ? 'primary' : 'plain-primary'"
              @click="selectSupplierEntity"
              >MSO Suppliers</inline-button>
            <inline-button
              :type="entity == entities.MSOPRODUCT ? 'primary' : 'plain-primary'"
              @click="selectProductEntity"
              >MSO Products</inline-button>
        </div>
      </SearchTable>
    </div>
    <BaseModal v-if="showPriorityPopUp" @toggle-modal="showPriorityPopUp = false" :title="`Set ${entity == entities.MSOSUPPLIER ? 'Supplier' : 'Product'} Priority`">
      <SortPriority :itemsSelected="itemsSelected" @setPriority="(e) => setPriority(e)"
        :isLoading="loadingStatus.setMSOPriority"></SortPriority>
    </BaseModal>
    <BaseModal v-if="showPreview" @toggle-modal="showPreview = false" title="Preview Online Menu">
      <ViewOnlineStore></ViewOnlineStore>
    </BaseModal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import SortPriority from "@/components/SortPriority/SortPriority.vue";
import ViewOnlineStore from "@/components/ViewOnlineStore/ViewOnlineStore.vue";

export default {
  name: "msoMenu",
  data() {
    return {
      listPageHeader,
      entity: Entities.MSOSUPPLIER,
      layoutEntity: Entities.MSOMenuSupplier,
      itemsSelected: [],
      clearSelected: 0,
      showPriorityPopUp: false,
      showPreview: false,
    };
  },
  mounted: function () {
    this.setPageTitle("MSO Menu");
  },
  components: {
    PageHeader,
    SearchTable,
    BaseBtn,
    BaseModal,
    SortPriority,
    ViewOnlineStore,
  },
  computed: {
    ...mapGetters({
      sort: "menuModule/sort",
      pagination: "menuModule/pagination",
      initialFilter: "menuModule/initialFilter",
      filterButtons: "menuModule/filterButtons",
      loadingStatus: "menuModule/loadingStatus",
    }),
    entities: () => Entities,
  },
  methods: {
    ...mapActions({
      updateSort: "menuModule/updateSort",
      updatePagination: "menuModule/updatePagination",
      updateInitialFilter: "menuModule/updateInitialFilter",
      updateFilterButtons: "menuModule/updateFilterButtons",
      setMSOProductPriority: "menuModule/setMSOProductPriority",
      setMSOSupplierPriority: "menuModule/setMSOSupplierPriority",
      setRefetchTableData: "stateModule/setRefetchTableData",
    }),
    setPriority(e){
      switch(this.entity){
        case Entities.MSOPRODUCT:
          this.setMsoProduct(e);
          break;
        case Entities.MSOSUPPLIER:
          this.setMsoSupplier(e);
          break;
      }
    },
    setMsoProduct(e) {
      this.setMSOProductPriority({
        positiveCallback: (result) => {
          this.showPriorityPopUp = false;
          this.clearSelected = Math.random();
          this.setRefetchTableData({clearPagination: true});
        },
        negativeCallback: (error) => {
          console.log(error);
        },
        items: this.itemsSelected,
        sortPriority: Number(e.sortPriority),
        resetPriority: e.resetPriority ?? false,
      });
    },
    setMsoSupplier(e){
      this.setMSOSupplierPriority({
        positiveCallback: (result) => {
          this.showPriorityPopUp = false;
          this.clearSelected = Math.random();
          this.setRefetchTableData({clearPagination: true});
        },
        negativeCallback: (error) => {
          console.log(error);
        },
        items: this.itemsSelected,
        sortPriority: Number(e.sortPriority),
        resetPriority: e.resetPriority ?? false,
      });
    },
    selectProductEntity(){
      this.clearSelected = Math.random();
      this.entity = Entities.MSOPRODUCT;
      this.layoutEntity = Entities.MSOMenu;
      this.setRefetchTableData({clearPagination: true, entityChanged: true});
    },
    selectSupplierEntity(){
      this.clearSelected = Math.random();
      this.entity = Entities.MSOSUPPLIER;
      this.layoutEntity = Entities.MSOMenuSupplier;
      this.setRefetchTableData({clearPagination: true, entityChanged: true});
    },
  }
};
</script>

<style lang="scss"></style>
