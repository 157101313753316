<template>
  <div class="cash-reconciliation-details">
    <StoreHeader :store="store" />
    <p class="cash-reconciliation-details-draft" v-if="!isPosted">- DRAFT -</p>
    <p class="cash-reconciliation-details-title">
      {{ title }}
    </p>
    <Overview
      :cashReconciliation="cashReconciliation"
      class="cash-reconciliation-details__overview"
    />
    <MainData
      :summarized="true"
      :cashReconciliation="cashReconciliation"
      class="cash-reconciliation-details__main-data"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StoreHeader from "./Store/index.vue";
import Overview from "./Overview/index.vue";
import MainData from "./MainData/index.vue";

export default {
  data() {
    return {
      title: "Station Reconciliation Summary",
    };
  },
  components: {
    StoreHeader,
    Overview,
    MainData,
  },
  computed: {
    ...mapGetters({
      getStore: "storeModule/currentStore",
      getCashReconciliation: "cashReconciliationModule/getCashReconciliation",
    }),
    isPosted() {
      return this.cashReconciliation.isPostedToJournal;
    },
    store() {
      return this.getStore;
    },
    cashReconciliation() {
      return this.getCashReconciliation;
    },
  },
  methods: {
    ...mapActions({
      getCashReconciliationById:
        "cashReconciliationModule/getCashReconciliationById",
    }),
    async getCashReconciliationInfo() {
      const id = this.$route.query.id;
      await this.getCashReconciliationById({ _id: id });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    const currentCashReconciliation = this.getCashReconciliation;
    if (currentCashReconciliation._id != id) {
      this.getCashReconciliationInfo();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_summarized.scss";
</style>
