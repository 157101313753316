<template>
  <div class="base-input">
    <label v-if="label" class="base-input__label" :class="{ 'base-input__label--focus': focused }">{{ label }}</label>
    <div class="container">
      <div class="container__left">
        <input class="input-text input-text__field" :class="{ 'size-sm': isSmall, 'password-look': isPassword }"
          :type="controlType || 'input'" v-bind="$attrs" :value="value" :min="min || ''"
          @input="$emit('input', $event.target.value)" v-on="listeners" :placeholder="placeholder" @focus="focused = true"
          @blur="focused = false" name="new-password" id="new-password" ref="input" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppInputControl",
  components: {},
  data() {
    return {
      focused: false,
    };
  },
  props: {
    controlType: {
      type: String,
      default: "input",
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "",
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    value: {},
    applyFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    autocompleteValue() {
      return this.autocomplete ? "on" : "off";
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  },
  mounted() {
    if (this.applyFocus) {
      this.$nextTick(() => this.$refs.input.focus());
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_input.scss";
</style>
