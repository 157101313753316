// Brand Editor
// Page Header
export const pageHeader = {
  title: "Brand",
  isEditorView: true,
  cancelPath: "brands",
  addPath: "brandEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Brands",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "brandEditor",
};
// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Brand Name",
    code: "name",
    field: { type: "input", label: "Brand Name" },
    isPartial: true,
  },
  { label: "Brand Id", code: "_id", field: { type: "input", label: "Brand Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Brand Name",
    id: "name",
  },
  {
    title: "Brand Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
];

// Brand Information - Main Section
export const brandInputs = [
  {
    id: "name",
    type: "input",
    title: "Brand Name",
    placeholder: "Brand Name",
    focus: true,
    required: true,
  },
];

export const defaultSelectedBrand = {
  _id: "NEW",
  name: "",
};
