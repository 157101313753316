import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  adjustments: [],
  loadingStatus: {
    saveAdjustment: false,
    getAdjustments: false,
    getAdjustmentById: false,
    deleteAdjustment: false,
  },
  adjustmentPopUp: {
    isOpen: false,
  },
  filterCriteria: [
    { label: "ID", code: "_id", field: { type: "input", label: "ID" } },
    { label: "Email", code: "email", field: { type: "input", label: "Email" } },
    { label: "Name", code: "name", field: { type: "input", label: "Name" } },
    {
      label: "Last name",
      code: "lastName",
      field: { type: "input", label: "Last name" },
    },
  ],
  selectedFilter: undefined,
  selectedPreFilters: undefined,
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setAdjustments: (state, payload) => {
    state.adjustments = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setAdjustmentPopUp: (state, payload) => {
    state.adjustmentPopUp = payload;
  },
};

const getters = {
  adjustments: (state) => state.adjustments,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  adjustmentPopUp: (state) => state.adjustmentPopUp,
  selectedPreFilters: (state) => state.selecteds,
};

const actions = {
  saveAdjustment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveAdjustment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveAdjustment,
      payload.adjustment,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveAdjustment", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteAdjustment: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteAdjustment", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteAdjustment,
      payload.adjustment,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteAdjustment", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getAdjustments: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjustments", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getAdjustments}?`;
    if (state.selectedFilter) {
      if (state.selectedFilter.limit) {
        url += `&limit=${state.selectedFilter.limit}`;
      }
      if (state.selectedFilter.skip) {
        url += `&skip=${state.selectedFilter.skip}`;
      }
      if (state.selectedFilter.criteria) {
        url += `&filterCriteria=${state.selectedFilter.criteria.code}`;
      }
      if (state.selectedFilter.value) {
        url += `&filterValue=${state.selectedFilter.value}`;
      }
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjustments", status: false });
      var adjustments = {};

      if (response) {
        adjustments = response.data.body;
        commit("setAdjustments", adjustments);
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getAdjustmentById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjustmentById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getAdjustmentById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getAdjustmentById", status: false });
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setAdjustmentPopUp: ({ commit, state }, payload) => {
    commit("setAdjustmentPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
