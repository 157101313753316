// Item Editor
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as supplierColumnHeaders,
  filterCriteria as supplierFilterCriteria,
} from "../Supplier/inputs";
import {
  searchColumnHeaders as roomColumnHeaders,
  filterCriteria as roomFilterCriteria,
} from "../Room/inputs";
import Constants from "@/resources/Constants";
import { searchColumnHeaders as productColumnHeaders } from "@/views/Product/inputs";

// Page Header
export const pageHeader = {
  title: "Item",
  isEditorView: true,
  cancelPath: "item",
  addPath: "itemEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Items",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "itemEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Batch #",
    code: "batchId",
    field: { type: "input", label: "Batch #" },
    isPartial: true,
    regex: /[^a-zA-Z0-9-_]/g,
    clearOnFocus: true,
  },
  {
    label: "Id",
    code: "barcode",
    field: { type: "input", label: "Item Id" },
    isPartial: true,
    regex: /[^a-zA-Z0-9-_]/g,
    clearOnFocus: true,
  },
  {
    label: "Group Name",
    code: "externalFields.group.name",
    field: { type: "input", label: "Group Name" },
    isPartial: true,
  },
  {
    label: "Product Id",
    code: "product",
    field: { type: "input", label: "Product Id" },
  },
  {
    label: "Product Name",
    code: "externalFields.product.name",
    field: { type: "input", label: "Product Name" },
    isPartial: true,
  },
  {
    label: "Online Store Name",
    code: "externalFields.product.onlineStoreName",
    field: { type: "input", label: "Online Store Name" },
    isPartial: true,
  },
  {
    label: "Purchase Order Id",
    code: "purchaseOrder",
    field: { type: "input", label: "Purchase Order Id" },
  },
  {
    label: "Supplier Name",
    code: "externalFields.supplier.name",
    field: { type: "input", label: "Supplier Name" },
    isPartial: true,
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    id: "move",
    type: "button",
    action: "move",
    position: "sticky",
    iconName: "people-carry",
    ariaLabel: "Move",
  },
  {
    id: "print",
    type: "button",
    action: "print",
    position: "sticky",
    iconName: "print",
    ariaLabel: "Print",
  },
  { title: "Batch #", id: "batchId", position: "sticky", size: "batch" },
  { title: "Received", id: "isReceived", type: "checkbox" },
  { title: "Posted", id: "isPostedToProducts", type: "checkbox" },

  { title: "Group Name", id: "externalFields.group.name", size: "medium" },
  { title: "Supplier", id: "externalFields.supplier.name", size: "medium" },
  {
    title: "Grams",
    id: "externalFields.product.cannabisGramsUnit",
    size: "medium",
  },
  { title: "PO Date", id: "createdAt", type: "date" },
  {
    title: "Product Name",
    id: "externalFields.product.name",
    link: true,
    url: "/products/productEditor?id=",
    urlId: "externalFields.product._id",
    size: "large",
  },
  { title: "WI", id: "quantityWorkingInventory" },
  { title: "PO", id: "quantityPreorder" },
  { title: "Vault", id: "quantityVault", isSortable: false },
  { title: "On Hand", id: "quantityOnHand" },
  { title: "Available", id: "quantityAvailable" },
  { title: "Purchased", id: "quantityPurchased" },
  { title: "Sold", id: "quantitySold" },
  {
    title: "Dispose",
    id: "quantityScheduledForDisposal",
  },
  { title: "Medical Price", id: "externalFields.product.medicalPrice" },
  {
    title: "Recreational Price",
    id: "externalFields.product.recreationalPrice",
  },
  {
    title: "Purchase Order",
    id: "purchaseOrder._id",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder._id",
  },

  { title: "Unit Cost", id: "unitCost" },
  { title: "Actual Cost", id: "actualUnitCost" },

  { title: "Batch Type", id: "batchType" },

  { title: "Inventory Type", id: "externalFields.group.inventoryType" },
  {
    title: "Cannabis",
    id: "externalFields.group.isCannabis",
    type: "checkbox",
  },
  {
    title: "Inventoried",
    id: "externalFields.group.isInventoried",
    type: "checkbox",
  },
  { title: "Archived", id: "isArchived", type: "checkbox" },
  { title: "Expiration Date", id: "expirationDate", type: "date" },
  { title: "Barcode", id: "barcode" },
  { title: "Item Id", id: "_id" },
];

export const editorModalColumnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  { title: "Batch #", id: "batchId" },
  { title: "Group Name", id: "product.externalFields.group.name" },
  { title: "Supplier", id: "product.supplier.name" },
  { title: "Cannabis Grams/Unit", id: "product.cannabisGramsUnit" },
  { title: "Quantity Working Iventory", id: "quantityWorkingInventory" },
  {
    title: "Purchase Order",
    id: "purchaseOrder._id",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder._id",
  },
  { title: "Barcode", id: "barcode" },
  {
    title: "Product Name",
    id: "product.name",
    link: true,
    url: "/products/productEditor?id=",
    urlId: "product._id",
  },

  { title: " Product Id", id: "product._id" },
  { title: "Item Id", id: "_id" },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  { title: "Batch #", id: "batchId" },
  { title: "Batch Type", id: "batchType" },
  { title: "Group Name", id: "product.externalFields.group.name" },
  { title: "Supplier", id: "product.supplier.name" },
  { title: "Cannabis Grams/Unit", id: "product.cannabisGramsUnit" },
  { title: "Quantity Working Iventory", id: "quantityWorkingInventory" },
  {
    title: "Purchase Order",
    id: "purchaseOrder._id",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder._id",
  },
  { title: "Barcode", id: "barcode" },
  {
    title: "Product Name",
    id: "product.name",
    link: true,
    url: "/products/productEditor?id=",
    urlId: "product._id",
  },

  { title: " Product Id", id: "product._id" },
  { title: "Item Id", id: "_id" },
];

export const splitInputs = [
  {
    id: "product",
    type: "search",
    title: "Choose Product",
    label: "Product",
    entity: Entities.PRODUCT,
    columnHeaders: productColumnHeaders,
    filter: {
      "externalFields.group.unitOfMeasure": "unit",
      isDeleted: false,
    },
    sort: {
      createdAt: 1,
    },
  },
];

export const itemInputs = [
  {
    id: 1,
    inputs: [
      {
        title: "Supplier",
        id: "purchaseOrder.externalFields.supplier",
        type: "link",
        placeholder: "Supplier",
        to: {
          name: "supplierEditor",
          valueKey: "_id",
          valueName: "name",
        },
        target: "_blank",
      },
      {
        id: "batchType",
        type: "select",
        title: "Batch Type",
        options: Constants.listOfSaleType,
        label: "label",
      },
      {
        title: "Group",
        id: "product.group",
        type: "link",
        placeholder: "Group",
        to: {
          name: "groupEditor",
          valueKey: "_id",
          valueName: "name",
        },
        target: "_blank",
      },
      {
        id: "expirationDate",
        type: "calendar",
        title: "Expiration Date",
      },
      {
        title: "Product",
        id: "product",
        type: "link",
        placeholder: "Product",
        to: {
          name: "productEditor",
          valueKey: "_id",
          valueName: "name",
        },
        target: "_blank",
      },
      {
        id: "product.cannabisGramsUnit",
        placeholder: "Cannabis Grams/Unit",
        type: "text",
        title: "Cannabis Grams/Unit",
        suffix: "g",
      },
      {
        id: "externalFields.group.unitOfMeasure",
        placeholder: "Unit of Measure",
        type: "text",
        title: "Unit of Measure",
      },
      {
        id: "product.packagedWeight",
        placeholder: "Packaged Weight",
        type: "text",
        title: "Packaged Weight",
        suffix: "g",
      },
      {
        id: "unitCost",
        type: "text",
        title: "Unit Cost",
        placeholder: "Unit Cost",
        prefix: "$",
      },
      {
        id: "actualUnitCost",
        type: "text",
        title: "Actual Unit Cost",
        placeholder: "Actual Unit Cost",
        prefix: "$",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "purchaseOrder",
        type: "link",
        title: "Purchase Order",
        placeholder: "Purchase Order",
        to: {
          name: "purchaseOrderEditor",
          valueKey: "_id",
        },
        target: "_blank",
      },
      {
        id: "externalFields.group.inventoryType",
        type: "text",
        title: "Inventory Type",
        placeholder: "Inventory Type",
      },
      {
        id: "isMenuPrintEnabled",
        type: "checkBox",
        title: "Print on Menu",
        placeholder: "Print on Menu",
      },
      {
        id: "isPublishedToOnlineStore",
        type: "checkBox",
        title: "Publish to Online Store",
        checkboxLabel: "Online Store",
      },
      {
        id: "isSample",
        type: "checkBox",
        title: "Sample",
        placeholder: "Sample",
      },
      {
        id: "isTaxed",
        type: "checkBox",
        title: "Taxed",
        placeholder: "Taxed",
      },
      {
        id: "isRecalled",
        type: "checkBox",
        title: "Recalled",
        checkboxLabel: "Select if Recalled",
      },
    ],
  },
];

// Adjustments Section
export const adjustmentsInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "type",
        type: "select",
        title: "Adjustment Type",
        placeholder: "Select Type",
        options: Constants.listOfAdjustmentTypes,
      },
      {
        id: "reason",
        type: "select",
        title: "Adjustment Reason",
        placeholder: "Select Reason",
        options: Constants.listOfAdjustmentReasons,
      },
      {
        id: "isArchived",
        checkboxLabel: "Archived",
        title: "Archived",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "isPostedToState",
        checkboxLabel: "Posted to State",
        title: "Posted to State",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "stateNotes",
        placeholder: "State Notes",
        type: "textarea",
        title: "State Notes",
        class: "grid-row-two-column",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "mode",
        type: "select",
        title: "Adjustment Mode",
        placeholder: "Select Mode",
        options: Constants.listOfAdjustmentModes,
      },
      {
        id: "quantity",
        type: "money",
        title: "Adjustment Quantity",
        precision: 6,
      },
      {
        id: "isPostedToProducts",
        checkboxLabel: "Posted to Inventory",
        title: "Posted to Inventory",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "quantityAvailable",
        type: "text",
        title: "Quantity Available",
      },
      {
        id: "detailedDescription",
        placeholder: "Detailed Description",
        type: "textarea",
        title: "Detailed Description",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Disposal Sections
export const disposalInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "disposalDate",
        type: "calendar",
        title: "Disposal Date",
      },
      {
        id: "isDisposalDateConfirmed",
        type: "checkBox",
        title: "Confirm Disposal Date",
      },
      {
        id: "type",
        type: "select",
        title: "Disposal Type",
        options: Constants.listOfDisposalTypes,
      },
      {
        id: "reason",
        type: "select",
        title: "Disposal Reason",
        options: Constants.listOfDisposalReasons,
      },
      {
        id: "quantity",
        type: "number",
        title: "Quantity to Dispose",
        precision: Constants.numericInput.defaultWeightPrecision,
      },
      {
        id: "quantityAvailable",
        type: "text",
        title: "Quantity Available",
      },
      {
        id: "unitCost",
        type: "text",
        title: "Unit Cost",
      },
      {
        id: "disposalTotal",
        type: "text",
        title: "DisposalTotal",
      },
      {
        id: "isArchived",
        checkboxLabel: "Archived",
        title: "Archived",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "isPostedToState",
        checkboxLabel: "Posted to State",
        title: "Posted to State",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "detailedDescription",
        placeholder: "Detailed Description",
        type: "textarea",
        title: "Detailed Description",
        class: "grid-row-two-column",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isInWorkingInventory",
        checkboxLabel: "Working Inventory",
        type: "checkBox",
        title: "Is in Working Inventory",
      },
      {
        id: "method",
        type: "select",
        title: "Disposal Method",
        options: Constants.listOfDisposalMethods,
      },
      {
        id: "supplier",
        nestedId: "selectedDisposal.supplier",
        type: "search",
        title: "Supplier",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.SUPPLIER,
        columnHeaders: supplierColumnHeaders,
        filterCriteria: supplierFilterCriteria,
      },
      {
        id: "isScheduled",
        checkboxLabel: "Scheduled",
        title: "Scheduled",
        type: "checkBox",
      },
      {
        id: "isPostedToProducts",
        checkboxLabel: "Posted to Inventory",
        title: "Posted to Inventory",
        type: "checkBox",
      },
      {
        id: "isPostedToJournal",
        checkboxLabel: "Posted to Journal",
        title: "Posted to Journal",
        type: "checkBox",
      },
      {
        id: "isCompleted",
        checkboxLabel: "Completed",
        title: "Completed",
        type: "checkBox",
      },
      {
        id: "isRefund",
        checkboxLabel: "Credit",
        title: "Check this box if the supplier is issuing you a credit",
        type: "checkBox",
      },
      {
        id: "otherReason",
        placeholder: "Explain Reason",
        type: "textarea",
        title: "Explain Reason",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Returns Section
export const returnsInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "isArchived",
        checkboxLabel: "Archived",
        title: "Archived",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "isPostedToState",
        checkboxLabel: "Posted to State",
        title: "Posted to State",
        type: "checkBox",
        disabled: true,
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "quantity",
        type: "money",
        title: "Adjustment Quantity",
        precision: 6,
      },
      {
        id: "isPostedToProducts",
        checkboxLabel: "Posted to Inventory",
        title: "Posted to Inventory",
        type: "checkBox",
        disabled: true,
      },
      {
        id: "detailedDescription",
        placeholder: "Detailed Description",
        type: "textarea",
        title: "Detailed Description",
        class: "grid-row-two-column",
      },
    ],
  },
];

export const defaultSelectedItem = {
  _id: "NEW",
  selectedAdjustment: {},
  selectedDisposal: {},
  selectedReturn: {},
  product: {},
  purchaseOrder: {},
  adjustments: [],
  disposals: [],
  returns: [],
  supplier: {},
  splitItems: [],
};
