<template>
  <div class="disposals">
    <div class="padded elevated-0 bordered">
      <div class="
          two-columns-layout
          two-columns-layout--responsive
          two-columns-layout--border-bottom
          right
        ">
        <div class="button-wrapper">
          <div v-if="!isNotEmpty(selectedItem.selectedDisposal)">
            <BaseBtn @click="() => setSelectedDisposal()" label="New" iconGroup="fas" iconName="plus" :colorType="0"
              :disabled="!isEdit" />
          </div>
          <div class="button-wrapper" v-if="isNotEmpty(selectedItem.selectedDisposal)">
            <BaseBtn @click="() => removeSelectedDisposal()" label="Close" iconGroup="fas" iconName="times"
              :colorType="3" />

            <BaseBtn @click="() => archiveDisposal()" :label="
              selectedItem.selectedDisposal.isArchived
                ? 'Unarchive'
                : 'Archive'
                " iconGroup="fas" iconName="trash" :colorType="1" :disabled="
                !isEdit ||
                selectedItem.selectedDisposal.isScheduled == true ||
                selectedItem.selectedDisposal.isPostedToJournal ||
                selectedItem.selectedDisposal.isPostedToProducts ||
                selectedItem.selectedDisposal.isPostedToState ||
                selectedItem.selectedDisposal._id === 'NEW'
              " />

            <BaseBtn @click="() => performDisposalSchedule()" :label="
              selectedItem.selectedDisposal.isScheduled
                ? 'UnSchedule'
                : 'Schedule/Save'
                " iconGroup="fas" iconName="clock" :colorType="5" :disabled="
                !canEdit ||
                selectedItem.selectedDisposal.isPostedToProducts ||
                selectedItem.selectedDisposal.isPostedToState ||
                selectedItem.selectedDisposal.isPostedToJournal
              " />

            <BaseBtn @click="() => performPostDisposal()" label="Post Disposal" iconGroup="fas" iconName="paper-plane"
              :colorType="2" :disabled="
                !canEdit ||
                selectedItem.selectedDisposal._id === 'NEW' ||
                selectedItem.selectedDisposal.isPostedToProducts ||
                selectedItem.selectedDisposal.isPostedToState ||
                selectedItem.selectedDisposal.isScheduled == false
              " />
          </div>
        </div>
      </div>
      <div v-if="isNotEmpty(selectedItem.selectedDisposal) && isEdit">
        <div>
          <div class="disposals__inputs">
            <div class="disposals__inputs__row">
              <div class="disposals__totals bordered__highlighted--neutral">
                <h4 class="disposals__totals__title">Disposal Totals</h4>

                <Input :model="getValue('selectedDisposal.disposalDate')" @updateModel="(event) => setValue('selectedDisposal.disposalDate', event)"
                  :inputData="getInput('selectedDisposal.disposalDate')"
                  v-if="selectedItem.selectedDisposal._id === 'NEW' ||
                  (selectedItem.selectedDisposal.isScheduled == false &&
                    selectedItem.selectedDisposal.isPostedToJournal == false &&
                    selectedItem.selectedDisposal.isPostedToProducts == false &&
                    selectedItem.selectedDisposal.isPostedToState == false)"
                    :isEdit="canEdit" />

                <Input :model="getValue('selectedDisposal.disposalDate')" @updateModel="(event) => setValue('selectedDisposal.disposalDate', event)"
                  :inputData="getInput('selectedDisposal.disposalDate')"
                  v-if="selectedItem.selectedDisposal._id !== 'NEW' &&
                  (selectedItem.selectedDisposal.isScheduled == true ||
                    selectedItem.selectedDisposal.isPostedToJournal == true ||
                    selectedItem.selectedDisposal.isPostedToProducts == true ||
                    selectedItem.selectedDisposal.isPostedToState == true)" />

                <Input :model="getValue('selectedDisposal.isDisposalDateConfirmed')" @updateModel="
                  (event) =>
                    setValue('selectedDisposal.isDisposalDateConfirmed', event)"
                    :inputData="getInput('selectedDisposal.isDisposalDateConfirmed')"
                    :isEdit="canEdit" />

                <Input :model="getValue('selectedDisposal.room')" @updateModel="(event) => setValue('selectedDisposal.room', event)"
                  :inputData="getInput('selectedDisposal.room')"
                  v-if="selectedItem.selectedDisposal.isPostedToJournal == false &&
                  selectedItem.selectedDisposal.isPostedToProducts == false &&
                  selectedItem.selectedDisposal.isPostedToState == false &&
                  selectedItem.selectedDisposal.isScheduled == false"
                  :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.room')" @updateModel="(event) => setValue('selectedDisposal.room', event)"
                  :inputData="getInput('selectedDisposal.room')"
                  v-if="selectedItem.selectedDisposal._id !== 'NEW' &&
                  (selectedItem.selectedDisposal.isScheduled == true ||
                  selectedItem.selectedDisposal.isPostedToJournal == true ||
                  selectedItem.selectedDisposal.isPostedToProducts == true ||
                  selectedItem.selectedDisposal.isPostedToState == true)" />

                <Input :model="getValue('selectedDisposal.quantity')" @updateModel="(event) => setValue('selectedDisposal.quantity', event)"
                  :inputData="getInput('selectedDisposal.quantity')"
                  v-if="selectedItem.selectedDisposal._id === 'NEW' ||
                  (selectedItem.selectedDisposal.isScheduled == false &&
                    selectedItem.selectedDisposal.isPostedToJournal == false &&
                    selectedItem.selectedDisposal.isPostedToProducts == false &&
                    selectedItem.selectedDisposal.isPostedToState == false)"
                    :isEdit="canEdit" />

                <Input :model="getValue('selectedDisposal.quantity')" @updateModel="(event) => setValue('selectedDisposal.quantity', event)"
                  :inputData="getInput('selectedDisposal.quantity')"
                  v-if="selectedItem.selectedDisposal._id !== 'NEW' &&
                  (selectedItem.selectedDisposal.isScheduled == true ||
                    selectedItem.selectedDisposal.isPostedToJournal == true ||
                    selectedItem.selectedDisposal.isPostedToProducts == true ||
                    selectedItem.selectedDisposal.isPostedToState == true)" />

                <Input :model="getValue('actualUnitCost')" @updateModel="(event) => setValue('actualUnitCost', event)"
                  :inputData="getInput('actualUnitCost')" :isEdit="canEdit" />
                <Input :model="getValue('disposalTotal')" @updateModel="(event) => setValue('disposalTotal', event)"
                  :inputData="getInput('disposalTotal')" :isEdit="canEdit" />
                <hr />
              </div>

              <div class="disposals__inputs__row--checkboxes">
                <Input :model="getValue('selectedDisposal.isScheduled')" @updateModel="
                  (event) => setValue('selectedDisposal.isScheduled', event)
                " :inputData="getInput('selectedDisposal.isScheduled')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isCompleted')" @updateModel="
                  (event) => setValue('selectedDisposal.isCompleted', event)
                " :inputData="getInput('selectedDisposal.isCompleted')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isArchived')" @updateModel="
                  (event) => setValue('selectedDisposal.isArchived', event)
                " :inputData="getInput('selectedDisposal.isArchived')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isPostedToProducts')" @updateModel="
                  (event) =>
                    setValue('selectedDisposal.isPostedToProducts', event)
                " :inputData="getInput('selectedDisposal.isPostedToProducts')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isPostedToState')" @updateModel="
                  (event) =>
                    setValue('selectedDisposal.isPostedToState', event)
                " :inputData="getInput('selectedDisposal.isPostedToState')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isPostedToJournal')" @updateModel="
                  (event) =>
                    setValue('selectedDisposal.isPostedToJournal', event)
                " :inputData="getInput('selectedDisposal.isPostedToJournal')" :isEdit="canEdit" />
                <Input :model="getValue('selectedDisposal.isRefund')" @updateModel="
                  (event) => setValue('selectedDisposal.isRefund', event)
                " :inputData="getInput('selectedDisposal.isRefund')" :isEdit="canEdit" />
              </div>
            </div>

            <div class="disposals__inputs__row">
              <Input :model="getValue('selectedDisposal.reason')" @updateModel="
                (event) => setValue('selectedDisposal.reason', event)
              " :inputData="getInput('selectedDisposal.reason')" :isEdit="canEdit" />
              <Input :model="getValue('selectedDisposal.method')" @updateModel="
                (event) => setValue('selectedDisposal.method', event)
              " :inputData="getInput('selectedDisposal.method')" :isEdit="canEdit" />
              <Input :model="getValue('selectedDisposal.type')" @updateModel="
                (event) => setValue('selectedDisposal.type', event)
              " :inputData="getInput('selectedDisposal.type')" :isEdit="canEdit" />
            </div>

            <div class="disposals__inputs__row">
              <Input :model="getValue('selectedDisposal.otherReason')" @updateModel="
              (event) => setValue('selectedDisposal.otherReason', event)
            " :inputData="getInput('selectedDisposal.otherReason')" :isEdit="canEdit" />
              <Input :model="getValue('selectedDisposal.supplier')" @updateModel="
                (event) => setValue('selectedDisposal.supplier', event)
              " :inputData="getInput('selectedDisposal.supplier')" :isEdit="canEdit" />
            </div>
            <Input :model="getValue('selectedDisposal.detailedDescription')" @updateModel="
              (event) =>
                setValue('selectedDisposal.detailedDescription', event)
            " :inputData="getInput('selectedDisposal.detailedDescription')" :isEdit="canEdit" />
          </div>
        </div>
        <About :embedded="true" v-if="selectedItem.selectedDisposal._id !== 'NEW'"
          :entity="selectedItem.selectedDisposal" />
      </div>
    </div>

    <br />
    <div v-if="selectedItem.disposals && selectedItem.disposals.length > 0" class="table-container bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Archived</th>
            <th class="cell">Scheduled</th>
            <th class="cell">Posted to State</th>
            <th class="cell">Posted to Products</th>
            <th class="cell">Posted to Journal</th>
            <th class="cell">Supplier Refund</th>
            <th class="cell">Quantity</th>
            <th class="cell">Disposal Date</th>
            <th class="cell">Room</th>
            <th class="cell">Method</th>
            <th class="cell">Type</th>
            <th class="cell">Reason</th>
            <th class="cell">Id</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="disposal in selectedItem.disposals" :key="disposal._id" class="row">
            <td class="cell">
              <inline-button label="Open" type="plain-primary responsive-column form-height wide"
                @click="() => setSelectedDisposal(disposal)" :disabled="!canEdit" />
            </td>

            <td class="cell">
              <check-mark :toggleVariable="disposal.isArchived" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="disposal.isScheduled" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="disposal.isPostedToState" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="disposal.isPostedToProducts" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="disposal.isPostedToJournal" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="disposal.isRefund" />
            </td>
            <td class="cell">{{ disposal.quantity }}</td>
            <td class="cell">
              {{
                moment(disposal.disposalDate).format(
                  Constants.dates.defaultDateFormat
                )
              }}
            </td>
            <td class="cell">{{ disposal.room.name || disposal.room.label }}</td>
            <td class="cell">
              {{ disposal.method ? disposal.method.label : "" }}
            </td>
            <td class="cell">
              {{ disposal.type ? disposal.type.label : "" }}
            </td>
            <td class="cell">
              {{ disposal.reason ? disposal.reason.label : "-" }}
            </td>
            <td class="cell">
              <span>{{ disposal._id }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import Utilities from "@/lib/Utilities";

import { prefillEntityBeforeRendering } from "@/utils/prefill-entity-before-rendering/item";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/item";
import { disposalInputs } from "./inputs";

export default {
  name: "Disposals",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      Entities,
      disposalInputs,
    };
  },
  components: {
    Input,
    BaseBtn,
    About,
  },
  props: {
    isEdit: {
      type: Boolean,
    },
    rooms: {
      type: Array,
      default: () => ([])
    }
  },
  mounted: function () { },
  computed: {
    ...mapGetters({
      item: "itemModule/item",
    }),
    selectedItem: {
      get() {
        return prefillEntityBeforeRendering(this.item);
      },
      set(entity) {
        return entity;
      },
    },
    disposalTotal() {
      return (
        parseFloat(this.selectedItem.actualUnitCost || 0) *
        parseFloat(this.selectedItem.selectedDisposal.quantity || 0)
      );
    },
    canEdit() {
      return this.isEdit && !this.selectedItem?.selectedDisposal?.isArchived;
    },
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      setItem: "itemModule/setItem",
      saveItem: "itemModule/saveItem",
      loadingStatus: "itemModule/loadingStatus",
      getItemById: "itemModule/getItemById",
      setMessage: "setMessage",
    }),
    getValue(field) {
      if (field === "disposalTotal") {
        return this.disposalTotal;
      }
      return _.get(this.selectedItem, field);
    },
    setValue(field, value) {
      _.set(this.selectedItem, field, value);
    },
    getInput(field) {
      const input = this.disposalInputs.find((i) => i.id == field);
      if (field == "selectedDisposal.room") {
        input.options = this.rooms
      }
      return input
    },
    isNotEmpty(item) {
      return !_.isEmpty(item);
    },
    setSelectedDisposal(disposalItem) {
      const NEW_DISPOSAL = {
        _id: "NEW",
        isPostedToState: false,
        isPostedToProducts: false,
        isPostedToJournal: false,
        isArchived: false,
        isDisposalDateConfirmed: false,
        isScheduled: false,
        isRefund: false,
        creditIssued: false,
        quantity: 0,
        type: { label: "Internal", code: "internal" },
        reason: { label: "Waste", code: "1" },
        method: { label: "Compostable", code: "compostable" },
        room: {},
        supplier: {},
        disposalDate: moment(new Date()).format(this.Constants.dates.defaultDateFormat),
      };
      this.$set(this.selectedItem, "selectedReturn", undefined);
      this.$set(this.selectedItem, "selectedAdjustment", undefined);
      if (!disposalItem) {
        disposalItem = NEW_DISPOSAL
      } else {
        if (!disposalItem.room.label) {
          disposalItem.room = this.rooms.find(r => r.code == disposalItem.room._id)
        }
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#return").offset().top,
          },
          300
        );
      }
      this.$set(this.selectedItem, "selectedDisposal", disposalItem);
    },

    removeSelectedDisposal() {
      if (this.canEdit) {
        this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.unsavedChangesTitle,
          message: Constants.strings.unsavedChangesConfirmation,
          positiveCallback: () => {
            this.$set(this.selectedItem, "selectedDisposal", undefined);
          },
        });
      } else {
        this.$set(this.selectedItem, "selectedDisposal", undefined);
      }
    },

    performPostDisposal() {

      if (!this.selectedItem.selectedDisposal.isDisposalDateConfirmed) {
        this.setMessage({
          text: 'Please select "Confirm Disposal Date"',
          type: "error",
        });
        return
      }
      const disposalDate = this.selectedItem.selectedDisposal.disposalDate
      const local = moment(disposalDate);
      var today = moment(new Date());

      const isCurrentDay = local.isSame(today, 'day');
      if (!isCurrentDay) {
        this.setMessage({
          text: 'You must post this disposal on the scheduled date! No posting allowed.',
          type: "error",
        });
        return false;
      }

      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: Constants.strings.stateCommunicationConfirmation,
        positiveCallback: () => {
          this.selectedItem.postDisposal = true;
          this.performSave({ postDisposal: true });
        },
      });
    },

    performDisposalSchedule() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.selectedItem.selectedDisposal.isScheduled
          ? Constants.strings.unscheduleDisposalConfirmation
          : Constants.strings.scheduleDisposalConfirmation,
        positiveCallback: () => {
          this.selectedItem.scheduleDisposal = true;
          this.performSave();
        },
      });
    },

    archiveDisposal() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.selectedItem.selectedDisposal.isArchived
          ? Constants.strings.unArchiveConfirmation
          : Constants.strings.archiveConfirmation,
        positiveCallback: () => {
          this.selectedItem.archiveDisposal = true;
          this.performSave();
        },
      });
    },

    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
    },
    performSave(type) {
      if (this.loadingStatus.saveItem) {
        return;
      }

      const itemToSave = normalizeEntityBeforeSaving(this.selectedItem);
      this.saveItem({
        item: itemToSave,
        positiveCallback: (item) => {
          const id = item.id ? item.id : item._id;
          this.setItem(item);
          const module =
            this.entity !== Entities.PRODUCT
              ? `${this.entity}s`
              : `${this.entity}`;
          if (this.$route.query.id !== id) {
            this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
          }
          this.$store.dispatch(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (!type?.archived) this.$emit("toggle-edit");
        },
        negativeCallback: () => {
          this.selectedItem.postDisposal = false;
          if (type && type.postDisposal) {
            this.getItemById({
              _id: this.$route.query.id,
              positiveCallback: (item) => {
                this.setPageTitle(
                  `Item ${item.batchId} - ${item.product?.name}`
                );
              },
            });
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_disposals.scss";
</style>
