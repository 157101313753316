<template>
  <div>
    <div v-if="!isToast && message.isOpen" v-on:click.stop="close" class="overlay-div"></div>
    <div v-if="isToast" id="snackbar" :class="this.cssClass" v-html="this.message.text || defaultMessage"></div>
    <transition name="fade-fast" mode="out-in">
      <div v-if="!isToast && message.isOpen">
        <div class="message-box" :class="{
          'message-box--error': isDfError,
          'message-box--state-error': isStateError,
          'message-box--info': isInfo,
        }">
          <div class="message-box__header">
            <span v-if="isDfError || isInfo">{{
              this.message.title ? this.message.title : "Error"
            }}</span>
            <span v-if="isStateError">State Error</span>
          </div>
          <div class="message-box__body" v-html="this.message.text || defaultMessage"></div>

          <div class="message-box__footer">
            <BaseBtn v-if="this.message.errorType === 'no-workstation'" class="message-box__footer-button" iconGroup="fas"
              iconName="desktop" label="Select Workstation" :colorType="0" @click="gotoSelectWorkstation" />
            <BaseBtn class="message-box__footer-button" iconGroup="fas" iconName="times" label="Dismiss" :colorType="3"
              @click="close" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "Toast",
  props: {
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      cssClass: "hide",
      timeout: undefined,
      pulseType: true,
    };
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  created() {
    window.addEventListener(
      "keydown",
      this.keyDownHandler
    )
  },
  components: { BaseBtn },
  computed: {
    defaultMessage: function () {
      if (this.message.type === "error") return "Oops! There is an error";
      else return "Success!";
    },
    isInfo: function () {
      return this.message.type === "info";
    },
    isToast: function () {
      return this.message.type === "success";
    },
    isDfError: function () {
      return this.message.type === "error";
    },
    isStateError: function () {
      return this.message.type === "state-error";
    },
  },
  watch: {
    message: function (newMessage) {
      this.cssClass = "show " + this.message.type;

      if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
      }

      this.timeout = setTimeout(
        function () {
          this.cssClass = "hide " + this.message.type;
          this.timeout = undefined;
        }.bind(this),
        3000
      );
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
      setWorkstationSelectionPopUp: "posModule/setWorkstationSelectionPopUp",
    }),
    keyDownHandler(e) {
      if (e.keyCode === 27 || e.keyCode === 13) {
        this.close()
      }
    },
    close() {
      const newMessage = JSON.parse(JSON.stringify(this.message));
      newMessage.isOpen = false;
      this.setMessage(newMessage);
    },
    gotoSelectWorkstation() {
      this.close();
      this.setWorkstationSelectionPopUp({ isOpen: true });
    },
  },
};
</script>

<style lang="scss" scoped>
#snackbar {
  visibility: hidden;
  min-width: 250px;
  text-align: center;
  border-radius: $border-radius-0;
  padding: 16px;
  position: fixed;
  z-index: 10006;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}

.message-box {
  border-radius: $border-radius-0;
  position: fixed;
  z-index: 10006;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  min-width: 320px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(phone) {
    width: 80%;
  }

  &__header {
    font-weight: bold;
    font-size: $text-size-7;
    width: 100%;
    margin-bottom: 15px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 20px;
  }

  &__footer-button {
    width: 100%;
  }

  &--error {
    background-color: $dark-toast-error-background;
    color: $dark-toast-error-text;
  }

  &--state-error {
    background-color: $dark-toast-state-error-background;
    color: $dark-text-color-0;
  }

  &--info {
    background-color: $dark-background-color;
    color: $dark-toast-info-text;
  }

  &__body {
    white-space: pre-wrap;
  }
}

.error {
  background-color: $dark-toast-error-background;
  color: $dark-toast-error-text;
}

.light .message-box {
  border-radius: $border-radius-0;

  &--error {
    background-color: $light-toast-error-background;
    color: $light-toast-error-text;
  }

  &--state-error {
    background-color: $light-background-color;
    color: $light-state-color;
  }

  &--info {
    background-color: $light-background-color;
    color: $light-toast-info-text;
  }
}

.light .error {
  background-color: $light-toast-error-background;
  color: $light-toast-error-text;
}

.success {
  background-color: $dark-toast-success-background;
  color: $dark-toast-success-text;
}

.light .success {
  background-color: $light-toast-success-background;
  color: $light-toast-success-text;
}

.info {
  background-color: $dark-toast-info-background;
  color: $dark-toast-info-text;
}

.light .info {
  background-color: $light-toast-info-background;
  color: $light-toast-info-text;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

#snackbar.hide {
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
    visibility: visible;
  }

  to {
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
    visibility: visible;
  }

  to {
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
