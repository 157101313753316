<template>
  <BaseModal v-if="isPopUpMessageOpen" @toggle-modal="togglePopUpMessage" title="Customer Info" :autoWidth="true">
    <div class="info-container">
      <p v-if="(CCPAmount > 0)" class="balance">CCP Memo Balance: <strong>{{ Utilities.formatPrice(CCPAmount) }}</strong>
      </p>
      <p v-if="(PointsAmount > 0)" class="balance">Point Memo Balance:
        <strong>{{ Utilities.formatPrice(PointsAmount) }}</strong>
      </p>
      <p v-if="(PaymentAmount > 0)" class="balance">Payment Credit Memo Balance:
        <strong>{{ Utilities.formatPrice(PaymentAmount) }}</strong>
      </p>
      <p v-if="((CCPAmount > 0 || PointsAmount > 0 || PaymentAmount > 0) && messages && messages.length > 0)"
        class="messages-title">Messages:
      </p>
      <p v-for="(message, index) of messages" :key="`pop_up_message_${index}`" class="message">
        {{ message.note }}
      </p>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import Utilities from "@/lib/Utilities";

export default {
  props: {
    messages: {
      type: Array,
    },
    PaymentAmount: {
      type: Number,
      default: 0
    },
    CCPAmount: {
      type: Number,
      default: 0
    },
    PointsAmount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isPopUpMessageOpen: false,
      Utilities
    };
  },
  watch: {
    PaymentAmount(newVal, oldVal) {
      if (this.isPopUpMessageOpen) return;

      if (newVal > 0) {
        this.isPopUpMessageOpen = true;
        return;
      }
    },
    CCPAmount(newVal, oldVal) {
      if (this.isPopUpMessageOpen) return;

      if (newVal > 0) {
        this.isPopUpMessageOpen = true;
        return;
      }
    },
    PointsAmount(newVal, oldVal) {
      if (this.isPopUpMessageOpen) return;

      if (newVal > 0) {
        this.isPopUpMessageOpen = true;
        return;
      }
    },
    messages(newVal, oldVal) {
      if (this.isPopUpMessageOpen) return;

      if (!newVal) {
        this.isPopUpMessageOpen = false;
        return;
      }

      if (!oldVal || newVal.length > 0) {
        this.isPopUpMessageOpen = true;
      }
    },
  },
  components: {
    BaseModal,
  },
  methods: {
    togglePopUpMessage(event) {
      this.isPopUpMessageOpen = event.isOpen;
    },
  },
  mounted: function () {
    if (this.isPopUpMessageOpen) return;

    if (this.messages.length > 0) {
      this.isPopUpMessageOpen = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
