<template>
  <div id="workstation-selection-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div class="default-pop-up-center-div bordered">
      <div class="center">
        <span class="h1 padded">Workstation Selection</span>
      </div>
      <br />
      <br />
      <div v-if="workstationList.length > 0" class="workstation">
        <BaseBtn
          v-for="ws in workstationList"
          :key="ws._id"
          :label="ws.name"
          :colorType="ws.isSelected ? 5 : undefined"
          @click="() => selectWorkstation(ws)"
        />
      </div>
      <div v-else>No workstations for this store</div>
      <br />
      <div class="padded button-wrapper">
        <BaseBtn
          @click="handleNegativeAnswer"
          class="message-pop-up__button"
          label="Cancel"
          :colorType="3"
        />
        <BaseBtn
          @click="handlePositiveAnswer"
          class="message-pop-up__button"
          label="Submit"
          :disabled="!isWsSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "WorkstationSelectionPopUp",
  data() {
    return {
      Constants: Constants,
      workstationList: [],
    };
  },
  mounted() {
    this.getCurrentStore();
    this.populateWorkstations();
  },
  watch: {
    currentStore(updatedStore) {
      console.log("updated");
      this.populateWorkstations();
    },
  },
  components: {
    BaseBtn,
  },
  computed: {
    ...mapGetters({
      workstationSelectionPopUp: "posModule/workstationSelectionPopUp",
      currentStore: "storeModule/currentStore",
      selectedWorkstation: "posModule/selectedWorkstation",
    }),
    isWsSelected() {
      var result = false;
      this.workstationList.forEach((ws) => {
        if (ws.isSelected) {
          result = true;
        }
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      getCurrentStore: "storeModule/getCurrentStore",
      setWorkstationSelectionPopUp: "posModule/setWorkstationSelectionPopUp",
      setSelectedWorkstation: "posModule/setSelectedWorkstation",
    }),
    populateWorkstations() {
      if (
        this.currentStore.workstations &&
        this.currentStore.workstations.length > 0
      ) {
        this.workstationList = JSON.parse(
          JSON.stringify(this.currentStore.workstations)
        );

        this.workstationList = this.workstationList.filter(
          (ws) => ws.isFulfillment == false
        ).sort((a, b) => a.name.localeCompare(b.name));

        if (this.selectedWorkstation) {
          const ws = this.workstationList.find(
            (ws) => ws._id == this.selectedWorkstation._id
          );
          if (ws) {
            ws.isSelected = true;
          }
        }
      }
      window.addEventListener(
        "keyup",
        function (e) {
          if (e.keyCode === 27) {
            this.handleNegativeAnswer();
          }
        }.bind(this)
      );
    },
    selectWorkstation(ws) {
      this.workstationList.forEach((ws) => {
        this.$set(ws, "isSelected", false);
      });
      this.$set(ws, "isSelected", true);
    },
    handlePositiveAnswer: function () {
      const selectedWorkstation = this.workstationList.find(
        (ws) => ws.isSelected
      );
      this.setSelectedWorkstation(selectedWorkstation);
      this.setWorkstationSelectionPopUp({});
    },
    handleNegativeAnswer: function () {
      this.setWorkstationSelectionPopUp({});
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;

  & > button {
    width: 50%;
  }
}

.pin-input {
  width: 160px !important;
  text-align: center;
  font-weight: bold;
}

.drawer-input {
  width: 160px !important;
  text-align: center;
  display: inline-block;
}

.numeric-keyboard-container {
  text-align: center;
}

.numeric-key {
  display: inline-block;
  background-color: $dark-elevated-0;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  //margin: 5px;
  border-radius: 2px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.light .numeric-key {
  background-color: $light-elevated-0;
}

.inputs {
  max-width: 200px;
  margin: auto;
}

.workstation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;

  & > * {
    justify-self: stretch;
  }

  @include respond(phone) {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
