const isEmpty = (obj) => {
  return !obj || JSON.stringify(obj.msoChartOfAccount) === JSON.stringify({});
};
export const normalizeEntityBeforeSaving = (chartOfAccount) => {
  const entity = JSON.parse(JSON.stringify(chartOfAccount));
  entity.room = entity.room ? entity.room._id : "";
  entity.msoChartOfAccount = isEmpty(entity.msoChartOfAccount)
    ? null
    : entity.msoChartOfAccount;
  if (
    entity.msoChartOfAccount &&
    Object.keys(entity.msoChartOfAccount).length === 0
  ) {
    entity.msoChartOfAccount = undefined;
  }
  return entity;
};
