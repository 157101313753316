import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedGroup } from "@/views/Group/inputs";
import { searchEntities } from "@/services/entityService";
import Entities from "@/resources/Entities";

const state = {
  groups: [],
  group: { ...defaultSelectedGroup },
  loadingStatus: {
    saveGroup: false,
    getGroups: false,
    getGroupById: false,
    deleteGroup: false,
  },
  groupOrderingPopUp: {
    isOpen: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setGroups: (state, payload) => {
    state.groups = payload;
  },
  setGroup: (state, payload) => {
    const defaultCopy = JSON.parse(JSON.stringify(defaultSelectedGroup));

    if (payload._id === "NEW") {
      searchEntities({
        filterCriteria: "isDefault",
        filterValue: "true",
        entity: Entities.ROOM,
        skip: 0,
        limit: 1,
        positiveCallback: (data) => {
          state.group = {
            ...defaultCopy,
            room: data?.[0],
          };
        },
      });
    } else state.group = { ...defaultCopy, ...payload };
  },
  setGroupOrderingPopUpOpen: (state, value) => {
    state.groupOrderingPopUp.isOpen = value;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  groups: (state) => state.groups,
  group: (state) => state.group,
  loadingStatus: (state) => state.loadingStatus,
  groupOrderingPopUp: (state) => state.groupOrderingPopUp,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveGroup", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveGroup,
      payload.group,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveGroup", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteGroup", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteGroup,
      payload.group,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteGroup", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getGroups: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingStatus", { api: "getGroups", status: true });
      let url = Apis.backendBaseUrl + Apis.getGroups + "?";

      if (payload?.filterCriteria && payload?.filterValue) {
        url +=
          `&filterCriteria=${payload.filterCriteria}` +
          `&filterValue=${payload.filterValue}`;
      }

      HttpService.get(url, function (response, error) {
        commit("setLoadingStatus", { api: "getGroups", status: false });
        var groups = {};

        if (response) {
          groups = response.data.body;
          resolve(groups);
          commit("setGroups", groups);
          return;
        }

        reject(error);
      });
    });
  },
  getGroupById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getGroupById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getGroupById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getGroupById", status: false });
        if (response) {
          commit("setGroup", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentGroup", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "group/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjacentGroup", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  updateGroupOrdering: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      const url = Apis.backendBaseUrl + Apis.group.updateOrdering;

      HttpService.post(url, payload, (response, error) => {
        if (response) {
          const groups = response.data.body;
          const id = state.group?._id || null;
          if (id) {
            const index = groups
              .map(function (x) {
                return x._id;
              })
              .indexOf(id);
            const newReceiptOrder = groups[index].receiptDisplayOrder;
            const updatedGroup = {
              ...state.group,
              receiptDisplayOrder: newReceiptOrder,
            };
            commit("setGroup", updatedGroup);
          }
          commit("setGroups", groups);

          commit(
            "setMessage",
            { text: "Updated", type: "success" },
            { root: true }
          );

          resolve(response);
        } else {
          reject(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      });
    });
  },
  setGroupOrderingPopUpOpen({ commit }, payload) {
    commit("setGroupOrderingPopUpOpen", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
