import _ from "lodash";

export const validateEntityBeforeSaving = (entity, options) => {
  let validationResult = {
    succeeded: true,
    message: "",
    isWarning: false,
  };

  if (
    entity.carouselEntries &&
    entity.carouselEntries.length > 0
  ) {
    const entryWithoutImages = entity.carouselEntries.find(e => _.isEmpty(e.imageDesktop) && _.isEmpty(e.imageMobile))

    if (entryWithoutImages) {
      validationResult.message =
        "All carousel entries must have at least one picture.";
      validationResult.succeeded = false
      return validationResult;
    }
  }

  return validationResult;
};
