<template>
  <div class="pos-product-browser">
    <div class="pos-product-browser__breadcrumb-container">
      <div class="pos-product-browser__breadcrumb">
        <span
          @click="reset(0)"
          class="btn"
          :class="{
            'btn--disabled': !selectedLevel1,
          }"
        >
          <font-awesome-icon :icon="['fas', 'sitemap']" />
        </span>
        <span
          v-if="selectedLevel1"
          @click="reset(1)"
          class="btn"
          :class="{
            'btn--disabled': !selectedLevel2,
          }"
          >{{ selectedLevel1.name }}</span
        >
        <span v-if="selectedLevel2" class="btn--disabled">{{
          selectedLevel2.name
        }}</span>
      </div>
      <div class="pos-product-browser__cannabis-toggle">
        <span
          @click="cannabisToggle"
          class="btn"
          :class="{
            'btn--disabled--toggle': !isCannabis,
          }"
        >
          <font-awesome-icon :icon="['fas', 'cannabis']" />
        </span>
      </div>
    </div>
    <transition name="fade-fast" mode="out-in">
      <div class="pos-items-box" v-if="showLoading">
        <div class="categories">
          <div
            class="
              category-box category-box--loading
              pulse-background
              elevated-1
              padded
              bordered
            "
            v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :key="`${i}-button`"
          >
            <span class="pulse-text"> </span>
          </div>
        </div>
      </div>
      <div class="pos-items-box" v-else>
        <div v-if="selectedLevel1 === undefined" class="categories">
          <div
            class="category-box elevated-1 padded bordered"
            v-for="i in level0"
            :key="i._id"
            @click="
              () => $emit('set-level', { level: 'selectedLevel1', value: i })
            "
          >
            <span class="no-interaction">
              <b>{{ i.name }}</b>
            </span>
          </div>
        </div>

        <div v-if="selectedLevel1 && !selectedLevel2" class="categories">
          <div
            class="category-box elevated-1 padded bordered"
            v-for="i in level1"
            :key="i._id"
            @click="
              () => $emit('set-level', { level: 'selectedLevel2', value: i })
            "
          >
            <span class="no-interaction">
              <b>{{ i.name }}</b>
            </span>
          </div>
        </div>

        <div
          v-if="selectedLevel1 && selectedLevel2"
          class="category-product-container"
        >
          <div class="product-table" :class="{'product-table--fixed-height': isProductFixedHeight}">
            <div
              class="row elevated-1 cart-tem pointer"
              v-for="p in level2"
              :class="{ 'pulse-background': p.isLoading }"
              :key="p._id"
              @click="() => $emit('select-product', p)"
            >
              <div class="cell-header">
                <b>{{ p.name }}</b>
                <span>{{ p.brandName }}</span>
              </div>

              <div class="cell flex">
                <div style="flex: 1">
                  <span class="text-color-1 text-thin">Quantity</span>&nbsp;
                  <b>{{ p.availableQuantity }}</b>
                </div>
                <div>
                  <b>{{
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(getPrice(p))
                  }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PosProductBrowser",
  components: { FontAwesomeIcon },
  props: {
    selectedLevel1: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    selectedLevel2: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    itemHierarchy: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    order: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    isCannabis: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    forceOrderType: {
      type: String,
    },
    isProductFixedHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    showLoading() {
      if (this.isLoading && !this.selectedLevel2) {
        return true;
      } else {
        return false;
      }
    },
    level0() {
      const level0 = [...this.itemHierarchy.children];
      const sorted = level0.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return sorted;
    },
    level1() {
      const level1 = [...this.selectedLevel1.children];
      const sorted = level1.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return sorted;
    },
    level2() {
      const level2 = [...this.selectedLevel2.children];
      const sorted = level2.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return sorted;
    },
  },
  methods: {
    reset(level) {
      switch (level) {
        case 0:
          this.$emit("set-level", { level: "selectedLevel1" });
          this.$emit("set-level", { level: "selectedLevel2" });
          break;

        case 1:
          this.$emit("set-level", { level: "selectedLevel2" });
          break;

        default:
          break;
      }
    },
    cannabisToggle() {
      this.$emit("cannabis-toggle");
    },
    getPrice(product) {
      if (this.forceOrderType) {
        if (this.forceOrderType == "medical") {
          return product.medicalPrice;
        } else {
          return product.recreationalPrice;
        }
      } else {
        if (this.order.type == "medical") {
          return product.medicalPrice;
        } else {
          return product.recreationalPrice;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_posProductBrowser.scss";
</style>
