<template>
  <div id="order-type-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div center bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Order Type</span> <br />
      <span class="text-color-1"
        >The selected customer is Medical and Recreational, please select the
        type of order they want to perform.</span
      >
      <br />

      <div class="padded button-wrapper">
        <BaseBtn
          v-if="currentStore.isMedical"
          @click="() => performTypeSelection({ type: 'medical' })"
          label="Medical"
        />
        <BaseBtn
          v-if="currentStore.isRecreational"
          @click="() => performTypeSelection({ type: 'recreational' })"
          label="Recreational"
        />
        <BaseBtn @click="handleNegativeAnswer" label="Cancel" :colorType="3" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "OrderTypePopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
    };
  },
  components: { BaseBtn },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      orderTypePopUp: "posModule/orderTypePopUp",
      currentStore: "storeModule/currentStore",
    }),
  },
  methods: {
    ...mapActions({
      setOrderTypePopUp: "posModule/setOrderTypePopUp",
    }),
    handleNegativeAnswer: function () {
      this.setOrderTypePopUp({ isOpen: false });
    },
    performTypeSelection: function (selection) {
      if (this.orderTypePopUp.positiveCallback) {
        this.orderTypePopUp.positiveCallback(selection);
      }
      this.setOrderTypePopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;

  & > button {
    width: 50%;
  }
}

.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;

    .button-secondary {
      margin-bottom: 10px;
    }
  }
}
</style>
