<template>
  <div class="create-ccp-modal">
    <div class="create-ccp-modal__dates">
      <div class="input-text">
        <div class="input-text__label">From</div>
        <Calendar v-model="selectedPeriod.from" :minDate="minDateValue" :placeholder="'From'" view="month"
          dateFormat="mm/yy" class="ccp-calendar" />
      </div>
      <div class="input-text">
        <div class="input-text__label">To</div>
        <Calendar v-model="selectedPeriod.to" :placeholder="'To'" view="month" dateFormat="mm/yy" class="ccp-calendar" />
      </div>
    </div>
    <div class="create-ccp-modal__action">
      <BaseBtn @click="createCCP" label="Create" :disabled="!isRangeValid" />
    </div>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Calendar,
    BaseBtn,
  },
  data() {
    return {
      selectedPeriod: {
        from: new Date(),
        to: new Date(),
      },
    };
  },
  computed: {
    isRangeValid() {
      return (
        this.isValidDate(this.selectedPeriod.from) &&
        this.isValidDate(this.selectedPeriod.to)
      );
    },
    minDateValue() {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
  },
  methods: {
    ...mapActions({
      performCreateCCP: "creditMemoModule/createCCP",
    }),
    formatFromDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    formatToDate(date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
    async createCCP() {
      await this.performCreateCCP({
        from: this.formatFromDate(this.selectedPeriod.from),
        to: this.formatToDate(this.selectedPeriod.to),
      })
        .then(() => {
          this.$emit("close", false);
        })
        .catch(() => { });
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
