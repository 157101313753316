<template>
  <div class="archive-modal">
    <div class="header">
      <MessageBox level="warning">
        Please, archive all orders only when all stations are closed.
      </MessageBox>
    </div>
    <div v-if="isLoading" class="center">
      Loading...
    </div>
    <div v-else-if="openOrders.length == 0" class="center">
      No open orders to archive
    </div>
    <div v-else class="body">
      <PercentageBar :value="archivedCount || 0" :maximum="openOrders.length || 0" title="Archived orders" />
    </div>
    <div class="footer">
      <BaseBtn iconGroup="fas" iconName="times" :colorType="3" label="Cancel" @click="onCancel()" />
      <BaseBtn iconGroup="fas" iconName="trash" :colorType="1" label="Archive All" @click="archiveAll()"
        :disabled="disable" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import MessageBox from "@/components/MessageBox/MessageBox.vue";
import PercentageBar from "@/components/PercentageBar/PercentageBar.vue";

export default {
  components: {
    BaseBtn,
    PercentageBar,
    MessageBox
  },
  data() {
    return {
      openOrders: [],
      archivedCount: 0,
      errorArchived: 0,
      archiving: false
    }
  },
  computed: {
    ...mapGetters({
      loadingStatus: "orderModule/loadingStatus",
      filterButtons: "orderModule/filterButtons",
    }),
    disable() {
      return this.isLoading || this.openOrders.length == 0 || this.percentage == 100;
    },
    isLoading() {
      return this.loadingStatus.getAllOpenOrders || this.archiving;
    },
    filter() {
      const filterInfo = this.filterButtons.find(f => f.name == 'open-orders');
      return filterInfo.filter;
    },
    percentage() {
      if (this.openOrders.length == 0) return 0;
      const num = this.archivedCount + this.errorArchived;
      const den = this.openOrders.length;

      return num / den * 100;
    }
  },
  methods: {
    ...mapActions({
      getOpenOrders: 'orderModule/getAllOpenOrders',
      archiveOpenOrder: "orderModule/archiveOpenOrder",
      updateItemPreOrderCount: "itemModule/updateItemPreOrderCount",
      setMessage: "setMessage",
    }),
    onCancel() {
      this.$emit('onClose')
    },
    async archiveAll() {
      this.archiving = true;
      let textMessage = "Open orders are archived!"
      let blockedError = false;

      for (let i = 0, blockingError = false; i < this.openOrders.length && !blockingError; i++) {
        const order = this.openOrders[i];
        try {
            await this.archiveOpenOrder(order);
            this.archivedCount++;
        } catch(error) {
            //console.log(error)
            blockedError = true;
            textMessage = "Please select workstation! Open orders are NOT archived."
            blockingError = error.errorCode == 1004
            this.errorArchived++;
            this.archiving = false;
            this.onCancel()
        }
      }

      if (!blockedError){ await this.updateItemPreOrderCount() }

      this.archiving = false;
      this.$emit('reload');
      this.setMessage({
        text: textMessage,
        type: "success",
      });
    }
  },
  mounted() {
    this.getOpenOrders({
      positiveCallback: (data) => this.openOrders = data,
    })
  }
}
</script>

<style scoped lang="scss">
@import './_archiveModal.scss';
</style>
