var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Cash Reconciliation By Employee","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
      {
        name: 'cashReconciliationByEmployee',
        label: 'Cash Reconciliation by Employee',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false,"filterByStoreOrOrg":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.formattedData),function(data,index){return _c('tr',{key:index,class:{ total: data.isTotal }},[_c('td',[_vm._v(_vm._s(data.date))]),(!data.isTotal)?_c('td',[_vm._v(_vm._s(data.employeeId))]):_c('td',[_vm._v("Total")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.cashPayment + data.cashPaymentReversed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.debitPayment + data.debitPaymentReversed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.creditMemoPayment + data.creditMemoPaymentReversed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.giftCardPayment + data.giftCardPaymentReversed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.order + data.orderReversed)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.formatAmount(data.orderRefund + data.orderRefundReversed)))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Cash Payment")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Debit Payment")]),_c('th',{attrs:{"align":"right"}},[_vm._v("CM Payment")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Gift Card Payment")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Sales Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Refunds")])])])}]

export { render, staticRenderFns }