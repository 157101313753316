import { render, staticRenderFns } from "./ArchiveModal.vue?vue&type=template&id=027a8270&scoped=true&"
import script from "./ArchiveModal.vue?vue&type=script&lang=js&"
export * from "./ArchiveModal.vue?vue&type=script&lang=js&"
import style0 from "./ArchiveModal.vue?vue&type=style&index=0&id=027a8270&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027a8270",
  null
  
)

export default component.exports