var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'page-header-base ' +
  (!_vm.pageHeader.isEditorView ? 'page-header-base__list-view' : ''),attrs:{"id":"page-header-wrapper"}},[(_vm.pageHeader.isEditorView)?_c('EntitySubMenu',{attrs:{"entity":_vm.entity,"isDisabled":_vm.isEdit},on:{"menu-option-click":function (entity, action) { return _vm.$emit('menu-option-click', entity, action); }}}):_vm._e(),(_vm.pageHeader.isEditorView)?_c('div',{staticClass:"editor-section__button-row editor-section-mode margin-bottom-sm"},[_c('div',{class:'flex editor-section__action-icons ' +
      (_vm.isNew || _vm.isEditVal ? 'editor-section__disabled' : '')},[_c('font-awesome-icon',{staticClass:"editor-section__icon-left",attrs:{"icon":['fas', 'angle-double-left']},on:{"click":function($event){return _vm.handleAdjacent('first')}}}),_c('font-awesome-icon',{staticClass:"editor-section__icon-left",attrs:{"icon":['fas', 'angle-left']},on:{"click":function($event){return _vm.handleAdjacent('previous')}}}),_c('font-awesome-icon',{staticClass:"editor-section__icon-left",attrs:{"icon":['fas', 'angle-right']},on:{"click":function($event){return _vm.handleAdjacent('next')}}}),_c('font-awesome-icon',{staticClass:"editor-section__icon-left",attrs:{"icon":['fas', 'angle-double-right']},on:{"click":function($event){return _vm.handleAdjacent('last')}}})],1),_c('div',{staticClass:"flex editor-section__action-icons"},[(_vm.selectedEntity.isDeleted)?_c('span',{staticClass:"editor-section__deleted"},[_c('font-awesome-icon',{staticClass:"editor-section__icon-right",attrs:{"icon":['fas', 'ban']}}),_c('span',[_vm._v("Is Deleted")])],1):_vm._e(),(_vm.selectedEntity.isArchived)?_c('span',{staticClass:"editor-section__deleted"},[_c('font-awesome-icon',{staticClass:"editor-section__icon-right",attrs:{"icon":['fas', 'ban']}}),_c('span',[_vm._v("Is Archived")])],1):_vm._e(),(_vm.pageHeader.cancelPath !== 'organization')?_c('font-awesome-icon',{class:'editor-section__icon-right ' +
        (_vm.searchDisabled ? 'editor-section__disabled' : ''),attrs:{"icon":['fas', 'search']},on:{"click":_vm.toggleSearchModal}}):_vm._e(),_c('div',{staticClass:"editor-section__icon-right",attrs:{"data-cy":"section-toggle"},on:{"click":function($event){return _vm.toggleSections()}}},[(_vm.isCollapsed)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'angle-double-up']}}):_vm._e(),(!_vm.isCollapsed)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'angle-double-down']}}):_vm._e()],1)],1)]):_vm._e(),_c('div',{staticClass:"page-header"},[_c('div',[_c('div',{staticClass:"bread-crumb h1"},[_vm._v(" "+_vm._s(_vm.pageHeader.title)+" "+_vm._s(_vm.selectedEntity.name ? _vm.selectedEntity.name : "")+" ")]),_c('BreadCrumb',{staticClass:"no-print",attrs:{"links":_vm.links}})],1),_c('div',{staticClass:"button-wrapper"},[_vm._t("default"),(_vm.pageHeader.isEditorView && _vm.isEditVal)?_c('BaseBtn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ saveWindows: ['ctrl', 's'], saveMac: ['meta', 's'] }),expression:"{ saveWindows: ['ctrl', 's'], saveMac: ['meta', 's'] }"}],attrs:{"data-cy":"save-button","label":"Save","iconGroup":"fas","iconName":"save","isLoading":_vm.isLoading,"disabled":_vm.disable},on:{"click":function($event){return _vm.saveEntity()}},nativeOn:{"shortkey":function($event){return _vm.saveEntity()}}}):_vm._e(),(
        !_vm.hideAddButton &&
        _vm.pageHeader.isEditorView &&
        !_vm.isNew &&
        !_vm.isEditVal &&
        _vm.pageHeader.cancelPath !== 'organization'
      )?_c('BaseBtn',{attrs:{"data-cy":"add-button","label":"Add","iconGroup":"fas","iconName":"plus","isLoading":_vm.isLoading,"disabled":_vm.disable},on:{"click":function($event){return _vm.refreshPage()}}}):_vm._e(),(_vm.pageHeader.isEditorView && !_vm.isEditVal && _vm.canEdit)?_c('BaseBtn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ saveWindows: ['ctrl', 'e'], saveMac: ['meta', 'e'] }),expression:"{ saveWindows: ['ctrl', 'e'], saveMac: ['meta', 'e'] }"}],attrs:{"data-cy":"edit-button","label":"Edit","iconGroup":"fas","iconName":"pen","isLoading":_vm.isLoading,"disabled":_vm.disable},on:{"click":function($event){return _vm.$emit('toggle-edit')}},nativeOn:{"shortkey":function($event){return _vm.$emit('toggle-edit')}}}):_vm._e(),(_vm.isNew)?_c('router-link',{attrs:{"to":{ name: _vm.pageHeader.cancelPath }}},[(_vm.pageHeader.isEditorView && _vm.isEditVal)?_c('BaseBtn',{attrs:{"label":"Cancel","iconGroup":"fas","iconName":"times","colorType":3,"isLoading":_vm.isLoading,"disabled":_vm.disable}}):_vm._e()],1):(_vm.pageHeader.isEditorView && _vm.isEditVal)?_c('BaseBtn',{attrs:{"label":"Cancel","iconGroup":"fas","iconName":"times","colorType":3,"isLoading":_vm.isLoading,"disabled":_vm.disable},on:{"click":function($event){_vm.handleCancel(), _vm.setIsEdit()}}}):_vm._e(),(
        _vm.pageHeader.isEditorView &&
        _vm.entity !== 'organization' &&
        !_vm.enableDelete[_vm.entity] &&
        _vm.canArchive
      )?_c('BaseBtn',{attrs:{"data-cy":"archive-button","label":_vm.selectedEntity.isArchived ? 'Unarchive' : 'Archive',"iconGroup":"fas","iconName":"trash","colorType":1,"isLoading":_vm.isLoading,"disabled":_vm.disable || _vm.isEditVal || _vm.isNew},on:{"click":_vm.handleArchive}}):_vm._e(),(
        _vm.pageHeader.isEditorView &&
        _vm.entity !== 'organization' &&
        _vm.enableDelete[_vm.entity]
      )?_c('BaseBtn',{attrs:{"data-cy":"delete-button","label":"Delete","iconGroup":"fas","iconName":"trash","colorType":1,"isLoading":_vm.isLoading,"disabled":_vm.disable ||
          _vm.disableDelete ||
          _vm.isEditVal ||
          _vm.isNew ||
          _vm.selectedEntity.isDeleted},on:{"click":_vm.handleDelete}}):_vm._e(),(
        !_vm.pageHeader.isEditorView &&
        !_vm.hideAddButton &&
        _vm.pageHeader.routerPath !== 'customerCheckIn' &&
        _vm.pageHeader.routerPath !== 'settings' &&
        _vm.pageHeader.routerPath !== 'todaysMenu' &&
        _vm.pageHeader.routerPath !== 'stateInventory'
      )?_c('BaseBtn',{attrs:{"data-cy":"new-button","label":"Add","iconGroup":"fas","iconName":"plus","disabled":_vm.disable},on:{"click":function () { return _vm.handleAddNew(_vm.pageHeader.routerPath); }}}):(_vm.pageHeader.isEditorView && !_vm.isEditVal && _vm.canPost)?_c('BaseBtn',{staticStyle:{"margin-left":"1rem"},attrs:{"label":"Post","iconGroup":"fas","iconName":"angle-up","colorType":2,"isLoading":_vm.isLoading,"disabled":_vm.disable},on:{"click":function($event){return _vm.$emit('post')}}}):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }