<template>
  <div :class="`message-box message-box--${level} `">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "MessageBox",
  data() {
    return {

    };
  },
  props: {
    level: {
      type: String,
      default: 'default'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./_messageBox.scss";
</style>