import _ from "lodash";
import moment from "moment";

export const validateFields = (item, field, validationErrors) => {
  if (
    field !== "startDate" &&
    field !== "endDate" &&
    field !== "applyDateRange"
  )
    return;

  _.set(validationErrors, "applyDateRange", { isInvalid: false });

  if (!item.applyDateRange) return;

  if (!item.startDate && !item.endDate) {
    _.set(validationErrors, "applyDateRange", {
      isInvalid: true,
      message: "A start or end date is required to apply date range",
    });
  }
  if (item.startDate && item.endDate) {
    if (moment(item.startDate).isAfter(item.endDate)) {
      _.set(validationErrors, "endDate", {
        isInvalid: true,
        message: "End date must be after start date",
      });
    } else {
      _.set(validationErrors, "endDate", { isInvalid: false });
    }
  } else {
    _.set(validationErrors, "endDate", { isInvalid: false });
  }

  return validationErrors;
};
