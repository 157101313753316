<template>
  <div class="todaysMenu responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Today's Menu"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'todaysMenu', label: 'Today\'s Menu' },
        ]"
        buttonLabel="New"
        routerPath="todaysMenu"
        :pageHeader="listPageHeader"
        :hideAddButton="true"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        @submit-filter="performGetReport"
        :isLoading="loadingStatus.getTodaysMenu"
      />
      <div
        v-for="g in filteredTodaysMenu"
        :key="g._id"
      >
        <div class="section-base section-base--no-margin-bottom">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">{{ g.name }}</div>
          </div>
        </div>
        <div class="table-container margined bordered">
          <table cellspacing="0" class="table-element">
            <thead>
              <tr class="header">
                <th class="cell">Product</th>
                <th class="cell">Cultivator</th>
                <th class="cell">Variety</th>
                <th class="cell">Size [g]</th>
                <th
                  class="cell"
                  v-if="filter.selectedReportSaleType.includes('medical')"
                >
                  Medical
                  <br />Price
                </th>
                <th
                  class="cell"
                  v-if="filter.selectedReportSaleType.includes('rec')"
                >
                  Recreational
                  <br />Price
                </th>
                <th class="cell">THC</th>
                <th class="cell">CBD</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="loadingStatus.GetTodaysMenu && todaysMenu.length === 0"
                class="row"
              >
                <td colspan="100" class="center padded">
                  <i class="fas fa-spin fa-circle-notch"></i> Loading...
                </td>
              </tr>

              <tr class="row" v-for="i in g.nodes" :key="i._id">
                <td class="cell">{{ i.name }}</td>
                <td class="cell">{{ i.externalFields.supplier.name }}</td>
                <td class="cell">{{ i.variety }}</td>
                <td class="cell">{{ Utilities.formatWeight(i.cannabisGramsUnit, i.externalFields.group.onlineStoreWeightUoM) }}</td>
                <td
                  class="cell"
                  v-if="filter.selectedReportSaleType.includes('medical')"
                >
                  {{ Utilities.formatPrice(i.medicalPrice) }}
                </td>
                <td
                  class="cell"
                  v-if="filter.selectedReportSaleType.includes('rec')"
                >
                  {{ Utilities.formatPrice(i.recreationalPrice) }}
                </td>
                <td class="cell">{{ i.thc }}%</td>
                <td class="cell">{{ i.cbd }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapActions, mapGetters } from "vuex";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import _ from "lodash";
import Utilities from "@/lib/Utilities";

export default {
  name: "todaysMenu",
  data() {
    return {
      listPageHeader: {
        title: "Today's menu",
        isEditorView: false,
      },
      Utilities: Utilities,
      filter: {selectedReportSaleType: ''},
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Today's Menu");
  },
  computed: {
    ...mapGetters({
      loadingStatus: "todaysMenuModule/loadingStatus",
      todaysMenu: "todaysMenuModule/todaysMenu",
    }),
    filteredTodaysMenu() {
      return this.todaysMenu?.nodes || [];
    },
  },
  methods: {
    ...mapActions({ getTodaysMenu: "todaysMenuModule/getTodaysMenu" }),
    performGetReport(filter) {
      this.filter = filter

      this.getTodaysMenu(filter)
    },
  },
};
</script>
