import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";
import Apis from "@/services/Apis";

mapGetters({
  loadingStatus: "employeeModule/loadingStatus",
  employee: "employeeModule/employee",
});

mapActions({
  getEmployeeById: "employeeModule/getEmployeeById",
  saveEmployee: "employeeModule/saveEmployee",
  deleteEmployee: "employeeModule/deleteEmployee",
  getStores: "storeModule/getStores",
  setMessagePopUp: "setMessagePopUp",
  setMessage: "setMessage",
  getAdjacentEmployee: "employeeModule/getAdjacentEmployee",
  setEmployee: "employeeModule/setEmployee",
});

export const entityName = {
  [Entities.EMPLOYEE]: "Employee",
  [Entities.GROUP]: "Group",
  [Entities.CUSTOMER]: "Customer",
  [Entities.PRODUCT]: "Product",
  [Entities.ITEM]: "Item",
  [Entities.ONLINESTOREGROUP]: "Online Store Group",
  [Entities.MSOGROUP]: "MSO Group",
  [Entities.MSOSUPPLIER]: "MSO Supplier",
  [Entities.MSOPRODUCT]: "MSO Product",
  [Entities.MSOCHARTOFACCOUNT]: "MSO Chart of Account",
  [Entities.CHARTOFACCOUNT]: "Chart of Account",
  [Entities.ORDER]: "Order",
  [Entities.ORGANIZATION]: "Organization",
  [Entities.PURCHASEORDER]: "Purchase Order",
  [Entities.ROOM]: "Room",
  //[Entities.SPECIALPRICING]: "Special Price",
  [Entities.SPECIALPRICING]: "Special Pricing",
  [Entities.STORE]: "Store",
  [Entities.SUPPLIER]: "Supplier",
  [Entities.SUPPLIERAGENT]: "Supplier Agent",
  [Entities.GIFTCARD]: "Gift Card",
  [Entities.CREDITMEMO]: "Credit Memo",
  [Entities.COUPON]: "Coupon",
  [Entities.MSOCOUPON]: "Mso Coupon",
  [Entities.PAYMENT]: "Payment",
  [Entities.TAXSETUP]: "Tax Setup",
  [Entities.FLIGHT]: "Flight",
  [Entities.JOURNAL]: "Journal",
  [Entities.TIMESHEET]: "TimeSheet",
  [Entities.QUEUE]: "Queue Customer",
  [Entities.RETURNITEM]: "Return Item",
  [Entities.FULLFILLMENT]: "Fulfillment Order",
  [Entities.CASHRECONCILIATION]: "Cash Reconciliation",
  [Entities.INVENTORYCOUNT]: "Inventory Count",
  [Entities.BRAND]: "Brand",
};

const entityApi = {
  [Entities.CUSTOMER]: `${Entities.CUSTOMER}`,
  [Entities.EMPLOYEE]: `${Entities.EMPLOYEE}`,
  [Entities.GROUP]: `${Entities.GROUP}`,
  [Entities.PRODUCT]: `${Entities.PRODUCT}`,
  [Entities.ITEM]: `${Entities.ITEM}`,
  [Entities.ONLINESTOREGROUP]: `${Entities.ONLINESTOREGROUP}`,
  [Entities.MSOGROUP]: `${Entities.MSOGROUP}`,
  [Entities.MSOSUPPLIER]: `${Entities.MSOSUPPLIER}`,
  [Entities.MSOPRODUCT]: `${Entities.MSOPRODUCT}`,
  [Entities.MSOCHARTOFACCOUNT]: `${Entities.MSOCHARTOFACCOUNT}`,
  [Entities.CHARTOFACCOUNT]: `${Entities.CHARTOFACCOUNT}`,
  [Entities.ORDER]: `${Entities.ORDER}`,
  [Entities.ORGANIZATION]: `${Entities.ORGANIZATION}`,
  [Entities.PURCHASEORDER]: `${Entities.PURCHASEORDER}`,
  [Entities.ROOM]: `${Entities.ROOM}`,
  [Entities.SPECIALPRICING]: `${Entities.SPECIALPRICING}`,
  [Entities.STORE]: `${Entities.STORE}`,
  [Entities.SUPPLIER]: `${Entities.SUPPLIER}`,
  [Entities.SUPPLIERAGENT]: `${Entities.SUPPLIERAGENT}`,
  [Entities.GIFTCARD]: `${Entities.GIFTCARD}`,
  [Entities.CREDITMEMO]: `${Entities.CREDITMEMO}`,
  [Entities.COUPON]: `${Entities.COUPON}`,
  [Entities.MSOCOUPON]: `${Entities.MSOCOUPON}`,
  [Entities.PAYMENT]: `${Entities.PAYMENT}`,
  [Entities.TAXSETUP]: `${Entities.TAXSETUP}`,
  [Entities.FLIGHT]: `${Entities.FLIGHT}`,
  [Entities.JOURNAL]: `${Entities.JOURNAL}`,
  [Entities.TIMESHEET]: `${Entities.TIMESHEET}`,
  [Entities.QUEUE]: `${Entities.QUEUE}`,
  [Entities.RETURNITEM]: `${Entities.RETURNITEM}`,
  [Entities.FULLFILLMENT]: `${Entities.FULLFILLMENT}`,
  [Entities.CASHRECONCILIATION]: `${Entities.CASHRECONCILIATION}`,
  [Entities.INVENTORYCOUNT]: `${Entities.INVENTORYCOUNT}`,
  [Entities.BRAND]: `${Entities.BRAND}`,
};

export const getEndpoint = function (entity, type) {
  if (type === "get") {
    return `${entityApi[entity]}/get${entity.charAt(0).toUpperCase() + entity.slice(1)
      }ById`;
  }
  if (type === "save") {
    return `${entityApi[entity]}/save${entity.charAt(0).toUpperCase() + entity.slice(1)
      }`;
  }
  if (type === "delete") {
    return `${entityApi[entity]}/${deleteEntity[entity]}`;
  }
  if (type === "archive") {
    return `${entityApi[entity]}/archive`;
  }
};

export const deleteEntity = {
  [Entities.EMPLOYEE]: "deleteEmployee",
  [Entities.GROUP]: "deleteGroup",
  [Entities.CUSTOMER]: "deleteCustomer",
  [Entities.PRODUCT]: "deleteProduct",
  [Entities.ITEM]: "deleteItem",
  [Entities.ONLINESTOREGROUP]: "deleteOnlineStoreGroup",
  [Entities.MSOGROUP]: "deleteMsoGroup",
  [Entities.MSOSUPPLIER]: "deleteMsoSupplier",
  [Entities.MSOPRODUCT]: "deleteMsoProduct",
  [Entities.MSOCHARTOFACCOUNT]: "deleteMsoChartOfAccount",
  [Entities.CHARTOFACCOUNT]: "deleteChartOfAccount",
  [Entities.ORDER]: "deleteOrder",
  [Entities.ORGANIZATION]: "deleteOrganization",
  [Entities.PURCHASEORDER]: "deletePurchaseOrder",
  [Entities.ROOM]: "deleteRoom",
  [Entities.SPECIALPRICING]: "deleteSpecialPricing",
  [Entities.STORE]: "deleteStore",
  [Entities.SUPPLIER]: "deleteSupplier",
  [Entities.SUPPLIERAGENT]: "deleteSupplierAgent",
  [Entities.GIFTCARD]: "deleteGiftCard",
  [Entities.CREDITMEMO]: "deleteCreditMemo",
  [Entities.COUPON]: "deleteCoupon",
  [Entities.MSOCOUPON]: "deleteMsoCoupon",
  [Entities.PAYMENT]: "deletePayment",
  [Entities.TAXSETUP]: "deleteTaxSetup",
  [Entities.FLIGHT]: "deleteFlight",
  [Entities.JOURNAL]: "deleteJournal",
  [Entities.TIMESHEET]: "deleteTimeSheet",
  [Entities.QUEUE]: "deleteQueueCustomer",
  [Entities.RETURNITEM]: "deleteReturnItem",
  [Entities.FULLFILLMENT]: "deleteFulfillment",
  [Entities.CASHRECONCILIATION]: "deleteCashReconciliation",
  [Entities.INVENTORYCOUNT]: "deleteInventoryCount",
  [Entities.BRAND]: "deleteBrand",
};

export const undeleteEntity = {
  [Entities.EMPLOYEE]: "undeleteEmployee",
  [Entities.GROUP]: "undeleteGroup",
  [Entities.CUSTOMER]: "undeleteCustomer",
  [Entities.PRODUCT]: "undeleteProduct",
  [Entities.ITEM]: "undeleteItem",
  [Entities.ONLINESTOREGROUP]: "undeleteOnlineStoreGroup",
  [Entities.MSOGROUP]: "undeleteMsoGroup",
  [Entities.MSOSUPPLIER]: "undeleteMsoSupplier",
  [Entities.MSOPRODUCT]: "undeleteMsoProduct",
  [Entities.MSOCHARTOFACCOUNT]: "undeleteMsoChartOfAccount",
  [Entities.CHARTOFACCOUNT]: "undeleteChartOfAccount",
  [Entities.ORDER]: "undeleteOrder",
  [Entities.ORGANIZATION]: "undeleteOrganization",
  [Entities.PURCHASEORDER]: "undeletePurchaseOrder",
  [Entities.ROOM]: "undeleteRoom",
  [Entities.SPECIALPRICING]: "undeleteSpecialPricing",
  [Entities.STORE]: "undeleteStore",
  [Entities.SUPPLIER]: "undeleteSupplier",
  [Entities.SUPPLIERAGENT]: "undeleteSupplierAgent",
  [Entities.GIFTCARD]: "undeleteGiftCard",
  [Entities.CREDITMEMO]: "undeleteCreditMemo",
  [Entities.COUPON]: "undeleteCoupon",
  [Entities.MSOCOUPON]: "undeleteMsoCoupon",
  [Entities.PAYMENT]: "undeletePayment",
  [Entities.TAXSETUP]: "undeleteTaxSetup",
  [Entities.FLIGHT]: "undeleteFlight",
  [Entities.JOURNAL]: "undeleteJournal",
  [Entities.TIMESHEET]: "undeleteTimeSheet",
  [Entities.FULLFILLMENT]: "undeleteFulfillment",
  [Entities.CASHRECONCILIATION]: "undeleteCashReconciliation",
  [Entities.INVENTORYCOUNT]: "undeleteInventoryCount",
  [Entities.BRAND]: "undeleteBrand",
};

export const getEntityById = {
  [Entities.EMPLOYEE]: "getEmployeeById",
  [Entities.GROUP]: "getGroupById",
  [Entities.CUSTOMER]: "getCustomerById",
  [Entities.PRODUCT]: "getProductById",
  [Entities.ITEM]: "getItemById",
  [Entities.ONLINESTOREGROUP]: "getOnlineStoreGroupById",
  [Entities.MSOGROUP]: "getMsoGroupById",
  [Entities.MSOSUPPLIER]: "getMsoSupplierById",
  [Entities.MSOPRODUCT]: "getMsoProductById",
  [Entities.MSOCHARTOFACCOUNT]: "getMsoChartOfAccountById",
  [Entities.CHARTOFACCOUNT]: "getChartOfAccountById",
  [Entities.ORDER]: "getOrderById",
  [Entities.ORGANIZATION]: "getOrganizationById",
  [Entities.PURCHASEORDER]: "getPurchaseOrderById",
  [Entities.ROOM]: "getRoomById",
  [Entities.SPECIALPRICING]: "getSpecialPricingById",
  [Entities.STORE]: "getStoreById",
  [Entities.SUPPLIER]: "getSupplierById",
  [Entities.SUPPLIERAGENT]: "getSupplierAgentById",
  [Entities.GIFTCARD]: "getGiftCardById",
  [Entities.CREDITMEMO]: "getCreditMemoById",
  [Entities.COUPON]: "getCouponById",
  [Entities.MSOCOUPON]: "getMsoCouponById",
  [Entities.PAYMENT]: "getPaymentById",
  [Entities.TAXSETUP]: "getTaxSetupById",
  [Entities.FLIGHT]: "getFlightById",
  [Entities.JOURNAL]: "getJournalById",
  [Entities.TIMESHEET]: "getTimeSheetById",
  [Entities.QUEUE]: "getQueueCustomerById",
  [Entities.RETURNITEM]: "getReturnItemById",
  [Entities.FULLFILLMENT]: "getFulfillmentOrderById",
  [Entities.CASHRECONCILIATION]: "undeleteCashReconciliation",
  [Entities.INVENTORYCOUNT]: "getInventoryCountById",
  [Entities.BRAND]: "getBrandById",
};

export const saveEntity = {
  [Entities.EMPLOYEE]: "saveEmployee",
  [Entities.GROUP]: "saveGroup",
  [Entities.CUSTOMER]: "saveCustomer",
  [Entities.PRODUCT]: "saveProduct",
  [Entities.ITEM]: "saveItem",
  [Entities.ONLINESTOREGROUP]: "saveOnlineStoreGroup",
  [Entities.MSOGROUP]: "saveMsoGroup",
  [Entities.MSOSUPPLIER]: "saveMsoSupplier",
  [Entities.MSOPRODUCT]: "saveMsoProduct",
  [Entities.MSOCHARTOFACCOUNT]: "saveMsoChartOfAccount",
  [Entities.CHARTOFACCOUNT]: "saveChartOfAccount",
  [Entities.ORDER]: "saveOrder",
  [Entities.ORGANIZATION]: "saveOrganization",
  [Entities.PURCHASEORDER]: "savePurchaseOrder",
  [Entities.ROOM]: "saveRoom",
  [Entities.SPECIALPRICING]: "saveSpecialPricing",
  [Entities.STORE]: "saveStore",
  [Entities.SUPPLIER]: "saveSupplier",
  [Entities.SUPPLIERAGENT]: "saveSupplierAgent",
  [Entities.GIFTCARD]: "saveGiftCard",
  [Entities.CREDITMEMO]: "saveCreditMemo",
  [Entities.COUPON]: "saveCoupon",
  [Entities.MSOCOUPON]: "saveMsoCoupon",
  [Entities.PAYMENT]: "savePayment",
  [Entities.TAXSETUP]: "saveTaxSetup",
  [Entities.FLIGHT]: "saveFlight",
  [Entities.JOURNAL]: "saveJournal",
  [Entities.TIMESHEET]: "saveTimeSheet",
  [Entities.RETURNITEM]: "saveReturnItem",
  [Entities.FULLFILLMENT]: "saveFulfillmentOrder",
  [Entities.CASHRECONCILIATION]: "saveCashReconciliation",
  [Entities.INVENTORYCOUNT]: "saveInventoryCount",
  [Entities.BRAND]: "saveBrand",
};

// module action map for setting entity
export const setEntity = {
  [Entities.EMPLOYEE]: "setEmployee",
  [Entities.GROUP]: "setGroup",
  [Entities.CUSTOMER]: "setCustomer",
  [Entities.PRODUCT]: "setProduct",
  [Entities.ITEM]: "setItem",
  [Entities.ONLINESTOREGROUP]: "setOnlineStoreGroup",
  [Entities.MSOGROUP]: "setMsoGroup",
  [Entities.MSOSUPPLIER]: "setMsoSupplier",
  [Entities.MSOPRODUCT]: "setMsoProduct",
  [Entities.MSOCHARTOFACCOUNT]: "setMsoChartOfAccount",
  [Entities.CHARTOFACCOUNT]: "setChartOfAccount",
  [Entities.ORDER]: "setOrder",
  [Entities.ORGANIZATION]: "setOrganization",
  [Entities.PURCHASEORDER]: "setPurchaseOrder",
  [Entities.ROOM]: "setRoom",
  [Entities.SPECIALPRICING]: "setSpecialPricing",
  [Entities.STORE]: "setStore",
  [Entities.SUPPLIER]: "setSupplier",
  [Entities.SUPPLIERAGENT]: "setSupplierAgent",
  [Entities.GIFTCARD]: "setGiftCard",
  [Entities.CREDITMEMO]: "setCreditMemo",
  [Entities.COUPON]: "setCoupon",
  [Entities.MSOCOUPON]: "setMsoCoupon",
  [Entities.PAYMENT]: "setPayment",
  [Entities.TAXSETUP]: "setTaxSetup",
  [Entities.FLIGHT]: "setFlight",
  [Entities.JOURNAL]: "setJournal",
  [Entities.TIMESHEET]: "setTimeSheet",
  [Entities.RETURNITEM]: "setReturnItem",
  [Entities.FULLFILLMENT]: "setFulllfillmentOrder",
  [Entities.CASHRECONCILIATION]: "setCashReconciliation",
  [Entities.INVENTORYCOUNT]: "setInventoryCount",
  [Entities.BRAND]: "setBrand",
};

// module action map for setting entity
export const setEntites = {
  [Entities.EMPLOYEE]: "setEmployees",
  [Entities.GROUP]: "setGroups",
  [Entities.CUSTOMER]: "setCustomers",
  [Entities.PRODUCT]: "setProduct",
  [Entities.ITEM]: "setItems",
  [Entities.ONLINESTOREGROUP]: "setOnlineStoreGroups",
  [Entities.MSOGROUP]: "setMsoGroups",
  [Entities.MSOSUPPLIER]: "setMsoSuppliers",
  [Entities.MSOPRODUCT]: "setMsoProducts",
  [Entities.MSOCHARTOFACCOUNT]: "setMsoChartOfAccounts",
  [Entities.CHARTOFACCOUNT]: "setChartOfAccounts",
  [Entities.ORDER]: "setOrders",
  [Entities.ORGANIZATION]: "setOrganizations",
  [Entities.PURCHASEORDER]: "setPurchaseOrders",
  [Entities.ROOM]: "setRooms",
  [Entities.SPECIALPRICING]: "setSpecialPricings",
  [Entities.STORE]: "setStores",
  [Entities.SUPPLIER]: "setSuppliers",
  [Entities.SUPPLIERAGENT]: "setSupplierAgents",
  [Entities.GIFTCARD]: "setGiftCards",
  [Entities.CREDITMEMO]: "setCreditMemos",
  [Entities.COUPON]: "setCoupons",
  [Entities.MSOCOUPON]: "setMsoCoupons",
  [Entities.PAYMENT]: "setPayments",
  [Entities.TAXSETUP]: "setTaxSetups",
  [Entities.FLIGHT]: "setFlights",
  [Entities.JOURNAL]: "setJournals",
  [Entities.TIMESHEET]: "setTimeSheets",
  [Entities.RETURNITEM]: "setReturnItems",
  [Entities.FULLFILLMENT]: "setFulllfillmentOrders",
  [Entities.CASHRECONCILIATION]: "setCashReconciliations",
  [Entities.INVENTORYCOUNT]: "setInventoryCounts",
  [Entities.BRAND]: "setBrands",
};

export const getEntites = {
  [Entities.EMPLOYEE]: "getEmployees",
  [Entities.GROUP]: "getGroups",
  [Entities.CUSTOMER]: "getCustomers",
  [Entities.PRODUCT]: "getProduct",
  [Entities.ITEM]: "getItems",
  [Entities.ONLINESTOREGROUP]: "getOnlineStoreGroups",
  [Entities.MSOGROUP]: "getMsoGroups",
  [Entities.MSOSUPPLIER]: "getMsoSuppliers",
  [Entities.MSOPRODUCT]: "getMsoProducts",
  [Entities.MSOCHARTOFACCOUNT]: "getMsoChartOfAccounts",
  [Entities.CHARTOFACCOUNT]: "getChartOfAccounts",
  [Entities.ORDER]: "getOrders",
  [Entities.ORGANIZATION]: "getOrganizations",
  [Entities.PURCHASEORDER]: "getPurchaseOrders",
  [Entities.ROOM]: "getRooms",
  [Entities.SPECIALPRICING]: "getSpecialPricings",
  [Entities.STORE]: "getStores",
  [Entities.SUPPLIER]: "getSuppliers",
  [Entities.SUPPLIERAGENT]: "getSupplierAgents",
  [Entities.GIFTCARD]: "getGiftCards",
  [Entities.CREDITMEMO]: "getCreditMemos",
  [Entities.COUPON]: "getCoupons",
  [Entities.MSOCOUPON]: "getMsoCoupons",
  [Entities.PAYMENT]: "getPayments",
  [Entities.TAXSETUP]: "getTaxSetups",
  [Entities.FLIGHT]: "getFlights",
  [Entities.JOURNAL]: "getJournals",
  [Entities.TIMESHEET]: "getTimeSheets",
  [Entities.QUEUE]: "getQueueCustomers",
  [Entities.RETURNITEM]: "getReturnItems",
  [Entities.FULLFILLMENT]: "getFulllfillmentOrders",
  [Entities.CASHRECONCILIATION]: "getCashReconciliations",
  [Entities.INVENTORYCOUNT]: "getInventoryCounts",
  [Entities.BRAND]: "getBrands",
};

export const entityModule = {
  [Entities.EMPLOYEE]: "employeeModule",
  [Entities.GROUP]: "groupModule",
  [Entities.CUSTOMER]: "customerModule",
  [Entities.PRODUCT]: "productModule",
  [Entities.ITEM]: "itemModule",
  [Entities.ONLINESTOREGROUP]: "onlineStoreGroupModule",
  [Entities.MSOGROUP]: "msoGroupModule",
  [Entities.MSOSUPPLIER]: "msoSupplierModule",
  [Entities.MSOPRODUCT]: "msoProductModule",
  [Entities.MSOCHARTOFACCOUNT]: "msoChartOfAccountModule",
  [Entities.CHARTOFACCOUNT]: "chartOfAccountModule",
  [Entities.ORDER]: "orderModule",
  [Entities.ORGANIZATION]: "organizationModule",
  [Entities.PURCHASEORDER]: "purchaseOrderModule",
  [Entities.ROOM]: "productModule",
  [Entities.SPECIALPRICING]: "productModule",
  [Entities.STORE]: "storeModule",
  [Entities.SUPPLIER]: "supplierModule",
  [Entities.SUPPLIERAGENT]: "supplierModule",
  [Entities.GIFTCARD]: "giftCardModule",
  [Entities.CREDITMEMO]: "creditMemoModule",
  [Entities.COUPON]: "couponModule",
  [Entities.MSOCOUPON]: "msoCouponModule",
  [Entities.PAYMENT]: "paymentModule",
  [Entities.TAXSETUP]: "taxSetupModule",
  [Entities.FLIGHT]: "flightModule",
  [Entities.JOURNAL]: "journalModule",
  [Entities.TIMESHEET]: "timeSheetModule",
  [Entities.QUEUE]: "queueModule",
  [Entities.RETURNITEM]: "returnItemModule",
  [Entities.FULLFILLMENT]: "FulllfillmentModule",
  [Entities.CASHRECONCILIATION]: "cashReconciliationModule",
  [Entities.INVENTORYCOUNT]: "inventoryCountModule",
  [Entities.BRAND]: "brandModule",
};

export const entityRoute = {
  [Entities.EMPLOYEE]: "employees",
  [Entities.GROUP]: "groups",
  [Entities.CUSTOMER]: "customers",
  [Entities.PRODUCT]: "products",
  [Entities.ITEM]: "items",
  [Entities.ONLINESTOREGROUP]: "onlineStoreGroups",
  [Entities.MSOGROUP]: "msoGroups",
  [Entities.MSOSUPPLIER]: "msoSuppliers",
  [Entities.MSOPRODUCT]: "msoProducts",
  [Entities.MSOCHARTOFACCOUNT]: "msoChartOfAccounts",
  [Entities.CHARTOFACCOUNT]: "chartOfAccounts",
  [Entities.ORDER]: "orders",
  [Entities.ORGANIZATION]: "organization",
  [Entities.PURCHASEORDER]: "purchaseOrders",
  [Entities.ROOM]: "rooms",
  [Entities.SPECIALPRICING]: "specialPricings",
  [Entities.STORE]: "stores",
  [Entities.SUPPLIER]: "suppliers",
  [Entities.SUPPLIERAGENT]: "supplierAgents",
  [Entities.GIFTCARD]: "giftCards",
  [Entities.CREDITMEMO]: "creditMemos",
  [Entities.COUPON]: "coupons",
  [Entities.MSOCOUPON]: "msoCoupons",
  [Entities.PAYMENT]: "payments",
  [Entities.TAXSETUP]: "taxSetups",
  [Entities.FLIGHT]: "flights",
  [Entities.JOURNAL]: "journals",
  [Entities.TIMESHEET]: "timeSheets",
  [Entities.QUEUE]: "queues",
  [Entities.RETURNITEM]: "returnItems",
  [Entities.FULLFILLMENT]: "fulfillment",
  [Entities.CASHRECONCILIATION]: "cashReconciliations",
  [Entities.INVENTORYCOUNT]: "inventoryCounts",
  [Entities.BRAND]: "brands",
};

export const entityApiRoute = {
  [Entities.EMPLOYEE]: "employee",
  [Entities.GROUP]: "group",
  [Entities.CUSTOMER]: "customer",
  [Entities.PRODUCT]: "product",
  [Entities.ITEM]: "item",
  [Entities.ONLINESTOREGROUP]: "onlineStoreGroup",
  [Entities.MSOGROUP]: "msoGroup",
  [Entities.MSOSUPPLIER]: "msoSupplier",
  [Entities.MSOPRODUCT]: "msoProduct",
  [Entities.MSOCHARTOFACCOUNT]: "msoChartOfAccount",
  [Entities.CHARTOFACCOUNT]: "chartOfAccount",
  [Entities.ORDER]: "order",
  [Entities.ORGANIZATION]: "organization",
  [Entities.PURCHASEORDER]: "purchaseOrder",
  [Entities.ROOM]: "room",
  [Entities.SPECIALPRICING]: "specialPricing",
  [Entities.STORE]: "store",
  [Entities.SUPPLIER]: "supplier",
  [Entities.SUPPLIERAGENT]: "supplierAgent",
  [Entities.GIFTCARD]: "giftCard",
  [Entities.CREDITMEMO]: "creditMemo",
  [Entities.COUPON]: "coupon",
  [Entities.MSOCOUPON]: "msoCoupon",
  [Entities.PAYMENT]: "payment",
  [Entities.TAXSETUP]: "taxSetup",
  [Entities.FLIGHT]: "flight",
  [Entities.JOURNAL]: "journal",
  [Entities.TIMESHEET]: "timeSheet",
  [Entities.QUEUE]: "queue",
  [Entities.RETURNITEM]: "returnItem",
  [Entities.FULLFILLMENT]: "fulfillment",
  [Entities.CASHRECONCILIATION]: "cashReconciliation",
  [Entities.INVENTORYCOUNT]: "inventoryCount",
  [Entities.BRAND]: "brand",
};
// Enable Delete

export const enableDelete = {
  [Entities.EMPLOYEE]: true,
  [Entities.GROUP]: true,
  [Entities.CUSTOMER]: true,
  [Entities.PRODUCT]: true,
  [Entities.ITEM]: false,
  [Entities.ONLINESTOREGROUP]: true,
  [Entities.MSOGROUP]: false,
  [Entities.MSOSUPPLIER]: false,
  [Entities.MSOPRODUCT]: false,
  [Entities.MSOCHARTOFACCOUNT]: false,
  [Entities.CHARTOFACCOUNT]: false,
  [Entities.ORDER]: false,
  [Entities.ORGANIZATION]: false,
  [Entities.PURCHASEORDER]: false,
  [Entities.ROOM]: true,
  [Entities.SPECIALPRICING]: true,
  [Entities.STORE]: false,
  [Entities.SUPPLIER]: true,
  [Entities.SUPPLIERAGENT]: true,
  [Entities.CREDITMEMO]: true,
  [Entities.COUPON]: true,
  [Entities.MSOCOUPON]: false,
  [Entities.PAYMENT]: true,
  [Entities.TAXSETUP]: true,
  [Entities.GIFTCARD]: true,
  [Entities.FLIGHT]: true,
  [Entities.JOURNAL]: true,
  [Entities.TIMESHEET]: true,
  [Entities.QUEUE]: true,
  [Entities.RETURNITEM]: true,
  [Entities.FULLFILLMENT]: false,
  [Entities.CASHRECONCILIATION]: true,
  [Entities.INVENTORYCOUNT]: false,
  [Entities.BRAND]: false,
};
