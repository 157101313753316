// Fulfillment Editor
import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";

// Page Header
export const pageHeader = {
  title: "Fulfillment",
  isEditorView: true,
  cancelPath: "fulfillment",
  addPath: "fulfillmentOrderEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Fulfillment",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "fulfillmentOrderEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Id",
    code: "stringId",
    field: { type: "input", label: "Id" },
    isPartial: true,
  },
  {
    label: "Order Code",
    code: "code",
    field: { type: "input", label: "Order Code" },
    isPartial: true,
  },
];

export const fulfillmentInputs = [
  {
    id: "customerInfo.name",
    type: "text",
    title: "Name",
    disabled: true,
  },
  {
    id: "customerInfo.lastName",
    type: "text",
    title: "Last Name",
    disabled: true,
  },
  {
    id: "orderType",
    type: "text",
    title: "Order Type",
    disabled: true,
  },
  {
    id: "postedToJournalAt",
    title: "Post Date Time",
    disabled: true,
    type: "calendar",
    showTime: true,
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Order Code",
    id: "code",
    size: "medium",
  },
  {
    title: "Filled",
    id: "isFilled",
    type: "checkbox",
  },
  {
    title: "Time",
    id: "postedToJournalAt",
    type: "date",
    size: "medium",
    dateFormat: "hh:mm a"
  },
  {
    title: "Total",
    id: "orderTotal",
    type: "money",
    size: "medium",
  },
  {
    title: "Customer",
    id: "customer.name",
    size: "large",
  },
  {
    title: "Preorder",
    id: "isPreorder",
    type: "checkbox",
  },
  {
    title: "Type",
    id: "type",
  },
];



export const columnHeadersPos = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    iconName: "folder-open",
    ariaLabel: "Open",
    // position: "sticky",
    disabled: (item) => {
      return item.status != "waiting";
    },
  },
  {
    id: "remove",
    type: "button",
    // position: "sticky",
    action: "removeItem",
    iconName: "trash",
    ariaLabel: "Remove",
  },
  {
    title: "Name",
    id: "customerInfo.name",
  },
  {
    title: "Last Name",
    id: "customerInfo.lastName",
  },
  {
    title: "Customer",
    id: "customer._id",
    link: true,
    url: "/customers/customerEditor?id=",
    urlId: "customer._id",
  },
  {
    id: "orderType",
    title: "Order Type",
  },
  {
    id: "customerInfo.state",
    title: "State",
  },
  {
    title: "Time In",
    id: "postedToJournalAt",
    type: "timeOnly",
  },
  {
    title: "Pre-order",
    id: "hasPreOrder",
    type: "checkbox",
  },
  {
    id: "status",
    title: "Status",
    type: "label",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Order Id",
    id: "_id",
  },
];

export const defaultFulfillment = {
  customerInfo: {},
};
