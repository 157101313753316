<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Buyer Sales for Product" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'inventoryBuyerProduct',
          label: 'Buyer Sales for Product',
        },
      ]"
      @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print"
      :filterByItemType="true"
      :filterByTime="true"
      @submit-filter="performGetReport"
      :isLoading="isLoading"
      :percentage="percentageCompleted" />
      <div class="report-container">
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Product</th>
                <th align="left">Grams</th>
                <th align="left">Group</th>
                <th align="left">Supplier</th>
                <th align="right">Units On Hand</th>
                <th align="right">On Hand Wholesale</th>
                <th align="right">Sales (units)</th>
                <th align="right">Sales</th>
                <th align="right">Units On Hand/Sales</th>
                <th align="right">$ On Hand/$ Sales</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[product, record] of Object.entries(reportData.list)" :key="product">
                <td>{{ record.product }}</td>
                <td>{{ record.grams }}</td>
                <td>{{ record.group }}</td>
                <td>{{ record.supplier }}</td>
                <td align="right"> {{ record.onHandQty }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.onHandActualCost) }}</td>
                <td align="right"> {{ record.quantity }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.totalPreTax) }}</td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.onHandQty / record.quantity) * 100
                    )
                  }} %
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (record.onHandActualCost / record.totalPreTax) * 100
                    )
                  }} %
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="4">TOTALS</td>
              <td align="right"> {{ totalItemQuantity }}</td>
              <td align="right"> {{ Utilities.formatPrice(totalOnHandActualCost) }}</td>
              <td align="right"> {{ reportData.total.quantity }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.totalPreTax) }}</td>

              <td align="right">
                  {{
                    Utilities.formatPercent(
                      (totalItemQuantity / reportData.total.quantity) * 100
                    )
                  }} %
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (totalOnHandActualCost / reportData.total.totalPreTax) * 100
                    )
                  }} %
                </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Buyer Sales for Product");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
    totActualUnitCost() {
      let tot = 0;
      for(let [k, v] of Object.entries(this.reportData.list)) {
        tot += v.actualCost
      }
      return tot;
    },
    totalOnHandActualCost() {
      let tot = 0;
      for(let [k, v] of Object.entries(this.reportData.list)) {
        tot += v.onHandActualCost
      }
      return tot;
    },
    totalItemQuantity () {
      let tot = 0;
      for(let [k, v] of Object.entries(this.reportData.list)) {
        tot += v.onHandQty
      }
      return tot;
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "inventoryBuyerProduct";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "inventoryBuyerProduct");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_inventoryBuyerProduct.scss";
</style>