<template>
  <div class="cash-reconciliations responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'cashReconciliations', label: 'Cash Reconciliation' },
        ]"
        :entity="entity"
        :preCheckBeforeAddAction="preCheckBeforeAddAction"
      />
      <SearchTable :entity="entity" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "cashReconciliations",
  data() {
    return {
      listPageHeader,
      entity: Entities.CASHRECONCILIATION,
    };
  },
  mounted: function () {
    this.setPageTitle("CashReconciliation");
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      getCurrentStore: "storeModule/currentStore",
    }),
    store() {
      return this.getCurrentStore;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
    preCheckBeforeAddAction() {
      const hasCashReconciliationSetup = this.store.cashReconciliationType;
      if (hasCashReconciliationSetup) return true;
      this.setMessage({
        text: `Please define in the store settings the Cash Reconciliation type: by Station or by Employee`,
        type: "error",
      });
      return false;
    },
  },
};
</script>
