<template>
  <div class="journals responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'journals', label: 'Journal Entries' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { listPageHeader } from "./inputs";
import SearchTable from "@/components/SearchTable.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "msoCoupons",
  data() {
    return {
      listPageHeader,
      entity: Entities.JOURNAL,
    };
  },
  mounted: function () {
    this.setPageTitle("Journals");
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      sort: "journalModule/sort",
      pagination: "journalModule/pagination",
      initialFilter: "journalModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "journalModule/updateSort",
      updatePagination: "journalModule/updatePagination",
      updateInitialFilter: "journalModule/updateInitialFilter"
    }),
  }
};
</script>
