<template>
  <div class="label-print-selection">
    <span class="h3 padded">{{ items.length }} labels found, select a label printer to print</span>
    <div class="padded left">
      <div v-if="labelPrinters.length > 0" class="printers">
        <BaseBtn
          v-for="lp in labelPrinters"
          :key="lp._id"
          :label="lp.name"
          :colorType="lp._id == selectedLabelPrinter._id ? 5 : undefined"
          @click="setSelectedPrinter(lp)"
        />
      </div>
      <div v-else>No label printers for this store</div>
    </div>
    <span class="h3 padded">Number of copies</span>
    <BaseInput
      placeholder="Insert number of copies"
      type="number"
      v-model="printLabelQuantity"
      :applyFocus="true"
    />
    <PercentageBar v-if="showProgressBar" :value="progressCount || 0" :maximum="totalCount || 0" title="Labels Printed" />
    <div>
      <div v-if="errorLabels.length > 0" class="error-labels">
        <span class="h3 error-labels__header">Error Item Ids ({{errorLabels.length }})</span>
        <div v-for="el in errorLabels" :key="el.item" class="error-label">
          <span>{{ el.item }}</span>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <BaseBtn
        :disabled="
          !selectedLabelPrinter._id || printing
        "
        class="label-print-selection__action"
        @click="printLabels"
        iconGroup="fas"
        iconName="print"
        label="Print All"
      >
      </BaseBtn>
      <BaseBtn
        v-if="errorLabels.length > 0"
        :disabled="
          !selectedLabelPrinter._id || printing
        "
        class="label-print-selection__action"
        @click="printFailed"
        iconGroup="fas"
        iconName="print"
        label="Retry Failed Labels"
      >
      </BaseBtn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import PercentageBar from "@/components/PercentageBar/PercentageBar.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    previousSelectedLabelPrinter: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      selectedLabelPrinter: {},
      printLabelQuantity: 1,
      successCount: 0,
      errorLabels: [],
      showProgressBar: false,
      totalCount: 0
    };
  },
  components: {
    BaseBtn,
    BaseInput,
    PercentageBar
  },
  watch:{
    successCount(){
      if(this.successCount == this.totalCount){
        this.setMessage({
          title: "Labels Printed",
          text: "Labels Printed",
          type: "success",
        });
        this.$emit("closePrintModal");
      }
    }
  },
  computed: {
    ...mapGetters({
      currentStore: "storeModule/currentStore",
    }),
    labelPrinters() {
      return this.currentStore.labelPrinters;
    },
    progressCount() {
      return this.successCount + this.errorLabels.length;
    },
    printing() {
      return this.progressCount < this.totalCount && this.showProgressBar;
    }
  },
  methods: {
    ...mapActions({
      printLabel: "itemModule/printLabel",
      setMessage: "setMessage",
    }),
    setSelectedPrinter(lp){
      this.selectedLabelPrinter = lp;
      this.$emit("printerSelected", lp);
    },
    printFailed(){
      const failedLabels = [...this.errorLabels];
      this.print(failedLabels);
    },
    printLabels(){
      this.print(this.items)
    },
    print(labels) {
      this.errorLabels = [];
      this.successCount = 0;
      this.totalCount = labels.length * this.printLabelQuantity;
      this.showProgressBar = true;

      labels.forEach((item, i) => {
        const payload = {
          _id: item.item,
          labelPrinter: this.selectedLabelPrinter,
          positiveCallback: (i) => {
            this.successCount++;
          },
          negativeCallback: (i) => {
            this.errorLabels.push(item);
          },
        };

        for (let i = 0; i < this.printLabelQuantity; i++) {
          this.printLabel(payload);
        }
      });
    },
  },
  mounted: function () {
    if(this.previousSelectedLabelPrinter)
      this.selectedLabelPrinter = this.previousSelectedLabelPrinter
  },
};
</script>

<style lang="scss" scoped>
@import './_printAll.scss';
</style>
