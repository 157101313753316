<template>
  <div class="store-selected no-print" v-if="show" @click="navigateToSettings">
    <font-awesome-icon :icon="['fas', 'store-alt']" />
    <span class="store-selected-name">
      {{ currentStore.name }}
    </span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  data() {
    return {};
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    ...mapGetters({
      stores: "storeModule/stores",
      currentStore: "storeModule/currentStore",
    }),
    show() {
      const routeName = this.$route.name;
      if (routeName == "login") return false;
      return this.stores.length > 1;
    },
  },
  methods: {
    navigateToSettings() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
