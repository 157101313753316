<template>
  <div class="padded elevated-0 bordered main-buttons">
    <div>
      <Input
        :inputData="getInput('markAllReceived-button')"
        :isEdit="
          !disabled && hasItems && !selectedPurchaseOrder.isPostedToJournal
        "
        @on-click="$emit('mark-all-received')"
      />
    </div>
    <div>
      <Input
        :inputData="getInput('unmarkAllReceived-button')"
        :isEdit="
          !disabled && hasItems && !selectedPurchaseOrder.isPostedToJournal
        "
        @on-click="$emit('mark-all-unreceived')"
      />
    </div>
    <div>
      <Input
        :inputData="getInput('move-all-to-wi')"
        :isEdit="
          !disabled && !selectedPurchaseOrder.isPostedToJournal && hasItems
        "
        @on-click="$emit('move-all-to-wi')"
      />
    </div>
    <div>
      <Input
        :inputData="getInput('postToJournal-button')"
        :isEdit="
          !disabled && !selectedPurchaseOrder.isPostedToJournal && hasItems
        "
        @on-click="$emit('post-to-journal')"
        v-if="!selectedPurchaseOrder.isPostedToJournal"
      />
    </div>
    <div>
      <Input
        :inputData="getInput('reversePO-button')"
        :isEdit="!disabled && hasItems"
        v-if="selectedPurchaseOrder.isPostedToJournal"
        @on-click="$emit('reverse-posting')"
      />
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import { mainButtons } from "../../inputs";
import Input from "@/components/Input/Input.vue";

export default {
  name: "PurchaseOrderMainButtons",
  components: { Input },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedPurchaseOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      Utilities,
    };
  },
  computed: {
    hasItems() {
      return this.selectedPurchaseOrder?.items?.length > 0;
    },
  },
  methods: {
    getInput(field) {
      return mainButtons.find((i) => i.id == field);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderMainButtons.scss";
</style>
