export const OPTIONS = {
    legend: {
        position: 'bottom',
        labels: {
            fontColor: 'white',
        }
    },
    animation: {
        duration: 300
    },
    tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
        }
    },
    maintainAspectRatio: false,
}

export const TIMESERIES_OPTIONS = {
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                maxTicksLimit: 5,
            },
            gridLines: {
            }
        }],
        xAxes: [{
            ticks: {
                maxTicksLimit: 30,
                fontColor: "white",
            },
            gridLines: {
                display: false,
            }
        }],
    }
}

export const DEFAULT_PALETTE = {
    dark: [
        'rgb(107 156 200)',
        'rgb(41 111 193)',
        'rgb(146 119 199)',
        'rgb(103 86 197)',
        'rgb(251 203 105)',
        'rgb(222 160 0)'
    ],
    light: [
        'rgb(148 203 255)',
        'rgb(53 152 236)',
        'rgb(215 195 255)',
        'rgb(116 99 225)',
        'rgb(255 205 30)',
        'rgb(255 239 149)',
    ]
}

export const DEFAULT_SCALE = {
    dark: [
        'rgb(68,53,154)',
        'rgb(77,59,173)',
        'rgb(86,67,190)',
        'rgb(103,86,197)',
        'rgb(120,105,204)',
        'rgb(137,124,210)',
        'rgb(154,143,217)'
    ],
    light: [
        'rgb(148 203 255)',
        'rgb(143, 198, 250)',
        'rgb(138, 193, 245)',
        'rgb(128, 183, 235)',
        'rgb(118, 173, 225)',
        'rgb(108, 163, 215)',
        'rgb(98, 153, 205)',
    ]
}

export const NO_DATA = {
    dark: 'rgb(69,69,72)',
    light: 'rgb(234,235,239)'
}