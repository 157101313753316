import _ from "lodash";

export const normalizeEntityBeforeSaving = function (item) {
  item.accountNo = item.chartOfAccount.accountNo;
  if (item.batchNumber === "") item.batchNumber = undefined;
  if (item._id === "NEW") {
    item.isManualCreation = true;
    item.isPostedToChartOfAccounts = false;
    if (item.purchaseOrder) {
      item.order = undefined;
      item.payment = undefined;
    } else if (item.order) {
      item.purchaseOrder = undefined;
      item.payment = undefined;
    } else if (item.payment) {
      item.purchaseOrder = undefined;
      item.order = undefined;
    }
  }
  return item;
};
