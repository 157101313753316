<template>
  <div class="timeSheet-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'timeSheets', label: 'TimeSheet' },
          {
            name: 'timeSheetEditor',
            label: selectedTimeSheet._id,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedTimeSheet"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base">
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--danger': selectedTimeSheet.isDeleted,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedTimeSheet.isDeleted"
              type="danger"
              text="DELETED"
            />
          </transition>
          <div>
            <div class="timeSheet-editor__info-wrapper">
              <div
                v-if="selectedTimeSheet._id !== 'NEW'"
                class="timeSheet-editor__info-wrapper__left-wrapper"
              >
                <router-link
                  class="timeSheet-editor__info-wrapper__name"
                  target="_blank"
                  :to="{
                    name: 'employeeEditor',
                    query: { id: selectedTimeSheet.employee._id },
                  }"
                >
                  {{ selectedTimeSheet.employee.name }}
                  {{ selectedTimeSheet.employee.lastName }}
                </router-link>
                <div class="timeSheet-editor__info-wrapper__contact">
                  <font-awesome-icon class="icon" :icon="['fas', 'phone']" />
                  <div>{{ selectedTimeSheet.employee.phone }}</div>
                </div>
                <div class="timeSheet-editor__info-wrapper__contact">
                  <font-awesome-icon class="icon" :icon="['fas', 'envelope']" />
                  <div>{{ selectedTimeSheet.employee.email }}</div>
                </div>
                <router-link
                  class="timeSheet-editor__info-wrapper__contact"
                  target="_blank"
                  :to="{
                    name: 'employeeEditor',
                    query: { id: selectedTimeSheet.employee._id },
                  }"
                >
                  <font-awesome-icon
                    class="icon"
                    :icon="['fas', 'info-circle']"
                  />
                  <div>{{ selectedTimeSheet.employee._id }}</div>
                </router-link>
              </div>
              <div v-else>
                <Input
                  :model="selectedTimeSheet.employee"
                  :inputData="getInput('employee')"
                  :isEdit="isEdit"
                  @updateModel="selectedTimeSheet.employee = $event"
                />
              </div>
              <div class="timeSheet-editor__hours-wrapper">
                <div class="timeSheet-editor__hours-label">Total Hours</div>
                <div class="timeSheet-editor__hours">{{ totalHours }}</div>
              </div>
            </div>

            <div class="timeSheet-editor__date-range">
              <Input
                class="timeSheet-editor__date-range--date"
                :model="selectedTimeSheet.stampedIn"
                :inputData="getInput('stampedIn')"
                :isEdit="isEdit"
                @updateModel="(event) => setValue('stampedIn', event)"
                :validation="isValid('startDate')"
              />
              <Input
                class="timeSheet-editor__date-range--date"
                :model="selectedTimeSheet.stampedOut"
                :inputData="getInput('stampedOut')"
                :isEdit="isEdit"
                @updateModel="(event) => setValue('stampedOut', event)"
                :validation="isValid('endDate')"
              />
            </div>
          </div>
          <div class="timeSheet-editor__divider" />
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedTimeSheet._id || ''}`"
        sectionType="about"
        :bus="bus"
      >
        <About :entity="selectedTimeSheet" />
      </EditorSection>
      <EditorSection :defaultIsOpen="true" :title="timeSheetsTitle" :bus="bus">
        <EmployeeTimeSheets
          v-if="selectedTimeSheet.employee._id"
          :employeeId="selectedTimeSheet.employee._id"
        />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import EditorSection from "@/components/EditorSection";
import About from "@/components/About/About.vue";

import EmployeeTimeSheets from "@/components/EmployeeTimeSheetTable/EmployeeTimeSheets.vue";

import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";

import { validateFields } from "@/utils/validation/timesheet";

import {
  timeSheetInputs,
  defaultSelectedTimeSheet,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";

export default {
  name: "timeSheetEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      timeSheetInputs,
      isEdit: false,
      defaultSelectedTimeSheet,
      timeSheets: [],
      timeSheetsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.TIMESHEET,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
      validationErrors: {},
    };
  },
  components: {
    PageHeader,
    Input,
    ContainerLabel,
    EditorSection,
    About,
    EmployeeTimeSheets,
  },
  mounted: function () {
    this.setPageTitle("TimeSheet");
    const id = this.$route.query.id;
    if (id && id !== "NEW") {
      this.getTimeSheetById({ _id: id });
    } else {
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getTimeSheetById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      timeSheet: "timeSheetModule/timeSheet",
    }),
    selectedTimeSheet: {
      get() {
        this.setPageTitle("TimeSheet" + this.timeSheet?.name);
        return this.timeSheet;
      },
      set(entity) {
        return entity;
      },
    },
    timeSheetsTitle() {
      return `View All Time Sheets for ${
        this.selectedTimeSheet.employee.name || ""
      } ${this.selectedTimeSheet.employee.lastName || ""}`;
    },
    totalHours() {
      const startTime = moment(this.selectedTimeSheet.stampedIn);
      const endTime = moment(this.selectedTimeSheet.stampedOut || new Date());
      const result = moment.duration(endTime.diff(startTime));
      if (result.days() > 0)
        return `${result._data.days}d ${result._data.hours}h ${result._data.minutes}m`;
      return `${result._data.hours}h ${result._data.minutes}m`;
    },
    dateRange() {
      return (
        moment(this.selectedTimeSheet.stampedIn).format(
          Constants.dates.extendedCalendarDateFormat
        ) +
        " - " +
        moment(this.selectedTimeSheet.stampedOut).format(
          Constants.dates.extendedCalendarDateFormat
        )
      );
    },
  },
  methods: {
    ...mapActions({
      getTimeSheetById: "timeSheetModule/getTimeSheetById",
      saveTimeSheet: "timeSheetModule/saveTimeSheet",
      deleteTimeSheet: "timeSheetModule/deleteTimeSheet",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getTimeSheets: "timeSheetModule/getTimeSheets",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    getInput(field) {
      return timeSheetInputs.find((i) => i.id == field);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedTimeSheet._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedTimeSheet._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedTimeSheet.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedTimeSheet = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    isValid(field) {
      return _.get(this.validationErrors, field);
    },
    setValue(field, value) {
      _.set(this.selectedTimeSheet, field, value);
      validateFields(this.selectedTimeSheet, field, this.validationErrors);
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedTimeSheet[entity] = item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_timeSheetEditor.scss";
</style>
