<template>
  <div>
    <Modal
      width="800px"
      :isOpen="showModal"
      :title="title"
      modalClass="search-modal"
      @handleButtonClicked="handleProductSelected"
      @toggle-modal="handleCloseModal"
      ref="modal"
    >
      <template slot="body">
        <div class="maintenance__content">
          <div ref="maintenanceInputs" id="maintenance-inputs">
            <div class="maintenance__row--space-between">
              <div class="maintenance__subtitle">Selected {{ entity }}</div>
              <BaseBtn
                label="Execute"
                :colorType="-1"
                @click="executeMaintenance"
                :disabled="isExecuteDisabled"
              ></BaseBtn>
            </div>
            <div v-for="input in inputs" :key="input.id">
              <Input :model="getValue(input.id)" :inputData="input" />
            </div>

            <hr />
            <div class="maintenance__table-title">Search {{ entity }}s</div>
          </div>
          <SearchTable
            :entity="entity"
            :defaultColumnHeaders="columnHeaders"
            @select-item="handleProductSelected"
            :height="height"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Input from "@/components/Input/Input.vue";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import {
  mergeProductIdInputs,
  duplicateNewSupplierInputs,
  changeProductIdInputs,
  changeGroupIdInputs,
} from "./inputs";
import Entities from "@/resources/Entities";
import { mapGetters } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Modal from "@/components/Modal.vue";
import SearchTable from "@/components/SearchTable.vue";

import { searchColumnHeaders as productColumnHeaders } from "../../views/Product/inputs";
import { searchColumnHeaders as supplierColumnHeaders } from "../../views/Supplier/inputs";
import { searchColumnHeaders as groupColumnHeaders } from "../../views/Group/inputs";

export default {
  name: "maintenance",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      Entities,
      newEntity: {
        group: {},
        supplier: {},
      },
      newSupplier: {},
      selectedAction: "mergeProductCodes",
      lodash: _,
      productColumnHeaders,
      supplierColumnHeaders,
      groupColumnHeaders,
      mergeProductIdInputs,
      duplicateNewSupplierInputs,
      changeProductIdInputs,
      changeGroupIdInputs,
      typeDictionary: {
        mergeProductCodes: {
          inputs: mergeProductIdInputs,
          entity: Entities.PRODUCT,
          columnHeaders: productColumnHeaders,
          title: "Merge Product into (please select product below):",
        },
        duplicateProductWithNewSupplier: {
          inputs: duplicateNewSupplierInputs,
          entity: Entities.SUPPLIER,
          columnHeaders: supplierColumnHeaders,
          title: "Duplicate Product Records with New Supplier Id",
        },
        changeProductCode: {
          inputs: changeProductIdInputs,
          entity: Entities.PRODUCT,
          columnHeaders: productColumnHeaders,
          title: "Change Product Id On this Item",
        },
        changeGroupCode: {
          inputs: changeGroupIdInputs,
          entity: Entities.GROUP,
          columnHeaders: groupColumnHeaders,
          title: "Change Group On this Product",
        },
      },
      height: 0,
    };
  },
  props: {
    showModal: {
      type: Boolean,
    },
    maintenanceType: {
      type: String,
      default: "mergeProductCodes",
    },
  },
  components: {
    Modal,
    Input,
    BaseBtn,
    SearchTable,
  },
  mounted: function () {
    this.maintenanceType;
    this.setTableHeight();
  },
  computed: {
    ...mapGetters({
      product: "productModule/product",
      item: "itemModule/item",
    }),
    inputs() {
      return this.typeDictionary?.[this.maintenanceType]?.inputs || [];
    },
    entity: function () {
      return this.typeDictionary?.[this.maintenanceType]?.entity;
    },
    maintenanceTypes() {
      return this.maintenanceType;
    },
    columnHeaders() {
      return this.typeDictionary?.[this.maintenanceType]?.columnHeaders;
    },
    title() {
      return this.typeDictionary?.[this.maintenanceType]?.title;
    },
    isExecuteDisabled() {
      return !_.values(this.newEntity).some(
        (x) => x !== undefined && !_.isEmpty(x)
      );
    },
  },
  methods: {
    getValue(field) {
      return _.get(this.newEntity, field);
    },
    executeMaintenance: function () {
      let payload;
      if (this.maintenanceType === "mergeProductCodes") {
        payload = {
          oldProduct: this.product,
          newProduct: this.newEntity,
        };
      }
      if (this.maintenanceType === "duplicateProductWithNewSupplier") {
        payload = {
          existingSupplier: this.product.supplier._id,
          newSupplier: this.newEntity._id,
        };
      }
      if (this.maintenanceType === "changeGroupCode") {
        payload = {
          thisProduct: this.product,
          newGroup: this.newEntity._id,
       };
      }
      if (this.maintenanceType === "changeProductCode") {
        payload = {
          oldProductGroup: this.item.product,
          newProductGroup: this.newEntity,
          item: this.item,
        };
      }
      this.$emit("execute", this.maintenanceType, payload);
    },
    handleProductSelected: function (id, item) {
      this.newEntity = item;
    },
    handleCloseModal: function () {
      this.$emit("close");
    },
    setTableHeight() {
      const heightToSubtract =
        this.$refs.maintenanceInputs.offsetHeight +
        this.$refs.modal.$refs.modalHeader.offsetHeight;
      const bodyStyles = window.getComputedStyle(
        this.$refs.modal.$refs.searchModalContent
      );
      const bodyBorder = 4;
      const bodyPadding =
        parseFloat(bodyStyles.paddingTop) +
        parseFloat(bodyStyles.paddingBottom);
      const temp =
        this.$refs.modal.$refs.modal.offsetHeight -
        heightToSubtract -
        bodyPadding -
        bodyBorder;
      this.height = window.innerHeight / 2 - temp;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_maintenance.scss";
</style>
