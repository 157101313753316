<template>
  <div class="stores responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
          { name: 'home', label: 'Home' },
          { name: 'stores', label: 'Stores' },
        ]" :hideAddButton="true" />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort" :pagination="pagination"
        @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter" :initialFilter="initialFilter" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";
import { Role } from "@/constants/roles";
import { listPageHeader } from "./inputs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "stores",
  data() {
    return {
      Entities,
      entity: Entities.STORE,
      listPageHeader,
      Role,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  computed: {
    ...mapGetters({
      role: "loginModule/role",
      sort: "storeModule/sort",
      pagination: "storeModule/pagination",
      initialFilter: "storeModule/initialFilter"
    }),
    hideAdd() {
      const role = this.role;
      return role != Role.GLOBAL_ADMIN;
    },
  },
  created: function () {
    this.setPageTitle("Stores");
  },
  methods: {
    ...mapActions({
      updateSort: "storeModule/updateSort",
      updatePagination: "storeModule/updatePagination",
      updateInitialFilter: "storeModule/updateInitialFilter"
    }),
  }
};
</script>
