<template>
  <div class="table-container bordered table-full-screen">
    <table cellspacing="0" class="table-element">
      <thead>
        <tr class="header">
          <th class="cell"></th>
          <th class="cell">SRN #</th>
          <th class="cell">First Name</th>
          <th class="cell">Last Name</th>
          <th class="cell">Verification date</th>
          <th class="cell">Verified By</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="row"
          v-for="(caregiver, index) in caregivers"
          :key="caregiver._id"
        >
          <td class="cell horizontal-distance">
            <BaseBtn
              iconGroup="fas"
              iconName="archive"
              :colorType="1"
              class="editor-button"
              :disabled="!isEdit"
              @click="() => $emit('deleteCaregiver', index)"
            />
          </td>
          <td class="cell">{{ caregiver.caregiverSrn }}</td>
          <td class="cell">{{ caregiver.name }}</td>
          <td class="cell">{{ caregiver.lastName }}</td>
          <td class="cell">
            {{ dateFormatter(caregiver.caregiverVerificationDate) }}
          </td>
          <td class="cell">{{ caregiver.verifiedBy }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {};
  },
  components: {
    BaseBtn,
  },
  props: {
    caregivers: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dateFormatter(date) {
      return Utilities.formatDate(date);
    },
  },
};
</script>
