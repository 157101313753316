<template>
  <div class="percentage-bar">
    <div class="percentage-bar__header">
      <div class="input-text__label">{{ title }}</div>
      <div class="input-text__label">{{ value }}/{{ maximum }} {{ unitOfMeasure }}</div>
    </div>
    <div class="percentage-bar__wrapper">
      <div :class="`percentage-bar__bar`" :style="`width: ${percentage}%`"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PercentageBar",
  data() {
    return {

    };
  },
  props: {
    title: {
      type: String
    },
    maximum: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    unitOfMeasure: {
      type: String
    }
  },
  computed: {
    percentage() {
      return (this.value / this.maximum) * 100
    }
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
@import "./_percentage-bar.scss";
</style>