<template>
  <div class="pos-cart">
    <div class="product-table">
      <transition-group name="fade-fast" mode="out-in">
        <div
          v-for="i in order.products"
          :key="i._id"
          class="row elevated-1 cart-item pointer"
          :class="{ 'pulse-background': i.isLoading }"
          @click="() => $emit('select-product', i)"
        >
          <div class="cell-header">
            <b>{{ i.name }} | {{ getItemBrandName(i) }}</b>
          </div>

          <div class="cell flex">
            <div style="flex: 1">
              <span :class="{ discounted: isDiscounted(i) }">
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    order.type === "medical"
                      ? i.medicalPrice
                      : i.recreationalPrice
                  )
                }}
              </span>
              <span class="text-color-1">x</span>
              {{ i.quantity }}
            </div>
            <div>
              <span class="text-color-1 text-thin">Sub Total </span>
              <span :class="{ discounted: isDiscounted(i) }">{{
                Utilities.formatPrice(i.unitCostOriginal * i.quantity)
              }}</span>
            </div>
          </div>

          <div class="cell flex" v-if="isDiscounted(i)">
            <div style="flex: 1">
              <div class="value-discounted">
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(i.salePrice)
                }}
              </div>
            </div>
            <div class="value-discounted">
              {{
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(i.salePrice * i.quantity)
              }}
            </div>
          </div>

          <div v-show="i.name === 'dummy'">
            <br />
          </div>

          <div class="cell text-size-1">
            <span class="text-color-1">
              <span v-show="i.flight_Id_No">
                <i class="fas fa-box"></i>
                FLIGHT:{{ i.flight_Id_No }} |
              </span>
              {{ i.externalFields.group.name }} |
              {{ i.externalFields.supplier.name }} |
              {{ renderBatches(i) }}
            </span>
          </div>

          <span
            v-show="i.name === 'dummy'"
            class="cell text-size-1 pulse-text"
          ></span>

          <div class="discount-tag">
            <span class="text-color-1 text-thin"></span>
            {{ renderDiscounts(i) }}
          </div>

          <div v-if="i.discount > 0" class="discount-tag">
            <span class="text-size-1">Discount</span>
            {{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(i.discount)
            }}

          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";

export default {
  name: "PosCart",
  components: {},
  props: {
    order: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
  },
  data() {
    return { Utilities };
  },
  methods: {
    getItemBrandName(item){
      return item.externalFields?.brand?.name ?? item.externalFields?.supplier?.name ?? item.brandname ?? ''
    },
    isDiscounted(product) {
      return product.unitCostOriginal > product.salePrice;
    },
    renderBatches(product) {
      let text = "Batch #";

      if (!product.items || product.items.length === 0) {
        return "";
      }

      if (product.items.length > 1) {
        text += "es: ";
      } else {
        text += ": ";
      }

      product.items.forEach((b, i) => {
        text += `${b.batchId} (${b.quantity})`;
        if (i < product.items.length - 1) {
          text += ", ";
        }
      });

      return text;
    },
    renderDiscounts(product) {
      let text = "";

      if (!product.items || product.items.length === 0) {
        return "";
      }
      product.items.forEach((b, i) => {
        if (b.discount > 0){
        text = `Discount: ${Utilities.formatPrice(b.discount)}`;
        }
      });

      return text;
    },
    calculateProductQuantity(product) {
      if (!product.items || product.items.length == 0) {
        return 0;
      }
      try {
        return product.items.reduce((a, b) => ({
          quantity: a.quantity + b.quantity,
        })).quantity;
      } catch (error) {
        return 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_posCart.scss";
</style>
