<template>
  <div class="organization-base">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "organizationBase",
};
</script>
