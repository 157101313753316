<template>
  <div class="table-container">
    <table cellspacing="0" class="table-element">
      <thead>
        <tr class="header">
          <th class="cell"></th>
          <th class="cell">Type</th>
          <th class="cell">Discount Total</th>
          <th class="cell">Coupon Code</th>
          <th class="cell">Coupon Amount</th>
          <th class="cell">Coupon Percent</th>
          <th class="cell">Instant Amount</th>
          <th class="cell">Instant Percent</th>
        </tr>
      </thead>
      <tbody>
        <tr class="row" v-for="discount of discounts" :key="discount._id">
          <td class="cell">
            <BaseBtn
              @click="removeDiscount(discount._id)"
              label="Cancel"
              :colorType="1"
              :disabled="discount.type == 'customer'"
            />
          </td>
          <td class="cell discount-name">{{ discount.type }}</td>
          <td class="cell">
            {{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(discount.amountDiscountedInDollars)
            }}
          </td>
          <td class="cell">{{ discount.couponName }}</td>
          <td class="cell">{{ couponAmount(discount) }}</td>
          <td class="cell">{{ couponPercent(discount) }}</td>
          <td class="cell">{{ instantDiscountAmount(discount) }}</td>
          <td class="cell">{{ instantDiscountPercent(discount) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="list__noDiscounts" v-if="discounts.length == 0">
      No discounts applied
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {};
  },
  components: {
    BaseBtn,
  },
  props: {
    discounts: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    removeDiscount(discountId) {
      this.$emit("removeDiscount", discountId);
    },
    couponAmount(discount) {
      if (discount.type != "coupon") return null;

      if (discount.couponDiscountType == "$") {
        return Utilities.formatPrice(discount.couponAmount);
      }
      return null;
    },
    couponPercent(discount) {
      if (discount.type != "coupon") return null;

      if (discount.couponDiscountType == "%") {
        return `${discount.couponAmount}%`;
      }
      return null;
    },
    instantDiscountAmount(discount) {
      if (discount.type != "instant") return null;

      if (discount.instantDiscountType == "$") {
        return Utilities.formatPrice(discount.instantDiscountAmount);
      }
      return null;
    },
    instantDiscountPercent(discount) {
      if (discount.type != "instant") return null;

      if (discount.instantDiscountType == "%") {
        return `${discount.instantDiscountAmount}%`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_list.scss";
</style>
