export const normalizeEntityBeforeSaving = function (product, stores) {
  const entity = JSON.parse(JSON.stringify(product));

  entity.group = entity.group ? entity.group._id : "";
  entity.supplier = entity.supplier ? entity.supplier._id : "";
  entity.brand = entity.brand ? entity.brand._id : null;

  if (entity.variety) {
    if (entity.variety.name) {
      entity.variety = entity.variety.name;
    }
  } else {
    entity.variety = ''
  }

  if (entity.salePrices) {
    for (let i = 0; i < entity.salePrices.length; i++) {
      const salePrice = entity.salePrices[i];
      if (salePrice.startDate) {
        salePrice.startDate = new Date(salePrice.startDate);
      }
      if (salePrice.endDate) {
        salePrice.endDate = new Date(salePrice.endDate);
      }
    }
  }
  

  entity.effects = selectIdOnly(entity.effects);
  entity.flavors = selectIdOnly(entity.flavors);
  entity.tags = selectIdOnly(entity.tags);
  entity.terpenes = selectIdOnly(entity.terpenes);

  return entity;
};

const selectIdOnly = (values) => {
  if (!values || values.length == 0) return [];
  return values.filter(v => v).map((v) => v._id);
};
