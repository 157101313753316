<template>
  <div class="order-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="getHeader"
        :hideAddButton="true"
        :canEdit="false"
        :selectedEntity="selectedOrder"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'orders', label: 'Orders' },
          {
            name: 'orderEditor',
            label: selectedOrder._id,
            query: $route.query,
          },
        ]"
        :canArchive="!selectedOrder.isPaid && isFinanceRole"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        :customArchiveMessage="archiveMessage"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
      />

      <div class="section-base">
        <div
          class="padded elevated-0 bordered position-relative order-editor__body"
          :class="{
            'bordered__highlighted--primary': selectedOrder.isPaid,
            'bordered__highlighted--danger':
              selectedOrder.isArchived || selectedOrder.isVoided,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedOrder.isPaid && !selectedOrder.isVoided"
              type="primary"
              text="PAID"
            />
            <ContainerLabel
              v-if="selectedOrder.isArchived"
              type="danger"
              text="ARCHIVED"
            />
            <ContainerLabel
              v-if="selectedOrder.isVoided"
              type="danger"
              text="REVERSED"
            />
          </transition>

          <div class="order-editor__header">
            <div class="order-editor__left-container">
              <div
                class="order-editor__customer-name"
                v-if="selectedOrder.customer"
              >
                <router-link
                  :to="`/customers/customerEditor?id=${selectedOrder.customer._id}`"
                  target="_blank"
                >
                  {{ Utilities.composeNameAndLastName(selectedOrder.customer) }}
                </router-link>
              </div>
              <div class="order-editor__left-container-row">
                <div v-if="selectedOrder.type">
                  <div class="input-text__label">Order Type</div>
                  {{ selectedOrder.type }}
                </div>
                <div v-if="selectedOrder.type">
                  <div class="input-text__label">Created at</div>
                  {{
                    moment(selectedOrder.createdAt).format(
                      Constants.dates.extendedCalendarDateFormat
                    )
                  }}
                </div>
                <div v-if="selectedOrder.postedToJournalAt">
                  <div class="input-text__label">Posted at</div>
                  {{
                    moment(selectedOrder.postedToJournalAt).format(
                      Constants.dates.extendedCalendarDateFormat
                    )
                  }}
                </div>
                <div v-if="selectedOrder.type">
                  <div class="input-text__label">Source</div>
                  {{ selectedOrder.source }}
                </div>
                <div v-if="selectedOrder.queueCode">
                  <div class="input-text__label">Queue Code</div>
                  {{ selectedOrder.queueCode }}
                </div>
                <div
                  v-if="
                    selectedOrder.postedToJournalByWorkstation && currentStore
                  "
                >
                  <div class="input-text__label">Workstation</div>
                  {{
                    getWorkstation(selectedOrder.postedToJournalByWorkstation)
                  }}
                </div>
                <div v-if="selectedOrder.notesVoid">
                  <div class="input-text__label">Reverse notes</div>
                  {{ selectedOrder.notesVoid }}
                </div>
                <div v-if="selectedOrder.caregiver">
                  <div class="input-text__label">Caregiver</div>
                  {{
                    Utilities.composeNameAndLastName(selectedOrder.caregiver)
                  }}
                </div>
              </div>
              <div class="order-editor__left-container-row">
                <LoyaltySummary
                  v-if="order.loyaltyTransaction"
                  :loyaltyTransaction="order.loyaltyTransaction"
                  :loyaltyInfo="order.loyaltyInfo"
                  :phone="customerPhone"
                  :email="customerEmail"
                />
              </div>
            </div>
            <div class="order-editor__right-container">
              <PriceSummary :rows="orderSummary" />
            </div>
          </div>

          <div class="order-editor__control-buttons">
            <BaseBtn
              :disabled="order.isPaid || order.isArchived || order.isVoided"
              iconGroup="fas"
              iconName="cash-register"
              label="Open in POS"
              @click="openInPos"
            />
            <BaseBtn
              v-canAccess="financeRoles"
              :disabled="
                !order.isPaid ||
                order.isArchived ||
                order.isVoided ||
                order.isPending ||
                loadingStatus.reverseOrder
              "
              iconGroup="fas"
              iconName="history"
              label="Reverse"
              @click="() => performReverse({ isClone: true })"
              :isLoading="loadingStatus.reverseOrder"
            />
            <BaseBtn
              :disabled="!order.isPaid"
              iconGroup="fas"
              iconName="print"
              label="Print"
              @click="print"
            />
            <BaseBtn
              :disabled="!order.isPaid"
              iconGroup="fa"
              iconName="box"
              label="Fulfill"
              @click="fulfill"
            />
            <BaseBtn
              :disabled="
                !order.isPending ||
                loadingStatus.processOrder ||
                order.isArchived
              "
              :isLoading="loadingStatus.processOrder"
              iconGroup="fas"
              iconName="check"
              label="Process Order"
              @click="performProcessOrder"
            />
            <BaseBtn
              iconGroup="fas"
              iconName="print"
              @click="generateReceipt"
              label="Generate Receipt"
              :colorType="3"
              :disabled="!order.isPaid"
            />
          </div>

          <div class="order-editor__divider"></div>

          <div class="order-editor__flag-container">
            <div
              v-for="input in orderInputs"
              :key="input.id"
              class="input-text"
            >
              <Input
                :model="selectedOrder[input.id]"
                @updateModel="selectedOrder[input.id] = $event"
                :inputData="input"
                :isEdit="isEdit"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section-base" v-if="selectedOrder.isVoided">
        <div
          class="padded elevated-0 bordered position-relative extra-info bordered__highlighted--danger"
        >
          <div>
            <div class="input-text__label">Voided Date</div>
            {{
              moment(selectedOrder.voidedAt).format(
                Constants.dates.extendedCalendarDateFormat
              )
            }}
          </div>
          <div>
            <div class="input-text__label">Voided By</div>
            {{ selectedOrder.voidedBy.name }}
            {{ selectedOrder.voidedBy.lastName }}
          </div>
          <div>
            <div class="input-text__label">Voided Workstation</div>
            {{ getWorkstation(selectedOrder.voidedByWorkstation) }}
          </div>
        </div>
      </div>
      <div class="section-base" v-if="selectedOrder.originalOrder">
        <div class="padded elevated-0 bordered position-relative extra-info">
          <MessageBox level="default" class="extra-info__title">
            This order is a consequence of a reversed order. Below is the
            original order info.
          </MessageBox>
          <div>
            <div class="input-text__label">Original Order#</div>
            {{ selectedOrder.originalOrder.code }}
          </div>
          <div>
            <div class="input-text__label">Original Order Created Date</div>
            {{
              moment(selectedOrder.originalCreatedAt).format(
                Constants.dates.extendedCalendarDateFormat
              )
            }}
          </div>
          <div>
            <div class="input-text__label">Original Order Voided Date</div>
            {{
              moment(selectedOrder.originalVoidedAt).format(
                Constants.dates.extendedCalendarDateFormat
              )
            }}
          </div>
        </div>
      </div>

      <EditorSection title="Items" :bus="bus" :defaultIsOpen="true">
        <Table
          @select-item="returnItem"
          :data="items"
          :columnHeaders="itemsColumnHeaders"
          :loadingStatus="false"
          :entity="Entities.ITEM"
          :hasForcedItems="true"
        />
      </EditorSection>

      <EditorSection title="Discounts" :bus="bus" :defaultIsOpen="true">
        <Table
          :data="selectedOrder.discountsApplied"
          :columnHeaders="discountsColumnHeaders"
          :loadingStatus="false"
          :entity="Entities.ORDER"
          :hasForcedItems="true"
        />
      </EditorSection>

      <EditorSection title="Payments" :bus="bus" :defaultIsOpen="true">
        <Table
          :data="selectedOrder.payments"
          :columnHeaders="paymentsColumnHeaders"
          :loadingStatus="false"
          :entity="Entities.PAYMENT"
          :hasForcedItems="true"
        />
      </EditorSection>
      <EditorSection
        :title="`About ${selectedOrder.name || ''}`"
        sectionType="about"
        v-if="selectedOrder._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedOrder" />
      </EditorSection>
    </div>
    <JournalEntries
      v-if="showJournalEntries"
      :showSearchModal="showJournalEntries"
      @toggle-modal="() => (showJournalEntries = !showJournalEntries)"
      :type="journalEntryType"
      :filter="filterJournalEntries"
      :hideFilter="true"
    />
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
    <BaseModal
      v-if="itemReturn.isPopUpOpen"
      @toggle-modal="toggleReturnModal"
      title="Return Item"
    >
      <ItemReturnPopUp
        :order="order"
        @toggle-modal="toggleReturnModal"
        :item="itemReturn.item"
        @save-return-item="performSaveReturnItem"
        @post-return-item="postReturnItem"
      />
    </BaseModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import LoyaltySummary from "@/components/LoyaltySummary/LoyaltySummary.vue";

import router from "@/router";
import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";

import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import Table from "@/components/Table.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import JournalEntries from "@/components/JournalEntries/JournalEntries.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import ItemReturnPopUp from "@/components/ItemReturn/PopUp";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import moment from "moment";
import { financeRoles } from "@/constants/roles";
import {
  orderInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
  itemsColumnHeaders,
  paymentsColumnHeaders,
  discountsColumnHeaders,
} from "./inputs";
import MessageBox from "@/components/MessageBox/MessageBox.vue";

import EditorSection from "@/components/EditorSection";
import Utilities from "@/lib/Utilities";
import { prefillOrderBeforeRendering } from "@/utils/prefill-entity-before-rendering/order";

export default {
  name: "orderEditor",
  data() {
    return {
      itemsColumnHeaders,
      paymentsColumnHeaders,
      discountsColumnHeaders,
      Constants: Constants,
      Entities,
      orderInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.ORDER,
      pageHeader,
      Utilities,
      showJournalEntries: false,
      journalEntryType: "order",
      moment: moment,
      financeRoles,
      itemReturn: {
        isPopUpOpen: false,
        item: {},
      },
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
    Table,
    PriceSummary,
    ContainerLabel,
    JournalEntries,
    BaseBtn,
    ItemReturnPopUp,
    BaseModal,
    LoyaltySummary,
    MessageBox,
  },
  created: function () {
    this.getStoreCurrentStore();
  },
  mounted: function () {
    this.setPageTitle("Order");
    if (this.$route.query.id) {
      this.getOrderById({ _id: this.$route.query.id });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getOrderById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    archiveMessage() {
      if (this.selectedOrder.giftCardUsed) {
        return "A gift card on the order has been used and can't be archived. Would you like to archive all other items are the order?";
      }
      return null;
    },
    ...mapGetters({
      loadingStatus: "orderModule/loadingStatus",
      order: "orderModule/order",
      currentStore: "storeModule/currentStore",
      role: "loginModule/role",
    }),
    isFinanceRole() {
      return this.financeRoles.includes(this.role);
    },
    selectedOrder: {
      get() {
        this.setPageTitle(`Order ${this.order._id}`);
        return prefillOrderBeforeRendering(this.order);
      },
      set(entity) {
        return entity;
      },
    },
    returns() {
      return this.returnItems;
    },
    items() {
      if (
        !this.selectedOrder.products ||
        this.selectedOrder.products.length == 0
      ) {
        return [];
      }
      let items = [];
      this.selectedOrder.products.forEach((p) => {
        p.items.forEach((i) => {
          const item = JSON.parse(JSON.stringify(i));
          item.product = p;
          item.lineTotal = item.subTotal - item.discount;
          item.total = item.lineTotal + (item.freight + item.tax);
          items.push(item);
        });
      });
      return items;
    },
    filterJournalEntries() {
      if (this.journalEntryType == "orderPayments") {
        return {
          order: this.id,
          origin: "P",
        };
      }
      return {
        order: this.id,
      };
    },
    customerPhone(){
      return this.selectedOrder?.customer?.phone;
    },
    customerEmail(){
      return this.selectedOrder?.customer?.email;
    },
    orderSummary() {
      const orderSummary = [];
      const total =
        this.selectedOrder.subTotal +
        this.selectedOrder.tax -
        this.selectedOrder.discount;

      orderSummary.push({
        name: "Sub Total",
        value: Utilities.formatPrice(this.selectedOrder.subTotal),
        isHighlighted: true,
      });
      orderSummary.push({
        name: "Discount",
        value: Utilities.formatPrice(this.selectedOrder.discount),
      });

      orderSummary.push({
        name: "Tax",
        value: Utilities.formatPrice(this.selectedOrder.tax),
      });
      orderSummary.push({
        name: "Total",
        value: Utilities.formatPrice(total),
        isHighlighted: true,
      });

      return orderSummary;
    },
    getHeader() {
      return {
        ...pageHeader,
        title: `${pageHeader.title} ${this.selectedOrder.code ?? ""}`,
      };
    },
  },
  methods: {
    ...mapActions({
      getOrderById: "orderModule/getOrderById",
      reverseOrder: "orderModule/reverseOrder",
      saveOrder: "orderModule/saveOrder",
      deleteOrder: "orderModule/deleteOrder",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "orderModule/getAdjacentOrder",
      setMessage: "setMessage",
      getStoreCurrentStore: "storeModule/getCurrentStore",
      print: "orderModule/print",
      saveReturnItem: "returnItemModule/saveReturnItem",
      // performPostReturnItem: 'returnItemModule/postReturnItem'
      fulfill: "orderModule/fulfill",
      processOrder: "orderModule/processOrder",
      setEmployeeConfirmationPopUp:
        "employeeModule/setEmployeeConfirmationPopUp",
    }),
    getWorkstation(workstationId) {
      try {
        return this.currentStore.workstations.find(
          (wo) => wo._id == workstationId
        ).name;
      } catch (error) {
        console.log("can't match workstation with order workstation");
        return "-";
      }
    },
    performSaveReturnItem(info) {
      this.saveReturnItem({
        orderId: this.order._id,
        customerId: this.order.customer,
        ...info,
        _id: "NEW",
        positiveCallback: () => {
          this.itemReturn.isPopUpOpen = false;
        },
      });
    },
    postReturnItem(item) {},
    toggleReturnModal(event) {
      this.itemReturn.isPopUpOpen = event.isOpen;
    },
    returnItem(id, item, nestedId) {
      if (!this.isFinanceRole) {
        this.setMessage({
          text: "You don't have permission to return this item",
          type: "error",
        });
        return;
      }
      this.itemReturn.isPopUpOpen = true;
      this.itemReturn.item = item;
    },
    openInPos() {
      const payload = {
        isOpen: true,
        positiveCallback: () =>
          this.getOrderById({
            _id: this.selectedOrder._id,
            isPos: true,
            positiveCallback: () => {
              this.$router.push({ path: "/pos" });
            },
          }),
      };
      if (
        this.currentStore.isPinAlwaysRequired &&
        Utilities.isEmployeeConfirmationExpired(this.employee)
      ) {
        this.setEmployeeConfirmationPopUp(payload);
      } else {
        payload.positiveCallback();
      }
    },
    performReverse(payload) {
      let warningMessage = "Are you sure that you want to reverse this order?";
      if (payload?.isClone) {
        warningMessage =
          "Are you sure that you want to reverse & edit this order?";
      }

      const executeReverse = function (notesVoid) {
        payload.notesVoid = notesVoid;
        this.reverseOrder(payload);
      }.bind(this);

      const proceedWithReverse = function () {
        this.setMessagePopUp({
          isOpen: true,
          title: "Reversing Order",
          message: "Enter reverse reason",
          hasTextbox: true,
          placeholder: "Enter reverse reason",
          isAnswerMandatory: true,
          isAknowledge: true,
          positiveCallback: executeReverse,
        });
      }.bind(this);

      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: warningMessage,
        positiveCallback: proceedWithReverse,
      });
    },
    performProcessOrder() {
      if (this.loadingStatus.processOrder) {
        return;
      }
      const orderToSave = this.normalizeEntityBeforeSaving(this.selectedOrder);

      this.processOrder({
        order: orderToSave,
        positiveCallback: (order) => {
          this.setPageTitle(`Order ${order.name}`);
          this.selectedOrder = order;
        },
      });
    },
    performSave() {
      if (this.loadingStatus.saveOrder) {
        return;
      }

      const orderToSave = this.normalizeEntityBeforeSaving(this.selectedOrder);

      this.saveOrder({
        order: orderToSave,
        positiveCallback: (order) => {
          this.setPageTitle(`Order ${order.name}`);
          this.selectedOrder = order;
          if (this.selectedOrder._id === "NEW") {
            router.push({
              name: "orderEditor",
              query: { id: order._id },
            });
          }
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(order) {
      const entity = JSON.parse(JSON.stringify(order));
      if (typeof entity.type == "string") {
        entity.type = entity.type.toLowerCase();
      }
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteOrder) {
        return;
      }

      const orderToDelete = this.normalizeEntityBeforeSaving(
        this.selectedOrder
      );

      this.deleteOrder({
        order: orderToDelete,
        positiveCallback: () => {
          router.push({
            name: "orders",
          });
        },
      });
    },
    addOrder() {
      this.isEdit = true;
      this.selectedOrder = this.defaultSelectedOrder;
      this.selectedOrder._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedOrder._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedOrder._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      switch (key) {
        case "journal-entries-all":
          this.journalEntryType = "order";
          this.showJournalEntries = !this.showJournalEntries;
          break;
        case "journal-entries-payments":
          this.journalEntryType = "orderPayments";
          this.showJournalEntries = !this.showJournalEntries;
          break;
        case "report-sales-discounts":
          window.open("/reportsSales/salesDiscountSummary");
          break;
        case "report-sales-discounts-employee":
          window.open("/reportsSales/salesDiscountsEmployee");
          break;
        case "report-sales-listings-archived":
          window.open("/reportsSales/salesListingArchived");
          break;
        case "report-sales-listings-not-posted-by-batch":
          window.open("/reportsSales/salesListingNotPostedByBatch");
          break;
        case "report-sales-listings-not-posted-by-date":
          window.open("/reportsSales/salesListingNotPostedByDate");
          break;
        case "report-sales-listings-returns":
          window.open("/reportsSales/salesListingReturns");
          break;
        case "report-sales-profitmargin-group-product":
          window.open("/reportsSales/salesProfitMarginsGroupProduct");
          break;
        case "report-sales-profitmargin-supplier":
          window.open("/reportsSales/salesProfitMarginsSupplier");
          break;
        case "report-sales-profitmargin-supplier-group":
          window.open("/reportsSales/salesProfitMarginsSupplierGroup");
          break;
        case "report-sales-profitmargin-supplier-group-product":
          window.open("/reportsSales/salesProfitMarginsSupplierGroupProduct");
          break;

        case "report-sales-promo-supplier":
          window.open("/reportsSales/salesPromoSupplier");
          break;
        case "report-sales-promo-supplier-group":
          window.open("/reportsSales/salesPromoSupplierGroup");
          break;
        case "report-sales-promo-supplier-group-product":
          window.open("/reportsSales/salesPromoSupplierGroupProduct");
          break;

        case "report-sales-recap-transactions-detailed":
          window.open("/reportsSales/salesRecapTransactions");
          break;
        case "report-sales-recap-transactions-summary":
          window.open("/reportsSales/salesRecapTransactionSummary");
          break;
        case "report-sales-recap-transactions-detailed-employee":
          window.open("/reportsSales/salesRecapTransactionsEmployee");
          break;
        case "report-sales-recap-transactions-summary-employee":
          window.open("/reportsSales/salesRecapTransactionSummaryEmployee");
          break;
        case "report-sales-summary":
          window.open("/reportsSales/salesSummary");
          break;
        case "report-sales-summary-breakdown":
          window.open("/reportsSales/salesSummaryBreakdown");
          break;
        case "report-sales-source-average":
          window.open("/reportsSales/salesAverageSource");
          break;
        case "report-sales-discount-comps":
          window.open("/reportsSales/salesDiscountComps");
          break;
        case "report-sales-tax-breakdown-day":
          window.open("/reportsSales/salesTaxBreakdownDay");
          break;
        case "report-sales-tax-summary":
          window.open("/reportsSales/salesTaxSummary");
          break;
        case "report-sales-tax-schedule":
          window.open("/reportsSales/salesTaxSchedule");
          break;

        default:
          break;
      }
    },
    generateReceipt() {
      window.open(`/receipt?orderId=${this.order._id}`, "_blank").focus();
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_orderEditor.scss";
</style>
