<template>
  <div class="pos-header">
    <div class="pos-header__left">
      <BaseBtn
        iconGroup="fas"
        iconName="user-edit"
        @click="$emit('change-employee')"
        :colorType="3"
        :disabled="order._id ? true : false"
      />
      <div>
        <div>
          <b>{{ Utilities.composeNameAndLastName(employee) }}</b>
        </div>
        <div v-if="workstation">
          <b>{{ workstation.name }}</b>
        </div>
      </div>
    </div>
    <div class="pos-header__right">
      <BaseBtn
        iconGroup="fas"
        iconName="search"
        label="Search"
        @click="$emit('show-product-search')"
      />
      <BaseBtn
        v-if="order.isPending && !order.isArchived"
        iconGroup="fas"
        iconName="check"
        label="Process"
        @click="$emit('process')"
        :loading="orderLoadingStatus.processOrder"
      />
      <BaseBtn
        v-if="showOptions && !order.isPaid"
        iconGroup="fas"
        iconName="sitemap"
        label="Products"
        @click="$emit('show-product-browser')"
      />
      <BaseBtn
        v-if="showOptions"
        iconGroup="fas"
        iconName="plus"
        label="New"
        :isLoading="orderLoadingStatus.saveOrder"
        @click="$emit('new-order')"
      />
      <BaseBtn
        v-if="showOptions && !order.isPaid & !order.isArchived"
        iconGroup="fas"
        iconName="user-friends"
        label="Queue"
        @click="$emit('queue')"
      />
      <BaseBtn
        v-if="showOptions"
        iconGroup="fas"
        iconName="folder"
        label="Open Orders"
        @click="$emit('open-orders')"
      />
      <div :class="{ 'left-margin': order._id }">
        <BaseBtn
          v-if="
            order._id &&
            !order.isArchived &&
            !order.isPaid &&
            (order.customer || order.isRetail) &&
            showLoyaltyButtons
          "
          iconGroup="fas"
          iconName="user-plus"
          label="New Customer"
          @click="$emit('new-customer')"
        />
        <BaseBtn
          v-if="
            order._id &&
            !order.isArchived &&
            !order.isPaid &&
            (order.customer || order.isRetail) &&
            showLoyaltyButtons
          "
          iconGroup="fas"
          iconName="user-tag"
          label="Loyalty Lookup"
          @click="$emit('loyalty-lookup')"
        />

        <BaseBtn
          v-if="order._id && !order.isArchived && !order.isPaid"
          iconGroup="fas"
          iconName="archive"
          label="Archive"
          @click="$emit('archive-order')"
          :colorType="1"
        />

        <BaseBtn
          v-if="order._id"
          iconGroup="fas"
          iconName="times"
          label="Close"
          @click="$emit('cancel-order')"
          :colorType="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";

export default {
  name: "PosHeader",
  components: { BaseBtn },
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      },
    },
    orderLoadingStatus: {
      type: Object,
      default: () => {
        return {};
      },
    },
    employee: {
      type: Object,
      default: () => {
        return {};
      },
    },
    workstation: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loyaltySystem: {
      type: String,
    },
  },
  data() {
    return {
      Utilities,
    };
  },
  computed: {
    showOptions() {
      if (!this.order || !this.order._id) return true;
      if (this.order.isArchived || this.order.isDeleted) return true;
      if (this.order.isPaid) return true;

      return false;
    },
    showLoyaltyButtons() {
      return !this.order.loyaltyInfo && this.loyaltySystem === "clutch";
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "./_posHeader.scss";
</style>
