<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader title="Deposit Slip for Green Check" :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'financeReport', label: 'Finance' },
          {
            name: 'financeDepositSlipGreenCheck',
            label: 'Deposit Slip for Green Check',
          },
        ]" @export="exportToCsv" :disableExport="!reportData" />
        <br class="no-print" />
        <ReportFilter class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport"
        :isLoading="isLoading"
        :percentage="percentageCompleted"
        :filterBySaleType="false" />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th>transaction_id</th>
                <th>receipt_date</th>
                <th>customer_id_number</th>
                <th>customer_id_type</th>
                <th>customer_id_expiry_date</th>
                <th>customer_dob</th>
                <th>product_id</th>
                <th>product_name</th>
                <th>product_grams</th>
                <th>product_price_per_unit</th>
                <th>product_type</th>
                <th>transaction_subtotal</th>
                <th>transaction_tax</th>
                <th>transaction_total</th>
                <th>transaction_cash_paid</th>
                <th>transaction_debit_paid</th>
                <th>transaction_credit_paid</th>
                <th>transaction_check_paid</th>
                <th>medical_or_adult_use</th>
                <th>location_id</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[recordId, record] in Object.entries(reportData.list)" :key="recordId">
                <td>{{ record.transaction_id }}</td>
                <td>{{ record.receipt_date }}</td>
                <td>{{ record.customer_id_number }}</td>
                <td>{{ record.customer_id_type }}</td>
                <td>{{ record.customer_id_expiry_date }}</td>
                <td>{{ record.customer_dob }}</td>
                <td>{{ record.product_id }}</td>
                <td>{{ record.product_name }}</td>
                <td>{{ record.product_grams }}</td>
                <td>{{ record.product_price_per_unit }}</td>
                <td>{{ record.product_type }}</td>
                <td>{{ record.transaction_subtotal }}</td>
                <td>{{ record.transaction_tax }}</td>

                <!-- transaction_total -->
                <td>
                    {{ Utilities.formatPrice(
                        record.orderPosted * -1 +
                        record.orderReversed * -1 +
                        record.orderRefund * -1 +
                        record.orderRefundReversed * -1) }}
                </td>

                <!-- transaction_cash_paid -->
                <td>
                    {{ Utilities.formatPrice(record.cashPayment +
                        record.cashPaymentReversed) }}
                </td>

                <!-- transaction_debit_paid -->
                <td>
                    {{ Utilities.formatPrice(
                        record.debitPayment +
                        record.debitPaymentReversed
                    ) }}
                </td>

                <td>{{ record.transaction_credit_paid }}</td>

                <!-- transaction_check_paid -->
                <td>
                    {{ Utilities.formatPrice(
                        record.giftCardPayment +
                        record.giftCardPaymentReversed +
                        record.creditMemoPayment +
                        record.creditMemoPaymentReversed
                        ) }}
                </td>

                <td>{{ record.medical_or_adult_use }}</td>
                <td>{{ record.location_id }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";
  import ReportHelper from "@/lib/report/index.js";

  export default {
    data() {
      return {
        Utilities,
        selectedPeriod: null,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Deposit Slip for Green Check");
    },
    computed: {
      ...mapGetters({
        isLoading: "reports/isLoading",
        getData: "reports/getData",
        percentageCompleted: 'reports/percentageCompleted'
      }),
      reportData() {
        return this.getData;
      },
    },
    methods: {
      ...mapActions({
        generateReport: "reports/generate",
        clearData: "reports/clearData",
      }),

      performGetReport(filter) {
      let reportType = "financeDepositSlipGreenCheck";
      this.generateReport({
        reportType,
        ...filter,
      });
    },

      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "depositSlipGreenCheck");
      },
    },
    beforeDestroy() {
      this.clearData()
    }
  };
  </script>

  <style lang="scss">
  @import "./_financeDepositSlipGreenCheck.scss";
  </style>