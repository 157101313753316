import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import Utilities from "@/lib/Utilities";
import _ from "lodash";
import LocalStorageManager from "@/lib/LocalStorageManager";
import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

import loginModule from "./loginModule/loginModule";
import productModule from "./productModule/productModule";
import groupModule from "./groupModule/groupModule";
import creditMemoModule from "./creditMemoModule/creditMemoModule";
import couponModule from "./couponModule/couponModule";
import msoCouponModule from "./msoCouponModule/msoCouponModule";
import paymentModule from "./paymentModule/paymentModule";
import taxSetupModule from "./taxSetupModule/taxSetupModule";
import supplierModule from "./supplierModule/supplierModule";
import chartOfAccountModule from "./chartOfAccountModule/chartOfAccountModule";
import organizationModule from "./organizationModule/organizationModule";
import msoChartOfAccountModule from "./msoChartOfAccountModule/msoChartOfAccountModule";
import msoGroupModule from "./msoGroupModule/msoGroupModule";
import msoSupplierModule from "./msoSupplierModule/msoSupplierModule";
import msoProductModule from "./msoProductModule/msoProductModule";
import storeModule from "./storeModule/storeModule";
import employeeModule from "./employeeModule/employeeModule";
import customerModule from "./customerModule/customerModule";
import purchaseOrderModule from "./purchaseOrderModule/purchaseOrderModule";
import stateModule from "./stateModule/stateModule";
import itemModule from "./itemModule/itemModule";
import orderModule from "./orderModule/orderModule";
import posModule from "./posModule/posModule";
import queueModule from "./queueModule/queueModule";
import returnModule from "./returnModule/returnModule";
import adjustmentModule from "./adjustmentModule/adjustmentModule";
import disposalModule from "./disposalModule/disposalModule";
import onlineStoreGroupModule from "./onlineStoreGroupModule/onlineStoreGroupModule";
import todaysMenuModule from "./reportStores/todaysMenuModule/todaysMenuModule";
import reports from "./reportStores/reports";
import flightModule from "./flightModule/flightModule";
import journalModule from "./journalModule/journalModule";
import timeSheetModule from "./timeSheetModule/timeSheetModule";
import giftCardModule from "./giftCardModule/giftCardModule";
import returnItemModule from "./returnItemModule/returnItemModule";
import fulfillmentModule from "./fulfillmentModule/fulfillmentModule";
import cashReconciliationModule from "./cashReconciliationModule/cashReconciliationModule";
import loyaltyModule from "./loyaltyModule/loyaltyModule";
import inventoryCountModule from "./inventoryCountModule/inventoryCountModule";
import dashboardModule from "./dashboardModule/dashboardModule";
import tagModule from "./tagModule/tagModule";
import brandModule from "./brandModule/brandModule";
import menuModule from "./menuModule/menuModule";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: "dark",
    isLoading: false,
    message: { isOpen: false },
    loadingStatus: {},
    messagePopUp: {
      isOpen: false,
    },
    deliveryPopUp: {
      isOpen: false,
    },
    selectedProductPopUp: {
      isOpen: false,
      product: undefined,
      forcedItem: undefined,
      mode: undefined,
      order: undefined,
    },
    flightsPopUp: {
      isOpen: false,
    },
    storeSelectionPopUp: {
      isOpen: false,
    },
    modal: {
      isOpen: false,
    },
    notePopUp: {
      isOpen: false,
      order: { customer: { noteList: [] } },
    },
    sellOptionsPopUp: {
      isOpen: false,
    },

    creditMemoPopUp: {
      isOpen: false,
    },
    couponPopUp: {
      isOpen: false,
    },
    customerVerificationPopUp: {
      isOpen: false,
    },
    labelPrinterSelectionPopUp: {
      isOpen: false,
    },
    isNavBarOpen: true,
    selectedLabelPrinter: undefined,
  },
  getters: {
    theme: (state) => state.theme || "dark",
    isLoading: (state) => state.isLoading,
    isNavBarOpen: (state) => state.isNavBarOpen,
    message: (state) => state.message,
    customerVerificationPopUp: (state) => state.customerVerificationPopUp,
    labelPrinterSelectionPopUp: (state) => state.labelPrinterSelectionPopUp,
    orderTypeSelectionPopUp: (state) => state.orderTypeSelectionPopUp,
    messagePopUp: (state) => state.messagePopUp,
    deliveryPopUp: (state) => state.deliveryPopUp,
    creditMemoPopUp: (state) => state.creditMemoPopUp,
    couponPopUp: (state) => state.couponPopUp,
    selectedProductPopUp: (state) => state.selectedProductPopUp,
    flightsPopUp: (state) => state.flightsPopUp,
    modal: (state) => state.modal,
    notePopUp: (state) => state.notePopUp,
    sellOptionsPopUp: (state) => state.sellOptionsPopUp,
    selectedLabelPrinter: (state) => state.selectedLabelPrinter,
    loadingStatus: (state) => state.loadingStatus,
    storeSelectionPopUp: (state) => state.storeSelectionPopUp,
  },
  mutations: {
    goTo: (state, payload) => {
      router.push(payload);
    },
    setCookie: (state, payload) => {
      LocalStorageManager.setLocalStorageKey(payload.name, payload.content);
    },
    readCookies: (state, { theme, order, selectedCustomer, employee }) => {
      state.theme = theme;
      state.order = order;
      state.selectedCustomer = selectedCustomer;
      state.employee = employee;
    },
    switchTheme: (state) => {
      if (state.theme === "dark") {
        state.theme = "light";
      } else {
        state.theme = "dark";
      }
      LocalStorageManager.setLocalStorageKey("theme", state.theme);
      if (state.theme === "light") {
        Utilities.setTheme("light light-background background-transition");
      } else {
        Utilities.setTheme("dark background-transition");
      }
    },
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setIsNavBarOpen: (state, payload) => {
      state.isNavBarOpen = payload;
    },
    setLoadingStatus: (state, payload) => {
      if (payload) {
        state.loadingStatus[payload.api] = payload.status;
      }
    },
    setMessage: (state, payload) => {
      state.message = payload;
      if (payload.isOpen == undefined) {
        state.message.isOpen = true;
      } else {
        state.message.isOpen = false;
      }
    },
    setCustomerVerificationPopUp: (state, payload) => {
      state.customerVerificationPopUp = payload;
    },
    setStoreSelectionPopUp: (state, payload) => {
      state.storeSelectionPopUp = payload;
    },
    setLabelPrinterSelectionPopUp: (state, payload) => {
      state.labelPrinterSelectionPopUp = payload;
    },
    setMessagePopUp: (state, payload) => {
      state.messagePopUp = payload;
    },
    setOrderTypeSelectionPopUp: (state, payload) => {
      state.orderTypeSelectionPopUp = payload;
    },
    setDeliveryPopUp: (state, payload) => {
      state.deliveryPopUp = payload;
    },
    setFlightsPopUp: (state, payload) => {
      state.flightsPopUp = payload;
    },
    setModal: (state, payload) => {
      state.modal = payload;
    },
    setNotePopUp: (state, payload) => {
      state.notePopUp = payload;
    },
    setSellOptionsPopUp: (state, payload) => {
      state.sellOptionsPopUp = payload;
    },
    setCreditMemoPopUp: (state, payload) => {
      state.creditMemoPopUp = payload;
    },
    setCouponPopUp: (state, payload) => {
      state.couponPopUp = payload;
    },
    setSelectedProductPopUp: (state, payload) => {
      state.selectedProductPopUp = payload;
    },
    setSelectedLabelPrinter: (state, payload) => {
      state.selectedLabelPrinter = payload;
      if (payload) {
        LocalStorageManager.setLocalStorageKey(
          "labelPrinter",
          JSON.stringify(payload)
        );
      }
    },
    updateSelectedLabelPrinterFromStore: (state, store) => {
      if (!state.selectedLabelPrinter || !store?.labelPrinters) {
        return
      }

      const updatedLabelPrinter = store.labelPrinters.find(lp => lp._id === state.selectedLabelPrinter._id)

      if (updatedLabelPrinter) {
        state.selectedLabelPrinter = updatedLabelPrinter
        LocalStorageManager.setLocalStorageKey(
          "labelPrinter",
          JSON.stringify(updatedLabelPrinter)
        );
      }
    },
  },
  actions: {
    goTo: ({ commit, state }, payload) => {
      commit("goTo", payload);
    },
    readCookies: ({ commit, dispatch }, payload) => {
      try {
        var theme = LocalStorageManager.getLocalStorageKey("theme");
        if (theme) {
          if (theme === "light") {
            Utilities.setTheme("light light-background");
          } else {
            Utilities.setTheme("dark");
          }
        }

        var order = LocalStorageManager.getLocalStorageKey("order");
        if (order) {
          order = JSON.parse(order);
        }

        var selectedCustomer =
          LocalStorageManager.getLocalStorageKey("selectedCustomer");
        if (selectedCustomer) {
          selectedCustomer = JSON.parse(selectedCustomer);
        }

        var employee = LocalStorageManager.getLocalStorageKey("employee");
        if (employee) {
          employee = JSON.parse(employee);
        }
        var user = LocalStorageManager.getLocalStorageKey("user");
        if (user) {
          user = JSON.parse(user);
        }
        var workstation = LocalStorageManager.getLocalStorageKey("workstation");
        if (workstation && workstation != "undefined") {
          workstation = JSON.parse(workstation);
        }
        var labelPrinter =
          LocalStorageManager.getLocalStorageKey("labelPrinter");
        if (labelPrinter) {
          labelPrinter = JSON.parse(labelPrinter);
        }

        const storeId = LocalStorageManager.getLocalStorageKey("storeId");
        dispatch("loginModule/setRole", storeId);
        commit("readCookies", { theme, order, selectedCustomer, employee });
        commit("loginModule/login", user);
        commit("posModule/setSelectedWorkstation", workstation);
        commit("setSelectedLabelPrinter", labelPrinter);
      } catch (err) {
        console.log(err);
      }
    },
    setSelectedLabelPrinter: ({ commit, state }, payload) => {
      commit("setSelectedLabelPrinter", payload);
    },
    switchTheme: ({ commit, state }, payload) => {
      commit("switchTheme");
    },
    setMessage: ({ commit, state }, payload) => {
      commit("setMessage", payload);
    },
    setIsLoading: ({ commit, state }, payload) => {
      commit("setIsLoading", payload);
    },
    setIsNavBarOpen: ({ commit, state }, payload) => {
      commit("setIsNavBarOpen", payload);
    },
    setCustomerVerificationPopUp: ({ commit, state }, payload) => {
      commit("setCustomerVerificationPopUp", payload);
    },
    setStoreSelectionPopUp: ({ commit, state }, payload) => {
      commit("setStoreSelectionPopUp", payload);
    },
    setLabelPrinterSelectionPopUp: ({ commit, state }, payload) => {
      commit("setLabelPrinterSelectionPopUp", payload);
    },
    setMessagePopUp: ({ commit, state }, payload) => {
      commit("setMessagePopUp", payload);
    },
    setDeliveryPopUp: ({ commit, state }, payload) => {
      commit("setDeliveryPopUp", payload);
    },
    setNotePopUp: ({ commit, state }, payload) => {
      commit("setNotePopUp", payload);
    },
    setSelectedProductPopUp: ({ commit, state }, payload) => {
      commit("setSelectedProductPopUp", payload);
    },
    setFlightsPopUp: ({ commit, state }, payload) => {
      commit("setFlightsPopUp", payload);
    },
    setModal: ({ commit, state }, payload) => {
      commit("setModal", payload);
    },
    setSellOptionsPopUp: ({ commit, state }, payload) => {
      commit("setSellOptionsPopUp", payload);
    },
    setCreditMemoPopUp: ({ commit, state }, payload) => {
      commit("setCreditMemoPopUp", payload);
    },
    setCouponPopUp: ({ commit, state }, payload) => {
      commit("setCouponPopUp", payload);
    },
    logEvent: ({ commit, state }, payload) => {
      HttpService.post(`${Apis.backendBaseUrl}${Apis.monitoring.logEvent}`, {
        logString: payload.logString,
      });
    },
  },
  modules: {
    loginModule,
    productModule,
    groupModule,
    creditMemoModule,
    couponModule,
    msoCouponModule,
    paymentModule,
    taxSetupModule,
    supplierModule,
    chartOfAccountModule,
    organizationModule,
    msoChartOfAccountModule,
    msoGroupModule,
    msoSupplierModule,
    msoProductModule,
    menuModule,
    storeModule,
    employeeModule,
    customerModule,
    purchaseOrderModule,
    stateModule,
    itemModule,
    orderModule,
    posModule,
    queueModule,
    returnModule,
    adjustmentModule,
    disposalModule,
    onlineStoreGroupModule,
    todaysMenuModule,
    flightModule,
    journalModule,
    timeSheetModule,
    giftCardModule,
    reports,
    returnItemModule,
    fulfillmentModule,
    cashReconciliationModule,
    loyaltyModule,
    inventoryCountModule,
    dashboardModule,
    tagModule,
    brandModule,
  },
});
