var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"base-button",class:[
  _vm.baseButtonColor,
  {
    'base-button--only-icon': !_vm.hasLabel || _vm.isLoading,
    'button-primary': _vm.focus,
    'base-button--small-label': _vm.hasLabel && _vm.smallLabel
  },
  _vm.sizeButton ],attrs:{"aria-label":_vm.ariaLabel}},'button',_vm.$attrs,false),_vm.$listeners),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c('i',{staticClass:"fas fa-spin fa-circle-notch"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],class:{ 'small-label': _vm.smallLabel }},[(_vm.isIconValid)?_c('font-awesome-icon',{attrs:{"icon":[_vm.iconGroup, _vm.iconName]}}):_vm._e(),_c('span',{class:{
      'base-button__label': true,
      'responsive-label': _vm.isIconValid,
    }},[_vm._v(" "+_vm._s(_vm.label))]),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }