import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  flavors: [],
  effects: [],
  terpenes: [],
  tags: [],
};

const mutations = {
  setFlavors: (state, payload) => {
    state.flavors = payload || [];
  },
  setTerpenes: (state, payload) => {
    state.terpenes = payload || [];
  },
  setEffects: (state, payload) => {
    state.effects = payload || [];
  },
  setTags: (state, payload) => {
    state.tags = payload || [];
  },
};

const getters = {
  effects: (state) => state.effects,
  flavors: (state) => state.flavors,
  terpenes: (state) => state.terpenes,
  tags: (state) => state.tags,
};

const actions = {
  getAll: ({ commit, state }) => {
    HttpService.get(
      Apis.backendBaseUrl + Apis.tag.getAll,
      function (response, error) {
        if (response) {
          const body = response.data.body;
          const flavors = body.flavors || [];
          const effects = body.effects || [];
          const terpenes = body.terpenes || [];
          const tags = body.tags || [];

          flavors.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          );
          effects.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          );
          terpenes.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          );
          tags.sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          );

          commit("setEffects", effects);
          commit("setFlavors", flavors);
          commit("setTerpenes", terpenes);
          commit("setTags", tags);
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
