import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedMsoCoupon } from "@/views/MSOCoupon/inputs";
const state = {
  msoCoupons: [],
  msoCoupon: { ...defaultSelectedMsoCoupon },
  loadingStatus: {
    saveMsoCoupon: false,
    getMsoCoupons: false,
    getMsoCouponById: false,
    deleteMsoCoupon: false,
  },
  msoCouponPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setMsoCoupons: (state, payload) => {
    state.msoCoupons = payload;
  },
  setMsoCoupon: (state, payload) => {
    if (payload._id === "NEW")
      state.msoCoupon = { ...defaultSelectedMsoCoupon };
    else state.msoCoupon = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setMsoCouponPopUp: (state, payload) => {
    state.msoCouponPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  msoCoupons: (state) => state.msoCoupons,
  msoCoupon: (state) => state.msoCoupon,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  msoCouponPopUp: (state) => state.msoCouponPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveMsoCoupon: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveMsoCoupon", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveMsoCoupon,
      payload.msoCoupon,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveMsoCoupon",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteMsoCoupon: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteMsoCoupon",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteMsoCoupon,
      payload.msoCoupon,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteMsoCoupon",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getMsoCouponById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getMsoCouponById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getMsoCouponById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getMsoCouponById",
          status: false,
        });
        if (response) {
          commit("setMsoCoupon", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setMsoCouponPopUp: ({ commit, state }, payload) => {
    commit("setMsoCouponPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
