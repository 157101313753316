var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Journal Batch Balance Check","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
      {
        name: 'financeJournalBatchCheck',
        label: 'Journal Batch Balance Check',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.formattedData),function(data,index){return _c('tr',{key:index,class:{ total2: data.isTotal }},[(!data.isTotal)?_c('td',[_vm._v(_vm._s(data.batchNumber))]):_c('td',[_vm._v("Batch Totals")]),_c('td',[_vm._v(_vm._s(data.date))]),_c('td',[_vm._v(_vm._s(data.idNo))]),_c('td',[_vm._v(_vm._s(data.origin))]),_c('td',[_vm._v(_vm._s(data.accountNo))]),_c('td',[_vm._v(_vm._s(data.name))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(data.debits)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(data.credits)))]),(!data.isTotal)?_c('td'):_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(data.debits - data.credits)))]),_c('td',[_vm._v(_vm._s(data.notes))]),_c('td',[_vm._v(_vm._s(data.customer))]),_c('td',[_vm._v(_vm._s(data.order))]),_c('td',[_vm._v(_vm._s(data.supplier))]),_c('td',[_vm._v(_vm._s(data.purchaseOrder))])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"6","align":"left"}},[_vm._v("Grand Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.debits)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.credits)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.debits - _vm.reportData.total.credits)))])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Batch #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Posted On")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Id #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("O")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Account#")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Name")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Debits")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Credits")]),_c('th',{attrs:{"align":"right"}}),_c('th',{attrs:{"align":"left"}},[_vm._v("Type")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Customer")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Order")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Purchase Order")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }