<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader title="Journal Batch Balance Check" :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'financeReport', label: 'Finance' },
          {
            name: 'financeJournalBatchCheck',
            label: 'Journal Batch Balance Check',
          },
        ]" @export="exportToCsv" :disableExport="!reportData" />
        <br class="no-print" />
        <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
          :percentage="percentageCompleted" :filterBySaleType="false" />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr>
                  <th align="left">Batch #</th>
                  <th align="left">Posted On</th>
                  <th align="left">Id #</th>
                  <th align="left">O</th>
                  <th align="left">Account#</th>
                  <th align="left">Name</th>
                  <th align="right">Debits</th>
                  <th align="right">Credits</th>
                  <th align="right"></th>
                  <th align="left">Type</th>
                  <th align="left">Customer</th>
                  <th align="left">Order</th>
                  <th align="left">Supplier</th>
                  <th align="left">Purchase Order</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(data, index) of formattedData" :key="index" :class="{ total2: data.isTotal }">
                  <td v-if="!data.isTotal">{{ data.batchNumber }}</td>
                  <td v-else>Batch Totals</td>
                  <td>{{ data.date }}</td>
                  <td>{{ data.idNo }}</td>
                  <td>{{ data.origin }}</td>
                  <td>{{ data.accountNo }}</td>
                  <td>{{ data.name  }}</td>
                  <td align="right"> {{ Utilities.formatPrice(data.debits) }}</td>
                  <td align="right"> {{ Utilities.formatPrice(data.credits) }}</td>

                  <td v-if="!data.isTotal"></td>
                  <td align="right" v-else>{{ Utilities.formatPrice(data.debits - data.credits) }}</td>

                  <td>{{ data.notes }}</td>
                  <td>{{ data.customer }}</td>
                  <td>{{ data.order }}</td>
                  <td>{{ data.supplier }}</td>
                  <td>{{ data.purchaseOrder }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="6" align="left">Grand Totals</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.debits) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.credits) }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.debits - reportData.total.credits) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        Utilities,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Journal Batch Balance Check");
    },
    computed: {
      ...mapGetters({
        isLoading: "reports/isLoading",
        getData: "reports/getData",
        percentageCompleted: 'reports/percentageCompleted'
      }),
      reportData() {
        return this.getData;
      },
      formattedData() {
      const data = this.reportData;
      let rows = []
      for (let [batchNumber, batchNumberValue] of Object.entries(data.list)) {
        for (let [itemId, item] of Object.entries(batchNumberValue.items)) {
          rows.push(item)
        }
        rows.push({ ...batchNumberValue.total, isTotal: true })
      }

      return rows;
    }
    },
    methods: {
      ...mapActions({
        generateReport: "reports/generate",
        clearData: "reports/clearData",
      }),
      performGetReport(filter) {
        let reportType = "financeJournalBatchCheck";
        this.generateReport({
          reportType,
          ...filter,
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "financeJournalBatchCheck");
      },
    },
    beforeDestroy() {
      this.clearData()
    }
  };
  </script>

  <style lang="scss">
  @import "./_financeJournalBatchCheck.scss";