export default {
  match: {
    ">": function (ref, val) {
      return val > ref;
    },
    ">=": function (ref, val) {
      return val >= ref;
    },
    "<": function (ref, val) {
      return val < ref;
    },
    "<=": function (ref, val) {
      return val <= ref;
    },
    "=": function (ref, val) {
      return val == ref;
    },
  },
  filterTaxes(taxes, type, date, thc = null, inventoryType = null) {
    let taxesFiltered = JSON.parse(JSON.stringify(taxes));
    if (type) {
      taxesFiltered = taxesFiltered.filter((t) => t.taxType == type);
    }

    if (date) {
      taxesFiltered = taxesFiltered.filter((t) => {
        const start = new Date(t.effectiveDate);
        let end = t.endDate ? new Date(t.endDate) : null;

        if (start > date) {
          return false;
        }

        if (end && end < date) {
          return false;
        }

        return true;
      });
    }

    // if (false) {
    // taxesFiltered = taxesFiltered.filter((t) => t.taxGroup == taxGroup);
    // }

    if (thc) {
      let taxesFilteredWithThc = [];
      const tempTaxesWithoutThc = taxesFiltered.filter(
        (t) => !t.thcOperator || t.thcOperator.trim().length == 0
      );

      taxesFilteredWithThc = taxesFiltered.filter(
        (t) => t.thcOperator && t.thcOperator.trim().length > 0
      );
      if (taxesFilteredWithThc && taxesFilteredWithThc.length > 0) {
        taxesFilteredWithThc = taxesFiltered.filter((t) =>
          this.match[t.thcOperator](+t.thcPercent / 100, thc)
        );
      }
      taxesFiltered = [...tempTaxesWithoutThc, ...taxesFilteredWithThc];
    }

    if (inventoryType) {
      let taxesFilteredWithInventoryType = [];
      const tempTaxesWithoutInventory = taxesFiltered.filter(
        (t) => !t.thcInventoryTypes || t.thcInventoryTypes.length == 0
      );

      taxesFilteredWithInventoryType = taxesFiltered.filter(
        (t) => t.thcInventoryTypes && t.thcInventoryTypes.length > 0
      );
      taxesFilteredWithInventoryType = taxesFiltered.filter((t) =>
        t.thcInventoryTypes.some((inv) => inv.includes(inventoryType))
      );
      taxesFiltered = [
        ...tempTaxesWithoutInventory,
        ...taxesFilteredWithInventoryType,
      ];
    }

    return taxesFiltered;
  },
  calculateTax(
    taxes,
    type,
    date = new Date(),
    subTotal,
    discount = 0,
    item,
    inventoryType = null,
    isAllIn = false
  ) {
    let taxTotal = 0;
    let taxTotalAL = 0;
    const thc = this.calculateThc(item);
    const filteredTaxes = this.filterTaxes(
      taxes,
      type,
      date,
      thc,
      inventoryType
    );

    filteredTaxes.sort((a, b) => a.taxOrder < b.taxOrder);
    let rateCumulative = 0;
    let rateTotal = 0;
    let rateTaxTotal = 0;

    for (let i = 0; i < filteredTaxes.length; i++) {
      const tax = filteredTaxes[i];
      rateTaxTotal = tax.taxRate + rateCumulative;
      if (tax.isCumulative) {
        rateCumulative += rateTaxTotal;
      } else {
        rateCumulative = 0;
      }
      rateTotal += rateTaxTotal;
    }

    if (isAllIn) {
      taxTotal = (
        subTotal -
        discount -
        (subTotal - discount) / (1 + rateTotal)
      ).toFixed(6);
      taxTotalAL = (subTotal - subTotal / (1 + rateTotal)).toFixed(6);
    } else {
      taxTotal = (rateTotal * (subTotal - discount)).toFixed(6);
      taxTotalAL = (rateTotal * subTotal).toFixed(6);
    }

    let taxesDetails = [];
    for (let i = 0; i < filteredTaxes.length; i++) {
      const tax = filteredTaxes[i];
      const taxRate = tax.taxRate;

      const rate = taxRate / rateTotal;

      const value = {
        _id: tax._id,
        taxRate: tax.taxRate,
        name: tax.name,
        taxValue: (rate * taxTotal).toFixed(6),
        taxValueAL: (rate * taxTotalAL).toFixed(6),
      };

      taxesDetails.push(value);
    }

    return {
      taxTotal: +taxTotal,
      taxTotalAL: +taxTotalAL,
      taxesDetails,
    };
  },
  calculateThc(item) {
    if (!item) return null;
    const thc = +(
      +item.chemicalComposition.thc +
      +item.chemicalComposition.thca * 0.877
    ).toFixed(2);
    return thc;
  },
  calculateTaxesPerOrder(
    taxes,
    orderType,
    date = new Date(),
    products,
    isAllInCannabis = false,
    isAllInNonCannabis = false
  ) {
    let taxTotal = 0;
    let taxesApplied = new Set();

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const isCannabis = product.externalFields.group.isCannabis;
      const isArmsLength = isCannabis
        ? product.externalFields.supplier.isArmsLength
        : false;

      let taxType = "non-cannabis";
      if (orderType == "medical" && isCannabis) {
        taxType = "medical";
      } else if (orderType == "recreational" && isCannabis) {
        taxType = "recreational";
      }

      let allIn = isCannabis ? isAllInCannabis : isAllInNonCannabis;

      for (let j = 0; j < product.items.length; j++) {
        const item = product.items[j];
        const subTotal = (item.price ? item.price : item.unitCost) || 0;
        const discount = item.discount || 0;
        const quantity = item.quantity;
        const inventoryType = item.inventoryType;

        let partialTax = this.calculateTax(
          taxes,
          taxType,
          date,
          subTotal,
          discount,
          item,
          inventoryType,
          allIn
        );

        const partialTaxesApplied = partialTax.taxesDetails;
        for (let z = 0; z < partialTaxesApplied.length; z++) {
          const partialTaxApplied = partialTaxesApplied[z];
          taxesApplied.add({
            taxId: partialTaxApplied._id,
            name: partialTaxApplied.name,
            taxRate: partialTaxApplied.taxRate,
          });
        }

        if (allIn) continue;

        if (isArmsLength) {
          taxTotal += partialTax.taxTotalAL * quantity;
        } else {
          taxTotal += partialTax.taxTotal * quantity;
        }
      }
    }

    const taxesRecord = Array.from(taxesApplied);

    return { taxesRecord, taxTotal };
  },
};
