<template>
  <div id="FlightsPopUp">
    <div
      v-on:click.stop="close"
      class="overlay-div"
      v-if="flightsPopUp.isOpen"
    ></div>
    <div
      v-if="flightsPopUp.isOpen"
      class="default-pop-up-center-div center bordered"
    >
      <span class="h1">Flights</span>
      <div class="padded center">
        <div class="flight-table">
          <div
            class="row elevated-1 cart-tem pointer"
            v-for="p in flights"
            :key="p.id_No"
            :class="{ 'pulse-background': p.quantity === 0 && p.isLoading }"
          >
            <div
              v-show="p.quantity === 0"
              class="cell flex"
              @click="() => performSelectFlight(p, 'ADD')"
            >
              <div style="flex: 1">
                <b>{{ p.flight_Desc }}</b>
              </div>
              <div>
                <b>{{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(p.price_Unit)
                }}</b>
              </div>
            </div>
            <div v-show="p.quantity > 0" class="cell flex">
              <div style="flex: 1">
                <b>{{ p.flight_Desc }}</b>
              </div>
              <div>
                <b>{{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(p.price_Unit)
                }}</b>
              </div>
            </div>
            <div v-show="p.quantity > 0" class="padded--10"></div>
            <div v-show="p.quantity > 0">
              <div
                :class="{
                  'interspace button-info': p.quantity > 0,
                  'interspace button-secondary--disabled': 0 === p.quantity,
                }"
                @click="() => performSelectFlight(p, 'SUBTRACT')"
              >
                <i class="fas fa-minus"></i>
              </div>

              <div class="interspace quantity-number">
                <span v-show="!p.isLoading" class="h1">{{ p.quantity }}</span>
                <span v-show="p.isLoading" class="h1">
                  <i class="fas fa-spin fa-circle-notch"></i>
                </span>
              </div>

              <div
                :class="{ 'interspace button-secondary': true }"
                @click="() => performSelectFlight(p, 'ADD')"
              >
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="padded center">
        <div
          class="button-info full-width-r"
          @click="() => this.setFlightsPopUp({ isOpen: false })"
        >
          <span>Close</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Configuration from "@/resources/Configuration";
import Utilities from "@/lib/Utilities";

export default {
  name: "FlightsPopUp",
  data() {
    return {};
  },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.setFlightsPopUp({ isOpen: false });
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters([
      "flightsPopUp",
      "flights",
      "loadingStatus",
      "selectedCustomer",
      "order",
    ]),
  },
  methods: {
    ...mapActions(["setFlightsPopUp", "addRemoveItemToCart", "setMessage"]),
    performSelectFlight: function (f, mode) {
      if (this.selectedCustomer.id_No === undefined) {
        this.setMessage({
          text: "Create an order and select a customer to add items to the cart.",
          type: "info",
        });
        return;
      }

      if (this.loadingStatus.isAddRemoveItemToCartLoading) {
        return;
      }

      if (mode === "SUBTRACT") {
        if (f.quantity === 1) {
          mode = "DELETE";
        }
      }
      this.addRemoveItemToCart({
        flight: f,
        mode: mode,
        // positiveCallback: this.close
      });
    },
    close: function () {
      this.setFlightsPopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss" scoped>
/* flight-table */
@mixin flight-table-cell {
  text-align: left;
}

.flight-table {
  box-shadow: $dark-shadow-0;
}

.light .flight-table {
  box-shadow: $light-shadow-0;
}

.flight-table .row {
  padding: 1em 0.857em;
}

.flight-table .row:nth-child(2n) {
  background-color: $dark-elevated-0;
}

.light .flight-table .row:nth-child(2n) {
  background-color: $light-elevated-0;
}

.flight-table .cell {
  text-align: left;
}
/* End flight-table */
</style>
