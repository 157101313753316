var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Sales Promo by Supplier and Group","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesPromoSupplierGroup',
          label: 'Sales Promo by Supplier and Group',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.list)),function(ref){
      var recordId = ref[0];
      var record = ref[1];
return _c('tr',{key:recordId},[_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',[_vm._v(_vm._s(record.group))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.quantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(record.productTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(record.subTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(record.productTotal - record.subTotal))+" ")])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.reportData.total.quantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.productTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.subTotal)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.productTotal - _vm.reportData.total.subTotal))+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Group")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Quantity")]),_c('th',{attrs:{"align":"right"}},[_vm._v("$Pre-Promo Sales Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("$Post-Promo Sales Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("$Difference")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }