<template>
  <div id="page-header-wrapper" :class="
    'page-header-base ' +
    (!pageHeader.isEditorView ? 'page-header-base__list-view' : '')
  ">
    <EntitySubMenu @menu-option-click="
      (entity, action) => $emit('menu-option-click', entity, action)
    " :entity="entity" v-if="pageHeader.isEditorView" :isDisabled="isEdit" />
    <!-- Header Icons -->
    <div v-if="pageHeader.isEditorView" class="editor-section__button-row editor-section-mode margin-bottom-sm">
      <!-- Pagination icons -->
      <div :class="
        'flex editor-section__action-icons ' +
        (isNew || isEditVal ? 'editor-section__disabled' : '')
      ">
        <font-awesome-icon class="editor-section__icon-left" :icon="['fas', 'angle-double-left']"
          @click="handleAdjacent('first')" />
        <font-awesome-icon class="editor-section__icon-left" :icon="['fas', 'angle-left']"
          @click="handleAdjacent('previous')" />
        <font-awesome-icon class="editor-section__icon-left" :icon="['fas', 'angle-right']"
          @click="handleAdjacent('next')" />
        <font-awesome-icon class="editor-section__icon-left" :icon="['fas', 'angle-double-right']"
          @click="handleAdjacent('last')" />
      </div>
      <!-- Top Right Icons -->
      <div class="flex editor-section__action-icons">
        <!-- Info Icon -->
        <span v-if="selectedEntity.isDeleted" class="editor-section__deleted">
          <font-awesome-icon class="editor-section__icon-right" :icon="['fas', 'ban']" />
          <span>Is Deleted</span>
        </span>
        <span v-if="selectedEntity.isArchived" class="editor-section__deleted">
          <font-awesome-icon class="editor-section__icon-right" :icon="['fas', 'ban']" />
          <span>Is Archived</span>
        </span>
        <!-- Search Modal Icon - opens search modal -->
        <font-awesome-icon v-if="pageHeader.cancelPath !== 'organization'" @click="toggleSearchModal" :class="
          'editor-section__icon-right ' +
          (searchDisabled ? 'editor-section__disabled' : '')
        " :icon="['fas', 'search']" />
        <!-- Toggle Sections Icon - open/ close all sections -->
        <div class="editor-section__icon-right" data-cy="section-toggle" @click="toggleSections()">
          <font-awesome-icon :icon="['fas', 'angle-double-up']" v-if="isCollapsed" />
          <font-awesome-icon :icon="['fas', 'angle-double-down']" v-if="!isCollapsed" />
        </div>
      </div>
    </div>
    <div class="page-header">
      <div>
        <div class="bread-crumb h1">
          {{ pageHeader.title }}
          {{ selectedEntity.name ? selectedEntity.name : "" }}
        </div>
        <BreadCrumb :links="links" class="no-print" />
      </div>
      <!--
        ** Action Buttons - top right **
          Read only: Add, Edit, Active Delete
          Edit existing item: Save, Cancel, Disabled Delete
          New Item: Save, Cancel - router takes you back to list page, Disabled Delete
      -->
      <div class="button-wrapper">
        <slot />
        <BaseBtn data-cy="save-button" v-if="pageHeader.isEditorView && isEditVal" label="Save" iconGroup="fas"
          iconName="save" :isLoading="isLoading" @click="saveEntity()"
          v-shortkey="{ saveWindows: ['ctrl', 's'], saveMac: ['meta', 's'] }" @shortkey.native="saveEntity()"
          :disabled="disable" />
        <BaseBtn v-if="
          !hideAddButton &&
          pageHeader.isEditorView &&
          !isNew &&
          !isEditVal &&
          pageHeader.cancelPath !== 'organization'
        " data-cy="add-button" label="Add" iconGroup="fas" iconName="plus" :isLoading="isLoading"
          @click="refreshPage()" :disabled="disable" />
        <BaseBtn v-if="pageHeader.isEditorView && !isEditVal && canEdit" data-cy="edit-button" label="Edit"
          iconGroup="fas" iconName="pen" :isLoading="isLoading" @click="$emit('toggle-edit')"
          v-shortkey="{ saveWindows: ['ctrl', 'e'], saveMac: ['meta', 'e'] }" @shortkey.native="$emit('toggle-edit')"
          :disabled="disable" />
        <router-link v-if="isNew" :to="{ name: pageHeader.cancelPath }">
          <BaseBtn v-if="pageHeader.isEditorView && isEditVal" label="Cancel" iconGroup="fas" iconName="times"
            :colorType="3" :isLoading="isLoading" :disabled="disable" />
        </router-link>
        <BaseBtn v-else-if="pageHeader.isEditorView && isEditVal" label="Cancel" iconGroup="fas" iconName="times"
          :colorType="3" :isLoading="isLoading" @click="handleCancel(), setIsEdit()" :disabled="disable" />
        <BaseBtn data-cy="archive-button" v-if="
          pageHeader.isEditorView &&
          entity !== 'organization' &&
          !enableDelete[entity] &&
          canArchive
        " :label="selectedEntity.isArchived ? 'Unarchive' : 'Archive'" iconGroup="fas" iconName="trash" :colorType="1"
          :isLoading="isLoading" @click="handleArchive" :disabled="disable || isEditVal || isNew" />
        <BaseBtn data-cy="delete-button" v-if="
          pageHeader.isEditorView &&
          entity !== 'organization' &&
          enableDelete[entity]
        " label="Delete" iconGroup="fas" iconName="trash" :colorType="1" :isLoading="isLoading" @click="handleDelete"
          :disabled="
            disable ||
            disableDelete ||
            isEditVal ||
            isNew ||
            selectedEntity.isDeleted
          " />
        <BaseBtn v-if="
          !pageHeader.isEditorView &&
          !hideAddButton &&
          pageHeader.routerPath !== 'customerCheckIn' &&
          pageHeader.routerPath !== 'settings' &&
          pageHeader.routerPath !== 'todaysMenu' &&
          pageHeader.routerPath !== 'stateInventory'
        " data-cy="new-button" label="Add" iconGroup="fas" iconName="plus"
          @click="() => handleAddNew(pageHeader.routerPath)" :disabled="disable" />
        <BaseBtn v-else-if="pageHeader.isEditorView && !isEditVal && canPost" style="margin-left: 1rem" label="Post"
          iconGroup="fas" iconName="angle-up" :colorType="2" :isLoading="isLoading" @click="$emit('post')"
          :disabled="disable" />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapActions, mapGetters } from "vuex";
import Constants from "@/resources/Constants";
import {
  deleteEntity,
  undeleteEntity,
  getEntityById,
  entityModule,
  setEntity,
  enableDelete,
  entityRoute,
} from "@/utils/entity-mappers";
import {
  getAdjacent,
  postEntity,
  archiveEntity,
  deleteEntity as deleteEntityService,
} from "@/services/entityService";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/index";
import { routeToEntity } from "@/utils/routeToEntity";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { validateEntityBeforeSaving } from "@/utils/validation/index";

import EntitySubMenu from "@/components/EntitySubMenu.vue";
library.add(faUserSecret);
library.add(faUserSecret);

export default {
  name: "PageHeader",
  components: {
    BreadCrumb,
    FontAwesomeIcon,
    EntitySubMenu,
    BaseBtn,
  },
  props: {
    entity: {
      type: String,
    },
    pageHeader: {},
    links: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    routerPath: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    hideAddButton: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
    },
    selectedEntity: {
      type: Object,
      default: function () {
        return { name: "" };
      },
    },
    canArchive: {
      type: Boolean,
      default: true,
    },
    canPost: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    customArchiveMessage: {
      type: String,
      default: null,
    },
    preCheckBeforeAddAction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      showDeleteConfirmationModal: false,
      enableDelete,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "employeeModule/loadingStatus",
      employee: "employeeModule/employee",
      user: "loginModule/user",

    }),
    isNew() {
      return this.selectedEntity?._id === "NEW";
    },
    isEditVal: {
      get() {
        return this.isEdit;
      },
      set(val) {
        this.$emit("updateIsEdit", !val);
      },
    },
    getMode() {
      if (!this.isEdit) return { iconLabel: "eye", label: "View" };
      if (this.isEditVal && !this.isNew)
        return { iconLabel: "user-edit", label: "Edit" };
      else return { iconLabel: "user-plus", label: "Add" };
    },
    paginationDisabled() {
      return this.disable || this.isNew || this.isEditVal;
    },
    searchDisabled() {
      return this.disable || this.isNew || this.isEditVal;
    },
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      deleteEmployee: "employeeModule/deleteEmployee",
      undeleteEmployee: "employeeModule/undeleteEmployee",
      logEvent: "logEvent",
    }),
    handleAddNew: function (url) {
      if (this.preCheckBeforeAddAction) {
        if (!this.preCheckBeforeAddAction()) return;
      }
      var path = `${entityModule[this.entity]}/${setEntity[this.entity]}`;
      this.$store.commit(
        `${entityModule[this.entity]}/${setEntity[this.entity]}`,
        { _id: "NEW" }
      );
      this.$router.push({ path: url });
      document.getElementById("app-body").scrollTo(0, 0);
    },
    setIsEdit: function () {
      this.isEditVal = !this.isEditVal;
    },
    toggleShowModal: function () {
      this.showModal = !this.showModal;
    },
    refreshPage: function () {
      this.$router.push(this.pageHeader.addPath);
      this.$store.commit(
        `${entityModule[this.entity]}/${setEntity[this.entity]}`,
        { _id: "NEW" }
      );
      this.$emit("toggle-edit");
      document.getElementById("app-body").scrollTo(0, 0);
    },
    toggleSections: function () {
      this.$emit("toggle-sections");
    },
    toggleDeleteConfirmationModal: function () {
      if (this.selectedEntity.isDeleted) this.$emit("undelete");
      else this.$emit("delete");
    },
    toggleSearchModal: function () {
      if (!this.isEditVal && !this.isNew) this.$emit("show-search-modal");
    },
    handleAdjacent: function (direction) {
      if (!this.isNew && !this.isEditVal) {
        let createdAt =
          this.$store.state[entityModule[this.entity]][this.entity].createdAt;
        let _id = this.$store.state[entityModule[this.entity]][this.entity]._id;
        getAdjacent(
          {
            createdAt,
            _id: _id,
            mode: direction,
            positiveCallback: (data) => {
              if (data) {
                routeToEntity(this.entity, data);
              }
            },
          },
          this.entity
        );
      }
      this.logEvent({ logString: `action: entity-navigation-click user: ${this.user?.email}` })
    },
    handleCancel: function () {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          this.setIsEdit();
          this.$emit("toggle-edit");
          this.$store.dispatch(
            `${entityModule[this.entity]}/${getEntityById[this.entity]}`,
            {
              _id: this.selectedEntity._id,
            }
          );
        },
      });
    },
    handleDelete: function () {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: Constants.strings.deletionConfirmation,
        positiveCallback: this.deleteEntityCallback,
      });
    },
    deleteEntityCallback() {
      deleteEntityService(
        {
          id: this.$route.query.id,
          positiveCallback: () => {
            this.$router.push({ name: `${entityRoute[this.entity]}` });
          },
        },
        this.entity
      );
    },
    handleArchive: function () {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.customArchiveMessage
          ? this.customArchiveMessage
          : Constants.strings.archiveConfirmation,
        positiveCallback: this.archiveEntity,
      });
    },
    archiveEntity: function () {
      this.isLoading = true;
      this.disable = true;
      archiveEntity(
        {
          id: this.selectedEntity._id,
          positiveCallback: this.fetchEntity,
          updatedAt: this.selectedEntity.updatedAt
        },
        this.entity,
        this.selectedEntity.isArchived
      );
    },
    undeleteEntity: function () {
      if (!this.loadingStatus[deleteEntity[this.entity]]) {
        const normalizedEntity = normalizeEntityBeforeSaving[this.entity](
          this.employee
        );
        this.$store.dispatch(
          `${entityModule[this.entity]}/${undeleteEntity[this.entity]}`,
          {
            employee: normalizedEntity,
          }
        );
      }
    },
    fetchEntity: function (data) {
      this.isLoading = true;
      this.disable = true;
      const id = data.id ? data.id : data._id;
      this.$store.commit(
        `${entityModule[this.entity]}/${setEntity[this.entity]}`,
        data
      );

      const module = `${this.entity}`;
      if (this.$route.query.id !== id) {
        this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
      }
      this.$store.dispatch(
        "setMessage",
        { text: "Success", type: "success" },
        { root: true }
      );
      this.isLoading = false;
      this.disable = false;
      // this.$emit("toggle-edit", true);
    },
    saveEntity: function () {
      let validationResult = { succeeded: true };
      if (validateEntityBeforeSaving[this.entity]) {
        validationResult = validateEntityBeforeSaving[this.entity](
          this.selectedEntity
        );
      }

      if (!validationResult.succeeded) {
        this.$store.dispatch(
          "setMessage",
          {
            text: validationResult.message,
            type: "error",
          },
          { root: true }
        );
        return;
      }

      const normalizedEntity = normalizeEntityBeforeSaving[this.entity](
        this.selectedEntity
      );

      // Add entity reflection data for server side check
      normalizedEntity._entity = this.entity

      if (validationResult.isWarning) {
        this.$store.dispatch(
          "setMessagePopUp",
          {
            isOpen: true,
            title: Constants.strings.warningMessageTitle,
            message: validationResult.message,
            positiveCallback: (data) => {
              this.performPostEntity(normalizedEntity);
            },
          },
          { root: true }
        );
        return;
      }

      this.performPostEntity(normalizedEntity);
    },
    performPostEntity(normalizedEntity) {
      this.isLoading = true;
      this.disable = true;
      postEntity(
        {
          data: normalizedEntity,
          positiveCallback: (data) => {
            const id = data.id ? data.id : data._id;
            this.$store.commit(
              `${entityModule[this.entity]}/${setEntity[this.entity]}`,
              data
            );
            const module = `${this.entity}s`;
            if (this.$route.query.id !== id) {
              this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
            }
            this.$store.dispatch(
              "setMessage",
              { text: "Saved", type: "success" },
              { root: true }
            );
            this.$emit("toggle-edit", true);
            this.$emit("on-post-save", {
              updatedEntity: data,
              previousEntity: this.selectedEntity,
            });
            this.isLoading = false;
            this.disable = false;
          },
          negativeCallback: () => {
            this.isLoading = false
            this.disable = false
          }
        },
        this.entity
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-header-base {
  min-width: 100%;
  z-index: 1001;
  position: sticky;
  top: 0;
  box-shadow: $dark-shadow-0;
  background-color: #1b1b1d;

  &__list-view {
    padding-top: 40px;

    @media screen and (max-width: 400px) {
      padding-top: 0;
    }
  }

  @include respond(print) {
    padding-top: 0;
  }
}

.light .page-header-base {
  background-color: #eaebef;
  box-shadow: $light-shadow-0;
}

.page-header {
  margin-bottom: 10px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-wrapper {
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  margin-left: 10px;
  margin-top: 20px;

  @media screen and (max-width: 400px) {
    margin-top: 0;
    grid-gap: 15px;

    button {
      padding: 9px !important;
    }
  }
}

.editor-section {
  &__icon-right {
    cursor: pointer;
    margin-left: 20px;
  }

  &__icon-left {
    cursor: pointer;
    margin-right: 20px;
  }

  &__button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-accent-color;

    @media (max-width: 800px) {
      padding-top: 10px;
    }
  }

  &__action-icons {
    @media screen and (max-width: 400px) {
      width: 46%;
      justify-content: space-between;
    }
  }

  &__disabled {
    color: gray;
  }

  &__deleted {
    color: red;

    &>span {
      margin-left: 5px;
    }
  }
}

.editor-section__mode {
  color: $dark-text-color-0;
}

.light .editor-section__mode {
  color: $light-text-color-0;
}

.editor-section-mode {
  height: 30px;
  padding-top: 10px;
}

@media screen and (max-width: 800px) {
  .page-header-base {
    width: auto;
    max-width: inherit;

    &__list-view {
      padding-top: 10px;
    }
  }
}
</style>
