import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedFlight } from "@/views/Flight/inputs";
import _ from "lodash";

const state = {
  flights: [],
  flight: _.cloneDeep(defaultSelectedFlight),
  loadingStatus: {
    saveFlight: false,
    getFlights: false,
    getFlightById: false,
    deleteFlight: false,
  },
  flightPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setFlights: (state, payload) => {
    state.flights = payload;
  },
  setFlight: (state, payload) => {
    if (payload._id === "NEW")
      state.flight = { ...JSON.parse(JSON.stringify(defaultSelectedFlight)) };
    else
      state.flight = {
        ...JSON.parse(JSON.stringify(defaultSelectedFlight)),
        ...payload,
      };
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setFlightPopUp: (state, payload) => {
    state.flightPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  flights: (state) => state.flights,
  flight: (state) => state.flight,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  flightPopUp: (state) => state.flightPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveFlight: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveFlight", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveFlight,
      payload.flight,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveFlight",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteFlight: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteFlight",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteFlight,
      payload.flight,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteFlight",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getFlightById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getFlightById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.flights.getById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getFlightById",
          status: false,
        });
        if (response) {
          commit("setFlight", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setFlightPopUp: ({ commit, state }, payload) => {
    commit("setFlightPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
