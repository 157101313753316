import HttpService from "@/services/HttpService";
import axios from 'axios';
import Apis from "@/services/Apis";
import { splitRequests } from '@/store/reportStores/helpers/index'
import aggregateList from '@/store/reportStores/helpers/aggregateList'
import aggregateRecords from '@/store/reportStores/helpers/aggregateRecords'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

const dataModel = {
  total: {},
  list: {},
  grandTotals: {},
  records: []
}

const loadingStatus = {
  totalRequests: 1,
  isLoading: false,
  requestsCompleted: 0,
}

const state = {
  data: {},
  totalRequests: 1,
  isLoading: false,
  loadingStatus: {},
};

const mutations = {
  clearData: (state, reportType) => {
    if (reportType) {
      Vue.set(state.data, reportType, cloneDeep(dataModel))
      Vue.set(state.loadingStatus, reportType, cloneDeep(loadingStatus))
    } else {
      state.data = {}
    }
  },
  setTotalRequests: (state, { reportName, value }) => {
    state.loadingStatus[reportName].totalRequests = value;
  },
  incrementRequestsCompleted: (state, reportName) => {
    state.loadingStatus[reportName].requestsCompleted += 1;
  },
  setLoadingStatus: (state, { reportName, value }) => {
    state.loadingStatus[reportName].isLoading = value || false;
  },
  addToList: (state, value) => {
    const reportName = value.reportName;
    const list = value.list;
    const total = value.total;
    const grandTotals = value.grandTotals;
    const records = value.records;
    const others = value.others;

    if (!state.data[reportName]) {
      Vue.set(state.data, reportName, cloneDeep(dataModel))
    }

    state.data[reportName].list = aggregateList(cloneDeep(state.data[reportName].list), list, others, reportName)

    if (records && records.length > 0) {
      state.data[reportName].records = aggregateRecords(records, state.data[reportName].records, others)
    }

    if (total) {
      for (const [key, value] of Object.entries(total)) {
        if (state.data[reportName].total[key]) {
          state.data[reportName].total[key] += value
        } else {
          state.data[reportName].total[key] = value;
        }
      }
    }

    if (grandTotals) {
      for (const [key, value] of Object.entries(grandTotals)) {
        if (state.data[reportName].grandTotals[key]) {
          state.data[reportName].grandTotals[key] += value
        } else {
          state.data[reportName].grandTotals[key] = value;
        }
      }
    }

  }
};

const getters = {
  // new
  isLoading: (state) => state.isLoading,
  percentageCompleted: (state) => {
    if (!state.totalRequests) return 100;
    return Math.min((state.requestsCompleted / state.totalRequests) * 100, 100);
  },
  getData: (state) => state.data,
  // old - deprecating
  loadingStatus: (state) => state.loadingStatus,
  salesTransactions: (state) => state.loadingStatus.getSalesTransactions,
  inventoryTransactions: (state) => state.loadingStatus.getInventoryTransactions,
  financeTransactions: (state) => state.loadingStatus.getFinanceTransactions,
  customerTransactions: (state) => state.loadingStatus.getCustomerTransactions,
};

const actions = {
  clearData: ({ commit }, payload) => {
    commit('clearData', payload?.reportType)
  },
  batchGenerate: ({ commit, state, dispatch }, payload) => {
    for (const reportRequest of payload.requests) {
      dispatch('generate', reportRequest)
    }
  },
  generate: ({ commit, state, dispatch }, payload) => {
    const reportName = payload.reportType;
    commit('clearData', reportName)
    commit('setLoadingStatus', { reportName, value: true });

    const apiUrl = `${Apis.backendBaseUrl}${Apis.reports.generate}`;
    const requests = splitRequests(apiUrl, payload)

    // check if at least one request is present;
    if (!requests || requests.length == 0) {
      return commit(
        "setMessage",
        { text: 'Error while generating the dashboard. Please contact the DF support.', type: "error" },
        { root: true }
      );
    }

    commit('setTotalRequests', { reportName, value: requests.length });
    let promises = requests.map(request => dispatch('makeRequest', { request, reportName }))
    try {
      Promise.all(promises).then((res) => {
        commit('setLoadingStatus', { reportName, value: false });
      })
        .catch((err) => {
          console.log(err)
          commit('setLoadingStatus', { reportName, value: false });
          commit(
            "setMessage",
            { text: 'Error while generating the dashboard. Please contact the DF support.', type: "error" },
            { root: true }
          );
        })
    } catch (err) {
      commit('setLoadingStatus', { reportName, value: false });
      commit(
        "setMessage",
        { text: 'Error while generating the dashboard. Please contact the DF support.', type: "error" },
        { root: true }
      );
    }
  },
  makeRequest: ({ commit }, { request, reportName }) => {
    return axios.get(request)
      .then((res) => {
        commit('incrementRequestsCompleted', reportName);
        const data = res.data.body;
        let list = data.days;
        const total = data.total;
        const grandTotals = data.grandTotals;
        const records = data.records;

        // sometimes the recods is an object instead of an array.......
        // it's an object for the report Sales Profit Margins by Group
        if (records && !Array.isArray(records)) {
          list = records;
        }

        delete data.days;
        delete data.total;
        delete data.grandTotals;
        delete data.records;
        commit('addToList', {
          reportName,
          list,
          total,
          grandTotals,
          records,
          others: { ...data }
        });
      })
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
