import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultFulfillment } from "@/views/Fulfillment/inputs";
import Vue from 'vue'

const state = {
  fulfillment: JSON.parse(JSON.stringify(defaultFulfillment)),
  loadingStatus: {
    find: false,
  },
  fulfillmentPopUp: {
    isOpen: false,
  },
  sort: { postedToJournalAt: 1 },
  orders: []
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setFulfillmentPopUp: (state, payload) => {
    state.fulfillmentPopUp = payload;
  },
  setOrders: (state, payload) => {
    state.orders = payload;
  },
  setIsLoadingOnOrder: (state, payload) => {
    const orderToUpdate = state.orders.find(o => o._id === payload.order._id)
    if (orderToUpdate) {
      Vue.set(orderToUpdate, 'isLoading', payload.isLoading)
    }
  },
  toggleItem: (state, payload) => {
    const orderToUpdate = state.orders.find(o => o._id === payload.order._id)
    if (orderToUpdate) {
      const productToUpdate = orderToUpdate.products.find(p => p._id === payload.product._id)
      if (productToUpdate) {
        const itemToUpdate = productToUpdate.items.find(i => i._id === payload.item._id)
        if (itemToUpdate.isFilled) {
          Vue.set(itemToUpdate, 'isFilled', false)
        } else {
          Vue.set(itemToUpdate, 'isFilled', true)
        }
      }
    }
  },
};

const getters = {
  fulfillment: (state) => state.fulfillment,
  loadingStatus: (state) => state.loadingStatus,
  fulfillmentPopUp: (state) => state.fulfillmentPopUp,
  orders: (state) => state.orders,
  sort: (state) => state.sort,
};

const actions = {
  getOrders: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "filter",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.fulfillment.filter,
      payload,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "filter",
          status: false,
        });
        if (response?.data?.body) {
          commit("setOrders", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  fulfill: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "fulfill",
      status: true,
    });
    commit("setIsLoadingOnOrder", {
      order: payload.order,
      isLoading: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.order.fulfill,
      { order: payload.order },
      function (response, error) {
        commit("setLoadingStatus", { api: "fulfill", status: false });
        commit("setIsLoadingOnOrder", {
          order: payload.order,
          isLoading: false,
        });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback();
          }
          commit(
            "setMessage",
            { text: "Order fulfilled", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  toggleItem: ({ commit, state }, payload) => {
    commit('toggleItem', payload)
  },
  setFulfillmentPopUp: ({ commit, state }, payload) => {
    commit("setFulfillmentPopUp", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
