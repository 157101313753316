var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pos-order-details"},[_c('div',{staticClass:"pos-order-box"},[_c('div',{staticClass:"elevated-0 bordered position-relative",class:{
        'bordered__highlighted--primary':
          _vm.order.isPaid || _vm.order.isVoided || _vm.order.isPreorder,
        'bordered__highlighted--danger': _vm.order.isArchived || _vm.order.isPending,
      }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.order.isPending && !_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"PENDING"}}):_vm._e(),(_vm.order.isPaid && !_vm.order.isArchived && !_vm.order.preOrder)?_c('ContainerLabel',{attrs:{"type":"primary","text":"PAID"}}):_vm._e(),(_vm.order.isVoided && !_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"primary","text":"REVERSED"}}):_vm._e(),(_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"ARCHIVED"}}):_vm._e(),(_vm.order.isPreorder)?_c('ContainerLabel',{attrs:{"type":"primary","text":"PREORDER"}}):_vm._e()],1),_c('div',{staticClass:"pos-order-details__order-summary"},[_c('PriceSummary',{attrs:{"rows":_vm.orderSummary}})],1)],1),_c('div',{staticClass:"pos-order-details__control-buttons"},[_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Pay","iconGroup":"fas","iconName":"dollar-sign","disabled":(!_vm.order.customer && !_vm.order.isRetail) ||
          _vm.order.isArchived ||
          _vm.order.isVoided ||
          !_vm.order.products ||
          _vm.order.products.length == 0},on:{"click":function($event){return _vm.$emit('sell-options')}}}),_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Discount","iconGroup":"fa","iconName":"percent","disabled":_vm.disableDiscount},on:{"click":function($event){return _vm.$emit('set-discount')}}}),_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Gift Card","iconGroup":"fa","iconName":"gift","disabled":_vm.disabledGiftCard},on:{"click":function($event){return _vm.$emit('giftCard-lookup')}}}),_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Notes","iconGroup":"fa","iconName":"sticky-note","disabled":!_vm.order.customer},on:{"click":function($event){return _vm.$emit('set-note')}}}),_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Print","iconGroup":"fa","iconName":"print","disabled":!_vm.order._id},on:{"click":function($event){return _vm.$emit('print')}}}),(_vm.store.isPOSScanningEnabled)?_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Fulfill","iconGroup":"fa","iconName":"box-open","disabled":_vm.disableFulfillmentPrint},on:{"click":function($event){return _vm.$emit('fulfillment-print')}}}):_vm._e(),_c('BaseBtn',{attrs:{"smallLabel":true,"label":"Drawer","iconGroup":"fas","iconName":"money-bill-wave"},on:{"click":function($event){return _vm.$emit('open-cash-drawer')}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }