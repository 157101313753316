import * as Entities from "@/resources/Entities";

import {
  filterCriteria as msoChartOfAccountFilterCriteria,
  searchColumnHeaders as msoChartOfAccountColumnHeaders,
} from "../MSOChartOfAccount/inputs";
// ChartOfAccount Editor Inputs

import Constants from "@/resources/Constants";

// Page Header
export const pageHeader = {
  title: "Chart Of Account",
  isEditorView: true,
  cancelPath: "chartOfAccounts",
  addPath: "chartOfAccountEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Chart Of Account",
  isEditorView: false,
  buttonLabel: "Add",
  routerPath: "chartOfAccountEditor",
};

// ChartOfAccount Information - Main Section
export const chartOfAccountInfoInputs = [
  {
    id: "msoChartOfAccount",
    placeholder: "Select MSO Chart of Account",
    type: "search",
    title: "MSO Chart of Account",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.MSOCHARTOFACCOUNT,
    columnHeaders: msoChartOfAccountColumnHeaders,
    filterCriteria: msoChartOfAccountFilterCriteria,
    filter: {
      "isActive" : true,
      $and : [
        { "isArchived" : false },
        { "isDeleted" : false },
      ]
    },
  },
  {
    id: "canEdit",
    placeholder: "Can Edit After Creation",
    type: "checkBox",
    title: "Can Edit",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "name",
    placeholder: "Account Name",
    type: "input",
    title: "Account Name",
    focus: true,
  },
  {
    id: "accountNo",
    placeholder: "Account #",
    type: "mask",
    title: "Account #",
    mask: Constants.inputMasks.defaultAccountNo,
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "accountNoOrganization",
    placeholder: "Organization Account #",
    type: "input",
    title: "3rd Party Account #",
  },
  {
    id: "category",
    placeholder: "Category",
    type: "select",
    title: "Category",
    options: [
      "Asset",
      "Liability",
      "Stockholders Equity",
      "Income",
      "Cost Of Goods Sold",
      "Expense",
    ],
    class: "grid-row-two-column",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "nbal",
    placeholder: "Account Type",
    type: "select",
    title: "Select Account Type",
    options: ["debit", "credit"],
    class: "grid-row-two-column",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "beginningBalance",
    placeholder: "Beginning Balance",
    type: "money",
    title: "Beginning Balance $",
    disableFunction: (item) => {
      return (
        item._id !== "NEW" &&
        !(item.debitBalance === 0 && item.creditBalance === 0)
      );
    },
  },
  {
    id: "currentBalance",
    placeholder: "Current Balance",
    type: "money",
    title: "Current Balance $",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "debitBalance",
    placeholder: "Debit Balance",
    type: "money",
    title: "Debit Balance $",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "creditBalance",
    placeholder: "Credit Balance",
    type: "money",
    title: "Credit Balance $",
    disableFunction: (item) => {
      return item._id !== "NEW";
    },
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
  {
    id: "isTaxDeductible",
    placeholder: "Tax Deductible",
    type: "checkBox",
    title: "Tax Deductible",
  },
];

export const defaultSelectedChartOfAccount = {
  _id: "NEW",
  name: "",
  accountNo: "",
  accountNoOrganization: "",
  category: "",
  nbal: "",
  isDeleted: false,
  isArchived: false,
  canEdit: true,
  isTaxDeductible: false,
  beginningBalance: 0,
  debitBalance: 0,
  creditBalance: 0,
  currentBalance: 0,
  notes: "",
  msoChartOfAccount: {},
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Account #",
    code: "accountNo",
    field: { type: "input", label: "Account #" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Account Name",
    id: "name",
    size: "large",
  },
  {
    title: "Account #",
    id: "accountNo",
    size: "medium",
  },
  {
    title: "Category",
    id: "category",
    size: "large",
  },
  {
    title: "Account Type",
    id: "nbal",
    size: "medium",
  },
  {
    title: "Is Tax Deductible",
    id: "isTaxDeductible",
    type: "checkbox",
  },
  {
    title: "Organization #",
    id: "msoChartOfAccount.accountNo",
    size: "medium",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Account Name",
    id: "name",
  },
  {
    title: "Account #",
    id: "accountNo",
  },
  {
    title: "Category",
    id: "category",
  },
  {
    title: "Account Type",
    id: "nbal",
  },
  {
    title: "Is Tax Deductible",
    id: "isTaxDeductible",
    type: "checkbox",
  },
];
