var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"supplierAgent-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"selectedEntity":_vm.selectedSupplierAgent,"links":[
        { name: 'home', label: 'Home' },
        { name: 'supplierAgents', label: 'Supplier Agents' },
        {
          name: 'supplierAgentEditor',
          label: _vm.selectedSupplierAgent.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); },"menu-option-click":_vm.menuOptionClick}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},_vm._l((_vm.supplierAgentInputs),function(inputs){return _c('div',{key:inputs.id},[_c('div',{staticClass:"\n                grid-two-column\n                two-columns-layout--responsive-horizontal-space-10\n              "},_vm._l((inputs.inputs),function(input){return _c('div',{key:input.id,class:'input-text' + ' ' + input.class},[_c('Input',{attrs:{"model":_vm.selectedSupplierAgent[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedSupplierAgent[input.id] = $event}}})],1)}),0)])}),0)])]),(_vm.selectedSupplierAgent._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedSupplierAgent.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedSupplierAgent}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }