var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Employee Listing","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'employeeReport', label: 'Employee' },
        {
          name: 'employeeListActive',
          label: 'Employee Listing',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"isLoading":_vm.employeeTransactions,"filterByStoreOrOrg":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.id))]),_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',[_vm._v(_vm._s(record.employeeRole))]),_c('td',[_vm._v(_vm._s(record.store))]),_c('td',[_vm._v(_vm._s(record.role))]),_c('td',[_vm._v(_vm._s(record.dateUpdated))]),_c('td',[_vm._v(_vm._s(record.employeeUpdated))]),_c('td',[_vm._v(_vm._s(record.email))]),_c('td',[_vm._v(_vm._s(record.cell))]),_c('td',[_vm._v(_vm._s(_vm.dateFormmatter(record.employmentDate)))]),_c('td',[_vm._v(_vm._s(record.srn))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(record.hasSaveAccess ? 'Y' : 'N'))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Global Role")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Store")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Role")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Updated")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Updated By")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Email")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Cell")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employment Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Srn #")]),_c('th',{attrs:{"align":"center"}},[_vm._v("Safe Access")])])])}]

export { render, staticRenderFns }