<template>
  <div class="graph elevated-0">
    <div class="graph__header">
      <div class="graph__header-title">
        {{ title }}
      </div>
    </div>
    <div
      v-if="this.$slots.default"
      class="graph__slot"
      :class="{
        'graph__center-slot': centerSlot,
        'graph__slot--y-scroll': yScroll,
      }"
      :style="getSlotStyle"
    >
      <slot></slot>
    </div>
    <div v-else>
      <canvas
        :id="`chart-${id}`"
        :height="height"
        class="graph__canvas"
      ></canvas>
    </div>
    <div
      v-if="loadingPercentage < 100"
      class="graph__loading-bar"
      :style="`width: ${loadingPercentage}%`"
    ></div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "Graph",
  data() {
    return {
      id: undefined,
      chart: undefined,
    };
  },
  props: {
    chartData: { type: Object, default: () => ({}) },
    loadingStatus: Object,
    size: { type: String, default: "md" },
    title: { type: String },
    centerSlot: { type: Boolean, default: true },
    yScroll: { type: Boolean, default: true },
  },
  watch: {
    "chartData.data.labels"() {
      this.chart?.update();
    },
  },
  created() {
    // Assign unique id to graph
    this.id = Math.random();
  },
  mounted() {
    const ctx = document.getElementById(`chart-${this.id}`)?.getContext("2d");
    if (ctx) {
      this.chart = new Chart(ctx, this.chartData);
    }
  },
  computed: {
    height() {
      switch (this.size) {
        case "sm":
          return 100;
        case "md":
          return 200;
        case "lg":
          return 400;
        default:
          return 200;
      }
    },
    loadingPercentage() {
      try {
        return (
          (this.loadingStatus.requestsCompleted /
            this.loadingStatus.totalRequests) *
          100
        );
      } catch (error) {
        return 0;
      }
    },
    showLoadingBar() {
      return this.loadingStatus?.isLoading;
    },
    getSlotStyle() {
      if (this.size === "auto") {
        return {};
      } else {
        return { height: `${this.height}px` };
      }
    },
  },
  methods: {
    clear() {
      this.chart.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_graph.scss";
</style>
