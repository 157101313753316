<template>
  <div class="brand-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :selectedEntity="selectedBrand"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'brands', label: 'Brands' },
          {
            name: 'brandEditor',
            label: selectedBrand.name,
            query: $route.query,
          },
        ]"
        :hideAddButton="true"
        :canArchive="!selectedBrand.isArchived"
        :canEdit="!selectedBrand.isArchived"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
      />
      <div class="section-base">
        <div
          class="
            padded
            elevated-0
            bordered
            position-relative
            order-editor__body
          "
          :class="{
            'bordered__highlighted--primary': selectedBrand.isPaid,
            'bordered__highlighted--danger':
              selectedBrand.isArchived || selectedBrand.isVoided,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedBrand.isArchived"
              type="danger"
              text="ARCHIVED"
            />
          </transition>

          <div class="padded elevated-0 bordered">
            <div class="two-columns-layout two-columns-layout--responsive">
              <div
                class="
                  grid-two-column
                  two-columns-layout--responsive-horizontal-space-10
                "
              >
                <div
                  v-for="input in brandInputs"
                  :key="input.id"
                  class="input-text"
                >
                  <Input
                    :model="selectedBrand[input.id]"
                    @updateModel="selectedBrand[input.id] = $event"
                    :inputData="input"
                    :isEdit="isEdit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditorSection
        :title="`About ${selectedBrand.name || ''}`"
        sectionType="about"
        v-if="selectedBrand._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedBrand" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";

import {
  brandInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
} from "./inputs";
import EditorSection from "@/components/EditorSection";

export default {
  name: "brandEditor",
  data() {
    return {
      Constants: Constants,
      brandInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.BRAND,
      pageHeader,
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
    ContainerLabel
  },
  mounted: function () {
    this.setPageTitle("Brand");
    if (this.$route.query.id) {
      this.getBrandById({ _id: this.$route.query.id });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getBrandById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "brandModule/loadingStatus",
      brand: "brandModule/brand",
    }),
    selectedBrand: {
      get() {
        this.setPageTitle(`Brand ${this.brand.name}`);
        return this.brand;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getBrandById: "brandModule/getBrandById",
      saveBrand: "brandModule/saveBrand",
      deleteBrand: "brandModule/deleteBrand",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "brandModule/getAdjacentBrand",
    }),
    performSave() {
      if (this.loadingStatus.saveBrand) {
        return;
      }

      const brandToSave = this.normalizeEntityBeforeSaving(this.selectedBrand);

      this.saveBrand({
        brand: brandToSave,
        positiveCallback: (brand) => {
          this.setPageTitle(`Brand ${brand.name}`);
          this.selectedBrand = brand;
          if (this.selectedBrand._id === "NEW") {
            router.push({
              name: "brandEditor",
              query: { id: brand._id },
            });
          }
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(brand) {
      const entity = JSON.parse(JSON.stringify(brand));
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteBrand) {
        return;
      }

      const brandToDelete = this.normalizeEntityBeforeSaving(
        this.selectedBrand
      );

      this.deleteBrand({
        brand: brandToDelete,
        positiveCallback: () => {
          router.push({
            name: "brands",
          });
        },
      });
    },
    addBrand() {
      this.isEdit = true;
      this.selectedBrand = this.defaultSelectedBrand;
      this.selectedBrand._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedBrand._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedBrand._id === "NEW") {
        this.isEdit = true;
      }
    },
  },
};
</script>
