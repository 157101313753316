<template>
  <div class="mso-product-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'msoProducts', label: 'MSO Products' },
          {
            name: 'msoProductEditor',
            label: selectedMsoProduct.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedMsoProduct"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div
            class="
              grid-two-column
              two-columns-layout--responsive-horizontal-space-10
            "
          >
            <Input
              :inputData="getInput('msoSupplier')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.msoSupplier"
              @updateModel="selectedMsoProduct.msoSupplier = $event"
            />
            <Input
              :inputData="getInput('msoGroup')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.msoGroup"
              @updateModel="selectedMsoProduct.msoGroup = $event"
            />
            <Input
              :inputData="getInput('name')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.name"
              @updateModel="selectedMsoProduct.name = $event"
            />
            <Input
              :inputData="getInput('cannabisGramsUnit')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.cannabisGramsUnit"
              @updateModel="selectedMsoProduct.cannabisGramsUnit = $event"
            />
            <Input
              :inputData="getInput('brand')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.brand"
              @updateModel="selectedMsoProduct.brand = $event"
            />
            <Input
              :inputData="getInput('onlineStoreName')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.onlineStoreName"
              @updateModel="selectedMsoProduct.onlineStoreName = $event"
            />
            <Input
              :inputData="getInput('isActive')"
              :isEdit="isEdit"
              :model="selectedMsoProduct.isActive"
              @updateModel="selectedMsoProduct.isActive = $event"
            />
            <div class="grid-row-two-column">
              <Input
                :inputData="getInput('imageNormal')"
                :isEdit="isEdit"
                :model="selectedMsoProduct.imageNormal"
                @updateModel="selectedMsoProduct.imageNormal = $event"
              />
              <Input
                :inputData="getInput('imageNormal.secure_url')"
                :isEdit="isEdit"
                :model="selectedMsoProduct.imageNormal.secure_url"
                @updateModel="
                  selectedMsoProduct.imageNormal.secure_url = $event
                "
              />
            </div>
            <Input
              :inputData="getInput('onlineStoreDescription')"
              :isEdit="isEdit"
              :class="getInput('onlineStoreDescription').class"
              :model="selectedMsoProduct.onlineStoreDescription"
              @updateModel="selectedMsoProduct.onlineStoreDescription = $event"
            />
            <div class="option" :class="{ 'option--disabled': !isEdit }">
              <p class="input-text__label">Flavors</p>
              <multiselect
                v-model="selectedMsoProduct.flavors"
                :options="flavors"
                :close-on-select="true"
                placeholder="Search flavor"
                :hide-selected="true"
                :multiple="true"
                label="value"
                track-by="uuid"
                :searchable="false"
              />
            </div>
            <div class="option" :class="{ 'option--disabled': !isEdit }">
              <p class="input-text__label">Effects</p>
              <multiselect
                :custom-label="customLabelOption"
                v-model="selectedMsoProduct.effects"
                :options="effects"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                placeholder="Search effect"
                label="value"
                track-by="uuid"
                :searchable="false"
              />
            </div>
            <div class="option" :class="{ 'option--disabled': !isEdit }">
              <p class="input-text__label">Terpenes</p>
              <multiselect
                :custom-label="customLabelOption"
                v-model="selectedMsoProduct.terpenes"
                :options="terpenes"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                placeholder="Search Terpenes"
                label="value"
                track-by="uuid"
                :searchable="false"
              />
            </div>
            <div class="option" :class="{ 'option--disabled': !isEdit }">
              <p class="input-text__label">Tags</p>
              <multiselect
                :custom-label="customLabelOption"
                v-model="selectedMsoProduct.tags"
                :options="tags"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                placeholder="Search Tag"
                label="value"
                track-by="uuid"
                :searchable="false"
              />
            </div>
            <div
              class="extra-info grid-row-two-column"
              :class="{ 'extra-info--edit': isEdit }"
            >
              <div class="margin-bottom-md space-between">
                <p>Extra Info</p>
                <inline-button
                  label="Add"
                  type="plain-primary responsive-column form-height wide"
                  @click="addExtraInfo"
                  :disabled="!isEdit"
                />
              </div>
              <div
                v-for="(extraInfo, i) in selectedMsoProduct.extraInfo"
                :key="i"
                class="two-columns-layout--border-bottom"
              >
                <div class="extra-info-record">
                  <div class="flex-right">
                    <inline-button
                      label="Delete"
                      class="desktop-only-inline-block"
                      type="plain-danger responsive-column form-height wide"
                      @click="() => deleteExtraInfo(i)"
                      :disabled="!isEdit"
                    />
                  </div>
                  <div
                    v-for="input in extraInfoInputs"
                    :key="input.id"
                    :class="'input-text ' + input.class"
                  >
                    <Input
                      :model="lodash.get(extraInfo, input.id)"
                      @updateModel="lodash.set(extraInfo, input.id, $event)"
                      :inputData="input"
                      :isEdit="isEdit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Input
              :inputData="getInput('notes')"
              :isEdit="isEdit"
              :class="getInput('notes').class"
              :model="selectedMsoProduct.notes"
              @updateModel="selectedMsoProduct.notes = $event"
            />
          </div>
        </div>
      </div>

      <EditorSection
        title="Associated Products"
        v-if="selectedMsoProduct._id !== 'NEW'"
        :bus="bus"
      >
        <SearchTable
          :key="id"
          :entity="Entities.PRODUCT"
          :hideFilters="true"
          :filter="preFilter"
        />
      </EditorSection>

      <EditorSection
        :title="`About ${selectedMsoProduct.name || ''}`"
        sectionType="about"
        v-if="selectedMsoProduct._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedMsoProduct" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SearchTable from "@/components/SearchTable";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import Multiselect from "vue-multiselect";

import {
  msoProductInfoInputs,
  defaultSelectedMsoProduct,
  pageHeader,
  filterCriteria,
  columnHeaders,
  extraInfoInputs,
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "msoProductEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      msoProductInfoInputs,
      isEdit: false,
      defaultSelectedMsoProduct,
      msoProducts: [],
      msoProductsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.MSOPRODUCT,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
      lodash: _,
      extraInfoInputs,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    Multiselect,
    SearchTable
  },
  mounted: function () {
    this.getTags();
    this.setPageTitle("MSOProduct");
    if (this.$route.query.id) {
      this.getMsoProductById({
        _id: this.$route.query.id,
        positiveCallback: (product) => {
          this.setPageTitle(`Product ${product.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getMsoProductById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      msoProduct: "msoProductModule/msoProduct",
      flavors: "tagModule/flavors",
      effects: "tagModule/effects",
      terpenes: "tagModule/terpenes",
      tags: "tagModule/tags",
    }),
    selectedMsoProduct: {
      get() {
        this.setPageTitle("MSO Product " + this.msoProduct.name);
        return this.prefillEntityBeforeRendering(this.msoProduct);
      },
      set(entity) {
        return entity;
      },
    },
    preFilter: function () {
      return {
        msoProduct: this.id,
      };
    },
  },
  methods: {
    ...mapActions({
      getMsoProductById: "msoProductModule/getMsoProductById",
      saveMsoProduct: "msoProductModule/saveMsoProduct",
      deleteMsoProduct: "msoProductModule/deleteMsoProduct",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getMsoProducts: "msoProductModule/getMsoProducts",
      getTags: "tagModule/getAll",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    prefillEntityBeforeRendering(selectedMsoProduct) {
      if (!selectedMsoProduct.imageNormal) {
        selectedMsoProduct.imageNormal = {}
      }

      if (!selectedMsoProduct.imageThumbnail) {
        selectedMsoProduct.imageThumbnail = {}
      }
      return selectedMsoProduct
    },
    clearItemFromSearch: function (entity) {
      this.selectedMsoProduct[entity] = null;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedMsoProduct._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedMsoProduct._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedMsoProduct.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedMsoProduct = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },

    selectItemFromSearch: function (entity, product) {
      this.selectedMsoProduct[entity] = product;
    },

    getProperty(id) {
      return _.get(this.selectedMsoProduct, id);
    },
    getInput(field) {
      return msoProductInfoInputs.find((i) => i.id == field);
    },
    setProperty(id, $event) {
      _.set(this.selectedMsoProduct, id, $event);
    },
    customLabelOption(option) {
      return option.value;
    },
    deleteExtraInfo(i) {
      this.$set(this.selectedMsoProduct.extraInfo, i, undefined);
      this.selectedMsoProduct.extraInfo.splice(i, 1);
    },
    addExtraInfo() {
      if (!this.selectedMsoProduct.extraInfo) {
        this.$set(this.selectedMsoProduct, 'extraInfo', []);
      }
      const extraInfo = {
        name: "",
        value: "",
      };
      this.selectedMsoProduct.extraInfo = [extraInfo, ...this.selectedMsoProduct.extraInfo];
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.extra-info {
  border-radius: $border-radius-0;
  border: 2px solid rgba(255, 255, 255, 0.05);
  padding: 0.5rem;

  &--edit {
    background-color: #313133;
  }
}

.light .extra-info {
  border: 2pt solid #d1d1d1;
  background-color: white;
}

.extra-info-record {
  display: grid;
  grid-template-columns: 1fr 5fr 8fr;
  grid-gap: 1rem;

  & > .input-text {
    margin-bottom: 18px;
  }
}

.option--disabled {
  pointer-events: none;

  &:hover {
    cursor: not-allowed;
  }
}

.multiselect__input {
  color: white;

  &::placeholder {
    color: white;
  }
}

.light .multiselect__input {
  color: black;

  &::placeholder {
    color: black;
  }
}
.multiselect__tag {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  background-color: $dark-accent-color;
}

.light .multiselect__tag {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  background-color: $light-accent-color;
}

.multiselect__tags,
.multiselect--disabled {
  border-radius: $border-radius-0;
  border: 2pt solid #313133;
  background-color: #313133;
  color: white;
}

.light .multiselect__tags,
.multiselect--disabled {
  border-radius: $border-radius-0;
  border: 2pt solid #d1d1d1;
  background-color: white;
  color: black;
}

.multiselect__content-wrapper {
  border-radius: $border-radius-0;
  border: 2pt solid #313133;
  background-color: #313133;
  color: white;
}

.light .multiselect__content-wrapper {
  border-radius: $border-radius-0;
  border: 2pt solid #d1d1d1;
  background-color: white;
  color: black;
}
</style>
