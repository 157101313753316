<template>
  <div class="payment">
    <div class="payment__buttons">
      <BaseBtn
        v-for="payment in availablePayments"
        :key="payment.code"
        @click="createPayment(payment)"
        :label="payment.label"
        :colorType="payment.isSelected ? 5 : undefined"
        :disabled="payment.disabled()"
      />
    </div>
    <div class="payment__creditmemo" v-if="PaymentAmount > 0">
      <p class="payment__creditmemo__value">
        The customer has ${{ formatNumber(PaymentAmount) }} in Payment
        CreditMemos
      </p>
    </div>
    <div class="payment__body">
      <div class="payment__container" v-if="selectedPayment">
        <PaymentForm
          :payment="selectedPayment"
          :payments="order.payments"
          @cancel="unselectPayment"
          @save="performSavePayment"
          @gift-card-barcode="searchGiftCard"
          @canpay-reimbursement="performReimburseCanpayPayment"
          @create-credit-memo="performCreateCreditMemo"
          :amountDue="order.amountDue"
          :customer="order.customer"
          @canpay-execute-payment="performExecuteCanpayPayment"
          :isLoading="
            orderLoadingStatus.savePayment ||
            orderLoadingStatus.executeDebitPayment
          "
          :orderLoadingStatus="orderLoadingStatus"
          @executeDebitPayment="executeDebitPayment"
          @executeOverrideDebitPayment="executeOverrideDebitPayment"
        />
      </div>
    </div>
    <div class="payment__list-container" v-if="!selectedPayment">
      <div class="payment__list-wrapper">
        <PaymentList
          class="payment__list"
          :payments="order.payments"
          @archive-payment="performArchivePayment"
          @edit-payment="performEditPayment"
          :disabled="order.isPaid"
        />
        <LoyaltySummary
          v-if="order.loyaltyInfo"
          :loyaltyTransaction="order.loyaltyTransaction"
          :loyaltyInfo="order.loyaltyInfo"
          :phone="customerPhone"
          :email="customerEmail"
          :showRedeemRewards="showRedeemRewards"
          @redeem-rewards="redeemRewardsClicked"
        />
      </div>
      <div
        class="payment__summary"
        :class="{
          'bordered__highlighted--primary':
            order.isPaid || order.isVoided || order.isPreorder,
          'bordered__highlighted--danger': order.isArchived || order.isPending,
        }"
      >
        <transition name="fade">
          <ContainerLabel
            v-if="order.isPending && !order.isArchived"
            type="danger"
            text="PENDING"
          />
          <ContainerLabel
            v-if="order.isPaid && !order.isArchived && !order.preOrder"
            type="primary"
            text="PAID"
          />
          <ContainerLabel
            v-if="order.isVoided && !order.isArchived"
            type="primary"
            text="REVERSED"
          />
          <ContainerLabel
            v-if="order.isArchived"
            type="danger"
            text="ARCHIVED"
          />
          <ContainerLabel
            v-if="order.isPreorder"
            type="primary"
            text="PREORDER"
          />
        </transition>
        <PriceSummary :rows="paymentSummary" />
        <div v-if="changeDue" class="payment__change-due elevated-0">
          <div>Change due</div>
          <div>{{ changeDue }}</div>
        </div>
      </div>
    </div>
    <div class="payment__footer" v-if="!selectedPayment">
      <div class="payment__footer-buttons-l">
        <BaseBtn
          @click="archiveAll"
          label="Archive All Payments"
          :isLoading="orderLoadingStatus.archiveAllPayments"
          :colorType="3"
          :disabled="
            orderLoadingStatus.saveOrder ||
            containsCanpayPayment ||
            order.isPaid
          "
        />
        <BaseBtn
          iconGroup="fas"
          iconName="print"
          @click="generateReceipt"
          label="Generate Receipt"
          :colorType="3"
          v-if="order.isPaid"
        />
      </div>
      <div class="payment__footer-buttons-r">
        <BaseBtn
          @click="postPayment"
          label="Post"
          :isLoading="orderLoadingStatus.saveOrder"
          :disabled="!isOrderFullyPaid() || order.isPaid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import LoyaltySummary from "@/components/LoyaltySummary/LoyaltySummary.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import PaymentForm from "@/components/Payment/PaymentForm/PaymentForm.vue";
import PaymentList from "@/components/Payment/PaymentList/PaymentList.vue";
import Utilities from "@/lib/Utilities";
import { mapActions, mapGetters } from "vuex";
import PaymentHelper from "@/lib/PaymentHelper";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";

export default {
  name: "Payment",
  data() {
    return {
      selectedPayment: undefined,
      showRedeemRewards: false,
      accountNumbers: {
        cash: "11005-00",
        "credit-memo": "22005-00",
        "gift-card": "22006-00",
        "debit-card": "11021-00",
      },
      availablePayments: [
        {
          label: "Cash",
          code: "cash",
          isSelected: false,
          disabled: () => {
            return this.isOrderFullyPaid() || this.selectedPayment;
          },
        },
        {
          label: "Debit",
          code: "debit-card",
          isSelected: false,
          disabled: () => {
            return (
              this.isOrderFullyPaid() ||
              this.selectedPayment ||
              this.hasValidDebitCardPayment()
            );
          },
        },
        {
          label: "CanPay",
          code: "canpay-retail",
          isSelected: false,
          disabled: () => {
            return this.isOrderFullyPaid() || this.selectedPayment;
          },
        },
        {
          label: "Credit Memo",
          code: "credit-memo",
          isSelected: false,
          disabled: () => {
            return this.isOrderFullyPaid() || this.selectedPayment;
          },
        },
        {
          label: "Gift Card",
          code: "gift-card",
          isSelected: false,
          disabled: () => {
            return this.isOrderFullyPaid() || this.selectedPayment;
          },
        },
        {
          label: "Zero Sale",
          code: "zero-sale",
          isSelected: false,
          disabled: () => {
            return (
              this.payments.total > 0 ||
              this.order.amountDue != 0 ||
              this.selectedPayment
            );
          },
        },
      ],
    };
  },
  components: {
    BaseBtn,
    PriceSummary,
    PaymentForm,
    PaymentList,
    ContainerLabel,
    LoyaltySummary,
  },
  props: {
    order: { type: Object, default: () => {} },
    PaymentAmount: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (!this.currentStore.enableCanPayRetail) {
      this.availablePayments = this.availablePayments.filter(
        (ap) => ap.code !== "canpay-retail"
      );
    }

    this.getAvailableDiscounts({
      positiveCallback: (result) => {
        this.showRedeemRewards = result?.discounts?.length > 0;
      },
      negativeCallback: (error) => {
        console.log(error);
      },
      body: { customer: this.order.customer, order: this.order },
    });
  },
  computed: {
    ...mapGetters({
      orderLoadingStatus: "orderModule/loadingStatus",
      currentStore: "storeModule/currentStore",
    }),
    workstationHasPaymentTerminal() {
      const ws = localStorage.getItem("workstation");
      if (!ws || ws == "undefined") return false;
      const wsJson = JSON.parse(ws);
      const value = wsJson.paymentTerminalId;
      return value != undefined;
    },
    payments() {
      return PaymentHelper.calculatePayments(this.order);
    },
    customerPhone(){
      return this.order?.customer?.phone;
    },
    customerEmail(){
      return this.order?.customer?.email;
    },
    containsCanpayPayment() {
      return (
        this.order.payments?.filter((p) => p.type.includes("canpay")).length > 0
      );
    },
    changeDue() {
      const cd = PaymentHelper.calculatePayments(this.order).changeDue;
      if (cd) {
        return Utilities.formatPrice(cd);
      } else {
        return undefined;
      }
    },
    cashCollected() {
      const cd = PaymentHelper.calculatePayments(this.order).cashCollected;
      if (cd) {
        return Utilities.formatPrice(cd);
      } else {
        return undefined;
      }
    },
    paymentSummary() {
      const payments = PaymentHelper.calculatePayments(this.order);
      const paymentsSummary = [
        {
          name: "Order Total",
          value: Utilities.formatPrice(this.order.orderTotal),
          isHighlighted: true,
        },
        {
          name: "Amount Paid",
          value: Utilities.formatPrice(this.order.amountPaid),
        },
        {
          name: "Amount Due",
          value: Utilities.formatPrice(this.order.amountDue),
          isHighlighted: true,
        },
        {
          name: "Cash",
          value: Utilities.formatPrice(payments.cash),
        },
        {
          name: "Amount Tendered",
          value: Utilities.formatPrice(payments.cashCollected),
        },
        {
          name: "Credit Memo",
          value: Utilities.formatPrice(payments["credit-memo"]),
        },
        {
          name: "Credit Card",
          value: Utilities.formatPrice(payments["credit-card"]),
        },
        {
          name: "Debit Card",
          value: Utilities.formatPrice(payments["debit-card"]),
        },
        {
          name: "Gift Card",
          value: Utilities.formatPrice(payments["gift-card"]),
        },
      ];

      if (payments["pastOrderChangeDue"]) {
        paymentsSummary.push({
          name: "Past Order Change Due",
          value: Utilities.formatPrice(payments["pastOrderChangeDue"]),
        });
      }

      if (this.currentStore.enableCanPayRetail) {
        paymentsSummary.push(
          {
            name: "CanPay Remote",
            value: Utilities.formatPrice(payments["canpay-remote"]),
          },
          {
            name: "CanPay Retail",
            value: Utilities.formatPrice(payments["canpay-retail"]),
          }
        );
      }

      return paymentsSummary;
    },
  },
  methods: {
    ...mapActions({
      savePayment: "orderModule/savePayment",
      archivePayment: "orderModule/archivePayment",
      archiveAllPayments: "orderModule/archiveAllPayments",
      setPayment: "orderModule/setPayment",
      setMessage: "setMessage",
      getGiftCard: "giftCardModule/getGiftCard",
      reimburseCanpayPayment: "orderModule/reimburseCanpayPayment",
      createCreditMemoFromPayment: "orderModule/createCreditMemoFromPayment",
      executeCanpayPayment: "orderModule/executeCanpayPayment",
      requestDebitPayment: "orderModule/executeDebitPayment",
      requestOverrideDebitPayment: "orderModule/executeOverrideDebitPayment",
      getAvailableDiscounts: "loyaltyModule/getAvailableDiscounts",
    }),
    formatNumber(value) {
      if (!value) return "";
      return parseFloat(value).toFixed(0);
    },
    unselectButtons() {
      this.availablePayments.forEach((ap) => (ap.isSelected = false));
    },
    redeemRewardsClicked() {
      this.$emit("redeem-rewards");
    },
    roundTo10(value) {
      if (!value) return 0;
      const valueParsed = parseFloat(value);
      return Math.ceil(valueParsed / 10) * 10;
    },
    createPayment(payment) {
      const ws = localStorage.getItem("workstation");
      if (!ws || ws == "undefined") {
        this.setMessage({
          text: `Please select a workstation`,
          type: "error",
          errorType: "no-workstation",
        });
        return;
      }

      if (payment.code == "debit-card") {
        if (!this.workstationHasPaymentTerminal) {
          this.setMessage({
            text: `This workstation has no payment terminal associated. Please do that in the store store settings or contact DF support.`,
            type: "error",
          });
          return;
        }
      }

      if (payment.code == "pre-order") {
        return this.preorder();
      }

      if (payment.code == "zero-sale") {
        return this.noSale();
      }

      this.unselectButtons();
      payment.isSelected = true;

      this.selectedPayment = {
        type: payment.code,
        _id: "NEW",
        amountPaid: this.order.amountDue.toFixed(2),
        creditMemo: {},
        giftCard: {},
        canpayPin: "",
      };

      if (this.selectedPayment.type == "debit-card") {
        const round = (num) => { return Math.round(num * 100) / 100; }
        this.selectedPayment.cardTotal = this.roundTo10(this.order.amountDue);
        this.selectedPayment.changeDue = round(this.selectedPayment.cardTotal - this.order.amountDue);
        this.selectedPayment.cashBack = round(this.selectedPayment.changeDue);
      }

      const accountNo = this.accountNumbers[payment.code];
      if (accountNo) {
        this.selectedPayment.accountNo = accountNo;
      }
    },
    isOrderFullyPaid() {
      return this.order.amountDue.toFixed(2) <= 0;
    },
    unselectPayment() {
      this.unselectButtons();
      this.selectedPayment = undefined;
    },
    executeDebitPayment() {
      this.requestDebitPayment({
        payment: this.selectedPayment,
        positiveCallback: () => {
          this.unselectPayment();
        },
      });
    },
    executeOverrideDebitPayment(body) {
      this.requestOverrideDebitPayment({
        body,
        positiveCallback: () => {
          this.unselectPayment();
        },
      });
    },
    performSavePayment() {
      this.savePayment({
        payment: this.selectedPayment,
        positiveCallback: () => {
          this.unselectPayment();
        },
      });
    },
    performArchivePayment(payment) {
      if (payment.type.includes("canpay")) {
        return this.setMessage({
          text: `Canpay payments cannot be archived.<br>But you can modify them.`,
          type: "error",
        });
      }

      this.archivePayment({
        payment: payment,
      });
    },
    performEditPayment(payment) {
      this.selectedPayment = payment;
      const availablePayment = this.availablePayments.find(
        (ap) => ap.code == payment.type
      );
      if (availablePayment) availablePayment.isSelected = true;
    },
    archiveAll() {
      this.archiveAllPayments();
    },
    postPayment() {
      this.setPayment({
        payment: { type: "fully-paid" },
        positiveCallback: (order) => {
          if (order?.earnedCreditMemo) {
            this.$emit("show-credit-memo", order?.earnedCreditMemo);
          }
          this.setMessage({
            text: `Posted`,
            type: "success",
          });
        },
      });
    },
    preorder() {
      this.setPayment({
        payment: { type: "pre-order" },
        positiveCallback: () => {
          this.$emit("toggle-modal", { isOpen: false });
          this.setMessage({
            text: `Preordered`,
            type: "success",
          });
        },
      });
    },
    searchGiftCard(barcode) {
      this.getGiftCard({
        barcode,
      }).then((giftCard, error) => {
        if (giftCard) {
          this.selectedPayment.giftCard = giftCard;
        } else {
          this.setMessage({
            text: `Gift Card not found`,
            type: "error",
          });
        }
      });
    },
    performReimburseCanpayPayment() {
      this.reimburseCanpayPayment({
        payment: this.selectedPayment,
        positiveCallback: (response) => (this.selectedPayment = undefined),
      });
    },
    performCreateCreditMemo() {
      this.createCreditMemoFromPayment({
        payment: this.selectedPayment,
        positiveCallback: (response) => (this.selectedPayment = undefined),
      });
    },
    performExecuteCanpayPayment() {
      this.executeCanpayPayment({
        payment: this.selectedPayment,
        positiveCallback: (response) => (this.selectedPayment = undefined),
      });
    },
    generateReceipt() {
      window.open(`/receipt?orderId=${this.order._id}`, "_blank").focus();
    },
    hasValidDebitCardPayment() {
      return this.order.payments.find(
        (p) => p.type == "debit-card" && !p.isArchived && p.amountPaid > 0
      );
    },
  },
};
</script>

<style lang="scss">
@import "payment";
</style>
