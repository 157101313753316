import Vue from "vue";
import VueRouter from "vue-router";
import LocalStorageManager from "@/lib/LocalStorageManager";
import Settings from "@/views/Settings/Settings.vue";
import Login from "@/views/Login/Login.vue";
import PageBase from "@/views/PageBase/PageBase.vue";
import ProductBase from "@/views/Product/ProductBase.vue";
import Products from "@/views/Product/Products.vue";
import ProductEditor from "@/views/Product/ProductEditor.vue";
import RoomBase from "@/views/Room/RoomBase.vue";
import Rooms from "@/views/Room/Rooms.vue";
import RoomEditor from "@/views/Room/RoomEditor.vue";
import SpecialPricingBase from "@/views/SpecialPricing/SpecialPricingBase.vue";
import SpecialPricing from "@/views/SpecialPricing/SpecialPricing.vue";
import SpecialPricingEditor from "@/views/SpecialPricing/SpecialPricingEditor.vue";
import GroupBase from "@/views/Group/GroupBase.vue";
import Groups from "@/views/Group/Groups.vue";
import GroupEditor from "@/views/Group/GroupEditor.vue";
import ChartOfAccountBase from "@/views/ChartOfAccount/ChartOfAccountBase.vue";
import ChartOfAccounts from "@/views/ChartOfAccount/ChartOfAccounts.vue";
import ChartOfAccountEditor from "@/views/ChartOfAccount/ChartOfAccountEditor.vue";
import MSOChartOfAccountBase from "@/views/MSOChartOfAccount/MSOChartOfAccountBase.vue";
import MSOChartOfAccounts from "@/views/MSOChartOfAccount/MSOChartOfAccounts.vue";
import MSOChartOfAccountEditor from "@/views/MSOChartOfAccount/MSOChartOfAccountEditor.vue";
import MSOGroupBase from "@/views/MSOGroup/MSOGroupBase.vue";
import MSOGroups from "@/views/MSOGroup/MSOGroups.vue";
import MSOGroupEditor from "@/views/MSOGroup/MSOGroupEditor.vue";
import MSOSupplierBase from "@/views/MSOSupplier/MSOSupplierBase.vue";
import MSOSuppliers from "@/views/MSOSupplier/MSOSuppliers.vue";
import MSOSupplierEditor from "@/views/MSOSupplier/MSOSupplierEditor.vue";
import MSOProductBase from "@/views/MSOProduct/MSOProductBase.vue";
import MSOProducts from "@/views/MSOProduct/MSOProducts.vue";
import MSOProductEditor from "@/views/MSOProduct/MSOProductEditor.vue";
import MSOMenuBase from "@/views/MSOMenu/MSOMenuBase.vue";
import MSOMenu from "@/views/MSOMenu/MSOMenu.vue";
import MenuBase from "@/views/Menu/MenuBase.vue";
import Menu from "@/views/Menu/Menu.vue";

import SupplierBase from "@/views/Supplier/SupplierBase.vue";
import Suppliers from "@/views/Supplier/Suppliers.vue";
import SupplierEditor from "@/views/Supplier/SupplierEditor.vue";
import SupplierAgentBase from "@/views/SupplierAgent/SupplierAgentBase.vue";
import SupplierAgents from "@/views/SupplierAgent/SupplierAgents.vue";
import SupplierAgentEditor from "@/views/SupplierAgent/SupplierAgentEditor.vue";
import OrganizationBase from "@/views/Organization/OrganizationBase.vue";
import OrganizationEditor from "@/views/Organization/OrganizationEditor.vue";
import StoreBase from "@/views/Store/StoreBase.vue";
import Stores from "@/views/Store/Stores.vue";
import StoreEditor from "@/views/Store/StoreEditor.vue";
import EmployeeBase from "@/views/Employee/EmployeeBase.vue";
import Employees from "@/views/Employee/Employees.vue";
import EmployeeEditor from "@/views/Employee/EmployeeEditor.vue";
import CustomerBase from "@/views/Customer/CustomerBase.vue";
import Customers from "@/views/Customer/Customers.vue";
import CustomerEditor from "@/views/Customer/CustomerEditor.vue";
import PurchaseOrderBase from "@/views/PurchaseOrder/PurchaseOrderBase.vue";
import PurchaseOrders from "@/views/PurchaseOrder/PurchaseOrders.vue";
import PurchaseOrderEditor from "@/views/PurchaseOrder/PurchaseOrderEditor.vue";
import ItemBase from "@/views/Item/ItemBase.vue";
import Items from "@/views/Item/Items.vue";
import ItemEditor from "@/views/Item/ItemEditor.vue";
import PosBase from "@/views/Pos/PosBase.vue";
import Pos from "@/views/Pos/Pos.vue";
import HomeBase from "@/views/Home/HomeBase.vue";
import Home from "@/views/Home/Home.vue";
import PlayGround from "@/views/PlayGround.vue";
import Orders from "@/views/Order/Orders.vue";
import OrderEditor from "@/views/Order/OrderEditor.vue";
import CustomerCheckIn from "@/views/CustomerCheckIn/CustomerCheckIn.vue";
import CustomerCheckInBase from "@/views/CustomerCheckIn/CustomerCheckInBase.vue";
import QueueBase from "@/views/Queue/QueueBase.vue";
import Queue from "@/views/Queue/Queue.vue";
import QueueEditor from "@/views/Queue/QueueEditor.vue";
import OnlineStoreGroupBase from "@/views/OnlineStoreGroup/OnlineStoreGroupBase.vue";
import OnlineStoreGroups from "@/views/OnlineStoreGroup/OnlineStoreGroups.vue";
import OnlineStoreGroupEditor from "@/views/OnlineStoreGroup/OnlineStoreGroupEditor.vue";
import TodaysMenu from "@/views/reports/TodaysMenu/TodaysMenu.vue";
import StateInventory from "@/views/reports/StateInventory/StateInventory.vue";
import SalesListingReturns from "@/reportsSales/SalesListingReturns/SalesListingReturns.vue";

import CreditMemoBase from "@/views/CreditMemo/CreditMemoBase.vue";
import CreditMemos from "@/views/CreditMemo/CreditMemos.vue";
import CreditMemoEditor from "@/views/CreditMemo/CreditMemoEditor.vue";

import CashReconciliationBase from "@/views/CashReconciliation/CashReconciliationBase.vue";
import CashReconciliations from "@/views/CashReconciliation/CashReconciliations.vue";
import CashReconciliationEditor from "@/views/CashReconciliation/CashReconciliationEditor.vue";
import CashReconciliationDetails from "@/views/CashReconciliation/Report/Details.vue";
import CashReconciliationSummarized from "@/views/CashReconciliation/Report/Summarized.vue";

import GiftCardBase from "@/views/GiftCard/GiftCardBase.vue";
import GiftCards from "@/views/GiftCard/GiftCards.vue";
import GiftCardEditor from "@/views/GiftCard/GiftCardEditor.vue";

import CouponBase from "@/views/Coupon/CouponBase.vue";
import Coupons from "@/views/Coupon/Coupons.vue";
import CouponEditor from "@/views/Coupon/CouponEditor.vue";

import MSOCouponBase from "@/views/MSOCoupon/MSOCouponBase.vue";
import MSOCoupons from "@/views/MSOCoupon/MSOCoupons.vue";
import MSOCouponEditor from "@/views/MSOCoupon/MSOCouponEditor.vue";

import PaymentBase from "@/views/Payment/PaymentBase.vue";
import Payments from "@/views/Payment/Payments.vue";
import PaymentEditor from "@/views/Payment/PaymentEditor.vue";

import TaxSetupBase from "@/views/TaxSetup/TaxSetupBase.vue";
import TaxSetups from "@/views/TaxSetup/TaxSetups.vue";
import TaxSetupEditor from "@/views/TaxSetup/TaxSetupEditor.vue";
import TaxSetupOrderAndTest from "@/views/TaxSetup/TaxSetupOrderAndTest/TaxSetupOrderAndTest.vue";

import FlightBase from "@/views/Flight/FlightBase.vue";
import Flights from "@/views/Flight/Flights.vue";
import FlightEditor from "@/views/Flight/FlightEditor.vue";

import ReturnItemBase from "@/views/ReturnItem/ReturnItemBase.vue";
import ReturnItems from "@/views/ReturnItem/ReturnItems.vue";
import ReturnItemEditor from "@/views/ReturnItem/ReturnItemEditor.vue";

import InventoryCountBase from "@/views/InventoryCount/InventoryCountBase.vue";
import InventoryCounts from "@/views/InventoryCount/InventoryCounts.vue";
import InventoryCountEditor from "@/views/InventoryCount/InventoryCountEditor.vue";

import JournalBase from "@/views/Journal/JournalBase.vue";
import Journals from "@/views/Journal/Journals.vue";
import JournalEditor from "@/views/Journal/JournalEditor.vue";
import TimeSheetBase from "@/views/TimeSheet/TimeSheetBase.vue";
import TimeSheets from "@/views/TimeSheet/TimeSheets.vue";
import TimeSheetEditor from "@/views/TimeSheet/TimeSheetEditor.vue";

import FulfillmentBase from "@/views/Fulfillment/FulfillmentBase.vue";
import Fulfillment from "@/views/Fulfillment/Fulfillment.vue";
import store from "@/store";

import SalesReportMenu from "@/views/reports/Sales/Menu.vue";
import SalesReportBase from "@/views/reports/Sales/Base.vue";

import SaleDiscountsSummary from "@/views/reports/Sales/SaleDiscounts/Discounts/SalesDiscountSummary.vue";
import SalesAverageSource from "@/views/reports/Sales/SalesAverage/SalesAverageSource/SalesAverageSource.vue";
import SalesDiscountComps from "@/views/reports/Sales/SaleDiscounts/Comps/SalesDiscountComps.vue";
import SaleDiscountsEmployee from "@/views/reports/Sales/SaleDiscounts/Employee/SalesDiscountsEmployee.vue";

import SalesProfitMarginsGroup from "@/views/reports/Sales/SalesProfitMargins/SalesProfitMarginsGroup/SalesProfitMarginsGroup.vue";
import SalesProfitMarginsGroupProduct from "@/views/reports/Sales/SalesProfitMargins/SalesProfitMarginsGroupProduct/SalesProfitMarginsGroupProduct.vue";
import SalesProfitMarginsSupplier from "@/views/reports/Sales/SalesProfitMargins/SalesProfitMarginsSupplier/SalesProfitMarginsSupplier.vue";
import SalesProfitMarginsSupplierGroup from "@/views/reports/Sales/SalesProfitMargins/SalesProfitMarginsSupplierGroup/SalesProfitMarginsSupplierGroup.vue";
import SalesProfitMarginsSupplierGroupProduct from "@/views/reports/Sales/SalesProfitMargins/SalesProfitMarginsSupplierGroupProduct/SalesProfitMarginsSupplierGroupProduct.vue";

import SalesPromoSupplier from "@/views/reports/Sales/SalesPromo/SalesPromoSupplier/SalesPromoSupplier.vue";
import SalesPromoSupplierGroup from "@/views/reports/Sales/SalesPromo/SalesPromoSupplierGroup/SalesPromoSupplierGroup.vue";
import SalesPromoSupplierGroupProduct from "@/views/reports/Sales/SalesPromo/SalesPromoSupplierGroupProduct/SalesPromoSupplierGroupProduct.vue";

import SalesRecapTransactions from "@/views/reports/Sales/SalesRecapTransactions/SalesRecapTransactions/SalesRecapTransactions.vue";
import SalesRecapTransactionSummary from "@/views/reports/Sales/SalesRecapTransactions/SalesRecapTransactionSummary/SalesRecapTransactionSummary.vue";
import SalesRecapTransactionsEmployee from "@/views/reports/Sales/SalesRecapTransactions/SalesRecapTransactionsEmployee/SalesRecapTransactionsEmployee.vue";
import SalesRecapTransactionSummaryEmployee from "@/views/reports/Sales/SalesRecapTransactions/SalesRecapTransactionSummaryEmployee/SalesRecapTransactionSummaryEmployee.vue";
import SalesRecapOrderEmployee from "@/views/reports/Sales/SalesRecapTransactions/SalesRecapOrderEmployee/SalesRecapOrderEmployee.vue";

import SalesSummary from "@/views/reports/Sales/SalesSummary/SalesSummary/SalesSummary.vue";
import SalesSummaryBreakdown from "@/views/reports/Sales/SalesSummary/SalesSummaryBreakdown/SalesSummaryBreakdown.vue";

import SalesOverview from "@/views/dashboards/SalesOverview/SalesOverview.vue";

import FinanceReportMenu from "@/views/reports/Finance/Menu.vue";
import FinanceReportBase from "@/views/reports/Finance/Base.vue";
import CouponReportMenu from "@/views/reports/Coupon/Menu.vue";
import CouponReportBase from "@/views/reports/Coupon/Base.vue";
import EmployeeReportMenu from "@/views/reports/Employee/Menu.vue";
import EmployeeReportBase from "@/views/reports/Employee/Base.vue";
import CustomerReportMenu from "@/views/reports/Customer/Menu.vue";
import CustomerReportBase from "@/views/reports/Customer/Base.vue";
import EndOfDayClosingReportMenu from "@/views/reports/EndOfDayClosing/Menu.vue";
import EndOfDayClosingReportBase from "@/views/reports/EndOfDayClosing/Base.vue";
import InventoryReportMenu from "@/views/reports/Inventory/Menu.vue";
import InventoryReportBase from "@/views/reports/Inventory/Base.vue";

import SalesTaxBreakdownDay from "@/views/reports/Finance/Tax/SalesTaxBreakdownDay/SalesTaxBreakdownDay.vue";
import SalesTaxSummary from "@/views/reports/Finance/Tax/SalesTaxSummary/SalesTaxSummary.vue";
import SalesTaxSchedule from "@/views/reports/Finance/Tax/SalesTaxSchedule/SalesTaxSchedule.vue";
import SalesOrderStatusBatchId from "@/views/reports/Sales/SalesOrderStatus/SalesForBatchId/SalesOrderStatusBatchId.vue";
import SalesOrderStatusProductId from "@/views/reports/Sales/SalesOrderStatus/SalesForProductId/SalesOrderStatusProductId.vue";
import SalesOrderStatusBuyer from "@/views/reports/Sales/SalesOrderStatus/SalesForBuyer/SalesOrderStatusBuyer.vue";
import SalesOrderStatusBuyerSummary from "@/views/reports/Sales/SalesOrderStatus/SalesForBuyer/SalesOrderStatusBuyerSummary.vue";
import SalesOrderStatusCustomerId from "@/views/reports/Sales/SalesOrderStatus/SalesForCustomerId/SalesOrderStatusCustomerId.vue";
import SalesOrderStatusDiscountUsage from "@/views/reports/Sales/SalesOrderStatus/SalesForDiscountUsage/SalesOrderStatusDiscountUsage.vue";
import SalesOrderStatusArchived from "@/views/reports/Sales/SalesOrderStatus/SalesForArchived/SalesOrderStatusArchived.vue";
import SalesOrderStatusNotPosted from "@/views/reports/Sales/SalesOrderStatus/SalesForNotPosted/SalesOrderStatusNotPosted.vue";
import SalesCouponSummary from "@/views/reports/Sales/SalesOrderStatus/Coupons/SalesCouponSummary.vue";

import InventoryListGroup from "@/views/reports/Inventory/List/InventoryListGroup/InventoryListGroup.vue";
import InventoryListGroupOnline from "@/views/reports/Inventory/List/InventoryListGroupOnline/InventoryListGroupOnline.vue";
import InventoryListSupplier from "@/views/reports/Inventory/List/InventoryListSupplier/InventoryListSupplier.vue";
import InventoryListSupplierAgent from "@/views/reports/Inventory/List/InventoryListSupplierAgent/InventoryListSupplierAgent.vue";
import InventoryListProduct from "@/views/reports/Inventory/List/InventoryListProduct/InventoryListProduct.vue";
import InventoryListSalePricing from "@/views/reports/Inventory/List/InventoryListSalePricing/InventoryListSalePricing.vue";
import InventoryListSpecialPricing from "@/views/reports/Inventory/List/InventoryListSpecialPricing/InventoryListSpecialPricing.vue";

import InventoryAdjustment from "@/views/reports/Inventory/StateReports/InventoryAdjustment/InventoryAdjustment.vue";
import InventoryTransfer from "@/views/reports/Inventory/StateReports/InventoryTransfer/InventoryTransfer.vue";
import InventoryDisposal from "@/views/reports/Inventory/StateReports/InventoryDisposal/InventoryDisposal.vue";
import InventoryVoid from "@/views/reports/Inventory/StateReports/InventoryVoid/InventoryVoid.vue";
import InventoryDisposalScheduled from "@/views/reports/Inventory/InventoryStatus/InventoryDisposalScheduled/InventoryDisposalScheduled.vue";
import InventoryCount from "@/views/reports/Inventory/InventoryStatus/InventoryCount/InventoryCount.vue";
import InventoryCountNc from "@/views/reports/Inventory/InventoryStatus/InventoryCountNc/InventoryCountNc.vue";
import InventoryCountFeatureOff from "@/views/reports/Inventory/InventoryStatus/InventoryCountFeatureOff/InventoryCountFeatureOff.vue";
import InventoryWorkingCount from "@/views/reports/Inventory/InventoryStatus/InventoryWorkingCount/InventoryWorkingCount.vue";
import InventoryExpiring from "@/views/reports/Inventory/InventoryStatus/InventoryExpiring/InventoryExpiring.vue";
import InventorySystemCheck from "@/views/reports/Inventory/SystemCheck/InventorySystemCheck/InventorySystemCheck.vue";
import InventorySystemCheckQuick from "@/views/reports/Inventory/SystemCheck/InventorySystemCheckQuick/InventorySystemCheckQuick.vue";
import InventorySystemCheckItem from "@/views/reports/Inventory/SystemCheck/InventorySystemCheckItem/InventorySystemCheckItem.vue";
import InventoryOnHand from "@/views/reports/Inventory/InventoryManagement/InventoryOnHand/InventoryOnHand.vue";
import InventoryReorderStatus from "@/views/reports/Inventory/InventoryManagement/InventoryReorderStatus/InventoryReorderStatus.vue";
import InventoryForecast from "@/views/reports/Inventory/InventoryManagement/InventoryForecast/InventoryForecast.vue";
import InventoryAging from "@/views/reports/Inventory/InventoryManagement/InventoryAging/InventoryAging.vue";

import InventoryBuyerGroup from "@/views/reports/Inventory/InventoryManagement/InventoryBuyerGroup/InventoryBuyerGroup.vue";
import InventoryBuyerProduct from "@/views/reports/Inventory/InventoryManagement/InventoryBuyerProduct/InventoryBuyerProduct.vue";
import InventoryBuyerSupplierGroup from "@/views/reports/Inventory/InventoryManagement/InventoryBuyerSupplierGroup/InventoryBuyerSupplierGroup.vue";

import TaxSystemCheck from "@/views/reports/Inventory/SystemCheck/TaxSystemCheck/TaxSystemCheck.vue";

import FinanceAccountsReceivable from "@/views/reports/Finance/General/FinanceAccountsReceivable/FinanceAccountsReceivable.vue";
import FinanceDepositSlip from "@/views/reports/Finance/General/FinanceDepositSlip/FinanceDepositSlip.vue";
import FinanceDepositSlipGreenCheck from "@/views/reports/Finance/General/FinanceDepositSlipGreenCheck/FinanceDepositSlipGreenCheck.vue";
import FinanceJournalLedger from "@/views/reports/Finance/General/FinanceJournalLedger/FinanceJournalLedger.vue";
import CashReconciliationByEmployee from "@/views/reports/Finance/General/CashReconciliationByEmployee/CashReconciliationByEmployee.vue";
import CashReconciliationByWorkstation from "@/views/reports/Finance/General/CashReconciliationByWorkstation/CashReconciliationByWorkstation.vue";
import CashCountByWorkstation from "@/views/reports/Finance/General/CashCountByWorkstation/CashCountByWorkstation.vue";

import InventoryOnHandAsOf from "@/views/reports/Finance/Inventory/InventoryOnHandAsOf/InventoryOnHandAsOf.vue";
import InventoryOnHandGroupAsOf from "@/views/reports/Finance/Inventory/InventoryOnHandGroupAsOf/InventoryOnHandGroupAsOf.vue";

import InventoryPurchase from "@/views/reports/Finance/Purchase/InventoryPurchase/InventoryPurchase.vue";
import InventoryPurchaseGroup from "@/views/reports/Finance/Purchase/InventoryPurchaseGroup/InventoryPurchaseGroup.vue";
import InventoryPurchaseSupplier from "@/views/reports/Finance/Purchase/InventoryPurchaseSupplier/InventoryPurchaseSupplier.vue";
import InventoryPurchaseSupplierGroup from "@/views/reports/Finance/Purchase/InventoryPurchaseSupplierGroup/InventoryPurchaseSupplierGroup.vue";

import FinancePurchaseOrderUpload from "@/views/reports/Finance/Upload/FinancePurchaseOrderUpload/FinancePurchaseOrderUpload.vue";
import FinanceCreditMemoUpload from "@/views/reports/Finance/Upload/FinanceCreditMemoUpload/FinanceCreditMemoUpload.vue";
import FinanceJournalLedgerUpload from "@/views/reports/Finance/Upload/FinanceJournalLedgerUpload/FinanceJournalLedgerUpload.vue";
import FinanceJournalBatchCheck from "@/views/reports/Finance/Upload/FinanceJournalBatchCheck/FinanceJournalBatchCheck.vue";
import FinanceListChartOfAccount from "@/views/reports/Finance/List/FinanceListChartOfAccount/FinanceListChartOfAccount.vue";
import CouponListActive from "@/views/reports/Coupon/List/CouponListActive/CouponListActive.vue";
import EmployeeListActive from "@/views/reports/Employee/List/EmployeeListActive.vue";
import EmployeeHours from "@/views/reports/Employee/List/EmployeeHours.vue";
import EmployeeNotes from "@/views/reports/Employee/Notes/EmployeeNotes.vue";

import CustomerNewCount from "@/views/reports/Customer/Marketing/CustomerNewCount/CustomerNewCount.vue";
import CustomerZipCodeCount from "@/views/reports/Customer/Marketing/CustomerZipCodeCount/CustomerZipCodeCount.vue";
import CustomerEmail from "@/views/reports/Customer/Marketing/CustomerEmail/CustomerEmail.vue";
import CustomerLoyaltyStatus from "@/views/reports/Customer/Marketing/CustomerLoyaltyStatus/CustomerLoyaltyStatus.vue";
import CustomerLoyaltySignUp from "@/views/reports/Customer/Marketing/CustomerLoyaltySignUp/CustomerLoyaltySignUp.vue";

import CustomerCoupon from "@/views/reports/Customer/Usage/CustomerCoupon/CustomerCoupon.vue";
import CustomerGiftCard from "@/views/reports/Customer/Usage/CustomerGiftCard/CustomerGiftCard.vue";
import CustomerCanPay from "@/views/reports/Customer/Usage/CustomerCanPay/CustomerCanPay.vue";
import CustomerTransactFirst from "@/views/reports/Customer/Usage/CustomerTransactFirst/CustomerTransactFirst.vue";

import SalesOrderStatusVoided from "@/views/reports/Sales/SalesOrderStatus/SalesForVoided/SalesOrderStatusVoided.vue";
import SalesOrderStatusRefunds from "@/views/reports/Sales/SalesOrderStatus/SalesForRefunds/SalesOrderStatusRefunds.vue";
import SalesOrderStatusStaffing from "@/views/reports/Sales/SalesOrderStatus/SalesForStaffing/SalesOrderStatusStaffing.vue";

import ReportsBase from "@/views/reports/Base.vue";
import ReportsMenu from "@/views/reports/Menu.vue";
import Receipt from "@/views/Receipt/Receipt.vue";

import BrandBase from "@/views/Brand/BrandBase.vue";
import Brands from "@/views/Brand/Brands.vue";
import BrandEditor from "@/views/Brand/BrandEditor.vue";

import IntegrationVue from "@/views/Integrations/Integration.vue";
import IntegrationBaseVue from "@/views/Integrations/IntegrationBase.vue";

import {
  checkInOutRolee,
  salesRoles,
  inventoryRoles,
  inventoryRolesWithoutState,
  managementRoles,
  financeRoles,
  globalAdminRoles,
  reportRoles,
  multiStoreRoles,
  Role
} from "@/constants/roles";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: PageBase,
    meta: { requiresAuth: true },
    redirect: {
      name: "home",
    },
    children: [
      {
        path: "integrations",
        meta: { requiresAuth: true },
        component: IntegrationBaseVue,
        redirect: {
          name: "integrations",
        },
        children: [
          {
            path: "quickbooks",
            name: "quickbooks",
            component: IntegrationVue,
          },
        ],
      },
      {
        path: "home",
        meta: { requiresAuth: true },
        component: HomeBase,
        redirect: {
          name: "home",
        },
        children: [
          {
            path: "",
            name: "home",
            component: Home,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "products",
        meta: { requiresAuth: true, roles: inventoryRoles },
        component: ProductBase,
        redirect: {
          name: "products",
        },
        children: [
          {
            path: "",
            name: "products",
            component: Products,
            meta: { requiresAuth: true, roles: inventoryRoles },
          },
          {
            path: "productEditor",
            name: "productEditor",
            component: ProductEditor,
            meta: { requiresAuth: true, roles: inventoryRoles },
          },
        ],
      },
      {
        path: "menu",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MenuBase,
        redirect: {
          name: "menu",
        },
        children: [
          {
            path: "",
            name: "menu",
            component: Menu,
            meta: { requiresAuth: true, roles: managementRoles },
          }
        ],
      },
      {
        path: "rooms",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: RoomBase,
        redirect: {
          name: "rooms",
        },
        children: [
          {
            path: "",
            name: "rooms",
            component: Rooms,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "roomEditor",
            name: "roomEditor",
            component: RoomEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "brands",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: BrandBase,
        redirect: {
          name: "brands",
        },
        children: [
          {
            path: "",
            name: "brands",
            component: Brands,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "brandEditor",
            name: "brandEditor",
            component: BrandEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "specialPricings",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: SpecialPricingBase,
        redirect: {
          name: "specialPricings",
        },
        children: [
          {
            path: "",
            name: "specialPricings",
            component: SpecialPricing,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "specialPricingEditor",
            name: "specialPricingEditor",
            component: SpecialPricingEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "groups",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: GroupBase,
        redirect: {
          name: "groups",
        },
        children: [
          {
            path: "",
            name: "groups",
            component: Groups,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "groupEditor",
            name: "groupEditor",
            component: GroupEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "msoGroups",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOGroupBase,
        redirect: {
          name: "msoGroups",
        },
        children: [
          {
            path: "",
            name: "msoGroups",
            component: MSOGroups,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "msoGroupEditor",
            name: "msoGroupEditor",
            component: MSOGroupEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "msoSuppliers",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOSupplierBase,
        redirect: {
          name: "msoSuppliers",
        },
        children: [
          {
            path: "",
            name: "msoSuppliers",
            component: MSOSuppliers,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "msoSupplierEditor",
            name: "msoSupplierEditor",
            component: MSOSupplierEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "msoProducts",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOProductBase,
        redirect: {
          name: "msoProducts",
        },
        children: [
          {
            path: "",
            name: "msoProducts",
            component: MSOProducts,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "msoProductEditor",
            name: "msoProductEditor",
            component: MSOProductEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "msoMenu",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOMenuBase,
        redirect: {
          name: "msoMenu",
        },
        children: [
          {
            path: "",
            name: "msoMenu",
            component: MSOMenu,
            meta: { requiresAuth: true, roles: managementRoles },
          }
        ],
      },
      {
        path: "msoChartOfAccounts",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOChartOfAccountBase,
        redirect: {
          name: "msoChartOfAccounts",
        },
        children: [
          {
            path: "",
            name: "msoChartOfAccounts",
            component: MSOChartOfAccounts,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "msoChartOfAccountEditor",
            name: "msoChartOfAccountEditor",
            component: MSOChartOfAccountEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "chartOfAccounts",
        meta: { requiresAuth: true, roles: financeRoles },
        component: ChartOfAccountBase,
        redirect: {
          name: "chartOfAccounts",
        },
        children: [
          {
            path: "",
            name: "chartOfAccounts",
            component: ChartOfAccounts,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "chartOfAccountEditor",
            name: "chartOfAccountEditor",
            component: ChartOfAccountEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "suppliers",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: SupplierBase,
        redirect: {
          name: "suppliers",
        },
        children: [
          {
            path: "",
            name: "suppliers",
            component: Suppliers,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "supplierEditor",
            name: "supplierEditor",
            component: SupplierEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "supplierAgents",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: SupplierAgentBase,
        redirect: {
          name: "supplierAgents",
        },
        children: [
          {
            path: "",
            name: "supplierAgents",
            component: SupplierAgents,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "supplierAgentEditor",
            name: "supplierAgentEditor",
            component: SupplierAgentEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "organizations",
        meta: { requiresAuth: true, roles: managementRoles },
        component: OrganizationBase,
        redirect: {
          name: "organizationEditor",
        },
        children: [
          {
            path: "OrganizationEditor",
            name: "organizationEditor",
            component: OrganizationEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "stores",
        meta: { requiresAuth: true, roles: managementRoles },
        component: StoreBase,
        redirect: {
          name: "stores",
        },
        children: [
          {
            path: "",
            name: "stores",
            component: Stores,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "storeEditor",
            name: "storeEditor",
            component: StoreEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "employees",
        meta: { requiresAuth: true, roles: globalAdminRoles },
        component: EmployeeBase,
        redirect: {
          name: "employees",
        },
        children: [
          {
            path: "",
            name: "employees",
            component: Employees,
            meta: { requiresAuth: true, roles: globalAdminRoles },
          },
          {
            path: "employeeEditor",
            name: "employeeEditor",
            component: EmployeeEditor,
            meta: { requiresAuth: true, roles: globalAdminRoles },
          },
        ],
      },
      {
        path: "customers",
        meta: { requiresAuth: true, roles: salesRoles },
        component: CustomerBase,
        redirect: {
          name: "customers",
        },
        children: [
          {
            path: "",
            name: "customers",
            component: Customers,
            meta: { requiresAuth: true, roles: salesRoles },
          },
          {
            path: "customerEditor",
            name: "customerEditor",
            component: CustomerEditor,
            meta: { requiresAuth: true, roles: salesRoles },
          },
        ],
      },
      {
        path: "purchaseOrders",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: PurchaseOrderBase,
        redirect: {
          name: "purchaseOrders",
        },
        children: [
          {
            path: "",
            name: "purchaseOrders",
            component: PurchaseOrders,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "purchaseOrderEditor",
            name: "purchaseOrderEditor",
            component: PurchaseOrderEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "items",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: ItemBase,
        redirect: {
          name: "items",
        },
        children: [
          {
            path: "",
            name: "items",
            component: Items,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "itemEditor",
            name: "itemEditor",
            component: ItemEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "inventoryCounts",
        meta: { requiresAuth: true, roles: [...inventoryRolesWithoutState, Role.SALES, Role.SALES_NO_PRINTING] },
        component: InventoryCountBase,
        redirect: {
          name: "inventoryCounts",
        },
        children: [
          {
            path: "",
            name: "inventoryCounts",
            component: InventoryCounts,
            meta: { requiresAuth: true, roles: [...inventoryRolesWithoutState, Role.SALES, Role.SALES_NO_PRINTING] },
          },
          {
            path: "inventoryCountEditor",
            name: "inventoryCountEditor",
            component: InventoryCountEditor,
            meta: { requiresAuth: true, roles: [...inventoryRolesWithoutState, Role.SALES, Role.SALES_NO_PRINTING] },
          },
        ],
      },
      {
        path: "pos",
        meta: { requiresAuth: true, roles: salesRoles },
        component: PosBase,
        redirect: {
          name: "pos",
        },
        children: [
          {
            path: "",
            name: "pos",
            component: Pos,
            meta: { requiresAuth: true, roles: salesRoles },
          },
        ],
      },
      {
        path: "orders",
        meta: { requiresAuth: true, roles: financeRoles },
        component: CustomerBase,
        redirect: {
          name: "orders",
        },
        children: [
          {
            path: "",
            name: "orders",
            component: Orders,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "orderEditor",
            name: "orderEditor",
            component: OrderEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "queues",
        meta: { requiresAuth: true, roles: salesRoles },
        component: QueueBase,
        redirect: {
          name: "queues",
        },
        children: [
          {
            path: "",
            name: "queues",
            component: Queue,
            meta: { requiresAuth: true, roles: salesRoles },
          },
          {
            path: "queueEditor",
            name: "queueEditor",
            component: QueueEditor,
            meta: { requiresAuth: true, roles: salesRoles },
          },
        ],
      },
      {
        path: "fulfillment",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: FulfillmentBase,
        redirect: {
          name: "fulfillment",
        },
        children: [
          {
            path: "",
            name: "fulfillment",
            component: Fulfillment,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          // {
          //   path: "fulfillmentEditor",
          //   name: "fulfillmentEditor",
          //   component: FulfillmentEditor,
          //   meta: { requiresAuth: true },
          // },
        ],
      },
      {
        path: "customerCheckIn",
        meta: { requiresAuth: true, roles: salesRoles },
        component: CustomerCheckInBase,
        redirect: {
          name: "customerCheckIn",
        },
        children: [
          {
            path: "customerCheckIn",
            name: "customerCheckIn",
            component: CustomerCheckIn,
            meta: { requiresAuth: true, roles: salesRoles },
          },
        ],
      },
      {
        path: "onlineStoreGroups",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: OnlineStoreGroupBase,
        redirect: {
          name: "onlineStoreGroups",
        },
        children: [
          {
            path: "",
            name: "onlineStoreGroups",
            component: OnlineStoreGroups,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "onlineStoreGroupEditor",
            name: "onlineStoreGroupEditor",
            component: OnlineStoreGroupEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "creditMemos",
        meta: { requiresAuth: true, roles: financeRoles },
        component: CreditMemoBase,
        redirect: {
          name: "creditMemos",
        },
        children: [
          {
            path: "",
            name: "creditMemos",
            component: CreditMemos,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "creditMemoEditor",
            name: "creditMemoEditor",
            component: CreditMemoEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "cashReconciliations",
        meta: { requiresAuth: true, roles: financeRoles },
        component: CashReconciliationBase,
        redirect: {
          name: "cashReconciliations",
        },
        children: [
          {
            path: "",
            name: "cashReconciliations",
            component: CashReconciliations,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "cashReconciliationEditor",
            component: CashReconciliationBase,
            meta: { requiresAuth: true, roles: financeRoles },
            redirect: {
              name: "cashReconciliationEditor",
            },
            children: [
              {
                path: "",
                name: "cashReconciliationEditor",
                component: CashReconciliationEditor,
                meta: { requiresAuth: true, roles: financeRoles },
              },
              {
                path: "details",
                name: "cashReconciliationDetails",
                component: CashReconciliationDetails,
                meta: { requiresAuth: true, roles: financeRoles },
              },
              {
                path: "summary",
                name: "cashReconciliationSummary",
                component: CashReconciliationSummarized,
                meta: { requiresAuth: true, roles: financeRoles },
              },
            ],
          },
        ],
      },
      {
        path: "giftCards",
        meta: { requiresAuth: true, roles: financeRoles },
        component: GiftCardBase,
        redirect: {
          name: "giftCards",
        },
        children: [
          {
            path: "",
            name: "giftCards",
            component: GiftCards,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "giftCardEditor",
            name: "giftCardEditor",
            component: GiftCardEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "coupons",
        meta: { requiresAuth: true, roles: financeRoles },
        component: CouponBase,
        redirect: {
          name: "coupons",
        },
        children: [
          {
            path: "",
            name: "coupons",
            component: Coupons,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "couponEditor",
            name: "couponEditor",
            component: CouponEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "msoCoupons",
        meta: { requiresAuth: true, roles: managementRoles },
        component: MSOCouponBase,
        redirect: {
          name: "msoCoupons",
        },
        children: [
          {
            path: "",
            name: "msoCoupons",
            component: MSOCoupons,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "msoCouponEditor",
            name: "msoCouponEditor",
            component: MSOCouponEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "payments",
        meta: { requiresAuth: true, roles: financeRoles },
        component: PaymentBase,
        redirect: {
          name: "payments",
        },
        children: [
          {
            path: "",
            name: "payments",
            component: Payments,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "paymentEditor",
            name: "paymentEditor",
            component: PaymentEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "taxSetups",
        meta: { requiresAuth: true, roles: financeRoles },
        component: TaxSetupBase,
        redirect: {
          name: "taxSetups",
        },
        children: [
          {
            path: "",
            name: "taxSetups",
            component: TaxSetups,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "taxSetupEditor",
            name: "taxSetupEditor",
            component: TaxSetupEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "taxSetupOrderAndTest",
            name: "taxSetupOrderAndTest",
            component: TaxSetupOrderAndTest,
            meta: {
              requiresAuth: true,
              roles: financeRoles,
            },
          },
        ],
      },
      {
        path: "returnItems",
        meta: { requiresAuth: true, roles: financeRoles },
        component: ReturnItemBase,
        redirect: {
          name: "returnItems",
        },
        children: [
          {
            path: "",
            name: "returnItems",
            component: ReturnItems,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "returnItemEditor",
            name: "returnItemEditor",
            component: ReturnItemEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "flights",
        meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
        component: FlightBase,
        redirect: {
          name: "flights",
        },
        children: [
          {
            path: "",
            name: "flights",
            component: Flights,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
          {
            path: "flightEditor",
            name: "flightEditor",
            component: FlightEditor,
            meta: { requiresAuth: true, roles: inventoryRolesWithoutState },
          },
        ],
      },
      {
        path: "journals",
        meta: { requiresAuth: true, roles: financeRoles },
        component: JournalBase,
        redirect: {
          name: "journals",
        },
        children: [
          {
            path: "",
            name: "journals",
            component: Journals,
            meta: { requiresAuth: true, roles: financeRoles },
          },
          {
            path: "journalEditor",
            name: "journalEditor",
            component: JournalEditor,
            meta: { requiresAuth: true, roles: financeRoles },
          },
        ],
      },
      {
        path: "timeSheets",
        meta: { requiresAuth: true, roles: managementRoles },
        component: TimeSheetBase,
        redirect: {
          name: "timeSheets",
        },
        children: [
          {
            path: "",
            name: "timeSheets",
            component: TimeSheets,
            meta: { requiresAuth: true, roles: managementRoles },
          },
          {
            path: "timeSheetEditor",
            name: "timeSheetEditor",
            component: TimeSheetEditor,
            meta: { requiresAuth: true, roles: managementRoles },
          },
        ],
      },
      {
        path: "reports",
        meta: { requiresAuth: true, roles: reportRoles },
        component: ReportsBase,
        redirect: {
          name: "reports",
        },
        children: [
          {
            path: "",
            name: "reports",
            component: ReportsMenu,
            meta: { requiresAuth: true, roles: reportRoles },
          },
          {
            path: "todaysMenu",
            name: "todaysMenu",
            meta: { requiresAuth: true, roles: reportRoles },
            component: TodaysMenu,
          },
          // {
          //   path: "stateInventory",
          //   name: "stateInventory",
          //   meta: { requiresAuth: true, roles: reportRoles },
          //   component: StateInventory,
          // },
          {
            path: "endOfDayClosing",
            meta: { requiresAuth: true, roles: reportRoles },
            component: EndOfDayClosingReportBase,
            redirect: {
              name: "endOfDayClosingReport",
            },
            children: [
              {
                path: "",
                name: "endOfDayClosingReport",
                component: EndOfDayClosingReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "accountsReceivable",
                name: "accountsReceivable",
                meta: { requiresAuth: true, roles: reportRoles },
                //component: AccountsReceivable,
              },
              {
                path: "cashReconciliation",
                name: "cashReconciliation",
                meta: { requiresAuth: true, roles: reportRoles },
                //component: CashReconciliation,
              },
              {
                path: "salesOrderStatusNotPosted",
                name: "salesOrderStatusNotPosted",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesOrderStatusNotPosted,
              },
              {
                path: "stateInventory",
                name: "stateInventory",
                meta: { requiresAuth: true, roles: reportRoles },
                component: StateInventory,
              },
            ],
          },
          {
            path: "inventory",
            meta: { requiresAuth: true, roles: reportRoles },
            component: InventoryReportBase,
            redirect: {
              name: "inventoryReport",
            },
            children: [
              {
                path: "",
                name: "inventoryReport",
                component: InventoryReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "inventoryAdjustment",
                name: "inventoryAdjustment",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryAdjustment,
              },

              {
                path: "inventoryListGroup",
                name: "inventoryListGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListGroup,
              },
              {
                path: "inventoryListGroupOnline",
                name: "inventoryListGroupOnline",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListGroupOnline,
              },
              {
                path: "inventoryListSupplier",
                name: "inventoryListSupplier",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListSupplier,
              },
              {
                path: "inventoryListSupplierAgent",
                name: "inventoryListSupplierAgent",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListSupplierAgent,
              },
              {
                path: "inventoryListProduct",
                name: "inventoryListProduct",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListProduct,
              },
              {
                path: "inventoryListSalePricing",
                name: "inventoryListSalePricing",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListSalePricing,
              },
              {
                path: "inventoryListSpecialPricing",
                name: "inventoryListSpecialPricing",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryListSpecialPricing,
              },
              {
                path: "inventoryTransfer",
                name: "inventoryTransfer",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryTransfer,
              },
              {
                path: "inventoryCount",
                name: "inventoryCount",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryCount,
              },
              {
                path: "inventoryCountNc",
                name: "inventoryCountNc",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryCountNc,
              },
              {
                path: "inventoryCountFeatureOff",
                name: "inventoryCountFeatureOff",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryCountFeatureOff,
              },
              {
                path: "inventoryWorkingCount",
                name: "inventoryWorkingCount",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryWorkingCount,
              },
              {
                path: "inventoryExpiring",
                name: "inventoryExpiring",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryExpiring,
              },
              {
                path: "inventoryOnHand",
                name: "inventoryOnHand",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryOnHand,
              },
              {
                path: "inventoryOnHandAsOf",
                name: "inventoryOnHandAsOf",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryOnHandAsOf,
              },
              {
                path: "inventoryOnHandGroupAsOf",
                name: "inventoryOnHandGroupAsOf",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryOnHandGroupAsOf,
              },
              {
                path: "inventoryPurchase",
                name: "inventoryPurchase",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryPurchase,
              },
              {
                path: "inventoryPurchaseGroup",
                name: "inventoryPurchaseGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryPurchaseGroup,
              },
              {
                path: "inventoryPurchaseSupplier",
                name: "inventoryPurchaseSupplier",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryPurchaseSupplier,
              },
              {
                path: "inventoryPurchaseSupplierGroup",
                name: "inventoryPurchaseSupplierGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryPurchaseSupplierGroup,
              },
              {
                path: "inventoryReorderStatus",
                name: "inventoryReorderStatus",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryReorderStatus,
              },
              {
                path: "inventoryForecast",
                name: "inventoryForecast",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryForecast,
              },
              {
                path: "inventoryAging",
                name: "inventoryAging",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryAging,
              },
              {
                path: "inventoryBuyerGroup",
                name: "inventoryBuyerGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryBuyerGroup,
              },
              {
                path: "inventoryBuyerProduct",
                name: "inventoryBuyerProduct",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryBuyerProduct,
              },
              {
                path: "inventoryBuyerSupplierGroup",
                name: "inventoryBuyerSupplierGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryBuyerSupplierGroup,
              },
              {
                path: "inventoryDisposal",
                name: "inventoryDisposal",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryDisposal,
              },
              {
                path: "inventoryDisposalScheduled",
                name: "inventoryDisposalScheduled",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventoryDisposalScheduled,
              },
              {
                path: "inventorySystemCheck",
                name: "inventorySystemCheck",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventorySystemCheck,
              },
              {
                path: "inventorySystemCheckQuick",
                name: "inventorySystemCheckQuick",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventorySystemCheckQuick,
              },
              {
                path: "inventorySystemCheckItem",
                name: "inventorySystemCheckItem",
                meta: { requiresAuth: true, roles: reportRoles },
                component: InventorySystemCheckItem,
              },
              {
                path: "taxSystemCheck",
                name: "taxSystemCheck",
                meta: { requiresAuth: true, roles: reportRoles },
                component: TaxSystemCheck,
              },
              {
                path: "stateInventory",
                name: "stateInventory",
                meta: { requiresAuth: true, roles: reportRoles },
                component: StateInventory,
              },
              {
                path: "inventoryVoid",
                name: "inventoryVoid",
                component: InventoryVoid,
                meta: { requiresAuth: true, roles: reportRoles },
              },
            ],
          },
          {
            path: "finance",
            meta: { requiresAuth: true, roles: reportRoles },
            component: FinanceReportBase,
            redirect: {
              name: "financeReport",
            },
            children: [
              {
                path: "",
                name: "financeReport",
                component: FinanceReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "financeAccountsReceivable",
                name: "financeAccountsReceivable",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceAccountsReceivable,
              },
              {
                path: "financeDepositSlip",
                name: "financeDepositSlip",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceDepositSlip,
              },
              {
                path: "financeDepositSlipGreenCheck",
                name: "financeDepositSlipGreenCheck",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceDepositSlipGreenCheck,
              },
              {
                path: "cashReconciliationByEmployee",
                name: "cashReconciliationByEmployee",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CashReconciliationByEmployee,
              },
              {
                path: "cashReconciliationByWorkstation",
                name: "cashReconciliationByWorkstation",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CashReconciliationByWorkstation,
              },
              {
                path: "cashCountByWorkstation",
                name: "cashCountByWorkstation",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CashCountByWorkstation,
              },
              {
                path: "financeJournalLedger",
                name: "financeJournalLedger",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceJournalLedger,
              },
              {
                path: "financePurchaseOrderUpload",
                name: "financePurchaseOrderUpload",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinancePurchaseOrderUpload,
              },
              {
                path: "financeCreditMemoUpload",
                name: "financeCreditMemoUpload",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceCreditMemoUpload,
              },
              {
                path: "financeJournalLedgerUpload",
                name: "financeJournalLedgerUpload",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceJournalLedgerUpload,
              },
              {
                path: "financeJournalBatchCheck",
                name: "financeJournalBatchCheck",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceJournalBatchCheck,
              },
              {
                path: "salesTaxBreakdownDayReport",
                name: "salesTaxBreakdownDayReport",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesTaxBreakdownDay,
              },
              {
                path: "salesTaxSummaryReport",
                name: "salesTaxSummaryReport",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesTaxSummary,
              },
              {
                path: "salesTaxScheduleReport",
                name: "salesTaxScheduleReport",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesTaxSchedule,
              },
              {
                path: "financeListChartOfAccount",
                name: "financeListChartOfAccount",
                meta: { requiresAuth: true, roles: reportRoles },
                component: FinanceListChartOfAccount,
              },
            ],
          },
          {
            path: "customer",
            meta: { requiresAuth: true, roles: reportRoles },
            component: CustomerReportBase,
            redirect: {
              name: "customerReport",
            },
            children: [
              {
                path: "",
                name: "customerReport",
                component: CustomerReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "customerNewCount",
                name: "customerNewCount",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerNewCount,
              },
              {
                path: "customerZipCodeCount",
                name: "customerZipCodeCount",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerZipCodeCount,
              },
              {
                path: "customerEmail",
                name: "customerEmail",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerEmail,
              },
              {
                path: "customerLoyaltyStatus",
                name: "customerLoyaltyStatus",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerLoyaltyStatus,
              },
              {
                path: "customerLoyaltySignUp",
                name: "customerLoyaltySignUp",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerLoyaltySignUp,
              },
              {
                path: "customerCoupon",
                name: "customerCoupon",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerCoupon,
              },
              {
                path: "customerGiftCard",
                name: "customerGiftCard",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerGiftCard,
              },
              {
                path: "customerCanPay",
                name: "customerCanPay",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerCanPay,
              },
              {
                path: "customerTransactFirst",
                name: "customerTransactFirst",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CustomerTransactFirst,
              },

            ],
          },
          {
            path: "coupon",
            meta: { requiresAuth: true, roles: reportRoles },
            component: CouponReportBase,
            redirect: {
              name: "couponReport",
            },
            children: [
              {
                path: "",
                name: "couponReport",
                component: CouponReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "couponListActive",
                name: "couponListActive",
                meta: { requiresAuth: true, roles: reportRoles },
                component: CouponListActive,
              },
            ],
          },
          {
            path: "employee",
            meta: { requiresAuth: true, roles: reportRoles },
            component: EmployeeReportBase,
            redirect: {
              name: "employeeReport",
            },
            children: [
              {
                path: "",
                name: "employeeReport",
                component: EmployeeReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "employeeListActive",
                name: "employeeListActive",
                meta: { requiresAuth: true, roles: reportRoles },
                component: EmployeeListActive,
              },
              {
                path: "employeeHours",
                name: "employeeHours",
                meta: { requiresAuth: true, roles: reportRoles },
                component: EmployeeHours,
              },
              {
                path: "employeeNotes",
                name: "employeeNotes",
                meta: { requiresAuth: true, roles: reportRoles },
                component: EmployeeNotes,
              },
            ],
          },
          {
            path: "sales",
            meta: { requiresAuth: true, roles: reportRoles },
            component: SalesReportBase,
            redirect: {
              name: "salesReport",
            },
            children: [
              {
                path: "",
                name: "salesReport",
                component: SalesReportMenu,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesProfitMarginsGroup",
                name: "salesProfitMarginsGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesProfitMarginsGroup,
              },
              {
                path: "salesProfitMarginsGroupProduct",
                name: "salesProfitMarginsGroupProduct",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesProfitMarginsGroupProduct,
              },
              {
                path: "salesProfitMarginsSupplier",
                name: "salesProfitMarginsSupplier",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesProfitMarginsSupplier,
              },
              {
                path: "salesProfitMarginsSupplierGroup",
                name: "salesProfitMarginsSupplierGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesProfitMarginsSupplierGroup,
              },
              {
                path: "salesProfitMarginsSupplierGroupProduct",
                name: "salesProfitMarginsSupplierGroupProduct",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesProfitMarginsSupplierGroupProduct,
              },
              {
                path: "salesPromoSupplier",
                name: "salesPromoSupplier",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesPromoSupplier,
              },
              {
                path: "salesPromoSupplierGroup",
                name: "salesPromoSupplierGroup",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesPromoSupplierGroup,
              },
              {
                path: "salesPromoSupplierGroupProduct",
                name: "salesPromoSupplierGroupProduct",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesPromoSupplierGroupProduct,
              },
              {
                path: "salesRecapTransactionsDetailed",
                name: "salesRecapTransactionsDetailed",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesRecapTransactions,
              },
              {
                path: "salesRecapTransactionSummary",
                name: "salesRecapTransactionSummary",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesRecapTransactionSummary,
              },

              {
                path: "salesRecapTransactionSummaryEmployee",
                name: "salesRecapTransactionSummaryEmployee",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesRecapTransactionSummaryEmployee,
              },
              {
                path: "salesRecapTransactionsDetailedEmployee",
                name: "salesRecapTransactionsDetailedEmployee",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesRecapTransactionsEmployee,
              },
              {
                path: "salesRecapOrderEmployee",
                name: "salesRecapOrderEmployee",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesRecapOrderEmployee,
              },
              {
                path: "salesSummary",
                name: "salesSummaryReport",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesSummary,
              },
              {
                path: "salesSummaryBreakdownReport",
                name: "salesSummaryBreakdownReport",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesSummaryBreakdown,
              },
              {
                path: "salesAverageSource",
                name: "salesAverageSource",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesAverageSource,
              },
              {
                path: "salesDiscountComps",
                name: "salesDiscountComps",
                meta: { requiresAuth: true, roles: reportRoles },
                component: SalesDiscountComps,
              },
              {
                path: "summary",
                name: "saleDiscountsReportsSummary",
                component: SaleDiscountsSummary,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesCouponSummary",
                name: "salesCouponSummary",
                component: SalesCouponSummary,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "employee",
                name: "saleDiscountsReportsEmployee",
                component: SaleDiscountsEmployee,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusBatchId",
                name: "salesOrderStatusBatchId",
                component: SalesOrderStatusBatchId,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusProductId",
                name: "salesOrderStatusProductId",
                component: SalesOrderStatusProductId,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusBuyer",
                name: "salesOrderStatusBuyer",
                component: SalesOrderStatusBuyer,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusBuyerSummary",
                name: "salesOrderStatusBuyerSummary",
                component: SalesOrderStatusBuyerSummary,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusCustomerId",
                name: "salesOrderStatusCustomerId",
                component: SalesOrderStatusCustomerId,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusDiscountUsage",
                name: "salesOrderStatusDiscountUsage",
                component: SalesOrderStatusDiscountUsage,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusArchived",
                name: "salesOrderStatusArchived",
                component: SalesOrderStatusArchived,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusNotPosted",
                name: "salesOrderStatusNotPosted",
                component: SalesOrderStatusNotPosted,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusVoided",
                name: "salesOrderStatusVoided",
                component: SalesOrderStatusVoided,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusRefunds",
                name: "salesOrderStatusRefunds",
                component: SalesOrderStatusRefunds,
                meta: { requiresAuth: true, roles: reportRoles },
              },
              {
                path: "salesOrderStatusStaffing",
                name: "salesOrderStatusStaffing",
                component: SalesOrderStatusStaffing,
                meta: { requiresAuth: true, roles: reportRoles },
              },
            ],
          },
        ],
      },
      {
        path: "dashboards",
        meta: { requiresAuth: true, roles: reportRoles },
        component: SalesOverview,
        redirect: {
          name: "dashboards",
        },
        children: [
          {
            path: "",
            name: "dashboards",
            component: SalesOverview,
            meta: { requiresAuth: true, roles: reportRoles },
          },
          {
            path: "sales",
            meta: { requiresAuth: true, roles: reportRoles },
            component: SalesOverview,
            redirect: {
              name: "salesDashboard",
            },
            children: [
              {
                path: "salesOverview",
                name: "salesOverview",
                component: SalesOverview,
                meta: { requiresAuth: true, roles: reportRoles },
              },
            ],
          },
        ],
      },
      {
        path: "reportsSales/salesListingReturns",
        name: "salesListingReturns",
        meta: { requiresAuth: true },
        component: SalesListingReturns,
      },

      {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/playGround",
    name: "playGround",
    component: PlayGround,
  },
  {
    path: "/receipt",
    name: "receipt",
    component: Receipt,
  },
  {
    path: "*",
    component: () => import("../views/PageNotFound/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!LocalStorageManager.getLocalStorageKey("user")) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
      return;
    }
  }

  if (to.name === "login") {
    if (LocalStorageManager.getLocalStorageKey("user")) {
      next({
        path: "/",
      });
      return;
    }
  }

  if (to.meta.roles && to.meta.roles.length > 0) {
    const roles = to.meta.roles;
    const userRole = LocalStorageManager.getLocalStorageKey("role");
    if (!roles.includes(userRole)) {
      next({
        path: "/",
      });
      return;
    }
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    if (
      to.name == "cashReconciliationDetails" ||
      to.name == "cashReconciliationSummary"
    ) {
      next({ name: to.name, query: from.query });
    } else {
      next();
    }
  }

  next();
});

export default router;

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}
