<template>
  <div class="customer-analytics">
    <div class="customer-analytics__charts-row">
      <Graph size="sm" title="Number of Orders">
        <ValueGraph
          size="sm"
          :chartData="{ value: spendingInformation.count }"
        />
      </Graph>
      <Graph size="sm" title="Average Order">
        <ValueGraph
          size="sm"
          dataType="money"
          :chartData="{ value: spendingInformation.avg }"
        />
      </Graph>
      <Graph size="sm" title="Max Order">
        <ValueGraph
          size="sm"
          dataType="money"
          :chartData="{ value: spendingInformation.max }"
        />
      </Graph>
      <Graph size="sm" title="Total Spent">
        <ValueGraph
          size="sm"
          dataType="money"
          :chartData="{ value: spendingInformation.totalSpent }"
        />
      </Graph>
      <Graph v-if="showPointsLevel1" size="sm" title="Points Level 1">
        <ValueGraph size="sm" :chartData="{ value: customer.pointsLevel1 }" />
      </Graph>
      <Graph v-if="showPointsLevel2" size="sm" title="Points Level 2">
        <ValueGraph size="sm" :chartData="{ value: customer.pointsLevel2 }" />
      </Graph>
    </div>

    <div class="customer-analytics__charts-row">
      <Graph
        :centerSlot="false"
        size="auto"
        :loadingStatus="dashboardLoadingStatus.topProductsChartData"
        title="Weekly Spending"
        :yScroll="false"
      >
        <CalendarHeatmap
          :collapsed="true"
          :chartData="calendarHeatmapChartData"
        />
      </Graph>
    </div>

    <div class="customer-analytics__charts-row">
      <Graph
        :centerSlot="false"
        size="md"
        :loadingStatus="dashboardLoadingStatus.topProductsChartData"
        title="Top Cannabis Products"
      >
        <TopList
          :chartData="topProductsChartData"
          :filter="(row) => row.entry.isCannabis"
        />
      </Graph>
      <Graph
        :centerSlot="false"
        size="md"
        :loadingStatus="dashboardLoadingStatus.topProductsChartData"
        title="Top Non-Cannabis Products"
      >
        <TopList
          :chartData="topProductsChartData"
          :filter="(row) => !row.entry.isCannabis"
        />
      </Graph>
    </div>
  </div>
</template>

<script>
import TopList from "@/components/TopList/TopList";
import Graph from "@/components/Graph/Graph";
import ValueGraph from "@/components/ValueGraph/ValueGraph";
import CalendarHeatmap from "@/components/CalendarHeatmap/CalendarHeatmap";

export default {
  name: "CustomerAnalytics",
  components: {
    TopList,
    Graph,
    ValueGraph,
    CalendarHeatmap,
  },
  data() {
    return {};
  },
  props: {
    spendingInformation: {
      type: Object,
      default: () => ({}),
    },
    topProductsChartData: {
      type: Object,
      default: () => ({}),
    },
    dashboardLoadingStatus: {
      type: Object,
      default: () => ({}),
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
    showPointsLevel1: {
      type: Boolean,
      default: false,
    },
    showPointsLevel2: {
      type: Boolean,
      default: false,
    },
    calendarHeatmapChartData: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./_customer-analytics.scss";
</style>
