// Order Editor
import Constants from "@/resources/Constants";
import * as Entities from "@/resources/Entities";
import { parseSrn } from "@/lib/srnHelper";

// Page Header
export const pageHeader = {
  title: "Order",
  isEditorView: true,
  cancelPath: "orders",
  addPath: "orderEditor",
  buttonLabel: "Save",
};

export const orderInputs = [
  {
    id: "isPaid",
    type: "checkbox",
    title: "Paid",
    disabled: true,
  },
  {
    id: "isFilled",
    type: "checkbox",
    title: "Filled",
    disabled: true,
  },
  {
    id: "isRetail",
    type: "checkbox",
    title: "Retail",
    disabled: true,
  },
  {
    id: "isInState",
    type: "checkbox",
    title: "In State",
    disabled: true,
  },
  {
    id: "isPreorder",
    type: "checkbox",
    title: "Preorder",
    disabled: true,
  },
  {
    id: "isVoided",
    type: "checkbox",
    title: "Reversed",
    disabled: true,
  },
  {
    id: "isArchived",
    type: "checkbox",
    title: "Archived",
    disabled: true,
  },
  {
    id: "isPending",
    type: "checkbox",
    title: "Pending",
    disabled: true,
  },
];

// Page Header
export const listPageHeader = {
  title: "Orders",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "orderEditor",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Customer Full Name",
    code: "customerFullName",
    field: { type: "input", label: "Full Name" },
    isPartial: false,
  },
  {
    label: "Customer Email",
    code: "externalFields.customer.email",
    field: { type: "input", label: "Email" },
    isPartial: false,
  },
  {
    label: "Caregiver Full Name",
    code: "caregiverFullName",
    field: { type: "input", label: "Full Name" },
    isPartial: false,
  },
  {
    label: "Caregiver Email",
    code: "externalFields.caregiver.email",
    field: { type: "input", label: "Email" },
    isPartial: false,
  },
  {
    label: "Queue Code",
    code: "queueCode",
    field: { type: "input", label: "Queue Code" },
    isPartial: false,
  },
  {
    label: "Code",
    code: "code",
    field: { type: "input", label: "Code" },
    isPartial: false,
  },
  {
    label: "Order Id",
    code: "stringId",
    field: { type: "input", label: "Order Id" },
    isPartial: false,
  },
  // {
  //   label: "Original Order Id",
  //   code: "originalOrder",
  //   field: { type: "input", label: "Original Order Id" },
  //   isPartial: false,
  // },
  {
    label: "Patient SRN #",
    code: "customerSrn",
    field: { type: "input", label: "SRN #" },
    isPartial: false,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
  {
    label: "Caregiver Srn #",
    code: "caregiverSrn",
    field: { type: "input", label: "Caregiver Srn #" },
    isPartial: false,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
];

export const filterCriteriaOpenOrders = [
  {
    label: "Queue Code",
    code: "queueCode",
    field: { type: "input", label: "Queue Code" },
    isPartial: false,
  },
  {
    label: "Customer Full Name",
    code: "customerFullName",
    field: { type: "input", label: "Full Name" },
    isPartial: false,
  },
  {
    label: "Customer Email",
    code: "externalFields.customer.email",
    field: { type: "input", label: "Email" },
    isPartial: false,
  },
  {
    label: "Caregiver Full Name",
    code: "caregiverFullName",
    field: { type: "input", label: "Full Name" },
    isPartial: false,
  },
  {
    label: "Caregiver Email",
    code: "externalFields.caregiver.email",
    field: { type: "input", label: "Email" },
    isPartial: false,
  },
  {
    label: "Code",
    code: "code",
    field: { type: "input", label: "Code" },
    isPartial: false,
  },
  {
    label: "Order Id",
    code: "stringId",
    field: { type: "input", label: "Order Id" },
    isPartial: false,
  },
  {
    label: "Patient SRN #",
    code: "customerSrn",
    field: { type: "input", label: "SRN #" },
    isPartial: false,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
  {
    label: "Caregiver Srn #",
    code: "caregiverSrn",
    field: { type: "input", label: "Caregiver Srn #" },
    isPartial: false,
    preProcessFilterValue(srn) {
      return parseSrn(srn);
    },
  },
];

export const itemsColumnHeaders = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "undo",
    ariaLabel: "Return",
  },
  { title: "Batch #", id: "batchId", position: "sticky" },
  { title: "Supplier", id: "product.externalFields.supplier.name" },
  { title: "Group Name", id: "product.externalFields.group.name" },
  { title: "Grams", id: "product.cannabisGramsUnit", type: "number" },
  {
    title: "Product Name",
    id: "product.name",
    link: true,
    url: "/products/productEditor?id=",
    urlId: "product._id",
  },
  { title: "Quantity", id: "quantity" },
  { title: "Price", id: "price", type: "money" },
  { title: "SubTot", id: "subTotal", type: "money" },
  { title: "Discount", id: "discount", type: "money" },
  { title: "Line Tot", id: "lineTotal", type: "money" },
  { title: "Delivery", id: "freight", type: "money" },
  { title: "Tax", id: "tax", type: "money" },
  {
    title: "Total",
    id: "total",
    type: "money",
  },
  {
    title: "Purchase Order",
    id: "purchaseOrder",
    link: true,
    url: "/purchaseOrders/purchaseOrderEditor?id=",
    urlId: "purchaseOrder",
  },
  {
    title: "Barcode",
    id: "barcode",
    link: true,
    url: "/items/itemEditor?id=",
    urlId: "barcode",
  },
  {
    title: "Cannabis",
    id: "externalFields.group.isCannabis",
    type: "checkbox",
  },
  { title: "Expiration Date", id: "expirationDate", type: "date" },
];

export const paymentsColumnHeaders = [
  { title: "Type", id: "type" },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  { title: "Amount Paid", id: "amountPaid", type: "money" },
  { title: "Date", id: "createdAt", type: "date" },
  {
    title: "Payment",
    id: "_id",
    link: true,
    url: "/payments/paymentEditor?id=",
    urlId: "_id",
  },
];
export const discountsColumnHeaders = [
  { title: "Type", id: "type" },
  { title: "Amount", id: "amountDiscountedInDollars", type: "money" },
  { title: "Coupon Name", id: "couponName" },
  { title: "Approved By", id: "instantDiscountEmployee" },
  { title: "Reason", id: "instantDiscountReason" },
  { title: "Id", id: "discountId" },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Code",
    id: "code",
    size: "medium",
  },
  {
    title: "Posted On",
    id: "postedToJournalAt",
    type: "date",
  },
  {
    title: "Created On",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Paid",
    id: "isPaid",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Pending",
    id: "isPending",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Voided",
    id: "isVoided",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Preorder",
    id: "isPreorder",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Source",
    id: "source",
    size: "medium"
  },
  {
    title: "Total",
    id: "orderTotal",
    type: "money",
    size: "medium",
    isSortable: false
  },
  {
    title: "Amount Paid",
    id: "amountPaid",
    type: "money",
    size: "medium",
    isSortable: false
  },
  {
    title: "Name",
    id: "externalFields.customer.name",
    size: "medium",
    isSortable: false
  },
  {
    title: "Last Name",
    id: "externalFields.customer.lastName",
    size: "medium",
    isSortable: false
  },
  {
    title: "Email",
    id: "externalFields.customer.email",
    size: "medium",
    isSortable: false
  },
  {
    title: "Original Order",
    id: "originalOrder",
    size: "large",
    isSortable: false
  },
  {
    title: "Queue Code",
    id: "queueCode",
    size: "medium",
    isSortable: false
  },
  {
    title: "Caregiver Name",
    id: "externalFields.caregiver.name",
    size: "medium",
    isSortable: false
  },
  {
    title: "Caregiver Last Name",
    id: "externalFields.caregiver.lastName",
    size: "medium",
    isSortable: false
  },
  {
    title: "Type",
    id: "type",
    size: "large",
    isSortable: false
  },
  // {
  //   title: "Queue Customer",
  //   id: "queueCustomer.customerInfo",
  //   type: "name",
  // },
  {
    title: "Srn #",
    id: "customerSrn",
    size: "medium",
    isSortable: false
  },
  {
    title: "Caregiver Srn #",
    id: "caregiverSrn",
    size: "medium",
    isSortable: false
  },
  {
    title: "Date Voided",
    id: "voidedAt",
    type: "date",
    size: "medium",
    isSortable: false
  },
  {
    title: "Order Id",
    id: "_id",
    isSortable: false
  },
];

export const columnHeadersPos = [
  {
    id: "open",
    type: "button",
    action: "selectItem",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Code",
    id: "code",
  },
  {
    title: "Queue Code",
    id: "queueCode",
    isSortable: false
  },
  {
    title: "Date",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Preorder",
    id: "isPreorder",
    type: "checkbox",
    isSortable: false
  },
  {
    title: "Name",
    id: "externalFields.customer.name",
    isSortable: false
  },
  {
    title: "Last Name",
    id: "externalFields.customer.lastName",
    isSortable: false
  },
  {
    title: "Email",
    id: "externalFields.customer.email",
    isSortable: false
  },
  {
    title: "Source",
    id: "source",
  },
  {
    title: "Type",
    id: "type",
    isSortable: false
  },
  {
    title: "Order Id",
    id: "_id",
    isSortable: false
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Order Id",
    id: "_id",
  },
  {
    title: "Customer",
    id: "customer.name",
  },
  {
    title: "Date",
    id: "createdAt",
    type: "date-extended",
  },
  {
    title: "Paid",
    id: "isPaid",
    type: "checkbox",
  },
  {
    title: "Preorder",
    id: "isPreorder",
    type: "checkbox",
  },
  {
    title: "Type",
    id: "type",
  },
];

export const defaultOrder = {};
