<template>
  <div class="mso-coupon-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'msoCoupons', label: 'MsoCoupon' },
          {
            name: 'msoCouponEditor',
            label: selectedMsoCoupon.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedMsoCoupon"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base">
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--danger': selectedMsoCoupon.isArchived,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedMsoCoupon.isArchived"
              type="danger"
              text="ARCHIVED"
            />
          </transition>
          <div class="mso-coupon-editor__grid">
            <div class="mso-coupon-editor__grid__column">
              <Input
                :model="selectedMsoCoupon.name"
                :inputData="getInput('name')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.name = $event"
              />
              <Input
                :model="selectedMsoCoupon.code"
                :inputData="getInput('code')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.code = $event"
              />
              <Input
                :model="selectedMsoCoupon.minSpending"
                :inputData="getInput('minSpending')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.minSpending = $event"
              />
              <Input
                :model="selectedMsoCoupon.customerOnly"
                :inputData="getInput('customerOnly')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.customerOnly = $event"
              />
              <div
                style="position: relative"
                class="
                  mso-coupon-editor__grouped-inputs
                  bordered__highlighted--neutral
                "
                :class="{
                  'bordered__highlighted--danger':
                    selectedMsoCoupon.discountPercentage &&
                    selectedMsoCoupon.discountAmount,
                }"
              >
                <Input
                  :model="selectedMsoCoupon.discountPercentage"
                  :inputData="getInput('discountPercentage')"
                  :isEdit="isEdit"
                  @updateModel="selectedMsoCoupon.discountPercentage = $event"
                />
                <div class="mso-coupon-editor__or-break">OR</div>
                <Input
                  :model="selectedMsoCoupon.discountAmount"
                  :inputData="getInput('discountAmount')"
                  :isEdit="isEdit"
                  @updateModel="selectedMsoCoupon.discountAmount = $event"
                />
                <div
                  v-if="
                    selectedMsoCoupon.discountPercentage &&
                    selectedMsoCoupon.discountAmount &&
                    isEdit
                  "
                  class="mso-coupon-editor__validation-text"
                >
                  You can only have discount amount or discount percentage
                </div>
              </div>
            </div>
            <div class="mso-coupon-editor__grid__column">
              <Input
                :model="selectedMsoCoupon.isOneTimeUse"
                :inputData="getInput('isOneTimeUse')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.isOneTimeUse = $event"
              />
              <Input
                :model="selectedMsoCoupon.isForCannabisOnly"
                :inputData="getInput('isForCannabisOnly')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.isForCannabisOnly = $event"
              />
              <Input
                :model="selectedMsoCoupon.isActive"
                :inputData="getInput('isActive')"
                :isEdit="isEdit"
                @updateModel="selectedMsoCoupon.isActive = $event"
              />
              <div
                style="position: relative"
                class="
                  mso-coupon-editor__grouped-inputs
                  bordered__highlighted--neutral
                "
                :class="{
                  'bordered__highlighted--danger':
                    selectedMsoCoupon.maximumQuantity <
                    selectedMsoCoupon.minimumQuantity,
                }"
              >
                <Input
                  :model="selectedMsoCoupon.minimumQuantity"
                  :inputData="getInput('minimumQuantity')"
                  :isEdit="isEdit"
                  @updateModel="selectedMsoCoupon.minimumQuantity = $event"
                />
                <Input
                  :model="selectedMsoCoupon.maximumQuantity"
                  :inputData="getInput('maximumQuantity')"
                  :isEdit="isEdit"
                  @updateModel="selectedMsoCoupon.maximumQuantity = $event"
                />
                <div
                  v-if="
                    selectedMsoCoupon.maximumQuantity <
                      selectedMsoCoupon.minimumQuantity && isEdit
                  "
                  class="mso-coupon-editor__validation-text"
                >
                  Maximum quantity must be greater or equal to minimum quantity
                </div>
              </div>
            </div>
          </div>
          <div class="mso-coupon-editor__divider" />
          <Input
            :model="selectedMsoCoupon.notes"
            :inputData="getInput('notes')"
            :isEdit="isEdit"
            @updateModel="selectedMsoCoupon.notes = $event"
          />
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedMsoCoupon.name || ''}`"
        sectionType="about"
        v-if="selectedMsoCoupon._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedMsoCoupon" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import {
  msoCouponInfoInputs,
  defaultSelectedMsoCoupon,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";

export default {
  name: "msoCouponEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      msoCouponInfoInputs,
      isEdit: false,
      defaultSelectedMsoCoupon,
      msoCoupons: [],
      msoCouponsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.MSOCOUPON,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    ContainerLabel,
  },
  mounted: function () {
    this.setPageTitle("MsoCoupon");
    const id = this.$route.query.id;
    if (id && id != "NEW") {
      this.getMsoCouponById({ _id: id });
    } else {
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getMsoCouponById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      msoCoupon: "msoCouponModule/msoCoupon",
    }),
    selectedMsoCoupon: {
      get() {
        this.setPageTitle("MsoCoupon" + this.msoCoupon.name);
        return this.msoCoupon;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getMsoCouponById: "msoCouponModule/getMsoCouponById",
      saveMsoCoupon: "msoCouponModule/saveMsoCoupon",
      deleteMsoCoupon: "msoCouponModule/deleteMsoCoupon",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getMsoCoupons: "msoCouponModule/getMsoCoupons",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    getInput(field) {
      return msoCouponInfoInputs.find((i) => i.id == field);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedMsoCoupon._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedMsoCoupon._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedMsoCoupon.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedMsoCoupon = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedMsoCoupon[entity] = item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_msoCouponEditor.scss";
</style>
