var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Staffing Projection","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'salesReport', label: 'Sales' },
      {
        name: 'salesOrderStatusStaffing',
        label: 'Staffing Projection',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},_vm._l((Object.entries(_vm.reportData.list)),function(ref){
    var dateId = ref[0];
    var date = ref[1];
return _c('div',{key:dateId,staticClass:"report-body__employee-container"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0,true),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((date.hours),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.dateId))]),_c('td',[_vm._v(_vm._s(record.dateHour))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (record.total / date.total.total) * 100 ))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.ordersCount)+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (record.ordersCount / date.total.ordersCount) * 100 ))+" ")])])})],2),_vm._m(1,true),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(date.total.total))+" ")]),_c('td',{attrs:{"colspan":"1"}}),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(date.total.ordersCount)+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2,true)])])}),0):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Day")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Hour")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("% of Day Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Order Count")]),_c('th',{attrs:{"align":"right"}},[_vm._v("% of Day Order Count")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }