var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"State Transfers","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'inventoryReport', label: 'Inventory' },
      {
        name: 'inventoryTransfer',
        label: 'State Transfers',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.manifest))]),_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',[_vm._v(_vm._s(record.customer))]),_c('td',{staticClass:"batch"},[_vm._v(_vm._s(record.batchId))]),_c('td',{staticClass:"batch"},[_vm._v(_vm._s(record.batchIdSub))]),_c('td',[_vm._v(_vm._s(record.product))]),_c('td',[_vm._v(_vm._s(record.stateNotes))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.quantity)+" ")])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_vm._v("Total")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.quantity)+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Manifest")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Destination")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Batch #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Batch Sub Lot #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Product")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Note")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Quantity")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }