var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-editor responsive-menu"},[(_vm.groupOrderingPopUp.isOpen)?_c('Ordering',{attrs:{"title":"Inventory Group Receipt Order","items":_vm.groups,"propertyToSortBy":"receiptDisplayOrder"},on:{"close":_vm.closeHandler,"save":_vm.saveHandler}}):_vm._e(),_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
        { name: 'home', label: 'Home' },
        { name: 'groups', label: 'Groups' },
        {
          name: 'groupEditor',
          label: _vm.selectedGroup.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedGroup,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":_vm.toggleSearchModal,"menu-option-click":_vm.menuOptionClick}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},_vm._l((_vm.groupInputs),function(inputs){return _c('div',{key:inputs.id},[_c('div',{staticClass:"\n                grid-two-column\n                two-columns-layout--responsive-horizontal-space-10\n              "},_vm._l((inputs.inputs),function(input){return _c('div',{key:input.id,class:'input-text' + ' ' + input.class},[_c('Input',{attrs:{"model":_vm.selectedGroup[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedGroup[input.id] = $event}}})],1)}),0)])}),0)])]),_c('EditorSection',{attrs:{"title":'Integrations',"bus":_vm.bus}},[_c('div',{staticClass:"padded elevated-0 bordered"},_vm._l((_vm.groupIntegrationInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedGroup[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedGroup[input.id] = $event}}})],1)}),0)]),(_vm.selectedGroup._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedGroup.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedGroup}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":_vm.toggleSearchModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }