import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultPurchaseOrder } from "@/views/PurchaseOrder/inputs";

const state = {
  purchaseOrders: [],
  purchaseOrder: JSON.parse(JSON.stringify(defaultPurchaseOrder)),
  loadingStatus: {
    savePurchaseOrder: false,
    getPurchaseOrders: false,
    getPurchaseOrderById: false,
    deletePurchaseOrder: false,
    undeletePurchaseOrder: false,
    reversePurchaseOrder: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setPurchaseOrders: (state, payload) => {
    state.purchaseOrders = payload;
  },
  setPurchaseOrder: (state, payload) => {
    if (payload._id === "NEW")
      state.purchaseOrder = JSON.parse(JSON.stringify(defaultPurchaseOrder));
    else state.purchaseOrder = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  purchaseOrders: (state) => state.purchaseOrders,
  purchaseOrder: (state) => state.purchaseOrder,
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  savePurchaseOrder: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "savePurchaseOrder", status: true });
    if (payload.options && payload.options.postToJournal) {
      payload.purchaseOrder.postToJournal = true;
    }
    HttpService.post(
      Apis.backendBaseUrl + Apis.savePurchaseOrder,
      payload.purchaseOrder,
      function (response, error) {
        commit("setLoadingStatus", { api: "savePurchaseOrder", status: false });
        if (response) {
          let toastMessage = "Saved";
          if (payload.options && payload.options.postToJournal) {
            toastMessage = "Posted to the Inventory & Saved";
          }
          commit(
            "setMessage",
            { text: toastMessage, type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined, },
            { root: true }
          );
        }
      }
    );
  },
  reversePurchaseOrder: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "reversePurchaseOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.reversePurchaseOrder,
      { _id: payload.purchaseOrder._id },
      function (response, error) {
        commit("setLoadingStatus", {
          api: "reversePurchaseOrder",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Reversed", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error", errorType: error.errorCode == 1004 ? "no-workstation" : undefined, },
            { root: true }
          );
        }
      }
    );
  },
  setBatchType: ({ commit, state }, payload) => {
    state.purchaseOrder.batchType = payload;
  },
  deletePurchaseOrder: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deletePurchaseOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deletePurchaseOrder,
      payload.purchaseOrder,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deletePurchaseOrder",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  undeletePurchaseOrder: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "undeletePurchaseOrder", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.undeletePurchaseOrder,
      payload.purchaseOrder,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "undeletePurchaseOrder",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Undeleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getPurchaseOrders: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getPurchaseOrders", status: true });
    let url = Apis.backendBaseUrl + Apis.getPurchaseOrders + "?";

    if (payload?.filterCriteria && payload?.filterValue) {
      url +=
        `&filterCriteria=${payload.filterCriteria}` +
        `&filterValue=${payload.filterValue}`;
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getPurchaseOrders", status: false });
      var purchaseOrders = {};

      if (response) {
        purchaseOrders = response.data.body;

        commit("setPurchaseOrders", purchaseOrders);
      }
    });
  },
  getPurchaseOrderById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getPurchaseOrderById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getPurchaseOrderById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getPurchaseOrderById",
          status: false,
        });
        if (response) {
          commit("setPurchaseOrder", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getAdjacentPurchaseOrder",
      status: true,
    });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl +
      "purchaseOrder/" +
      Apis.getAdjacent +
      "?mode=" +
      mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentPurchaseOrder",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getJournals: ({ commit, state }, payload) => {
    var url = Apis.backendBaseUrl + "item/getJournals?&skip=0&limit=20";
    HttpService.get(url, function (response, error) {
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  updateSort: ({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination: ({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter: ({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
