<template>
  <div class="coupon">
    <Input
      :model="selectedItem.coupon"
      @updateModel="selectedItem.coupon = $event"
      :inputData="getInput('coupon')"
      :isEdit="true"
    />
    <div class="coupon__amount">
      <div>Amount:</div>
      <div>{{ formattedValue }}</div>
    </div>
    <BaseBtn label="Apply Discount" :disabled="disabled" @click="apply" />
  </div>
</template>

<script>
import _ from "lodash";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Utilities from "@/lib/Utilities";

import { inputs } from "./inputs";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      selectedItem: {
        coupon: {},
      },
    };
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    Input,
    BaseBtn,
  },
  computed: {
    formattedValue() {
      if (!this.value) return "-";

      if (this.type == "$") {
        return Utilities.formatPrice(this.value);
      }

      if (this.type == "%") {
        return `${this.value}%`;
      }

      return "-";
    },
    disabled() {
      let disabled = true;
      if (!this.selectedItem.coupon) return disabled;

      if (
        !this.selectedItem.coupon.discountAmount &&
        !this.selectedItem.coupon.discountPercentage
      )
        return disabled;

      if (this.selectedItem.coupon.discountAmount) {
        if (parseFloat(this.selectedItem.coupon.discountAmount) > 0)
          disabled = false;
      }

      if (this.selectedItem.coupon.discountPercentage) {
        if (parseFloat(this.selectedItem.coupon.discountPercentage) > 0)
          disabled = false;
      }

      return disabled;
    },
    value() {
      if (
        this.selectedItem.coupon.discountAmount &&
        parseFloat(this.selectedItem.coupon.discountAmount) > 0
      ) {
        return parseFloat(this.selectedItem.coupon.discountAmount);
      }
      if (this.selectedItem.coupon.discountPercentage) {
        return parseFloat(this.selectedItem.coupon.discountPercentage);
      }
      return 0;
    },
    type() {
      if (
        !this.selectedItem.coupon &&
        !this.selectedItem.coupon.discountAmount &&
        !this.selectedItem.coupon.discountPercentage
      )
        return null;

      if (
        this.selectedItem.coupon.discountAmount &&
        parseFloat(this.selectedItem.coupon.discountAmount) > 0
      ) {
        return "$";
      }

      if (this.selectedItem.coupon.discountPercentage) {
        return `%`;
      }

      return null;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
    getInput(field) {
      let input = inputs.find((i) => i.id == field);
      let couponApplied = []
      // commenting this since we want to allow to apply the same coupon multiple times
      // let couponApplied =
      //   this.order?.discountsApplied
      //     ?.filter((d) => d.type == "coupon")
      //     .map((p) => p.discountId) || [];
      switch (field) {
        case "coupon":
          input.filter._id = { $nin: couponApplied };
          break;
        default:
          break;
      }

      return input;
    },
    isValid() {
      const coupon = this.selectedItem.coupon;
      const date = this.order.createdAt
        ? new Date(this.order.createdAt)
        : new Date();

      if (!coupon.isActive) {
        this.setMessage({
          text: "Coupon is not active",
          type: "error",
        });
        return false;
      }

      if (coupon.applyDateRange) {
        if (!Utilities.isInDateRange(date, coupon.startDate, coupon.endDate)) {
          this.setMessage({
            text: "Coupon is expired or not available when the order was purchased",
            type: "error",
          });
          return false;
        }
      }

      return true;
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }

      this.selectedItem[entity] = item;
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = {};
    },
    apply() {
      if (!this.isValid()) return;

      const discount = {
        type: "coupon",
        discountId: this.selectedItem.coupon._id,
        couponCode: this.selectedItem.coupon?.msoCoupon?.code,
        couponName: this.selectedItem.coupon.name,
        couponDiscountType: this.type,
        couponAmount: this.value,
        isCannabisOnly: this.selectedItem.coupon.isForCannabisOnly,
        isOneTimeUse: this.selectedItem.coupon.isOneTimeUse,
      };
      this.$emit("applyDiscount", discount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../_discounts.scss";
@import "./_coupon.scss";
</style>
