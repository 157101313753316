<template>
  <div class="coupon-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'coupons', label: 'Coupon' },
          {
            name: 'couponEditor',
            label: selectedCoupon.name,
            query: $route.query,
          },
        ]"
        :canEdit="!selectedCoupon.isDeleted"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedCoupon"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
        @menu-option-click="menuOptionClick"
      />
      <div class="section-base">
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--danger':
              selectedCoupon.isDeleted || selectedCoupon.isArchived,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedCoupon.isDeleted"
              type="danger"
              text="DELETED"
            />
          </transition>
          <Input
            :model="selectedCoupon.msoCoupon"
            :inputData="getInput('msoCoupon')"
            :isEdit="isEdit"
            @updateModel="selectedCoupon.name = $event"
          />
          <div class="coupon-editor__grid">
            <div class="coupon-editor__grid__column">
              <Input
                :model="selectedCoupon.name"
                :inputData="getInput('name')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.name = $event"
              />
              <Input
                :model="selectedCoupon.code"
                :inputData="getInput('code')"
                :isEdit="enforceMso ? false : isEdit"
                @updateModel="selectedCoupon.code = $event"
              />
              <Input
                :model="selectedCoupon.minSpending"
                :inputData="getInput('minSpending')"
                :isEdit="enforceMso ? false : isEdit"
                @updateModel="selectedCoupon.minSpending = $event"
              />
              <Input
                :model="selectedCoupon.customerOnly"
                :inputData="getInput('customerOnly')"
                :isEdit="enforceMso ? false : isEdit"
                @updateModel="selectedCoupon.customerOnly = $event"
              />
              <div
                style="position: relative"
                class="
                  coupon-editor__grouped-inputs
                  bordered__highlighted--neutral
                "
                :class="{
                  'bordered__highlighted--danger':
                    selectedCoupon.discountPercentage &&
                    selectedCoupon.discountAmount,
                }"
              >
                <Input
                  :model="selectedCoupon.discountPercentage"
                  :inputData="getInput('discountPercentage')"
                  :isEdit="isEdit"
                  @updateModel="selectedCoupon.discountPercentage = $event"
                />
                <div class="coupon-editor__or-break">OR</div>
                <Input
                  :model="selectedCoupon.discountAmount"
                  :inputData="getInput('discountAmount')"
                  :isEdit="isEdit"
                  @updateModel="selectedCoupon.discountAmount = $event"
                />
                <div
                  v-if="moreThanOneDiscountTypeInserted"
                  class="coupon-editor__validation-text"
                >
                  You can only have discount amount or discount percentage
                </div>
              </div>
            </div>
            <div class="coupon-editor__grid__column">
              <Input
                :model="selectedCoupon.isOneTimeUse"
                :inputData="getInput('isOneTimeUse')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.isOneTimeUse = $event"
              />
              <Input
                :model="selectedCoupon.isForCannabisOnly"
                :inputData="getInput('isForCannabisOnly')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.isForCannabisOnly = $event"
              />
              <Input
                :model="selectedCoupon.isActive"
                :inputData="getInput('isActive')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.isActive = $event"
              />
              <div
                style="position: relative"
                class="
                  coupon-editor__grouped-inputs
                  bordered__highlighted--neutral
                "
                :class="{
                  'bordered__highlighted--danger':
                    selectedCoupon.maximumQuantity <
                    selectedCoupon.minimumQuantity,
                }"
              >
                <Input
                  :model="selectedCoupon.maximumQuantity"
                  :inputData="getInput('maximumQuantity')"
                  :isEdit="isEdit"
                  @updateModel="selectedCoupon.maximumQuantity = $event"
                />
                <Input
                  :model="selectedCoupon.minimumQuantity"
                  :inputData="getInput('minimumQuantity')"
                  :isEdit="isEdit"
                  @updateModel="selectedCoupon.minimumQuantity = $event"
                />
                <div
                  v-if="
                    selectedCoupon.maximumQuantity <
                      selectedCoupon.minimumQuantity && isEdit
                  "
                  class="coupon-editor__validation-text"
                >
                  Maximum quantity must be greater or equal to minimum quantity
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="coupon-editor__input-row">
              <Input
                :model="selectedCoupon.applyDateRange"
                :inputData="getInput('applyDateRange')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.applyDateRange = $event"
              />
            </div>
            <div class="coupon-editor__grid">
              <Input
                class="coupon-editor__grid__column"
                :model="selectedCoupon.startDate"
                :inputData="getInput('startDate')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.startDate = $event"
              />
              <Input
                class="coupon-editor__grid__column"
                :model="selectedCoupon.endDate"
                :inputData="getInput('endDate')"
                :isEdit="isEdit"
                @updateModel="selectedCoupon.endDate = $event"
              />
            </div>
          </div>
          <div class="coupon-editor__divider" />
          <Input
            :model="selectedCoupon.notes"
            :inputData="getInput('notes')"
            :isEdit="isEdit"
            @updateModel="selectedCoupon.notes = $event"
          />
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedCoupon.name || ''}`"
        sectionType="about"
        v-if="selectedCoupon._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedCoupon" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import LocalStorageManager from "@/lib/LocalStorageManager";
import {
  couponInfoInputs,
  defaultSelectedCoupon,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";

export default {
  name: "couponEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      couponInfoInputs,
      isEdit: false,
      defaultSelectedCoupon,
      coupons: [],
      couponsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.COUPON,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    ContainerLabel,
  },
  mounted: function () {
    this.setPageTitle("Coupon");
    const id = this.$route.query.id;
    if (id && id != "NEW") {
      this.getCouponById({ _id: id });
    } else {
      this.isEdit = true;
    }

    const organizationId = LocalStorageManager.getLocalStorageKey("organizationId");
    this.getOrganizationById({
        _id: organizationId
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getCouponById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      coupon: "couponModule/coupon",
      organization: 'organizationModule/organization'
    }),
    enforceMso() {
      return this.organization.enforceMso
    },
    moreThanOneDiscountTypeInserted() {
      return (
        parseFloat(this.selectedCoupon.discountPercentage) > 0 &&
        parseFloat(this.selectedCoupon.discountAmount) > 0 &&
        this.isEdit
      );
    },
    selectedCoupon: {
      get() {
        this.setPageTitle("Coupon" + this.coupon.name);
        return this.coupon;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getCouponById: "couponModule/getCouponById",
      saveCoupon: "couponModule/saveCoupon",
      deleteCoupon: "couponModule/deleteCoupon",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getCoupons: "couponModule/getCoupons",
      getOrganizationById: "organizationModule/getOrganizationById",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },

    getInput(field) {
      return couponInfoInputs.find((i) => i.id == field);
    },

    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedCoupon._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedCoupon._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedCoupon.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedCoupon = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },

    prefillItem: function (item) {
      const itemSubsetToCopy = _.pick(item, [
        "name",
        "code",
        "discountPercentage",
        "discountAmount",
        "isOneTimeUse",
        "isForCannabisOnly",
        "minimumQuantity",
        "notes",
        "minSpending",
        "customerOnly",
      ]);
      this.selectedCoupon.name = itemSubsetToCopy.name;
      this.selectedCoupon.code = itemSubsetToCopy.code;
      this.selectedCoupon.discountPercentage = itemSubsetToCopy.discountPercentage;
      this.selectedCoupon.discountAmount = itemSubsetToCopy.discountAmount;
      this.selectedCoupon.isOneTimeUse = itemSubsetToCopy.isOneTimeUse;
      this.selectedCoupon.isForCannabisOnly = itemSubsetToCopy.isForCannabisOnly;
      this.selectedCoupon.minimumQuantity = itemSubsetToCopy.minimumQuantity;
      this.selectedCoupon.notes = itemSubsetToCopy.notes;
      this.selectedCoupon.minSpending = itemSubsetToCopy.minSpending;
      this.selectedCoupon.customerOnly = itemSubsetToCopy.customerOnly;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedCoupon[entity] = item;
      if (entity === Entities.MSOCOUPON) {
        this.prefillItem(item);
      }
    },
    menuOptionClick(entity, key) {
      if (key === "coupon-listing-active") {
        window.open(`/reports/coupon/couponListActive`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_couponEditor.scss";
</style>
