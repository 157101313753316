<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Coupon Summary" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesCouponSummary',
          label: 'Coupon Summary',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Name</th>
                <th align="left">Date</th>
                <th align="right">Used</th>
                <th align="right">Coupon Amount</th>
                <th align="right">Total</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total2: data.isTotal }">
                <td v-if="!data.isTotal">{{ data.name }}</td>
                <td v-else>Totals</td>
                <td>{{ data.date }}</td>
                <td align="right"> {{ data.couponCount }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.couponAmount) }}</td>
                <td align="right"> {{ Utilities.formatPrice(data.couponTotal) }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="2" align="left">Grand Totals</td>
              <td align="right"> {{ reportData.total.couponCount }}</td>
              <td align="right">-</td>
              <td align="right"> {{ reportData.total.couponTotal }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Coupon Summary");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
    formattedData() {
      const data = this.reportData;

      let rows = []
      for (let [couponName, { days, total }] of Object.entries(data.list)) {
        for (let [dayId, value] of Object.entries(days)) {
          rows.push(value)
        }
        rows.push({ ...total, isTotal: true })
      }

      return rows;
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "salesCouponSummary";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "salesCouponSummary");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesCouponSummary.scss";
