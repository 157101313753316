<template>
  <div class="login">
    <div class="left-pane desktop-only-flex">
      <img v-if="!theme || theme == 'dark'" src="@/assets/images/logo_large_white.svg" alt="Dispense first logo" />
      <img v-else src="@/assets/images/logo_large_black.svg" alt="DispenseFirst logo" />
    </div>
    <div class="right-pane">
      <div class="mobile-only-block">
        <div class="logo--mobile">
          <img v-if="!theme || theme == 'dark'" src="@/assets/images/logo_large_white.svg" alt="Dispense first logo" />
          <img v-else src="@/assets/images/logo_large_black.svg" alt="DispenseFirst logo" />
        </div>
      </div>
      <div class="floating-div padded bordered">
        <input id="login-email" data-cy="login-email" style="margin-bottom: 10px" class="flat-input" type="text"
          v-model="user.email" placeholder="Email" @keyup.enter="performLogin" />
        <input data-cy="login-password" style="margin: 10px 0" class="flat-input" type="password" v-model="user.password"
          placeholder="Password" @keyup.enter="performLogin" />
        <div style="margin-top: 10px" data-cy="login-button" class="button-primary full-width" @click="performLogin">
          <span v-show="loadingStatus.login">
            <i class="fas fa-spin fa-circle-notch"></i>
          </span>
          <span v-show="!loadingStatus.login">Log in</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  created: function () {
    this.setPageTitle("Login");
  },
  computed: {
    ...mapGetters({
      loadingStatus: "loginModule/loadingStatus",
      theme: "theme"
    }),
  },
  methods: {
    ...mapActions({ login: "loginModule/login" }),
    performLogin: function () {
      // Verification
      this.login(this.user);
    },
  },
  mounted() {
    document.getElementById("login-email").focus();
  }
};
</script>

<style lang="scss">
@import "./_login.scss";
</style>
