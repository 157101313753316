<template>
  <div class="supplierAgent-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :selectedEntity="selectedSupplierAgent"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'supplierAgents', label: 'Supplier Agents' },
          {
            name: 'supplierAgentEditor',
            label: selectedSupplierAgent.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :entity="entity"
        @updateIsEdit="isEdit = $event"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="inputs in supplierAgentInputs" :key="inputs.id">
              <div
                class="
                  grid-two-column
                  two-columns-layout--responsive-horizontal-space-10
                "
              >
                <div
                  v-for="input in inputs.inputs"
                  :key="input.id"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="selectedSupplierAgent[input.id]"
                    @updateModel="selectedSupplierAgent[input.id] = $event"
                    :inputData="input"
                    :isEdit="isEdit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditorSection
        :title="`About ${selectedSupplierAgent.name || ''}`"
        sectionType="about"
        v-if="selectedSupplierAgent._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedSupplierAgent" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import EditorSection from "@/components/EditorSection.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import router from "@/router";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";

import {
  supplierAgentInputs,
  supplierDeliveryAgentsInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
} from "./inputs";

export default {
  name: "supplierAgentEditor",
  data() {
    return {
      Utilities: Utilities,
      Constants: Constants,
      moment: moment,
      supplierAgentInputs,
      supplierDeliveryAgentsInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.SUPPLIERAGENT,
      pageHeader,
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
  },
  mounted: function () {
    this.setPageTitle("Supplier Agent");
    if (this.$route.query.id) {
      this.getSupplierAgentById({
        _id: this.$route.query.id,
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getSupplierAgentById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "supplierModule/loadingStatus",
      supplierAgent: "supplierModule/supplierAgent",
    }),
    selectedSupplierAgent: {
      get() {
        return this.$store.state.supplierModule.supplierAgent;
      },
      set(entity) {
        return this.$store.commit("supplierModule/setSupplierAgent", entity);
      },
    },
    message: {
      get() {
        return this.$store.state.message;
      },
      set(value) {
        this.$store.commit("updateMessage", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getSupplierAgentById: "supplierModule/getSupplierAgentById",
      saveSupplierAgent: "supplierModule/saveSupplierAgent",
      deleteSupplierAgent: "supplierModule/deleteSupplierAgent",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "supplierModule/getAdjacent",
    }),
    performSave(verifyIt = false) {
      if (this.loadingStatus.saveSupplierAgent) {
        return;
      }

      this.selectedSupplierAgent.verifyIt = verifyIt;

      const supplierAgentToSave = this.normalizeEntityBeforeSaving(
        this.selectedSupplierAgent
      );
      this.saveSupplierAgent({
        supplierAgent: supplierAgentToSave,
        positiveCallback: (supplierAgent) => {
          this.setPageTitle(`Supplier Agent ${supplierAgent.name}`);
          if (this.selectedSupplierAgent._id === "NEW") {
            router.push({
              name: "supplierAgentEditor",
              query: { id: supplierAgent._id },
            });
          }
          this.selectedSupplierAgent = supplierAgent;
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(supplierAgent) {
      const entity = JSON.parse(JSON.stringify(supplierAgent));
      if (entity.verificationExpirationDate === "") {
        delete entity.verificationExpirationDate;
      }
      if (entity.verificationDate === "") {
        delete entity.verificationDate;
      }
      delete entity.verifiedBy;
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteSupplierAgent) {
        return;
      }

      const supplierAgentToDelete = this.normalizeEntityBeforeSaving(
        this.selectedSupplierAgent
      );

      this.deleteSupplierAgent({
        supplierAgent: supplierAgentToDelete,
        positiveCallback: () => {
          router.push({
            name: "supplierAgents",
          });
        },
      });
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedSupplierAgent._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedSupplierAgent._id === "NEW") {
        this.isEdit = true;
      }
    },
    menuOptionClick(entity, key) {
      if (key === "supplier-agent-listing") {
        window.open(`/reports/inventory/inventoryListSupplierAgent`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  width: 100%;
  border-radius: 2px;
}
</style>
