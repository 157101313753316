import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedMsoGroup } from "@/views/MSOGroup/inputs";
const state = {
  msoGroups: [],
  msoGroup: { ...defaultSelectedMsoGroup },
  loadingStatus: {
    saveMsoGroup: false,
    getMsoGroups: false,
    getMsoGroupById: false,
    deleteMsoGroup: false,
  },
  msoGroupPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setMsoGroups: (state, payload) => {
    state.msoGroups = payload;
  },
  setMsoGroup: (state, payload) => {
    if (payload._id === "NEW") state.msoGroup = { ...defaultSelectedMsoGroup };
    else state.msoGroup = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setMsoGroupPopUp: (state, payload) => {
    state.msoGroupPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  msoGroups: (state) => state.msoGroups,
  msoGroup: (state) => state.msoGroup,
  loadingStatus: (state) => state.loadingStatus,
  selectedFilter: (state) => state.selectedFilter,
  msoGroupPopUp: (state) => state.msoGroupPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveMsoGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveMsoGroup", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveMsoGroup,
      payload.msoGroup,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveMsoGroup",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteMsoGroup: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteMsoGroup",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteMsoGroup,
      payload.msoGroup,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteMsoGroup",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getMsoGroupById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getMsoGroupById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getMsoGroupById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getMsoGroupById",
          status: false,
        });
        if (response) {
          commit("setMsoGroup", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setMsoGroupPopUp: ({ commit, state }, payload) => {
    commit("setMsoGroupPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
