<template>
  <div class="product-search">

    <BaseInput label="Search for Available Products" placeholder="Gelato" controlType="text" v-model="searchKeyword"
      :applyFocus="true" />

    <div class="product-search__body">
      <div v-for="product in groups" :key="product._id" class="product-search__product" @click="selectProduct(product)">
        <div>
          <img v-lazy="getProductImage(product)" class="product-search__product-image" alt="">
        </div>
        <div class="product-search__product-body">
          <div>
            <div class="product-search__product-name">
              <div>
                {{ product.name }}
              </div>
              <div>{{ product.externalFields.supplier.shortName }} </div>
            </div>
            <div class="product-search__product-subtitle">
              {{ product.onlineStoreName }}
            </div>
          </div>
          <div class="product-search__product-subtitle">
            <div>{{ product.externalFields.group.name }} | Quantity: {{ product.availableQuantity }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import { getImageObj } from '@/lib/productHelper'

export default {
  name: "ProductSearch",
  components: {
    BaseInput
  },
  data() {
    return {
      searchKeyword: ''
    };
  },
  props: {
    itemHierarchy: {
      type: Object,
      default: () => ({ children: [] })
    },
    store: {
      type: Object,
      default: () => { }
    }
  },
  created() {

  },
  computed: {
    groups() {
      const products = []
      const rootChildren = this.itemHierarchy.children || []
      for (const level0 of rootChildren) {
        for (const level1 of level0.children) {
          if (level1.children && level1.children.length > 0) {
            products.push(...level1.children)
          }
        }
      }
      return products.filter(product => {
        if (!product) return true

        const searchKeyword = this.searchKeyword?.replaceAll('-', ' ')?.toLowerCase()
        const name = product.name?.replaceAll('-', ' ').toLowerCase()
        const onlineStoreName = product.onlineStoreName?.toLowerCase()
        const group = product.externalFields?.group?.name?.toLowerCase()



        return name.includes(searchKeyword) || onlineStoreName.includes(searchKeyword) || group.includes(searchKeyword)
      }).sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    selectProduct(product) {
      this.$emit('select-product', product)
      this.$emit('toggle')
    },
    getProductImage(product) {
      return getImageObj(this.store, product);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./_productSearch.scss";
</style>