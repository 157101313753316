<template>
  <div class="home responsive-menu">
    <div class="responsive-menu__child responsive-menu__sub-section">
      <div class="section-base" v-canAccess="checkInOutRoles">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-clock"></i>
            </div>
            <div>Daily Tasks</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button
            @click="() => handleShowTimeClock(true)"
            type="plain-primary responsive-column"
            label="Check In"
          />
          <inline-button
            @click="() => handleShowTimeClock(false)"
            type="plain-primary responsive-column"
            label="Check Out"
          />
          <inline-button
            v-canAccess="salesRoles"
            :to="{ name: 'inventoryCounts' }"
            type="plain-primary responsive-column"
            label="Inventory Count"
          />
          <inline-button
            v-canAccess="financeRoles"
            :to="{ name: 'cashReconciliations' }"
            type="plain-primary responsive-column"
            label="Cash Reconciliation"
          />
        </div>
      </div>
      <div class="section-base" v-canAccess="salesRoles">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-cash-register"></i>
            </div>
            <div>Sales</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button
            :to="{ name: 'pos' }"
            type="plain-primary responsive-column"
            label="Point of Sale"
          />
          <inline-button
            :to="{ name: 'customers' }"
            type="plain-primary responsive-column"
            label="Customers"
          />
          <inline-button
            :to="{ name: 'customerCheckIn' }"
            type="plain-primary responsive-column"
            label="Check In"
          />
          <inline-button
            :to="{ name: 'queues' }"
            type="plain-primary responsive-column"
            label="Queue"
          />
        </div>
      </div>

      <div class="section-base" v-canAccess="inventoryRoles">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-boxes"></i>
            </div>
            <div>Inventory</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <!-- <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'flights' }"
            type="plain-primary responsive-column"
            label="Flights"
          /> -->
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'groups' }"
            type="plain-primary responsive-column"
            label="Groups"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'items' }"
            type="plain-primary responsive-column"
            label="Items"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'onlineStoreGroups' }"
            type="plain-primary responsive-column"
            label="Online Store Groups"
          />
          <inline-button
            v-canAccess="inventoryRoles"
            :to="{ name: 'products' }"
            type="plain-primary responsive-column"
            label="Products"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'purchaseOrders' }"
            type="plain-primary responsive-column"
            label="Purchase Orders"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'rooms' }"
            type="plain-primary responsive-column"
            label="Rooms"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'specialPricings' }"
            type="plain-primary responsive-column"
            label="Special Pricing"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'suppliers' }"
            type="plain-primary responsive-column"
            label="Suppliers"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'supplierAgents' }"
            type="plain-primary responsive-column"
            label="Supplier Agents"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'brands' }"
            type="plain-primary responsive-column"
            label="Brand"
          />
          <inline-button
            v-canAccess="inventoryRolesWithoutState"
            :to="{ name: 'fulfillment' }"
            type="plain-primary responsive-column"
            label="Fulfillment"
          />
        </div>
      </div>

      <div class="section-base" v-canAccess="managementRoles">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-building"></i>
            </div>
            <div>Administration</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button
            :to="{ name: 'employees' }"
            type="plain-primary responsive-column"
            label="Employees"
            v-canAccess="globalAdminRoles"
          />
          <inline-button
            :to="{ name: 'timeSheets' }"
            type="plain-primary responsive-column"
            label="Time Sheets"
          />
          <inline-button
            :to="{ name: 'msoChartOfAccounts' }"
            type="plain-primary responsive-column"
            label="MSO Chart Of Accounts"
            v-canAccess="managementRolesWithMso"
          />
          <inline-button
            :to="{ name: 'msoCoupons' }"
            type="plain-primary responsive-column"
            label="MSO Coupons"
            v-canAccess="managementRolesWithMso"
          />
          <inline-button
            :to="{ name: 'msoGroups' }"
            type="plain-primary responsive-column"
            label="MSO Groups"
            v-canAccess="managementRolesWithMso"
          />
          <inline-button
            :to="{ name: 'msoProducts' }"
            type="plain-primary responsive-column"
            label="MSO Products"
            v-canAccess="managementRolesWithMso"
          />
          <inline-button
            :to="{ name: 'msoSuppliers' }"
            type="plain-primary responsive-column"
            label="MSO Suppliers"
            v-canAccess="managementRolesWithMso"
          />
          <inline-button
            :to="{
              name: 'organizationEditor',
              query: { id: getOrganizationId },
            }"
            type="plain-primary responsive-column"
            label="Organization"
            v-canAccess="multiStoreRoles"
          />
          <inline-button
            :to="{ name: 'stores' }"
            type="plain-primary responsive-column"
            label="Stores"
          />
        </div>
      </div>

      <div class="section-base" v-canAccess="financeRoles">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fas fa-dollar-sign"></i>
            </div>
            <div>Finance</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button
            :to="{ name: 'chartOfAccounts' }"
            type="plain-primary responsive-column"
            label="Chart of Accounts"
          />
          <inline-button
            :to="{ name: 'coupons' }"
            type="plain-primary responsive-column"
            label="Coupons"
          />
          <inline-button
            :to="{ name: 'creditMemos' }"
            type="plain-primary responsive-column"
            label="Credit Memos"
          />
          <inline-button
            :to="{ name: 'giftCards' }"
            type="plain-primary responsive-column"
            label="Gift Cards"
          />
          <inline-button
            :to="{ name: 'journals' }"
            type="plain-primary responsive-column"
            label="Journal Entries"
          />
          <inline-button
            :to="{ name: 'orders' }"
            type="plain-primary responsive-column"
            label="Orders"
          />
          <inline-button
            :to="{ name: 'returnItems' }"
            type="plain-primary responsive-column"
            label="Order Returns"
          />
          <inline-button
            :to="{ name: 'payments' }"
            type="plain-primary responsive-column"
            label="Payments"
          />
          <inline-button
            :to="{ name: 'taxSetups' }"
            type="plain-primary responsive-column"
            label="Tax Setup"
          />
        </div>
      </div>
      <ReportsMenu :isComponent="true" />
      <div class="section-base">
        <div class="section-base__header space-between">
          <div class="section-base__header-name">
            <div>
              <i class="fa fa-cog"></i>
            </div>
            <div>Settings</div>
          </div>
        </div>
        <div class="padded elevated-0 bordered spacer-responsive">
          <inline-button
            :to="{ name: 'settings' }"
            type="plain-primary responsive-column"
            label="Settings"
          />
        </div>
      </div>
    </div>
    <TimeClock
      v-if="showTimeClock"
      :showTimeClock="showTimeClock"
      @close="handleShowTimeClock"
      @swap-time-clock="handleSwapClock"
      :clockIn="clockIn"
    />
  </div>
</template>

<script>
import LocalStorageManager from "@/lib/LocalStorageManager";
import TimeClock from "@/components/TimeClock/TimeClock.vue";
import ReportsMenu from "@/views/reports/Menu.vue";

import {
  checkInOutRoles,
  salesRoles,
  inventoryRoles,
  inventoryRolesWithoutState,
  managementRoles,
  financeRoles,
  reportRoles,
  managementRolesWithMso,
  globalAdminRoles,
  multiStoreRoles
} from "@/constants/roles";

export default {
  name: "home",
  data() {
    return {
      showTimeClock: false,
      clockIn: true,
      checkInOutRoles,
      salesRoles,
      inventoryRoles,
      inventoryRolesWithoutState,
      managementRoles,
      financeRoles,
      reportRoles,
      managementRolesWithMso,
      globalAdminRoles,
      multiStoreRoles
    };
  },
  components: {
    TimeClock,
    ReportsMenu,
  },
  created: function () {
    this.setPageTitle("Home");
  },
  mounted: function () {},
  computed: {
    getOrganizationId: function () {
      return LocalStorageManager.getLocalStorageKey("organizationId");
    },
  },
  methods: {
    handleShowTimeClock: function (clockIn) {
      this.showTimeClock = !this.showTimeClock;
      this.clockIn = clockIn;
    },
    handleSwapClock: function () {
      this.clockIn = !this.clockIn;
    },
  },
};
</script>

<style lang="scss"></style>
