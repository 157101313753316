// Room Editor
// Page Header
export const pageHeader = {
  title: "Room",
  isEditorView: true,
  cancelPath: "rooms",
  addPath: "roomEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Rooms",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "roomEditor",
};
// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Room Name",
    code: "name",
    field: { type: "input", label: "Room Name" },
    isPartial: true,
  },
  { label: "Room Id", code: "_id", field: { type: "input", label: "Room Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Room Name",
    id: "name",
  },
  {
    title: "Room Id",
    id: "_id",
  },
  {
    title: "Created By",
    id: "createdBy",
  },
  {
    title: "Updated By",
    id: "updatedBy",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Created By",
    id: "createdBy",
  },
  {
    title: "Updated By",
    id: "updatedBy",
  },
];

// Room Information - Main Section
export const roomInputs = [
  {
    id: "name",
    type: "input",
    title: "Room Name",
    placeholder: "Room Name",
    focus: true,
    required: true,
  },
  {
    id: "isDefault",
    type: "checkBox",
    title: "Default",
  },
];

export const defaultSelectedRoom = {
  _id: "NEW",
  name: "",
};
