<template>
  <div class="springbig-rewards-redeem">
    <div class="springbig-rewards-redeem__row">
      <div>
        <div class="input-text__label">Order total</div>
        ${{ lineTotal }}
      </div>
      <div>
        <div class="input-text__label">Points available</div>
        {{ springbigCustomer ? springbigCustomer.points : 0 }}
      </div>
      <div>
        <div class="input-text__label">Points-cash convertion rate</div>
        {{ pointsCashConversionRate }}
      </div>
      <div>
        <div class="input-text__label">Customer Cashback</div>
        ${{ getCashbackTotal }}
      </div>
      <BaseInput
        label="Cashback to Redeem"
        controlType="number"
        placeholder="Cashback to Redeem"
        v-model="value"
        @input="validateWholeNumber"
        @focus="setFocus"
      />
    </div>
    <div class="springbig-rewards-redeem__row">
      <BaseBtn
        class="instant__action--mobile"
        label="Redeem"
        @click="apply"
        :disabled="!canApply"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "SpringbigRewardsRedeem",
  data() {
    return {
      springbigCustomer: {},
      value: "",
      pointsCashConversionRate: undefined,
    };
  },
  props: {
    loyaltyInfo: {
      type: Object,
      default: () => ({}),
    },
    lineTotal: {
      type: Number,
    },
  },
  components: {
    BaseInput,
    BaseBtn,
  },
  created() {
    this.fetchPointsCashConversionRate();
    this.fetchSpringbigCustomer();
  },
  computed: {
    ...mapGetters({ order: "orderModule/posOrder" }),
    getPointTotal() {
      return this.springbigCustomer?.points;
    },
    getCashbackTotal() {
      const points = this.springbigCustomer?.points;
      if (!this.pointsCashConversionRate || !points) {
        return 0;
      }
      return Math.trunc(points * this.pointsCashConversionRate);
    },
    canApply() {
      return (
        this.value > 0 &&
        this.value &&
        this.value <=
          this.getCashbackTotal - this.existingSpringbigCashbackDiscounts &&
        this.value <= this.lineTotal
      );
    },
    existingSpringbigCashbackDiscounts() {
      return this.order.discountsApplied
        ?.filter((b) => b.type == "springbig-cashback")
        .map((b) => b.amountDiscountedInDollars)
        .reduce((prev, curr) => prev + curr, 0);
    },
  },
  methods: {
    ...mapActions({
      getPointsCashConversionRate: "loyaltyModule/getPointsCashConversionRate",
      lookup: "loyaltyModule/lookup",
    }),
    validateWholeNumber(event) {
      this.value = Math.floor(event ?? 0);
    },
    fetchPointsCashConversionRate() {
      this.getPointsCashConversionRate({
        positiveCallback: (result) => {
          this.pointsCashConversionRate = result?.pointsCashConversionRate;
        },
      });
    },
    fetchSpringbigCustomer() {
      this.lookup({
        searchFields: { cardNumber: this.loyaltyInfo.cardNumber },
        positiveCallback: (result) => {
          this.springbigCustomer = result[0];
        },
      });
    },
    apply() {
      const discount = {
        type: "springbig-cashback",
        amountDiscountedInDollars: this.value,
        pointAmount: this.value / this.pointsCashConversionRate,
      };

      this.$emit("applyDiscount", discount);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_springbigRewardsRedeem.scss";
</style>
