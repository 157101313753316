var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Tax Schedule","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'salesTaxSchedule',
          label: 'Tax Schedule',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.salesTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report-1"}},[_vm._m(0),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.gross.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.gross.medical))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.gross.adult))+" ")])]),_c('tr'),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.taxesList)),function(ref){
      var taxName = ref[0];
      var value = ref[1];
return _c('tr',{key:taxName},[_c('td'),_c('td'),_c('td'),(value.name != 'Medical')?_c('td',{attrs:{"colspan":"2","align":"left"}},[_vm._v(_vm._s(value.name))]):_vm._e(),(value.name != 'Medical')?_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.total))+" ")]):_vm._e()])})],2)]),_c('table',{attrs:{"id":"table-report-2"}},[_vm._m(1),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.tax.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.tax.medical))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.tax.adult))+" ")])]),_c('tr'),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.taxesList)),function(ref){
      var taxName = ref[0];
      var value = ref[1];
return _c('tr',{key:taxName},[_c('td'),_c('td'),_c('td'),(value.name != 'Medical')?_c('td',{attrs:{"colspan":"2","align":"left"}},[_vm._v(_vm._s(value.name))]):_vm._e(),(value.name != 'Medical')?_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.tax))+" ")]):_vm._e()])})],2),_vm._m(2)]),_c('table',{attrs:{"id":"table-report-3"}},[_vm._m(3),_c('tr',[_c('td'),_c('td'),_c('td'),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.net.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.net.medical))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.net.adult))+" ")])]),_c('tr'),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.taxesList)),function(ref){
      var taxName = ref[0];
      var value = ref[1];
return _c('tr',{key:taxName},[_c('td'),_c('td'),_c('td'),(value.name != 'Medical')?_c('td',{attrs:{"colspan":"2","align":"left"}},[_vm._v(_vm._s(value.name))]):_vm._e(),(value.name != 'Medical')?_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.net))+" ")]):_vm._e()])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Gross Receipts from Cannabis")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Combined")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Medical")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Adult Use")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Total Tax Collected")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Combined")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Medical")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Adult Use")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Net Receipts from Cannabis")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Combined")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Medical")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Adult Use")])])])}]

export { render, staticRenderFns }