<template>
    <div class="table-container">
        <table cellspacing="0" class="table-element">
            <thead>
                <tr class="header">
                    <th class="cell">Store</th>
                    <th class="cell">Date</th>
                    <th class="cell">Supplier</th>
                    <th class="cell">Group</th>
                    <th class="cell">Grams</th>
                    <th class="cell">Product</th>
                    <th class="cell">Qty</th>
                    <th class="cell">Price</th>
                    <th class="cell">Total</th>
                    <th class="cell">Discount</th>
                    <th class="cell">Type</th>
                    <th class="cell">Order</th>
                    <th class="cell">Batch</th>
                </tr>
            </thead>
            <tbody>
                <tr class="row" v-for="item of items" :key="item._id">
                    <td class="cell">{{ item.store}}</td>
                    <td class="cell">{{ Utilities.formatDate(item.createdAt)}}</td>
                    <td class="cell">{{item.supplier}}</td>
                    <td class="cell">{{ item.group}}</td>
                    <td class="cell">{{ item.grams }}</td>
                    <td class="cell">
                        {{ item.product }}
                    </td>
                    <td class="cell">
                        {{ Math.abs(item.quantity) }}
                    </td>
                    <td class="cell">
                    {{ Utilities.formatPrice(item.price) }}</td>

                    <td class="cell">
                    {{ Utilities.formatPrice(item.total) }}</td>

                    <td class="cell">
                    {{ Utilities.formatPrice(item.discount) }}</td>
                    <td class="cell">{{item.description}}</td>
                    <td class="cell">
                        {{item.orderCode}}
                    </td>
                    <td class="cell">
                        {{ item.batchId}}
                    </td>
                </tr>
            </tbody>

        </table>
        <div class="item-list__info" v-if="loading">
            Loading...
        </div>
        <div class="item-list__info" v-else-if="items.length == 0">
            No Items
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Utilities from "@/lib/Utilities";

export default {
    data() {
        return {
            items: [],
            loading: false,
            Utilities
        };
    },
    props: {
        customerId: {
            type: String,
        },
    },
    methods: {
        ...mapActions({
            getCustomerItemHistory: "customerModule/getCustomerItemHistory",
        })
    },
    mounted() {
        if (this.customerId == "NEW" || !this.customerId) {
            this.itemHistory = [];
            return;
        }

        this.loading = true;

        this.getCustomerItemHistory({ customerId: this.customerId })
            .then((data) => {
                this.items = data;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            })
    }
};
</script>

<style lang="scss" scoped>
@import "./itemHistory.scss";
</style>
