// Item Editor
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as supplierColumnHeaders,
  filterCriteria as supplierFilterCriteria,
} from "../../../Supplier/inputs";
import {
  searchColumnHeaders as roomColumnHeaders,
  filterCriteria as roomFilterCriteria,
} from "../../../Room/inputs";
import Constants from "@/resources/Constants";

// Disposal Sections
export const disposalInputs = [
  {
    id: "selectedDisposal.disposalDate",
    type: "calendar",
    title: "Disposal Date",
  },
  {
    id: "selectedDisposal.room",
    type: "select",
    title: "Room of item to dispose",
    placeholder: "Select Room",
    options: [],
  },
  {
    id: "selectedDisposal.isDisposalDateConfirmed",
    type: "checkBox",
    title: "Confirm Disposal Date",
  },
  {
    id: "selectedDisposal.type",
    type: "select",
    title: "Disposal Type",
    options: Constants.listOfDisposalTypes,
  },
  {
    id: "selectedDisposal.reason",
    type: "select",
    title: "Disposal Reason",
    options: Constants.listOfDisposalReasons,
  },
  {
    id: "selectedDisposal.quantity",
    type: "number",
    title: "Quantity to Dispose",
    precision: 0,
  },
  {
    id: "quantityAvailable",
    type: "text",
    title: "Quantity Available",
  },
  {
    id: "actualUnitCost",
    type: "text",
    title: "Unit Cost",
    prefix: "$",
  },
  {
    id: "disposalTotal",
    type: "text",
    title: "Disposal Total",
    prefix: "$",
  },
  {
    id: "selectedDisposal.isArchived",
    checkboxLabel: "Archived",
    title: "Archived",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isPostedToState",
    checkboxLabel: "Posted to State",
    title: "Posted to State",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isPostedToJournal",
    checkboxLabel: "Posted to Journal",
    title: "Posted to Journal",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isInWorkingInventory",
    checkboxLabel: "Working Inventory",
    type: "checkBox",
    title: "Is in Working Inventory",
  },
  {
    id: "selectedDisposal.method",
    type: "select",
    title: "Disposal Method",
    options: Constants.listOfDisposalMethods,
  },
  {
    id: "selectedDisposal.otherReason",
    placeholder: "Explain Reason",
    type: "input",
    title: "Explain Reason",
  },
  {
    id: "selectedDisposal.supplier",
    nestedId: "selectedDisposal.supplier",
    type: "search",
    title: "Supplier",
    label: "name",
    entity: Entities.SUPPLIER,
    columnHeaders: supplierColumnHeaders,
    filterCriteria: supplierFilterCriteria,
  },
  {
    id: "selectedDisposal.room",
    nestedId: "selectedDisposal.room",
    type: "search",
    title: "Which Room did you remove this item from?",
    label: "name",
    entity: Entities.ROOM,
    columnHeaders: roomColumnHeaders,
    filterCriteria: roomFilterCriteria,
  },
  {
    id: "selectedDisposal.isScheduled",
    checkboxLabel: "Scheduled",
    title: "Scheduled",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isPostedToProducts",
    checkboxLabel: "Posted to Inventory",
    title: "Posted to Inventory",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isCompleted",
    checkboxLabel: "Completed",
    title: "Completed",
    type: "checkBox",
    disabled: true,
  },
  {
    id: "selectedDisposal.isRefund",
    checkboxLabel: "Credit",
    title: "Check this box if the supplier is issuing you a credit",
    type: "checkBox",
  },
  {
    id: "selectedDisposal.detailedDescription",
    placeholder: "Detailed Description",
    type: "textarea",
    title: "Detailed Description",
    class: "grid-row-two-column",
  },
];
