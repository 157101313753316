<template>
  <div class="customer-check-in responsive-menu">
    <div class="margined--horizontal responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        title="Check In"
        :canEdit="false"
        :hideAddButton="true"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'customerCheckIn', label: 'Check In' },
        ]"
        routerPath="customerCheckIn"
      >
        <BaseBtn
          iconGroup="fas"
          iconName="user-plus"
          label="New Customer"
          @click="newCustomer"
          :disabled="selectedRecreationalCustomer.customer"
        />
        <BaseBtn
          v-if="showLoyaltyLookup"
          iconGroup="fas"
          iconName="user-tag"
          label="Loyalty Lookup"
          @click="loyaltyLookup"
          :disabled="queueCustomerHasLoyaltyCard"
        />
      </PageHeader>
      <EditorSection
        title="Medical Patient"
        :bus="bus"
        :defaultIsOpen="true"
        v-if="isMedical"
      >
        <div class="customer-check-in__header">
          <div class="input-text">
            <div class="reversed-flex-column">
              <input
                @input="srnSearch"
                class="input-text__field"
                v-model="selectedPatient.srn"
                placeholder="SRN #"
                :disabled="selectedPatient.caregiverSrn.length > 0"
              />
              <div class="input-text__label">SRN #</div>
            </div>
          </div>
          <div class="input-text">
            <div class="reversed-flex-column">
              <input
                @input="srnCaregiverSearch"
                class="input-text__field"
                v-model="selectedPatient.caregiverSrn"
                placeholder="Caregiver SRN #"
              />
              <div class="input-text__label">Caregiver SRN #</div>
            </div>
          </div>
          <div>
            <CustomerInfo
              v-show="selectedPatient.customer"
              class="customer-info-container"
              :customer="selectedPatient.customer"
            />
          </div>

          <div>
            <CustomerInfo
              class="customer-info-container"
              v-show="selectedPatient.caregiver"
              :customer="selectedPatient.caregiver"
            />
          </div>
        </div>
        <div
          class="two-columns-layout two-columns-layout--responsive-horizontal-space-10"
        >
          <div class="space-between">
            <BaseBtn label="Clear" @click="() => clearMedicalForm()" />

            <BaseBtn
              label="Add Patient to Queue"
              :isLoading="loadingStatus.saveQueueCustomer"
              :disabled="
                !selectedPatient.caregiver && !selectedPatient.customer
              "
              @click="validateMedicalCustomer"
            />
          </div>
        </div>
      </EditorSection>

      <br />
      <EditorSection
        title="Recreational Customer"
        :bus="bus"
        :defaultIsOpen="true"
        v-if="isRecreational"
      >
        <div class="col-50-r">
          <div class="padded">
            Scan License/ID
            <br />
            <br />
            <textarea
              v-model="scannedDL"
              class="flat-textarea textarea"
              @input="parseDL"
            ></textarea>
          </div>
          <div class="padded">
            Scan QR Code
            <br />
            <br />
            <textarea
              v-model="scannedQR"
              class="flat-textarea textarea"
              @input="parseQR"
            ></textarea>
          </div>
        </div>
        <div class="col-50-r">
          <div class="padded">
            Manual Entry
            <br />
            <br />
            <CustomerInfo
              class="customer-info-container"
              v-if="selectedRecreationalCustomer.customer"
              :customer="selectedRecreationalCustomer.customer"
            />
            <div>
              <Input
                :model="
                  lodash.get(selectedRecreationalCustomer, findCustomerInput.id)
                "
                @updateModel="
                  lodash.set(
                    selectedRecreationalCustomer,
                    findCustomerInput.id,
                    $event
                  )
                "
                :inputData="findCustomerInput"
                :isEdit="true"
                :initialFilterValue="getCustomerInitialFilterValue"
              />
              <div class="separator margined">
                <hr class="separator__line-over" />
                <span class="separator__or">OR</span>
              </div>
            </div>
            <div
              class="field input-wrapper"
              v-if="queueCustomerHasLoyaltyCard"
              style="padding: 0 0.25rem"
            >
              <div class="input-text__label">Loyalty Card</div>
              <div class="field__content full-width flex flex--space-between">
                <div>
                  {{ getLoyaltyCard }}
                </div>
                <div class="customer-check-in__loyalty-input-buttons">
                  <div
                    v-show="!showLoyaltyNumber"
                    @click="showLoyaltyNumber = !showLoyaltyNumber"
                    class="pointer"
                  >
                    <i class="fas fa-eye-slash"></i>
                  </div>
                  <div
                    v-show="showLoyaltyNumber"
                    @click="showLoyaltyNumber = !showLoyaltyNumber"
                    class="pointer"
                  >
                    <i class="fas fa-eye"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="input in recreationalCustomerInputs"
              :key="input.id"
              :class="'input-text ' + input.class"
            >
              <Input
                :model="lodash.get(selectedRecreationalCustomer, input.id)"
                @updateModel="
                  lodash.set(selectedRecreationalCustomer, input.id, $event)
                "
                :inputData="input"
                :isEdit="selectedRecreationalCustomer.customer ? false : true"
              />
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <div class="padded space-between">
          <BaseBtn
            label="Clear"
            @click="() => clearRecreationalForm()"
            :colorType="3"
          />
          <BaseBtn
            label="Add to Queue"
            :isLoading="loadingStatus.saveQueueCustomer"
            :disabled="loadingStatus.findCustomer"
            @click="() => performSaveQueueCustomer({ mode: 'recreational' })"
          />
        </div>
      </EditorSection>
    </div>
    <BaseModal
      v-if="isCheckInPopUpOpen"
      @toggle-modal="isCheckInPopUpOpen = false"
      title="Check In Customer As"
    >
      <div
        style="
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <BaseBtn
          data-cy="check-in-as-patient-button"
          label="Patient"
          @click="checkInAs('patient')"
        />
        <BaseBtn
          data-cy="check-in-as-caregiver-button"
          label="Caregiver"
          @click="checkInAs('caregiver')"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="isCaregiverPatientSelectionPopUpOpen"
      @toggle-modal="isCaregiverPatientSelectionPopUpOpen = false"
      title="Select Patient to Assist"
    >
      <div
        style="
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-direction: column;
          padding-bottom: 20px;
        "
      >
        <BaseBtn
          v-for="p in pendingCaregiver.patients"
          :key="p.srn"
          :label="`${Utilities.composeNameAndLastName(p)} (${p.srn})`"
          @click="selectCaregiverPatient(p)"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="showPrivacyPolicyPopUp"
      @toggle-modal="showPrivacyPolicyPopUp = false"
      title="Notice of Privacy Practices"
    >
      <div
        class="customer-check-in__privacy-buttons flex flex--center flex--gap"
      >
        <BaseBtn
          data-cy="check-in-as-patient-button"
          label="Decline"
          @click="showPrivacyPolicyPopUp = false"
        />
        <BaseBtn
          data-cy="check-in-as-patient-button"
          label="Accept"
          @click="handlePrivacyAcceptance"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="showPatientInfoPopUp"
      @toggle-modal="showPatientInfoPopUp = false"
      :title="`${patientInfo.fullName} Checked in`"
      :autoWidth="true"
    >
      <div class="customer-check-in__patient-info">
        <CustomerInfo
          class="customer-info-container"
          :customer="patientInfo"
          :schema="patientInfoSchema"
        />
      </div>
    </BaseModal>
    <CustomerNotes
      :messages="messages"
      :CCPAmount="CCPAmount"
      :PointsAmount="PointsAmount"
      :PaymentAmount="PaymentAmount"
    />
    <BaseModal
      v-if="showLoyaltyLookupPopUp"
      @toggle-modal="showLoyaltyLookupPopUp = false"
      title="Loyalty Lookup"
    >
      <div class="customer-check-in__padded-container">
        <LoyaltyLookup
          :selectCustomer="selectLoyaltyCustomer"
          :name="selectedRecreationalCustomer.firstName"
          :lastName="selectedRecreationalCustomer.lastInitial"
        />
      </div>
    </BaseModal>
    <BaseModal
      v-if="showNewCustomerPopUp"
      @toggle-modal="showNewCustomerPopUp = false"
      title="Create new Customer"
    >
      <div class="customer-check-in__padded-container">
        <CustomerForm
          :name="selectedRecreationalCustomer.firstName"
          :lastName="selectedRecreationalCustomer.lastInitial"
          :licenseNumber="selectedRecreationalCustomer.dl"
          :state="getState"
          @customerCreation="customerCreation"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader.vue";
import aamva from "aamva";
import Input from "@/components/Input/Input.vue";
import CustomerInfo from "@/views/CustomerCheckIn/components/CustomerInfo/CustomerInfo.vue";
import lodash from "lodash";
import {
  recreationalCustomerInputs,
  pageHeader,
  findCustomerInput,
} from "./inputs";
import _ from "lodash";
import BaseBtn from "@/components/Base/Button/Button.vue";
import LoyaltyLookup from "@/components/LoyaltyLookup/LoyaltyLookup.vue";
import CustomerForm from "@/components/CustomerForm/CustomerForm.vue";
import Constants from "@/resources/Constants";
import EditorSection from "@/components/EditorSection.vue";
import Vue from "vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import Utilities from "@/lib/Utilities";
import CustomerNotes from "@/components/CustomerNotes";
import { parseSrn } from "@/lib/srnHelper";
import { debounce } from "lodash";
import { capitalizeFirstLetter } from "@/lib/capitalize-first-letter";
import { searchEntities } from "@/services/entityService";
import * as Entities from "@/resources/Entities";
import router from "@/router";

const initialPatient = {
  _id: "NEW",
  orderType: "medical",
  srn: "",
  caregiverSrn: "",
  status: "waiting",
  customer: undefined,
  caregiver: undefined,
};

const initialCustomer = {
  _id: "NEW",
  orderType: "recreational",
  id: "",
  firstName: "",
  lastInitial: "",
  lastName: "",
  country: "US",
  state: "IL",
  status: "waiting",
  customer: undefined,
  loyaltyCard: undefined,
  dl: undefined,
};

export default {
  name: "CustomerCheckIn",
  data() {
    return {
      lodash,
      pageHeader,
      recreationalCustomerInputs,
      selectedPatient: JSON.parse(JSON.stringify(initialPatient)),
      selectedRecreationalCustomer: JSON.parse(JSON.stringify(initialCustomer)),
      scannedDL: "",
      scannedQR: "",
      bus: new Vue(),
      findCustomerInput,
      isCheckInPopUpOpen: false,
      forcedCustomer: undefined,
      pendingCaregiver: undefined,
      isCaregiverPatientSelectionPopUpOpen: false,
      Utilities,
      messages: [],
      CCPAmount: 0,
      PointsAmount: 0,
      PaymentAmount: 0,
      showPrivacyPolicyPopUp: false,
      patientInfo: {
        privileges: "",
        minorRestrictions: "",
        fullName: "",
        cardId: "",
        cardExpiration: "",
        limit: "",
        preferredName: "",
      },
      showPatientInfoPopUp: false,
      lastKeyCode: "",
      patientInfoSchema: [
        { label: "Preferred Name", id: "preferredName" },
        { label: "Full Name", id: "fullName" },
        { label: "Allocation", id: "limit", suffix: "g" },
        { label: "Restrictions", id: "minorRestrictions" },
        { label: "Card #", id: "cardId" },
        { label: "Card Expiration", id: "cardExpiration" },
        { label: "Privileges", id: "privileges" },
      ],
      showLoyaltyLookupPopUp: false,
      showNewCustomerPopUp: false,
      showLoyaltyNumber: false,
    };
  },
  components: {
    PageHeader,
    Input,
    CustomerInfo,
    BaseBtn,
    EditorSection,
    BaseModal,
    CustomerNotes,
    LoyaltyLookup,
    CustomerForm,
  },
  watch: {
    // selectedPatient(newVal, oldVal) {
    //   if (!newVal || !newVal.customer || !newVal.customer.noteList) return;
    //   this.messages = newVal.noteList.filter(
    //     (n) => n.noteType == "pop-up-message"
    //   );
    // },
    // selectedRecreationalCustomer(newVal, oldVal) {
    //   if (!newVal || !newVal.customer || !newVal.customer.noteList) return;
    //   this.messages = newVal.customer.noteList.filter(
    //     (n) => n.noteType == "pop-up-message"
    //   );
    // },
    "selectedRecreationalCustomer.country"(newVal, oldVal) {
      if (newVal != "US") {
        this.selectedRecreationalCustomer.state = undefined;
      }
    },
  },
  created: function () {
    this.setPageTitle("Customer Check In");
    this.getStoreCurrentStore();

    document.addEventListener("keydown", this.preventScannerCodes);
  },
  destroyed() {
    document.removeEventListener("keydown", this.preventScannerCodes);
  },
  mounted: function () {
    const customerId = this.$route.query.customerId;
    const checkInType = this.$route.query.checkInType;
    this.findCustomer({
      filter: { _id: customerId },
      positiveCallback: (customer) => {
        this.forcedCustomer = customer;
        if (customer && checkInType == "recreational") {
          this.assignRecreationalCustomerInfo(customer);
          this.selectedRecreationalCustomer.customer = customer;
          this.showCustomerNotes(customer);
        }
        if (customer && checkInType == "medical") {
          if (customer.isCaregiver && customer.isPatient) {
            this.isCheckInPopUpOpen = true;
          } else {
            if (customer.isCaregiver) {
              this.checkInAs("caregiver");
            } else {
              this.checkInAs("patient");
            }
          }
        }
      },
    });
  },
  computed: {
    ...mapGetters({
      loadingStatus: "queueModule/loadingStatus",
      selectedWorkstation: "posModule/selectedWorkstation",
      currentStore: "storeModule/currentStore",
    }),
    isMedical() {
      return this.currentStore.isMedical;
    },
    isRecreational() {
      return this.currentStore.isRecreational;
    },
    getState() {
      if (this.selectedRecreationalCustomer.state) {
        return Constants.listOfStates.find(
          (s) => s.code == this.selectedRecreationalCustomer.state
        );
      }
      return undefined;
    },
    getCustomerInitialFilterValue() {
      if (this.selectedRecreationalCustomer.firstName) {
        return `${this.selectedRecreationalCustomer.firstName} ${this.selectedRecreationalCustomer.lastInitial}`;
      } else {
        return "";
      }
    },
    showLoyaltyLookup() {
      return this.currentStore?.loyaltySystem === "clutch";
    },
    queueCustomerHasLoyaltyCard() {
      return !!this.selectedRecreationalCustomer?.loyaltyCard;
    },
    getLoyaltyCard() {
      const loyaltyCard = this.selectedRecreationalCustomer?.loyaltyCard || "";
      if (this.showLoyaltyNumber) {
        return loyaltyCard;
      }
      return `******${loyaltyCard.substr(loyaltyCard.length - 4)}`;
    },
  },
  methods: {
    ...mapActions({
      saveQueueCustomer: "queueModule/saveQueueCustomer",
      findCustomer: "queueModule/findCustomer",
      getStoreCurrentStore: "storeModule/getCurrentStore",
      setMessage: "setMessage",
      setNotePopUp: "setNotePopUp",
      getValidCreditMemoPerCustomerIdAndDate:
        "creditMemoModule/getValidCreditMemoPerCustomerIdAndDate",
    }),
    preventScannerCodes(event) {
      if (this.lastKeyCode == "ControlLeft" && event.code == "KeyJ") {
        event.preventDefault();
      }
      this.lastKeyCode = event.code;
    },
    checkInAs(mode) {
      this.isCheckInPopUpOpen = false;
      switch (mode) {
        case "patient":
          this.selectedPatient.srn = this.forcedCustomer.srn;
          this.srnSearch();
          break;

        case "caregiver":
          this.selectedPatient.caregiverSrn = this.forcedCustomer.caregiverSrn;
          this.srnCaregiverSearch();
          break;

        default:
          break;
      }
    },
    srnSearch: _.debounce(function (e) {
      if (this.selectedPatient.srn == "") {
        return;
      }
      this.findCustomer({
        filter: { srn: parseSrn(this.selectedPatient.srn) },
        positiveCallback: (customer) => {
          if (customer) {
            this.selectedPatient.customer = customer;
            this.showCustomerNotes(customer);
          }
        },
      });
    }, Constants.debounce),
    srnCaregiverSearch: _.debounce(function (e) {
      if (this.selectedPatient.caregiverSrn == "") {
        return;
      }
      this.findCustomer({
        filter: {
          caregiverSrn: parseSrn(this.selectedPatient.caregiverSrn),
          isCaregiver: true,
        },
        positiveCallback: (customer) => {
          if (customer) {
            if (customer.patients.length == 0) {
              return this.setMessage({
                text: "This caregiver does not have any patients",
                type: "error",
              });
            }

            if (customer.patients.length > 1) {
              this.pendingCaregiver = customer;
              this.isCaregiverPatientSelectionPopUpOpen = true;
            } else if (customer.patients.length == 1) {
              this.selectedPatient.srn = customer.patients[0].srn;
              this.selectedPatient.customer = customer.patients[0];
              this.selectedPatient.caregiver = customer;
              this.showCustomerNotes(this.selectedPatient?.customer)
            }
          }
        },
      });
    }, Constants.debounce),
    selectCaregiverPatient(patient) {
      this.selectedPatient.srn = patient.srn;
      this.selectedPatient.customer = patient;
      this.selectedPatient.caregiver = this.pendingCaregiver;
      this.isCaregiverPatientSelectionPopUpOpen = false;
      this.showCustomerNotes(this.selectedPatient?.customer);
    },
    clearRecreationalForm() {
      this.scannedDL = "";
      this.selectedRecreationalCustomer = JSON.parse(
        JSON.stringify(initialCustomer)
      );
    },
    clearMedicalForm() {
      this.selectedPatient = JSON.parse(JSON.stringify(initialPatient));
    },
    validateMedicalCustomer() {
      const privacyDocExpDate = this.selectedPatient.customer.privacyDocExpDate;

      if (!privacyDocExpDate || new Date(privacyDocExpDate) < new Date()) {
        this.showPrivacyPolicyPopUp = true;
      } else {
        this.performSaveQueueCustomer({ mode: "medical" });
      }
    },
    handlePrivacyAcceptance() {
      const date = new Date();
      this.selectedPatient.updatedPrivacyDocExpDate = date.setDate(
        date.getDate() + 365
      );
      this.showPrivacyPolicyPopUp = false;
      this.performSaveQueueCustomer({ mode: "medical" });
    },
    performSaveQueueCustomer(options) {
      if (
        options.mode == "medical" &&
        this.selectedPatient.customer.isMinor &&
        !this.selectedPatient.caregiver
      ) {
        return this.setMessage({
          text: "This patient needs a caregiver",
          type: "error",
        });
      }

      const payload = {
        customer: this.selectedPatient,
        positiveCallback: (result) => {
          if (result?.patientVerificationResult) {
            this.setPatientInfoPopUp(result?.patientVerificationResult?.body);
          }
          this.clearMedicalForm();
          this.clearRecreationalForm();
          this.scannedQR = "";
        },
      };

      if (options && options.mode === "recreational") {
        payload.customer = this.selectedRecreationalCustomer;
      }

      payload.customer.workstation = this.selectedWorkstation;

      delete payload.customer.id;

      // If customer is not in the system, then remove license id form request body
      if (!payload.customer.customer) {
        delete payload.customer.dl;
      }

      this.saveQueueCustomer(payload);
    },
    setPatientInfoPopUp(patientInfo) {
      this.patientInfo.privileges = patientInfo.privileges;
      this.patientInfo.minorRestrictions = patientInfo.minor_restrictions;
      this.patientInfo.fullName = `${patientInfo.card_first_name} ${patientInfo.card_last_name}`;
      this.patientInfo.cardId = patientInfo.card_id;
      this.patientInfo.cardExpiration = patientInfo.card_expiration;
      this.patientInfo.limit = patientInfo.card_limit;
      this.patientInfo.preferredName = patientInfo.preferredName;
      this.showPatientInfoPopUp = true;
    },
    selectItemFromSearch: function (entity, item) {
      switch (entity) {
        case "customer":
          this.showCustomerNotes(item)
          delete item.id;
          this.assignRecreationalCustomerInfo(item);
          this.$set(this.selectedRecreationalCustomer, "customer", item);
          break;

        default:
          break;
      }
    },
    showCustomerNotes(customer) {
      if(!customer) return;

      const positiveCallback = (creditMemos) => {
        this.setNotePopUp({creditMemos});
        this.messages = customer?.noteList?.filter(
          (n) => n.noteType == "pop-up-message"
        ) ?? [];
      };

      this.performGetValidCreditMemoPerCustomerIdAndDate(customer._id ?? customer.id);

      searchEntities({
        entity: Entities.CREDITMEMO,
        filter: { customer: customer._id ?? customer.id, memoBalance: { $gt: 0 } },
        positiveCallback: positiveCallback,
      });
    },
    async performGetValidCreditMemoPerCustomerIdAndDate(customerId) {
      const payload = {
        customerId: customerId,
        date: new Date(),
        memoType: "credit-memos-except-ccp-points",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payload)
        .then((res) => (this.PaymentAmount = res))
        .catch(() => {});

      const payloadPoints = {
        customerId: customerId,
        date: new Date(),
        memoType: "Points",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payloadPoints)
        .then((res) => (this.PointsAmount = res))
        .catch(() => {});

      const payloadCCP = {
        customerId: customerId,
        date: new Date(),
        memoType: "CCP",
      };

      this.getValidCreditMemoPerCustomerIdAndDate(payloadCCP)
        .then((res) => (this.CCPAmount = res))
        .catch(() => {});
    },
    // method used both by customer selection and license scanning
    assignRecreationalCustomerInfo(customer) {
      const name =
        typeof customer.name === "string" ? customer.name : customer.name.first;
      const lastName =
        typeof customer.lastName === "string"
          ? customer.lastName
          : customer.name.last;

      this.selectedRecreationalCustomer.firstName = name
        ? capitalizeFirstLetter(name)
        : "";
      this.selectedRecreationalCustomer.lastInitial = lastName[0];
      this.selectedRecreationalCustomer.lastName =
        capitalizeFirstLetter(lastName);

      // customer.dl is the scanned license id
      if (customer.dl) {
        this.selectedRecreationalCustomer.dl = customer.dl;
      }

      // if customer has loyalty card, associate it to the customer checkin

      if (customer?.loyaltyInfo?.cardNumber) {
        this.$set(
          this.selectedRecreationalCustomer,
          "loyaltyCard",
          customer.loyaltyInfo.cardNumber
        );
      }
      this.selectedRecreationalCustomer.state = customer.state;
    },
    checkIDExpiration(data) {
      const result = { message: "", success: true };
      try {
        const { expiration_date } = data;
        const year = expiration_date.substring(0, 4);
        const month = expiration_date.substring(4, 6);
        const day = expiration_date.substring(6, 8);
        const date = new Date(`${year}/${month}/${day}`);
        if (date < new Date()) {
          result.success = false;
          result.message = "License expired.";
        }
      } catch (error) {
        result.success = false;
        result.message =
          "Cannot evaluate expiration date, please check manually.";
      }
      return result;
    },
    checkIDAge(data) {
      const result = { message: "", success: true };
      try {
        const { birthday } = data;
        const year = birthday.substring(0, 4);
        const month = birthday.substring(4, 6);
        const day = birthday.substring(6, 8);
        const date = new Date(`${year}/${month}/${day}`);
        if (new Date() - date < 662256000 * 1000) {
          result.success = false;
          result.message = "Customer is not 21 years old.";
        }
      } catch (error) {
        result.success = false;
        result.message =
          "Cannot evaluate customer age from ID, please check manually.";
      }
      return result;
    },
    parseQR: debounce(function (event) {
      if (this.scannedQR == "") {
        return;
      }
      try {
        const checkInType = this.$route.query.checkInType;
        this.findCustomer({
        filter: { _id: this.scannedQR.trim() },
        positiveCallback: (customer) => {
          if (customer && !checkInType || checkInType == "recreational") {
            this.assignRecreationalCustomerInfo(customer);
            this.selectedRecreationalCustomer.customer = customer;
            this.showCustomerNotes(customer);
          }
          if (customer && checkInType == "medical") {
            if (customer.isCaregiver && customer.isPatient) {
              this.isCheckInPopUpOpen = true;
            } else {
              if (customer.isCaregiver) {
                this.checkInAs("caregiver");
              } else {
                this.checkInAs("patient");
              }
            }
          }
        },
      });
      } catch (error) {
        console.log(error);
        this.setMessage({
          title: "Error in parsing QR",
          text: `This QR Code cannot be parsed.<br>Try again or please enter data manually.`,
          type: "error",
        });
      }
    }, 500),
    parseDL: debounce(function (event) {
      if (this.scannedDL == "") {
        return;
      }
      try {
        const data = aamva.pdf417(this.scannedDL);

        data.dl = data.id;
        delete data.id;

        this.assignRecreationalCustomerInfo(data);

        const expirationResult = this.checkIDExpiration(data);
        const birthdayResult = this.checkIDAge(data);
        if (!birthdayResult.success || !expirationResult.success) {
          this.setMessage({
            title: "Warning!",
            text: [expirationResult.message, birthdayResult.message].join(" "),
            type: "error",
          });
        }

        // Find customer in system
        if (
          this.currentStore.isDriverLicenseScanningEnabled &&
          data.dl &&
          data.dl.length > 5
        ) {
          this.findExistingCustomerAndAssociateIt({ licenseNumber: data.dl });
        }
      } catch (error) {
        console.log(error);
        this.setMessage({
          title: "Error in parsing DL",
          text: `This Driver's License cannot be parsed.<br>Please enter data manually.`,
          type: "error",
        });
      }
    }, 500),
    findExistingCustomerAndAssociateIt(filter) {
      this.findCustomer({
        filter: filter,
        positiveCallback: (customer) => {
          if (customer) {
            this.showCustomerNotes(customer);
            delete customer.id;
            this.selectedRecreationalCustomer.customer = customer;
            this.assignRecreationalCustomerInfo(customer);
          }
        },
      });
    },
    loyaltyLookup() {
      this.showLoyaltyLookupPopUp = true;
    },
    selectLoyaltyCustomer(id, loyaltyCustomer, nestedId) {
      this.selectedRecreationalCustomer.loyaltyCard =
        loyaltyCustomer.cardNumber;

      this.selectedRecreationalCustomer.firstName = loyaltyCustomer.firstName;
      this.selectedRecreationalCustomer.lastInitial =
        loyaltyCustomer.lastName?.[0];
      this.showLoyaltyLookupPopUp = false;

      // Find existing customer with that loyalty card
      this.findExistingCustomerAndAssociateIt({
        "loyaltyInfo.cardNumber": loyaltyCustomer.cardNumber,
      });
    },
    newCustomer() {
      if (this.currentStore.loyaltySystem === "clutch") {
        this.showNewCustomerPopUp = true;
      } else {
        const query = {
          licenseNumber: this.selectedRecreationalCustomer.dl,
          name: this.selectedRecreationalCustomer.firstName,
          lastName: this.selectedRecreationalCustomer.lastName,
          state: this.selectedRecreationalCustomer.state,
        };
        router.push({
          name: "customerEditor",
          query,
        });
      }
    },
    customerCreation(customer) {
      this.showNewCustomerPopUp = false;
      delete customer.id;
      this.assignRecreationalCustomerInfo(customer);
      this.$set(this.selectedRecreationalCustomer, "customer", customer);
      this.showCustomerNotes(customer);
    },
  },
};
</script>

<style lang="scss">
.customer-check-in {
  @media (max-width: 800px) {
    margin-top: 0px;
    padding-top: 20px;
  }

  &__patient-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }
  }

  &__privacy-iframe {
    width: 100%;
    height: 600px;

    @include respond(phone) {
      height: 100%;
    }
  }

  &__privacy-buttons {
    padding: 2rem;

    & > button {
      width: 100%;
    }
  }

  &__padded-container {
    padding: 2rem;
  }

  &__loyalty-input-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}

.customer-info-container {
  margin: 20px 5px;
}

.separator {
  position: relative;

  &__line-over {
  }

  &__or {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.textarea {
  width: 100%;
  min-height: 100px;
}
</style>
