import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const DEFAULT_SCHEDMA_CREDIT_MEMO = {
  _id: "NEW",
  ccpExpirationDate: null,
  ccpStartDate: null,
  pointQuantity2: 0,
  pointQuantity: 0,
  notes: "",
  discountAmountRefund: 0,
  memoBalance: 0,
  memoAmountUsed: 0,
  memoTotal: 0,
  memoType: "",
  expenseAccountCode: null,
  accountNo: "",
  isArchived: false,
  isPostedToJournal: false,
  postedToJournalAt: null,
  archivedAt: null,
  item: null,
  order: null,
  purchaseOrder: null,
  supplier: null,
  customer: null,
  store: null,
  organization: null,
  createdBy: null,
  updatedBy: null,
  enteredDate: null,
  createdAt: new Date(),
};

const state = {
  creditMemos: [],
  creditMemo: { ...DEFAULT_SCHEDMA_CREDIT_MEMO },
  loadingStatus: {
    saveCreditMemo: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setCreditMemos: (state, payload) => {
    state.creditMemos = payload;
  },
  setCreditMemo: (state, payload) => {
    if (payload._id == "NEW") {
      state.creditMemo = { ...DEFAULT_SCHEDMA_CREDIT_MEMO };
    } else {
      state.creditMemo = payload;
    }
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  getCreditMemos: (state) => state.creditMemos,
  getCreditMemo: (state) => state.creditMemo,
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveCreditMemo: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveCreditMemo", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.creditMemo.save,
      payload.creditMemo,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveCreditMemo", status: false });
        if (response) {
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(response);
          }
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getCreditMemoById: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl + Apis.creditMemo.getById + "?id=" + payload._id,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setCreditMemo", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  postToJournal: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.creditMemo.postToJournal,
        payload,
        function (response, error) {
          if (response) {
            commit(
              "setMessage",
              { text: "Posted to Journal", type: "success" },
              { root: true }
            );
            resolve();
          } else {
            console.log(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
          }
        }
      );
    });
  },
  printCreditMemo: ({ commit, state, rootGetters }, payload) => {
    const ws = rootGetters["posModule/selectedWorkstation"];
    if (!ws) {
      return commit(
        "setMessage",
        {
          text: "No workstation selected",
          type: "error",
          errorType: "no-workstation",
        },
        { root: true }
      );
    }
    commit("setLoadingStatus", { api: "printCreditMemo", status: true });
    payload.workstation = ws;
    HttpService.post(
      Apis.backendBaseUrl + Apis.creditMemo.printCreditMemo,
      payload,
      function (response, error) {
        commit("setLoadingStatus", { api: "printCreditMemo", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Sent to print queue", type: "success" },
            { root: true }
          );
          const data = response.data.body;
          if (payload.positiveCallback) {
            payload.positiveCallback(data);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  reversePostToJournal: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.creditMemo.reversePostToJournal,
        payload,
        function (response, error) {
          if (response) {
            commit(
              "setMessage",
              { text: "Reversed Post to Journal", type: "success" },
              { root: true }
            );
            resolve();
          } else {
            console.log(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
          }
        }
      );
    });
  },
  createCCP: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.creditMemo.createCCP,
        payload,
        function (response, error) {
          if (response) {
            commit(
              "setMessage",
              { text: "Created CCP", type: "success" },
              { root: true }
            );
            resolve();
          } else {
            console.log(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
          }
        }
      );
    });
  },
  getValidCreditMemoPerCustomerIdAndDate: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.creditMemo.getValidCreditMemoPerCustomerIdAndDate,
        payload,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            resolve(data);
          } else {
            console.log(error);
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
          }
        }
      );
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
