<template>
  <div class="brands responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'brands', label: 'Brands' },
      ]" :entity="entity" />
      <SearchTable :entity="entity" :filter="{
        isDeleted: false
      }" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";

import { listPageHeader } from "./inputs";

export default {
  name: "brands",
  data() {
    return {
      Entities,
      entity: Entities.BRAND,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("Brands");
  },
};
</script>
