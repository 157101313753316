var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Tax System Check","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'taxSystemCheck',
          label: 'Tax System Check',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.inventoryTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.createdAt))]),_c('td',[_vm._v(_vm._s(record.auditId))]),_c('td',[_vm._v(_vm._s(record.barcode))]),_c('td',[_vm._v(_vm._s(record.orderItemTaxType))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.thc))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.thca))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.thcPercent))]),_c('td',[_vm._v(_vm._s(record.inventoryType))]),_c('td',[_vm._v(_vm._s(record.taxName))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.taxRate))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(record.taxAmount))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Created")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Audit Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Item Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Tax Type")]),_c('th',{attrs:{"align":"right"}},[_vm._v("thc")]),_c('th',{attrs:{"align":"right"}},[_vm._v("thca")]),_c('th',{attrs:{"align":"right"}},[_vm._v("thc %")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Inventory Type")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Tax Name")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Tax Rate")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Tax Amount")])])])}]

export { render, staticRenderFns }