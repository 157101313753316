import { render, staticRenderFns } from "./TraceabilityCredentials.vue?vue&type=template&id=506d1120&scoped=true&"
import script from "./TraceabilityCredentials.vue?vue&type=script&lang=js&"
export * from "./TraceabilityCredentials.vue?vue&type=script&lang=js&"
import style0 from "./TraceabilityCredentials.vue?vue&type=style&index=0&id=506d1120&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506d1120",
  null
  
)

export default component.exports