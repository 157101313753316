<template>
  <div class="organization-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :hideAddButton="true"
        :selectedEntity="selectedOrganization"
        :links="[
          { name: 'home', label: 'Home' },
          {
            name: 'organizationEditor',
            label: selectedOrganization.name,
            query: $route.query,
          },
        ]"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @updateIsEdit="isEdit = $event"
        :isEdit="isEdit"
        :entity="entity"
        :isCollapsed="isCollapsed"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div
              class="
                grid-two-column
                two-columns-layout--responsive-horizontal-space-10
              "
            >
              <div
                v-for="input in mainInputs"
                :key="input.id"
                :class="'input-text' + ' ' + input.class"
              >
                <Input
                  :model="selectedOrganization[input.id]"
                  @updateModel="selectedOrganization[input.id] = $event"
                  :inputData="input"
                  :isEdit="isEdit"
                />
              </div>
            </div>
            <div>
              <div
                class="
                  two-columns-layout
                  two-columns-layout--responsive-horizontal-space-10
                "
              >
                <div>
                  <div class="input-text">
                    <div class="reversed-flex-column">
                      <FileUploader
                        label="Upload Logo"
                        allowedTypes="image/png, image/jpeg"
                        @change="updateImage"
                        :disabled="!isEdit"
                      />
                      <div class="input-text__label">Organization Logo</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    v-if="
                      this.selectedOrganization.imageNormal &&
                      (this.selectedOrganization.imageNormal.data ||
                        this.selectedOrganization.imageNormal.secure_url)
                    "
                    class="input-text"
                  >
                    <div class="reversed-flex-column">
                      <div>
                        <inline-button
                          label="Remove Logo"
                          type="plain-danger responsive-column form-height wide"
                          @click="() => removeImage()"
                          :disabled="!isEdit"
                        />
                      </div>

                      <div class="input-text__label">Organization Logo</div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                :src="
                  this.selectedOrganization.imageNormal &&
                  (this.selectedOrganization.imageNormal.data
                    ? this.selectedOrganization.imageNormal.data
                    : this.selectedOrganization.imageNormal.secure_url)
                "
                class="image-preview"
                alt
              />
            </div>
          </div>
        </div>
      </div>

      <EditorSection
        :title="`About ${selectedOrganization.name || ''}`"
        sectionType="about"
        v-if="selectedOrganization._id !== 'NEW'"
        :bus="bus"
      >
        <About
          v-if="selectedOrganization._id !== undefined"
          :entity="selectedOrganization"
        />
      </EditorSection>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import { mapActions, mapGetters } from "vuex";
import Constants from "@/resources/Constants";
import FileUploader from "@/components/FileUploader/FileUploader.vue";
import Input from "@/components/Input/Input.vue";
import { mainInputs, pageHeader } from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "organizationEditor",
  data() {
    return {
      Constants: Constants,
      mainInputs,
      isEdit: false,
      isCollapsed: true,
      bus: new Vue(),
      entity: Entities.ORGANIZATION,
      pageHeader,
    };
  },
  components: {
    PageHeader,
    About,
    FileUploader,
    EditorSection,
    Input,
  },
  mounted: function () {
    this.setPageTitle("Organization");
    if (this.$route.query.id) {
      this.getOrganizationById({
        _id: this.$route.query.id,
      });
    } else {
      this.setMessage({
        text: "Cannot create more than one organization",
        type: "error",
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getOrganizationById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "organizationModule/loadingStatus",
      organization: "organizationModule/organization",
    }),
    selectedOrganization: {
      get() {
        if (this.$route.query.id && this.organization) {
          this.setPageTitle(`Organization ${this.organization.name}`);
          return this.organization;
        } else {
          const result = {};
          return result;
        }
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getOrganizationById: "organizationModule/getOrganizationById",
      saveOrganization: "organizationModule/saveOrganization",
      setMessage: "setMessage",
      setMessagePopUp: "setMessagePopUp",
    }),
    getInput(field) {
      return this.mainInputs.find((i) => i.id == field);
    },
    performSave() {
      if (
        this.loadingStatus.saveOrganization ||
        this.selectedOrganization._id === undefined ||
        this.selectedOrganization._id === "NEW"
      ) {
        return;
      }

      const organizationToSave = this.normalizeEntityBeforeSaving(
        this.selectedOrganization
      );

      this.saveOrganization({
        organization: organizationToSave,
        positiveCallback: (organization) => {
          this.setPageTitle(`Organization ${organization.name}`);
          this.selectedOrganization = organization;
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(organization) {
      const entity = JSON.parse(JSON.stringify(organization));
      if (entity.state && entity.state.abbreviation) {
        entity.state = entity.state.abbreviation;
      }
      return entity;
    },
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (!this.selectedOrganization.imageNormal) {
          this.selectedOrganization.imageNormal = {};
        }
        this.selectedOrganization.imageNormal.data = e.target.result;
        this.$forceUpdate();
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.selectedOrganization.imageNormal = undefined;
      this.selectedOrganization.imageThumbnail = undefined;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedOrganization._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedOrganization._id === "NEW") {
        this.isEdit = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image-preview {
  width: 300px;
}
</style>
