<template>
  <div class="base-input" @click="$emit('click')">
    <label
      v-if="label"
      class="base-input__label"
      :class="{ 'base-input__label--focus': focused }"
      >{{ label }}</label
    >
    <div class="checkbox__body">
      <check-mark :toggleVariable="value" v-bind="$attrs" />
      <span v-if="sideLabel">{{ sideLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppInputControl",
  data() {
    return {
      focused: false,
    };
  },
  props: {
    label: {
      type: String,
    },
    sideLabel: {
      type: String,
      default: null,
    },
    value: {},
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "_checkbox.scss";
</style>
