var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Cannabis Expiring","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'inventoryExpiring',
          label: 'Cannabis Expiring',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.inventoryTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',[_vm._v(_vm._s(record.group))]),_c('td',[_vm._v(_vm._s(record.grams))]),_c('td',[_vm._v(_vm._s(record.name))]),_c('td',{staticClass:"batch"},[_vm._v(_vm._s(record.batchId))]),_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.dateExpiration))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.quantityOnHand))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.dollarValue)))])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.quantityOnHand)+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.dollarValue)))])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Group")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Grams")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Product")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Batch #")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Received")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Expires")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Quantity")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Dollar Value")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }