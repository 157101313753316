<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Employee Hours" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'employeeReport', label: 'Employee' },
        {
          name: 'employeeHours',
          label: 'Employee Hours',
        },
      ]"
      @export="exportToCsv" :disableExport="!reportData" />
    <br class="no-print" />
    <ReportFilter class="no-print"
      :filterByTime="true"
      @submit-filter="performGetReport"
      :isLoading="employeeTransactions"
      :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <td colspan="5">Employee Hours</td>
              </tr>
              <tr>
                <td colspan="5">{{ reportData.total.datePeriod }}</td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <td> </td>
              </tr>
              <tr>
                <th align="left">Employee</th>
                <th align="left">Stamped In</th>
                <th align="left">Stamped Out</th>
                <th align="right">Hours</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total3: data.isTotal }">
                <td v-if="!data.isTotal">{{ data.name }}</td>
                <td v-else>Totals</td>
                <td>{{ dateFormmatter(data.stampedIn) }}</td>
                <td>{{ dateFormmatter(data.stampedOut) }}</td>
                <td align="right">{{ Utilities.formatQuantity(data.calculatedHours) }}</td>
              </tr>
            </tbody>

            <tr class="skip-export">
              <td colspan="3" align="left">Grand Total</td>
              <td align="right"> {{ Utilities.formatQuantity(reportData.total.calculatedHours) }}</td>
            </tr>

          </table>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
data() {
  return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
      filterText: undefined,
  };
},
components: {
  PageHeader,
  ReportFilter,
},
created: function () {
  this.setPageTitle("Employee Hours");
},
mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      employeeTransactions: "reports/employeeTransactions",
    }),

    formattedData() {
      const data = this.reportData;
      let rows = []
      for (let [name, nameValue] of Object.entries(data.records)) {
        for (let [stampedIn, order] of Object.entries(nameValue.orders)) {
          rows.push(order)
        }
        rows.push({ ...nameValue.total, isTotal: true })
      }

      return rows;
    }
  },

  methods: {
    ...mapActions({
      getEmployeeTransactions: "reports/getEmployeeTransactions",
    }),
    performGetReport(filter) {
      let reportType = "employeeHours";
      this.getEmployeeTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "employeeHours");
    },

    dateFormmatter(date) {
    return Utilities.formatDateWithHour(date);
    },
  },

    beforeDestroy() {
    this.clearData()
  }
};
</script>

  <style lang="scss">
  @import "./_employeeHours.scss";