// Supplier Editor
import * as Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";

import {
  filterCriteria as supplierAgentFilterCriteria,
  searchColumnHeaders as supplierAgentColumnHeaders,
} from "../SupplierAgent/inputs";

import {
  filterCriteria as msoSupplierFilterCriteria,
  searchColumnHeaders as msoSupplierColumnHeaders,
} from "../MSOSupplier/inputs";

// Page Header
export const pageHeader = {
  title: "Supplier",
  isEditorView: true,
  cancelPath: "suppliers",
  addPath: "supplierEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Suppliers",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "supplierEditor",
};

// Search By Options:
// • Id
// • SRN #
// • Name (default index)
// • Phone #
// • Supplier Code
// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  {
    label: "SRN #",
    code: "srn",
    field: { type: "input", label: "SRN #" },
    isPartial: true,
  },
  {
    label: "Phone",
    code: "phone",
    field: { type: "input", label: "Phone" },
    isPartial: true,
  },
];

// Columns (in order):
// • _id (column header clickable)
// • supplierCode (missing from collection)
// • name
// • amountDue
// • srn
// • contactLastName
// • contactName
// • contactMiddleName
// • city
// • state
// • phone
export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Amount Due",
    id: "amountDue",
    prefix: "$",
    size: "medium",
  },
  {
    title: "SRN #",
    id: "srn",
    size: "medium",
  },
  {
    title: "Contact Last Name",
    id: "contactLastName",
    size: "medium",
  },
  {
    title: "Contact Name",
    id: "contactName",
    size: "medium",
  },
  {
    title: "Contact Middle Name",
    id: "contactMiddleName",
    size: "medium",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
    size: "medium",
  },
  {
    title: "Phone",
    id: "phone",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Short Name",
    id: "shortName",
  },
  {
    title: "Created By",
    id: "createdBy",
  },
  {
    title: "Updated By",
    id: "updatedBy",
  },
];

// Supplier Information - Main Section
export const supplierInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "msoSupplier",
        placeholder: "Select MSO Supplier",
        type: "search",
        title: "MSO Supplier",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.MSOSUPPLIER,
        columnHeaders: msoSupplierColumnHeaders,
        filterCriteria: msoSupplierFilterCriteria,
        filter: {
          "isActive" : true,
          $and : [
            { "isArchived" : false },
            { "isDeleted" : false },
          ]
        },
      },
      {
        id: "name",
        type: "input",
        title: "Supplier Name",
        focus: true,
      },
      {
        id: "shortName",
        type: "input",
        title: "Supplier Short Name",
      },
      {
        id: "shortNameOnlineStore",
        type: "input",
        title: "Online Store Short Name",
      },
      {
        id: "nameCode",
        placeholder: "Accounting Code",
        type: "input",
        title: "Accounting Code",
      },
      {
        id: "address",
        placeholder: "Address",
        type: "input",
        title: "Address",
        class: "grid-row-two-column",
      },
      {
        id: "city",
        placeholder: "City",
        type: "input",
        title: "City",
      },
      {
        id: "state",
        placeholder: "State",
        type: "select",
        title: "State",
        options: Constants.listOfStates,
      },
      {
        id: "zip",
        placeholder: "Zip",
        type: "mask",
        title: "Zip",
        mask: Constants.inputMasks.defaultZip,
      },
      {
        id: "county",
        placeholder: "County",
        type: "input",
        title: "County",
      },
      {
        id: "phone",
        type: "mask",
        title: "Phone",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "fax",
        type: "mask",
        title: "Fax",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "email",
        placeholder: "Email",
        type: "input",
        title: "Email",
        class: "grid-row-two-column",
      },
      {
        id: "website",
        placeholder: "Website",
        type: "input",
        title: "Website",
        class: "grid-row-two-column",
      },
      {
        id: "contactName",
        type: "input",
        title: "Contact First Name",
      },
      {
        id: "contactLastName",
        type: "input",
        title: "Contact Last Name",
      },
      {
        id: "contactMiddleName",
        type: "input",
        title: "Contact Middle Name",
      },
      {
        id: "contactTitle",
        type: "input",
        title: "Contact Title",
      },
      {
        id: "contactSalutation",
        placeholder: "Select Salutation",
        type: "select",
        title: "Salutation",
        options: Constants.listOfSalutations,
      },
      {
        id: "srn",
        type: "input",
        title: "SRN #",
        //mask: Constants.inputMasks.defaultSrn,
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isActive",
        type: "checkBox",
        title: "Active",
      },
      {
        id: "isArmsLength",
        type: "checkBox",
        title: "Is arm's length",
      },
      {
        id: "taxRate",
        type: "money",
        title: "Tax Rate",
        precision: Constants.numericInput.percentagePrecision,
        prefix: "%",
      },
      {
        id: "cannabisTaxRate",
        type: "money",
        title: "Cannabis Tax Rate",
        precision: Constants.numericInput.percentagePrecision,
        prefix: "%",
      },
      {
        id: "fein",
        type: "mask",
        title: "FEIN",
        mask: Constants.inputMasks.defaultFein,
      },
      {
        id: "amountDue",
        type: "money",
        title: "Amount Due",
        precision: Constants.inputMasks.moneyPrecision,
        prefix: "$",
      },
      {
        id: "moneyPrecision",
        type: "money",
        title: "Credit Limit",
        precision: Constants.inputMasks.moneyPrecision,
        prefix: "$",
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Verification Section
export const verificationInputs = [
  {
    id: "srn",
    type: "input",
    title: "SRN #",
  },
  {
    id: "expirationDate",
    placeholder: "Expiration Date",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "verificationDate",
    placeholder: "Verification Date",
    type: "text",
    title: "Verification Date",
  },
  {
    id: "verifiedBy",
    placeholder: "Verified by",
    type: "name",
    title: "Verified by",
    disabled: true,
  },
];

// Supplier Delivery Agent Section
export const supplierDeliveryAgentInputs = [
  {
    id: "srn",
    type: "text",
    title: "SRN #",
  },
  {
    id: "name",
    type: "text",
    title: "Supplier Delivery Agent",
  },
  {
    id: "verificationDate",
    placeholder: "Verification Date",
    type: "text",
    title: "Verification Date",
  },
  {
    id: "verifiedBy",
    placeholder: "Verified By",
    type: "name",
    title: "Verified By",
    disabled: true,
  },
];

export const supplierAgentSearchInput = {
  id: "selectedAgent",
  type: "search",
  title: "Supplier Agent",
  buttonOnly: true,
  entity: Entities.SUPPLIERAGENT,
  columnHeaders: supplierAgentColumnHeaders,
  filterCriteria: supplierAgentFilterCriteria,
};

export const defaultSelectedSupplier = {
  _id: "NEW",
  name: undefined,
  shortName: undefined,
  address: undefined,
  city: undefined,
  state: "",
  zip: "",
  county: "",
  phone: "",
  fax: "",
  email: "",
  website: "",
  contactName: "",
  contactLastName: "",
  contactSalutation: "",
  contactMiddleName: "",
  contactTitle: "",
  taxRate: 0,
  cannabisTaxRate: 0,
  fein: "",
  srn: "",
  expirationDate: "",
  originalExpirationDate: "",
  verificationDate: "",
  amountDue: 0,
  creditLimit: 0,
  isActive: true,
  isVerified: false,
  createdBy: {},
  updatedBy: {},
  verifiedBy: {},
  agents: [],
  msoSupplier: undefined,
  isArmsLength: false,
};
