// commeting out the ones d33 said that they don't needed. but keeping it in case we need to turn them on again

const productExtraInfo = [
  { label: "Blend", code: "blend" },
  { label: "Breeder", code: "breeder" },
  // { label: "Cartridge", code: "cartridge" },
  // { label: "Cultivar", code: "cultivar" },
  { label: "Dietary Information", code: "dietary-information" },
  // { label: "Disposable", code: "disposable" },
  { label: "Dosage", code: "dosage" },
  // { label: "Edible", code: "edible" },
  { label: "Extraction", code: "extraction" },
  { label: "Lineage", code: "lineage" },
  // { label: "Pre-Roll", code: "pre-roll" },
  // { label: "Sublingual", code: "sublingual" },
  // { label: "Topical", code: "topical" },
  { label: "Palate", code: "palate" },
  { label: "Weight", code: "weight" },
];

export default productExtraInfo;
