<template>
  <div class="space-between">
    <div class="field">
      <div class="input-text__label">ID</div>
      <div class="field__content">{{ entity._id }}</div>
    </div>
    <div class="field">
      <div class="input-text__label">Created by</div>
      <div class="field__content">
        {{ createdBy }}
      </div>
    </div>
    <div class="field">
      <div class="input-text__label">Created at</div>
      <div v-if="entity.createdAt" class="field__content">
        {{ moment(entity.createdAt).format("MMMM Do YYYY, h:mm:ss a") }}
      </div>
      <div v-if="entity.createdAt === undefined" class="field__content">-</div>
    </div>
    <div v-if="entity.updatedBy" class="field">
      <div class="input-text__label">Updated by</div>
      <div class="field__content">
        {{
            entity.updatedBy.name +
            " " +
            (entity.updatedBy.lastName ? entity.updatedBy.lastName : "")
        }}
      </div>
      <div v-if="entity.updatedBy === undefined" class="field__content">-</div>
    </div>
    <div v-if="entity.updatedAt" class="field">
      <div class="input-text__label">Updated at</div>
      <div class="field__content">
        {{ moment(entity.updatedAt).format("MMMM Do YYYY, h:mm:ss a") }}
      </div>
      <div v-if="entity.updatedAt === undefined" class="field__content">-</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "About",
  data() {
    return {
      moment: moment,
      isCollapsed: false,
    };
  },
  props: {
    entity: {
      type: Object,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    createdBy() {
      const createdBy = this.entity.createdBy;
      if (!createdBy) return "-";

      const isEmpty = Object.keys(createdBy).length === 0;
      if (isEmpty) return "-";

      const lastName = createdBy.lastName ? createdBy.lastName : "";
      const result = `${createdBy.name} ${lastName}`;
      return result;
    },
  },
  methods: {
    toggleSection() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  mounted() {
    // console.log(this.entity);
  },
};
</script>
<style scoped lang="scss">
.about-section-base {
  margin: 20px;
  margin-bottom: 40px;
  padding: 5px 10px;

  &--no-margin-bottom {
    margin: 20px;
  }

  &__header {
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__header-name {
    font-size: $text-size-6;
    font-weight: bold;

    >div {
      margin: 0 7px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
