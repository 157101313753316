<template>
  <div class="inventory-count-body">
    <table class="table" v-if="hasItems" id="inventory-count-table">
      <tr class="table-header">
        <th class="table__action"></th>
        <th>Batch #</th>
        <th v-if="!isMobileView">Group</th>
        <th v-if="!isMobileView">Supplier</th>
        <th v-if="!isMobileView">Product</th>
        <th v-if="!isMobileView" class="column-sm">Grams</th>
        <th class="column-sm">Count</th>
        <th v-if="!isMobileView">Employee</th>
        <th v-if="isClosed && !isMobileView" class="column-sm">Expected</th>
        <th v-else-if="doneCounting && !isMobileView" class="column-sm" v-canAccess="managementRoles">{{ doneCounting ?
          'Expected' : '' }}
        </th>
        <th v-if="isClosed" class="column-sm">Diff</th>
        <th v-else-if="doneCounting" class="column-sm" v-canAccess="managementRoles">{{ doneCounting ?
          'Diff' : ''
        }}</th>
      </tr>
      <DynamicScroller :items="filteredItems" :min-item-size="50" class="scroller">
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[
            item.externalFields.product.name,
          ]" :data-index="index">
            <Record :item="item" v-on="$listeners" :viewAll="viewAll" :allowEditing="allowEditing"
              :showExtraColumns="isClosed" :doneCounting="doneCounting" @printRecord="printBatchHandler" />
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </table>
    <div class="no-data" v-else>
      <p>No Item Scanned.<br />Start by scanning by the batch.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Record from '../Record/Record.vue';
import {
  managementRoles,
} from "@/constants/roles";

export default {
  props: {
    items: {
      type: Array
    },
    viewAll: {
      type: Boolean,
      default: false
    },
    inventoryCount: {
      type: Object
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      managementRoles,
    }
  },
  components: {
    Record,
  },
  methods: {
    printBatchHandler(item) {
      this.$emit('printBatch', item)
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'loginModule/user'
    }),
    isMobileView() {
      return window.innerWidth < 1000
    },
    isClosed() {
      return this.inventoryCount.isClosed
    },
    userId() {
      return this.getUser._id;
    },
    doneCounting() {
      if (this.viewAll) return true;
      let employees = this.inventoryCount.employees;
      const employee = employees.find(e => e.employee._id == this.userId);
      if (!employee) return false;
      return employee.countCompleted
    },
    allowEditing() {
      let employees = this.inventoryCount.employees;
      const employee = employees.find(e => e.employee._id == this.userId);
      if (!employee) return false;
      return employee.countCompleted
    },
    hasItems() {
      return Object.entries(this.items).length > 0
    },
    filteredItems() {
      const formattedSearch = this.search.trim();
      if (!formattedSearch) return this.items;

      const filteredItems = this.items.filter((i) => i.batchId.includes(formattedSearch));
      return filteredItems;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './body';
</style>