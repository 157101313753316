// Supplier Agent Editor
import Constants from "@/resources/Constants";
// Page Header
export const pageHeader = {
  title: "Supplier Agent",
  isEditorView: true,
  cancelPath: "supplierAgents",
  addPath: "supplierAgentEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Supplier Agents",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "supplierAgentEditor",
};

// Search By Options:
// • Id
// • SRN #
// • Supplier Id
// • Supplier Name
// • Name (lastName + name) (default index)
// • Company (missing from collection)
// • Phone #
// • Cell #

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Last Name",
    code: "lastName",
    field: { type: "input", label: "Last Name" },
    isPartial: true,
  },
  {
    label: "First Name",
    code: "name",
    field: { type: "input", label: "Name" },
    isPartial: true,
  },
  {
    label: "Id",
    code: "_id",
    field: { type: "input", label: "Id" },
  },
  {
    label: "SRN #",
    code: "srn",
    field: { type: "input", label: "SRN #" },
    isPartial: true,
  },
  {
    label: "Cell",
    code: "cell",
    field: { type: "input", label: "Cell" },
    isPartial: true,
  },
  {
    label: "Phone",
    code: "phone",
    field: { type: "input", label: "phone" },
    isPartial: true,
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Last Name",
    id: "lastName",
    size: "large",
  },
  {
    title: "First Name",
    id: "name",
    size: "medium",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "SRN #",
    id: "srn",
    size: "medium",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
    size: "medium",
  },
  {
    title: "Phone",
    id: "phone",
    size: "medium",
  },
  {
    title: "Cell",
    id: "cell",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "First Name",
    id: "name",
  },
  {
    title: "Last Name",
    id: "lastName",
  },
  {
    title: "Created By",
    id: "createdBy",
  },
  {
    title: "Updated By",
    id: "updatedBy",
  },
];

// Supplier Agent Information - Main Section
export const supplierAgentInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "name",
        type: "input",
        title: "Name",
        focus: true,
      },
      {
        id: "middleName",
        placeholder: "Middle Name",
        type: "input",
        title: "Middle Name",
      },
      {
        id: "lastName",
        placeholder: "Last Name",
        type: "input",
        title: "Last Name",
      },
      {
        id: "salutation",
        placeholder: "Select Salutation",
        type: "select",
        title: "Salutation",
        options: Constants.listOfSalutations,
      },
      {
        id: "address",
        placeholder: "Address",
        type: "input",
        title: "Address",
        class: "grid-row-two-column",
      },
      {
        id: "city",
        placeholder: "City",
        type: "input",
        title: "City",
      },
      {
        id: "state",
        placeholder: "State",
        type: "select",
        title: "State",
        options: Constants.listOfStates,
      },
      {
        id: "zip",
        placeholder: "Zip",
        type: "mask",
        title: "Zip",
        mask: Constants.inputMasks.defaultZip,
      },
      {
        id: "county",
        placeholder: "County",
        type: "input",
        title: "County",
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isActive",
        placeholder: "Active",
        type: "checkBox",
        title: "Active",
      },
      {
        id: "isVerified",
        type: "checkBox",
        title: "Verified",
        options: ["Not Verified", "Is Verified"],
      },
      {
        id: "srn",
        placeholder: "SRN",
        type: "input",
        title: "SRN",
      },
      {
        id: "cell",
        placeholder: "Cell",
        type: "mask",
        title: "Cell",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "phone",
        placeholder: "Phone",
        type: "mask",
        title: "Phone",
        mask: Constants.inputMasks.defaultPhone,
      },
      {
        id: "email",
        type: "input",
        title: "Email",
        placeholder: "Email",
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Supplier Delivery Agent Section
export const supplierDeliveryAgentsInputs = [
  {
    id: "srn",
    placeholder: "SRN #",
    type: "input",
    title: "SRN #",
    //mask: Constants.inputMasks.defaultSrn,
  },
  {
    id: "verificationExpirationDate",
    placeholder: "Expiration Date",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "verificationDate",
    placeholder: "Verification Date",
    type: "calendar",
    title: "Verification Date",
  },
  {
    id: "verifiedBy",
    placeholder: "Verified By",
    type: "name",
    title: "Verified By",
    disabled: true,
  },
];

export const defaultSelectedSupplierAgent = {
  _id: "NEW",
  name: "",
  lastName: "",
  salutation: "",
  middleName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  phone: "",
  cell: "",
  email: "",
  notes: "",
  srn: "",
  verificationExpirationDate: "",
  verificationDate: "",
  isActive: true,
  isVerified: false,
  createdBy: {},
  updatedBy: {},
  verifiedBy: {},
};
