<template>
  <div class="discounts-summary">
    <div class="discounts-summary__row">
      <div>SubTotal</div>
      <div>
        {{ formatPrice(subTotal) }}
      </div>
    </div>
    <div class="discounts-summary__row">
      <div>Discount Total</div>
      <div>{{ formatPrice(discount) }}</div>
    </div>
    <div class="discounts-summary__discounts-breakdown">
      <div class="discounts-summary__row">
        <div>Instant</div>
        <div>{{ formatPrice(instant) }}</div>
      </div>
      <div class="discounts-summary__row">
        <div>Points</div>
        <div>{{ formatPrice(points) }}</div>
      </div>
      <div class="discounts-summary__row">
        <div>Coupon</div>
        <div>{{ formatPrice(coupon) }}</div>
      </div>
      <div class="discounts-summary__row">
        <div>CCP</div>
        <div>{{ formatPrice(ccp) }}</div>
      </div>
      <div class="discounts-summary__row">
        <div>Customer</div>
        <div>{{ formatPrice(customer) }}</div>
      </div>
    </div>
    <div
      class="
        discounts-summary__row
        discounts-summary__row--highlighted
        discounts-summary__total
      "
    >
      <div>Sale Total</div>
      <div>
        {{ formatPrice(orderTotal) }}
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";

export default {
  name: "PriceSummary",
  data() {
    return {};
  },
  props: {
    subTotal: {
      type: Number,
      default: 0,
    },
    discount: {
      type: Number,
      default: 0,
    },
    orderTotal: {
      type: Number,
      default: 0,
    },
    discounts: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    points() {
      let sum = 0;
      const discounts = this.discounts.filter((d) => d.type == "points");
      discounts.forEach((d) => (sum += d.amountDiscountedInDollars));
      return sum;
    },
    coupon() {
      let sum = 0;
      const discounts = this.discounts.filter((d) => d.type == "coupon");
      discounts.forEach((d) => (sum += d.amountDiscountedInDollars));
      return sum;
    },
    ccp() {
      let sum = 0;
      const discounts = this.discounts.filter((d) => d.type == "ccp");
      discounts.forEach((d) => (sum += d.amountDiscountedInDollars));
      return sum;
    },
    customer() {
      let sum = 0;
      const discounts = this.discounts.filter((d) => d.type == "customer");
      discounts.forEach((d) => (sum += d.amountDiscountedInDollars));
      return sum;
    },
    instant() {
      let sum = 0;
      const discounts = this.discounts.filter((d) => d.type == "instant");
      discounts.forEach((d) => (sum += d.amountDiscountedInDollars));
      return sum;
    },
  },
  methods: {
    formatPrice(val) {
      return Utilities.formatPrice(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_summary.scss";
</style>
