<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader title="Sales Promo by Supplier and Group" :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'salesReport', label: 'Sales' },
          {
            name: 'salesPromoSupplierGroup',
            label: 'Sales Promo by Supplier and Group',
          },
        ]" @export="exportToCsv" :disableExport="!reportData" />
        <br class="no-print" />
        <ReportFilter class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
        <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Supplier</th>
                <th align="left">Group</th>
                <th align="right">Quantity</th>
                <th align="right">$Pre-Promo Sales Total</th>
                <th align="right">$Post-Promo Sales Total</th>
                <th align="right">$Difference</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[recordId, record] in Object.entries(reportData.list)" :key="recordId">
                <td>{{ record.supplier }}</td>
                <td>{{ record.group }}</td>
                <td align="right">{{ record.quantity }}</td>
                <td align="right">{{ Utilities.formatPrice(record.productTotal) }} </td>
                <td align="right">{{ Utilities.formatPrice(record.subTotal) }} </td>
                <td align="right">{{ Utilities.formatPrice(record.productTotal - record.subTotal) }} </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
                <td colspan="2">Totals</td>
                <td align="right">{{ reportData.total.quantity }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.productTotal) }} </td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.subTotal) }}</td>
                <td align="right">{{ Utilities.formatPrice(reportData.total.productTotal - reportData.total.subTotal) }} </td>
              </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sale Promos by Supplier and Group");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "salesPromoSupplierGroup";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "salesPromoSupplierGroup");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesPromoSupplierGroup.scss";