

const aggregateRecords = (newRecords, currentRecords, others) => {

  let updatedRecords = currentRecords.concat(newRecords);

  if (shouldSortByTimestamp(others)) {
    updatedRecords = sortByTimeStamp(updatedRecords)
  }

  return updatedRecords;
}

const sortByTimeStamp = (records) => {
  return records.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
}

const shouldSortByTimestamp = (properties) => {
  if (properties && properties.isSortedByDate) return true;
  return false;
}

export default aggregateRecords;