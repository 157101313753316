<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Not Posted Sales Orders"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'salesReport', label: 'Sales' },
          {
            name: 'salesOrderStatusNotPosted',
            label: 'Not Posted Sales Orders',
          },
        ]"
        @export="exportToCsv"
        :disableExport="!reportData"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        @submit-filter="performGetReport"
        :isLoading="salesTransactions"
      />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr><td colspan="3">Not Posted Sales Orders</td></tr>
              <tr><td> </td></tr><tr><td> </td></tr>
              <tr>
                <th align="left">Date Created</th>
                <th align="left">Date Updated</th>
                <th align="left">Employee</th>
                <th align="left">Order Code</th>
                <th align="left">Source</th>
                <th align="left">Paid</th>
                <th align="left">Customer</th>
                <th align="left">Batch #</th>
                <th align="right">Quantity</th>
                <th align="right">Original Order</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.records" :key="index">
                <td>{{ record.date }}</td>
                <td>{{ record.dateUpdated }}</td>
                <td>{{ record.employee }}</td>
                <td>{{ record.orderCode }}</td>
                <td>{{ record.source }}</td>
                <td>{{ record.amountPaid }}</td>
                <td>{{ record.customer }}</td>
                <td class="batch">{{ record.batchId }}</td>
                <td align="right">
                  {{ record.quantity }}
                </td>
                <td>{{ record.originalOrder }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="8">Total</td>
              <td align="right">
                {{ reportData.total.quantity }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Not Posted Sales Orders");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      salesTransactions: "reports/salesTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getSalesTransactions",
    }),
    performGetReport(filter) {
      let reportType = "salesOrderStatusNotPosted";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesOrderStatusNotPosted");
    },
  },
};
</script>

<style lang="scss">
@import "./_salesOrderStatusNotPosted.scss";
</style>
