<template>
  <div class="clutch-rewards-redeem">
    <div class="clutch-rewards-redeem__row">
      <div>
        <div class="input-text__label">Order total</div>
        ${{ lineTotal }}
      </div>
      <div>
        <div class="input-text__label">Customer Cashback</div>
        ${{ getCashbackTotal }}
      </div>
      <BaseInput
        label="Cashback to Redeem"
        controlType="number"
        placeholder="Cashback to Redeem"
        v-model="value"
        @focus="setFocus"
      />
    </div>
    <div class="clutch-rewards-redeem__row">
      <BaseBtn
        class="instant__action--mobile"
        label="Redeem"
        @click="apply"
        :disabled="!canApply"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "ClutchRewardsRedeem",
  data() {
    return {
      clutchCustomer: {},
      value: "",
    };
  },
  props: {
    loyaltyInfo: {
      type: Object,
      default: () => ({}),
    },
    lineTotal: {
      type: Number,
    },
  },
  components: {
    BaseInput,
    BaseBtn,
  },
  created() {
    this.fetchClutchCustomer();
  },
  computed: {
    ...mapGetters({ order: "orderModule/posOrder" }),
    getPointTotal() {
      return this.clutchCustomer?.points;
    },
    getCashbackTotal() {
      return this.clutchCustomer?.cashback;
    },
    canApply() {
      return (
        this.value > 0 &&
        this.value &&
        this.value <=
          this.getCashbackTotal - this.existingClutchCashbackDiscounts &&
        this.value <= this.lineTotal
      );
    },
    existingClutchCashbackDiscounts() {
      return this.order.discountsApplied
        ?.filter((b) => b.type == "clutch-cashback")
        .map((b) => b.amountDiscountedInDollars)
        .reduce((prev, curr) => prev + curr, 0);
    },
  },
  methods: {
    ...mapActions({
      lookup: "loyaltyModule/lookup",
    }),
    fetchClutchCustomer() {
      this.lookup({
        searchFields: { cardNumber: this.loyaltyInfo.cardNumber },
        positiveCallback: (result) => {
          this.clutchCustomer = result[0];
        },
      });
    },
    apply() {
      const discount = {
        type: "clutch-cashback",
        amountDiscountedInDollars: this.value,
      };

      this.$emit("applyDiscount", discount);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_clutchRewardsRedeem.scss";
</style>
