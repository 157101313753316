<template>
  <div class="navigation-arrows">
    <span
      @click="previous"
      class="navigation-arrows__arrow"
      :class="current === 0 ? 'navigation-arrows__arrow--disabled' : ''"
    >
      <i class="fas fa-chevron-left"></i>
    </span>
    <span
      @click="next"
      class="navigation-arrows__arrow"
      :class="
        current + 1 === length ? 'navigation-arrows__arrow--disabled' : ''
      "
    >
      <i class="fas fa-chevron-right"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "NavigationArrows",
  data() {
    return {};
  },
  props: {
    length: {
      type: Number,
    },
    current: {
      type: Number,
    },
  },
  methods: {
    previous() {
      if (this.current === 0) {
        return;
      }
      this.$emit("previous");
    },
    next() {
      if (this.current === this.length) {
        return;
      }
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss">
.navigation-arrows {
  color: $dark-accent-color;

  &__arrow {
    margin-right: 20px;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      color: $dark-disabled-text-color;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.light .navigation-arrows {
  color: $light-accent-color;

  &--disabled {
    cursor: not-allowed;
    color: $light-disabled-text-color;
  }
}
</style>
