<template>
  <div>
    <div class="section-base__header space-between space-between--not-responsive">
      <div v-if="item.isNew" class="section-base__header-name">New Item</div>
      <div v-else class="section-base__header-name">Item {{ getItemName }}</div>
      <div class="section-base__header-navigation-arrows">
        <navigation-arrows :current="selectedItemSequentialNumber" :length="numberOfItems"
          @next="$emit('select-item-by-sequential-number', 'next')"
          @previous="$emit('select-item-by-sequential-number', 'previous')" />
      </div>
    </div>

    <div class="padded elevated-0 bordered position-relative" :class="{
      'bordered__highlighted--primary': item.isReceived,
      'bordered__highlighted--danger': item.isArchived,
    }">
      <transition name="fade">
        <ContainerLabel v-if="item.isReceived && !item.isPostedToProducts" type="primary" text="RECEIVED" />
        <ContainerLabel v-if="item.isReceived && item.isPostedToProducts" type="primary"
          text="RECEIVED & POSTED TO PRODUCTS" />
        <ContainerLabel v-if="item.isArchived" type="danger" text="ARCHIVED" />
      </transition>
      <div class="item-detail__header">
        <div class="item-detail__left-container">
          <div class="item-detail__divider item-detail__divider--mobile-only"></div>
          <div class="item-detail__left-container-row">
            <Input :key="item._id + item.batchId" :inputData="getInput('product')" :isEdit="!disabled && !item.isReceived"
              :model="item.product" />
            <div class="field input-wrapper">
              <div class="input-text__label">Group</div>
              <div class="field__content">
                <a class="internal-link" v-if="item.product.group" target="_blank"
                  :href="`/groups/groupEditor?id=${item.product.group._id}`">{{ item.product.group.name }}</a>
                <span v-else>-</span>
              </div>
            </div>
          </div>

          <div class="item-detail__grid">
            <Input :inputData="getInput('quantityPurchased')" :isEdit="!disabled && !item.isReceived"
              :model="item.quantityPurchased" @updateModel="item.quantityPurchased = $event" />
            <Input :inputData="getInput('unitCost')" :isEdit="!disabled && !item.isReceived" :model="item.unitCost"
              @updateModel="updateUnitCost" />
          </div>

          <div class="item-detail__grid">
            <Input :inputData="getInput('batchId')" :isEdit="!disabled && !item.isReceived" :model="item.batchId"
              @updateModel="item.batchId = $event" />
            <Input :inputData="getInput('expirationDate')" :isEdit="!disabled && !item.isReceived"
              :model="item.expirationDate" @updateModel="item.expirationDate = $event" />
            <Input :inputData="getInput('batchType')" :isEdit="!disabled && !item.isReceived" :model="item.batchType"
              @updateModel="item.batchType = $event" />
          </div>

          <div class="item-detail__grid">
            <Input :inputData="getInput('isSample')" :isEdit="!disabled && !item.isReceived" :model="item.isSample"
              @updateModel="item.isSample = $event" />
            <Input :inputData="getInput('isPublishedToOnlineStore')" :isEdit="!disabled && !item.isReceived"
              :model="item.isPublishedToOnlineStore" @updateModel="item.isPublishedToOnlineStore = $event" />
            <Input :inputData="getInput('isMenuPrintEnabled')" :isEdit="!disabled && !item.isReceived"
              :model="item.isMenuPrintEnabled" @updateModel="item.isMenuPrintEnabled = $event" />
            <Input :inputData="getInput('isTaxed')" :isEdit="!disabled && !item.isReceived" :model="item.isTaxed"
              @updateModel="updateIsTaxed" />
          </div>
        </div>
        <div class="item-detail__right-container">
          <div class="item-detail__right-container-buttons">
            <div class="input-wrapper">
              <div class="input-text__label">
                {{ item.isReceived ? "Unreceive" : "Receive" }}
              </div>
              <BaseBtn label="Receive" size="sm" v-show="!item.isReceived" :disabled="disabled || item.isReceived"
                @click="$emit('receive')" />
              <BaseBtn label="Unreceive" size="sm" v-show="item.isReceived" :disabled="disabled || !item.isReceived"
                @click="$emit('unreceive')" />
            </div>

            <div class="input-wrapper">
              <div class="input-text__label">Close</div>
              <BaseBtn size="sm" label="Close" :colorType="3" :disabled="disabled" @click="$emit('close')" />
            </div>
          </div>
          <div class="item-detail__divider item-detail__divider--mobile-only"></div>
          <PriceSummary :rows="priceSummary" />
        </div>
      </div>

      <div class="item-detail__divider"></div>

      <div class="space-between">
        <div class="field input-wrapper">
          <div class="input-text__label">Quantity on Hand</div>
          <div class="field__content">
            {{ item.quantityOnHand }}
          </div>
        </div>

        <div class="field input-wrapper">
          <div class="input-text__label">Quantity in WI</div>
          <div class="field__content">
            {{ item.quantityWorkingInventory }}
          </div>
        </div>

        <div class="field input-wrapper">
          <div class="input-text__label">Tax Rate</div>
          <div class="field__content">{{ taxRate }}%</div>
        </div>

        <div class="field input-wrapper">
          <div class="input-text__label">THC</div>
          <div class="field__content">{{ getCannabinoid("thc") }}</div>
        </div>
        <div class="field input-wrapper">
          <div class="input-text__label">THCa</div>
          <div class="field__content">{{ getCannabinoid("thca") }}</div>
        </div>
        <div class="field input-wrapper">
          <div class="input-text__label">CBD</div>
          <div class="field__content">{{ getCannabinoid("cbd") }}</div>
        </div>
        <div class="field input-wrapper">
          <div class="input-text__label">CBDa</div>
          <div class="field__content">{{ getCannabinoid("cbda") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import { itemDetailInputs } from "../../inputs";
import Input from "@/components/Input/Input.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import INVENTORY_TYPES from "@/constants/InventoryTypes";
export default {
  name: "PurchaseOrderItemDetail",
  components: { Input, ContainerLabel, BaseBtn, PriceSummary },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {
          product: {},
        };
      },
    },
    purchaseOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedItemSequentialNumber: {
      type: Number,
      default: 0,
    },
    numberOfItems: {
      type: Number,
      default: 0,
    },
    taxRate: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Utilities,
      itemDetailInputs: JSON.parse(JSON.stringify(itemDetailInputs)),
    };
  },
  computed: {
    priceSummary() {
      const priceSummary = [];
      const itemsTotal = this.item.unitCost * this.item.quantityPurchased;
      const discount = this.item.discount;
      const subTotal =
        this.item.unitCost * this.item.quantityPurchased - discount;
      const tax = this.item.tax;
      const delivery = this.item.freight;
      const total = subTotal + tax + delivery;

      priceSummary.push({
        name: "Item Total",
        value: Utilities.formatPrice(itemsTotal),
      });
      priceSummary.push({
        name: "Discount",
        value: Utilities.formatPrice(discount),
      });
      priceSummary.push({
        name: "Sub Total",
        value: Utilities.formatPrice(subTotal),
        isHighlighted: true,
      });
      priceSummary.push({
        name: "Tax",
        value: Utilities.formatPrice(tax),
      });
      priceSummary.push({
        name: "Delivery",
        value: Utilities.formatPrice(delivery),
      });
      priceSummary.push({
        name: "Total",
        value: Utilities.formatPrice(total),
        isHighlighted: true,
      });

      return priceSummary;
    },
    getItemName() {
      try {
        if (this.item.description) {
          return this.item.description
        }
        if (this.item.batchId) {
          return this.item.batchId
        }
        return this.item._id
      } catch (error) {
        console.log('error in generating item name')
        return ''
      }
    }
  },
  methods: {
    getInput(field) {
      const input = this.itemDetailInputs.find((i) => i.id == field);
      if (field == "product") {
        if (this.item.description && this.item.batchId) {
          input.title = `Assign Product for ${this.item.description} - Batch Id: ${this.item.batchId}`;
        } else {
          input.title = `Assign Product`;
        }
        //input.defaultFilterValue = this.purchaseOrder.supplier._id;
        input.filterMetadata = { filterFields: [] }

        input.defaultFilter = { supplier: this.purchaseOrder.supplier._id }
        input.filterMetadata.filterFields.push({ filterKey: 'supplier', description: 'Supplier', value: this.purchaseOrder.supplier.name })


        if (this.item.inventoryType) {
          const inventoryTypeNumber = this.item.inventoryType.toString()
          input.defaultFilter['externalFields.group.inventoryType'] = { "$regex": inventoryTypeNumber, "$options": "i" }
          input.filterMetadata.filterFields.push({ filterKey: 'externalFields.group.inventoryType', description: 'Inventory Type', value: INVENTORY_TYPES.find(it => it.includes(inventoryTypeNumber)) || inventoryTypeNumber, isDeletable: true })
        }

      }
      return input;
    },
    getCannabinoid(field) {
      if (!this.item.chemicalComposition) {
        return "-";
      }
      const cannabinoid = this.item.chemicalComposition[field];
      return cannabinoid ? cannabinoid : "-";
    },
    updateUnitCost(event) {
      this.item.unitCost = event;
      this.$emit("unit-cost-change");
    },
    updateIsTaxed(event) {
      this.item.isTaxed = event;
      this.$emit("set-selected-item-taxes", {
        removeTaxes: !this.item.isTaxed,
      });
    },
    selectItemFromSearch: function (entity, item) {
      this.$emit("assign-product", item);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderItemDetail.scss";
</style>
