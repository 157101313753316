<template>
  <div class="scanner">
    <div class="input-method">
      <div class="input-method-option" @click="() => inputMethod = INPUT_METHOD.SCAN"
        :class="{ 'input-method-option--selected': inputMethod == INPUT_METHOD.SCAN }">Scan</div>
      <div class="input-method-option" @click="() => inputMethod = INPUT_METHOD.LAST4DIGITS"
        :class="{ 'input-method-option--selected': inputMethod == INPUT_METHOD.LAST4DIGITS }">Last 4 digits</div>
    </div>
    <BaseInput v-if="inputMethod == INPUT_METHOD.SCAN" class="barcode-input" placeholder="Scan barcode here..."
      v-model="barcode" :applyFocus="true" ref="scanInput" />
    <div v-else class="last-4-digits">
      <BaseInput class="last-4-digits-input" placeholder="Batch last 4 digits" v-model="last4Digits"
        v-on:input="onLastDigitsHandler" :applyFocus="true" controlType="number" ref="digitInput" />
      <BaseBtn class="last-4-digits-btn" label="Search" @click="getByLast4Digits" />
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import _ from "lodash";
import { mapActions } from 'vuex';

const INPUT_METHOD = {
  SCAN: 0,
  LAST4DIGITS: 1
}

export default {
  components: {
    BaseInput,
    BaseBtn
  },
  data() {
    return {
      inputMethod: INPUT_METHOD.SCAN,
      barcode: '',
      last4Digits: '',
      INPUT_METHOD: INPUT_METHOD
    }
  },
  watch: {
    barcode: _.debounce(function (newVal) {
      if (newVal) {
        let formatBarcode = newVal.replace(/\s/g, '');
        formatBarcode = formatBarcode.replace(/[^a-zA-Z0-9 ]/g, "");
        this.getByBarcode(formatBarcode);
      }
      this.barcode = '';
    }, 500),
  },
  methods: {
    ...mapActions({
      getItemsByBarcode: 'inventoryCountModule/getItemsByBarcode'
    }),
    setCursor() {
      if (this.inputMethod == INPUT_METHOD.SCAN) {
        this.$refs.scanInput.focus()
      } else {
        this.$fers.digitInput.focus()
      }
    },
    // onBarcodeHandler(event) {
    //   // TODO:
    //   console.log(event.target.value)
    // },
    onLastDigitsHandler(event) {
      // TODO: 
      // console.log(event.target.value)
    },
    searchHandler() {
      console.log('search handler')
    },
    getByBarcode(barcode) {
      this.getItemsByBarcode({ barcode, isLast4Digits: false })
        .then((res) => {
          this.$emit('on-items', res)
        })
    },
    getByLast4Digits() {
      const barcode = this.last4Digits;
      this.last4Digits = ''
      this.getItemsByBarcode({ barcode, isLast4Digits: true })
        .then((res) => {
          // TODO: remove log
          console.log(res)
          this.$emit('on-items', res)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './scanner';
</style>