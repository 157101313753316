<template>
  <div class="order-list">
    <EntityFilter
      :selectedFilter="selectedFilter"
      :filterCriteria="filterCriteria"
      :preFilters="selectedPreFilters"
      @criteriaChange="performSetSelectedFilter"
      @valueChange="loadData"
    />
    <div class="table-container margined bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Customer</th>
            <th class="cell">Date</th>
            <th class="cell">Created by</th>
            <th class="cell">Updated by</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loadingStatus.getOrders && orders.length === 0" class="row">
            <td colspan="100" class="center padded">
              <i class="fas fa-spin fa-circle-notch"></i> Loading...
            </td>
          </tr>
          <tr class="row" v-for="g in orders" :key="g._id">
            <td class="cell">
              <inline-button
                v-if="!allowSelection"
                :to="{ name: 'orderEditor', query: { id: g._id } }"
                label="Open"
                type="plain-primary responsive-column form-height wide"
              />
              <inline-button
                v-if="allowSelection"
                label="Select"
                type="plain-primary responsive-column form-height wide"
                @click="() => emitSelectedElement(g)"
              />
            </td>
            <td class="cell">
              {{ Utilities.composeNameAndLastName(g.customer) }}
            </td>
            <td class="cell">
              {{
                moment(g.createdAt).format(Constants.dates.extendedDateFormat)
              }}
            </td>
            <td class="cell">
              {{ Utilities.composeNameAndLastName(g.createdBy) }}
            </td>
            <td class="cell">
              {{ Utilities.composeNameAndLastName(g.updatedBy) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Constants from "@/resources/Constants";
import EntityFilter from "@/components/EntityFilter/EntityFilter";
import Utilities from "@/lib/Utilities";
import moment from "moment";

export default {
  name: "OrderList",
  data() {
    return { Utilities: Utilities, Constants: Constants, moment: moment };
  },
  props: {
    allowSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EntityFilter,
  },
  mounted: function () {
    this.getOrder();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "orderModule/loadingStatus",
      orders: "orderModule/orders",
      filterCriteria: "orderModule/filterCriteria",
      selectedFilter: "orderModule/selectedFilter",
      selectedPreFilters: "orderModule/selectedPreFilters",
    }),
  },
  methods: {
    ...mapActions({
      getOrder: "orderModule/getOrders",
      setSelectedFilter: "orderModule/setSelectedFilter",
    }),
    loadData: function (filter) {
      this.setSelectedFilter(filter);
      this.getOrder();
    },
    performSetSelectedFilter({ filter, filter2 }) {
      this.setSelectedFilter(filter);
      this.getOrder();
    },
    emitSelectedElement(selectedElement) {
      this.$emit("elementSelected", selectedElement);
    },
  },
};
</script>

<style lang="scss"></style>
