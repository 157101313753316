const Configuration = {
  displayDateFormat: "MM/DD/YYYY h:mm:ss a",
  displayDateFormatTimeOnly: "h:mm a",
  types: {
    CUSTOMER_TYPE_MEDICAL: 1,
    CUSTOMER_TYPE_RECREATIONAL: 2,
    STORE_TYPE_MEDICAL: 1,
    STORE_TYPE_RECREATIONAL: 2,
    STORE_TYPE_MEDICAL_AND_RECRIATIONAL: 3,
    ORDER_TYPE_MEDICAL: 1,
    ORDER_TYPE_IN_STATE: 2,
    ORDER_TYPE_OUT_OF_STATE: 3,
  },
};

module.exports = Configuration;
