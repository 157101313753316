var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Employee Notes","links":[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'employeeReport', label: 'Employee' },
          {
            name: 'employeeNotes',
            label: 'Employee Notes',
          } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false,"filterByStoreOrOrg":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.formattedData),function(data,index){return _c('tr',{key:index,class:{ total2: data.isTotal }},[(!data.isTotal)?_c('td',[_vm._v(_vm._s(data.thisEmployee))]):_c('td',[_vm._v("Totals")]),_c('td',[_vm._v(_vm._s(data.date))]),_c('td',[_vm._v(_vm._s(data.noteUpdated))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(data.hours))]),_c('td',[_vm._v(_vm._s(data.noteType))]),_c('td',[_vm._v(_vm._s(data.thisNote))])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Grand Total")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.hours))])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Updated")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Updated By")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Hours")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Note Type")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Note")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }