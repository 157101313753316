import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedCoupon } from "@/views/Coupon/inputs";
const state = {
  coupons: [],
  coupon: { ...defaultSelectedCoupon },
  loadingStatus: {
    saveCoupon: false,
    getCoupons: false,
    getCouponById: false,
    deleteCoupon: false,
  },
  couponPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setCoupons: (state, payload) => {
    state.coupons = payload;
  },
  setCoupon: (state, payload) => {
    if (payload._id === "NEW") state.coupon = { ...defaultSelectedCoupon };
    else state.coupon = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setCouponPopUp: (state, payload) => {
    state.couponPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  coupons: (state) => state.coupons,
  coupon: (state) => state.coupon,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  couponPopUp: (state) => state.couponPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveCoupon: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveCoupon", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveCoupon,
      payload.coupon,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveCoupon",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteCoupon: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteCoupon",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteCoupon,
      payload.coupon,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteCoupon",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getCouponById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getCouponById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getCouponById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getCouponById",
          status: false,
        });
        if (response) {
          commit("setCoupon", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setCouponPopUp: ({ commit, state }, payload) => {
    commit("setCouponPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
