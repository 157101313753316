<template>
  <div class="fulfillment-order__order elevated-0">
    <div class="fulfillment-order__horizontal-padding fulfillment-order__header">
      <div class="fulfillment-order__order-code">
        {{ order.code }}
      </div>
      <div v-if="!order.isFilled" class="fulfillment-order__header-buttons">
        <BaseBtn v-if="showOpenButton" size="sm" iconGroup="fa" iconName="folder-open" @click="$emit('selectOrder', order)" />
          <BaseBtn size="sm" iconGroup="fa" iconName="box" label="Fulfill" @click="() => $emit('fulfill', {order})"
          :isLoading="order.isLoading" :disabled="order.isLoading" />
      </div>
    </div>
    <div class="fulfillment-order__groups elevated-0">
      <div v-for="group in getAggregatedOrder(order).groups" :key="group._id">
        <div>{{ group.name }}</div>
        <div class="fulfillment-order__products">
          <div v-for="product in group.products" :key="product._id">
            <div>
              <div class="fulfillment-order__product">{{ product.name }} [<span class="gray-text">{{
    product.externalFields.supplier.shortName || product.externalFields.supplier.name
}}</span>]</div>
            </div>
            <div v-for="item in product.items" :key="item._id" @click="$emit('toggleItem', { order, product, item })">
              <div class="fulfillment-order__item" :class="{ 'fulfillment-order__item-marked': item.isFilled }">{{ item.quantity
}} x {{ item.batchId }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="space-between gray-text text-small fulfillment-order__footer-info fulfillment-order__horizontal-padding">
      <div>
        {{ getCustomer(order) }}
      </div>
      <div>
        {{ formatDate(order.postedToJournalAt) }}
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"
import moment from "moment";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "FulfillmentOrder",
  data() {
    return {};
  },
  props: {
    order: {
      type: Object
    },
    showOpenButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseBtn,
  },
  methods: {
    getCustomer(order) {
      try {
        return Utilities.composeNameAndLastName(order.customer)
      } catch (error) {
        return '-'
      }
    },
    formatDate(date) {
      return moment(date).format(Constants.dates.timeOnly)
    },
    getAggregatedOrder(originalOrder) {
      const order = cloneDeep(originalOrder)

      // order products by group
      const groupHash = {}

      for (const product of order.products) {
        if (groupHash[product.group._id]) {
          groupHash[product.group._id].products.push(product)
        } else {
          groupHash[product.group._id] = { ...product.group, products: [product] }
        }
      }

      order.groups = Object.keys(groupHash).map(k => groupHash[k])

      return order
    }
  }
};
</script>

<style lang="scss">
@import "./_fulfillmentOrder.scss";
</style>
