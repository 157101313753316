<template>
  <div :class="entitySubMenu.length === 0 ? 'entity-sub-menu-margin' : ''">
    <div class="menu-container" v-if="entitySubMenu.length > 0">
      <div class="main-menu" @mouseleave="
        closeMenu('sub-menu');
      removeActiveLinks('button');
                                                                ">
        <template v-for="menuItem in entitySubMenu">
          <div :key="'main-menu__' + menuItem.title" class="main-menu__menu-item">
            <button :key="'main-menu__' + menuItem.title + '__button'" :id="'main-menu__' + menuItem.title"
              class="button plain-primary" :class="isDisabled ? 'main-menu__disabled' : ''" @click="
                removeActiveLinks('button');
              closeMenu('first-sub-menu');
              closeMenu('second-sub-menu');
              openMenu(
                'main-menu__' + menuItem.title,
                'first-sub-menu__' + menuItem.title
              );
                                                                                                                              ">
              <div class="label">{{ menuItem.title }}</div>
              <font-awesome-icon v-if="menuItem.children" class="icon" :icon="['fas', 'angle-down']" />
            </button>

            <div class="first-sub-menu sub-menu" :key="'first-sub-menu__' + menuItem.title"
              :id="'first-sub-menu__' + menuItem.title">
              <div class="menu-overlay">
                <template v-for="child in menuItem.children">
                  <button :key="'first-sub-menu__' + child.title" :id="'first-sub-menu__' + child.title"
                    class="button first-sub-menu_button plain-primary"
                    @click="
                      handleMenuClicked(child, 'sub-menu');
                    closeMenu('second-sub-menu');
                    openMenu(
                      'first-sub-menu__' + child.title,
                      'second-sub-menu__' + child.title
                    );
                                                                                                                                                                              ">
                    <div class="label">{{ child.title }}</div>
                    <font-awesome-icon v-if="child.children" class="icon" :icon="['fas', 'angle-right']" />
                  </button>
                  <div class="second-sub-menu sub-menu" :id="'second-sub-menu__' + child.title"
                    :key="'second-sub-menu__' + child.title"
                    @mouseleave="
                      closeMenu('second-sub-menu');
                    removeActiveLinks('first-sub-menu_button');
                                                                                                                                                                              ">
                    <template v-for="nestedChild in child.children">
                      <button :key="'second-sub-menu__' + nestedChild.title"
                        :id="'button second-sub-menu__' + nestedChild.title" class="plain-primary"
                        @click="handleMenuClicked(nestedChild, 'sub-menu')">
                        <div class="label">{{ nestedChild.title }}</div>
                      </button>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { entitySubMenuList } from "../utils/subMenuLists";
library.add(faUserSecret);

export default {
  name: "EntitySubMenu",
  data() {
    return {
      entitySubMenu: [],
    };
  },
  props: {
    entity: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  created: function () { },
  components: {
    FontAwesomeIcon,
  },
  mounted: function () {
    this.entitySubMenu = entitySubMenuList[this.entity] || [];
  },
  methods: {
    openMenu: function (buttonId, menuToShow) {
      if (this.isDisabled) return;
      document.getElementById(buttonId).classList.add("selected");
      const menu = document.getElementById(menuToShow);
      menu.classList.add("show");
    },
    closeMenu: function (menuClass) {
      let menusToClose = document.getElementsByClassName(menuClass);
      menusToClose = Array.from(menusToClose);
      menusToClose.forEach((menu) => {
        menu.classList.remove("show");
      });
    },
    removeActiveLinks: function (className) {
      let buttons = document.getElementsByClassName(className);
      buttons = Array.from(buttons);
      buttons.forEach((button) => {
        button.classList.remove("selected");
      });
    },
    handleMenuClicked: function (action, menuClass) {
      if (action?.children?.length > 0) return;
      else {
        this.closeMenu(menuClass);
        this.$emit("menu-option-click", this.entity, action.key);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.menu-container {
  margin-bottom: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.main-menu {
  position: relative;
  display: inline-block;
  background-color: #2b2b2c;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;

  margin-bottom: 10px;

  &__disabled {
    color: gray;

    &:hover {
      color: gray;
    }
  }

  &__menu-item {
    display: inline-block;
  }
}

.light .main-menu {
  background-color: #f0f1f4;
}

.menu-overlay {
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
}

.first-sub-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #2b2b2c;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.light .first-sub-menu {
  background-color: #f0f1f4;
}

.second-sub-menu {
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: #2b2b2c;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &>div {
    margin: 5px;
  }
}

.light .second-sub-menu {
  background-color: #f0f1f4;
}

button {
  background-color: transparent;
  border: none;
  color: $dark-text-color-0;
  font-weight: 600;
  padding: 5px 10px;
  margin: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: start;
  cursor: pointer;

  .label {
    margin-right: 10px;
  }

  &:hover {
    color: $dark-accent-color;
  }
}

.light button {
  color: $light-text-color-0;

  &:hover {
    color: $light-accent-color;
  }
}

.icon {
  color: $dark-accent-color;
}

.selected {
  color: $dark-accent-color;
}

.light .icon {
  color: $light-accent-color;
}

.light .selected {
  color: $light-accent-color;
}

.show {
  display: flex;
}

@media screen and (max-width: 800px) {
  .first-sub-menu {
    width: 100%;
  }

  .second-sub-menu {
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
