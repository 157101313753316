export const mergeProductIdInputs = [
  {
    id: "name",
    type: "text",
    title: "Product Name to Merge",
  },
  {
    id: "supplier.name",
    type: "text",
    title: "Supplier",
  },
  {
    id: "group.name",
    type: "text",
    title: "Group",
  },
  {
    id: "cannabisGramsUnit",
    type: "number",
    title: "Grams",
    suffix: "g",
  },
];

export const changeGroupIdInputs = [
  {
    id: "name",
    type: "text",
    title: "Group",
  },
];

export const changeProductIdInputs = [
  {
    id: "name",
    type: "text",
    title: "Name",
  },
  {
    id: "supplier.name",
    type: "text",
    title: "Supplier",
  },
  {
    id: "group.name",
    type: "text",
    title: "Group",
  },
  {
    id: "cannabisGramsUnit",
    type: "number",
    title: "Grams",
    suffix: "g",
  },
];

export const duplicateNewSupplierInputs = [
  {
    id: "_id",
    type: "text",
    title: "Id",
  },
  {
    id: "name",
    type: "text",
    title: "Name",
  },
  {
    id: "srn",
    type: "text",
    title: "SRN",
  },
];
