<template>
  <div class="app-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <div class="app" id="app" :class="{ blur: isPopUpOpen }">
      <NavigationDrawer ref="menu" v-if="user.isLogged" class="app__sidebar" />
      <StoreSelected />
      <router-view
        @click.native="clicked"
        class="app__body"
        id="app-body"
        :class="{ 'app__body--fullWidth': !user.isLogged }"
      />
    </div>
    <CustomerVerificationPopUp v-if="customerVerificationPopUp.isOpen" />
    <LabelPrinterSelectionPopUp v-if="labelPrinterSelectionPopUp.isOpen" />
    <OrderTypeSelectionPopUp v-if="orderTypeSelectionPopUp.isOpen" />
    <OrderTypePopUp v-if="orderTypePopUp.isOpen" />
    <OrderModePopUp v-if="orderModePopUp.isOpen" />
    <CaregiverPatientSelectionPopUp
      v-if="caregiverPatientSelectionPopUp.isOpen"
    />
    <RecreationalOrderTypePopUp v-if="recreationalOrderTypePopUp.isOpen" />
    <OrderPopUp v-if="orderPopUp.isOpen" />
    <EmployeeConfirmationPopUp v-if="employeeConfirmationPopUp.isOpen" />
    <SelectedProductPopUp v-if="selectedProductPopUp.isOpen" />
    <WorkstationSelectionPopUp v-if="workstationSelectionPopUp.isOpen" />
    <ProductPopUp v-if="productPopUp.isOpen" />
    <CustomerPopUp v-if="customerPopUp.isOpen" />
    <QueuePopUp v-if="queuePopUp.isOpen" />
    <StateProductPopUp v-if="stateInventoryPopUp.isOpen" />
    <FlightsPopUp />
    <DeliveryPopUp />
    <NotePopUp v-if="notePopUp.isOpen" />
    <SellOptionsPopUp v-if="sellOptionsPopUp.isOpen" />
    <CreditMemoPopUp />
    <CouponPopUp />
    <ItemMovementPopUp v-if="itemMovementPopUp.isOpen" />
    <MessagePopUp v-if="messagePopUp.isOpen" />
    <Toast :message="message" />
    <Discounts v-if="discountsPopUp.isOpen" />
    <GiftCard v-if="giftCardPopUp.isOpen" />
    <GiftCardCart v-if="giftCardCartPopUp.isOpen" />
    <Search v-if="showSearch" @toggle-modal="showSearch = false"></Search>
    <StoreSelectionPopUp v-if="storeSelectionPopUp.isOpen" />

  </div>
</template>

<script>
import Toast from "@/components/ToastMessage";
import StoreSelected from "@/components/StoreSelected";
import CustomerVerificationPopUp from "@/components/CustomerVerificationPopUp";
import OrderTypeSelectionPopUp from "@/components/OrderTypeSelectionPopUp";
import RecreationalOrderTypePopUp from "@/components/RecreationalOrderTypePopUp";
import OrderTypePopUp from "@/components/OrderTypePopUp";
import OrderModePopUp from "@/components/OrderModePopUp";
import CaregiverPatientSelectionPopUp from "@/components/CaregiverPatientSelectionPopUp";
import MessagePopUp from "@/components/MessagePopUp";
import EmployeeConfirmationPopUp from "@/components/EmployeeConfirmationPopUp";
import WorkstationSelectionPopUp from "@/components/WorkstationSelectionPopUp";
import SelectedProductPopUp from "@/components/SelectedProductPopUp";
import FlightsPopUp from "@/components/FlightsPopUp";
import DeliveryPopUp from "@/components/DeliveryPopUp";
import NotePopUp from "@/components/NotePopUp";
import StoreSelectionPopUp from "@/components/StoreSelectionPopUp";
import SellOptionsPopUp from "@/components/SellOptionsPopUp";
import CreditMemoPopUp from "@/components/CreditMemoPopUp";
import CouponPopUp from "@/components/CouponPopUp";
import { mapActions, mapGetters } from "vuex";
import ProductPopUp from "@/views/Product/components/ProductPopUp";
import CustomerPopUp from "@/views/Customer/components/CustomerPopUp";
import OrderPopUp from "@/views/Order/components/OrderPopUp";
import QueuePopUp from "@/views/Queue/components/QueuePopUp";
import StateProductPopUp from "@/views/reports/StateInventory/components/StateInventoryPopUp";
import ItemMovementPopUp from "@/components/ItemMovementPopUp";
import NavigationDrawer from "@/components/NavigationDrawer/NavigationDrawer.vue";
import Discounts from "@/views/Pos/components/Discounts/Discounts.vue";
import GiftCard from "@/views/Pos/components/GiftCard/GiftCard.vue";
import GiftCardCart from "@/views/Pos/components/GiftCardCart/GiftCardCart.vue";
import LabelPrinterSelectionPopUp from "@/components/LabelPrinterSelectionPopUp.vue";
import Search from "@/components/Search/Search.vue";

export default {
  name: "App",
  components: {
    Toast,
    CustomerVerificationPopUp,
    OrderTypeSelectionPopUp,
    MessagePopUp,
    EmployeeConfirmationPopUp,
    WorkstationSelectionPopUp,
    SelectedProductPopUp,
    FlightsPopUp,
    DeliveryPopUp,
    NotePopUp,
    SellOptionsPopUp,
    CreditMemoPopUp,
    CouponPopUp,
    ProductPopUp,
    CustomerPopUp,
    OrderPopUp,
    QueuePopUp,
    OrderTypePopUp,
    ItemMovementPopUp,
    RecreationalOrderTypePopUp,
    OrderModePopUp,
    CaregiverPatientSelectionPopUp,
    StateProductPopUp,
    NavigationDrawer,
    Discounts,
    GiftCard,
    GiftCardCart,
    LabelPrinterSelectionPopUp,
    StoreSelected,
    Search,
    StoreSelectionPopUp
  },
  data() {
    return {
      drawerVisible: true,
      windowWidth: window.innerWidth,
      showSearch: false
    };
  },
  mounted: function () {
    if (this.isLoading) this.$Progress.start();
  },
  watch: {
    isLoading() {
      if (!this.isLoading) this.$Progress.finish();
      else this.$Progress.start();
    },
    isIdle(newValue) {
      if (newValue) {
        //this.logout();
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDownListener);
  },
  // make sure you remove the listener when the component is no longer visible
  destroyed() {
    window.removeEventListener("keydown", this.keyDownListener);
  },
  computed: {
    ...mapGetters({
      message: "message",
      customerVerificationPopUp: "customerVerificationPopUp",
      orderTypeSelectionPopUp: "orderModule/orderTypeSelectionPopUp",
      orderTypePopUp: "posModule/orderTypePopUp",
      orderModePopUp: "posModule/orderModePopUp",
      caregiverPatientSelectionPopUp:
        "posModule/caregiverPatientSelectionPopUp",
      recreationalOrderTypePopUp: "posModule/recreationalOrderTypePopUp",
      messagePopUp: "messagePopUp",
      employeeConfirmationPopUp: "employeeModule/employeeConfirmationPopUp",
      workstationSelectionPopUp: "posModule/workstationSelectionPopUp",
      selectedProductPopUp: "posModule/selectedProductPopUp",
      flightsPopUp: "flightsPopUp",
      deliveryPopUp: "deliveryPopUp",
      notePopUp: "notePopUp",
      creditMemoPopUp: "creditMemoPopUp",
      couponPopUp: "couponPopUp",
      productPopUp: "productModule/productPopUp",
      customerPopUp: "customerModule/customerPopUp",
      msoGroupPopUp: "msoGroupModule/msoGroupPopUp",
      orderPopUp: "orderModule/orderPopUp",
      queuePopUp: "queueModule/queuePopUp",
      sellOptionsPopUp: "posModule/sellOptionsPopUp",
      itemMovementPopUp: "itemModule/itemMovementPopUp",
      stateInventoryPopUp: "stateModule/stateInventoryPopUp",
      user: "loginModule/user",
      isLoading: "isLoading",
      discountsPopUp: "posModule/discountsPopUp",
      giftCardPopUp: "posModule/giftCardPopUp",
      giftCardCartPopUp: "posModule/giftCardCartPopUp",
      labelPrinterSelectionPopUp: "labelPrinterSelectionPopUp",
      storeSelectionPopUp: "storeSelectionPopUp"
    }),
    currentRouteName() {
      return this.$route.name;
    },
    showMenu() {
      if (this.currentRouteName === "login" || this.currentRouteName === "receipt") return false;
      else return true;
    },
    isMobile() {
      return this.windowWidth < 400;
    },
    isPopUpOpen() {
      return (
        this.customerVerificationPopUp.isOpen ||
        this.orderTypeSelectionPopUp.isOpen ||
        this.messagePopUp.isOpen ||
        this.employeeConfirmationPopUp.isOpen ||
        this.workstationSelectionPopUp.isOpen ||
        this.selectedProductPopUp.isOpen ||
        this.flightsPopUp.isOpen ||
        this.deliveryPopUp.isOpen ||
        this.notePopUp.isOpen ||
        this.creditMemoPopUp.isOpen ||
        this.couponPopUp.isOpen ||
        this.productPopUp.isOpen ||
        this.customerPopUp.isOpen ||
        this.orderPopUp.isOpen ||
        this.queuePopUp.isOpen ||
        this.sellOptionsPopUp.isOpen ||
        this.orderTypePopUp.isOpen ||
        this.itemMovementPopUp.isOpen ||
        this.recreationalOrderTypePopUp.isOpen ||
        this.orderModePopUp.isOpen ||
        this.caregiverPatientSelectionPopUp.isOpen ||
        this.stateInventoryPopUp.isOpen ||
        this.discountsPopUp.isOpen ||
        this.giftCardPopUp.isOpen ||
        this.giftCardCartPopUp.isOpen ||
        this.labelPrinterSelectionPopUp.isOpen ||
        (this.message?.isOpen && this.message.type == "error") ||
        this.storeSelectionPopUp.isOpen
      );
    },
    isIdle() {
      return this.$store?.state?.idleVue?.isIdle;
    },
  },
  methods: {
    ...mapActions({
      logout: "loginModule/logout",
    }),
    keyDownListener(event) {
      if (event.key === "k" && (event.metaKey || event.ctrlKey) ) {
        this.showSearch = true
      }
    },
    clicked() {
      if (this.$refs.menu) {
        this.$refs.menu.closeMenu();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_app.scss";
</style>
