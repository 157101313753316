<template>
  <table style="width: 100%">
    <tr style="font-weight: bold; border: 1pt solid white; text-align: left">
      <th v-if="!summarized" style="min-width: 200px">Date</th>
      <th v-if="!summarized">Day</th>
      <th style="min-width: 200px">Employee</th>
      <th style="min-width: 200px">Invoice #</th>
      <th>Cash Payment</th>
      <th>Credit/Debit Payment</th>
      <th>CM Payment</th>
      <th>Gift Card Payment</th>
      <th>Sales Total</th>
      <th>Disc</th>
      <th>Refunds</th>
      <th>Customer Count</th>
    </tr>
    <tr
      v-for="(employeeData, index) of employeesData"
      :key="index"
      :class="{ total: employeeData.isTotal }"
    >
      <td v-if="!summarized">
        {{ employeeData.isTotal ? "" : parseDate(employeeData.row.date) }}
      </td>
      <td v-if="!summarized">
        {{ employeeData.isTotal ? "" : getDayName(employeeData.row.date) }}
      </td>
      <td>{{ employeeData.employee.name }}</td>
      <td>{{ employeeData.row.orderId }}</td>
      <td>{{ formatValue(employeeData.row.cashCollected) }}</td>
      <td>{{ employeeData.row.debitcreditCardCollected }}</td>
      <td>{{ employeeData.row.creditmemoCollected }}</td>
      <td>{{ employeeData.row.giftcardCollected }}</td>
      <td>{{ formatValue(employeeData.row.totalSales) }}</td>
      <td>{{ employeeData.row.discount }}</td>
      <td>{{ employeeData.row.refundTotal }}</td>
      <td>{{ employeeData.isTotal ? employeeData.row.customerCount : "" }}</td>
    </tr>
    <tr class="grandTotal">
      <td v-if="!summarized"></td>
      <td v-if="!summarized"></td>
      <td></td>
      <td>Grand Total</td>
      <td>{{ formatValue(cashReconciliation.cashCollected) }}</td>
      <td>{{ cashReconciliation.debitcreditCardCollected }}</td>
      <td>{{ cashReconciliation.creditmemoCollected }}</td>
      <td>{{ cashReconciliation.giftcardCollected }}</td>
      <td>{{ formatValue(cashReconciliation.totalSales) }}</td>
      <td>{{ cashReconciliation.discountTotal }}</td>
      <td>{{ cashReconciliation.refundTotal }}</td>
      <td>{{ cashReconciliation.customerCountTotal }}</td>
    </tr>
  </table>
</template>

<script>
import { DEFAULT_SCHEMA_CASH_RECONCILIATION } from "@/store/cashReconciliationModule/cashReconciliationModule.js";
import moment from "moment";
import Constants from "@/resources/Constants";

export default {
  props: {
    cashReconciliation: {
      type: Object,
      default: () => {
        return { ...DEFAULT_SCHEMA_CASH_RECONCILIATION };
      },
    },
    summarized: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    employeesData() {
      return this.formatDataForTable(this.cashReconciliation.employeesData);
    },
  },
  methods: {
    parseDate(date) {
      return moment(date).format(Constants.dates.extendedDateFormat);
    },
    formatValue(val) {
      if (!val) return '-'
      return parseFloat(val).toFixed(2);
    },
    getDayName(date) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var d = new Date(date);
      return days[d.getDay()];
    },
    formatDataForTable(employeesData) {
      const result = [];

      for (let employeeData of employeesData) {
        const orders = employeeData.orders;
        const employee = employeeData.employee;
        const total = employeeData.total;

        if (!this.summarized) {
          for (let order of orders) {
            const data = {
              row: order,
              employee,
              isTotal: false,
            };
            result.push(data);
          }
        }

        result.push({
          row: total,
          employee,
          isTotal: true,
        });
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
