<template>
  <div class="employee-time-sheets">
    <div
      class="employee-time-sheets__search-wrapper"
      id="employee-time-sheets-entity-filter"
    >
      <div class="employee-time-sheets__filters bordered elevated-0 padded">
        <Input
          class="employee-time-sheets__filters__calendar-input"
          :model="filters.stampedIn"
          :inputData="getInput('stampedIn')"
          :isEdit="true"
          @updateModel="(event) => setValue('stampedIn', event)"
        />
        <Input
          class="employee-time-sheets__filters__calendar-input"
          :model="filters.stampedOut"
          :inputData="getInput('stampedOut')"
          :isEdit="true"
          @updateModel="(event) => setValue('stampedOut', event)"
        />
      </div>
    </div>
    <div class="employee-time-sheets__table-wrapper">
      <Table
        :data="timeSheets"
        :columnHeaders="columnHeaders"
        :entity="entity"
        :cardinality="cardinality"
        :pageNumber="pageNumber"
        :currentPageNumber="currentPageNumber"
        @get-next-entities="getNextEntities"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Table from "@/components/Table.vue";
import Entities from "@/resources/Entities";
import Input from "@/components/Input/Input.vue";
import { getCardinality } from "@/services/entityService";

import { columnHeaders, timeSheetInputs } from "./inputs";

export default {
  name: "employeeTimeSheets",
  data() {
    return {
      entity: Entities.TIMESHEET,
      columnHeaders,
      timeSheetInputs,
      filters: {
        stampedIn: "",
        stampedOut: "",
      },
      cardinality: 0,
      numberOfEntities: 0,
      skip: 0,
      limit: 10,
    };
  },
  props: {
    employeeId: { type: String },
  },
  mounted: function () {
    if (this.employeeId == "NEW") {
      return;
    }
    this.getTimeSheets({
      filterCriteria: "employee",
      filterValue: this.employeeId,
      skip: this.skip,
      limit: this.limit,
    });
    getCardinality({
      entity: Entities.TIMESHEET,
      filterCriteria: "employee",
      filterValue: this.employeeId,
      skip: this.skip,
      limit: this.limit,
      positiveCallback: (data) => {
        this.cardinality = data;
        this.numberOfEntities = data;
      },
    });
  },
  watch: {
    employeeId: function () {
      this.getTimeSheets({
        filterCriteria: "employee",
        filterValue: this.employeeId,
        skip: this.skip,
        limit: this.limit,
      });
      getCardinality({
        entity: Entities.TIMESHEET,
        filterCriteria: "employee",
        filterValue: this.employeeId,
        skip: this.skip,
        limit: this.limit,
        positiveCallback: (data) => {
          this.cardinality = data;
          this.numberOfEntities = data;
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      timeSheets: "timeSheetModule/timeSheets",
    }),
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    currentPageNumber() {
      if (this.cardinality === 0) return 0;
      return Math.ceil((this.skip + 1) / this.limit);
    },
    pageNumber() {
      return Math.ceil(this.cardinality / this.limit);
    },
  },
  components: {
    Table,
    Input,
  },
  methods: {
    ...mapActions({
      getTimeSheets: "timeSheetModule/getTimeSheets",
    }),
    getInput(field) {
      return timeSheetInputs.find((i) => i.id == field);
    },
    setValue(field, value) {
      _.set(this.filters, field, value);
      if (field === "stampedOut" && this.filters.stampedIn) {
        this.getTimeSheets({
          filterCriteria: "employee",
          filterValue: this.employeeId,
          stampedIn: this.filters.stampedIn.toISOString(),
          stampedOut: this.filters.stampedOut.toISOString(),
        });
        getCardinality({
          entity: Entities.TIMESHEET,
          filterCriteria: "employee",
          filterValue: this.employeeId,
          skip: this.skip,
          limit: this.limit,
          stampedIn: this.filters.stampedIn.toISOString(),
          stampedOut: this.filters.stampedOut.toISOString(),
          positiveCallback: (data) => {
            this.cardinality = data;
            this.numberOfEntities = data;
          },
        });
      }
    },
    getNextEntities: function (type, isDisabled) {
      if (type === "next") {
        if (!isDisabled) this.skip = this.skip + this.limit;
      } else if (type === "prev") {
        if (!isDisabled) this.skip = this.skip - this.limit;
      } else if (type === "first") {
        if (!isDisabled) this.skip = 0;
      } else if (type === "last") {
        if (!isDisabled)
          this.skip =
            Math.ceil(this.cardinality / this.limit) * this.limit - this.limit;
      }
      this.searchTimeSheets();
    },
    searchTimeSheets() {
      const stampedIn = this.filters.stampedIn
        ? this.filters.stampedIn.toISOString()
        : undefined;
      const stampedOut = this.filters.stampedOut
        ? this.filters.stampedOut.toISOString()
        : undefined;
      this.getTimeSheets({
        filterCriteria: "employee",
        filterValue: this.employeeId,
        skip: this.skip,
        limit: this.limit,
        stampedIn: stampedIn,
        stampedOut: stampedOut,
      });
      getCardinality({
        entity: Entities.TIMESHEET,
        filterCriteria: "employee",
        filterValue: this.employeeId,
        stampedIn: stampedIn,
        stampedOut: stampedOut,
        positiveCallback: (data) => {
          this.cardinality = data;
          this.numberOfEntities = data;
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_employeeTimeSheets.scss";
</style>
