<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Cash Count By Workstation" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'cashCountByWorkstation',
          label: 'Cash Count by Workstation',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" :filterBySaleType="false" :filterByStoreOrOrg="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Date</th>
                <th align="left">Reconciliation Id</th>
                <th align="left">Station</th>
                <th align="left">Start</th>
                <th align="left">End</th>
                <th align="right">Cash Difference</th>
                <th align="right">User Count</th>
                <th align="right">DF Count</th>
                <th align="right">Payout</th>
                <th align="right">Debit</th>
                <th align="right">Credit</th>
                <th align="right">Gift Card</th>
                <th align="right">Total Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(data, index) of formattedData" :key="index" :class="{ total2: data.isTotal }">
                <td>{{ data.date }}</td>
                <td>{{ data.id }}</td>
                <td v-if="!data.isTotal">{{ data.workstationId }}</td>
                <td v-else>Totals</td>
                <td>{{ data.startDate }}</td>
                <td>{{ data.endDate }}</td>
                <td align="right">{{ formatAmount(data.cashTotalDifference) }}</td>
                <td align="right">{{ formatAmount(data.userCashCountTotal) }}</td>
                <td align="right">{{ formatAmount(data.cashTotalExpected) }}</td>
                <td align="right">{{ formatAmount(data.payoutTotal) }}</td>
                <td align="right">{{ formatAmount(data.debitcreditCardCollected) }}</td>
                <td align="right">{{ formatAmount(data.creditmemoCollected) }}</td>
                <td align="right">{{ formatAmount(data.giftcardCollected) }}</td>
                <td align="right">{{ formatAmount(data.totalCollected) }}</td>
              </tr>
            </tbody>
            <tr class="skip-export"></tr>
            <tr>
              <td colspan="5">Grand Totals</td>
              <td align="right">{{ formatAmount(reportData.total.cashTotalDifference) }}</td>
              <td align="right">{{ formatAmount(reportData.total.userCashCountTotal) }}</td>
              <td align="right">{{ formatAmount(reportData.total.cashTotalExpected) }}</td>
              <td align="right">{{ formatAmount(reportData.total.payoutTotal) }}</td>
              <td align="right">{{ formatAmount(reportData.total.debitcreditCardCollected) }}</td>
              <td align="right">{{ formatAmount(reportData.total.creditmemoCollected) }}</td>
              <td align="right">{{ formatAmount(reportData.total.giftcardCollected) }}</td>
              <td align="right">{{ formatAmount(reportData.total.totalCollected) }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Cash Count By Workstation");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
    formattedData() {
      const data = this.reportData;
      let rows = []

      for (let [day, dayValue] of Object.entries(data.list)) {
        for (let [workstationId, workstation] of Object.entries(dayValue.workstations)) {
          rows.push(workstation)
        }
        rows.push({ ...dayValue.total, isTotal: true })
      }

      return rows;
    }
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "cashCountByWorkstation";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    formatAmount(value) {
      if (!value) return '';
      return value.toFixed(2)
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "cashCountByWorkstation");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_cashCountByWorkstation.scss";
</style>