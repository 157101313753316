<template>
  <div class="tax-setup-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'returnItems', label: 'Returns' },
        {
          name: 'returnEditor',
          label: selectedReturnItem.item.externalFields.product.name,
          query: $route.query,
        },
      ]" :entity="entity" :isEdit="isEdit" :isCollapsed="isCollapsed" :selectedEntity="selectedReturnItem"
        @toggle-edit="toggleEdit" @updateIsEdit="isEdit = $event" @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent" @show-search-modal="toggleSearchModal" :canEdit="canEdit"
        :hideAddButton="true" :disableDelete="selectedReturnItem.isPostedToJournal">
        <BaseBtn label="Post to Journal" @click="postToJournal" v-if="canPost && !isEdit" />
        <BaseBtn label="Print" @click="performPrintReturnItem" v-if="selectedReturnItem.isPostedToJournal" />
      </PageHeader>
      <div class="section-base section-base--spaced">
        <div class="padded elevated-0 bordered header">
          <div>
            <Input :model="selectedReturnItem.isRefund" :inputData="getInput('isRefund')" :isEdit="isEdit"
              :entityObject="selectedReturnItem" @updateModel="selectedReturnItem.isRefund = $event" />
            <Input :model="selectedReturnItem.isSupplierIssuingRefund" :inputData="getInput('supplierRefund')"
              :isEdit="isEdit" @updateModel="selectedReturnItem.isSupplierIssuingRefund = $event" />
          </div>
          <div>
            <CheckBox sideLabel="Cash" :value="selectedReturnItem.returnType == 'cash'"
              @click="changeReturnType('cash')" />
            <CheckBox sideLabel="Credit Memo" :value="selectedReturnItem.returnType == 'credit-memo'"
              @click="changeReturnType('credit-memo')" />
          </div>
        </div>

        <div class="padded elevated-0 bordered position-relative" :class="{
          'bordered__highlighted--primary':
            selectedReturnItem.isPostedToJournal,
          'bordered__highlighted--danger':
            selectedReturnItem.isArchived || selectedReturnItem.isDeleted,
        }">
          <transition name="fade">
            <ContainerLabel v-if="
              selectedReturnItem.isPostedToJournal &&
              !selectedReturnItem.isVoided
            " type="primary" text="POSTED" />
            <ContainerLabel v-if="selectedReturnItem.isArchived" type="danger" text="ARCHIVED" />
            <ContainerLabel v-if="selectedReturnItem.isVoided" type="danger" text="DELETED" />
          </transition>
          <div class="header">
            <div>
              <div class="returnItem_section">
                <Input :model="selectedReturnItem._id" :inputData="getInput('_id')" />

                <Input v-if="selectedReturnItem.order" :model="selectedReturnItem.order._id"
                  :inputData="getInput('invoiceNo')"/>

                <Input :model="selectedReturnItem.item._id" :inputData="getInput('invoiceItemNo')" />
                <Input :model="selectedReturnItem.customer" :inputData="getInput('customerNo')" />
                <Input :model="clientCreditMemo" :inputData="getInput('clientCreditMemo')" />
                <Input :model="selectedReturnItem.supplier" :inputData="getInput('supplierNo')" />
                <Input :model="supplierCreditMemo" :inputData="getInput('supplierCreditMemo')" />
              </div>

              <div class="returnItem_section">
                <Input v-if="selectedReturnItem.product" :model="selectedReturnItem.product._id"
                  :inputData="getInput('product')" />
                <Input v-if="selectedReturnItem.product" :model="selectedReturnItem.product.name"
                  :inputData="getInput('productDescription')" />
              </div>
            </div>
            <ReturnItemSummary :returnItem="returnItem" :canEdit="canEdit" :isEdit="isEdit" @update-refund-quantity="
              (newQuantity) => (returnItem.quantity = +newQuantity)
            " @update-tax="(value) => (returnItem.tax = value)"
              @update-delivery="(value) => (returnItem.delivery = value)"
              @update-returnTotal="(value) => (returnItem.returnTotal = value)" />
          </div>
        </div>

        <EditorSection title="Notes" sectionType="notes" :bus="bus">
          <Input :model="selectedReturnItem.notes" :inputData="getInput('notes')"
            @updateModel="selectedReturnItem.notes = $event" :isEdit="isEdit" />
        </EditorSection>
        <EditorSection :title="`About ${selectedReturnItem.item.externalFields.product.name || ''}`" sectionType="about"
          v-if="selectedReturnItem._id !== 'NEW'" :bus="bus">
          <About :entity="selectedReturnItem" />
        </EditorSection>
      </div>
      <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal" @toggle-modal="toggleSearchModal"
        :entity="entity" :forcedFilterCriteria="filterCriteria" :columnHeaders="columnHeaders" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Entities from "@/resources/Entities";
import EditorSection from "@/components/EditorSection";
import Input from "@/components/Input/Input.vue";
import ReturnItemSummary from "./ReturnItemSummary/ReturnItemSummary.vue";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";


import {
  pageHeader,
  returnItemInputs,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import { mapGetters, mapActions } from "vuex";
import OrderEditor from "../Order/OrderEditor.vue";

export default {
  name: "returns",
  data() {
    return {
      columnHeaders,
      filterCriteria,
      Entities,
      entity: Entities.RETURNITEM,
      pageHeader,
      bus: new Vue(),
      isEdit: false,
      isCollapsed: true,
      Constants,
      showSearchModal: false,
    };
  },
  components: {
    PageHeader,
    About,
    EditorSection,
    Input,
    ReturnItemSummary,
    CheckBox,
    BaseBtn,
    ContainerLabel,
  },
  computed: {
    ...mapGetters({
      returnItem: "returnItemModule/returnItem",
    }),
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    clientCreditMemo() {
      return this.selectedReturnItem?.clientCreditMemo?._id;
    },
    supplierCreditMemo() {
      return this.selectedReturnItem?.supplierCreditMemo?._id;
    },
    selectedReturnItem: {
      get() {
        this.setPageTitle("Return Item - " + this.returnItem.product?.name);
        return this.prefillEntityBeforeRendering(this.returnItem);
      },
      set(entity) {
        return entity;
      },
    },
    isCannabis() {
      return (
        this.selectedReturnItem.product?.externalFields?.group?.isCannabis ||
        false
      );
    },
    canPost() {
      return (
        this.canEdit &&
        this.selectedReturnItem.quantity > 0 &&
        !this.selectedReturnItem.isPostedToJournal
      );
    },
    canEdit() {
      return (
        !this.selectedReturnItem.isPostedToJournal &&
        !this.selectedReturnItem.isDeleted &&
        !this.selectedReturnItem.isArchived &&
        !this.selectedReturnItem.order?.isVoided
      );
    },
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getReturnItemById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  methods: {
    ...mapActions({
      getReturnItemById: "returnItemModule/getReturnItemById",
      printReturnItem: "returnItemModule/printReturnItem",
      getAdjacent: "returnItemModule/getAdjacent",
      performPostToJournal: "returnItemModule/postToJournal",
      setMessagePopUp: "setMessagePopUp",
    }),
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    postToJournal() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: "Post the return/refund Now?",
        positiveCallback: () => {
          this.performPostToJournal(this.selectedReturnItem);
        },
      });
    },
    prefillEntityBeforeRendering(entity) {
      return entity;
    },
    getInput(field) {
      return returnItemInputs.find((i) => i.id == field);
    },
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedReturnItem.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedReturnItem = adjacent;
        },
      });
    },
    toggleEdit() {
      if (!(this.selectedReturnItem._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedReturnItem._id === "NEW") {
        this.isEdit = true;
      }
    },
    changeReturnType(type) {
      if (!this.isEdit) return;

      this.selectedReturnItem.returnType = type;
    },
    performPrintReturnItem() {
      this.printReturnItem({ returnItem: this.selectedReturnItem });
    },
  },
  mounted: function () {
    this.setPageTitle("Return Item");
    if (this.$route.query.id) {
      this.getReturnItemById({
        _id: this.$route.query.id,
        positiveCallback: (entity) => {
          this.setPageTitle(`Return Item  ${entity.product?.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_returnItemEditor.scss";
</style>
