import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

const state = {
  todaysMenu: undefined,
  loadingStatus: {
    getTodaysMenu: false,
  },
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setTodaysMenu: (state, payload) => {
    state.todaysMenu = payload;
  },
};

const getters = {
  todaysMenu: (state) => state.todaysMenu,
  loadingStatus: (state) => state.loadingStatus,
};

const actions = {
  getTodaysMenu: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getTodaysMenu", status: true });

    const url = `${Apis.backendBaseUrl}${Apis.getTodaysMenu}?selectedReportFor=${payload.selectedReportFor}&selectedReportSaleType=${payload.selectedReportSaleType}`;

    // if (state.selectedFilter) {
    //     if (state.selectedFilter.limit) {
    //         url += `&limit=${state.selectedFilter.limit}`
    //     }
    //     if (state.selectedFilter.skip) {
    //         url += `&skip=${state.selectedFilter.skip}`
    //     }
    //     if (state.selectedFilter.criteria) {
    //         url += `&filterCriteria=${state.selectedFilter.criteria.code}`
    //     }
    //     if (state.selectedFilter.value) {
    //         url += `&filterValue=${state.selectedFilter.value}`
    //     }
    //     if(state.selectedFilter.itemFilter) {
    //         url += `&itemFilter=${state.selectedFilter.itemFilter}`
    //     }
    // }

    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getTodaysMenu", status: false });
      var todaysMenu = [];
      if (response) {
        todaysMenu = response.data.body;

        commit("setTodaysMenu", todaysMenu);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
