var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.entitySubMenu.length === 0 ? 'entity-sub-menu-margin' : ''},[(_vm.entitySubMenu.length > 0)?_c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"main-menu",on:{"mouseleave":function($event){_vm.closeMenu('sub-menu');
    _vm.removeActiveLinks('button');}}},[_vm._l((_vm.entitySubMenu),function(menuItem){return [_c('div',{key:'main-menu__' + menuItem.title,staticClass:"main-menu__menu-item"},[_c('button',{key:'main-menu__' + menuItem.title + '__button',staticClass:"button plain-primary",class:_vm.isDisabled ? 'main-menu__disabled' : '',attrs:{"id":'main-menu__' + menuItem.title},on:{"click":function($event){_vm.removeActiveLinks('button');
            _vm.closeMenu('first-sub-menu');
            _vm.closeMenu('second-sub-menu');
            _vm.openMenu(
              'main-menu__' + menuItem.title,
              'first-sub-menu__' + menuItem.title
            );}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(menuItem.title))]),(menuItem.children)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'angle-down']}}):_vm._e()],1),_c('div',{key:'first-sub-menu__' + menuItem.title,staticClass:"first-sub-menu sub-menu",attrs:{"id":'first-sub-menu__' + menuItem.title}},[_c('div',{staticClass:"menu-overlay"},[_vm._l((menuItem.children),function(child){return [_c('button',{key:'first-sub-menu__' + child.title,staticClass:"button first-sub-menu_button plain-primary",attrs:{"id":'first-sub-menu__' + child.title},on:{"click":function($event){_vm.handleMenuClicked(child, 'sub-menu');
                  _vm.closeMenu('second-sub-menu');
                  _vm.openMenu(
                    'first-sub-menu__' + child.title,
                    'second-sub-menu__' + child.title
                  );}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(child.title))]),(child.children)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'angle-right']}}):_vm._e()],1),_c('div',{key:'second-sub-menu__' + child.title,staticClass:"second-sub-menu sub-menu",attrs:{"id":'second-sub-menu__' + child.title},on:{"mouseleave":function($event){_vm.closeMenu('second-sub-menu');
                  _vm.removeActiveLinks('first-sub-menu_button');}}},[_vm._l((child.children),function(nestedChild){return [_c('button',{key:'second-sub-menu__' + nestedChild.title,staticClass:"plain-primary",attrs:{"id":'button second-sub-menu__' + nestedChild.title},on:{"click":function($event){return _vm.handleMenuClicked(nestedChild, 'sub-menu')}}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(nestedChild.title))])])]})],2)]})],2)])])]})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }