export const Role = {
  GLOBAL_ADMIN: "global-admin",
  GLOBAL_INVENTORY_MANAGER: "global-inventory-manager",
  SUPER_USER: "super-user",
  ADMIN: "admin",
  CONSULTANT: "consultant",
  SALES: "sales",
  SALES_NO_PRINTING: "sales-no-printing",
  INVENTORY_PURCHASING: "inventory-purchasing",
  SALES_INVENTORY_PURCHASING: "sales-inventory-purchasing",
  STATE_AUDITOR: "state-auditor",
};

export const checkInOutRoles = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
  Role.SUPER_USER,
  Role.ADMIN,
  Role.CONSULTANT,
  Role.SALES,
  Role.SALES_NO_PRINTING,
  Role.INVENTORY_PURCHASING,
  Role.SALES_INVENTORY_PURCHASING,
];

export const salesRoles = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
  Role.SUPER_USER,
  Role.ADMIN,
  Role.CONSULTANT,
  Role.SALES,
  Role.SALES_NO_PRINTING,
  Role.SALES_INVENTORY_PURCHASING,
];

export const inventoryRoles = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
  Role.SUPER_USER,
  Role.ADMIN,
  Role.INVENTORY_PURCHASING,
  Role.SALES_INVENTORY_PURCHASING,
  Role.STATE_AUDITOR,
];

export const inventoryRolesWithoutState = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
  Role.SUPER_USER,
  Role.ADMIN,
  Role.INVENTORY_PURCHASING,
  Role.SALES_INVENTORY_PURCHASING,
];

export const managementRoles = [Role.GLOBAL_ADMIN, Role.SUPER_USER, Role.ADMIN];

export const financeRoles = [Role.GLOBAL_ADMIN, Role.SUPER_USER];

export const reportRoles = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
  Role.SUPER_USER,
  Role.ADMIN,
  Role.SALES,
  Role.INVENTORY_PURCHASING,
  Role.SALES_INVENTORY_PURCHASING,
];

// Employees with these roles can access any store even non associated to the empl
export const multiStoreRoles = [
  Role.GLOBAL_ADMIN,
  Role.GLOBAL_INVENTORY_MANAGER,
];

export const managementRolesWithMso = [Role.GLOBAL_ADMIN, Role.SUPER_USER];

export const globalAdminRoles = [Role.GLOBAL_ADMIN];
