<template>
  <div class="specialPricing responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'specialPricings', label: 'SpecialPricing' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";

import { listPageHeader } from "./inputs";

export default {
  name: "specialPricing",
  data() {
    return {
      Entities,
      entity: Entities.SPECIALPRICING,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  created: function () {
    this.setPageTitle("Special Pricing");
  },
};
</script>
