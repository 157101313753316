var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment"},[_c('div',{staticClass:"payment__buttons"},_vm._l((_vm.availablePayments),function(payment){return _c('BaseBtn',{key:payment.code,attrs:{"label":payment.label,"colorType":payment.isSelected ? 5 : undefined,"disabled":payment.disabled()},on:{"click":function($event){return _vm.createPayment(payment)}}})}),1),(_vm.PaymentAmount > 0)?_c('div',{staticClass:"payment__creditmemo"},[_c('p',{staticClass:"payment__creditmemo__value"},[_vm._v(" The customer has $"+_vm._s(_vm.formatNumber(_vm.PaymentAmount))+" in Payment CreditMemos ")])]):_vm._e(),_c('div',{staticClass:"payment__body"},[(_vm.selectedPayment)?_c('div',{staticClass:"payment__container"},[_c('PaymentForm',{attrs:{"payment":_vm.selectedPayment,"payments":_vm.order.payments,"amountDue":_vm.order.amountDue,"customer":_vm.order.customer,"isLoading":_vm.orderLoadingStatus.savePayment ||
          _vm.orderLoadingStatus.executeDebitPayment,"orderLoadingStatus":_vm.orderLoadingStatus},on:{"cancel":_vm.unselectPayment,"save":_vm.performSavePayment,"gift-card-barcode":_vm.searchGiftCard,"canpay-reimbursement":_vm.performReimburseCanpayPayment,"create-credit-memo":_vm.performCreateCreditMemo,"canpay-execute-payment":_vm.performExecuteCanpayPayment,"executeDebitPayment":_vm.executeDebitPayment,"executeOverrideDebitPayment":_vm.executeOverrideDebitPayment}})],1):_vm._e()]),(!_vm.selectedPayment)?_c('div',{staticClass:"payment__list-container"},[_c('div',{staticClass:"payment__list-wrapper"},[_c('PaymentList',{staticClass:"payment__list",attrs:{"payments":_vm.order.payments,"disabled":_vm.order.isPaid},on:{"archive-payment":_vm.performArchivePayment,"edit-payment":_vm.performEditPayment}}),(_vm.order.loyaltyInfo)?_c('LoyaltySummary',{attrs:{"loyaltyTransaction":_vm.order.loyaltyTransaction,"loyaltyInfo":_vm.order.loyaltyInfo,"phone":_vm.customerPhone,"email":_vm.customerEmail,"showRedeemRewards":_vm.showRedeemRewards},on:{"redeem-rewards":_vm.redeemRewardsClicked}}):_vm._e()],1),_c('div',{staticClass:"payment__summary",class:{
        'bordered__highlighted--primary':
          _vm.order.isPaid || _vm.order.isVoided || _vm.order.isPreorder,
        'bordered__highlighted--danger': _vm.order.isArchived || _vm.order.isPending,
      }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.order.isPending && !_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"PENDING"}}):_vm._e(),(_vm.order.isPaid && !_vm.order.isArchived && !_vm.order.preOrder)?_c('ContainerLabel',{attrs:{"type":"primary","text":"PAID"}}):_vm._e(),(_vm.order.isVoided && !_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"primary","text":"REVERSED"}}):_vm._e(),(_vm.order.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"ARCHIVED"}}):_vm._e(),(_vm.order.isPreorder)?_c('ContainerLabel',{attrs:{"type":"primary","text":"PREORDER"}}):_vm._e()],1),_c('PriceSummary',{attrs:{"rows":_vm.paymentSummary}}),(_vm.changeDue)?_c('div',{staticClass:"payment__change-due elevated-0"},[_c('div',[_vm._v("Change due")]),_c('div',[_vm._v(_vm._s(_vm.changeDue))])]):_vm._e()],1)]):_vm._e(),(!_vm.selectedPayment)?_c('div',{staticClass:"payment__footer"},[_c('div',{staticClass:"payment__footer-buttons-l"},[_c('BaseBtn',{attrs:{"label":"Archive All Payments","isLoading":_vm.orderLoadingStatus.archiveAllPayments,"colorType":3,"disabled":_vm.orderLoadingStatus.saveOrder ||
          _vm.containsCanpayPayment ||
          _vm.order.isPaid},on:{"click":_vm.archiveAll}}),(_vm.order.isPaid)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"print","label":"Generate Receipt","colorType":3},on:{"click":_vm.generateReceipt}}):_vm._e()],1),_c('div',{staticClass:"payment__footer-buttons-r"},[_c('BaseBtn',{attrs:{"label":"Post","isLoading":_vm.orderLoadingStatus.saveOrder,"disabled":!_vm.isOrderFullyPaid() || _vm.order.isPaid},on:{"click":_vm.postPayment}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }