var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Sales Source Averages","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesAverageSource',
          label: 'Sales Source Averages',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.salesTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.source))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.average))+" ")])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_vm._v("Sales Source Averages")])]),_c('tr',[_c('td')]),_c('tr',[_c('td')]),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Source")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Average Order Total")])])])}]

export { render, staticRenderFns }