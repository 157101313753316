var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"purchaseOrder-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"selectedEntity":_vm.selectedPurchaseOrder,"links":[
        { name: 'home', label: 'Home' },
        { name: 'purchaseOrders', label: 'Purchase Orders' },
        {
          name: 'purchaseOrderEditor',
          label: _vm.selectedPurchaseOrder._id,
          query: _vm.$route.query,
        } ],"isLoading":_vm.isLoading,"disable":_vm.isLoading,"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"entity":_vm.entity},on:{"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); },"updateIsEdit":function($event){_vm.isEdit = $event},"menu-option-click":function (entity, action) { return _vm.handleMenuOptionClick(entity, action); }}}),_c('div',{staticClass:"section-base section-base--spaced"},[_c('PurchaseOrderHeaderForm',{attrs:{"suppliers":_vm.suppliers,"supplierAgents":_vm.supplierAgents,"currentStore":_vm.currentStore,"disabled":!_vm.isEdit ||
          _vm.selectedPurchaseOrder.isArchived ||
          _vm.selectedPurchaseOrder.isPostedToJournal,"selectedPurchaseOrder":_vm.selectedPurchaseOrder,"totals":_vm.totals,"stateLoadingStatus":_vm.stateLoadingStatus},on:{"performGetManifestById":_vm.performGetManifestById,"batch-type-changed":_vm.propagateBatchIdChangeConfirmation,"total-modifier-toggled":function($event){_vm.showTotalModifier = !_vm.showTotalModifier}}}),(_vm.showTotalModifier)?_c('PurchaseOrderTotalModifiersForm',{attrs:{"disabled":!_vm.isEdit ||
          _vm.selectedPurchaseOrder.isArchived ||
          _vm.selectedPurchaseOrder.isPostedToJournal,"totalModifiers":_vm.defaultMainSectionValues,"purchaseOrder":_vm.selectedPurchaseOrder},on:{"apply-total-modifiers":_vm.applyTotalModifiers}}):_vm._e(),_c('PurchaseOrderMainButtons',{attrs:{"disabled":!_vm.isEdit || _vm.selectedPurchaseOrder.isArchived || _vm.isLoading,"selectedPurchaseOrder":_vm.selectedPurchaseOrder},on:{"mark-all-unreceived":function () { return _vm.toggleAllUnreceived(false); },"mark-all-received":function () { return _vm.toggleAllReceived(true); },"post-to-journal":_vm.performPostToJournal,"reverse-posting":_vm.performReversePurchaseOrder,"move-all-to-wi":_vm.moveAllToWI}})],1),_c('PurchaseOrderItems',{attrs:{"disabled":!_vm.isEdit || _vm.selectedPurchaseOrder.isArchived,"items":_vm.selectedPurchaseOrder.items,"showChemicalCompositionButton":_vm.showUpdateChemicalCompositionButton,"isUpdateChemicalCompositionLoading":_vm.stateLoadingStatus.getChemicalComposition,"selectedFilter":_vm.itemsSelectedFilter,"filterCriteria":_vm.itemsFilterCriteria},on:{"addItem":_vm.addItemToPurchaseOrder,"selectItem":_vm.selectItem,"archiveItem":_vm.peformArchiveItem,"removeItem":_vm.peformRemoveItem,"update-checmical-composition":_vm.updateChemicalComposition,"items-filter-value-change":_vm.filterItems,"items-filter-criteria-change":_vm.clearItemFilter}}),_c('div',{attrs:{"id":"item"}}),(_vm.selectedItem)?_c('div',{staticClass:"section-base"},[_c('PurchaseOrderItemDetail',{attrs:{"disabled":!_vm.isEdit ||
          _vm.selectedPurchaseOrder.isArchived ||
          _vm.selectedItem.isArchived ||
          _vm.selectedPurchaseOrder.isPostedToJournal,"item":_vm.selectedItem,"selectedItemSequentialNumber":_vm.selectedItemSequentialNumber,"numberOfItems":_vm.selectedPurchaseOrder.items.length,"taxRate":_vm.getTaxRate(_vm.selectedPurchaseOrder, _vm.selectedItem),"purchaseOrder":_vm.selectedPurchaseOrder},on:{"set-selected-item-taxes":_vm.setSelectedItemTax,"select-item-by-sequential-number":_vm.selectItemBySequentialNumber,"assign-product":_vm.assignProduct,"unit-cost-change":_vm.setSelectedItemTax,"quantity-purchased-change":_vm.setSelectedItemTax,"receive":_vm.performReceive,"unreceive":_vm.performUnreceive,"close":_vm.unselectItem}})],1):_vm._e(),(
        _vm.lodash.get(_vm.selectedItem, 'product.externalFields.group.isCannabis')
      )?_c('div',{staticClass:"section-base"},[_c('ChemicalCompositionForm',{attrs:{"disabled":!_vm.isEdit ||
          _vm.selectedPurchaseOrder.isArchived ||
          _vm.selectedItem.isReceived,"title":"Item - Chemical Composition","chemicalComposition":_vm.selectedItem.chemicalComposition}})],1):_vm._e(),(_vm.selectedPurchaseOrder._id !== 'NEW')?_c('EditorSection',{attrs:{"title":"About","sectionType":"about","bus":_vm.bus}},[(_vm.selectedPurchaseOrder._id !== 'NEW')?_c('About',{attrs:{"entity":_vm.selectedPurchaseOrder}}):_vm._e()],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e(),(_vm.showJournalEntries)?_c('JournalEntries',{attrs:{"showSearchModal":_vm.showJournalEntries,"type":"purchaseOrder","filter":_vm.filterJournalEntries,"hideFilter":true},on:{"toggle-modal":function () { return (_vm.showJournalEntries = !_vm.showJournalEntries); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }