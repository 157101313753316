import _ from "lodash";

export const normalizeEntityBeforeSaving = function (item) {
  const entity = JSON.parse(JSON.stringify(item));
  entity._entity = 'item'
  if (_.isEmpty(entity.supplier)) entity.supplier = undefined;
  if (_.isEmpty(entity.selectedReturn)) entity.selectedReturn = undefined;
  if (_.isEmpty(entity.selectedAdjustment))
    entity.selectedAdjustment = undefined;
  if (_.isEmpty(entity.selectedDisposal)) entity.selectedDisposal = undefined;

  if (!_.isEmpty(entity.selectedAdjustment)) {
    entity.selectedAdjustment.type = entity.selectedAdjustment.type
      ? entity.selectedAdjustment.type.code
      : "";
    entity.selectedAdjustment.reason = entity.selectedAdjustment.reason
      ? entity.selectedAdjustment.reason.code
      : "";
    entity.selectedAdjustment.mode = entity.selectedAdjustment.mode
      ? entity.selectedAdjustment.mode.code
      : "";
  } else entity.selectedAdjustment = undefined;

  if (!_.isEmpty(entity.selectedDisposal)) {
    entity.selectedDisposal.type = entity.selectedDisposal.type
      ? entity.selectedDisposal.type.code
      : "";
    entity.selectedDisposal.method = entity.selectedDisposal.method
      ? entity.selectedDisposal.method.code
      : "";
    entity.selectedDisposal.reason = entity.selectedDisposal.reason
      ? entity.selectedDisposal.reason.code
      : "";
      if (_.isEmpty(entity.selectedDisposal.supplier))
      entity.selectedDisposal.supplier = undefined;

    if (typeof entity.selectedDisposal.disposalDate == 'string' && !entity.selectedDisposal.disposalDate.includes("Z")) {
      // the time is not in UTC, converting it:
      const utcDate = new Date(entity.selectedDisposal.disposalDate).toISOString()
      entity.selectedDisposal.disposalDate = utcDate
    }
    
  }

  if (entity.batchType) {
    if (entity.batchType.code) {
      entity.batchType = entity.batchType.code;
    }
  }

  if (entity.returns && entity.returns.length > 0) {
    for(let i = 0; i < entity.returns.length; i++) {
      const itemReturn = entity.returns[i];

      itemReturn.room = itemReturn.room.code;
    }
  }

  if (entity.selectedReturn) {
    if (entity.selectedReturn.room && entity.selectedReturn.room.code) {
      entity.selectedReturn.room = entity.selectedReturn.room.code
    }
  }
  return entity;
};
