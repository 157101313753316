var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Sales Summary Breakdown","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'salesReport', label: 'Sales' },
      {
        name: 'salesSummaryBreakdown',
        label: 'Sales Summary Breakdown',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.list)),function(ref){
    var day = ref[0];
    var value = ref[1];
return _c('tr',{key:day},[_c('td',[_vm._v(_vm._s(value.dateString))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(value.total)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.discount))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(value.tax)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.freight))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(value.discountsCount))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(value.ordersCount))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(value.itemsCount))])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"1"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.discount))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.tax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.freight))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.discountsCount)+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.ordersCount)+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.itemsCount)+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Day")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Total Sales")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Discount")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Tax")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Delivery")]),_c('th',{attrs:{"align":"right"}},[_vm._v("# of Discounts")]),_c('th',{attrs:{"align":"right"}},[_vm._v("# of Orders")]),_c('th',{attrs:{"align":"right"}},[_vm._v("# of Items")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }