<template>
  <div>
    <div class="inventory-editor responsive-menu" id="page-body">
      <div class="responsive-menu__child">
        <PageHeader :pageHeader="pageHeader" :links="[
          { name: 'home', label: 'Home' },
          { name: 'products', label: 'Products' },
          {
            name: 'productEditor',
            label: selectedProduct.name,
            query: $route.query,
          },
        ]" :isEdit="isEdit" :disable="showMaintenance" :isCollapsed="isCollapsed" :selectedEntity="selectedProduct"
          @updateIsEdit="isEdit = $event" :entity="entity" @toggle-edit="toggleEdit" @toggle-sections="forceToggle"
          @show-search-modal="() => (showSearchModal = !showSearchModal)" @menu-option-click="menuOptionClick" />
        <template>
          <div key="editor" class="product-editor-inputs" v-if="!showMaintenance">
            <div class="section-base">
              <div class="padded elevated-0 bordered">
                <div class="two-columns-layout two-columns-layout--responsive" :key="getMsoId">
                  <div v-for="inputs in getInputs" :key="inputs.id">
                    <div class="
                        grid-two-column
                        two-columns-layout--responsive-horizontal-space-10
                      ">
                      <div v-for="input in inputs.inputs" :key="input.id" :class="'input-text' + ' ' + input.class">
                        <Input :key="enforceMso" :model="getProperty(input.id)"
                          @updateModel="selectedProduct[input.id] = $event" :inputData="getInput(input)"
                          :isEdit="isEdit" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bulk prices Begin  -->
            <EditorSection title="Bulk Prices" sectionType="bulkPrices" :bus="bus">
              <div v-for="(priceForGram, i) in selectedProduct.priceByWeight" :key="priceForGram._id" class="
                  space-between
                  space-between--align-center-responsive-left
                  space-between--border-bottom
                  responsive-helper-1
                ">
                <div v-for="input in bulkPricesInputs" :key="input.id" :class="'input-text' + ' ' + input.class">
                  <Input :model="priceForGram[input.id]" @updateModel="priceForGram[input.id] = $event" :inputData="input"
                    :isEdit="isEdit" />
                </div>
                <inline-button label="Delete" type="plain-danger responsive-column form-height wide"
                  @click="() => deleteBulkPrice(i)" :disabled="!isEdit" />
              </div>
              <div class="right">
                <inline-button label="Add" type="plain-primary responsive-column form-height wide" @click="addBulkPrice"
                  :disabled="!isEdit" />
              </div>
            </EditorSection>
            <!-- Bulk prices end  -->

            <!-- Sale Pricing Begin  -->
            <EditorSection title="Sale Pricing" sectionType="salePricing" :bus="bus">
              <div v-for="(salePrice, i) in selectedProduct.salePrices" :key="salePrice._id" class="salePricing">
                <div>
                  <BaseBtn @click="() => deleteSalePrice(i)" label="Delete" :colorType="1" :disabled="!isEdit"
                    class="salePricing__delete" />
                </div>
                <div class="salePricing__content">
                  <div v-for="input in salePricingInputs" :key="input.id" class="salePricing__input input-text"
                    :class="input.class">
                    <Input :model="salePrice[input.id]" @updateModel="salePrice[input.id] = $event" :inputData="input"
                      :isEdit="isEdit" />
                  </div>
                </div>
              </div>
              <div class="right">
                <inline-button label="Add" type="plain-primary responsive-column form-height wide" @click="addSalePrice"
                  :disabled="!isEdit" />
              </div>
            </EditorSection>
            <!-- Sale Pricing end  -->

            <!-- Online store Begin  -->
            <EditorSection title="Online Store" sectionType="onlineStore" :bus="bus" :key="getMsoId">
              <div class="two-columns-layout two-columns-layout--responsive">
                <div>
                  <Input class="input-text" :model="selectedProduct.onlineStoreName"
                    @updateModel="selectedProduct.onlineStoreName = $event" :inputData="getInput(getOnlineStoreInput('onlineStoreName'))
                      " :class="getOnlineStoreInput('onlineStoreName').class" :isEdit="isEdit" />
                  <Input class="input-text" :model="selectedProduct.slug" @updateModel="selectedProduct.slug = $event"
                    :inputData="getInput(getOnlineStoreInput('slug'))" :class="getOnlineStoreInput('slug').class"
                    :isEdit="isEdit" />
                  <Input class="input-text" :model="selectedProduct.onlineStoreDescription" @updateModel="
                    selectedProduct.onlineStoreDescription = $event
                    " :inputData="getInput(getOnlineStoreInput('onlineStoreDescription'))
    " :class="getOnlineStoreInput('onlineStoreDescription').class" :isEdit="isEdit" />
                  <Input class="input-text" :model="getImageSecureUrl" @updateModel="updateImageSecureUrl($event)"
                  :inputData="getInput(getOnlineStoreInput('imageNormal.secure_url'))
    " :class="getOnlineStoreInput('imageNormal.secure_url').class" :isEdit="isEdit" />

                  <div class="option" :class="{
                    'option--disabled':
                      !isEdit ||
                      !!getInput(getOnlineStoreInput('flavors')).disabled,
                  }">
                    <p class="input-text__label">
                      {{ getOnlineStoreInput("flavors").title }}
                    </p>
                    <multiselect :custom-label="customLabelOption" v-model="selectedProduct.flavors" :options="flavors"
                      :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Search flavor"
                      track-by="uuid" label="value" :searchable="false" />
                  </div>
                  <div class="option" :class="{
                    'option--disabled':
                      !isEdit ||
                      !!getInput(getOnlineStoreInput('effects')).disabled,
                  }">
                    <p class="input-text__label">
                      {{ getOnlineStoreInput("effects").title }}
                    </p>
                    <multiselect :custom-label="customLabelOption" v-model="selectedProduct.effects" :options="effects"
                      :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Search effect"
                      track-by="uuid" label="value" :searchable="false" />
                  </div>
                  <div class="option" :class="{
                    'option--disabled':
                      !isEdit ||
                      !!getInput(getOnlineStoreInput('terpenes')).disabled,
                  }">
                    <p class="input-text__label">
                      {{ getOnlineStoreInput("terpenes").title }}
                    </p>
                    <multiselect :custom-label="customLabelOption" v-model="selectedProduct.terpenes" :options="terpenes"
                      :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Search Terpenes"
                      track-by="uuid" label="value" :searchable="false" />
                  </div>
                  <div class="option" :class="{
                    'option--disabled':
                      !isEdit ||
                      !!getInput(getOnlineStoreInput('tags')).disabled,
                  }">
                    <p class="input-text__label">
                      {{ getOnlineStoreInput("tags").title }}
                    </p>
                    <multiselect :custom-label="customLabelOption" v-model="selectedProduct.tags" :options="tags"
                      :multiple="true" :close-on-select="true" :clear-on-select="true" placeholder="Search Tags"
                      track-by="uuid" label="value" :searchable="false" />
                  </div>
                </div>
                <div style="margin: 1rem 0" class="extra-info grid-row-two-column"
                  :class="{ 'extra-info--edit': isEdit }">
                  <div class="margin-bottom-md space-between">
                    <p>Extra Info</p>
                    <inline-button label="Add" type="plain-primary responsive-column form-height wide"
                      @click="addExtraInfo" :disabled="!isEdit || enforceMso" />
                  </div>
                  <div v-for="(extraInfo, i) in selectedProduct.extraInfo" :key="i"
                    class="two-columns-layout--border-bottom">
                    <div class="extra-info-record">
                      <div class="flex-right">
                        <inline-button label="Delete" class="desktop-only-inline-block"
                          type="plain-danger responsive-column form-height wide" @click="() => deleteExtraInfo(i)"
                          :disabled="!isEdit || enforceMso" />
                      </div>
                      <div v-for="input in extraInfoInputs" :key="input.id" :class="'input-text ' + input.class">
                        <Input :model="lodash.get(extraInfo, input.id)"
                          @updateModel="lodash.set(extraInfo, input.id, $event)" :inputData="getInput(
                            getOnlineStoreExtraInfoInput(input.id),
                            true
                          )
                            " :isEdit="isEdit" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="two-columns-layout two-columns-layout--responsive">
                    <div>
                      <div class="input-text">
                        <div class="reversed-flex-column">
                          <!-- <FileUploader
                            label="Choose File"
                            allowedTypes="image/png, image/jpeg"
                            @change="updateImage"
                            :disabled="!isEdit"
                          /> -->
                          <FileUploader label="Upload Receipt Logo" allowedTypes="image/png,image/jpeg,image/jpg"
                            @change="(e) => updateImage(e)" :disabled="!isEdit || isMsoProductSet" iconGroup="fas"
                            iconName="upload" @remove-image="() => removeImage()" :image="selectedProduct.imageNormal &&
                              (selectedProduct.imageNormal.data ||
                                selectedProduct.imageNormal.secure_url)
                              " id="favicon" />
                          <div class="input-text__label">Product Image</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div v-if="this.selectedProduct.imageNormal &&
                        (this.selectedProduct.imageNormal.data ||
                          this.selectedProduct.imageNormal.secure_url)
                        " class="input-text">
                        <div class="reversed-flex-column">
                          <div>
                            <inline-button label="Remove Image" type="plain-danger responsive-column form-height wide"
                              @click="() => removeImage()" :disabled="!isEdit || isMsoProductSet" />
                          </div>

                          <div class="input-text__label">Product Image</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </EditorSection>
            <!-- Online Store end  -->

            <!-- Available Inventory Begin  -->
            <EditorSection title="Available Inventory" sectionType="availableInventory" :isTable="true" :bus="bus">
              <table cellspacing="0" class="table-element">
                <thead>
                  <tr class="header">
                    <th class="cell">Purchase Order</th>
                    <th class="cell">Item</th>
                    <th class="cell">
                      Quantity
                      <br />Available
                    </th>
                    <th class="cell">
                      Quantity
                      <br />Working Inventory
                    </th>
                    <th class="cell">Unit Cost</th>
                    <th class="cell">Batch</th>
                    <th class="cell">Barcode</th>
                    <th class="cell">Expiration Date</th>
                    <th class="cell">Created At</th>
                    <th class="cell">Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item._id" class="row">
                    <td class="cell">
                      <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        " v-if="item.purchaseOrder">
                        <router-link class="internal-link" target="_blank" :to="{
                          name: 'purchaseOrderEditor',
                          query: { id: item.purchaseOrder },
                        }">
                          {{ item.purchaseOrder }}
                        </router-link>
                      </div>
                      <div v-else>-</div>
                    </td>
                    <td class="cell">
                      <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                        <router-link class="internal-link" target="_blank"
                          :to="{ name: 'itemEditor', query: { id: item._id } }">
                          {{ item._id }}
                        </router-link>
                      </div>
                    </td>

                    <td class="cell">
                      <span>{{ item.quantityAvailable }}</span>
                    </td>
                    <td class="cell">
                      <span>{{ item.quantityWorkingInventory }}</span>
                    </td>
                    <td class="cell">
                      {{ item ? Utilities.formatPrice(item.unitCost) : "-" }}
                    </td>
                    <td class="cell">{{ item.batchId }}</td>
                    <td class="cell">{{ item.barcode }}</td>
                    <td class="cell">
                      {{
                        moment(item.expirationDate).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">
                      {{
                        moment(item.createdAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">
                      {{ item.createdBy }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </EditorSection>

            <!-- New Audit Begin  -->
            <EditorSection title="Audit Transactions" sectionType="availableAudit" :isTable="true" :bus="bus">
              <table cellspacing="0" class="table-element">
                <thead>
                  <tr class="header">
                    <th class="cell">Post Date</th>
                    <th class="cell">Batch #</th>
                    <th class="cell">Quantity</th>
                    <th class="cell">Sale Price</th>
                    <th class="cell">Unit Cost</th>
                    <th class="cell">Transaction Type</th>
                    <th class="cell">Manifest #</th>
                    <th class="cell">Supplier Srn</th>
                    <th class="cell">Delivery Agent Srn</th>
                    <th class="cell">Purchase Order Id</th>
                    <th class="cell">Purchase Order Item Id</th>
                    <th class="cell">Purchase Return Item Id</th>
                    <th class="cell">Adjustment</th>
                    <th class="cell">Disposal</th>
                    <th class="cell">Sub Batch #</th>
                    <th class="cell">Customer Id</th>
                    <th class="cell">Invoice Code</th>
                    <th class="cell">Invoice Item Id</th>
                    <th class="cell">Invoice Return Id</th>
                    <th class="cell">Sample</th>
                    <th class="cell">Note</th>
                    <th class="cell">Employee Id</th>
                    <th class="cell">Audit Id</th>
                    <th class="cell">State Reporting Date</th>
                    <th class="cell">Workstation</th>
                    <th class="cell">State #</th>
                    <th class="cell">State Split #</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="audit in audits" :key="audit._id" class="row">
                    <td class="cell">
                      {{
                        moment(audit.createdAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">{{ audit.batchId }}</td>
                    <td class="cell">{{ audit.quantity }}</td>
                    <td class="cell">{{ audit.price }}</td>
                    <td class="cell">{{ audit.actualUnitCost }}</td>
                    <td class="cell">{{ audit.description }}</td>
                    <td class="cell">{{ audit.manifestNumber }}</td>
                    <td class="cell">{{ audit.supplier.srn }}</td>
                    <td class="cell">
                      {{ audit.supplierAgent ? audit.supplierAgent.srn : "" }}
                    </td>
                    <td class="cell">{{ audit.purchaseOrder }}</td>
                    <td class="cell">{{ audit.barcode }}</td>
                    <td class="cell">{{ audit.return }}</td>
                    <td class="cell">{{ audit.adjustment }}</td>
                    <td class="cell">{{ audit.disposal }}</td>
                    <td class="cell">{{ audit.batchSubId }}</td>
                    <td class="cell">{{ getCustomerId(audit.customer) }}</td>
                    <td class="cell">{{ getInvoiceCode(audit.order) }}</td>
                    <td class="cell">{{ audit.orderItem }}</td>
                    <td class="cell">{{ audit.orderReturnItem }}</td>
                    <td class="cell">{{ audit.isSample }}</td>
                    <td class="cell">{{ audit.detailedDescription }}</td>
                    <td class="cell">{{ audit.createdBy }}</td>
                    <td class="cell">{{ audit._id }}</td>
                    <td class="cell">
                      {{
                        moment(audit.originalCreatedAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">{{ audit.workstation }}</td>
                    <td class="cell">{{ audit.transactionId }}</td>
                    <td class="cell">{{ audit.transactionidSub }}</td>
                  </tr>
                </tbody>
              </table>
            </EditorSection>
            <!-- New Audit end  -->

            <!-- Disposals Begin  -->
            <EditorSection title="Disposals" sectionType="disposals" :isTable="true" :bus="bus">
              <table cellspacing="0" class="table-element">
                <thead>
                  <tr class="header">
                    <th class="cell">Posted To Products</th>
                    <th class="cell">Posted To Journal</th>
                    <th class="cell">Archived</th>
                    <th class="cell">Purchase Order Item Id</th>
                    <th class="cell">Disposal Date</th>
                    <th class="cell">Sub Batched Item</th>
                    <th class="cell">Method</th>
                    <th class="cell">Quantity</th>
                    <th class="cell">Actual Unit Cost</th>
                    <th class="cell">Total</th>
                    <th class="cell">Disposal Id</th>
                    <th class="cell">Posted To Journal At</th>
                    <th class="cell">Archived At</th>
                    <th class="cell">Created At</th>
                    <th class="cell">Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="disposal in disposals" :key="disposal._id" class="row">
                    <td class="cell">
                      <check-mark :toggleVariable="disposal.isPostedToProducts" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="disposal.isPostedToJournal" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="disposal.isArchived" />
                    </td>

                    <td class="cell">
                      <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                        <router-link class="internal-link" target="_blank" :to="{
                          name: 'itemEditor',
                          query: { id: disposal.item._id },
                        }">
                          {{ disposal.item._id }}
                        </router-link>
                      </div>
                    </td>

                    <td class="cell">
                      {{
                        moment(disposal.disposalDate).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">{{ disposal.subBatchedItem || "-" }}</td>
                    <td class="cell">
                      <span>{{ getDisposalMethod(disposal) }}</span>
                    </td>
                    <td class="cell">{{ disposal.quantity }}</td>
                    <td class="cell">
                      {{ disposal.item.actualUnitCost || "-" }}
                    </td>
                    <td class="cell">
                      {{
                        disposal.quantity * (disposal.item.actualUnitCost || 0)
                      }}
                    </td>
                    <td class="cell">{{ disposal._id }}</td>
                    <td class="cell">
                      {{
                        disposal.isPostedToJournal
                        ? moment(disposal.postedToJournalAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>
                    <td class="cell">
                      {{
                        disposal.isArchived
                        ? moment(disposal.archivedAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>
                    <td class="cell">
                      {{
                        moment(disposal.createdAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">
                      {{ Utilities.composeNameAndLastName(disposal.createdBy) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </EditorSection>
            <!-- Disposals end  -->

            <!-- Returns Begin  -->
            <EditorSection title="Returns" sectionType="onlineStore" :isTable="true" :bus="bus">
              <table cellspacing="0" class="table-element">
                <thead>
                  <tr class="header">
                    <th class="cell">Posted To Product</th>
                    <th class="cell">Posted To Journal</th>
                    <th class="cell">Archived</th>
                    <th class="cell">Purchase Order Item Id</th>
                    <th class="cell">Quantity</th>
                    <th class="cell">Actual Unit Cost</th>
                    <th class="cell">SubTotal</th>
                    <th class="cell">Tax</th>
                    <th class="cell">Freight</th>
                    <th class="cell">Total</th>
                    <th class="cell">Return Id</th>
                    <th class="cell">Posted To Journal At</th>
                    <th class="cell">Archived At</th>
                    <th class="cell">Created At</th>
                    <th class="cell">Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="returnItem in returns" :key="returnItem._id" class="row">
                    <td class="cell">{{ returnItem._id }}</td>

                    <td class="cell">
                      <check-mark :toggleVariable="returnItem.isPostedToProducts" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="returnItem.isPostedToJournal" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="returnItem.isArchived" />
                    </td>

                    <td class="cell">
                      <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                        <router-link class="internal-link" target="_blank" :to="{
                          name: 'itemEditor',
                          query: { id: returnItem.item._id },
                        }">
                          {{ returnItem.item._id }}
                        </router-link>
                      </div>
                    </td>

                    <td class="cell">{{ returnItem.quantity }}</td>
                    <td class="cell">{{ returnItem.item.actualUnitCost }}</td>
                    <td class="cell">
                      {{ returnItem.quantity * returnItem.item.actualUnitCost }}
                    </td>
                    <td class="cell">
                      {{ returnItem.item.tax * returnItem.item.actualUnitCost }}
                    </td>
                    <td class="cell">{{ returnItem.freight }}</td>
                    <td class="cell">
                      {{
                        returnItem.quantity * returnItem.item.actualUnitCost +
                        returnItem.item.tax *
                        (returnItem.quantity *
                          returnItem.item.actualUnitCost) +
                        returnItem.freight
                      }}
                    </td>
                    <td class="cell">
                      {{
                        returnItem.isPostedToJournal
                        ? moment(returnItem.postedToJournalAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>
                    <td class="cell">
                      {{
                        returnItem.isArchived
                        ? moment(returnItem.archivedAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>
                    <td class="cell">
                      {{
                        moment(returnItem.createdAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">
                      {{
                        Utilities.composeNameAndLastName(returnItem.createdBy)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </EditorSection>
            <!-- Returns end  -->

            <!-- Adjustments Begin  -->
            <EditorSection title="Adjustments" sectionType="adjustments" :isTable="true" :bus="bus">
              <table cellspacing="0" class="table-element">
                <thead>
                  <tr class="header">
                    <th class="cell">Posted To Product</th>
                    <th class="cell">Posted To Journal</th>
                    <th class="cell">Archived</th>
                    <th class="cell">Purchase Order Item Id</th>
                    <th class="cell">Mode</th>
                    <th class="cell">Quantity</th>
                    <th class="cell">Actual Unit Cost</th>
                    <th class="cell">Total</th>
                    <th class="cell">Adjustment Id</th>
                    <th class="cell">Posted to Journal At</th>
                    <th class="cell">Archived At</th>
                    <th class="cell">Created At</th>
                    <th class="cell">Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="adjustment in adjustments" :key="adjustment._id" class="row">
                    <td class="cell">
                      <check-mark :toggleVariable="adjustment.isPostedToProducts" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="adjustment.isPostedToJournal" />
                    </td>
                    <td class="cell">
                      <check-mark :toggleVariable="adjustment.isArchived" />
                    </td>

                    <td class="cell">
                      <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                        <router-link class="internal-link" target="_blank" :to="{
                          name: 'itemEditor',
                          query: { id: adjustment.item._id },
                        }">
                          {{ adjustment.item._id }}
                        </router-link>
                      </div>
                    </td>

                    <td class="cell">{{ getAdjustmentMode(adjustment) }}</td>
                    <td class="cell">{{ adjustment.quantity }}</td>
                    <td class="cell">{{ adjustment.item.actualUnitCost }}</td>
                    <td class="cell">
                      {{ adjustment.quantity * adjustment.item.actualUnitCost }}
                    </td>
                    <td class="cell">{{ adjustment._id }}</td>
                    <td class="cell">
                      {{
                        adjustment.isPostedToJournal
                        ? moment(adjustment.postedToJournalAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>
                    <td class="cell">
                      {{
                        adjustment.isArchived
                        ? moment(adjustment.archivedAt).format(
                          Constants.dates.defaultDateFormat
                        )
                        : "-"
                      }}
                    </td>

                    <td class="cell">
                      {{
                        moment(adjustment.createdAt).format(
                          Constants.dates.defaultDateFormat
                        )
                      }}
                    </td>
                    <td class="cell">
                      {{
                        Utilities.composeNameAndLastName(adjustment.createdBy)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </EditorSection>
            <!-- Adjustments end  -->

            <div v-if="false" class="section-base">
              <div class="section-base__header space-between">
                <div class="section-base__header-name">Audit</div>
              </div>
              <div class="padded elevated-0 bordered"></div>
            </div>

            <EditorSection :title="`About ${selectedProduct.name || ''}`" sectionType="about"
              v-if="selectedProduct._id !== 'NEW'" :bus="bus">
              <About :entity="selectedProduct" />
            </EditorSection>
          </div>
        </template>
      </div>
    </div>
    <Maintenance v-if="showMaintenance" @execute="executeMaintenance" @close="closeMaintenance"
      :maintenanceType="maintenanceType" />
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)" :entity="entity" :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";
import router from "@/router";
import FileUploader from "@/components/FileUploader/FileUploader.vue";
import { setNewEntity } from "@/utils/initialize-page";
import Maintenance from "@/components/Maintenance/Maintenance.vue";
import LocalStorageManager from "@/lib/LocalStorageManager";
import Multiselect from "vue-multiselect";
import { searchEntities } from "@/services/entityService";

import {
  productInputs,
  bulkPricesInputs,
  salePricingInputs,
  onlineStoreInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
  defaultSalePrice,
  extraInfoInputs,
} from "./inputs";

export default {
  name: "productEditor",
  data() {
    return {
      Utilities: Utilities,
      moment: moment,
      Constants: Constants,
      showSupplierSearchModal: false,
      filterOptions: [
        { name: "SRN", id: "srn" },
        { name: "Name", id: "name" },
        { name: "Phone", id: "phone" },
      ],
      filterOption: undefined,
      supplierResults: this.suppliers,
      productInputs,
      bulkPricesInputs,
      salePricingInputs,
      defaultSalePrice,
      onlineStoreInputs,
      searchText: undefined,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.PRODUCT,
      pageHeader,
      showMaintenance: false,
      maintenanceType: "",
      enforceMso: false,
      extraInfoInputs,
      lodash: _,
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    FileUploader,
    EditorSection,
    Maintenance,
    BaseBtn,
    Multiselect,
  },
  mounted: function () {
    this.getTags();
    this.setPageTitle("Product Editor");
    this.getSuppliers();
    this.getGroups();
    if (this.$route.query.id) {
      this.getProductById({
        _id: this.$route.query.id,
        positiveCallback: (product) => {
          this.setPageTitle(`Product ${product.name}`);
          const filter = {
            criteria: { code: "product" },
            value: product._id,
          };
          this.setReturnsSelectedFilter(filter);
          this.setAdjustmentsSelectedFilter(filter);
          this.setDisposalsSelectedFilter(filter);
          this.setItemsSelectedFilter({
            criteria: { code: "product" },
            value: product._id,
          });
          this.getReturns();
          this.getAdjustments();
          this.getDisposals();
          this.getAvailableInventoryForProduct({
            productId: this.$route.query.id,
          });
          this.getAuditsByProductId(this.$route.query.id);
        },
      });
    } else {
      // new element
      setNewEntity(this.entity);
      this.isEdit = true;
    }
    const organizationId =
      LocalStorageManager.getLocalStorageKey("organizationId");
    this.getOrganizationById({
      _id: organizationId,
    });
  },
  destroyed() {
    this.setReturnsSelectedFilter(undefined);
    this.setAdjustmentsSelectedFilter(undefined);
    this.setDisposalsSelectedFilter(undefined);
    this.setItemsSelectedFilter(undefined);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getProductById({
          _id: this.id,
          positiveCallback: (product) => {
            console.log(product);
          },
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
    organization: function (organization) {
      this.enforceMso = organization?.enforceMso;
    },
  },
  computed: {
    isMsoProductSet() {
      return this.selectedProduct?.msoProduct?._id && this.enforceMso;
    },
    getMsoId() {
      return this.selectedProduct?.msoProduct?._id || Math.random();
    },
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      supplierLoadingStatus: "supplierModule/loadingStatus",
      suppliers: "supplierModule/suppliers",
      groupLoadingStatus: "groupModule/loadingStatus",
      groups: "groupModule/groups",
      items: "itemModule/items",
      returns: "returnModule/returns",
      adjustments: "adjustmentModule/adjustments",
      disposals: "disposalModule/disposals",
      productLoadingStatus: "productModule/loadingStatus",
      products: "productModule/products",
      product: "productModule/product",
      loadingStatus: "productModule/loadingStatus",
      getAudits: "productModule/getAudits",
      organization: "organizationModule/organization",
      flavors: "tagModule/flavors",
      effects: "tagModule/effects",
      terpenes: "tagModule/terpenes",
      tags: "tagModule/tags",
    }),
    selectedProduct: {
      get() {
        return this.prefillEntityBeforeRendering(this.product);
      },
      set(entity) {
        return entity;
      },
    },
    getInputs: function () {
      const inputs = this.productInputs;
      inputs[0].inputs = inputs[0].inputs.map((item) => {
        if (item.id === "group") {
          return {
            ...item,
            options: this.groups,
          };
        }
        return item;
      });
      return inputs;
    },
    audits() {
      return this.getAudits;
    },
    getImageSecureUrl() {
      return this.selectedProduct.imageNormal?.secure_url
    },
  },
  methods: {
    ...mapActions({
      getProductById: "productModule/getProductById",
      saveProduct: "productModule/saveProduct",
      getGroups: "groupModule/getGroups",
      getSuppliers: "supplierModule/getSuppliers",
      deleteProduct: "productModule/deleteProduct",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getReturns: "returnModule/getReturns",
      getAdjustments: "adjustmentModule/getAdjustments",
      getDisposals: "disposalModule/getDisposals",
      getAvailableInventoryForProduct:
        "itemModule/getAvailableInventoryForProduct",
      setReturnsSelectedFilter: "returnModule/setSelectedFilter",
      setAdjustmentsSelectedFilter: "adjustmentModule/setSelectedFilter",
      setDisposalsSelectedFilter: "disposalModule/setSelectedFilter",
      setItemsSelectedFilter: "itemModule/setSelectedFilter",
      getAdjacentProduct: "productModule/getAdjacentProduct",
      getAuditsByProductId: "productModule/getAuditsByProductId",
      performMaintenance: "productModule/performMaintenance",
      getOrganizationById: "organizationModule/getOrganizationById",
      getTags: "tagModule/getAll",
    }),
    getCustomerId(customer) {
      if (!customer || !customer.stringId) return "";
      return customer.stringId;
    },
    getInvoiceCode(invoice) {
      if (!invoice || !invoice.code) return "";
      return invoice.code;
    },
    getProperty(id) {
      return _.get(this.selectedProduct, id);
    },
    getOnlineStoreExtraInfoInput(field) {
      return this.extraInfoInputs.find((i) => i.id == field);
    },
    getOnlineStoreInput(field) {
      return this.onlineStoreInputs.find((i) => i.id == field);
    },
    getInput(input, isExtraInfo = false) {
      const copyInput = JSON.parse(JSON.stringify(input));
      if (
        (copyInput.id == "onlineStoreName" ||
          copyInput.id == "onlineStoreDescription" ||
          copyInput.id == "imageNormal.secure_url" ||
          copyInput.id == "name" ||
          copyInput.id == "brandName" ||
          copyInput.id == "effects" ||
          copyInput.id == "flavors" ||
          copyInput.id == "terpenes" ||
          copyInput.id == "extraInfo" ||
          copyInput.id == "tags" ||
          copyInput.id == "cannabisGramsUnit") &&
        this.selectedProduct?.msoProduct?._id &&
        this.enforceMso
      ) {
        copyInput.disabled = true;
      }

      if (
        isExtraInfo &&
        this.selectedProduct?.msoProduct?._id &&
        this.enforceMso &&
        (copyInput.id == "name" || copyInput.id == "value")
      ) {
        copyInput.disabled = true;
      }
      return copyInput;
    },
    getNestedValue(item, id) {
      if (id.includes(".")) {
        const keys = id.split(".");
        let result = item;
        keys.forEach((key) => {
          if (result) result = result[key];
        });
        return result;
      } else return item[id];
    },
    prefillEntityBeforeRendering(product) {
      if (!product) return product;
      if (product.salePrices) {
        for (let i = 0; i < product.salePrices.length; i++) {
          const salePrice = product.salePrices[i];
          if (salePrice.endDate)
            salePrice.endDate = moment(salePrice.endDate).format(
              Constants.dates.extendedCalendarDateFormat
            );
          if (salePrice.startDate)
            salePrice.startDate = moment(salePrice.startDate).format(
              Constants.dates.extendedCalendarDateFormat
            );
        }
      }

      return product;
    },
    updateImageSecureUrl(imageUrl) {
      if (!this.selectedProduct.imageNormal) {
        this.selectedProduct.imageNormal = {}
      }
      this.selectedProduct.imageNormal.secure_url = imageUrl
    },
    getAdjustmentMode(entity) {
      let mode = entity.mode;
      const foundElement = _.find(
        Constants.listOfAdjustmentModes,
        (ar) => ar.code === entity.mode
      );
      if (foundElement) {
        mode = foundElement.label;
      }
      return mode;
    },
    getDisposalReason(entity) {
      let reason = entity.reason;
      const foundReason = _.find(
        Constants.listOfDisposalReasons,
        (ar) => ar.code === entity.reason
      );
      if (foundReason) {
        reason = foundReason.label;
      }
      return reason;
    },
    getDisposalType(entity) {
      let type = entity.type;
      const foundElement = _.find(
        Constants.listOfDisposalTypes,
        (ar) => ar.code === entity.type
      );
      if (foundElement) {
        type = foundElement.label;
      }
      return type;
    },
    getDisposalMethod(entity) {
      let method = entity.method;
      const foundElement = _.find(
        Constants.listOfDisposalMethods,
        (ar) => ar.code === entity.method
      );
      if (foundElement) {
        method = foundElement.label;
      }
      return method;
    },
    addSalePrice() {
      const defaultSalePrice = JSON.parse(
        JSON.stringify(this.defaultSalePrice)
      );
      this.selectedProduct.salePrices.push({ ...defaultSalePrice });
    },
    deleteSalePrice(i) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: Constants.strings.deletionConfirmation,
        positiveCallback: (data) => {
          this.selectedProduct.salePrices.splice(i, 1);
        },
      });
    },
    addBulkPrice() {
      this.selectedProduct.priceByWeight.push({
        name:
          this.selectedProduct.priceByWeight.length > 0
            ? this.selectedProduct.priceByWeight[
              this.selectedProduct.priceByWeight.length - 1
            ].weight *
            1 +
            0.5 +
            " grams"
            : 0.5 + " grams",
        weight:
          this.selectedProduct.priceByWeight.length > 0
            ? this.selectedProduct.priceByWeight[
              this.selectedProduct.priceByWeight.length - 1
            ].weight *
            1 +
            0.5
            : 0.5,
        isActive: true,
        price: 0,
      });
    },
    deleteBulkPrice(i) {
      this.selectedProduct.priceByWeight.splice(i, 1);
    },
    performSave() {
      if (this.loadingStatus.saveProduct) {
        return;
      }

      const productToSave = this.normalizeEntityBeforeSaving(
        this.selectedProduct
      );

      this.saveProduct({
        product: productToSave,
        positiveCallback: (product) => {
          this.setPageTitle(`Product ${product.name}`);
          if (this.selectedProduct._id === "NEW") {
            router.push({
              name: "productEditor",
              query: { id: product._id },
            });
            return;
          }
          this.selectedProduct = product;
          this.setReturnsSelectedFilter({
            criteria: { code: "product" },
            value: product._id,
          });
          this.getReturns();
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(product) {
      const entity = JSON.parse(JSON.stringify(product));

      entity.group = entity.group ? entity.group._id : "";
      entity.supplier = entity.supplier ? entity.supplier._id : "";
      entity.brand = entity.brand ? entity.brand._id : "";
      if (entity.variety && entity.variety.name) {
        entity.variety = entity.variety.name;
      }
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteProduct) {
        return;
      }

      const productToDelete = this.normalizeEntityBeforeSaving(
        this.selectedProduct
      );

      this.deleteProduct({
        product: productToDelete,
        positiveCallback: () => {
          router.push({
            name: "inventory",
          });
        },
      });
    },
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        if (!this.selectedProduct.imageNormal) {
          this.selectedProduct.imageNormal = {};
        }
        this.selectedProduct.imageNormal.data = e.target.result;
        this.$forceUpdate();
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.selectedProduct.imageNormal = undefined;
      this.selectedProduct.imageThumbnail = undefined;
    },
    toggleSearchModal: function () {
      if (this.showSupplierSearchModal) this.getSuppliers();
      this.showSupplierSearchModal = !this.showSupplierSearchModal;
    },
    searchSuppliers: function (filterCriteria, filterValue) {
      this.getSuppliers({ filterCriteria: filterCriteria.id, filterValue });
    },
    setSupplier: function (supplier) {
      this.selectedProduct.supplier = supplier;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    setProperty(id, $event) {
      _.set(this.selectedProduct, id, $event);
    },
    prefillItem: function (item) {
      const itemSubsetToCopy = _.pick(item, [
        "name",
        "description",
        "brandName",
        "onlineStoreName",
        "onlineStoreDescription",
        "cannabisGramsUnit",
        "notes",
        "imageNormal",
        "flavors",
        "effects",
        "terpenes",
        "extraInfo",
        "brand",
        "tags"
      ]);
      this.selectedProduct.name = itemSubsetToCopy.name;
      this.selectedProduct.description = itemSubsetToCopy.description;
      this.selectedProduct.brandName = itemSubsetToCopy.brandName;
      this.selectedProduct.onlineStoreName = itemSubsetToCopy.onlineStoreName;
      this.selectedProduct.onlineStoreDescription =
        itemSubsetToCopy.onlineStoreDescription;
      this.selectedProduct.cannabisGramsUnit =
        itemSubsetToCopy.cannabisGramsUnit;
      this.selectedProduct.notes = itemSubsetToCopy.notes;
      this.selectedProduct.imageNormal = itemSubsetToCopy.imageNormal;

      const _flavors = this.flavors
      const _effects = this.effects
      const _terpenes = this.terpenes
      const _tags = this.tags

      this.selectedProduct.effects = itemSubsetToCopy.effects.map((_id) => {
        const effect = _effects.find((effect) => effect._id === _id);
        return  effect;
      });

      this.selectedProduct.flavors = itemSubsetToCopy.flavors.map((_id) => {
        const flavor = _flavors.find((flavor) => flavor._id === _id);
        return flavor;
      });

      this.selectedProduct.terpenes = itemSubsetToCopy.terpenes.map((_id) => {
        const terpene = _terpenes.find((terpene) => terpene._id === _id);
        return terpene;
      });

      this.selectedProduct.tags = itemSubsetToCopy.tags.map((_id) => {
        const tag = _tags.find((tag) => tag._id === _id);
        return tag;
      });

      this.selectedProduct.extraInfo = itemSubsetToCopy.extraInfo;
      this.selectedProduct.brand = itemSubsetToCopy.brand;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedProduct[entity] = item;
      if (entity === Entities.MSOPRODUCT) {
        this.prefillItem(item);
        this.getGroupFromMsoProduct(item);
        this.getSupplierFromMsoProduct(item);
      }
    },
    getGroupFromMsoProduct: function(msoEntity){
      searchEntities({
        entity: Entities.GROUP,
        filter: {"msoGroup": msoEntity.msoGroup?.id, isActive: true},
        positiveCallback: (data) => {
          if(data == undefined || data.length != 1){
            this.setMessage({
              type: 'info',
              title: 'Info',
              text: 'Unable to pre-populate group information, please make sure to set a group.',
            });
            this.selectedProduct.group = undefined
            return;
          }

          this.selectedProduct.group = data[0]
        },
        negativeCallback: () => {
          this.selectedProduct.group = undefined
        },
      });
    },
    getSupplierFromMsoProduct: function(msoEntity){
      searchEntities({
        entity: Entities.SUPPLIER,
        filter: {"msoSupplier": msoEntity.msoSupplier?._id, isActive: true},
        positiveCallback: (data) => {
          if(data == undefined || data.length != 1){
            this.setMessage({
              type: 'info',
              title: 'Info',
              text: 'Unable to pre-populate supplier information, please make sure to set a supplier.',
            });
            this.selectedProduct.supplier = undefined
            return;
          }
          this.selectedProduct.supplier = data[0]
        },
        negativeCallback: () => {
          this.selectedProduct.supplier = undefined
        },
      });
    },
    toggleEdit() {
      if (!(this.selectedProduct._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedProduct._id === "NEW") {
        this.isEdit = true;
      }
    },
    clearItemFromSearch: function (entity) {
      this.selectedProduct[entity] = null;
    },
    menuOptionClick(entity, key) {
      if (key === "duplicateProductWithNewSupplier") {
        this.showMaintenance = true;
        this.maintenanceType = "duplicateProductWithNewSupplier";
      } else if (key === "mergeProductCodes") {
        this.showMaintenance = true;
        this.maintenanceType = "mergeProductCodes";
      } else if (key === "changeGroupCode") {
        this.showMaintenance = true;
        this.maintenanceType = "changeGroupCode";
      }
      if (key === "sales-for-productid") {
        window.open(`/reports/sales/salesOrderStatusProductId?productId=${this.product._id}`);
      }

      if (key === "sale-pricing-list") {
        window.open(`/reports/inventory/inventoryListSalePricing`);
      }
    },
    executeMaintenance(type, payload) {
      this.performMaintenance({
        type,
        payload,
        positiveCallback: () => {
          this.showMaintenance = false;
        },
      });
    },
    closeMaintenance() {
      this.showMaintenance = false;
    },
    customLabelOption(option) {
      return option.value;
    },
    deleteExtraInfo(i) {
      this.selectedProduct.extraInfo.splice(i, 1);
    },
    addExtraInfo() {
      if (!this.selectedProduct.extraInfo) {
        this.selectedProduct.extraInfo = [];
      }
      const extraInfo = {
        name: "",
        value: "",
      };
      this.selectedProduct.extraInfo.unshift(extraInfo);
    },
    setImageSecureUrl(url) {
      if (this.selectedProduct.imageNormal) {
        this.selectedProduct.imageNormal.secure_url = url
      } else {
        this.selectedProduct.imageNormal = { secure_url: url }

      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "./_productEditor.scss";

.image-preview {
  width: 280px;
  border-radius: 2px;
}

.modal-body-slot {
  padding: 10px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.extra-info {
  border-radius: $border-radius-0;
  border: 2px solid rgba(255, 255, 255, 0.05);
  padding: 0.5rem;

  &--edit {
    background-color: #313133;
  }
}

.light .extra-info {
  border: 2pt solid #d1d1d1;
  background-color: white;
}

.extra-info-record {
  display: grid;
  grid-template-columns: 1fr 5fr 8fr;
  grid-gap: 1rem;

  &>.input-text {
    margin-bottom: 18px;
  }
}

.option--disabled {
  pointer-events: none;
}

.multiselect__input {
  color: white;

  &::placeholder {
    color: white;
  }
}

.light .multiselect__input {
  color: black;

  &::placeholder {
    color: black;
  }
}

.multiselect__tag {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  background-color: $dark-accent-color;
}

.light .multiselect__tag {
  margin-right: 10px !important;
  margin-bottom: 5px !important;
  background-color: $light-accent-color;
}

.multiselect__tags,
.multiselect--disabled {
  border-radius: $border-radius-0;
  border: 2pt solid #313133;
  background-color: #313133;
  color: white;
}

.light .multiselect__tags,
.multiselect--disabled {
  border-radius: $border-radius-0;
  border: 2pt solid #d1d1d1;
  background-color: white;
  color: black;
}

.multiselect__content-wrapper {
  border-radius: $border-radius-0;
  border: 2pt solid #313133;
  background-color: #313133;
  color: white;
}

.light .multiselect__content-wrapper {
  border-radius: $border-radius-0;
  border: 2pt solid #d1d1d1;
  background-color: white;
  color: black;
}
</style>
