<template>
  <div class="chart-of-account-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'chartOfAccounts', label: 'Chart of Accounts' },
          {
            name: 'chartOfAccountEditor',
            label: selectedChartOfAccount.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedChartOfAccount"
        :entity="entity"
        @updateIsEdit="isEdit = $event"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="() => (showSearchModal = !showSearchModal)"
        @menu-option-click="menuOptionClick"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div
            v-for="input in chartOfAccountInfoInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              v-if="showInput(input)"
              :model="getProperty(input.id)"
              @updateModel="selectedChartOfAccount[input.id] = $event"
              :inputData="getInput(input)"
              :isEdit="isEdit"
              :entityObject="selectedChartOfAccount"
            />
          </div>
        </div>
      </div>
      <EditorSection
        :title="`About ${selectedChartOfAccount.name || ''}`"
        sectionType="about"
        v-if="selectedChartOfAccount._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedChartOfAccount" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import LocalStorageManager from "@/lib/LocalStorageManager";
import {
  defaultSelectedChartOfAccount,
  pageHeader,
  chartOfAccountInfoInputs,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "chartOfAccountEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      isEdit: false,
      defaultSelectedChartOfAccount,
      organizationGroups: [],
      organizationGroupsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.CHARTOFACCOUNT,
      pageHeader,
      chartOfAccountInfoInputs,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      enforceMso: false
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
  },
  mounted: function () {
    const organizationId =
      LocalStorageManager.getLocalStorageKey("organizationId");
    this.getOrganizationById({
      _id: organizationId,
    });

    this.setPageTitle("Chart of Account");
    if (this.$route.query.id) {
      this.getChartOfAccountById({
        _id: this.$route.query.id,
        positiveCallback: (chartOfAccount) => {
          this.setPageTitle(`Chart of Account ${chartOfAccount.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }

  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getChartOfAccountById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
    organization: function (organization) {
      this.enforceMso = organization?.enforceMso;
    },
  },
  computed: {
    ...mapGetters({
      organization: "organizationModule/organization",
    }),
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      chartOfAccount: "chartOfAccountModule/chartOfAccount",
    }),
    selectedChartOfAccount: {
      get() {
        this.setPageTitle("Chart of Account " + this.chartOfAccount.name);
        return this.chartOfAccount;
      },
      set(entity) {
        return entity;
      },
    },
    canEdit() {
      if (
        this.selectedChartOfAccount._id === "NEW" ||
        this.selectedChartOfAccount.canEdit
      )
        return true;
      else return false;
    },
  },
  methods: {
    ...mapActions({
      getChartOfAccountById: "chartOfAccountModule/getChartOfAccountById",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getChartOfAccounts: "organizationGroupModule/getChartOfAccounts",
      getOrganizationById: "organizationModule/getOrganizationById",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    getProperty(id) {
      return _.get(this.selectedChartOfAccount, id);
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedChartOfAccount._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedChartOfAccount._id === "NEW") {
        this.isEdit = true;
      }
    },
    showInput(input) {
      if (input.id == 'msoChartOfAccount' && !this.enforceMso) {
        return false;
      }
      return true;
    },
    getInput(input) {
      const copyInput = JSON.parse(JSON.stringify(input));
      if (
        ( copyInput.id == "accountNoOrganization" ||
        copyInput.id == "accountNo") &&
        this.selectedChartOfAccount?.msoChartOfAccount?._id &&
        this.enforceMso
      ) {
        copyInput.disabled = true;
      }

      return copyInput;
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedChartOfAccount.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedChartOfAccount = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    prefillItem: function (item) {
      const itemSubsetToCopy = _.pick(item, [
        "name",
        "accountNo",
        "accountNoOrganization",
        "isTaxDeductible",
        "category",
        "nbal",
        "notes",
      ]);
      function mergeEmptyItems(objValue, srcValue) {
        if (objValue) return objValue;
        return srcValue;
      }
      _.mergeWith(
        this.selectedChartOfAccount,
        itemSubsetToCopy,
        mergeEmptyItems
      );
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedChartOfAccount[entity] = item;
      if (
        entity === Entities.MSOCHARTOFACCOUNT &&
        this.selectedChartOfAccount._id === "NEW"
      )
        this.prefillItem(item);
    },
    menuOptionClick(entity, key) {
      if (key === "chart-of-account-listing") {
        window.open(`/reports/finance/financeListChartOfAccount`);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.chart-of-account {
  &__can-edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: red;
    margin-bottom: 10px;
    &__text {
      margin-left: 5px;
    }
  }
}
</style>
