var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Deposit Slip for Green Check","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
      {
        name: 'financeDepositSlipGreenCheck',
        label: 'Deposit Slip for Green Check',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.list)),function(ref){
    var recordId = ref[0];
    var record = ref[1];
return _c('tr',{key:recordId},[_c('td',[_vm._v(_vm._s(record.transaction_id))]),_c('td',[_vm._v(_vm._s(record.receipt_date))]),_c('td',[_vm._v(_vm._s(record.customer_id_number))]),_c('td',[_vm._v(_vm._s(record.customer_id_type))]),_c('td',[_vm._v(_vm._s(record.customer_id_expiry_date))]),_c('td',[_vm._v(_vm._s(record.customer_dob))]),_c('td',[_vm._v(_vm._s(record.product_id))]),_c('td',[_vm._v(_vm._s(record.product_name))]),_c('td',[_vm._v(_vm._s(record.product_grams))]),_c('td',[_vm._v(_vm._s(record.product_price_per_unit))]),_c('td',[_vm._v(_vm._s(record.product_type))]),_c('td',[_vm._v(_vm._s(record.transaction_subtotal))]),_c('td',[_vm._v(_vm._s(record.transaction_tax))]),_c('td',[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice( record.orderPosted * -1 + record.orderReversed * -1 + record.orderRefund * -1 + record.orderRefundReversed * -1))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.cashPayment + record.cashPaymentReversed))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice( record.debitPayment + record.debitPaymentReversed ))+" ")]),_c('td',[_vm._v(_vm._s(record.transaction_credit_paid))]),_c('td',[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice( record.giftCardPayment + record.giftCardPaymentReversed + record.creditMemoPayment + record.creditMemoPaymentReversed ))+" ")]),_c('td',[_vm._v(_vm._s(record.medical_or_adult_use))]),_c('td',[_vm._v(_vm._s(record.location_id))])])})],2),_vm._m(1)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',[_vm._v("transaction_id")]),_c('th',[_vm._v("receipt_date")]),_c('th',[_vm._v("customer_id_number")]),_c('th',[_vm._v("customer_id_type")]),_c('th',[_vm._v("customer_id_expiry_date")]),_c('th',[_vm._v("customer_dob")]),_c('th',[_vm._v("product_id")]),_c('th',[_vm._v("product_name")]),_c('th',[_vm._v("product_grams")]),_c('th',[_vm._v("product_price_per_unit")]),_c('th',[_vm._v("product_type")]),_c('th',[_vm._v("transaction_subtotal")]),_c('th',[_vm._v("transaction_tax")]),_c('th',[_vm._v("transaction_total")]),_c('th',[_vm._v("transaction_cash_paid")]),_c('th',[_vm._v("transaction_debit_paid")]),_c('th',[_vm._v("transaction_credit_paid")]),_c('th',[_vm._v("transaction_check_paid")]),_c('th',[_vm._v("medical_or_adult_use")]),_c('th',[_vm._v("location_id")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }