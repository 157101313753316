<template>
  <div class="label" :class="[labelColor]">{{ label }}</div>
</template>

<script>
export default {
  name: 'Label',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false
    }
  },
  computed: {
    labelColor() {
      switch (this.type) {
        case 'warning':
          return 'label--warning'
        case 'error':
          return 'label--error'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './label';
</style>