<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Sales Discounts by Employee"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          {
            name: 'salesDiscountsEmployee',
            label: 'Sales Discounts by Employee',
          },
        ]"
        @export="exportToCsv"
        :disableExport="!reportData"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport"
        :isLoading="salesTransactions"
      />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <div
            v-for="day in reportData.days"
            :key="day.date"
            class="report-body__day-container"
          >
            <table id="table-report">
              <thead>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Employee</th>
                  <th align="left">Order</th>
                  <th align="left">Customer Name</th>
                  <th align="right">PreTax<br />Total</th>
                  <th align="right">Discount</th>
                  <th align="right">%</th>
                  <th align="right">Customer</th>
                  <th align="right">Points</th>
                  <th align="right">CCP</th>
                  <th align="right">Coupon</th>
                  <th align="right">Instant</th>
                  <th align="right">Approved<br />By</th>
                  <th align="right">Coupon Code<br />Reason</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr
                  v-for="batch in day.saleBatch"
                  :key="`${day.date}-${batch.batchKey}`"
                >
                  <td>{{ batch.postedToJournalAtShortDate }}</td>
                  <td>{{ batch.employeeName }}</td>
                  <td>{{ batch.orderId }}</td>
                  <td>{{ batch.customerName }}</td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalPreTax) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.discount) }}
                  </td>
                  <td align="right">
                    {{
                      Utilities.formatPercent(
                        (batch.discount / batch.totalPreTax) * 100
                      )
                    }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalOther) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalPoint) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalCCP) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalCoupon) }}
                  </td>
                  <td align="right">
                    {{ Utilities.formatPrice(batch.totalInstant) }}
                  </td>
                  <td>{{ batch.instantDiscountEmployee }}</td>
                  <td>{{ batch.instantDiscountReason }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%"><hr class="line1" /></td>
              </tr>
              <tr>
                <td colspan="4">Grand Totals</td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].totalPreTax)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].discount)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPercent(
                      (reportData.grandTotals[0].discount /
                        reportData.grandTotals[0].totalPreTax) *
                        100
                    )
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].totalOther)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].totalPoint)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].totalCCP)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(reportData.grandTotals[0].totalCoupon)
                  }}
                </td>
                <td align="right">
                  {{
                    Utilities.formatPrice(
                      reportData.grandTotals[0].totalInstant
                    )
                  }}
                </td>
              </tr>
              <tr></tr>
              <tr class="skip-export">
                <td colspan="100%"><hr class="line1" /></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Sales Discounts by Employee");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      salesTransactions: "reports/salesTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getSalesTransactions",
    }),
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "salesDiscountsEmployee");
    },
    performGetReport(filter) {
      let reportType = "salesDiscountsEmployee";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_salesDiscountsEmployee.scss";
</style>
