<template>
  <div class="pos-main-input">
    <form
      action=""
      v-if="order.customer === undefined && !order.isRetail"
      onsubmit="event.preventDefault()"
      onkeydown="return event.key != 'Enter';"
    >
      <div class="pos-main-input__row">
        <input
          data-cy="input-barcode"
          class="input-text__field"
          :value="customerId"
          placeholder="Customer ID, Queue Barcode, #SRN"
          @input="emitFilter"
          ref="searchInput"
          :disabled="!order._id"
        />

        <BaseBtn
          label="Customer"
          iconGroup="fas"
          iconName="search"
          @click="$emit('customer-popup')"
          size="sm"
          :disabled="!order._id || orderLoadingStatus.saveOrder"
          :isLoading="orderLoadingStatus.saveOrder"
        />
        <BaseBtn
          label="Retail"
          @click="$emit('retail')"
          size="sm"
          :disabled="!order._id || orderLoadingStatus.saveOrder"
          :isLoading="orderLoadingStatus.saveOrder"
        />
      </div>
    </form>

    <div v-else class="elevated-0 padded bordered pos-main-input__customer-box">
      <div class="pos-main-input__pills">
        <Pill
          v-if="isFirstTimeInThisLocation"
          color="purple"
          text="first time at this location"
        ></Pill>
        <Pill
          v-if="isFirstTimeInThisOrganization"
          text="first time customer"
        ></Pill>
        <Pill v-if="order.isInState" color="yellow" text="In State"></Pill>
        <Pill v-if="!order.isInState" color="pink" text="Out of State"></Pill>
      </div>

      <div
        v-if="order.customer"
        class="pos-main-input__customer-name underlined-dashed-link"
        @click="$emit('customer-name-click')"
      >
        <span v-if="order.customer.salutation">{{
          order.customer.salutation
        }}</span>
        {{ Utilities.composeNameAndLastName(order.customer) }}
        <span v-if="order.customer.preferredName"
          >({{ order.customer.preferredName }})</span
        >
      </div>
      <div>
        <div class="space-between">
          <div v-if="order.customer && order.customer.pronoun">
            <div class="field">
              <div class="pos-main-input__label">Pronoun</div>
              <div class="field__content field__content--normal-line-height">
                {{ order.customer.pronoun }}
              </div>
            </div>
          </div>
          <div v-if="order">
            <div class="field">
              <div class="pos-main-input__label">Code</div>
              <div class="field__content field__content--normal-line-height">
                {{ order.code }}
              </div>
            </div>
          </div>
          <div v-if="order.customer">
            <div class="field">
              <div class="pos-main-input__label">Account type</div>
              <div class="field__content field__content--normal-line-height">
                {{ getAccountType }}
              </div>
            </div>
          </div>
          <div
            v-if="
              order &&
              order.type === 'recreational' &&
              order.queueCustomer &&
              !order.customer
            "
          >
            <div class="field">
              <div class="pos-main-input__label">Consumer</div>
              <div class="field__content field__content--normal-line-height">
                {{
                  order.queueCustomer.customerInfo.name +
                  " " +
                  order.queueCustomer.customerInfo.lastName
                }}
              </div>
            </div>
          </div>
          <div
            v-if="order && order.loyaltyInfo && order.loyaltyInfo.cardNumber"
            class="field"
          >
            <div class="pos-main-input__label">Loyalty Card</div>
            <div class="field__content field__content--normal-line-height">
              {{
                order.loyaltyInfo.cardNumber.substr(
                  order.loyaltyInfo.cardNumber.length - 4
                )
              }}
            </div>
          </div>
          <div>
            <div class="field">
              <div class="pos-main-input__label">Order type</div>
              <div class="field__content field__content--normal-line-height">
                {{ getOrderType }}
              </div>
            </div>
          </div>
          <div v-if="order && order.type === 'medical'">
            <div class="field">
              <div class="pos-main-input__label">SRN#</div>
              <div class="field__content field__content--normal-line-height">
                {{ order.customer.srn }}
              </div>
            </div>
          </div>
          <div v-if="order && order.type === 'medical'">
            <div class="field">
              <div class="pos-main-input__label">DF Limit</div>
              <div class="field__content field__content--normal-line-height">
                {{ Utilities.formatWeight(dfCannabisLimit) }}
              </div>
            </div>
          </div>
          <div v-if="order && order.type === 'medical'">
            <div class="field">
              <div class="pos-main-input__label">State Limit</div>
              <div class="field__content field__content--normal-line-height">
                {{ Utilities.formatWeight(stateCannabisLimit) }}
              </div>
            </div>
          </div>
          <div v-if="order && order.caregiver && order.caregiver">
            <div class="field">
              <div class="pos-main-input__label">Caregiver SRN#</div>
              <div class="field__content field__content--normal-line-height">
                {{ order.caregiver.caregiverSrn }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pos-main-input__limits"
        v-if="order.recreationalLimits && order.recreationalLimits.length > 0"
      >
        <PercentageBar
          v-for="limit in order.recreationalLimits"
          :key="limit.name"
          :value="limit.current ? limit.current.toFixed(3) : 0"
          :maximum="order.isInState ? limit.inState : limit.outOfState"
          :title="limit.name"
          unitOfMeasure="g"
        />
      </div>
    </div>
    <div
      class="pos-main-input__row-barcode"
      v-if="!(order._id === undefined || (!order.customer && !order.isRetail))"
    >
      <input
        data-cy="input-barcode"
        class="input-text__field"
        v-model="productBarcode"
        placeholder="Barcode"
        @keydown.enter.prevent="searchProduct"
        @keydown.tab.prevent="searchProduct"
        ref="barcodeInput"
      />
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import { PosMainInput } from "../../inputs";
import Utilities from "@/lib/Utilities";
import Constants from "@/resources/Constants";
import { relativeTimeThreshold } from "moment";
import _ from "lodash";
import { debounce } from "lodash";
import PercentageBar from "@/components/PercentageBar/PercentageBar.vue";
import Pill from "@/components/Pill/Pill";

export default {
  name: "PosMainInput",
  components: { BaseBtn, PercentageBar, Pill },
  props: {
    order: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerId: {
      type: String,
      default: "",
    },
    dfCannabisLimit: {
      type: Number,
      default: 0,
    },
    stateCannabisLimit: {
      type: Number,
      default: 0,
    },
    orderLoadingStatus: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerLocationInformation: {
      type: Object,
      default: () => {
        return {
          hasOrdersInOtherLocations: undefined,
          hasOrdersInThisLocation: undefined,
        };
      },
    },
  },
  watch: {
    order(order) {
      if (order._id && !order.customer) {
        this.$nextTick(() => this.focusSearchInput());
        //setTimeout(this.focusSearchInput, 500)
      }
      if (order._id && order.customer) {
        this.$nextTick(() => this.focusBarcode());
      }
    },
  },
  data() {
    return { Utilities, Constants, productBarcode: "" };
  },
  computed: {
    getAccountType() {
      let type = this.order?.customer
        ? this.order.customer.accountType
        : undefined;
      return this.getType(type);
    },
    getOrderType() {
      let type = this.order ? this.order.type : undefined;
      return this.getType(type);
    },
    isFirstTimeInThisLocation() {
      return (
        this.customerLocationInformation.hasOrdersInOtherLocations === true &&
        this.customerLocationInformation.hasOrdersInThisLocation === false
      );
    },
    isFirstTimeInThisOrganization() {
      return (
        this.customerLocationInformation.hasOrdersInOtherLocations === false &&
        this.customerLocationInformation.hasOrdersInThisLocation === false
      );
    },
  },
  methods: {
    getInput(field) {
      return PosMainInput.find((i) => i.id == field);
    },
    emitFilter: debounce(function (event) {
      this.$emit("customer-change", event);
    }, 500),
    searchProduct(event) {
      (this.productBarcode = ""), this.$emit("search-product", event);
    },
    getType(type) {
      if (!type) {
        return "-";
      }
      const accountType = _.find(
        this.Constants.listOfSaleType,
        (at) => at.code === type
      );
      if (accountType) {
        return accountType.label;
      } else {
        return "-";
      }
    },
    focusSearchInput: function () {
      try {
        this.$refs.searchInput.focus();
      } catch (error) {
        console.log(error);
      }
    },
    focusBarcode: function () {
      try {
        this.$refs.barcodeInput.focus();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$nextTick(() => this.focusSearchInput());
  },
};
</script>

<style scoped lang="scss">
@import "./_posMainInput.scss";
</style>
