<template>
  <div class="room-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :selectedEntity="selectedRoom" :links="[
        { name: 'home', label: 'Home' },
        { name: 'rooms', label: 'Rooms' },
        { name: 'roomEditor', label: selectedRoom.name, query: $route.query },
      ]" :isEdit="isEdit" :isCollapsed="isCollapsed" :entity="entity" @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle" @show-search-modal="() => (showSearchModal = !showSearchModal)" />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div class="two-columns-layout two-columns-layout--responsive">
            <div class="
                grid-two-column
                two-columns-layout--responsive-horizontal-space-10
              ">
              <div v-for="input in roomInputs" :key="input.id" class="input-text">
                <Input :model="selectedRoom[input.id]" @updateModel="selectedRoom[input.id] = $event" :inputData="input"
                  :isEdit="isEdit" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Items Begin  -->
      <EditorSection title="Items" sectionType="items" :isTable="true" :bus="bus" :defaultIsOpen="true">
        <table cellspacing="0" class="table-element">
          <thead>
            <tr class="header">
              <th class="cell">Batch ID</th>
              <th class="cell">Product</th>
              <th class="cell">
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in selectedRoom.items" :key="item._id" class="row">
              <td class="cell">
                <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                  <router-link class="internal-link" target="_blank" :to="{
                    name: 'itemEditor',
                    query: { id: item._id },
                  }">
                    {{ item.batchId || item._id }}
                  </router-link>
                </div>
              </td>

              <td class="cell">
                <div class="
                          space-between
                          space-between--align-items-center
                          space-between--not-responsive
                        ">
                  <router-link class="internal-link" target="_blank" :to="{
                    name: 'productEditor',
                    query: { id: item.externalFields.product._id },
                  }">
                    {{ item.externalFields.product.name }}
                  </router-link>
                </div>
              </td>

              <td class="cell">
                <span>{{ item.rooms.find(r => r.room == selectedRoom._id).quantity }}</span>
              </td>

            </tr>
          </tbody>
        </table>
      </EditorSection>

      <EditorSection :title="`About ${selectedRoom.name || ''}`" sectionType="about" v-if="selectedRoom._id !== 'NEW'"
        :bus="bus">
        <About :entity="selectedRoom" />
      </EditorSection>
      <!-- <DangerZone
        v-if="selectedRoom._id !== 'NEW'"
        @delete="setMessagePopUp({isOpen: true, title: Constants.strings.warningMessageTitle, message: Constants.strings.deletionConfirmation, positiveCallback: performDelete})"
      /> -->
    </div>
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)" :entity="entity" :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import About from "@/components/About/About.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";

import {
  roomInputs,
  columnHeaders,
  filterCriteria,
  pageHeader,
} from "./inputs";
import EditorSection from "@/components/EditorSection";

export default {
  name: "roomEditor",
  data() {
    return {
      Constants: Constants,
      roomInputs,
      isEdit: false,
      bus: new Vue(),
      isCollapsed: true,
      showSearchModal: false,
      columnHeaders,
      filterCriteria,
      entity: Entities.ROOM,
      pageHeader,
    };
  },
  components: {
    PageHeader,
    About,
    Input,
    EditorSection,
  },
  mounted: function () {
    this.setPageTitle("Room");
    if (this.$route.query.id) {
      this.getRoomById({ _id: this.$route.query.id });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getRoomById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      loadingStatus: "productModule/loadingStatus",
      room: "productModule/room",
    }),
    selectedRoom: {
      get() {
        this.setPageTitle(`Room ${this.room.name}`);
        return this.room;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getRoomById: "productModule/getRoomById",
      saveRoom: "productModule/saveRoom",
      deleteRoom: "productModule/deleteRoom",
      setMessagePopUp: "setMessagePopUp",
      getAdjacent: "productModule/getAdjacentRoom",
    }),
    performSave() {
      if (this.loadingStatus.saveRoom) {
        return;
      }

      const roomToSave = this.normalizeEntityBeforeSaving(this.selectedRoom);

      this.saveRoom({
        room: roomToSave,
        positiveCallback: (room) => {
          this.setPageTitle(`Room ${room.name}`);
          this.selectedRoom = room;
          if (this.selectedRoom._id === "NEW") {
            router.push({
              name: "roomEditor",
              query: { id: room._id },
            });
          }
          this.isEdit = false;
        },
      });
    },
    normalizeEntityBeforeSaving(room) {
      const entity = JSON.parse(JSON.stringify(room));
      return entity;
    },
    performDelete() {
      if (this.loadingStatus.deleteRoom) {
        return;
      }

      const roomToDelete = this.normalizeEntityBeforeSaving(this.selectedRoom);

      this.deleteRoom({
        room: roomToDelete,
        positiveCallback: () => {
          router.push({
            name: "rooms",
          });
        },
      });
    },
    addRoom() {
      this.isEdit = true;
      this.selectedRoom = this.defaultSelectedRoom;
      this.selectedRoom._id = "NEW";
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedRoom._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedRoom._id === "NEW") {
        this.isEdit = true;
      }
    },
  },
};
</script>
