var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Customer Loyalty Status","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'customerReport', label: 'Customer' },
        {
          name: 'customerLoyaltyStatus',
          label: 'Customer Loyalty Status',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByCustomerLoyalty":true,"isLoading":_vm.customerTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.id))]),_c('td',[_vm._v(_vm._s(record.fullNameOpposite))]),_c('td',[_vm._v(_vm._s(record.email))]),_c('td',[_vm._v(_vm._s(record.saleDiscountPercentage))]),_c('td',[_vm._v(_vm._s(record.isEmployee ? 'Y' : 'N'))]),_c('td',[_vm._v(_vm._s(record.isVeteran ? 'Y' : 'N'))]),_c('td',[_vm._v(_vm._s(record.isCCP ? 'Y' : 'N'))]),_c('td',[_vm._v(_vm._s(record.isInLoyaltyProgram ? 'Y' : 'N'))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Customer")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Email")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Discount %")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Veteran")]),_c('th',{attrs:{"align":"left"}},[_vm._v("CCP")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Loyalty Program")])])])}]

export { render, staticRenderFns }