<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Inventory Aging"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'inventoryReport', label: 'Inventory' },
            {
              name: 'inventoryAging',
              label: 'Inventory Aging',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          :filterByItemType="true"
          @submit-filter="performGetReport"
          :isLoading="inventoryTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr><td colspan="3">Inventory Aging</td></tr>
                <tr><td colspan="3">{{ reportData.total.datePeriod }}</td></tr>
                <tr><td> </td></tr><tr><td> </td></tr>
                <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
                <tr>
                    <th align="left">supplier</th>
                    <th align="left">group</th>
                    <th align="left">grams</th>
                    <th align="left">name</th>
                    <th align="left">batch/id</th>
                    <th align="left">date purc</th>
                    <th align="right">qty purc</th>
                    <th align="right">0-30 qty</th>
                    <th align="right">0-30 cost</th>
                    <th align="right">31-60 qty</th>
                    <th align="right">31-60 cost</th>
                    <th align="right">61-90 qty</th>
                    <th align="right">61-90 cost</th>
                    <th align="right">91-120 qty</th>
                    <th align="right">91-120 cost</th>
                    <th align="right">120+ qty</th>
                    <th align="right">120+ cost</th>
                </tr>
                <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                    <td>{{ record.supplier }}</td>
                    <td>{{ record.group }}</td>
                    <td>{{ record.grams }}</td>
                    <td>{{ record.name }}</td>
                    <td class="batch">{{ record.id }}</td>
                    <td>{{ record.createdAt}}</td>
                    <td align="right"> {{ record.quantityPurchased }}</td>
                    <td align="right"> {{ record.days_0_30 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_0_30_Cost) }}</td>
                    <td align="right"> {{ record.days_30_60 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_30_60_Cost) }}</td>
                    <td align="right"> {{ record.days_60_90 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_60_90_Cost) }}</td>
                    <td align="right"> {{ record.days_90_120 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_90_120_Cost) }}</td>
                    <td align="right"> {{ record.days_120 }}</td>
                    <td align="right"> {{ Utilities.formatPrice(record.days_120_Cost) }}</td>
                </tr>
              </tbody>
              <tr class="skip-export">
                <td colspan="100%">
                  <hr class="line1" />
                </td>
              </tr>
              <tr>
                <td colspan="6">Totals</td>
                <td align="right"> {{ reportData.total.quantityPurchased }}</td>
                <td align="right"> {{ reportData.total.days_0_30 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_0_30_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_30_60 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_30_60_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_60_90 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_60_90_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_90_120 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_90_120_Cost) }}</td>
                <td align="right"> {{ reportData.total.days_120 }}</td>
                <td align="right"> {{ Utilities.formatPrice(reportData.total.days_120_Cost) }}</td>
              </tr>
              <tr class="skip-export"></tr>
              <tr class="skip-export">
                <td colspan="100%"><hr class="line1" /></td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Inventory Aging");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        inventoryTransactions: "reports/inventoryTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getInventoryTransactions: "reports/getInventoryTransactions",
      }),
      performGetReport(filter) {
        let reportType = "inventoryAging";
        this.getInventoryTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryAging");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_inventoryAging.scss";