<template>
  <Modal
    v-if="showSearchModal"
    @toggle-modal="$emit('toggle-modal')"
    :title="searchModalTitle"
    :isOpen="showSearchModal"
    width="800px"
    modalClass="entity-search-modal"
    @expand-modal="expandModal"
  >
    <template slot="body">
      <slot name="header"></slot>
      <div class="modal-search-table">
        <EntityFilter
          class="search-modal__entity-filter"
          v-if="selectedFilter && !hasForcedItems && !hideFilter"
          :selectedFilter="selectedFilter"
          :filterCriteria="filterCriteria"
          @criteriaChange="performSetSelectedFilter"
          :filterButtons="filterButtonsResults"
          :entity="entity"
          @valueChange="
            (filters, clearPagination) =>
              searchItems(filters, clearPagination, true)
          "
          customStyle="max-width: 800px;"
          :disabled="isFiltersDisabled"
          @filterButtonClicked="searchItems"
        />
        <div
          class="padded search-modal__filter-metadata-container"
          v-if="this.filterMetadata_ && this.filterMetadata_.filterFields"
        >
          <div>Active Filters:</div>
          <div
            class="search-modal__filter-metadata-button elevated-0"
            v-for="metadata in filterMetadata_.filterFields"
            :key="metadata.filterKey"
          >
            <span
              class="gray-text"
              :class="{ 'search-modal__deleted-filter': metadata.isDeleted }"
              >{{ metadata.description }}</span
            >
            :
            <span
              :class="{ 'search-modal__deleted-filter': metadata.isDeleted }"
              >{{ metadata.value }}</span
            >
            <span
              @click="() => toggleDefaultFilter(metadata)"
              v-if="metadata.isDeletable && !metadata.isDeleted"
              class="gray-text pointer search-modal__metadata-toggle-button"
              >×</span
            >
            <span
              @click="() => toggleDefaultFilter(metadata)"
              v-if="metadata.isDeletable && metadata.isDeleted"
              class="gray-text pointer search-modal__metadata-toggle-button"
              >+</span
            >
          </div>
        </div>
        <Table
          :data="data"
          :hasForcedItems="hasForcedItems"
          :columnHeaders="columnHeaders"
          :entity="entity"
          :id="id"
          :nestedId="nestedId"
          :pageNumber="pageNumber"
          :currentPageNumber="currentPageNumber"
          :cardinality="cardinality"
          @toggle-modal="$emit('toggle-modal')"
          @remove-item="removeItem"
          @get-next-entities="getNextEntities"
          @select-item="selectItem"
          :height="heightOfTable"
          :bigIcons="bigIcons"
          :searchTableLoadingStatus="loadingStatus"
          @sortChange="handleSortChange"
          :sort="sort_"
          :enableCardniality="enableCardniality"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import EntityFilter from "@/components/EntityFilter/EntityFilter";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import Constants from "@/resources/Constants";
import { mapGetters } from "vuex";
import { searchEntities, getCardinality } from "@/services/entityService";
import Utilities from "@/lib/Utilities";
import { dataMapper } from "@/utils/entityDataMappers";
import { entityName } from "@/utils/entity-mappers";
import Entities from "@/resources/Entities";
import { filterCriteriaDictionary } from "@/utils/table-lists";
import { cloneDeep } from "lodash";

export default {
  name: "SearchModal",
  components: {
    Modal,
    EntityFilter,
    Table,
  },
  props: {
    showSearchModal: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    initialFilterCriteria: undefined,
    initialFilterValue: {
      type: String,
    },
    filterMetadata: {
      type: Object,
      default: () => ({}),
    },
    columnHeaders: {},
    entity: {
      type: String,
    },
    filterButtons: {
      type: Array,
    },
    id: {
      type: String,
    },
    parentEntity: {
      type: String,
    },
    isFiltersDisabled: {
      type: Boolean,
      default: false,
    },
    nestedId: {
      type: String,
    },
    type: {
      type: String,
    },
    additionalFilters: {
      type: Object,
    },
    filter: {
      type: Object,
    },
    sort: {
      type: Object,
      default: () => ({ createdAt: -1 }),
    },
    defaultFilter: {
      type: Object,
    },
    forcedFilterCriteria: {
      type: Array,
    },
    forcedItems: {
      type: Array,
      default: undefined,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    bigIcons: {
      type: Boolean,
      default: false,
    },
    enableCardniality: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Constants,
      searchText: undefined,
      filterOption: this.defaultOption,
      data: {},
      Utilities,
      skip: 0,
      limit: 12,
      cardinality: undefined,
      numberOfEntities: 0,
      toMso: {
        supplier: "msoSupplier",
        product: "msoProduct",
        group: "msoGroup",
      },
      selectedFilter: undefined,
      filterCriteria: {},
      heightOfTable: 0,
      filterButtonsResults: [],
      sort_: {},
      loadingStatus: {
        isFilterLoading: false,
      },
      selectedFilterButton: undefined,
      filterMetadata_: undefined,
    };
  },
  created() {
    this.sort_ = this.sort;
    this.selectedFilterButton = this.filterButtons
      ? this.filterButtons.find((fb) => fb.isSelected)
      : undefined;
    window.addEventListener("keydown", this.keyDownHandler);
    this.filterMetadata_ = cloneDeep(this.filterMetadata);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  mounted() {
    if (this.forcedFilterCriteria) {
      this.filterCriteria = this.forcedFilterCriteria;
    } else {
      this.filterCriteria = filterCriteriaDictionary[this.entity];
    }

    this.selectedFilter = {
      criteria: this.initialFilterCriteria || this.filterCriteria[0],
      value: this.initialFilterValue || "",
    };

    this.searchItems({ filter: this.selectedFilter });

    this.filterButtonsResults = this.filterButtons;
  },
  updated() {
    this.heightOfTable = this.getTableHeight();
  },
  watch: {
    journalEntryType() {
      this.searchItems({ filter: this.selectedFilter });
    },
    refetchTableData: function () {
      this.searchItems({ filter: this.selectedFilter });
    },
  },
  computed: {
    ...mapGetters({
      product: "productModule/product",
      refetchTableData: "stateModule/refetchTableData",
    }),
    currentPageNumber() {
      if (this.cardinality === 0) return 0;
      return Math.ceil((this.skip + 1) / this.limit);
    },
    pageNumber() {
      return Math.ceil(this.cardinality / this.limit);
    },
    searchModalTitle() {
      if (this.title) return this.title;
      else return entityName[this.entity] + " Search";
    },
    journalEntryType() {
      return this.type;
    },
    hasForcedItems() {
      try {
        return this.forcedItems != undefined;
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.$emit("toggle-modal", { isOpen: false });
      }
    },
    handleSortChange(sort) {
      this.sort_ = sort;
      var filter = this.selectedFilter;
      this.searchItems({ filter }, true, true);
    },
    updateFilterButtons(filterButton) {
      this.filterButtonsResults = this.filterButtonsResults.map((button) => {
        if (button === filterButton) {
          if (button.isSelected) this.selectedFilterButton = undefined;
          else {
            this.selectedFilterButton = filterButton;
          }
          return {
            ...button,
            isSelected: !button.isSelected,
          };
        } else
          return {
            ...button,
            isSelected: false,
          };
      });
    },
    expandModal: function (isExpanded) {
      if (isExpanded) {
        this.skip = this.skip + 5 * (this.currentPageNumber - 1);
        this.limit = 14;
        this.searchItems();
      } else {
        this.skip = this.skip - 5 * (this.currentPageNumber - 1);
        this.limit = 12;
        this.searchItems();
      }
    },
    performSetSelectedFilter: function ({ filter, filter2 }) {
      this.selectedFilter = { ...filter };
      this.$forceUpdate();
      if (!filter.value && !filter.criteria?.code) this.searchItems();
    },
    getNextEntities: function (type, isDisabled) {
      const canSearchEntities = !isDisabled;
      if (type === "next") {
        if (!isDisabled) this.skip = this.skip + this.limit;
      } else if (type === "prev") {
        if (!isDisabled) this.skip = this.skip - this.limit;
      } else if (type === "first") {
        if (!isDisabled) this.skip = 0;
      } else if (type === "last") {
        if (!isDisabled) {
          this.skip =
            Math.ceil(this.cardinality / this.limit) * this.limit - this.limit;
        }
      }
      if (canSearchEntities) {
        this.searchItems({ filter: this.selectedFilter });
      }
    },
    searchItems: async function (
      filters,
      clearPagination,
      isFilterEvent = false
    ) {
      let previousSelectedFilterButtonFilter = {};
      if (this.selectedFilterButton?.filter) {
        previousSelectedFilterButtonFilter = JSON.parse(
          JSON.stringify(this.selectedFilterButton.filter)
        );
      }

      if (isFilterEvent) {
        this.selectedFilter.value = filters?.filter?.value;
      }
      if (filters?.filterButton) {
        this.updateFilterButtons(filters.filterButton);
        if (filters.filterButton.showAll) {
          this.limit = undefined;
          this.skip = undefined;
        } else {
          this.skip = 0;
          this.limit = 20;
        }
      }

      const filter = filters?.filter;
      if (clearPagination) {
        this.skip = 0;
        this.limit = 20;
      }

      // If items are being forced bypass api calls
      if (this.hasForcedItems) {
        return (this.data = dataMapper[this.entity](this.forcedItems));
      }

      let mongoFilter = {};
      if (this.filter) {
        mongoFilter = JSON.parse(JSON.stringify(this.filter));
      }

      if (this.selectedFilterButton) {
        // Remove fields set by previous slected filter button
        for (let field of Object.keys(previousSelectedFilterButtonFilter)) {
          delete mongoFilter[field];
        }
        // Then applies fields set by current selected filter button
        mongoFilter = { ...mongoFilter, ...this.selectedFilterButton.filter };
      }

      if (this.defaultFilter) {
        mongoFilter = { ...mongoFilter, ...this.defaultFilter };
      }

      if (this.enableCardniality) {
        getCardinality({
          entity: this.entity,
          filterCriteria: filter?.criteria?.code,
          isPartial: filter?.criteria?.isPartial,
          filterValue: filter?.value || this.selectedFilter.value,
          additionalFilters: this.additionalFilters,
          filter: mongoFilter,
          preProcessFilterValue: filter?.criteria?.preProcessFilterValue,
          sort: this.sort_,
          positiveCallback: (data) => {
            this.cardinality = data;
            this.numberOfEntities = data;
          },
        });
      }
      this.loadingStatus.isFilterLoading = true;
      searchEntities({
        filterCriteria: filter?.criteria?.code,
        filterValue: filter?.value || this.selectedFilter.value,
        entity: this.entity,
        isPartial: filter?.criteria?.isPartial,
        skip: this.skip,
        limit: this.limit,
        type: this.journalEntryType,
        additionalFilters: this.additionalFilters,
        filter: mongoFilter,
        preProcessFilterValue: filter?.criteria?.preProcessFilterValue,
        sort: this.sort_,
        positiveCallback: (data) => {
          if (dataMapper[this.entity]) {
            this.data = dataMapper[this.entity](data);
          } else this.data = data;
          this.loadingStatus.isFilterLoading = false;
        },
        negativeCallback: () => {
          this.loadingStatus.isFilterLoading = false;
        },
      });
    },
    selectItem(entity, item, nestedId) {
      this.$emit("select-item", entity, item, nestedId);
    },
    removeItem(entity, item, nestedId) {
      this.$emit("remove-item", entity, item, nestedId);
    },
    getTableHeight() {
      const searchmodalContent = document.getElementById(
        "search-modal-content"
      );
      let entityFilterHeight =
        document.getElementById("entity-filter")?.offsetHeight || 0;
      let pageHeader =
        document.getElementById("modal-header")?.offsetHeight || 0;
      let appBody = document.getElementById("modal")?.offsetHeight || 0;
      let bodyStyles = searchmodalContent
        ? window.getComputedStyle(searchmodalContent)
        : 0;
      const bodyPadding =
        parseFloat(bodyStyles.paddingTop) +
        parseFloat(bodyStyles.paddingBottom);
      return appBody - pageHeader - entityFilterHeight - bodyPadding;
    },
    toggleDefaultFilter(metadata) {
      if (metadata.isDeleted) {
        metadata.isDeleted = false;
        this.defaultFilter[metadata.filterKey] = metadata.filterValue;
      } else {
        this.$set(metadata, "isDeleted", true);
        this.$set(
          metadata,
          "filterValue",
          this.defaultFilter[metadata.filterKey]
        );
        delete this.defaultFilter[metadata.filterKey];
      }
      this.searchItems({ filter: this.selectedFilter });
    },
  },
};
</script>

<style lang="scss">
.search-modal {
  &__entity-filter {
    margin: 0;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 25px;

    &__cardinality {
      font-size: 14px;
    }

    &__icon {
      margin: 10px;
      cursor: pointer;
    }

    &__active {
      &:hover {
        color: $dark-accent-color;
      }
    }

    &__disabled {
      color: gray;

      &:hover {
        color: "black" !important;
      }
    }
  }

  &__filter-metadata-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__filter-metadata-button {
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius-0;
  }

  &__deleted-filter {
    text-decoration: line-through;
  }

  &__metadata-toggle-button {
    margin-left: 0.5rem;

    &:hover {
      color: $dark-text-color-0;
    }
  }
}

.light .search-modal__metadata-toggle-button:hover {
  color: $light-text-color-0;
}
</style>
