<template>
  <div class="customer-list">
    <EntityFilter
      :selectedFilter="selectedFilter"
      :filterCriteria="filterCriteria"
      :preFilters="selectedPreFilters"
      @criteriaChange="performSetSelectedFilter"
      @valueChange="loadData"
    />
    <div v-if="isRetailButtonActive" class="section-base">
      <div class="padded elevated-0 bordered">
        <inline-button
          label="Recreational Sale"
          type="plain-primary responsive-column form-height wide"
          @click="
            () =>
              emitSelectedElement({
                isRetail: true,
                accountType: 'recreational',
              })
          "
        />
      </div>
    </div>
    <div class="table-container margined bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Email</th>
            <th class="cell">Name</th>
            <th class="cell">Last name</th>
            <th class="cell">Account type</th>
            <th class="cell">Created by</th>
            <th class="cell">Updated by</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="loadingStatus.getCustomers && customers.length === 0"
            class="row"
          >
            <td colspan="100" class="center padded">
              <i class="fas fa-spin fa-circle-notch"></i> Loading...
            </td>
          </tr>
          <tr class="row" v-for="g in customers" :key="g._id">
            <td class="cell">
              <inline-button
                v-if="!allowSelection"
                :to="{ name: 'customerEditor', query: { id: g._id } }"
                label="Open"
                type="plain-primary responsive-column form-height wide"
              />
              <inline-button
                v-if="allowSelection"
                label="Select"
                type="plain-primary responsive-column form-height wide"
                @click="() => emitSelectedElement(g)"
              />
            </td>
            <td class="cell">{{ g.email }}</td>
            <td class="cell">{{ g.name }}</td>

            <td class="cell">{{ g.lastName }}</td>
            <td class="cell">{{ getAccountType(g) }}</td>
            <td class="cell">
              {{
                g.createdBy
                  ? g.createdBy.name +
                    " " +
                    (g.createdBy.lastName ? g.createdBy.lastName : "")
                  : "-"
              }}
            </td>
            <td class="cell">
              {{
                g.updatedBy
                  ? g.updatedBy.name +
                    " " +
                    (g.updatedBy.lastName ? g.updatedBy.lastName : "")
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EntityFilter from "@/components/EntityFilter/EntityFilter";
import _ from "lodash";
import Constants from "@/resources/Constants";

export default {
  name: "CustomerList",
  data() {
    return { Constants: Constants };
  },
  props: {
    allowSelection: {
      type: Boolean,
      default: false,
    },
    isRetailButtonActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EntityFilter,
  },
  mounted: function () {
    this.getCustomers();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "customerModule/loadingStatus",
      customers: "customerModule/customers",
      filterCriteria: "customerModule/filterCriteria",
      selectedFilter: "customerModule/selectedFilter",
      selectedPreFilters: "customerModule/selectedPreFilters",
    }),
  },
  methods: {
    ...mapActions({
      getCustomers: "customerModule/getCustomers",
      setSelectedFilter: "customerModule/setSelectedFilter",
    }),
    loadData: function (filter) {
      this.setSelectedFilter(filter);
      this.getCustomers();
    },
    performSetSelectedFilter({ filter, filter2 }) {
      this.setSelectedFilter(filter);
      this.getCustomers();
    },
    emitSelectedElement(selectedElement) {
      this.$emit("elementSelected", selectedElement);
    },
    getAccountType(customer) {
      if (!customer) {
        return "-";
      }
      const accountType = _.find(
        this.Constants.listOfSaleType,
        (at) => at.code === customer.accountType
      );
      if (accountType) {
        return accountType.label;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss"></style>
