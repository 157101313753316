var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"brand-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"selectedEntity":_vm.selectedBrand,"links":[
        { name: 'home', label: 'Home' },
        { name: 'brands', label: 'Brands' },
        {
          name: 'brandEditor',
          label: _vm.selectedBrand.name,
          query: _vm.$route.query,
        } ],"hideAddButton":true,"canArchive":!_vm.selectedBrand.isArchived,"canEdit":!_vm.selectedBrand.isArchived,"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"entity":_vm.entity},on:{"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"\n          padded\n          elevated-0\n          bordered\n          position-relative\n          order-editor__body\n        ",class:{
          'bordered__highlighted--primary': _vm.selectedBrand.isPaid,
          'bordered__highlighted--danger':
            _vm.selectedBrand.isArchived || _vm.selectedBrand.isVoided,
        }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.selectedBrand.isArchived)?_c('ContainerLabel',{attrs:{"type":"danger","text":"ARCHIVED"}}):_vm._e()],1),_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},[_c('div',{staticClass:"\n                grid-two-column\n                two-columns-layout--responsive-horizontal-space-10\n              "},_vm._l((_vm.brandInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedBrand[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedBrand[input.id] = $event}}})],1)}),0)])])],1)]),(_vm.selectedBrand._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedBrand.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedBrand}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }