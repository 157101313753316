<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Tax Summary" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'salesTaxSummary',
          label: 'Tax Summary',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print" :filterByTime="true" @submit-filter="performGetReport" :isLoading="isLoading"
        :percentage="percentageCompleted" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Tax Description</th>
                <th align="right">Total Tax</th>
                <th align="right">Cannabis Tax</th>
                <th align="right">Medical Tax</th>
                <th align="right">Adult Tax</th>
                <th align="right">Other Tax</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="(record, index) in reportData.list" :key="index">
                <td>{{ record.taxName }}</td>
                <td align="right">
                  {{ Utilities.formatPrice(record.totalTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.cannabisTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.medicalTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.recreationalTax) }}
                </td>
                <td align="right">
                  {{ Utilities.formatPrice(record.otherTax) }}
                </td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td>Totals</td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.totalTax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.cannabisTax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.medicalTax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.recreationalTax) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.total.otherTax) }}
              </td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Tax Summary");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "SalesTaxSummary");
    },
    performGetReport(filter) {
      let reportType = "salesTaxSummary";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_salesTaxSummary.scss";
</style>
