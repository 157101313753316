import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedBrand } from "@/views/Brand/inputs";
const state = {
  brands: [],
  brand: { ...defaultSelectedBrand },
  loadingStatus: {
    saveBrand: false,
    getBrands: false,
    getBrandById: false,
    deleteBrand: false,
  },
  brandPopUp: {
    isOpen: false,
  },
  selectedFilter: undefined,
  selectedPreFilters: undefined,
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setBrands: (state, payload) => {
    state.brands = payload;
  },
  setBrand: (state, payload) => {
    if (payload._id === "NEW") state.brand = { ...defaultSelectedBrand };
    else state.brand = payload;
  },
  setSelectedFilter: (state, payload) => {
    state.selectedFilter = payload;
  },
  setSelectedPreFilters: (state, payload) => {
    state.selectedPreFilters = payload;
  },
  setBrandPopUp: (state, payload) => {
    state.brandPopUp = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  brands: (state) => state.brands,
  brand: (state) => state.brand,
  loadingStatus: (state) => state.loadingStatus,
  filterCriteria: (state) => state.filterCriteria,
  selectedFilter: (state) => state.selectedFilter,
  brandPopUp: (state) => state.brandPopUp,
  selectedPreFilters: (state) => state.selectedPreFilters,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveBrand: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveBrand", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.brand.saveBrand,
      payload.brand,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "saveBrand",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteBrand: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "deleteBrand",
      status: true,
    });
    HttpService.post(
      Apis.backendBaseUrl + Apis.brand.deleteBrand,
      payload.brand,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteBrand",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getBrandById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getBrandById",
      status: true,
    });
    HttpService.get(
      Apis.backendBaseUrl + Apis.brand.getBrandById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getBrandById",
          status: false,
        });
        if (response) {
          commit("setBrand", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setSelectedFilter: ({ commit, state }, payload) => {
    commit("setSelectedFilter", payload);
  },
  setBrandPopUp: ({ commit, state }, payload) => {
    commit("setBrandPopUp", payload);
  },
  setSelectedPreFilters: ({ commit, state }, payload) => {
    commit("setSelectedPreFilters", payload);
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
