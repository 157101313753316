import Constants from "@/resources/Constants";
import _ from "lodash";

export const prefillOrderBeforeRendering = (order) => {
  if (order.type) {
    const accountType = _.find(
      Constants.listOfSaleType,
      (at) => at.code === order.type
    );
    if (accountType) {
      order.type = accountType.label;
    }
  }

  return order;
};
