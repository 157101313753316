<template>
  <div class="pos-scan">

    <BaseInput label="Batch ID" placeholder="0000000000000000" controlType="text" v-model="batchId"
      v-on:keyup.enter="handleScan" :applyFocus="true" v-on:input="handleScan" ref="scanInput" />

    <div class="pos-scan__body">
      <div v-for="product in order.products" :key="product._id" class="pos-scan__product">
        <div class="pos-scan__product-wrapper">

          <div class="elevated-0 pos-scan__product-quantity"
            :class="{ 'pos-scan__product-quantity--correct': product.scannedQuantity == product.quantity }">
            {{ product.scannedQuantity || 0 }} / {{ product.quantity }}
          </div>

          <div class="elevated-1 pos-scan__product-info">
            <div class="pos-scan__product-header">
              <div> {{ product.name }}</div>
              <div> {{ getBatchIds(product) }}</div>
            </div>
            <div class="text-color-1">
              {{ product.externalFields.group.name }} |
              {{ product.externalFields.supplier.name }}
              {{ getBrandNameString(product) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pos-scan__buttons">
      <BaseBtn @click="handleResetScanCounters" iconGroup="fas" iconName="undo" label="Reset Count" :colorType="1" />
      <div class="pos-scan__buttons__right">
        <BaseBtn label="Fulfill" iconGroup="fa" iconName="box-open" @click="handleFulfillmentPrint"
          v-if="store.isPOSScanningEnabled" :disabled="disableFulfillmentPrint" />
        <BaseBtn @click="$emit('scan-done')" iconGroup="fas" iconName="arrow-right" label="Next to Payments"
          :disabled="!areAllItemsScanned" />
      </div>
    </div>

  </div>
</template>

<script>
import BaseInput from "@/components/Base/Input/Base.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "PosScan",
  data() {
    return {
      batchId: ''
    };
  },
  components: {
    BaseInput,
    BaseBtn,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      order: "orderModule/posOrder",
      currentStore: "storeModule/currentStore",
    }),
    store() {
      return this.currentStore
    },
    areAllItemsScanned() {
      return this.order.products.every(p => p.quantity == p.scannedQuantity)
    },
    disableFulfillmentPrint() {
      if (!this.order._id) {
        return true
      }
      if (this.order.products.length == 0) {
        return true
      }
      if (this.order.isPaid || this.order.isArchived || this.order.isVoided) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      scanItem: "orderModule/scanItem",
      resetScanCounters: "orderModule/resetScanCounters",
    }),
    getBatchIds(product) {
      try {
        return product.items.map(i => !i.batchId ? i._id : i.batchId).join(',')
      } catch (error) {
        return ''
      }
    },
    getBrandNameString(product) {
      return product.brandName ? `(${product.brandName})` : ''
    },
    handleScan: _.debounce(function (e) {
      // Remove spaces in the scanned batch id
      const batchId = this.batchId.replaceAll(' ', '')

      this.scanItem({
        batchId: batchId, positiveCallback: () => {
          this.batchId = ''
        }
      })
    }, 200),
    handleResetScanCounters() {
      this.resetScanCounters()
      this.$refs.scanInput.focus()
    },
    handleFulfillmentPrint() {
      this.$emit('fulfillment-print')
      this.$refs.scanInput.focus()
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_pos-scan.scss";
</style>