<template>
  <div class="mso-group-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="pageHeader" :links="[
        { name: 'home', label: 'Home' },
        { name: 'msoGroups', label: 'MSO Groups' },
        {
          name: 'msoGroupEditor',
          label: selectedOrganizationGroup.name,
          query: $route.query,
        },
      ]" :isEdit="isEdit" :isCollapsed="isCollapsed" :selectedEntity="selectedOrganizationGroup"
        @updateIsEdit="isEdit = $event" :entity="entity" @toggle-edit="toggleEdit" @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent" @show-search-modal="toggleSearchModal" />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div v-for="input in msoGroupInfoInputs" :key="input.id" class="input-text">
            <Input :model="selectedOrganizationGroup[input.id]"
              @updateModel="selectedOrganizationGroup[input.id] = $event" :inputData="input" :isEdit="isEdit" />
          </div>
        </div>
      </div>

      <EditorSection title="Associated Groups" v-if="selectedOrganizationGroup._id !== 'NEW'" :bus="bus">
        <SearchTable :key="id" :entity="Entities.GROUP" :hideFilters="true" :filter="filter" />
      </EditorSection>
      
      <EditorSection title="Integrations" :bus="bus">
        <div class="padded elevated-0 bordered">
          <div v-for="input in msoGroupIntegrationInputs" :key="input.id" class="input-text">
            <Input :model="selectedOrganizationGroup[input.id]"
              @updateModel="selectedOrganizationGroup[input.id] = $event" :inputData="input" :isEdit="isEdit" />
          </div>
        </div>
      </EditorSection>

      <EditorSection :title="`About ${selectedOrganizationGroup.name || ''}`" sectionType="about"
        v-if="selectedOrganizationGroup._id !== 'NEW'" :bus="bus">
        <About :entity="selectedOrganizationGroup" />
      </EditorSection>
    </div>
    <SearchModal v-if="showSearchModal" :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)" :entity="entity" :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders" />
  </div>
</template>

<script>
import Vue from "vue";
import SearchTable from "@/components/SearchTable";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import { mapActions, mapGetters } from "vuex";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import {
  defaultSelectedMsoGroup,
  pageHeader,
  msoGroupInfoInputs,
  filterCriteria,
  columnHeaders,
  msoGroupIntegrationInputs
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "msoGroupEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      isEdit: false,
      defaultSelectedMsoGroup,
      organizationGroups: [],
      organizationGroupsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.MSOGROUP,
      pageHeader,
      msoGroupInfoInputs,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
      msoGroupIntegrationInputs
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("MSOGroup");
    if (this.$route.query.id) {
      this.getMsoGroupById({
        _id: this.$route.query.id,
        positiveCallback: (group) => {
          this.setPageTitle(`Group ${group.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getMsoGroupById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      msoGroup: "msoGroupModule/msoGroup",
    }),
    selectedOrganizationGroup: {
      get() {
        this.setPageTitle("MSO Group " + this.msoGroup.name);
        return this.msoGroup;
      },
      set(entity) {
        return entity;
      },
    },
    filter() {
      return {
        msoGroup: this.id,
      };
    },
  },
  methods: {
    ...mapActions({
      getMsoGroupById: "msoGroupModule/getMsoGroupById",
      saveOrganizationGroup: "organizationGroupModule/saveOrganizationGroup",
      deleteOrganizationGroup:
        "organizationGroupModule/deleteOrganizationGroup",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getOrganizationGroups: "organizationGroupModule/getOrganizationGroups",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedOrganizationGroup._id === "NEW"))
        this.isEdit = !this.isEdit;
      if (this.selectedOrganizationGroup._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedOrganizationGroup.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedOrganizationGroup = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedOrganizationGroup[entity] = item;
    },
  },
};
</script>
