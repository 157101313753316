<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="Tax Schedule"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'financeReport', label: 'Finance' },
          {
            name: 'salesTaxSchedule',
            label: 'Tax Schedule',
          },
        ]"
        @export="exportToCsv"
        :disableExport="!reportData"
      />
      <br class="no-print" />
      <ReportFilter
        class="no-print"
        :filterByTime="true"
        @submit-filter="performGetReport"
        :isLoading="salesTransactions"
      />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report-1">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th colspan="3" align="left">Gross Receipts from Cannabis</th>
                <th align="right">Combined</th>
                <th align="right">Medical</th>
                <th align="right">Adult Use</th>
              </tr>
            </thead>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.gross.total) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.gross.medical) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.gross.adult) }}
              </td>
            </tr>
            <tr></tr>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr
                v-for="[taxName, value] in Object.entries(reportData.taxesList)"
                :key="taxName"
              >
                <td></td>
                <td></td>
                <td></td>
                <td v-if="value.name != 'Medical'" colspan="2" align="left">{{ value.name }}</td>
                <td v-if="value.name != 'Medical'" align="right">
                  {{ Utilities.formatPrice(value.total) }}
                </td>
              </tr>
            </tbody>
        </table>

          <table id="table-report-2">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th colspan="3" align="left">Total Tax Collected</th>
                <th align="right">Combined</th>
                <th align="right">Medical</th>
                <th align="right">Adult Use</th>
              </tr>
            </thead>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.tax.total) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.tax.medical) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.tax.adult) }}
              </td>
            </tr>
            <tr></tr>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr
                v-for="[taxName, value] in Object.entries(reportData.taxesList)"
                :key="taxName"
              >
                <td></td>
                <td></td>
                <td></td>
                <td v-if="value.name != 'Medical'" colspan="2" align="left">{{ value.name }}</td>
                <td v-if="value.name != 'Medical'" align="right">
                  {{ Utilities.formatPrice(value.tax) }}
                </td>
              </tr>
            </tbody>
            <tr>
              <td colspan="100%"><hr class="line1" /></td>
            </tr>
          </table>

          <table id="table-report-3">
            <thead>
              <tr class="report-body__row report-body__row--header"></tr>
              <tr>
                <th colspan="3" align="left">Net Receipts from Cannabis</th>
                <th align="right">Combined</th>
                <th align="right">Medical</th>
                <th align="right">Adult Use</th>
              </tr>
            </thead>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.net.total) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.net.medical) }}
              </td>
              <td align="right">
                {{ Utilities.formatPrice(reportData.net.adult) }}
              </td>
            </tr>
            <tr></tr>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr
                v-for="[taxName, value] in Object.entries(reportData.taxesList)"
                :key="taxName"
              >
                <td></td>
                <td></td>
                <td></td>
                <td v-if="value.name != 'Medical'" colspan="2" align="left">{{ value.name }}</td>
                <td v-if="value.name != 'Medical'" align="right">
                  {{ Utilities.formatPrice(value.net) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";
import ReportHelper from "@/lib/report/index.js";

export default {
  data() {
    return {
      reportData: undefined,
      Utilities,
      reportType: undefined,
      selectedPeriod: undefined,
      selectedReportFor: undefined,
      selectedReportSaleType: undefined,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Tax Schedule");
  },
  mounted: function () {
    const today = new Date();
    this.selectedPeriod = {
      from: new Date(new Date().setDate(today.getDate() - 7)),
      to: today,
    };
  },
  computed: {
    ...mapGetters({
      loadingStatus: "reports/loadingStatus",
      salesTransactions: "reports/salesTransactions",
    }),
  },
  methods: {
    ...mapActions({
      getSalesTransactions: "reports/getSalesTransactions",
    }),
    exportToCsv() {
      var table1 = document.getElementById("table-report-1");
      ReportHelper.exportToExcel(table1, "salesTaxSchedule-1");
      var table2 = document.getElementById("table-report-2");
      ReportHelper.exportToExcel(table2, "salesTaxSchedule-2");
      var table3 = document.getElementById("table-report-3");
      ReportHelper.exportToExcel(table3, "salesTaxSchedule-3");
    },
    performGetReport(filter) {
      let reportType = "salesTaxSchedule";
      this.getSalesTransactions({
        reportType,
        ...filter,
        positiveCallback: (data) => {
          this.reportData = data;
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./_salesTaxSchedule.scss";
</style>
