import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";
import { defaultSelectedJournal } from "@/views/Journal/inputs";

const state = {
  journals: [],
  journal: { ...defaultSelectedJournal },
  totals: {},
  loadingStatus: {
    saveJournal: false,
    getJournals: false,
    getJournalById: false,
    deleteJournal: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setJournals: (state, payload) => {
    state.journals = payload;
  },
  setJournal: (state, payload) => {
    if (payload._id === "NEW") state.journal = { ...defaultSelectedJournal };
    else state.journal = payload;
  },
  setReverseJournal: (state, payload) => {
    state.journal = payload;
  },
  setTotals: (state, payload) => {
    state.totals = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  journals: (state) => state.journals,
  journal: (state) => state.journal,
  totals: (state) => state.totals,
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  getJournals: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getJournals", status: true });
    let url = `${Apis.backendBaseUrl}${Apis.getJournals}?`;
    if (state.selectedFilter || payload) {
      if (state.selectedFilter?.limit) {
        url += `&limit=${state.selectedFilter.limit}`;
      }
      if (state.selectedFilter?.skip) {
        url += `&skip=${state.selectedFilter.skip}`;
      }
      if (state.selectedFilter?.criteria || payload) {
        url += `&filterCriteria=${payload.filterCriteria}`;
      }
      if (state.selectedFilter?.value || payload) {
        url += `&filterValue=${
          state.selectedFilter?.value || payload.filterValue
        }`;
      }
    }
    if (state.selectedPreFilters) {
      state.selectedPreFilters.forEach((pf) => {
        if (!pf.criteria || !pf.filterValue) {
          return;
        }
        let value = pf.filterValue;
        if (typeof pf.filterValue === "object" && pf.filterValue !== null) {
          value = pf.filterValue._id;
        }
        url += `&${pf.criteria}=${value}`;
      });
    }

    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getJournals",
        status: false,
      });
      var journals = {};

      if (response) {
        journals = response.data.body;
        commit("setJournals", journals);
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getJournalById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getJournalById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getJournalById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getJournalById", status: false });
        if (response) {
          commit("setJournal", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getTotals: ({ commit, state }, payload) => {
    HttpService.get(
      Apis.backendBaseUrl + Apis.getJournalTotals,
      function (response, error) {
        if (response) {
          commit("setTotals", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setReverseJournal: ({ commit, state }, payload) => {
    commit("setReverseJournal", payload);
  },
  postManualJournalEntries: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.postManualJournals,
      {},
      function (response, error) {
        if (response) {
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
