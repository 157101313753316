<template>
  <div id="order-mode-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div center bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Order Mode</span> <br />
      <span class="text-color-1"
        >The selected customer is a Caregiver. Do they want to buy as patient or
        as a caregiver?</span
      >
      <br />
      <div class="padded button-wrapper">
        <BaseBtn
          :disabled="!orderModePopUp.customer.isPatient"
          @click="() => performTypeSelection({ type: 'patient' })"
          label="Buy as Patient"
        />
        <BaseBtn
          @click="() => performTypeSelection({ type: 'caregiver' })"
          label="Buy as Caregiver"
        />
        <BaseBtn @click="handleNegativeAnswer" label="Cancel" :colorType="3" />
      </div>
      <div class="padded space-between">
        <div class="button-info" @click="handleNegativeAnswer">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "OrderModePopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
    };
  },
  components: { BaseBtn },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({ orderModePopUp: "posModule/orderModePopUp" }),
  },
  methods: {
    ...mapActions({
      setOrderModePopUp: "posModule/setOrderModePopUp",
    }),
    handleNegativeAnswer: function () {
      this.setOrderModePopUp({ isOpen: false });
    },
    performTypeSelection: function (selection) {
      if (this.orderModePopUp.positiveCallback) {
        this.orderModePopUp.positiveCallback(selection);
      }
      this.setOrderModePopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;
    .button-secondary {
      margin-bottom: 10px;
    }
  }
}

.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  & > button {
    width: 50%;
  }
}
</style>
