<template>
  <div class="mso-supplier-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'msoSuppliers', label: 'MSO Suppliers' },
          {
            name: 'msoSupplierEditor',
            label: selectedMsoSupplier.name,
            query: $route.query,
          },
        ]"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedMsoSupplier"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />
      <div class="section-base">
        <div class="padded elevated-0 bordered">
          <div
            v-for="input in msoSupplierInfoInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="selectedMsoSupplier[input.id]"
              @updateModel="selectedMsoSupplier[input.id] = $event"
              :inputData="input"
              :isEdit="isEdit"
            />
          </div>
        </div>
      </div>
      <EditorSection
        title="Associated Suppliers"
        v-if="selectedMsoSupplier._id !== 'NEW'"
        :bus="bus"
      >
        <SearchTable
          :entity="Entities.SUPPLIER"
          :hideFilters="true"
          :filter="filter"
        />
      </EditorSection>

      <EditorSection
        :title="`About ${selectedMsoSupplier.name || ''}`"
        sectionType="about"
        v-if="selectedMsoSupplier._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedMsoSupplier" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import SearchTable from "@/components/SearchTable";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import {
  msoSupplierInfoInputs,
  defaultSelectedMsoSupplier,
  pageHeader,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "msoSupplierEditor",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      msoSupplierInfoInputs,
      isEdit: false,
      defaultSelectedMsoSupplier,
      msoSuppliers: [],
      msoSuppliersResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.MSOSUPPLIER,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      Entities,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    SearchTable,
  },
  mounted: function () {
    this.setPageTitle("MSOSupplier");
    if (this.$route.query.id) {
      this.getMsoSupplierById({
        _id: this.$route.query.id,
        positiveCallback: (supplier) => {
          this.setPageTitle(`Supplier ${supplier.name}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getMsoSupplierById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      msoSupplier: "msoSupplierModule/msoSupplier",
    }),
    selectedMsoSupplier: {
      get() {
        this.setPageTitle("MSO Supplier " + this.msoSupplier.name);
        return this.msoSupplier;
      },
      set(entity) {
        return entity;
      },
    },
    filter() {
      return {
        msoSupplier: this.id,
      };
    },
  },
  methods: {
    ...mapActions({
      getMsoSupplierById: "msoSupplierModule/getMsoSupplierById",
      saveMsoSupplier: "msoSupplierModule/saveMsoSupplier",
      deleteMsoSupplier: "msoSupplierModule/deleteMsoSupplier",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getMsoSuppliers: "msoSupplierModule/getMsoSuppliers",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedMsoSupplier._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedMsoSupplier._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedMsoSupplier.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedMsoSupplier = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedMsoSupplier[entity] = item;
    },
  },
};
</script>
