var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-of-account-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
        { name: 'home', label: 'Home' },
        { name: 'chartOfAccounts', label: 'Chart of Accounts' },
        {
          name: 'chartOfAccountEditor',
          label: _vm.selectedChartOfAccount.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedChartOfAccount,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); },"menu-option-click":_vm.menuOptionClick}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},_vm._l((_vm.chartOfAccountInfoInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[(_vm.showInput(input))?_c('Input',{attrs:{"model":_vm.getProperty(input.id),"inputData":_vm.getInput(input),"isEdit":_vm.isEdit,"entityObject":_vm.selectedChartOfAccount},on:{"updateModel":function($event){_vm.selectedChartOfAccount[input.id] = $event}}}):_vm._e()],1)}),0)]),(_vm.selectedChartOfAccount._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedChartOfAccount.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedChartOfAccount}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }