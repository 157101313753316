import Constants from "@/resources/Constants";

// Page Header
export const pageHeader = {
  title: "MsoCoupon",
  isEditorView: true,
  cancelPath: "msoCoupons",
  addPath: "msoCouponEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Mso Coupons",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "msoCouponEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    numCharsToSearch: 1,
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  {
    label: "Code",
    code: "code",
    numCharsToSearch: 1,
    field: { type: "input", label: "code" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Code",
    id: "code",
    size: "medium",
  },
  {
    id: "discountPercentage",
    title: "%",
  },
  {
    id: "discountAmount",
    title: "Discount Amount",
  },
  {
    id: "isOneTimeUse",
    type: "checkbox",
    title: "One Time Use",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Deleted",
    id: "isDeleted",
    type: "checkbox",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Code",
    id: "code",
  },
];

export const msoCouponInfoInputs = [
  {
    id: "name",
    placeholder: "Mso Coupon Name",
    type: "input",
    title: "Mso Coupon Name",
    focus: true,
  },
  {
    id: "code",
    placeholder: "Accounting Code",
    type: "input",
    title: "Accounting Code",
  },
  {
    id: "isActive",
    type: "checkBox",
    title: "Active",
  },
  {
    id: "discountPercentage",
    type: "percentage",
    precision: 0,
    title: "Discount Percent %",
  },
  {
    id: "discountAmount",
    type: "input",
    inputType: "number",
    title: "Discount Amount",
  },
  {
    id: "isOneTimeUse",
    type: "checkbox",
    title: "One Time Use",
  },
  {
    id: "isForCannabisOnly",
    type: "checkbox",
    title: "Cannabis Only",
  },
  {
    id: "minimumQuantity",
    type: "money",
    title: "Minimum Quantity",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "maximumQuantity",
    type: "money",
    title: "Maximum Quantity",
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "minSpending",
    type: "money",
    title: "Min Order Spending",
    prefix: '$',
    precision: Constants.numericInput.defaultPrecision,
  },
  {
    id: "customerOnly",
    type: "checkbox",
    title: "Registered Customers Only",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];

export const defaultSelectedMsoCoupon = {
  _id: "NEW",
  code: "",
  name: "",
  isActive: true,
  isArchived: false,
  isDeleted: false,
  discountPercentage: 0,
  discountAmount: 0,
  msoCoupon: undefined,
  startDate: "",
  endDate: "",
  applyDateRange: false,
  isOneTimeUse: false,
  notes: "",
  isForCannabisOnly: false,
  goodFor: "",
  minimumQuantity: 0,
  maximumQuantity: 0,
  minSpending: 0,
  customerOnly: false,
};
