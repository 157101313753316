import Constants from "@/resources/Constants";
import moment from "moment";

export default {
  setTheme: function (classString) {
    document.getElementsByTagName("html")[0].setAttribute("class", classString);
  },
  findElementsInArray: function (value, field, arrayList, isExact) {
    if (value === "" || typeof value !== "string") {
      return arrayList;
    }

    var resultList = [];

    arrayList.forEach((element) => {
      if (element[field] === undefined) return;
      if (isExact) {
        if (element[field] === value) {
          resultList.push(element);
        }
      } else {
        if (
          JSON.stringify(element[field])
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
        ) {
          resultList.push(element);
        }
      }
    });

    return resultList;
  },
  findElementInArray: function (value, field, arrayList) {
    var result;

    if (arrayList === undefined) return result;

    arrayList.forEach((element) => {
      if (element[field] === value) {
        result = element;
      }
    });

    return result;
  },
  isEmployeeConfirmationExpired: function (employee) {
    if (employee === undefined) return true;
    const lastConfirmationFromStorage =
      localStorage.getItem("lastConfirmation");
    if (
      employee.lastConfirmation === undefined &&
      lastConfirmationFromStorage === undefined
    )
      return true;

    if (employee.lastConfirmation === undefined) {
      employee.lastConfirmation = lastConfirmationFromStorage;
    }

    let timeFromLastConfirmation = Date.now() - employee.lastConfirmation;
    timeFromLastConfirmation = Math.trunc(timeFromLastConfirmation / 1000);
    if (
      timeFromLastConfirmation >
      Constants.expirations.employeeConfirmationExpiration
    ) {
      return true;
    }

    return false;
  },
  composeNameAndLastName: function (entity) {
    if (!entity) {
      return "-";
    }

    if (!entity.name && !entity.lastName) {
      return "-";
    }

    const name = entity.name ? entity.name + " " : "- ";
    const lastName = entity.lastName ? entity.lastName : "";

    return name + lastName;
  },
  formatPrice: function (price) {
    if (!price) {
      price = 0;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  },
  formatQuantity: function (quantity) {
    if (!quantity) {
      quantity = 0;
    }
    return quantity.toFixed(2)
  },

  formatWeight: function (weigth, uom = 'g') {
    try {
      if (uom === 'mg') {
        weigth = weigth * 1000
      }
      return weigth.toFixed(2) + uom
    } catch (error) {
      return 0
    }
  },
  formatPercent: function (percent) {
    if (!percent) {
      percent = 0;
    }
    return new Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 2,
    }).format(percent);
  },
  formatDate(date) {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-US");
  },
  formatDateWithHour(date) {
    if (!date) return "";
    const myDate = new Date(date);
    const stringDate = moment(myDate).format("MM/DD/YYYY hh:mm A");
    return stringDate;
  },
  formatReadableDate(date) {
    date = parseInt(date);
    if (!date) return "";
    const myDate = new Date(date);
    const stringDate = moment(myDate).format("MM/DD/YYYY");
    return stringDate;
  },
  formatReadableDateLong(date) {
    date = parseInt(date);
    if (!date) return "";
    const myDate = new Date(date);
    const stringDate = moment(myDate).format("dddd-MM/DD/YYYY");
    return stringDate;
  },
  formatReadableHour(date) {
    date = parseInt(date);
    if (!date) return "";
    const myDate = new Date(date);
    //const stringDate = moment(myDate).format("H");
    const stringDate = moment(myDate).format("hh A");
    return stringDate;
  },
  average(array) {
    return array.reduce((a, b) => a + b) / array.length;
  },
  isInDateRange(date, startDate, endDate) {
    let isValid = true;
    if (startDate) {
      const startDateParsed = new Date(startDate);
      if (startDateParsed > date) {
        isValid = false;
      }
    }

    if (endDate) {
      const endDateParsed = new Date(endDate);
      if (endDateParsed < date) {
        isValid = false;
      }
    }

    return isValid;
  }
};
