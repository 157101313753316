<template>
  <div class="adjustments">
    <BaseModal v-if="isPrintLabelOpen" @toggle-modal="togglePrintLableModal" title="Print Label">
      <div class="label-print-selection">
        <span class="h3 padded">Select a label printer</span>
        <div class="padded left">
          <div v-if="labelPrinters.length > 0" class="printers">
            <BaseBtn v-for="lp in labelPrinters" :key="lp._id" :label="lp.name"
              :colorType="lp._id == selectedLabelPrinter._id ? 5 : undefined" @click="selectedLabelPrinter = lp" />
          </div>
          <div v-else>No label printers for this store</div>
        </div>
        <span class="h3 padded">Number of copies</span>
        <BaseInput placeholder="Insert number of copies" type="number" v-model="printLabelQuantity" :applyFocus="true" />
        <BaseBtn :disabled="!selectedLabelPrinter._id || !printLabelQuantity || printLabelQuantity <= 0"
          class="label-print-selection__action" @click="printLabels" iconGroup="fas" iconName="print" label="Print">
        </BaseBtn>
      </div>
    </BaseModal>

    <div class="padded elevated-0 bordered">
      <div class="
          two-columns-layout
          two-columns-layout--responsive
          two-columns-layout--border-bottom
          right
        ">
        <div class="button-wrapper">
          <div v-if="!isNotEmpty(selectedItem.selectedAdjustment)">
            <BaseBtn @click="() => setSelectedAdjustment()" label="New" iconGroup="fas" iconName="plus" :colorType="0"
              :disabled="!isEdit" />
          </div>
          <div class="button-wrapper" v-if="isNotEmpty(selectedItem.selectedAdjustment)">
            <BaseBtn @click="() => removeSelectedAdjustment()" label="Close" iconGroup="fas" iconName="times"
              :colorType="3" />
            <BaseBtn @click="() => archiveAdjustment(selectedItem.selectedAdjustment._id)
              " :label="selectedItem.selectedAdjustment.isArchived
              ? 'Unarchive'
              : 'Archive'
              " iconGroup="fas" iconName="trash" :colorType="1" :disabled="!isEdit ||
              selectedItem.selectedAdjustment.isPostedToJournal ||
              selectedItem.selectedAdjustment.isPostedToProducts ||
              selectedItem.selectedAdjustment.isPostedToState ||
              selectedItem.selectedAdjustment._id === 'NEW'
              " />
            <BaseBtn @click="() => performSave()" label="Save" iconGroup="fas" iconName="save" :colorType="0" :disabled="!canEdit ||
              selectedItem.selectedAdjustment.isPostedToProducts ||
              selectedItem.selectedAdjustment.isPostedToState
              " />
            <BaseBtn @click="() => performPostAdjustment()" label="Post Adjustment" iconGroup="fas" iconName="paper-plane"
              :colorType="selectedItem.selectedAdjustment.type &&
              (selectedItem.selectedAdjustment.type.code === 'state-and-df' ||
                selectedItem.selectedAdjustment.type.code === 'state-only')
              ? 2
              : 0
              " :disabled="!canEdit ||
              selectedItem.selectedAdjustment._id === 'NEW' ||
              selectedItem.selectedAdjustment.isPostedToProducts ||
              selectedItem.selectedAdjustment.isPostedToState ||
              selectedItem.selectedAdjustment.isArchived
              " />
          </div>
        </div>
      </div>
      <div v-if="isNotEmpty(selectedItem.selectedAdjustment) && isEdit">
        <div>
          <div class="adjustments__inputs">
            <div class="adjustments__totals bordered__highlighted--neutral">
              <h4 class="adjustments__totals__title">Adjustment Totals</h4>
              <Input :model="getValue('selectedAdjustment.room')" @updateModel="(event) => setValue('selectedAdjustment.room', event)"
                :inputData="getInput('selectedAdjustment.room')"
                v-if="selectedItem.selectedAdjustment.isPostedToJournal == false &&
                selectedItem.selectedAdjustment.isPostedToProducts == false &&
                selectedItem.selectedAdjustment.isPostedToState == false"
                :isEdit="canEdit" />

              <Input :model="getValue('selectedAdjustment.room')" @updateModel="(event) => setValue('selectedAdjustment.room', event)"
                :inputData="getInput('selectedAdjustment.room')"
                v-if="selectedItem.selectedAdjustment._id !== 'NEW' &&
                (selectedItem.selectedAdjustment.isPostedToJournal == true ||
                selectedItem.selectedAdjustment.isPostedToProducts == true ||
                selectedItem.selectedAdjustment.isPostedToState == true)" />

              <Input :model="getValue('selectedAdjustment.mode')" @updateModel="(event) => setValue('selectedAdjustment.mode', event)"
                :inputData="getInput('selectedAdjustment.mode')"
                v-if="selectedItem.selectedAdjustment.isPostedToJournal == false &&
                selectedItem.selectedAdjustment.isPostedToProducts == false &&
                selectedItem.selectedAdjustment.isPostedToState == false"
                :isEdit="canEdit" />

              <Input :model="getValue('selectedAdjustment.mode')" @updateModel="(event) => setValue('selectedAdjustment.mode', event)"
                :inputData="getInput('selectedAdjustment.mode')"
                v-if="selectedItem.selectedAdjustment._id !== 'NEW' &&
                (selectedItem.selectedAdjustment.isPostedToJournal == true ||
                selectedItem.selectedAdjustment.isPostedToProducts == true ||
                selectedItem.selectedAdjustment.isPostedToState == true)" />

              <Input :model="getValue('selectedAdjustment.quantity')" @updateModel="(event) => setValue('selectedAdjustment.quantity', event)"
                :inputData="getInput('selectedAdjustment.quantity')"
                v-if="selectedItem.selectedAdjustment.isPostedToJournal == false &&
                selectedItem.selectedAdjustment.isPostedToProducts == false &&
                selectedItem.selectedAdjustment.isPostedToState == false"
                :isEdit="canEdit" />

              <Input :model="getValue('selectedAdjustment.quantity')" @updateModel="(event) => setValue('selectedAdjustment.quantity', event)"
                :inputData="getInput('selectedAdjustment.quantity')"
                v-if="selectedItem.selectedAdjustment._id !== 'NEW' &&
                (selectedItem.selectedAdjustment.isPostedToJournal == true ||
                selectedItem.selectedAdjustment.isPostedToProducts == true ||
                selectedItem.selectedAdjustment.isPostedToState == true)" />

              <Input :model="getValue('actualUnitCost')" @updateModel="(event) => setValue('actualUnitCost', event)"
                :inputData="getInput('actualUnitCost')" :isEdit="canEdit" />
              <Input :model="getValue('adjustmentTotal')" @updateModel="(event) => setValue('adjustmentTotal', event)"
                :inputData="getInput('adjustmentTotal')" :isEdit="canEdit" />
              <hr />
            </div>
            <div>
              <div class="adjustments__row">
                <Input :model="getValue('selectedAdjustment.isArchived')" @updateModel="(event) => setValue('selectedAdjustment.isArchived', event)
                  " :inputData="getInput('selectedAdjustment.isArchived')" :isEdit="canEdit" />
                <Input :model="getValue('selectedAdjustment.isPostedToState')" @updateModel="(event) =>
                  setValue('selectedAdjustment.isPostedToState', event)
                  " :inputData="getInput('selectedAdjustment.isPostedToState')" :isEdit="canEdit" />
                <Input :model="getValue('selectedAdjustment.isPostedToProducts')" @updateModel="(event) =>
                  setValue('selectedAdjustment.isPostedToProducts', event)
                  " :inputData="getInput('selectedAdjustment.isPostedToProducts')" :isEdit="canEdit" />
                <Input :model="getValue('selectedAdjustment.isPostedToJournal')" @updateModel="(event) =>
                  setValue('selectedAdjustment.isPostedToJournal', event)
                  " :inputData="getInput('selectedAdjustment.isPostedToJournal')" :isEdit="canEdit" />
              </div>
              <Input :model="getValue('selectedAdjustment.type')" @updateModel="(event) => setValue('selectedAdjustment.type', event)
                " :inputData="getInput('selectedAdjustment.type')" :isEdit="canEdit" />
              <Input :model="getValue('selectedAdjustment.reason')" @updateModel="(event) => setValue('selectedAdjustment.reason', event)
                " :inputData="getInput('selectedAdjustment.reason')" :isEdit="canEdit" />

              <Input v-show="isStoreTransfer" :model="getValue('selectedAdjustment.manifest')" @updateModel="(event) => setValue('selectedAdjustment.manifest', event)
                " :inputData="getInput('selectedAdjustment.manifest')" :isEdit="true" />
              <Input v-show="isStoreTransfer" :model="getValue('selectedAdjustment.batchSubLot')" @updateModel="(event) => setValue('selectedAdjustment.batchSubLot', event)
                " :inputData="getInput('selectedAdjustment.batchSubLot')" :isEdit="true" />
              <Input v-show="isStoreTransfer" :model="getValue('selectedAdjustment.customer')"
                @updateModel="(event) => setValue('selectedAdjustment.customer', event)"
                :inputData="getInput('selectedAdjustment.customer')" :isEdit="true" />
            </div>
          </div>
          <div>
            <Input :model="getValue('selectedAdjustment.stateNotes')"
              @updateModel="(event) => setValue('selectedAdjustment.stateNotes', event)"
              :inputData="getInput('selectedAdjustment.stateNotes')" :isEdit="canEdit" />
            <Input :model="getValue('selectedAdjustment.detailedDescription')" @updateModel="(event) =>
              setValue('selectedAdjustment.detailedDescription', event)
              " :inputData="getInput('selectedAdjustment.detailedDescription')" :isEdit="canEdit" />
          </div>
        </div>
        <About :embedded="true" v-if="selectedItem.selectedAdjustment._id !== 'NEW'"
          :entity="selectedItem.selectedAdjustment" />
      </div>
    </div>
    <br />
    <div v-if="selectedItem.adjustments && selectedItem.adjustments.length > 0" class="table-container bordered">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Archived</th>
            <th class="cell">Posted to State</th>
            <th class="cell">Posted to Products</th>
            <th class="cell">Posted to Journal</th>
            <th class="cell">Mode</th>
            <th class="cell">Quantity</th>
            <th class="cell">Type</th>
            <th class="cell">Room</th>
            <th class="cell">Reason</th>
            <th class="cell">Customer</th>
            <th class="cell">Sub Lot Batch#</th>
            <th class="cell">Created At</th>
            <th class="cell">Id</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" adjustment  in  selectedItem.adjustments " :key="adjustment._id" class="row">
            <td class="cell">
              <inline-button label="Open" type="plain-primary responsive-column form-height wide"
                @click="() => setSelectedAdjustment(adjustment)" :disabled="!isEdit" />
              <inline-button v-if="isPostedTransferAdjusment(adjustment)" label="Print" style="margin-left: .5rem"
                type="plain-primary responsive-column form-height wide" @click="onPrintHandler(adjustment)"
                :disabled="!isEdit"><i class="fas fa-print"></i></inline-button>
            </td>

            <td class="cell">
              <check-mark :toggleVariable="adjustment.isArchived" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="adjustment.isPostedToState" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="adjustment.isPostedToProducts" />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="adjustment.isPostedToJournal" />
            </td>
            <td class="cell">
              {{ adjustment.mode ? adjustment.mode.label : "-" }}
            </td>
            <td class="cell">{{ adjustment.quantity }}</td>
            <td class="cell">
              {{ adjustment.type ? adjustment.type.label : "" }}
            </td>
            <td class="cell">
              {{ adjustment.room.name || adjustment.room.label }}
            </td>
            <td class="cell">
              {{ adjustment.reason != "" ? adjustment.reason.label : "-" }}
            </td>
            <td class="cell">
              {{ adjustment.customer && adjustment.customer.name ? adjustment.customer.name : "-" }}
            </td>
            <td class="cell">
              {{ adjustment.batchSubLot ? adjustment.batchSubLot : "-" }}
            </td>
            <td class="cell">
              {{
                moment(adjustment.createdAt).format(
                  Constants.dates.defaultDateFormat
                )
              }}
            </td>
            <td class="cell">
              <span>{{ adjustment._id }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import BaseInput from "@/components/Base/Input/Base.vue";

import Constants from "@/resources/Constants";
import Entities from "@/resources/Entities";
import Utilities from "@/lib/Utilities";

import { prefillEntityBeforeRendering } from "@/utils/prefill-entity-before-rendering/item";
import { normalizeEntityBeforeSaving } from "@/utils/normalize-entity-before-saving/item";
import { adjustmentsInputs } from "./inputs";

export default {
  name: "Adjustments",
  data() {
    return {
      Constants,
      moment,
      Utilities,
      Entities,
      adjustmentsInputs,
      isPrintLabelOpen: false,
      adjustmentToPrint: {},
      selectedLabelPrinter: {},
      printLabelQuantity: 1
    };
  },
  components: {
    Input,
    BaseBtn,
    About,
    BaseModal,
    BaseInput
  },
  props: {
    isEdit: {
      type: Boolean,
    },
    rooms: {
      type: Array,
      default: () => ([])
    }
  },
  mounted: function () {
  },
  computed: {
    ...mapGetters({
      item: "itemModule/item",
      currentStore: "storeModule/currentStore",
    }),
    isStoreTransfer() {
      return this.selectedItem.selectedAdjustment.reason?.code == 8
    },
    labelPrinters() {
      return this.currentStore.labelPrinters
    },
    selectedItem: {
      get() {
        return prefillEntityBeforeRendering(this.item);
      },
      set(entity) {
        return entity;
      },
    },
    adjustmentTotal() {
      return (
        this.selectedItem.actualUnitCost *
        this.selectedItem.selectedAdjustment.quantity
      );
    },
    canEdit() {
      return this.isEdit && !this.selectedItem?.selectedAdjustment?.isArchived;
    },
    adjustmentsInputOptionsNormalized() {
      const inputs = this.adjustmentsInputs;
      let filteredAdjustments = [...Constants.listOfAdjustmentTypes];
      if (!this.isCannabis) {
        filteredAdjustments = Constants.listOfAdjustmentTypes.filter(
          (ad) => ad.cannabisOnly == false
        );
      }
      inputs[1].options = filteredAdjustments;
      const traceabilitySystem = this.currentStore?.traceabilitySystem
      const actualTraceabilitySystem =
        traceabilitySystem ||
        "biotrack";
      let filteredAdjustmentReasons = Constants.listOfAdjustmentReasons.filter(
        (el) => el.traceabilitySystem == actualTraceabilitySystem
      );
      inputs[2].options = filteredAdjustmentReasons;
      return inputs;
    },
    isCannabis() {
      if (this.selectedItem.batchType !== "medical") return true;
      else return false;
    },
  },
  methods: {
    ...mapActions({
      setMessagePopUp: "setMessagePopUp",
      setItem: "itemModule/setItem",
      saveItem: "itemModule/saveItem",
      loadingStatus: "itemModule/loadingStatus",
      getItemById: "itemModule/getItemById",
      printStoreTransferLabel: "itemModule/printStoreTransferLabel",
    }),
    printLabels() {
      const payload = {
        id: this.selectedItem._id,
        adjustment: this.adjustmentToPrint,
        labelPrinter: this.selectedLabelPrinter,
        printLabelQuantity: this.printLabelQuantity
      }
      this.printStoreTransferLabel(payload)
    },
    togglePrintLableModal(event) {
      const isOpen = event.isOpen;
      this.isPrintLabelOpen = isOpen;

      if (!isOpen) {
        this.adjustmentToPrint = {}
      }
    },
    onPrintHandler(adjustment) {
      this.isPrintLabelOpen = true;
      this.adjustmentToPrint = adjustment
    },
    isPostedTransferAdjusment(adjustment) {
      return adjustment.reason.code == 8 && adjustment.isPostedToJournal
    },
    getInput(field) {
      const input = this.adjustmentsInputOptionsNormalized.find((i) => i.id == field);
      if (field == "selectedAdjustment.room") {
        input.options = this.rooms
      }
      return input
    },
    getValue(field) {
      if (field === "adjustmentTotal") {
        return this.adjustmentTotal;
      }
      return _.get(this.selectedItem, field);
    },
    setValue(field, value) {
      _.set(this.selectedItem, field, value);
    },
    isNotEmpty(item) {
      return !_.isEmpty(item);
    },
    clearItemFromSearch: function (entity) {
      if (entity == 'customer') {
        _.set(this.selectedItem, 'selectedAdjustment.customer', {});
      } else {
        this.selectedItem[entity] = null;
      }
    },
    setSelectedAdjustment(adjustment) {
      const NEW_ADJUSTMNET = {
        _id: "NEW",
        isPostedToState: false,
        isPostedToProducts: false,
        isPostedToJournal: false,
        isArchived: false,
        stateNotes: "",
        quantity: 0,
        availableQuantity: 0,
        detailedDescription: "",
        mode: { label: "Add", code: "add" },
        type: { label: "DF Only", code: "df-only" },
        reason: { label: "General Inventory Audit", code: "1" },
        customer: {},
        manifest: '',
        batchSubLot: ''
      };
      this.$set(this.selectedItem, "selectedDisposal", undefined);
      this.$set(this.selectedItem, "selectedReturn", undefined);
      if (!adjustment) {
        adjustment = NEW_ADJUSTMNET
      } else {
        if (!adjustment.room.label) {
          adjustment.room = this.rooms.find(r => r.code == adjustment.room._id)
        }
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#adjustment").offset().top,
          },
          300
        );
      }
      this.$set(this.selectedItem, "selectedAdjustment", adjustment);
    },
    archiveAdjustment() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.warningMessageTitle,
        message: this.selectedItem.selectedAdjustment.isArchived
          ? Constants.strings.unArchiveConfirmation
          : Constants.strings.archiveConfirmation,
        positiveCallback: () => {
          this.selectedItem.archiveAdjustment = true;
          this.performSave();
        },
      });
    },

    removeSelectedAdjustment() {
      this.selectedItem.selectedAdjustment = {};
    },
    performPostAdjustment() {
      if (
        this.selectedItem.selectedAdjustment.type.code === "state-and-df" ||
        this.selectedItem.selectedAdjustment.type.code === "state-only"
      ) {
        this.setMessagePopUp({
          isOpen: true,
          title: Constants.strings.warningMessageTitle,
          message: Constants.strings.stateCommunicationConfirmation,
          positiveCallback: () => {
            this.selectedItem.postAdjustment = true;
            this.performSave();
          },
        });
      } else {
        this.selectedItem.postAdjustment = true;
        this.performSave();
      }
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      } else {
        this.selectedItem[entity] = item;
      }
    },
    performSave(type) {
      if (this.loadingStatus.saveItem) {
        return;
      }
      const itemToSave = normalizeEntityBeforeSaving(this.selectedItem);
      this.saveItem({
        item: itemToSave,
        positiveCallback: (item) => {
          const id = item.id ? item.id : item._id;
          this.setItem(item);
          const module =
            this.entity !== Entities.PRODUCT
              ? `${this.entity}s`
              : `${this.entity}`;
          if (this.$route.query.id !== id) {
            this.$router.push(`/${module}/${this.entity}Editor?id=${id}`);
          }
          this.$store.dispatch(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (!type?.archived) this.$emit("toggle-edit");
        },
        negativeCallback: () => {
          this.selectedItem.postAdjustment = false;
          //if (type && type.postAdjustment) {
          if (type && type.postDisposal) {
            this.getItemById({
              _id: this.$route.query.id,
              positiveCallback: (item) => {
                this.setPageTitle(
                  `Item ${item.batchId} - ${item.product?.name}`
                );
              },
            });
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_adjustments.scss";
</style>
