var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"room-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"selectedEntity":_vm.selectedRoom,"links":[
      { name: 'home', label: 'Home' },
      { name: 'rooms', label: 'Rooms' },
      { name: 'roomEditor', label: _vm.selectedRoom.name, query: _vm.$route.query } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"entity":_vm.entity},on:{"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},[_c('div',{staticClass:"\n              grid-two-column\n              two-columns-layout--responsive-horizontal-space-10\n            "},_vm._l((_vm.roomInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedRoom[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedRoom[input.id] = $event}}})],1)}),0)])])]),_c('EditorSection',{attrs:{"title":"Items","sectionType":"items","isTable":true,"bus":_vm.bus,"defaultIsOpen":true}},[_c('table',{staticClass:"table-element",attrs:{"cellspacing":"0"}},[_c('thead',[_c('tr',{staticClass:"header"},[_c('th',{staticClass:"cell"},[_vm._v("Batch ID")]),_c('th',{staticClass:"cell"},[_vm._v("Product")]),_c('th',{staticClass:"cell"},[_vm._v(" Quantity ")])])]),_c('tbody',_vm._l((_vm.selectedRoom.items),function(item){return _c('tr',{key:item._id,staticClass:"row"},[_c('td',{staticClass:"cell"},[_c('div',{staticClass:"\n                        space-between\n                        space-between--align-items-center\n                        space-between--not-responsive\n                      "},[_c('router-link',{staticClass:"internal-link",attrs:{"target":"_blank","to":{
                  name: 'itemEditor',
                  query: { id: item._id },
                }}},[_vm._v(" "+_vm._s(item.batchId || item._id)+" ")])],1)]),_c('td',{staticClass:"cell"},[_c('div',{staticClass:"\n                        space-between\n                        space-between--align-items-center\n                        space-between--not-responsive\n                      "},[_c('router-link',{staticClass:"internal-link",attrs:{"target":"_blank","to":{
                  name: 'productEditor',
                  query: { id: item.externalFields.product._id },
                }}},[_vm._v(" "+_vm._s(item.externalFields.product.name)+" ")])],1)]),_c('td',{staticClass:"cell"},[_c('span',[_vm._v(_vm._s(item.rooms.find(function (r) { return r.room == _vm.selectedRoom._id; }).quantity))])])])}),0)])]),(_vm.selectedRoom._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedRoom.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedRoom}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }