<template>
  <div
    class="blank-link-icon"
    :class="{
      'blank-link-icon--left-margin': hasLeftMargin,
      'blank-link-icon--right-margin': hasRightMargin,
    }"
  >
    <i class="fas fa-external-link-alt"></i>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "BlankLinkIcon",
  data() {
    return {};
  },
  props: {
    hasLeftMargin: {
      type: Boolean,
      default: true,
    },
    hasRightMargin: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "./_blankLinkIcon.scss";
</style>
