import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

export const DEFAULT_SCHEMA_INVENTORY_COUNT = {
  _id: "NEW",
  createdAt: new Date(),
  createdBy: null,
  updatedBy: null,
  closed: false,
  closedAt: null,
  closedBy: null,
  checkedMissingBatches: false,
  compareTo: {
    id: null,
    name: null
  },
  employees: []
};

const state = {
  inventoryCounts: [],
  inventoryCount: { ...DEFAULT_SCHEMA_INVENTORY_COUNT },
  getFormattedInventoryCount: { ...DEFAULT_SCHEMA_INVENTORY_COUNT },
  workstationWithActivty: [],
  loadingStatus: {
    getCompareToOptions: false,
    checkMissingBatches: false,
    compareInventoryCount: false,
    recalculateCompareInventoryCount: false
  }
};

const mutations = {
  setInventoryCounts: (state, payload) => {
    state.inventoryCounts = payload;
  },
  setInventoryCount: (state, payload) => {
    if (payload._id == "NEW") {
      state.inventoryCount = { ...DEFAULT_SCHEMA_INVENTORY_COUNT };
    } else {
      state.inventoryCount = payload;
    }
  },
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setViewAllCompleted: (state, value) => {
    state.viewAllCompleted = value;
  }
};

const getters = {
  getInventoryCounts: (state) => state.inventoryCounts,
  getInventoryCount: (state) => state.inventoryCount,
  loadingStatus: (state) => state.loadingStatus,
};

const actions = {
  setViewAllCompleted: ({ commit }, value) => {
    commit('setViewAllCompleted', value)
  },
  saveInventoryCount: ({ commit, state }, payload) => {
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.save,
      payload,
      function (response, error) {
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getInventoryCountById: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl +
        Apis.inventoryCount.getinventoryCountById +
        "?id=" +
        payload._id,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setInventoryCount", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  getStationsWithActivity: ({ commit, state }, payload) => {
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl +
        Apis.inventoryCount.getStationsWithActivity,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit("setWorkstationsWithActivity", data);
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  setInventoryCount: ({ commit, state }, payload) => {
    commit("setInventoryCount", payload);
  },
  getItemsByBarcode: ({ commit, state }, { barcode, isLast4Digits }) => {
    return new Promise((resolve, reject) => {
      const inventoryCountId = state.inventoryCount._id
      HttpService.get(
        Apis.backendBaseUrl +
        Apis.inventoryCount.getItemsByBarcode +
        `?barcode=${barcode.trim()}&isLast4Digits=${isLast4Digits}&inventoryCountId=${inventoryCountId}`,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  updateInventoryCountList: ({ commit, state }, payload) => {
    const inventoryCountId = state.inventoryCount._id;
    const { itemId, handQuantity } = payload
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.updateInventoryCountList,
      { inventoryCountId, itemId, handQuantity },
      function (response, error) {
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  compareInventoryCount: ({ commit, state }, employeeId) => {
    const inventoryCountId = state.inventoryCount._id;
    commit("setLoadingStatus", { api: "compareInventoryCount", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.compareInventoryCount,
      { inventoryCountId, employeeId },
      function (response, error) {
        commit("setLoadingStatus", { api: "compareInventoryCount", status: false });
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  recalculateCompareInventoryCount: ({ commit, state }, employeeId) => {
    const inventoryCountId = state.inventoryCount._id;
    commit("setLoadingStatus", { api: "recalculateCompareInventoryCount", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.recalculateCompareInventoryCount,
      { inventoryCountId, employeeId },
      function (response, error) {
        commit("setLoadingStatus", { api: "recalculateCompareInventoryCount", status: false });
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit(
            "setMessage",
            { text: "Recalculated", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  confirmInventoryCount: ({ commit, state }) => {
    const inventoryCountId = state.inventoryCount._id;
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.confirmInventoryCount,
      { inventoryCountId },
      function (response, error) {
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  checkMissingBatches: ({ commit, state }) => {
    const inventoryCountId = state.inventoryCount._id;
    commit("setLoadingStatus", { api: "checkMissingBatches", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.checkMissingBatches,
      { inventoryCountId },
      function (response, error) {
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit("setLoadingStatus", { api: "checkMissingBatches", status: false });
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          commit("setLoadingStatus", { api: "checkMissingBatches", status: false });
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  closeInventoryCount: ({ commit, state }) => {
    const inventoryCountId = state.inventoryCount._id;
    HttpService.post(
      Apis.backendBaseUrl + Apis.inventoryCount.closeInventoryCount,
      { inventoryCountId },
      function (response, error) {
        if (response) {
          const data = response.data.body;
          commit('setInventoryCount', data)
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getCompareToOptions: ({ commit, state }) => {
    commit("setLoadingStatus", { api: "getCompareToOptions", status: true });
    return new Promise((resolve, reject) => {
      HttpService.get(
        Apis.backendBaseUrl +
        Apis.inventoryCount.getCompareToOptions,
        function (response, error) {
          commit("setLoadingStatus", { api: "getCompareToOptions", status: false });
          if (response) {
            const data = response.data.body;
            resolve(data);
          } else {
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
            reject(error);
            console.log(error);
          }
        }
      );
    });
  },
  setCompareTo: ({ commit, state }, payload) => {
    const body = {
      compareTo: payload,
      inventoryCountId: state.inventoryCount._id
    }
    return new Promise((resolve, reject) => {
      HttpService.post(
        Apis.backendBaseUrl + Apis.inventoryCount.setCompareTo,
        body,
        function (response, error) {
          if (response) {
            const data = response.data.body;
            commit('setInventoryCount', data)
            commit(
              "setMessage",
              { text: "Saved", type: "success" },
              { root: true }
            );
            resolve(response)
          } else {
            console.log(error);
            reject(error)
            commit(
              "setMessage",
              { text: error.message, type: "error" },
              { root: true }
            );
          }
        }
      );
    })

  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
