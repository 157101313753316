<template>
  <div class="inventory-count-editor">
    <BaseModal
      v-if="isPrintBatchModalOpen"
      @toggle-modal="toggleBatchModal"
      title=""
      :autoWidth="true"
    >
      <Print 
        :item="itemToPrint" 
        :previousSelectedLabelPrinter="selectedLabelPrinter"
        @printerSelected="setLabelPrinter"
        @closePrintModal="toggleBatchModal"
        />
    </BaseModal>
    <BaseModal
      v-if="isCounterPopUpOpen"
      @toggle-modal="togglePopUpMessage"
      title=""
      :autoWidth="true"
    >
      <Counter
        :items="itemsFound"
        @update="counterUpdateHandler"
        :allItems="items"
        :viewAll="viewAll"
      />
    </BaseModal>
    <div v-if="isLoading" class="loading">Loading...</div>
    <CompareTo v-else-if="!isCompareToSet" />
    <div class="container" v-else>
      <InventoryCountHeader
        :inventoryCount="inventoryCount"
        @on-items="itemsScannedHandler"
        :viewAll="viewAll"
        @onViewAll="viewAllHandler"
        :status="status"
        :canBeClosed="canBeClosed"
        :items="items"
        :canCheckMissingBatches="canCheckMissingBatches"
        ref="inventoryCountHeader"
      />
      <div class="inventory-count-search" v-if="hasItems">
        <BaseInput
          label="Batch #"
          class="inventory-search"
          placeholder="Batch #"
          controlType="text"
          v-model="search"
        />
      </div>
      <InventoryCountBody
        :key="viewAll"
        :items="items"
        :search="search"
        :viewAll="viewAll"
        @editRecord="editRecordHandler"
        @printBatch="printBatchHandler"
        :inventoryCount="formattedInventoryCount"
      />
    </div>
  </div>
</template>

<script>
import InventoryCountHeader from "./components/Header/Header.vue";
import InventoryCountBody from "./components/Body/Body.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";
import Counter from "./components/Counter/Counter.vue";
import CompareTo from "./components/CompareTo/CompareTo.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import Print from './components/Print/Print.vue'
import { mapActions, mapGetters } from "vuex";
import { managementRoles } from "@/constants/roles";

const STATUS = {
  NOT_STARTED: "Not Started",
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  CLOSED: "Finished",
};

export default {
  components: {
    BaseModal,
    Counter,
    InventoryCountHeader,
    InventoryCountBody,
    CompareTo,
    BaseInput,
    Print
  },
  data() {
    return {
      isCounterPopUpOpen: false,
      isPrintBatchModalOpen: false,
      itemsFound: [],
      viewAll: false,
      isLoading: true,
      managementRoles,
      search: "",
      itemToPrint: null,
      selectedLabelPrinter: undefined
    };
  },
  computed: {
    ...mapGetters({
      getInventoryCount: "inventoryCountModule/getInventoryCount",
      getUser: "loginModule/user",
      role: "loginModule/role",
    }),
    hasItems() {
      return Object.entries(this.items).length > 0;
    },
    canBeClosed() {
      if (this.inventoryCount.isClosed) return false;
      return this.status == STATUS.COMPLETED;
    },
    canCheckMissingBatches() {
      return !this.inventoryCount.checkedMissingBatches;
    },
    userId() {
      return this.getUser._id;
    },
    status() {
      if (this.inventoryCount.isClosed) return STATUS.CLOSED;
      const employees = this.inventoryCount.employees;
      if (employees.length == 0) return STATUS.NOT_STARTED;

      const notCompleted = employees.some((e) => e.countCompleted == false);
      return notCompleted ? STATUS.IN_PROGRESS : STATUS.COMPLETED;
    },
    isNew() {
      return this.inventoryCount._id == "NEW";
    },
    inventoryCount() {
      return this.getInventoryCount;
    },
    isCompareToSet() {
      return (
        this.inventoryCount.compareTo.id != null &&
        this.inventoryCount.compareTo.id != undefined
      );
    },
    totDifferences() {
      if (!this.inventoryCount.isClosed) return 0;
      return this.items.reduce(function (acc, obj) {
        return acc + (obj.handQuantity - obj.currentValue);
      }, 0);
    },
    formattedInventoryCount() {
      // if inventory count has been closed, show all
      if (this.inventoryCount.isClosed) {
        return this.inventoryCount;
      }

      // this is temporary stage where only the managers can see the confirmed counts done by other employees
      if (this.viewAll) {
        const employees = this.inventoryCount.employees.filter(
          (e) => e.countCompleted || e.employee._id == this.getUser._id
        );
        return {
          ...this.inventoryCount,
          employees: employees,
        };
      }
      // just show the current employee status
      const employees = this.inventoryCount.employees.filter(
        (e) => e.employee._id == this.userId
      );
      return {
        ...this.inventoryCount,
        employees: employees,
      };
    },
    items() {
      let itemsMap = {};
      for (let i = 0; i < this.formattedInventoryCount.employees.length; i++) {
        const employee = this.formattedInventoryCount.employees[i];
        const itemsInE = employee.items;
        if (!itemsInE) continue;
        for (let j = 0; j < itemsInE.length; j++) {
          const item = itemsInE[j].item;
          const id = item._id;
          if (itemsMap[id]) {
            itemsMap[id].employees.push(
              this.getEmployeeInfo(employee, itemsInE[j].handQuantity)
            );
          } else {
            item.employees = [
              this.getEmployeeInfo(
                employee,
                itemsInE[j].handQuantity,
                itemsInE[j].currentValue
              ),
            ];
            itemsMap[id] = item;
          }
        }
      }

      const items = Object.values(itemsMap);
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        item.difference = 0;
        let differenceTot = 0;
        const employees = items[i].employees;
        if (!employees) continue;
        for (let j = 0; j < employees.length; j++) {
          const employee = employees[j];
          const handQuantity = employee.handQuantity || 0;
          const currentValue = employee.currentValue || 0;
          const difference = handQuantity - currentValue;
          differenceTot += difference;
        }
        item.difference = differenceTot;
      }
      items.sort((a, b) => {
        if (a.difference == 0) return 1;
        if (b.difference == 0) return -1;
        return a.difference - b.difference;
      });
      return items;
    },
    isManagerRole() {
      return this.managementRoles.includes(this.role);
    },
  },
  methods: {
    ...mapActions({
      updateInventoryCountList: "inventoryCountModule/updateInventoryCountList",
      getInventoryCountById: "inventoryCountModule/getInventoryCountById",
    }),
    setCursor() {
      this.$refs.inventoryCountHeader.setCounter();
    },
    getEmployeeInfo(employee, handQuantity, currentValue) {
      return {
        _id: employee.employee._id,
        name: employee.employee.name,
        status: this.getEmployeeStatus(employee),
        handQuantity: handQuantity,
        currentValue: currentValue,
        countConfirmed: employee.countConfirmed,
        countCompleted: employee.countCompleted,
      };
    },
    getEmployeeStatus(employee) {
      if (!employee.items || employee.items.length == 0)
        return STATUS.NOT_STARTED;
      if (!employee.countCompleted) return STATUS.IN_PROGRESS;
      return STATUS.COMPLETED;
    },
    togglePopUpMessage(event) {
      this.resetCounter();
    },
    itemsScannedHandler(items) {
      this.itemsFound = items;
      this.isCounterPopUpOpen = true;
    },
    counterUpdateHandler({ itemId, handQuantity }) {
      this.updateInventoryCountList({ itemId, handQuantity }).then((res) => {
        this.resetCounter();
      });
      this.setCursor();
    },
    toggleBatchModal() {
      this.isPrintBatchModalOpen = false;
      this.itemToPrint = null;
    },
    printBatchHandler(item) {
      this.itemToPrint = item;
      this.isPrintBatchModalOpen = true;
    },
    editRecordHandler(item) {
      this.itemsFound = [item];
      this.isCounterPopUpOpen = true;
    },
    setLabelPrinter(printer) {
      this.selectedLabelPrinter = printer;
    },
    resetCounter() {
      this.isCounterPopUpOpen = false;
      this.itemsFound = [];
    },
    viewAllHandler() {
      this.viewAll = !this.viewAll;
    },
  },
  async beforeMount() {
    const id = this.$route.query.id;
    if (!id || id == "NEW") {
      this.isLoading = false;
      return;
    }

    await this.getInventoryCountById({ _id: id });
    if (this.isManagerRole && this.canBeClosed) {
      this.viewAll = true;
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "./inventoryCountEditor";
</style>
