// Page Header
export const listPageHeader = {
  title: "Inventory Count",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "InventoryCountEditor",
};

export const defaultSelectedInventoryCount = {
  _id: "NEW",
  createdAt: null,
  createdBy: null,
  items: []
};

export const filterCriteria = [
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    position: "sticky",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    id: "createdAt",
    placeholder: "Created Date",
    type: "date-extended",
    title: "Created Date",
  },
  {
    id: "closedAt",
    placeholder: "Closed Date",
    type: "date-extended",
    title: "Closed Date",
  },
  {
    title: "Created By",
    id: "createdBy.name",
    nestedId: "createdBy.name",
  },
  {
    id: "isClosed",
    checkboxLabel: "Closed",
    title: "Closed",
    type: "checkbox",
  },
];