<template>
  <div id="recreational-order-type-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div center bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Order Type</span> <br />
      <span class="text-color-1"
        >Specify if it is an In State or Out of State order.</span
      >
      <br />
      <div class="padded button-wrapper">
        <BaseBtn
          @click="() => performTypeSelection({ type: 'in-state' })"
          label="In State"
        />
        <BaseBtn
          @click="() => performTypeSelection({ type: 'out-of-state' })"
          label="Out Of State"
        />
        <BaseBtn @click="handleNegativeAnswer" label="Cancel" :colorType="3" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";
import BaseBtn from "@/components/Base/Button/Button.vue";

export default {
  name: "RecreationalOrderTypePopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
    };
  },
  components: { BaseBtn },
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      recreationalOrderTypePopUp: "posModule/recreationalOrderTypePopUp",
    }),
  },
  methods: {
    ...mapActions({
      setRecreationalOrderTypePopUp: "posModule/setRecreationalOrderTypePopUp",
    }),
    handleNegativeAnswer: function () {
      this.setRecreationalOrderTypePopUp({ isOpen: false });
    },
    performTypeSelection: function (selection) {
      if (this.recreationalOrderTypePopUp.positiveCallback) {
        this.recreationalOrderTypePopUp.positiveCallback(selection);
      }
      this.setRecreationalOrderTypePopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  & > button {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;
    .button-secondary {
      margin-bottom: 10px;
    }
  }
}
</style>
