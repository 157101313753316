// Page Header
export const pageHeader = {
  title: "Payment",
  isEditorView: true,
  cancelPath: "payments",
  addPath: "paymentEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Payments",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "paymentEditor",
};

export const filterCriteria = [
  {
    label: "Order",
    code: "order",
    field: { type: "input", label: "Order" },
  },
  {
    label: "Id",
    code: "_id",
    field: { type: "input", label: "Id" },
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted To Journal",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Voided",
    id: "isVoided",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Date",
    id: "postedToJournalAt",
    type: "date",
  },
  {
    title: "Type",
    id: "type",
    size: "large",
  },
  {
    title: "Amount Paid",
    id: "amountPaid",
    type: "money",
    size: "medium",
  },
  {
    title: "Order",
    id: "order",
    link: true,
    url: "/orders/orderEditor?id=",
    urlId: "order",
  },
  {
    title: "Customer Name",
    id: "customer.name",
    size: "large",
  },
  {
    title: "Last name",
    id: "customer.lastName",
    size: "large",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const paymentInfoInputs = [
  {
    inputs: [
      {
        id: "customer",
        placeholder: "Customer Name",
        type: "name",
        title: "Customer Name",
      },
      {
        id: "customer",
        type: "link",
        to: {
          name: "customerEditor",
          valueKey: "_id",
        },
        target: "_blank",
        title: "Customer",
      },
      {
        id: "amountPaid",
        placeholder: "Amount Paid",
        type: "money",
        title: "Amount Paid",
      },
      {
        id: "type",
        placeholder: "Type",
        title: "Type",
      },
      {
        id: "isPostedToJournal",
        title: "Posted to Journal",
        type: "checkbox",
      },
      {
        id: "postedToJournalByWorkstation",
        title: "Posted to Journal By Station",
      },
      {
        id: "order",
        nestedId: 'order._id',
        type: "link",
        to: {
          name: "orderEditor",
          valueKey: "_id",
        },
        target: "_blank",
        title: "Order Id",
      },
      {
        id: "isVoided",
        title: "Voided",
        type: "checkbox",
      },
      {
        id: "voidedByWorkstation",
        title: "Voided By Station",
      },
      {
        id: "isArchived",
        title: "Archived",
        type: "checkbox",
      },
      {
        id: "accountNo",
        title: "Account Number",
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

export const gitftCardInputs = [
  {
    id: "giftCard",
    type: "link",
    to: {
      name: "giftCardEditor",
      valueKey: "_id",
    },
    target: "_blank",
    title: "Gift Card",
  },
  {
    id: "giftCard.memoBalance",
    placeholder: "Balance",
    type: "money",
    title: "Balance",
  },
  {
    id: "giftCard.memoAmountUsed",
    placeholder: "Amount Used",
    type: "money",
    title: "Amount Used",
  },
  {
    id: "giftCard.memoTotal",
    placeholder: "Total",
    type: "money",
    title: "Total",
  },
];

export const creditMemoInputs = [
  {
    id: "creditMemo",
    type: "link",
    to: {
      name: "creditMemoEditor",
      valueKey: "_id",
    },
    target: "_blank",
    title: "Credit Memo",
  },
  {
    id: "creditMemo.memoBalance",
    placeholder: "Balance",
    type: "money",
    title: "Balance",
  },
  {
    id: "creditMemo.memoAmountUsed",
    placeholder: "Amount Used",
    type: "money",
    title: "Amount Used",
  },
  {
    id: "creditMemo.memoTotal",
    placeholder: "Total",
    type: "money",
    title: "Total",
  },
];

export const defaultSelectedPayment = {
  _id: "NEW",
  name: "",
  nameCode: "",
  isDeleted: false,
  notes: "",
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
  isSupplier: true,
};
