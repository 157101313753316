import _ from "lodash";

export const validateReceive = (item, purchaseOrder, options) => {
  let validationResult = { succeeded: true, message: "" };

  if (!item.product || !item.product._id) {
    validationResult.message = "Product missing.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (
    _.get(item, "product.externalFields.group.isCannabis") &&
    (item.batchId === "" || !item.batchId)
  ) {
    validationResult.message = "Batch Number missing.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (
    _.get(item, "product.externalFields.group.isCannabis") &&
    purchaseOrder.supplier &&
    purchaseOrder.supplier.srn === ""
  ) {
    validationResult.message = "Supplier or Supplier SRN missing.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (
    _.get(item, "product.externalFields.group.isCannabis") &&
    purchaseOrder.supplierAgent &&
    purchaseOrder.supplierAgent.srn === ""
  ) {
    validationResult.message = "Supplier Agent or Supplier Agent SRN missing.";
    validationResult.succeeded = false;
    return validationResult;
  }

  const adultUseInfused = ["22", "23", "25", "32"];
  const inventoryType = item.inventoryType;
  if (
    _.get(item, "product.externalFields.group.isCannabis") &&
    item.chemicalComposition.thc === 0 && !adultUseInfused.includes(inventoryType)
  ) {
    validationResult.message = "THC missing.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (item.quantityPurchased <= 0) {
    validationResult.message = "Quantity can't be 0.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (
    item.unitCost <= 0 &&
    !_.get(item, "product.externalFields.group.isGiftCard")
  ) {
    validationResult.message = "Unit cost can't be 0.";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};
export const validateUnreceive = (item, purchaseOrder, options) => {
  let validationResult = { succeeded: true, message: "" };

  if (item.quantitySold > 0) {
    validationResult.message = "";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};

export const validateEntityBeforeSaving = (purchaseOrder, options) => {
  let validationResult = { succeeded: true, message: "" };

  if (!purchaseOrder || !purchaseOrder.supplier) {
    validationResult.message = "Please select a Supplier.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (
    !purchaseOrder ||
    (!purchaseOrder.supplierAgent && purchaseOrder.manifestId.length > 0)
  ) {
    validationResult.message = "Please select a Supplier Agent.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (!purchaseOrder || !purchaseOrder.batchType) {
    validationResult.message = "Please select a Batch Type.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (options && options.postToJournal) {
    if (!purchaseOrder.items) {
      validationResult.message =
        "You may not post this purchase order. Please enter items first.";
      validationResult.succeeded = false;
      return validationResult;
    }
    let isReceivedItem = false;
    purchaseOrder.items.forEach((i) => {
      if (i.isReceived) {
        isReceivedItem = true;
      }
    });

    if (!isReceivedItem) {
      validationResult.message =
        "Unable to post a Purchase Order without received items.";
      validationResult.succeeded = false;
      return validationResult;
    }
  }

  return validationResult;
};

export const validateEntityBeforePostingToJournal = (
  purchaseOrder,
  options
) => {
  let validationResult = { succeeded: true, message: "" };

  if (!purchaseOrder.items || purchaseOrder?.items?.length == 0) {
    validationResult.message = "Unable to post a PO without items.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (purchaseOrder.items.find((i) => i.isReceived == false && !i.isArchived)) {
    validationResult.message =
      "Unable to post a PO, not all items are received.";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};

export const validateItemArchiving = (item) => {
  let validationResult = { succeeded: true, message: "" };

  if (item.adjustments && item.adjustments.length > 0) {
    validationResult.message =
      "The item cannot be archived because it has adjustments.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (item.returns && item.returns.length > 0) {
    validationResult.message =
      "The item cannot be archived because it has returns.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (item.disposals && item.disposals.length > 0) {
    validationResult.message =
      "The item cannot be archived because it has disposals.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (item.isReceived) {
    validationResult.message =
      "The item cannot be archived because it is received.";
    validationResult.succeeded = false;
    return validationResult;
  }
  if (item.isPostedToProducts) {
    validationResult.message =
      "The item cannot be archived because it is posted to products.";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};

export const isEditallowed = (purchaseOrder) => {
  let validationResult = { succeeded: true, message: "" };

  if (purchaseOrder.isDeleted) {
    validationResult.message = "No edit allowed: Purchase Order deleted.";
    validationResult.succeeded = false;
    return validationResult;
  }

  if (purchaseOrder.isPostedToJournal) {
    validationResult.message =
      "No editing allowed: Purchase Order is posted to journal.";
    validationResult.succeeded = false;
    return validationResult;
  }

  return validationResult;
};
