<template>
  <div class="ccp">
    <Input :model="selectedItem.ccp" @updateModel="selectedItem.ccp = $event" :inputData="getInput('ccp')"
      :isEdit="true" />
    <div class="ccp__amount">
      <div>Amount:</div>
      <div>{{ memoBalance }}</div>
    </div>
    <div class="ccp__amount">
      <div>Order amount due:</div>
      <div>{{ Utilities.formatPrice(lineTotal) }}</div>
    </div>
    <BaseInput label="$ To Apply" placeholder="0" v-model="ccpToApply" :controlType="'number'"
      :disabled="disableMemoBalance" />
    <p v-if="ccpToApply > lineTotal" class="ccp__error">
      Can't apply more than the current order amount due
    </p>
    <BaseBtn label="Apply Discount" :disabled="disabled" @click="apply" />
  </div>
</template>

<script>
import _ from "lodash";
import Input from "@/components/Input/Input.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import BaseInput from "@/components/Base/Input/Base.vue";
import Utilities from "@/lib/Utilities";

import { inputs } from "./inputs";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      Utilities,
      ccpToApply: null,
      selectedItem: {
        ccp: {},
      },
    };
  },
  components: {
    BaseBtn,
    Input,
    BaseInput,
  },
  props: {
    order: {
      type: Object,
    },
    lineTotal: {
      type: Number,
    },
  },
  computed: {
    disableMemoBalance() {
      if (
        !this.selectedItem ||
        !this.selectedItem.ccp ||
        this.selectedItem.ccp.memoBalance == null
      ) {
        return true;
      }

      return false;
    },
    disabled() {
      if (
        !this.selectedItem ||
        !this.selectedItem.ccp ||
        this.selectedItem.ccp.memoBalance == null
      )
        return true;

      if (this.ccpToApply > this.lineTotal) return true;

      if (this.selectedItem.ccp.memoBalance && this.ccpToApply > 0)
        return false;

      return true;
    },
    memoBalance() {
      if (
        !this.selectedItem ||
        !this.selectedItem.ccp ||
        this.selectedItem.ccp.memoBalance == null
      )
        return "-";
      return this.selectedItem.ccp.memoBalance;
    },
  },
  methods: {
    ...mapActions({
      setMessage: "setMessage",
    }),
    getInput(field) {
      let input = inputs.find((i) => i.id == field);
      const ccpApplied =
        this.order?.discountsApplied
          ?.filter((d) => d.type == "ccp")
          .map((p) => p.discountId) || [];
      switch (field) {
        case "ccp":
          input.filter._id = { $nin: ccpApplied };
          if (this.order?.customer) {
            input.filter.customer = this.order.customer._id;
          }
          break;
        default:
          break;
      }

      return input;
    },
    updateAmountToAppy() {
      if (
        !this.selectedItem ||
        !this.selectedItem.ccp ||
        this.selectedItem.ccp.memoBalance == null
      ) {
        this.ccpToApply = null;
      }

      const memoBalance = this.selectedItem.ccp.memoBalance;
      const max = Math.min(memoBalance, this.lineTotal);
      this.ccpToApply = max;
    },
    selectItemFromSearch: function (entity, item, nestedId) {
      if (nestedId) {
        _.set(this.selectedItem, nestedId, item);
      }
      this.selectedItem[entity] = item;
      this.updateAmountToAppy();
    },
    clearItemFromSearch: function (entity) {
      this.selectedItem[entity] = {};
      this.ccpToApply = null;
    },
    isValid() {
      if (!this.ccpToApply || this.ccpToApply <= 0) {
        this.setMessage({
          text: "CCP memo is not valid",
          type: "error",
        });
        return false;
      }

      if (this.ccpToApply > this.selectedItem.ccp.memoBalance) {
        this.setMessage({
          text: "CCP amount to apply is greater than remaining balance",
          type: "error",
        });
        return false;
      }

      return true;
    },
    apply() {
      if (!this.isValid()) return;

      const discount = {
        type: "ccp",
        discountId: this.selectedItem.ccp._id,
        ccpAmount: this.ccpToApply,
        ccpBalance: this.selectedItem.ccp.memoBalance,
      };

      this.$emit("applyDiscount", discount);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_ccp.scss";
</style>
