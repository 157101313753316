<template>
  <div>
    <div class="customer-check-in__padded-container">
      <div style="padding-bottom: 1rem;">
        <MessageBox level="default">
          Enter a Sort Priority for the selected items. The higher the number, the higher the priority.
        </MessageBox>
      </div>
      <div class="customer-form__fields">
        <div class="customer-form__input-container">
          <span class="input-text__label">Sort Priority</span>
          <input v-model="sortPriority" placeholder="1" class="input-text__field" type="number" />
        </div>
        <CheckBox :value="resetPriority" @click="() => resetPriority = !resetPriority" label="Reset Priority" />
      </div>
    </div>
    <div style="width: full; display: flex; padding: 1rem; justify-content: end;">
      <BaseBtn v-shortkey="['enter']" iconGroup="fas" iconName="save" label="Save" :isLoading="isLoading"
        @click="$emit('setPriority', { sortPriority, resetPriority })" />
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import MessageBox from "@/components/MessageBox/MessageBox.vue";
import CheckBox from "@/components/Base/CheckBox/CheckBox.vue";

export default {
  name: "SortPriority",
  props: {
    itemsSelected: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    MessageBox,
    BaseBtn,
    CheckBox,
  },
  data() {
    return {
      sortPriority: 0,
      resetPriority: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.customer-check-in {
  @media (max-width: 800px) {
    margin-top: 0px;
    padding-top: 20px;
  }

  &__patient-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }
  }

  &__privacy-iframe {
    width: 100%;
    height: 600px;

    @include respond(phone) {
      height: 100%;
    }
  }

  &__privacy-buttons {
    padding: 2rem;

    &>button {
      width: 100%;
    }
  }

  &__padded-container {
    padding: 2rem;
  }

  &__loyalty-input-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
  }
}

.customer-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
</style>
