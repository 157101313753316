<template>
  <div class="section-base">
    <div class="section-base__header">
      <EntityFilter
        :selectedFilter="selectedFilter"
        :filterCriteria="filterCriteria"
        @criteriaChange="$emit('items-filter-criteria-change')"
        @valueChange="(e) => $emit('items-filter-value-change', e)"
        @filterButtonClicked="$emit('items-filter-button-clicked')"
        :noMargins="true"
        :noFocus="true"
        :disabled="disabled"
      >
        <div style="display: flex; gap: 20px">
          <BaseBtn
            label="Add Item"
            iconGroup="fas"
            iconName="plus"
            @click="$emit('addItem')"
            :disabled="disabled"
            class="add-button"
          />
          <BaseBtn
            :isLoading="isUpdateChemicalCompositionLoading"
            v-if="showChemicalCompositionButton"
            label="Update Chemical Composition"
            iconGroup="fas"
            iconName="sync-alt"
            @click="$emit('update-checmical-composition')"
            :disabled="disabled || isUpdateChemicalCompositionLoading"
            class="add-button"
          />
        </div>
      </EntityFilter>
    </div>
    <div class="table-container bordered table-full-screen">
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Received</th>
            <th class="cell">Posted</th>
            <th class="cell">Archived</th>
            <th class="cell">Batch</th>
            <!-- <th class="cell">Group</th> -->
            <th class="cell">Grams</th>
            <th class="cell">Product Name</th>
            <th class="cell">Quantity</th>
            <th class="cell">Available Quantity</th>
            <th class="cell">Quantity on Hand</th>
            <th class="cell">Quantity WI</th>
            <th class="cell">Quantity Sold</th>
            <th class="cell">Unit Cost</th>
            <th class="cell">Actual Unit Cost</th>
            <th class="cell">Discount</th>
            <th class="cell">Sub Total</th>
            <th class="cell">Tax</th>
            <th class="cell">Freight</th>
            <th class="cell">Total</th>
            <th class="cell">State Description</th>
            <th class="cell">Inventory Type</th>
            <th class="cell">Location</th>
            <th class="cell">Expiration Date</th>
            <th class="cell">Barcode #</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-show="!i.hidden"
            class="row"
            v-for="(i, sequentialNumber) in items"
            :key="i._id"
          >
            <td class="cell horizontal-distance">
              <BaseBtn
                iconGroup="far"
                iconName="edit"
                class="editor-button"
                @click="
                  () => $emit('selectItem', { item: i, sequentialNumber })
                "
                :disabled="disabled"
              />
              <BaseBtn
                v-if="i._id && !i.isNew"
                iconGroup="fas"
                iconName="archive"
                :colorType="1"
                class="editor-button"
                :disabled="disabled || i.isPostedToProducts"
                @click="
                  () =>
                    $emit('archiveItem', {
                      item: i,
                      itemPosition: sequentialNumber,
                    })
                "
              />
              <BaseBtn
                v-else
                iconGroup="fas"
                iconName="trash"
                :colorType="1"
                class="editor-button"
                :disabled="disabled || i.isPostedToProducts"
                @click="
                  () =>
                    $emit('removeItem', {
                      item: i,
                      itemPosition: sequentialNumber,
                    })
                "
              />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="i.isReceived" />
            </td>
            <td class="cell">
              <check-mark
                :key="`${i._id}_posted`"
                :toggleVariable="i.isPostedToProducts"
              />
            </td>
            <td class="cell">
              <check-mark :toggleVariable="i.isArchived" />
            </td>
            <td class="cell">{{ i.batchId, }}</td>

            <!-- <td class="cell">
              {{ i.product && i.externalFields.group.name ? i.externalFields.group.name : "-" }}
            </td> -->

            <td class="cell">
              {{ i.product ? i.product.cannabisGramsUnit : "-" }}
            </td>

            <td class="cell">
              {{ i.product && i.product.name ? i.product.name : i.description }}
            </td>

            <td class="cell">{{ i.quantityPurchased }}</td>
            <td class="cell">{{ i.quantityAvailable }}</td>
            <td class="cell">{{ i.quantityOnHand }}</td>
            <td class="cell">{{ i.quantityWorkingInventory }}</td>
            <td class="cell">{{ i.quantitySold }}</td>
            <td class="cell">{{ Utilities.formatPrice(i.unitCost) }}</td>
            <td class="cell">{{ Utilities.formatPrice(i.actualUnitCost) }}</td>
            <td class="cell">{{ Utilities.formatPrice(i.discount) }}</td>
            <td class="cell">
              {{
                Utilities.formatPrice(
                  i.quantityPurchased * i.unitCost - i.discount
                )
              }}
            </td>
            <td class="cell">{{ Utilities.formatPrice(i.tax) }}</td>
            <td class="cell">{{ Utilities.formatPrice(i.freight) }}</td>
            <td class="cell">
              {{
                Utilities.formatPrice(
                  i.quantityPurchased * i.unitCost -
                    i.discount +
                    i.quantityPurchased * i.unitCost * i.tax +
                    i.freight
                )
              }}
            </td>

            <td class="cell">{{ i.description }}</td>
            <td class="cell">{{ i.inventoryType }}</td>
            <td class="cell">{{ i.location }}</td>
            <td class="cell">
              {{ getExpirationDate(i) }}
            </td>
            <td class="cell">
              <a
                v-if="i._id"
                target="_blank"
                :href="`/items/itemEditor?id=${i._id}`"
                >{{ i._id }}</a
              >
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import moment from "moment";
import EntityFilter from "@/components/EntityFilter/EntityFilter.vue";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Constants from "@/resources/Constants";

export default {
  name: "PurchaseOrderItems",
  components: { EntityFilter, BaseBtn },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterCriteria: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedFilter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showChemicalCompositionButton: {
      type: Boolean,
      default: false,
    },
    isUpdateChemicalCompositionLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Utilities,
      moment,
    };
  },
  computed: {},
  methods: {
    getExpirationDate(item) {
      try {
        return moment(item.expirationDate).format(
          Constants.dates.defaultDateFormat
        );
      } catch (error) {
        console.log(error);
        return "-";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_purchaseOrderItems.scss";
</style>
