<template>
  <div id="caregiver-patient-selection-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div
      class="default-pop-up-center-div bordered"
      :class="{ blur: isBlurred }"
    >
      <span class="h1 padded">Select Patient</span>
      <br />
      <span class="text-color-1">Please select Caregiver's patient:</span>
      <br />

      <div class="padded">
        <div
          class="padded--vertical"
          style="display: flex; flex-direction: column; gap: 5px"
        >
          <inline-button
            v-for="patient in caregiverPatientSelectionPopUp.customer.patients"
            :key="patient._id"
            :label="patient.name"
            class="full-width"
            type="plain-primary responsive-column form-height wide"
            @click="() => performTypeSelection(patient)"
          />
        </div>
      </div>
      <div class="padded space-between">
        <div class="button-info" @click="handleNegativeAnswer">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Constants from "@/resources/Constants";

export default {
  name: "CaregiverPatientSelectionPopUp",
  data() {
    return {
      Constants: Constants,
      isBlurred: false,
    };
  },
  components: {},
  created: function () {
    window.addEventListener(
      "keyup",
      function (e) {
        if (e.keyCode === 27) {
          this.handleNegativeAnswer();
        }
      }.bind(this)
    );
  },
  computed: {
    ...mapGetters({
      caregiverPatientSelectionPopUp:
        "posModule/caregiverPatientSelectionPopUp",
    }),
  },
  methods: {
    ...mapActions({
      setCaregiverPatientSelectionPopUp:
        "posModule/setCaregiverPatientSelectionPopUp",
    }),
    handleNegativeAnswer: function () {
      this.setCaregiverPatientSelectionPopUp({ isOpen: false });
    },
    performTypeSelection: function (selection) {
      if (this.caregiverPatientSelectionPopUp.positiveCallback) {
        this.caregiverPatientSelectionPopUp.positiveCallback(selection);
      }
      this.setCaregiverPatientSelectionPopUp({ isOpen: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.options-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  div {
    flex: 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 800px) {
  .options-grid {
    display: block;
    .button-secondary {
      margin-bottom: 10px;
    }
  }
}
</style>
