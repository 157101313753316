<template>
  <div id="message-pop-up">
    <div class="overlay-div" v-on:click.stop="handleNegativeAnswer"></div>
    <div :class="
      'message-pop-up default-pop-up-center-div bordered ' +
      (messagePopUp.isError ? 'error' : '')
    ">
      <span class="h1">{{ messagePopUp.title }}</span>
      <br /><br />
      <p style="margin: 0" v-html="messagePopUp.message"></p>

      <br />
      <br />
      <div v-if="!messagePopUp.isError && messagePopUp.hasTextbox">
        <Input :isEdit="true" :model="model.answer" :inputData="inputDataObj" @updateModel="model.answer = $event" />
      </div>

      <br />
      <br />
      <div v-if="!messagePopUp.isError && !messagePopUp.isAknowledge" class="message-pop-up__button-wrapper">
        <BaseBtn @click="handleNegativeAnswer" class="message-pop-up__button" label="No" :colorType="3" />
        <BaseBtn @click="handlePositiveAnswer" class="message-pop-up__button" label="Yes" v-shortkey="['enter']"
          @shortkey.native="handlePositiveAnswer" />
      </div>
      <div v-if="!messagePopUp.isError && messagePopUp.isAknowledge" class="
              message-pop-up__button-wrapper message-pop-up__button-wrapper--center
            ">
        <BaseBtn @click="handlePositiveAnswer" class="message-pop-up__button" label="Ok" v-shortkey="['enter']"
          @shortkey.native="handlePositiveAnswer"
          :disabled="messagePopUp.isAnswerMandatory && model.answer.length == 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BaseBtn from "@/components/Base/Button/Button.vue";
import Input from "@/components/Input/Input.vue";

export default {
  name: "OrderTypeSelectionPopUp",
  data() {
    return {
      model: { answer: "" },
      inputDataObj: { title: "", id: "answer", type: "input", focus: true },
    };
  },
  components: {
    BaseBtn,
    Input,
  },
  created: function () {
    window.addEventListener(
      "keydown",
      this.keyDownHandler
    )
  },
  computed: {
    ...mapGetters(["messagePopUp"]),
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  methods: {
    handlePositiveAnswer: function () {
      this.messagePopUp.isOpen = false;
      if (this.messagePopUp.positiveCallback) {
        this.messagePopUp.positiveCallback(this.model.answer);
      }
    },
    handleNegativeAnswer: function () {
      this.messagePopUp.isOpen = false;

      if (this.messagePopUp.negativeCallback) {
        this.messagePopUp.negativeCallback();
      }
    },
    keyDownHandler(e) {
      if (e.keyCode === 27) {
        this.handleNegativeAnswer()
      }
    },
  },
};
</script>

<style lang="scss">
.error {
  background-color: $dark-toast-error-background !important;
  color: $dark-toast-error-text;
}

.message-pop-up {
  display: block;

  &__button-wrapper {
    display: flex;
    gap: 10px;

    &--center {
      justify-content: center;
    }
  }

  &__button {
    width: 50%;
  }
}
</style>
