var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"organization-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"hideAddButton":true,"selectedEntity":_vm.selectedOrganization,"links":[
        { name: 'home', label: 'Home' },
        {
          name: 'organizationEditor',
          label: _vm.selectedOrganization.name,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"entity":_vm.entity,"isCollapsed":_vm.isCollapsed},on:{"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"updateIsEdit":function($event){_vm.isEdit = $event}}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},[_c('div',{staticClass:"\n              grid-two-column\n              two-columns-layout--responsive-horizontal-space-10\n            "},_vm._l((_vm.mainInputs),function(input){return _c('div',{key:input.id,class:'input-text' + ' ' + input.class},[_c('Input',{attrs:{"model":_vm.selectedOrganization[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedOrganization[input.id] = $event}}})],1)}),0),_c('div',[_c('div',{staticClass:"\n                two-columns-layout\n                two-columns-layout--responsive-horizontal-space-10\n              "},[_c('div',[_c('div',{staticClass:"input-text"},[_c('div',{staticClass:"reversed-flex-column"},[_c('FileUploader',{attrs:{"label":"Upload Logo","allowedTypes":"image/png, image/jpeg","disabled":!_vm.isEdit},on:{"change":_vm.updateImage}}),_c('div',{staticClass:"input-text__label"},[_vm._v("Organization Logo")])],1)])]),_c('div',[(
                    this.selectedOrganization.imageNormal &&
                    (this.selectedOrganization.imageNormal.data ||
                      this.selectedOrganization.imageNormal.secure_url)
                  )?_c('div',{staticClass:"input-text"},[_c('div',{staticClass:"reversed-flex-column"},[_c('div',[_c('inline-button',{attrs:{"label":"Remove Logo","type":"plain-danger responsive-column form-height wide","disabled":!_vm.isEdit},on:{"click":function () { return _vm.removeImage(); }}})],1),_c('div',{staticClass:"input-text__label"},[_vm._v("Organization Logo")])])]):_vm._e()])]),_c('img',{staticClass:"image-preview",attrs:{"src":this.selectedOrganization.imageNormal &&
                (this.selectedOrganization.imageNormal.data
                  ? this.selectedOrganization.imageNormal.data
                  : this.selectedOrganization.imageNormal.secure_url),"alt":""}})])])])]),(_vm.selectedOrganization._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedOrganization.name || '')),"sectionType":"about","bus":_vm.bus}},[(_vm.selectedOrganization._id !== undefined)?_c('About',{attrs:{"entity":_vm.selectedOrganization}}):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }