import _ from "lodash";
import moment from "moment";

export const validateFields = (item, field, validationErrors) => {
  if (field !== "credits" && field !== "debits") return;

  _.set(validationErrors, "credits", { isInvalid: false });
  _.set(validationErrors, "debits", { isInvalid: false });

  if (!item.credits || !item.debits) return;
  _.set(validationErrors, field, {
    isInvalid: true,
    message: "Enter either a credit or debit amount!",
  });

  return validationErrors;
};
