<template>
  <div class="items responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :hideAddButton="true"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'items', label: 'Items' },
        ]"
        :entity="entity"
      >
        <Button
          label="Inventory Count"
          iconGroup="fas"
          iconName="tachometer-alt"
          @click="inventoryCountHandler()"
        />
        <Button
          label="Select Label Printer"
          iconGroup="fas"
          iconName="print"
          @click="selectPrinterPopUp()"
        />
      </PageHeader>
      <SearchTable
        :entity="entity"
        :filterButtons="filterButtons"
        :filterButtons2="filterButtons2"
        @filterButtonsChange="updateFilterButtons"
        @filterButtonsChange2="updateFilterButtons2"
        :sort="sort"
        @sortChangeEvent="handleSortChange"
        :pagination="pagination"
        @paginationChange="updatePagination"
        @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter"
        :enableAdditionalFilter="true"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Utilities from "@/lib/Utilities";
import SearchTable from "@/components/SearchTable.vue";
import _ from "lodash";
import Entities from "@/resources/Entities";
import Button from "@/components/Base/Button/Button.vue";

import { listPageHeader } from "./inputs";

export default {
  name: "items",
  data() {
    return {
      moment: moment,
      Utilities: Utilities,
      listPageHeader,
      entity: Entities.ITEM,
    };
  },
  components: {
    PageHeader,
    SearchTable,
    Button,
  },
  created: function () {
    this.setPageTitle("Items");
  },
  mounted: function () {
    // this.getItems();
    this.getStoreCurrentStore();
    if (this.selectedFilter && this.selectedFilter.itemFilter) {
      const selectedFilterButton = _.find(
        this.filterButtons,
        (fb) => fb.name === this.selectedFilter.itemFilter
      );
      if (selectedFilterButton) {
        selectedFilterButton.isSelected = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingStatus: "itemModule/loadingStatus",
      items: "itemModule/items",
      filterCriteria: "itemModule/filterCriteria",
      selectedFilter: "itemModule/selectedFilter",
      selectedLabelPrinter: "selectedLabelPrinter",
      sort: "itemModule/sort",
      pagination: "itemModule/pagination",
      filterButtons: "itemModule/filterButtons",
      filterButtons2: "itemModule/filterButtons2",
      initialFilter: "itemModule/initialFilter",
    }),
  },
  methods: {
    ...mapActions({
      getItems: "itemModule/getItems",
      setSelectedFilter: "itemModule/setSelectedFilter",
      setItemMovementPopUp: "itemModule/setItemMovementPopUp",
      setLabelPrinterSelectionPopUp: "setLabelPrinterSelectionPopUp",
      getItemById: "itemModule/getItemById",
      printLabel: "itemModule/printLabel",
      updateSort: "itemModule/updateSort",
      getStoreCurrentStore: "storeModule/getCurrentStore",
      setMessage: "setMessage",
      updatePagination: "itemModule/updatePagination",
      updateFilterButtons: "itemModule/updateFilterButtons",
      updateFilterButtons2: "itemModule/updateFilterButtons2",
      updateInitialFilter: "itemModule/updateInitialFilter",
    }),
    loadData: function (filter) {
      this.setSelectedFilter(filter);
      this.getItems();
    },
    performSetSelectedFilter(filter) {
      this.setSelectedFilter(filter);
      this.getItems();
    },
    handleFilterButtonClicked(payload) {
      const selectedButton = _.find(this.filterButtons, (fb) => fb.isSelected);
      const filter = payload.filter;

      if (selectedButton && selectedButton._id === payload.filterButton._id) {
        selectedButton.isSelected = false;
        delete filter.itemFilter;
      } else {
        this.filterButtons.map((fb) => (fb.isSelected = false));
        payload.filterButton.isSelected = true;
        filter.itemFilter = payload.filterButton.name;
      }

      this.setSelectedFilter(filter);
      this.getItems();
    },
    performItemMovement(item) {
      this.setItemMovementPopUp({ isOpen: true, item: item });
    },
    handleButtonClicked(type, id, item) {
      if (type === "move") {
        this.getItemById({
          _id: item._id,
          positiveCallback: (item) => {
            this.performItemMovement(item);
          },
        });
      }
      if (type === "print") {
        const payload = {
          _id: item._id,
          labelPrinter: this.selectedLabelPrinter,
          positiveCallback: (item) => {
            this.setMessage({
              title: "Label Printed",
              text: "Label Printed",
              type: "success",
            });
          },
        };

        if (!this.selectedLabelPrinter) {
          this.selectPrinterPopUp(payload);
        } else {
          this.printLabel(payload);
        }
      }
    },
    inventoryCountHandler() {
      this.$router.push({ name: "inventoryCounts" });
    },
    selectPrinterPopUp(payload) {
      this.setLabelPrinterSelectionPopUp({
        isOpen: true,
        positiveCallback: (labelPrinter) => {
          if (payload) {
            payload.labelPrinter = labelPrinter.selectedLabelPinter;
            this.printLabel(payload);
          }
        },
      });
    },
    handleSortChange(sort) {
      this.updateSort(sort);
    },
  },
};
</script>
