<template>
  <div>
    <PageHeader v-if="!isComponent" title="Reports" :links="[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'salesReport', label: 'Sales' },
    ]" />
    <div class="home responsive-menu">
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Sales Summary</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'salesSummaryReport' }" type="plain-primary responsive-column"
              label="Sales Summary" />
            <inline-button :to="{ name: 'salesSummaryBreakdownReport' }" type="plain-primary responsive-column"
              label="Sales Summary Breakdown" />
            <inline-button :to="{ name: 'salesAverageSource' }" type="plain-primary responsive-column"
              label="Sales Source Averages" />
            <inline-button :to="{ name: 'salesCouponSummary' }" type="plain-primary responsive-column"
              label="Coupon Summary" />
            <inline-button :to="{ name: 'saleDiscountsReportsSummary' }" type="plain-primary responsive-column"
              label="Discount Summary" />
            <inline-button :to="{ name: 'salesDiscountComps' }" type="plain-primary responsive-column"
              label="Discount Comps" />
          </div>
        </div>
      </div>
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Sales Order Status</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'salesOrderStatusArchived' }" type="plain-primary responsive-column"
              label="Archived Orders" />
            <inline-button :to="{ name: 'salesOrderStatusBatchId' }" type="plain-primary responsive-column"
              label="Batch #" />
            <!-- <inline-button :to="{ name: 'salesOrderStatusProductId' }" type="plain-primary responsive-column"
              label="Product Id" /> -->
            <!-- <inline-button :to="{ name: 'salesOrderStatusBuyer' }" type="plain-primary responsive-column"
              label="Buyer Detail" />
            <inline-button :to="{ name: 'salesOrderStatusBuyerSummary' }" type="plain-primary responsive-column"
              label="Buyer Summary" /> -->
            <inline-button :to="{ name: 'salesOrderStatusNotPosted' }" type="plain-primary responsive-column"
              label="Open Orders" />
            <inline-button :to="{ name: 'salesOrderStatusRefunds' }" type="plain-primary responsive-column"
              label="Refunded Orders" />
            <inline-button :to="{ name: 'salesOrderStatusStaffing' }" type="plain-primary responsive-column"
              label="Staffing" />
            <inline-button :to="{ name: 'salesOrderStatusVoided' }" type="plain-primary responsive-column"
              label="Voided Orders" />

            <!-- <inline-button :to="{ name: 'saleDiscountsReportsEmployee' }" type="plain-primary responsive-column"
              label="By Employee" /> -->
          </div>
        </div>
      </div>
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Sales Profit Margins</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'salesProfitMarginsGroup' }" type="plain-primary responsive-column"
              label="By Group" />
            <inline-button :to="{ name: 'salesProfitMarginsGroupProduct' }" type="plain-primary responsive-column"
              label="By Group & Product" />
            <inline-button :to="{ name: 'salesProfitMarginsSupplier' }" type="plain-primary responsive-column"
              label="By Supplier" />
            <inline-button :to="{ name: 'salesProfitMarginsSupplierGroup' }" type="plain-primary responsive-column"
              label="By Supplier & Group" />
            <inline-button :to="{ name: 'salesProfitMarginsSupplierGroupProduct' }" type="plain-primary responsive-column"
              label="By Supplier & Group & Product" />
          </div>
        </div>
      </div>
      <div class="responsive-menu__child responsive-menu__sub-section">
        <div class="section-base" v-canAccess="reportRoles">
          <div class="section-base__header space-between">
            <div class="section-base__header-name">
              <div>
                <i class="fas fa-chart-line"></i>
              </div>
              <div>Sales Transactions</div>
            </div>
          </div>
          <div class="padded elevated-0 bordered spacer-responsive">
            <inline-button :to="{ name: 'salesRecapTransactionsDetailed' }" type="plain-primary responsive-column"
              label="Detailed - By Date" />
            <inline-button :to="{ name: 'salesRecapTransactionSummary' }" type="plain-primary responsive-column"
              label="Summary - By Date" />
            <inline-button :to="{ name: 'salesRecapTransactionSummaryEmployee' }" type="plain-primary responsive-column"
              label="Summary - By Employee" />
            <inline-button :to="{ name: 'salesRecapTransactionsDetailedEmployee' }" type="plain-primary responsive-column"
              label="Non-Cannabis Sales By Employee" />
            <!-- <inline-button
              :to="{ name: 'salesRecapOrderEmployee' }"
              type="plain-primary responsive-column"
              label="Orders - By Employee"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportRoles } from "@/constants/roles";
import PageHeader from "@/components/Header/Header.vue";

export default {
  name: "reports",
  data() {
    return {
      reportRoles,
    };
  },
  components: { PageHeader },
  created: function () {
    this.setPageTitle("Reports");
  },
};
</script>

<style lang="scss"></style>
