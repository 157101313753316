<template>
  <div class="pos-order-details">
    <div class="pos-order-box">
      <div
        class="elevated-0 bordered position-relative"
        :class="{
          'bordered__highlighted--primary':
            order.isPaid || order.isVoided || order.isPreorder,
          'bordered__highlighted--danger': order.isArchived || order.isPending,
        }"
      >
        <transition name="fade">
          <ContainerLabel
            v-if="order.isPending && !order.isArchived"
            type="danger"
            text="PENDING"
          />
          <ContainerLabel
            v-if="order.isPaid && !order.isArchived && !order.preOrder"
            type="primary"
            text="PAID"
          />
          <ContainerLabel
            v-if="order.isVoided && !order.isArchived"
            type="primary"
            text="REVERSED"
          />
          <ContainerLabel
            v-if="order.isArchived"
            type="danger"
            text="ARCHIVED"
          />
          <ContainerLabel
            v-if="order.isPreorder"
            type="primary"
            text="PREORDER"
          />
        </transition>
        <div class="pos-order-details__order-summary">
          <PriceSummary :rows="orderSummary" />
        </div>
      </div>
      <div class="pos-order-details__control-buttons">
        <BaseBtn
          :smallLabel="true"
          label="Pay"
          iconGroup="fas"
          iconName="dollar-sign"
          @click="$emit('sell-options')"
          :disabled="
            (!order.customer && !order.isRetail) ||
            order.isArchived ||
            order.isVoided ||
            !order.products ||
            order.products.length == 0
          "
        />
        <BaseBtn
          :smallLabel="true"
          label="Discount"
          iconGroup="fa"
          iconName="percent"
          @click="$emit('set-discount')"
          :disabled="disableDiscount"
        />
        <BaseBtn
          :smallLabel="true"
          label="Gift Card"
          iconGroup="fa"
          iconName="gift"
          @click="$emit('giftCard-lookup')"
          :disabled="disabledGiftCard"
        />

        <!-- <BaseBtn
          iconGroup="fa"
          iconName="truck"
          @click="$emit('set-delivery')"
          :disabled="true"
        /> -->
        <BaseBtn
          :smallLabel="true"
          label="Notes"
          iconGroup="fa"
          iconName="sticky-note"
          @click="$emit('set-note')"
          :disabled="!order.customer"
        />
        <BaseBtn
          :smallLabel="true"
          label="Print"
          iconGroup="fa"
          iconName="print"
          @click="$emit('print')"
          :disabled="!order._id"
        />
        <BaseBtn
          :smallLabel="true"
          label="Fulfill"
          iconGroup="fa"
          iconName="box-open"
          @click="$emit('fulfillment-print')"
          v-if="store.isPOSScanningEnabled"
          :disabled="disableFulfillmentPrint"
        />
        <BaseBtn
          :smallLabel="true"
          label="Drawer"
          iconGroup="fas"
          iconName="money-bill-wave"
          @click="$emit('open-cash-drawer')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";
import BaseBtn from "@/components/Base/Button/Button.vue";
import PriceSummary from "@/components/PriceSummary/PriceSummary.vue";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";

export default {
  name: "PosOrderDetails",
  components: { BaseBtn, ContainerLabel, PriceSummary },
  props: {
    order: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    store: {
      type: Object,
      default: () => {
        return undefined;
      },
    },
    averageSpending: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return { Utilities };
  },
  computed: {
    disableDiscount() {
      if (!this.order || (!this.order.isRetail && !this.order.customer))
        return true;

      if (this.order.products.length == 0) return true;
      return this.order.isPaid || this.order.isArchived || this.order.isVoided;
    },
    disabledGiftCard() {
      if (!this.order || (!this.order.isRetail && !this.order.customer))
        return true;
      return false;
    },
    disableFulfillmentPrint() {
      if (!this.order._id) {
        return true;
      }
      if (this.order.products.length == 0) {
        return true;
      }
      if (this.order.isPaid || this.order.isArchived || this.order.isVoided) {
        return true;
      }
      return false;
    },
    orderSummary() {
      const orderSummary = [];

      orderSummary.push({
        name: "Sub Total",
        value: Utilities.formatPrice(this.order.subTotal),
        isHighlighted: true,
      });
      orderSummary.push({
        name: "Discount",
        value: Utilities.formatPrice(this.order.discount),
      });

      orderSummary.push({
        name: "Tax",
        value: Utilities.formatPrice(this.order.tax),
      });
      const total = {
        name: "Total",
        value: Utilities.formatPrice(this.order.orderTotal),
        isHighlighted: true,
      };
      if (this.averageSpending > 0 && this.order.orderTotal > 0) {
        if (this.order.orderTotal < this.averageSpending) {
          total.label = {
            text: "Less than average spending",
            icon: "angle-double-down",
            color: "red",
          };
        }
        if (this.order.orderTotal > this.averageSpending) {
          total.label = {
            text: "More than average spending",
            icon: "angle-double-up",
            color: "green",
          };
        }
      }

      orderSummary.push(total);

      return orderSummary;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./_posOrderDetails.scss";
</style>
