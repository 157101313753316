var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"queue-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"canEdit":false,"hideAddButton":true,"canArchive":false,"selectedEntity":_vm.selectedQueue,"links":[
        { name: 'home', label: 'Home' },
        { name: 'queues', label: 'Queue' },
        {
          name: 'queueEditor',
          label: ((_vm.selectedQueue.customerInfo.name) + " " + (_vm.selectedQueue.customerInfo.lastName)),
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"entity":_vm.entity},on:{"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"show-search-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},[_c('div',{staticClass:"two-columns-layout two-columns-layout--responsive"},[_c('div',{staticClass:"\n              grid-two-column\n              two-columns-layout--responsive-horizontal-space-10\n            "},_vm._l((_vm.queueInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.lodash.get(_vm.selectedQueue, input.id),"inputData":input,"isEdit":_vm.isEdit}})],1)}),0)])])]),(_vm.selectedQueue._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedQueue.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedQueue}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }