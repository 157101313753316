import { cloneDeep } from "lodash";

export const splitRequests = (apiURL, payload) => {
  const payloadClone = cloneDeep(payload)

  if (!containsDateRange(payloadClone)) {
    // need to return the array of requests; in this case there will be just one since there is no date range;
    return [generateFinalApiURI(apiURL, payloadClone)];
  }

  const requests = [];
  const timeFrames = getTimeFrames(payloadClone.selectedPeriod.from, payloadClone.selectedPeriod.to);

  for (let i = 0; i < timeFrames.length; i++) {
    const timeFrame = timeFrames[i];
    payloadClone.selectedPeriod.from = timeFrame.start;
    payloadClone.selectedPeriod.to = timeFrame.end;

    const finalUrl = generateFinalApiURI(apiURL, payloadClone);
    requests.push(finalUrl);
  }
  return requests;

}

const getTimeFrames = (start, end, days = 7) => {
  let result = [];
  // Copy start so don't affect original
  let s = new Date(start);

  while (s < end) {
    // Create a new date for the block end that is s + days
    let e = new Date(s.getFullYear(), s.getMonth(), s.getDate() + days);

    // Push into an array. If block end is beyond end date, use a copy of end date
    const start_info = new Date(s);
    start_info.setHours(0, 0, 0, 0)
    const end_info = e <= end ? e : new Date(end);
    end_info.setHours(23, 59, 59, 999);

    result.push({ start: start_info, end: end_info });
    // Increment s to the start of the next block which is one day after
    // the current block end
    s.setDate(s.getDate() + days + 1);
  }
  return result;
}

const generateFinalApiURI = (apiUrl, payload) => {
  const params = []
  if (payload.selectedReportFor) {
    params.push(`reportFor=${payload.selectedReportFor}`)
  }
  if (payload.selectedReportSaleType) {
    params.push(`reportSaleType=${payload.selectedReportSaleType}`)
  }
  if (payload.selectedReportItemLocation) {
    params.push(`reportItemLocation=${payload.selectedReportItemLocation}`)
  }
  if (payload.selectedReportItemType) {
    params.push(`reportItemType=${payload.selectedReportItemType}`)
  }
  if (payload.selectedReportCustomerLoyalty) {
    params.push(`reportCustomerLoyalty=${payload.selectedReportCustomerLoyalty}`)
  }
  if (payload.selectedReportAllThis) {
    params.push(`reportAllThis=${payload.selectedReportAllThis}`)
  }
  if (payload.reportType) {
    params.push(`reportType=${payload.reportType}`)
  }
  if (payload.filterText) {
    params.push(`filterText=${payload.filterText}`)
  }
  // the range period at this stage will be already split if needed;
  if (payload.selectedPeriod && payload.selectedPeriod.from) {
    params.push(`startTime=${payload.selectedPeriod.from}`)
  }
  if (payload.selectedPeriod && payload.selectedPeriod.to) {
    params.push(`endTime=${payload.selectedPeriod.to}`)
  }
  if (payload.customer) {
    params.push(`customer=${payload.customer}`)
  }

  let query = params.length > 0 ? `?${params.join('&')}` : '';
  return query ? `${apiUrl}${query}` : apiUrl;
}

const containsDateRange = (payload) => {
  let hasStartTime = false;
  let hasEndTime = false;

  if (payload.selectedPeriod && payload.selectedPeriod.from) {
    hasStartTime = true;
  }
  if (payload.selectedPeriod && payload.selectedPeriod.to) {
    hasEndTime = true;
  }

  return hasStartTime && hasEndTime;
}

