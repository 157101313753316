var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Supplier Listing","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'inventoryReport', label: 'Inventory' },
        {
          name: 'inventoryListSupplier',
          label: 'Supplier Listing',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"isLoading":_vm.inventoryTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',[_vm._v(_vm._s(record.name))]),_c('td',[_vm._v(_vm._s(record.srn))]),_c('td',[_vm._v(_vm._s(record.namePos))]),_c('td',[_vm._v(_vm._s(record.nameOnLineStore))]),_c('td',[_vm._v(_vm._s(record.taxRate))]),_c('td',[_vm._v(_vm._s(record.cannabisTaxRate))]),_c('td',[_vm._v(_vm._s(record.isArmsLength ? 'Y' : 'N'))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Srn")]),_c('th',{attrs:{"align":"left"}},[_vm._v("POS Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Online Store Name")]),_c('th',{attrs:{"align":"left"}},[_vm._v("NC Tax Rate")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Cannabis Tax Rate")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Arms Length")])])])}]

export { render, staticRenderFns }