var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pos-header"},[_c('div',{staticClass:"pos-header__left"},[_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"user-edit","colorType":3,"disabled":_vm.order._id ? true : false},on:{"click":function($event){return _vm.$emit('change-employee')}}}),_c('div',[_c('div',[_c('b',[_vm._v(_vm._s(_vm.Utilities.composeNameAndLastName(_vm.employee)))])]),(_vm.workstation)?_c('div',[_c('b',[_vm._v(_vm._s(_vm.workstation.name))])]):_vm._e()])],1),_c('div',{staticClass:"pos-header__right"},[_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"search","label":"Search"},on:{"click":function($event){return _vm.$emit('show-product-search')}}}),(_vm.order.isPending && !_vm.order.isArchived)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"check","label":"Process","loading":_vm.orderLoadingStatus.processOrder},on:{"click":function($event){return _vm.$emit('process')}}}):_vm._e(),(_vm.showOptions && !_vm.order.isPaid)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"sitemap","label":"Products"},on:{"click":function($event){return _vm.$emit('show-product-browser')}}}):_vm._e(),(_vm.showOptions)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"plus","label":"New","isLoading":_vm.orderLoadingStatus.saveOrder},on:{"click":function($event){return _vm.$emit('new-order')}}}):_vm._e(),(_vm.showOptions && !_vm.order.isPaid & !_vm.order.isArchived)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"user-friends","label":"Queue"},on:{"click":function($event){return _vm.$emit('queue')}}}):_vm._e(),(_vm.showOptions)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"folder","label":"Open Orders"},on:{"click":function($event){return _vm.$emit('open-orders')}}}):_vm._e(),_c('div',{class:{ 'left-margin': _vm.order._id }},[(
          _vm.order._id &&
          !_vm.order.isArchived &&
          !_vm.order.isPaid &&
          (_vm.order.customer || _vm.order.isRetail) &&
          _vm.showLoyaltyButtons
        )?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"user-plus","label":"New Customer"},on:{"click":function($event){return _vm.$emit('new-customer')}}}):_vm._e(),(
          _vm.order._id &&
          !_vm.order.isArchived &&
          !_vm.order.isPaid &&
          (_vm.order.customer || _vm.order.isRetail) &&
          _vm.showLoyaltyButtons
        )?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"user-tag","label":"Loyalty Lookup"},on:{"click":function($event){return _vm.$emit('loyalty-lookup')}}}):_vm._e(),(_vm.order._id && !_vm.order.isArchived && !_vm.order.isPaid)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"archive","label":"Archive","colorType":1},on:{"click":function($event){return _vm.$emit('archive-order')}}}):_vm._e(),(_vm.order._id)?_c('BaseBtn',{attrs:{"iconGroup":"fas","iconName":"times","label":"Close","colorType":3},on:{"click":function($event){return _vm.$emit('cancel-order')}}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }