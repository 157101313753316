<template>
  <div class="table-container payment-list">
    <table cellspacing="0" class="table-element">
      <thead>
        <tr class="header">
          <th class="cell"></th>
          <th class="cell"></th>
          <th class="cell">Type</th>
          <th class="cell">Status</th>
          <th class="cell">Payment Applied</th>
          <th class="cell">Credit Memo</th>
          <th class="cell">Gift Card</th>
          <th class="cell">Authorization</th>
        </tr>
      </thead>
      <tbody>
        <tr class="row" v-for="payment of payments" :key="payment._id">
          <td class="cell">
            <BaseBtn @click="removePayment(payment)" iconGroup="fas" iconName="archive" :colorType="1" size="sm"
              :disabled="isDisabled(payment) || disabled" />
          </td>
          <td class="cell">
            <BaseBtn @click="editPayment(payment)" iconGroup="fa" iconName="edit" size="sm"
              :disabled="isDisabled(payment) || disabled" />
          </td>
          <td class="cell">{{ payment.type.toUpperCase() }}</td>
          <td class="cell"><Label :label="getPaymentStatus(payment).label" :type="getPaymentStatus(payment).type" /></td>
          <td class="cell">
            {{
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(payment.amountPaid)
            }}
          </td>
          <td class="cell">
            {{ payment.creditMemo ? payment.creditMemo._id : "" }}
          </td>
          <td class="cell">
            {{ payment.giftCard ? payment.giftCard._id : "" }}
          </td>
          <td class="cell">{{ payment.authorization }}</td>
        </tr>
      </tbody>
    </table>
    <div class="payment-list__no-payments" v-if="payments.length == 0">
      No payments applied
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/Base/Button/Button.vue";
import Label from '@/components/Base/Label/Label.vue'

const PAYMENT_STATUS = {
  ARCHIVED: {
    label: 'Archived',
    type: 'error'
  },
  PAID: {
    label: 'Paid',
    type: 'success'
  }
}

export default {
  data() {
    return {};
  },
  components: {
    BaseBtn,
    Label
  },
  props: {
    payments: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPaymentStatus(payment) {
      if (payment.isArchived) {
        return PAYMENT_STATUS.ARCHIVED
      }
      return PAYMENT_STATUS.PAID
    },
    isDisabled(payment) {
      return payment.isArchived || payment.type == 'debit-card'
    },
    removePayment(payment) {
      this.$emit("archive-payment", payment);
    },
    editPayment(payment) {
      this.$emit("edit-payment", payment);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_payment-list.scss";
</style>
