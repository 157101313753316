<template>
  <div class="search__container" @click="$emit('toggle-modal', { isOpen: false })">
    <div class="search__body" @click.stop="">
      <div class="elevated-0">
        <div class="search__header">
          <input v-model="keyword" id="search_input" type="text" class="search__input" placeholder="Search..." />
          <div class="search__header-close">
            <font-awesome-icon @click="$emit('toggle-modal', { isOpen: false })" :icon="['fas', 'times']" />
          </div>
        </div>
        <div class="search__content">
          <div v-for="(resource, i) of filteredResources" :key="resource.id" class="search__resource"
            :class="{ 'elevated-0': i === selectionIndex }" @click="() => selectResource(resource)">
            {{ resource.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "Search",
  data() {
    return {
      keyword: '',
      selectionIndex: 0,
      resources: [
        { id: 1, name: 'Point of Sale', tags: 'pos', url: '/pos' },
        { id: 2, name: 'Customers', url: '/customers/' },
        { id: 3, name: 'Check In', url: '/customerCheckIn/customerCheckIn' },
        { id: 23, name: 'Stores', url: '/stores/' },
        { id: 4, name: 'Queue', url: '/queue/' },
        { id: 5, name: 'Flights', url: '/flights/' },
        { id: 6, name: 'Groups', url: '/groups/' },
        { id: 7, name: 'Items', url: '/items/' },
        { id: 8, name: 'Online Store Groups', url: '/onlineStoreGroups/' },
        { id: 9, name: 'Products', url: '/products/' },
        { id: 29, name: 'Orders', url: '/orders/' },
        { id: 10, name: 'Purchase Orders', url: '/purchaseOrders/' },
        { id: 11, name: 'Room', url: '/rooms/' },
        { id: 12, name: 'Special Pricing', url: '/specialPricings/' },
        { id: 13, name: 'Suppliers', url: '/suppliers/' },
        { id: 14, name: 'Supplier Agents', url: '/supplierAgents/' },
        { id: 45, name: 'Settings', url: '/settings/' },
        { id: 15, name: 'Fulfillment', url: '/fulfillment/' },
        { id: 16, name: 'Employees', url: '/employees/' },
        { id: 44, name: 'Time Sheets', url: '/timeSheets/' },
        { id: 17, name: 'MSO Chart Of Account', url: '/msoChartOfAccounts/' },
        { id: 18, name: 'Mso Coupons', url: '/msoCoupons/' },
        { id: 19, name: 'MSO Groups', url: '/msoGroups/' },
        { id: 20, name: 'MSO Products', url: '/msoProducts/' },
        { id: 21, name: 'MSO Suppliers', url: '/msoSuppliers/' },
        { id: 22, name: 'Organization', url: `/organizations/OrganizationEditor?id=${localStorage.getItem("organizationId")}` },
        { id: 24, name: 'Chart Of Account', url: '/chartOfAccounts/' },
        { id: 25, name: 'Coupons', url: '/coupons/' },
        { id: 26, name: 'Credit Memos', url: '/creditMemos/' },
        { id: 27, name: 'GiftCards', url: '/giftCards/' },
        { id: 28, name: 'Journal Entries', url: '/journals/' },
        { id: 30, name: 'Order Returns', url: '/returnItems/' },
        { id: 31, name: 'Payments', url: '/payments/' },
        { id: 32, name: 'Tax Setup', url: '/taxSetups/' },
        { id: 33, name: `Today's menu`, url: '/reports/todaysMenu/' },
        { id: 34, name: 'End Of Day Closing Reports', url: '/reports/endOfDayClosing/' },
        { id: 35, name: 'Inventory Status', url: '/reports/inventory/' },
        { id: 36, name: 'State Reports', url: '/reports/inventory/' },
        { id: 37, name: 'Sales Summary', url: '/reports/sales/' },
        { id: 38, name: 'Sales Order Status', url: '/reports/sales/' },
        { id: 39, name: 'Sales Profit Margins', url: '/reports/sales/' },
        { id: 40, name: 'Sales Transactions', url: '/reports/sales/' },
        { id: 41, name: 'Cash', url: '/reports/finance/' },
        { id: 42, name: 'Uploads', url: '/reports/finance/' },
        { id: 43, name: 'Taxes', url: '/reports/finance/' },
        { id: 46, name: 'Inventory Management', url: '/reports/inventory/' },
        { id: 47, name: 'Purchase', url: '/reports/finance/' },
        { id: 48, name: 'Inventory', url: '/reports/finance/' },
      ]
    };
  },
  components: { FontAwesomeIcon },
  props: {
    title: { type: String, default: "" },
    autoWidth: { type: Boolean, default: false },
  },
  created() {
    window.addEventListener(
      "keydown",
      this.keyDownHandler
    )
  },
  mounted() {
    document.getElementById("search_input").focus();
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  computed: {
    filteredResources() {
      const keyword = this.keyword.trim().toLowerCase()
      return this.resources.filter(resource => resource.name.toLowerCase().includes(keyword) || resource.tags?.includes(keyword))
    }
  },
  methods: {
    keyDownHandler(e) {

      if (e.keyCode === 27) {
        this.$emit('toggle-modal', { isOpen: false })

      }
      if (e.keyCode === 40) {
        const length = this.filteredResources.length
        if (this.selectionIndex < length - 1) {
          this.selectionIndex++
        }

      }
      if (e.keyCode === 38) {
        if (this.selectionIndex > 0) {
          this.selectionIndex--
        }

      }
      if (e.keyCode === 13) {
        this.selectResource(this.filteredResources[this.selectionIndex])

      }
    },
    selectResource(resource) {
      if (!resource) return
      this.$router.push({ path: resource.url });
      this.$emit('toggle-modal', { isOpen: false })
    }
  }
};
</script>

<style lang="scss">
@import "search";
</style>
