import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import router from "@/router";
import Apis from "@/services/Apis";
import { defaultSelectedSupplier } from "@/views/Supplier/inputs";
import { defaultSelectedSupplierAgent } from "@/views/SupplierAgent/inputs";

const state = {
  suppliers: [],
  supplier: { ...defaultSelectedSupplier },
  supplierAgents: [],
  supplierAgent: { ...defaultSelectedSupplierAgent },
  filteredSuppliers: [],
  loadingStatus: {
    saveSupplier: false,
    getSupplierById: false,
    deleteSupplier: false,
    saveSupplierAgent: false,
    getSupplierAgents: false,
    getSupplierAgentById: false,
    deleteSupplierAgent: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setSuppliers: (state, payload) => {
    state.suppliers = payload;
  },
  setSupplier: (state, payload) => {
    if (payload._id === "NEW") state.supplier = { ...defaultSelectedSupplier };
    else state.supplier = payload;
  },
  setSupplierAgents: (state, payload) => {
    state.supplierAgents = payload;
  },
  setSupplierAgent: (state, payload) => {
    if (payload._id === "NEW")
      state.supplierAgent = { ...defaultSelectedSupplierAgent };
    else state.supplierAgent = payload;
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  suppliers: (state) => state.suppliers,
  supplier: (state) => state.supplier,
  supplierAgents: (state) => state.supplierAgents,
  supplierAgent: (state) => state.supplierAgent,
  loadingStatus: (state) => state.loadingStatus,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveSupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveSupplier", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveSupplier,
      payload.supplier,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveSupplier", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteSupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteSupplier", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteSupplier,
      payload.supplier,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteSupplier", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getSuppliers: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSuppliers", status: true });
    var url = Apis.backendBaseUrl + Apis.getSuppliers;
    if (payload)
      url +=
        "?filterCriteria=" +
        payload.filterCriteria +
        "&filterValue=" +
        payload.filterValue;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getSuppliers", status: false });
      var suppliers = {};
      if (response) {
        suppliers = response.data.body;
        commit("setSuppliers", suppliers);
        if (payload?.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getSupplierById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSupplierById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getSupplierById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getSupplierById", status: false });
        if (response) {
          commit("setSupplier", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  saveSupplierAgent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveSupplierAgent", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveSupplierAgent,
      payload.supplierAgent,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveSupplierAgent", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteSupplierAgent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteSupplierAgent", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteSupplierAgent,
      payload.supplierAgent,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "deleteSupplierAgent",
          status: false,
        });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getSupplierAgents: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSupplierAgents", status: true });
    var url = Apis.backendBaseUrl + Apis.getSupplierAgents;
    if (payload)
      url +=
        "?filterCriteria=" +
        payload.filterCriteria +
        "&filterValue=" +
        payload.filterValue;
    // if (payload && payload.filterCriteria === 'name') url += '&filterValue=' + 'lastName' + payload.filterValue
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getSupplierAgents", status: false });
      var supplierAgents = {};
      if (response) {
        supplierAgents = response.data.body;
        commit("setSupplierAgents", supplierAgents);
      }
    });
  },
  getSupplierAgentById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getSupplierAgentById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getSupplierAgentById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", {
          api: "getSupplierAgentById",
          status: false,
        });
        if (response) {
          commit("setSupplierAgent", response.data.body);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getAdjacentSupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentSuppliers", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "supplier/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentSuppliers",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getAdjacent: ({ commit, state }, payload) => {
    commit("setLoadingStatus", {
      api: "getAdjacentSupplierAgent",
      status: true,
    });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl +
      "supplierAgent/" +
      Apis.getAdjacent +
      "?mode=" +
      mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getAdjacentSupplierAgent",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  getCardinalitySupplier: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getCardinalitySupplier", status: true });
    var url = Apis.backendBaseUrl + "supplier/" + Apis.getCardinality;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", {
        api: "getCardinalitySupplier",
        status: false,
      });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
