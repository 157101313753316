// Employee Editor Inputs
import Constants from "@/resources/Constants";
import { multiStoreRoles } from "@/constants/roles";
const storeId = localStorage.getItem("storeId");
// Page Header
export const listPageHeader = {
  title: "Employees",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "employeeEditor",
};

// Page Header
export const pageHeader = {
  title: "Employee",
  isEditorView: true,
  cancelPath: "employees",
  addPath: "employeeEditor",
  buttonLabel: "Save",
};

// Search By Options:
// • Id
// • Pin
// • Name (lastName + name) (default index)
// Columns (in order):
// • _id (column header clickable)
// • pin (column header clickable)
// • lastName+name (column header clickable)
// • city
// • state
// • phone
// • cell

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Last Name",
    code: "lastName",
    field: { type: "input", label: "Last Name" },
    isPartial: true,
  },
  {
    label: "First Name",
    code: "name",
    field: { type: "input", label: "First Name" },
    isPartial: true,
  },
  { label: "Pin", code: "pin", field: { type: "input", label: "Pin" } },
  {
    label: "Email",
    code: "email",
    field: { type: "input", label: "Email" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
];

// • _id (column header clickable)
// • pin (column header clickable)
// • lastName+name (column header clickable)
// • city
// • state
// • phone
// • cell

const readableRole = (roleCode) => {
  let roleFound = Constants.listOfStoreRoles.filter((r) => r.code == roleCode);
  if (roleFound && roleFound.length > 0) return roleFound[0].label;

  roleFound = Constants.listOfOrganizationRoles.filter(
    (r) => r.code == roleCode
  );
  if (roleFound && roleFound.length > 0) return roleFound[0].label;

  return roleCode;
};

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Employed",
    id: "isEmployeed",
    type: "checkbox",
  },
  {
    title: "Last Name",
    id: "lastName",
    size: "large",
  },
  {
    title: "First Name",
    id: "name",
    size: "large",
  },
  {
    title: "Pin",
    id: "pin",
    size: "medium",
  },
  {
    title: "Email",
    id: "email",
    size: "medium",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "Phone",
    id: "phone",
    size: "medium",
  },
  {
    title: "Role",
    dynamicValue(item) {
      const isGlobal = multiStoreRoles.includes(item.role);
      if (isGlobal) return readableRole(item.role);

      if (!item.stores || item.stores.length == 0) return undefined;

      if (item.stores.length == 1) return readableRole(item.stores[0].role);

      if (item.stores.length > 1) return "multi";
    },
  },
  {
    title: "Cell",
    id: "cell",
  },
  {
    title: "Id",
    id: "_id",
  },
];

export const searchColumnHeaders = [
  {
    title: "Select",
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
  },
  {
    title: "Last Name",
    id: "lastName",
  },
  {
    title: "First Name",
    id: "name",
  },
  {
    title: "Pin",
    id: "pin",
  },
  {
    title: "Email",
    id: "email",
  },
  {
    title: "State",
    id: "state",
  },
  {
    title: "City",
    id: "city",
  },
  {
    title: "Phone",
    id: "phone",
  },
  {
    title: "Cell",
    id: "cell",
  },
  {
    title: "Id",
    id: "_id",
  },
];

// Employee Information - Main Section
export const employeeInfoInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "name",
        placeholder: "First Name",
        type: "input",
        title: "First Name",
        required: true,
        testAttribute: "field-name",
        focus: true,
      },
      {
        id: "lastName",
        placeholder: "Last Name",
        type: "input",
        title: "Last Name",
        required: true,
        testAttribute: "field-last-name",
      },
      {
        id: "salutation",
        placeholder: "Select Salutation",
        type: "select",
        title: "Salutation",
        options: Constants.listOfSalutations,
      },
      {
        id: "middleName",
        placeholder: "Middle Name",
        type: "input",
        title: "Middle Name",
      },
      {
        id: "address",
        placeholder: "Address",
        type: "input",
        title: "Address",
        class: "grid-row-two-column",
      },
      {
        id: "city",
        placeholder: "City",
        type: "input",
        title: "City",
      },
      {
        id: "state",
        placeholder: "State",
        type: "select",
        title: "State",
        options: Constants.listOfStates,
      },
      {
        id: "zip",
        placeholder: "Zip",
        type: "mask",
        title: "Zip",
        mask: Constants.inputMasks.defaultZip,
      },
      {
        id: "county",
        placeholder: "County",
        type: "input",
        title: "County",
      },
      {
        id: "phone",
        placeholder: "Phone",
        type: "mask",
        title: "Phone",
        mask: Constants.inputMasks.defaultPhone,
        required: true,
        testAttribute: "field-phone",
      },
      {
        id: "cell",
        placeholder: "Cell",
        type: "mask",
        title: "Cell",
        mask: Constants.inputMasks.defaultPhone,
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isEmployeed",
        type: "checkBox",
        title: "Employed",
      },
      {
        id: "employmentDate",
        placeholder: "Employment date",
        type: "calendar",
        title: "Employment date",
      },
      {
        id: "emailPrivate",
        placeholder: "Private Email",
        type: "input",
        title: "Private Email",
      },
      // {
      //   id: "points",
      //   type: "money",
      //   title: "Points",
      //   precision: Constants.numericInput.defaultPrecision,
      // },
      {
        id: "referrals",
        type: "money",
        title: "Referrals",
        prefix: "",
        precision: Constants.numericInput.defaultPrecision,
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

// Verification Section
export const verificationInputs = [
  {
    id: "srn",
    placeholder: "SRN #",
    type: "input",
    title: "SRN #",
    //mask: Constants.inputMasks.defaultSrn,
  },
  {
    id: "verificationExpirationDate",
    placeholder: "Expiration Date",
    type: "calendar",
    title: "Expiration Date",
  },
  {
    id: "verificationDate",
    placeholder: "Verification date",
    type: "date",
    title: "Verification date",
    disabled: true,
    hide: "isVerified",
  },
  {
    id: "verifiedBy",
    placeholder: "Verified by",
    type: "name",
    title: "Verified by",
    disabled: true,
  },
];

export const terminationInputs = [
  {
    id: "terminationDate",
    placeholder: "Termination Date",
    type: "calendar",
    title: "Termination Date",
  },
  {
    id: "terminatedBy",
    placeholder: "Terminated by",
    type: "name",
    title: "Terminated by",
    disabled: true,
  },
];

export const traceabilityInputs = [
  {
    key: "any",
    id: "traceabilitySystem",
    placeholder: "Select Account Type",
    type: "select",
    title: "Traceability System",
    options: Constants.listOfCompatibleTraceabilitySystems,
  },
  {
    key: "biotrack",
    id: "traceabilityRegisteredName",
    placeholder: "Registered Name",
    type: "input",
    title: "Registered Name",
  },
  {
    key: "biotrack",
    id: "traceabilityPassword",
    placeholder: "Password",
    type: "input",
    title: "Password",
  },
  {
    key: "metrc",
    id: "traceabilityUserKey",
    placeholder: "User Key",
    type: "input",
    title: "User Key",
  },
];

export const newTraceabilityInputs = [
  {
    model: "store",
    id: "store",
    placeholder: "Select Store",
    type: "select",
    title: "Store",
    options: [],
  },
  {
    model: "traceabilitySystem",
    id: "traceabilitySystem",
    placeholder: "Select System",
    type: "select",
    title: "System",
    options: Constants.listOfCompatibleTraceabilitySystems,
    label: "code",
  },
  {
    model: "username",
    id: "traceabilityRegisteredName",
    placeholder: "Username",
    type: "input",
    title: "Username",
  },
  {
    model: "password",
    id: "traceabilityPassword",
    placeholder: "Password",
    type: "input",
    title: "Password",
    inputType: 'password'
  },
];

export const systemAccessInputs = [
  {
    id: "email",
    type: "input",
    title: "Email",
    placeholder: "Email",
    required: true,
    testAttribute: "field-email",
  },
  {
    id: "password",
    type: "input",
    title: "Password",
    placeholder: "Password",
    inputType: "password",
    required: true,
    testAttribute: "field-password",
  },
  {
    id: "confirmedPassword",
    type: "input",
    title: "Confirm Password",
    placeholder: "Confirm Password",
    inputType: "password",
    required: true,
    testAttribute: "field-password-confirmation",
  },
  {
    id: "pin",
    type: "mask",
    title: "Pin",
    placeholder: "Pin",
    mask: Constants.inputMasks.defaultPin,
  },
];

export const storeAccessInputs = [
  {
    id: "name",
    secondId: true,
    title: "Store Name",
    type: "text",
  },
  {
    id: "address",
    secondId: true,
    title: "Store Address",
    type: "text",
  },
  {
    id: "isAccessGranted",
    type: "checkBox",
    title: "Grant Access",
  },
  {
    id: "role",
    type: "select",
    title: "Store Role",
    options: Constants.listOfStoreRoles,
  },
];

export const notesInputs = [
  {
    id: "noteType",
    type: "select",
    placeholder: "Select Role",
    title: "Note Type",
    options: Constants.listOfEmploymentNoteTypes,
  },
  {
    id: "hours",
    type: "number",
    title: "Hours",
    precision: 2,
    placeholder: "Hours",
  },
  {
    id: "note",
    type: "textarea",
    title: "Notes",
    placeholder: "Notes",
    class: "flex-break-row",
  },
];

export const defaultSelectedEmployee = {
  _id: "NEW",
  password: "",
  confirmedPassword: "",
  name: "",
  lastName: "",
  salutation: "",
  middleName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  county: "",
  phone: "",
  cell: "",
  email: "",
  notes: "",
  pin: 0,
  isEmployeed: false,
  employmentDate: "",
  terminationDate: "", // todo
  hasSafeAccess: false, // todo safe access maybe per store
  safeAccessStartDate: "", // todo safe access
  safeAccessEndDate: "", // todo safe access
  srn: "",
  verificationExpirationDate: "",
  verificationDate: "",
  idPicture: "",
  srnCardPicture: "",
  points: 0,
  referrals: 0,
  verificationNotes: "",
  ssn: "",
  dob: "",
  payrollId: 0,
  wageType: "",
  wage: 0,
  isPartTime: false,
  lastW4: "",
  allowances: 0,
  noteList: [],
  roles: [],
  role: "",
  store: undefined,
  stores: [],
  organization: {},
  verifiedBy: {},
  createdBy: {},
  updatedBy: {},
  terminatedBy: {},
  traceabilitySystem: { label: "None", code: "none" },
  traceabilityCredentials: [],
};
