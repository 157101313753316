export default {
  calculatePayments(order) {
    const payments = {
      cash: 0,
      "canpay-remote": 0,
      "canpay-retail": 0,
      "credit-memo": 0,
      "credit-card": 0,
      "debit-card": 0,
      "gift-card": 0,
      total: 0,
      changeDue: 0,
      pastOrderChangeDue: 0,
      cashCollected: 0
    };

    try {
      for (const payment of order.payments) {
        if (payment.isArchived) {
          continue
        }

        // If canpay order didn't go through ignore it from calculation
        if (payment.type == 'canpay-retail' && !payment.canpayPaymentVerification) {
          return
        }

        if (payment.type == 'debit-card' && payment.amountPaid != 0) {
          payments[payment.type] += payment.cardTotal * 1; // since they don't pay the exact amount (but usually more). this represents the actoul payment of the debit card
          payments.changeDue += payment.changeDue
          payments.pastOrderChangeDue += payment.pastOrderChangeDue
        } else {
          payments[payment.type] += payment.amountPaid * 1;
        }

        payments.total += payment.amountPaid * 1;

        if (payment.type == 'cash') {
          payments.changeDue += payment.changeDue
          payments.cashCollected += payment.cashCollected
        }
      }
      return payments;
    } catch (error) {
      return payments;
    }
  },
};
