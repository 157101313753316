import { getDefaultChartData } from "@/views/dashboards/chart.helpers.js";

export const getDefaultSalesBySourceChartData = (theme) => {
  const orderTypeChartData = getDefaultChartData("pie", theme);
  return orderTypeChartData;
};

export const populateSalesBySourceChartData = (orderTypeChartData, newData) => {
  const data = newData?.total || {};
  orderTypeChartData.data.labels = ["POS", "Online Store", "Kiosk"];
  orderTypeChartData.data.datasets[0].data = [
    data.pos || 0,
    data["online-store"] || 0,
    data.kiosk || 0,
  ];
  console.log(newData);
  console.log(orderTypeChartData);
  return orderTypeChartData;
};
