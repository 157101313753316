import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import Apis from "@/services/Apis";
import { defaultSelectedStore } from "@/views/Store/inputs";

const state = {
  store: { ...defaultSelectedStore },
  stores: [],
  userStores: [],
  currentStore: {},
  loadingStatus: {
    saveStore: false,
    getStoreById: false,
    getCurrentStore: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setStores: (state, payload) => {
    state.stores = payload;
  },
  setStore: (state, payload) => {
    if (payload._id === "NEW") state.store = { ...defaultSelectedStore };
    else state.store = { ...defaultSelectedStore, ...payload };
    LocalStorageManager.setLocalStorageKey("storeId", state.store._id);
  },
  setCurrentStore: (state, payload) => {
    state.currentStore = payload;
    LocalStorageManager.setLocalStorageKey("storeId", state.currentStore._id);
  },
  setUserStores: (state, payload) => {
    state.userStores = payload;
  },
  changeStore: (state, payload) => {
    state.store = payload;
    LocalStorageManager.setLocalStorageKey("storeId", payload.store._id);
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  stores: (state) => state.stores,
  store: (state) => state.store,
  currentStore: (state) => state.currentStore,
  userStores: (state) => state.userStores,
  loadingStatus: (state) => state.loadingStatus,
  getWorkstations: (state) => state.store?.workstations,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  setStore: ({ commit, dispatch }, payload) => {
    commit("setCurrentStore", payload);
    dispatch("loginModule/setRole", payload, { root: true });
  },
  saveStore: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "saveStore", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveStore,
      payload.store,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveStore", status: false });
        var store = {};

        if (response) {
          store = response.data.body;
          commit("setStore", store);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(store);
          }
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getStores: ({ commit, state, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("setLoadingStatus", { api: "getStores", status: true });
      let url = Apis.backendBaseUrl + Apis.getStores + "?";

      if (payload?.filterCriteria && payload?.filterValue) {
        url +=
          `&filterCriteria=${payload.filterCriteria}` +
          `&filterValue=${payload.filterValue}`;
      }
      HttpService.get(url, function (response, error) {
        commit("setLoadingStatus", { api: "getStores", status: false });
        var stores = {};

        if (response) {
          stores = response.data.body;
          commit("setStores", stores);

          if (payload.isFirstLoad) {
            if (stores && stores.length > 0) {
              dispatch("setStore", selectStoreAtFirstLoad(stores));
            }
            if (stores && stores.length > 1 && !localStorage.getItem("store-selection-ack")) {
              dispatch("setStoreSelectionPopUp", {isOpen: true}, { root: true });
              localStorage.setItem("store-selection-ack", true)
            }
          }

          if (payload && payload.positiveCallback) {
            payload.positiveCallback(stores);
          }
          resolve(stores);
        } else {
          reject();
        }
      });
    });
  },
  getStoreById: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getStoreById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getStoreById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getStoreById", status: false });
        var store = {};

        if (response) {
          store = response.data.body;
          commit("setStore", store);
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(store);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getCurrentStore: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getCurrentStore", status: true });
    const storeId = LocalStorageManager.getLocalStorageKey("storeId");
    HttpService.get(
      Apis.backendBaseUrl + Apis.getStoreById + "?id=" + storeId,
      function (response, error) {
        commit("setLoadingStatus", { api: "getCurrentStore", status: false });
        var store = {};

        if (response) {
          store = response.data.body;
          commit("setCurrentStore", store);
          // We need to update the local selected printers if their configuration changed
          commit("updateSelectedLabelPrinterFromStore", store, { root: true });

          if (payload && payload.positiveCallback) {
            payload.positiveCallback(store);
          }
        } else {
          commit(
            "setMessage",
            { text: error?.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getUserStores: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getUserStores", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getUserStores,
      function (response, error) {
        commit("setLoadingStatus", { api: "getUserStores", status: false });
        var stores = {};

        if (response) {
          stores = response.data.body;
          stores = stores.filter((s) => s && s._id);
          commit("setStores", stores);

          if (payload.isFirstLoad) {
            if (stores && stores.length > 0) {
              dispatch("setStore", selectStoreAtFirstLoad(stores));
            }
            if (stores && stores.length > 1 && !localStorage.getItem("store-selection-ack")) {
              dispatch("setStoreSelectionPopUp", {isOpen: true}, { root: true });
              localStorage.setItem("store-selection-ack", true)
            }
          }

          if (payload && payload.positiveCallback) {
            payload.positiveCallback(stores);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  changeStore: ({ commit, state, dispatch }, payload) => {
    dispatch("setStore", payload);
    dispatch("posModule/setSelectedWorkstation", undefined, { root: true });
    dispatch("orderModule/setPosOrder", undefined, { root: true });
    commit(
      "setMessage",
      { text: `Store changed to ${payload.name}`, type: "success" },
      { root: true }
    );
    // dispatch(
    //   "employeeModule/changeStore",
    //   {
    //     store: payload.store,
    //     positiveCallback: () => {
    //       commit(
    //         "setMessage",
    //         { text: `Store changed to ${payload.store.name}`, type: "success" },
    //         { root: true }
    //       );
    //     },
    //   },
    //   { root: true }
    // );
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

function selectStoreAtFirstLoad(stores) {
  let filteredStores = stores.filter((s) => s.isDeleted == false);
  if (filteredStores.length == 0) return;

  const storeId = LocalStorageManager.getLocalStorageKey("storeId");

  const foundStore = stores.find((s) => s._id == storeId);
  if (foundStore) {
    return foundStore;
  }

  return filteredStores[0];
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
