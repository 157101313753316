<template>
  <div class="search-table">
    <slot name="filter">
      <div class="entity-filter-wrapper">
        <EntityFilter
          v-if="selectedFilter && !hideFilters"
          :selectedFilter="selectedFilter"
          :selectedFilter2="selectedFilter2"
          :filterCriteria="filterCriteria"
          :filterButtons="filterButtons"
          :filterButtons2="filterButtons2"
          @criteriaChange="performSetSelectedFilter"
          @valueChange="
            (filters, clearPagination) =>
              searchItems(filters, clearPagination, true)
          "
          @filterButtonClicked="searchItems"
          @filterButton2Clicked="searchItems"
          :entity="getFilterEntity"
          :enableAdditionalFilter="enableAdditionalFilter"
        >
        <template #buttons>
          <slot></slot>
        </template>
      </EntityFilter>
      </div>
    </slot>
    <Table
      v-if="heightOfTable"
      :data="data"
      :columnHeaders="columnHeaders"
      :loadingStatus="false"
      :entity="entity"
      :pageNumber="pageNumber"
      :cardinality="cardinality"
      :currentPageNumber="currentPageNumber"
      :lastArrowsEnalbled="lastArrowsEnalbled"
      :firstArrowEnabled="firstArrowEnabled"
      @get-next-entities="getNextEntities"
      @select-item="selectItem"
      :height="heightOfTable"
      :sort="sort_"
      @sortChange="handleSortChange"
      :searchTableLoadingStatus="loadingStatus"
      :enableCardniality="enableCardniality"
      :selectable="selectable"
      @itemsSelected="(items) => $emit('itemsSelected', items)"
      :clearSelected="clearSelected"
    />
  </div>
</template>

<script>
import EntityFilter from "./EntityFilter/EntityFilter.vue";
import Table from "./Table.vue";

import {
  filterCriteriaDictionary,
  columnHeadersDictionary,
} from "@/utils/table-lists";
import { searchEntities, getCardinality } from "@/services/entityService";
import { dataMapper } from "@/utils/entityDataMappers";
import { mapGetters } from "vuex";
import Entities from "@/resources/Entities";

export default {
  name: "SearchTable",
  components: {
    EntityFilter,
    Table,
  },
  props: {
    title: {
      type: String,
    },
    entity: {
      type: String,
    },
    layoutEntity: {
      type: String,
    },
    filterButtons: {
      type: Array,
    },
    filterButtons2: {
      type: Array,
    },
    preFilter: {
      type: String,
    },
    hideFilters: {
      type: Boolean,
    },
    defaultColumnHeaders: {
      type: Array,
    },
    height: {
      type: Number,
    },
    // This filter will be always merged with the fitering user actions
    filter: {
      type: Object,
    },
    // This filter will be always merged with the fitering user actions
    defaultFilter: {
      type: Object,
    },
    // This is used to pass an inital search filter to keep the search
    // filter during navigation among entities
    initialFilter: {
      type: Object,
    },
    sort: {
      type: Object,
      default: () => ({ createdAt: -1 }),
    },
    lastArrowsEnalbled: {
      type: Boolean,
      default: true,
    },
    firstArrowEnabled: {
      type: Boolean,
      default: true,
    },
    // pagination provided from parent to ensure keeping the same
    // page when navigating between pages
    pagination: {
      type: Object,
    },
    enableCardniality: {
      type: Boolean,
      default: true,
    },
    enableAdditionalFilter: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    clearSelected: {
      type: Number,
    }
  },
  data() {
    return {
      filterCriteria: [],
      columnHeaders: [],
      filterCriteriaDictionary,
      data: [],
      skip: 0,
      limit: 20,
      cardinality: undefined,
      numberOfEntities: 0,
      itemFilter: undefined,
      selectedFilterButton: undefined,
      selectedFilterButton2: undefined,
      selectedFilter: undefined,
      selectedFilter2: undefined,
      heightOfTable: 0,
      sort_: {},
      loadingStatus: {
        isFilterLoading: false,
      },
    };
  },
  created() {
    this.sort_ = this.sort;
    if (this.pagination) {
      this.skip = this.pagination.skip || 0;
      this.limit = this.pagination.limit || 20;
    }
    this.selectedFilterButton = this.filterButtons?.find((fb) => fb.isSelected);
    this.selectedFilterButton2 = this.filterButtons2?.find(
      (fb) => fb.isSelected
    );
  },
  mounted() {
    this.filterCriteria = filterCriteriaDictionary[this.layoutEntity ?? this.entity];

    if (!this.defaultColumnHeaders)
      this.columnHeaders = columnHeadersDictionary[this.layoutEntity ?? this.entity];
    else this.columnHeaders = this.defaultColumnHeaders;

    if (this.initialFilter) {
      this.selectedFilter = this.initialFilter;
      this.searchItems({ filter: this.selectedFilter, filter2: this.selectedFilter2 });
      this.selectedFilter2 = {
        criteria: this.filterCriteria[1],
        value: undefined,
      };
    } else {
      this.selectedFilter = {
        criteria: this.filterCriteria[0],
        value: undefined,
      };
      this.selectedFilter2 = {
        criteria: this.filterCriteria[1],
        value: undefined,
      };
      this.searchItems();
    }
  },
  updated() {
    this.heightOfTable = this.getTableHeight();
  },
  computed: {
    currentPageNumber() {
      if (this.cardinality === 0) return 0;
      return Math.ceil((this.skip + 1) / this.limit);
    },
    pageNumber() {
      return Math.ceil(this.cardinality / this.limit);
    },
    ...mapGetters({
      refetchTableData: "stateModule/refetchTableData",
    }),
    getFilterEntity(){
      return this.layoutEntity ?? this.entity;
    }
  },
  watch: {
    refetchTableData: function (updatedItem) {
      if (updatedItem?._id) {
        this.data = this.data.map((item) =>
          item._id === updatedItem._id ? updatedItem : item
        );
      } else {
        if(updatedItem?.entityChanged){
          this.filterCriteria = filterCriteriaDictionary[this.layoutEntity ?? this.entity];

          if (!this.defaultColumnHeaders)
            this.columnHeaders = columnHeadersDictionary[this.layoutEntity ?? this.entity];
          else this.columnHeaders = this.defaultColumnHeaders;

          if (this.initialFilter) {
            this.selectedFilter = this.initialFilter;
            this.searchItems({ filter: this.selectedFilter, filter2: this.selectedFilter2 });
            this.selectedFilter2 = {
              criteria: this.filterCriteria?.[1],
              value: undefined,
            };
          } else {
            this.selectedFilter = {
              criteria: this.filterCriteria?.[0],
              value: undefined,
            };
            this.selectedFilter2 = {
              criteria: this.filterCriteria?.[1],
              value: undefined,
            };
            this.searchItems();
          }
        }
        const clearPagination = updatedItem?.clearPagination ?? false;
        const filter = this.selectedFilter;
        const filter2 = this.selectedFilter2
        this.searchItems({ filter, filter2 }, clearPagination);
      }
    },
    selectedFilter: function (updatedFilter) {
      // save a copy in the store to allow keeping the filter
      // while navigating among entities
      this.$emit("initialFilterUpdate", updatedFilter);
    },
  },
  methods: {
    getNextEntities: function (type, isDisabled) {
      const canSearchEntities = !isDisabled;
      if (type === "next") {
        if (!isDisabled) this.skip = this.skip + this.limit;
      } else if (type === "prev") {
        if (!isDisabled) this.skip = this.skip - this.limit;
      } else if (type === "first") {
        if (!isDisabled) this.skip = 0;
      } else if (type === "last") {
        if (!isDisabled)
          this.skip =
            Math.ceil(this.cardinality / this.limit) * this.limit - this.limit;
      }

      this.$emit("paginationChange", { skip: this.skip, limit: this.limit });

      if (canSearchEntities) {
        this.searchItems({ filter: this.selectedFilter, filter2: this.selectedFilter2 });
      }
    },
    performSetSelectedFilter({ filter, filter2 }) {
      this.skip = 0;
      this.limit = 20;
      this.$emit("paginationChange", { skip: this.skip, limit: this.limit });

      if (filter) {
        this.selectedFilter = { ...filter };
        this.$forceUpdate();
        if (!filter.value && !filter.criteria?.code) this.searchItems();
      }

      if (filter2) {
        this.selectedFilter2 = { ...filter2 };
        this.$forceUpdate();
        if (!filter2.value && !filter2.criteria?.code) this.searchItems();
      }
    },
    updateFilterButtons(filterButton) {
      const updatedFilterButtons = this.filterButtons.map((button) => {
        if (button._id === filterButton._id) {
          if (button.isSelected) this.selectedFilterButton = undefined;
          else {
            this.selectedFilterButton = filterButton;
          }
          return {
            ...button,
            isSelected: !button.isSelected,
          };
        } else
          return {
            ...button,
            isSelected: false,
          };
      });
      this.$emit("filterButtonsChange", updatedFilterButtons);
    },
    updateFilterButtons2(filterButton) {
      const updatedFilterButtons = this.filterButtons2.map((button) => {
        if (button._id === filterButton._id) {
          if (button.isSelected) this.selectedFilterButton2 = undefined;
          else {
            this.selectedFilterButton2 = filterButton;
          }
          return {
            ...button,
            isSelected: !button.isSelected,
          };
        } else
          return {
            ...button,
            isSelected: false,
          };
      });
      this.$emit("filterButtonsChange2", updatedFilterButtons);
    },
    handleSortChange(sort) {
      this.sort_ = sort;
      var filter = this.selectedFilter;
      var filter2 = this.selectedFilter2
      this.searchItems({ filter, filter2 }, true, true);
      this.$emit("sortChangeEvent", sort);
    },
    searchItems: async function (
      filters,
      clearPagination,
      isFilterEvent = false
    ) {
      let previousSelectedFilterButtonFilter = {};
      let previousSelectedFilterButtonFilter2 = {};
      if (this.selectedFilterButton?.filter) {
        previousSelectedFilterButtonFilter = JSON.parse(
          JSON.stringify(this.selectedFilterButton.filter)
        );
      }
      if (this.selectedFilterButton2?.filter) {
        previousSelectedFilterButtonFilter2 = JSON.parse(
          JSON.stringify(this.selectedFilterButton2.filter)
        );
      }
      if (isFilterEvent) {
        this.selectedFilter.value = filters?.filter?.value;
        this.selectedFilter2.value = filters?.filter2?.value;
      }
      if (filters?.filterButton) {
        this.updateFilterButtons(filters.filterButton);
        if (filters.filterButton.showAll) {
          this.limit = undefined;
          this.skip = undefined;
        } else {
          this.skip = 0;
          this.limit = 20;
        }
      }
      if (filters?.filterButton2) {
        this.updateFilterButtons2(filters.filterButton2);
        if (filters.filterButton2.showAll) {
          this.limit = undefined;
          this.skip = undefined;
        } else {
          this.skip = 0;
          this.limit = 20;
        }
      }
      const filter = filters?.filter;
      if (clearPagination) {
        this.skip = 0;
        this.limit = 20;
      }
      this.$emit("paginationChange", { skip: this.skip, limit: this.limit });
      let mongoFilter = {};
      // always add the filter passed from parent component
      if (this.filter) {
        mongoFilter = JSON.parse(JSON.stringify(this.filter));
      }
      if (this.selectedFilterButton) {
        // Remove fields set by previous slected filter button
        for (let field of Object.keys(previousSelectedFilterButtonFilter)) {
          delete mongoFilter[field];
        }
        // Then applies fields set by current selected filter button
        mongoFilter = { ...mongoFilter, ...this.selectedFilterButton.filter };
      }
      if(this.selectedFilterButton2) {
        for (let field of Object.keys(previousSelectedFilterButtonFilter2)) {
          delete mongoFilter[field];
        }
        mongoFilter = { ...mongoFilter, ...this.selectedFilterButton2.filter };
      }
      // always add the filter passed from parent component
      if (this.defaultFilter) {
        mongoFilter = { ...mongoFilter, ...this.defaultFilter };
      }
      if (this.enableCardniality) {
        getCardinality({
          entity: this.entity,
          filterCriteria: filter?.criteria?.code,
          isPartial: filter?.criteria?.isPartial,
          filterValue: filter?.value || this.selectedFilter.value,
          preProcessFilterValue: filter?.criteria?.preProcessFilterValue,
          filterCriteria2: filters?.filter2?.criteria?.code,
          isPartial2: filters?.filter2?.criteria?.isPartial,
          filterValue2: filters?.filter2?.value,
          preProcessFilterValue2:
            filters?.filter2?.criteria?.preProcessFilterValue,
          preFilter: this.preFilter,
          filter: mongoFilter,
          sort: this.sort_,
          positiveCallback: (data) => {
            this.cardinality = data;
            this.numberOfEntities = data;
          },
        });
      }
      this.loadingStatus.isFilterLoading = true;
      searchEntities({
        entity: this.entity,
        filterCriteria: filter?.criteria?.code,
        filterValue: filter?.value || this.selectedFilter.value,
        isPartial: filter?.criteria?.isPartial,
        preProcessFilterValue: filter?.criteria?.preProcessFilterValue,
        filterCriteria2: filters?.filter2?.criteria?.code,
        filterValue2: filters?.filter2?.value,
        isPartial2: filters?.filter2?.criteria?.isPartial,
        preProcessFilterValue2:
          filters?.filter2?.criteria?.preProcessFilterValue,
        skip: this.skip,
        limit: this.limit,
        //itemFilter: this.selectedFilterButton,
        preFilter: this.preFilter,
        filter: mongoFilter,
        sort: this.sort_,
        positiveCallback: (data) => {
          // placing this fix because open orders from online store can be createdAt "very old" and the user gets confused
          let extraFlag = false;
          if (this.entity == Entities.ORDER) {
            const selectedFilterIsOpenOrders = this.selectedFilterButton
              ? this.selectedFilterButton.name
              : "";
            if (selectedFilterIsOpenOrders == "open-orders") {
              extraFlag = true;
            }
          }
          if (dataMapper[this.entity]) {
            this.data = dataMapper[this.entity](data, extraFlag);
          } else this.data = data;
          this.loadingStatus.isFilterLoading = false;
        },
        negativeCallback: () => {
          this.loadingStatus.isFilterLoading = false;
        },
      });
    },
    handleButtonClicked(type, id, item) {
      if (this.$parent.handleButtonClicked)
        this.$parent.handleButtonClicked(type, id, item);
    },
    selectItem(id, item, nestedId) {
      this.$emit("select-item", id, item, nestedId);
    },
    getTableHeight() {
      let entityFilterHeight =
        document.getElementById("entity-filter")?.offsetHeight || 0;
      if (this.height) {
        return this.height - entityFilterHeight;
      }
      let pageHeader =
        document.getElementById("page-header-wrapper")?.offsetHeight || 0;
      let appBody = document.getElementById("app-body")?.offsetHeight || 0;
      return appBody - pageHeader - entityFilterHeight;
    },
  },
};
</script>

<style scoped lang="scss">
.search-filters {
  margin: 5px 0;
  display: flex;

  &__filter-by {
    width: 200px;
    margin-right: 30px;
  }

  &__label {
    margin-bottom: 10px;
  }
}

.search-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
