import HttpService from "@/services/HttpService";
import LocalStorageManager from "@/lib/LocalStorageManager";
import router from "@/router";
import Apis from "@/services/Apis";
import { multiStoreRoles } from "@/constants/roles";
import store from "../index";

const state = {
  user: {},
  loadingStatus: {
    login: false,
  },
  role: "",
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  login: (state, payload = {}) => {
    if (!payload) {
      state.user = {};
      state.user.isLogged = false;
      return;
    }
    state.user = payload;
    state.user.isLogged = true;
    LocalStorageManager.setLocalStorageKey("user", JSON.stringify(state.user));
    LocalStorageManager.setLocalStorageKey("token", state.user.token);
    LocalStorageManager.setLocalStorageKey(
      "refreshToken",
      state.user.refreshToken
    );
    LocalStorageManager.setLocalStorageKey("_id", state.user._id);
    LocalStorageManager.setLocalStorageKey(
      "organizationId",
      state.user.organization
    );
    const isGlobal = multiStoreRoles.includes(state.user.role);
    if (!isGlobal) {
      store.dispatch(
        "storeModule/getUserStores",
        { isFirstLoad: true },
        { root: true }
      );
    } else {
      store.dispatch(
        "storeModule/getStores",
        { isFirstLoad: true },
        { root: true }
      );
    }
  },
  logout: (state) => {
    state.user = { isLogged: false };
    LocalStorageManager.deleteLocalStorageKey("user");
    LocalStorageManager.deleteLocalStorageKey("token");
    LocalStorageManager.deleteLocalStorageKey("refreshToken");
    LocalStorageManager.deleteLocalStorageKey("_id");
    LocalStorageManager.deleteLocalStorageKey("organizationId");
    LocalStorageManager.deleteLocalStorageKey("employeeId");
    LocalStorageManager.deleteLocalStorageKey("drawer");
    LocalStorageManager.deleteLocalStorageKey("orderId");
    LocalStorageManager.deleteLocalStorageKey("workstation");
    LocalStorageManager.deleteLocalStorageKey("labelPrinter");
    LocalStorageManager.deleteLocalStorageKey("role");
    LocalStorageManager.deleteLocalStorageKey("store-selection-ack");
  },
  setRole: (state, role) => {
    state.role = role;
    LocalStorageManager.setLocalStorageKey("role", role);
  },
};

const getters = {
  user: (state) => state.user,
  loadingStatus: (state) => state.loadingStatus,
  role: (state) => state.role,
};

const actions = {
  login: ({ commit, state, dispatch }, payload) => {
    var body = {
      email: payload.email,
      password: payload.password,
    };
    commit("setLoadingStatus", { api: "login", status: true });

    HttpService.post(
      Apis.backendBaseUrl + Apis.login,
      body,
      function (response, error) {
        commit("setLoadingStatus", { api: "login", status: false });
        var user = {};

        if (response) {
          user = response.data.body;
          commit("login", user);
          router.push({ name: "home" });
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  setRole: ({ commit, state }, store) => {
    const user = state.user;
    const isGlobal = multiStoreRoles.includes(user.role);

    if (!user) return;

    let storeId = store?._id;
    if (!storeId) {
      storeId = store;
    }

    if (isGlobal) {
      commit("setRole", user.role);
    } else {
      const stores = user.stores;
      if (!stores || stores.length == 0) return;
      if (storeId) {
        const foundStore = stores.filter((s) => s.store == storeId);
        if (foundStore && foundStore.length > 0) {
          commit("setRole", foundStore[0].role);
        } else {
          commit("setRole", stores[0].role);
        }
      } else {
        commit("setRole", stores[0].role);
      }
    }
  },
  logout: ({ commit, state }, payload) => {
    commit("logout");
    router.push({ name: "login" });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
