import moment from "moment";
import Constants from "@/resources/Constants";
import Utilities from "@/lib/Utilities";
import Entities from "@/resources/Entities";
import { getDefaultExpirationDate } from "@/views/PurchaseOrder/get-default-expiration-date";

export const formatName = (entity) => {
  if (entity?.name && entity?.lastName)
    return `${entity.name} ${entity.lastName}`;
  else if (entity?.name) return entity.name;
  else if (entity?.lastName) return entity.lastName;
  else return null;
};

export const formatGeneric = function (items) {
  return items.map((item) => {
    return {
      ...item,
      createdBy: formatName(item.createdBy),
      updatedBy: formatName(item.updatedBy),
    };
  });
};

export const formatEmployees = function (employees) {
  return employees.map((item) => {
    return {
      ...item,
      createdBy: formatName(item.createdBy),
      updatedBy: formatName(item.updatedBy),
    };
  });
};

export const formatOrders = function (orders, replaceCreatedAtWithUpdatedAt) {
  return orders.map((order) => {
    return {
      ...order,
      createdAt: replaceCreatedAtWithUpdatedAt ? order.updatedAt : order.createdAt,
    };
  });
};

export const formatPurchaseOrders = function (items) {
  return items.map((item) => {
    return {
      ...item,
      createdBy: formatName(item.createdBy),
      updatedBy: formatName(item.updatedBy),
    };
  });
};

export const formatProducts = function (items) {
  return items.map((item) => {
    return {
      ...item,
      createdBy: formatName(item.createdBy),
      updatedBy: formatName(item.updatedBy),
    };
  });
};

export const dataMapper = {
  [Entities.EMPLOYEE]: formatEmployees,
  [Entities.GROUP]: formatGeneric,
  [Entities.CUSTOMER]: formatGeneric,
  [Entities.PRODUCT]: formatProducts,
  [Entities.ITEM]: formatGeneric,
  [Entities.ONLINESTOREGROUP]: formatGeneric,
  [Entities.ORDER]: formatOrders,
  [Entities.ORGANIZATION]: formatGeneric,
  [Entities.PURCHASEORDER]: formatPurchaseOrders,
  [Entities.ROOM]: formatGeneric,
  [Entities.SPECIALPRICING]: formatGeneric,
  [Entities.STORE]: formatGeneric,
  [Entities.SUPPLIER]: formatGeneric,
  [Entities.SUPPLIERAGENT]: formatGeneric,
};
