var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Sales Recap By Employee","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'salesReport', label: 'Sales' },
        {
          name: 'salesRecapOrderEmployee',
          label: 'Sales Recap By Employee',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.salesTransactions},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_vm._l((Object.entries(
            _vm.reportData.employees
          )),function(ref){
          var employeeId = ref[0];
          var employee = ref[1];
return _c('div',{key:employeeId,staticClass:"report-body__employee-container"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0,true),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((employee.days),function(record,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(record.date))]),_c('td',[_vm._v(_vm._s(record.employee))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.subTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.discount))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.lineTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(record.tax)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.freight))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.total))+" ")])])})],2),_vm._m(1,true),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.subTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.discount))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.lineTotal))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.tax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.freight))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(employee.total.total))+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2,true)])])}),(
            (_vm.loaded = true && Object.keys(_vm.reportData.employees).length === 0)
          )?_c('div',{staticClass:"table__no-data-message"},[_vm._v(" No Data ")]):_vm._e()],2):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Empl")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Sub Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Discount")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Line Total")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Tax")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Delivery")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Total")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }