<template>
  <div>
    <h1 class="store-name">{{ store.name }}</h1>
    <hr class="divider" />
    <div class="store-info">
      <h3>{{ store.address }}</h3>
      <h3>{{ store.phone }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
