import { filterCriteria as creditMemoFilterCriteria } from "@/views/CreditMemo/inputs";

import * as Entities from "@/resources/Entities";

export const pointsColumns = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Customer #",
    id: "customer._id",
  },
  {
    title: "Memo Type",
    id: "memoType",
  },
  {
    title: "Balance",
    id: "memoBalance",
  },
  {
    title: "Used",
    id: "memoAmountUsed",
  },
  {
    title: "Total",
    id: "memoTotal",
  },
];

export const inputs = [
  {
    id: "points",
    placeholder: "Select Points",
    type: "search",
    title: "Points",
    label: "name",
    class: "grid-row-two-column",
    entity: Entities.CREDITMEMO,
    columnHeaders: pointsColumns,
    filterCriteria: creditMemoFilterCriteria,
    isClearable: true,
    fieldsToDisplay: [{ label: "Id", id: "_id" }],
    hideFilter: true,
    filter: {
      memoType: "Points",
      memoBalance: { $gt: 0 },
      isPostedToJournal: true,
      isArchived: false,
    },
  },
];
