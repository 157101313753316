// Store Editor
import * as Entities from "@/resources/Entities";
import Constants from "@/resources/Constants";
import {
  searchColumnHeaders as customerColumnHeaders,
  filterCriteria as customerFilterCriteria,
} from "../Customer/inputs";

// Page Header
export const pageHeader = {
  title: "Customer Check-in",
  isEditorView: false,
};

export const recreationalCustomerInputs = [
  {
    id: "dl",
    type: "input",
    title: "License/ID #",
    placeholder: "License/ID #",
  },
  {
    id: "firstName",
    type: "input",
    title: "First Name *",
    placeholder: "First Name",
  },
  {
    id: "lastInitial",
    type: "input",
    title: "Last Name Initial",
    placeholder: "Last Name Initial",
  },
  {
    id: "country",
    placeholder: "Country",
    type: "select",
    title: "Country",
    options: Constants.countries,
  },
  {
    id: "state",
    placeholder: "State *",
    type: "select",
    title: "State",
    options: Constants.listOfStates,
  },
];

export const findCustomerInput = {
  id: "customer",
  type: "search",
  title: "Customer",
  label: "customer",
  entity: Entities.CUSTOMER,
  columnHeaders: customerColumnHeaders,
  filterCriteria: customerFilterCriteria,
  filter: {
    $or: [
      { accountType: "recreational" },
      { accountType: "medical-and-recreational" },
    ],
  },
};
