<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        title="State Inventory"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'reports', label: 'Reports' },
          { name: 'stateInventory', label: 'State Inventory' },
        ]"
        :pageHeader="listPageHeader"
        :entity="entity"
        :hideAddButton="true"
        @export="exportInCSV"
        :disableExport="isLoading"
      />
      <br class="no-print" />
      <div>
        <StateInventoryList />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import { mapActions, mapGetters } from "vuex";
import StateInventoryList from "./components/StateInventoryList.vue";
import moment from "moment";
// import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Entities from "@/resources/Entities";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import Constants from "@/resources/Constants";
import ReportHelper from "@/lib/report/index.js";

export const listPageHeader = {
  title: "State Inventory",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "employeeEditor",
};

export default {
  name: "stateInventory",
  data() {
    return {
      moment: moment,
      Constants: Constants,
      Utilities: Utilities,
      listPageHeader,
      entity: Entities.STATEINVENTORY,
    };
  },
  components: {
    PageHeader,
    // ReportFilter,
    StateInventoryList,
  },
  created: function () {
    this.setPageTitle("State Inventory");
  },
  mounted: function () {
    this.getStateInventory();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "stateModule/loadingStatus",
      stateInventory: "stateModule/stateInventory",
    }),
    isLoading() {
      return this.loadingStatus.getStateInventory;
    },
  },
  methods: {
    ...mapActions({
      getStateInventory: "stateModule/getStateInventory",
    }),
    exportInCSV() {
      var table = document.getElementById("state-inventory-table");
      ReportHelper.exportToExcel(table, "StateInventory");
    },
  },
};
</script>
