import Entities from "@/resources/Entities";
import {
  filterCriteria as employeeFilterCriteria,
  columnHeaders as employeeColumnHeaders,
} from "@/views/Employee/inputs";
import {
  filterCriteria as customerFilterCriteria,
  columnHeaders as customerColumnHeaders,
} from "@/views/Customer/inputs";
import {
  filterCriteria as groupFilterCriteria,
  columnHeaders as groupColumnHeaders,
} from "@/views/Group/inputs";
import {
  filterCriteria as productFilterCriteria,
  columnHeaders as productColumnHeaders,
} from "@/views/Product/inputs";
import {
  filterCriteria as menuCriteria,
  columnHeaders as menuColumnHeaders,
  menuSupplierColumnHeaders,
  filterCriteriaMenuSupplier,
} from "@/views/Menu/inputs";
import {
  filterCriteria as itemFilterCriteria,
  columnHeaders as itemColumnHeaders,
} from "@/views/Item/inputs";
import {
  filterCriteria as onlineStoreGroupFilterCriteria,
  columnHeaders as onlineStoreGroupColumnHeaders,
} from "@/views/OnlineStoreGroup/inputs";
import {
  filterCriteria as purchaseOrderFilterCriteria,
  columnHeaders as purchaseOrderColumnHeaders,
} from "@/views/PurchaseOrder/inputs";
import {
  filterCriteria as roomFilterCriteria,
  columnHeaders as roomColumnHeaders,
} from "@/views/Room/inputs";
import {
  filterCriteria as specialPricingFilterCriteria,
  columnHeaders as specialPricingColumnHeaders,
} from "@/views/SpecialPricing/inputs";
import {
  filterCriteria as storeFilterCriteria,
  columnHeaders as storeColumnHeaders,
} from "@/views/Store/inputs";
import {
  filterCriteria as supplierCriteria,
  columnHeaders as supplierColumnHeaders,
} from "@/views/Supplier/inputs";
import {
  filterCriteria as supplierAgentCriteria,
  columnHeaders as supplierAgentColumnHeaders,
} from "@/views/SupplierAgent/inputs";
import {
  filterCriteria as msoGroupCriteria,
  columnHeaders as msoGroupColumnHeaders,
} from "@/views/MSOGroup/inputs";

import {
  filterCriteria as msoSupplierCriteria,
  columnHeaders as msoSupplierColumnHeaders,
} from "@/views/MSOSupplier/inputs";

import {
  filterCriteria as msoProductCriteria,
  columnHeaders as msoProductColumnHeaders,
} from "@/views/MSOProduct/inputs";

import {
  filterCriteria as msoMenuCriteria,
  columnHeaders as msoMenuColumnHeaders,
  msoMenuSupplierColumnHeaders,
  filterCriteriaMenuMSOSupplier,
} from "@/views/MSOMenu/inputs";

import {
  filterCriteria as msoChartOfAccountCriteria,
  columnHeaders as msoChartOfAccountColumnHeaders,
} from "@/views/MSOChartOfAccount/inputs";

import {
  filterCriteria as chartOfAccountCriteria,
  columnHeaders as chartOfAccountColumnHeaders,
} from "@/views/ChartOfAccount/inputs";

import {
  filterCriteria as creditMemoCriteria,
  columnHeaders as creditMemoColumnHeaders,
} from "@/views/CreditMemo/inputs";

import {
  filterCriteria as giftCardCriteria,
  columnHeaders as giftCardColumnHeaders,
} from "@/views/GiftCard/inputs";

import {
  filterCriteria as couponCriteria,
  columnHeaders as couponColumnHeaders,
} from "@/views/Coupon/inputs";

import {
  filterCriteria as paymentCriteria,
  columnHeaders as paymentColumnHeaders,
} from "@/views/Payment/inputs";

import {
  filterCriteria as taxSetupCriteria,
  columnHeaders as taxSetupColumnHeaders,
} from "@/views/TaxSetup/inputs";

import {
  filterCriteria as msoCouponCriteria,
  columnHeaders as msoCouponColumnHeaders,
} from "@/views/MSOCoupon/inputs";

import {
  filterCriteria as orderCriteria,
  columnHeaders as orderColumnHeaders,
} from "@/views/Order/inputs";

import {
  filterCriteria as queueCriteria,
  columnHeaders as queueColumnHeaders,
} from "@/views/Queue/inputs";

import {
  filterCriteria as fulfillmentCriteria,
  columnHeaders as fulfillmentColumnHeaders,
} from "@/views/Fulfillment/inputs";

import {
  filterCriteria as flightCriteria,
  columnHeaders as flightColumnHeaders,
} from "@/views/Flight/inputs";

import {
  filterCriteria as journalCriteria,
  columnHeaders as journalColumnHeaders,
} from "@/views/Journal/inputs";

import {
  filterCriteria as timeSheetCriteria,
  columnHeaders as timeSheetColumnHeaders,
} from "@/views/TimeSheet/inputs";

import {
  filterCriteria as returnItemCriteria,
  columnHeaders as returnItemColumnHeaders,
} from "@/views/ReturnItem/inputs";

import {
  filterCriteria as cashReconciliationCriteria,
  columnHeaders as cashReconciliationHeaders,
} from "@/views/CashReconciliation/inputs";

import {
  filterCriteria as inventoryCountCriteria,
  columnHeaders as inventoryCountHeaders,
} from "@/views/InventoryCount/inputs";

import {
  filterCriteria as brandCountCriteria,
  columnHeaders as brandCountHeaders,
} from "@/views/Brand/inputs";

export const filterCriteriaDictionary = {
  [Entities.EMPLOYEE]: employeeFilterCriteria,
  [Entities.GROUP]: groupFilterCriteria,
  [Entities.CUSTOMER]: customerFilterCriteria,
  [Entities.PRODUCT]: productFilterCriteria,
  [Entities.MENU]: menuCriteria,
  [Entities.MenuSupplier]: filterCriteriaMenuSupplier,
  [Entities.ITEM]: itemFilterCriteria,
  [Entities.ONLINESTOREGROUP]: onlineStoreGroupFilterCriteria,
  [Entities.MSOGROUP]: msoGroupCriteria,
  [Entities.MSOSUPPLIER]: msoSupplierCriteria,
  [Entities.MSOPRODUCT]: msoProductCriteria,
  [Entities.MSOMenu]: msoMenuCriteria,
  [Entities.MSOMenuSupplier]: filterCriteriaMenuMSOSupplier,
  [Entities.MSOCHARTOFACCOUNT]: msoChartOfAccountCriteria,
  [Entities.CHARTOFACCOUNT]: chartOfAccountCriteria,
  [Entities.ORDER]: orderCriteria,
  [Entities.ORGANIZATION]: [],
  [Entities.PURCHASEORDER]: purchaseOrderFilterCriteria,
  [Entities.ROOM]: roomFilterCriteria,
  [Entities.SPECIALPRICING]: specialPricingFilterCriteria,
  [Entities.STORE]: storeFilterCriteria,
  [Entities.SUPPLIER]: supplierCriteria,
  [Entities.SUPPLIERAGENT]: supplierAgentCriteria,
  [Entities.GIFTCARD]: giftCardCriteria,
  [Entities.CREDITMEMO]: creditMemoCriteria,
  [Entities.COUPON]: couponCriteria,
  [Entities.MSOCOUPON]: msoCouponCriteria,
  [Entities.PAYMENT]: paymentCriteria,
  [Entities.TAXSETUP]: taxSetupCriteria,
  [Entities.QUEUE]: queueCriteria,
  [Entities.FULLFILLMENT]: fulfillmentCriteria,
  [Entities.FLIGHT]: flightCriteria,
  [Entities.JOURNAL]: journalCriteria,
  [Entities.TIMESHEET]: timeSheetCriteria,
  [Entities.RETURNITEM]: returnItemCriteria,
  [Entities.CASHRECONCILIATION]: cashReconciliationCriteria,
  [Entities.INVENTORYCOUNT]: inventoryCountCriteria,
  [Entities.BRAND]: brandCountCriteria,
};

export const columnHeadersDictionary = {
  [Entities.EMPLOYEE]: employeeColumnHeaders,
  [Entities.GROUP]: groupColumnHeaders,
  [Entities.CUSTOMER]: customerColumnHeaders,
  [Entities.PRODUCT]: productColumnHeaders,
  [Entities.MENU]: menuColumnHeaders,
  [Entities.MenuSupplier]: menuSupplierColumnHeaders,
  [Entities.ITEM]: itemColumnHeaders,
  [Entities.ONLINESTOREGROUP]: onlineStoreGroupColumnHeaders,
  [Entities.MSOGROUP]: msoGroupColumnHeaders,
  [Entities.MSOSUPPLIER]: msoSupplierColumnHeaders,
  [Entities.MSOPRODUCT]: msoProductColumnHeaders,
  [Entities.MSOMenu]: msoMenuColumnHeaders,
  [Entities.MSOMenuSupplier]: msoMenuSupplierColumnHeaders,
  [Entities.MSOCHARTOFACCOUNT]: msoChartOfAccountColumnHeaders,
  [Entities.CHARTOFACCOUNT]: chartOfAccountColumnHeaders,
  [Entities.ORDER]: orderColumnHeaders,
  [Entities.ORGANIZATION]: [],
  [Entities.PURCHASEORDER]: purchaseOrderColumnHeaders,
  [Entities.ROOM]: roomColumnHeaders,
  [Entities.SPECIALPRICING]: specialPricingColumnHeaders,
  [Entities.STORE]: storeColumnHeaders,
  [Entities.SUPPLIER]: supplierColumnHeaders,
  [Entities.SUPPLIERAGENT]: supplierAgentColumnHeaders,
  [Entities.GIFTCARD]: giftCardColumnHeaders,
  [Entities.CREDITMEMO]: creditMemoColumnHeaders,
  [Entities.COUPON]: couponColumnHeaders,
  [Entities.MSOCOUPON]: msoCouponColumnHeaders,
  [Entities.PAYMENT]: paymentColumnHeaders,
  [Entities.TAXSETUP]: taxSetupColumnHeaders,
  [Entities.QUEUE]: queueColumnHeaders,
  [Entities.FULLFILLMENT]: fulfillmentColumnHeaders,
  [Entities.FLIGHT]: flightColumnHeaders,
  [Entities.JOURNAL]: journalColumnHeaders,
  [Entities.TIMESHEET]: timeSheetColumnHeaders,
  [Entities.RETURNITEM]: returnItemColumnHeaders,
  [Entities.CASHRECONCILIATION]: cashReconciliationHeaders,
  [Entities.INVENTORYCOUNT]: inventoryCountHeaders,
  [Entities.BRAND]: brandCountHeaders,
};
