var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Credit Memo Upload","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
      {
        name: 'financeCreditMemoUpload',
        label: 'Credit Memo Upload',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.reportData.records),function(record,index){return _c('tr',{key:index},[_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.enter_date))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.memo_tot_balance))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.invoice_item_id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.pord_item_id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.return_id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.vend_id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.company))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.bill_hist))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.pord_hist))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.accn))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.product_desc))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.batch_id))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.pord_date))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.product_id_no))]),_c('td',{attrs:{"align":"left"}},[_vm._v(_vm._s(record.store_id))])])})],2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("enter_date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("memo_tot_balance")]),_c('th',{attrs:{"align":"left"}},[_vm._v("invoice_item_id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("pord_item_id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("return_id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("vend_id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("company")]),_c('th',{attrs:{"align":"left"}},[_vm._v("bill_hist")]),_c('th',{attrs:{"align":"left"}},[_vm._v("pord_hist")]),_c('th',{attrs:{"align":"left"}},[_vm._v("accn")]),_c('th',{attrs:{"align":"left"}},[_vm._v("product_desc")]),_c('th',{attrs:{"align":"left"}},[_vm._v("batch_id")]),_c('th',{attrs:{"align":"left"}},[_vm._v("pord_date")]),_c('th',{attrs:{"align":"left"}},[_vm._v("product_id_no")]),_c('th',{attrs:{"align":"left"}},[_vm._v("store_id")])])])}]

export { render, staticRenderFns }