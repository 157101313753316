<template>
  <div class="stateInventory responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader title="Purchases by Group" :links="[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'financeReport', label: 'Finance' },
        {
          name: 'inventoryPurchaseGroup',
          label: 'Purchases by Group',
        },
      ]" @export="exportToCsv" :disableExport="!reportData" />
      <br class="no-print" />
      <ReportFilter class="no-print"
      :filterByTime="true"
      @submit-filter="performGetReport"
      :isLoading="isLoading"
      :percentage="percentageCompleted"
      :filterBySaleType="false" />
      <div class="report-container">
        <!-- This could be a component -->
        <div class="report-body" v-if="reportData">
          <table id="table-report">
            <thead>
              <tr>
                <th align="left">Group</th>
                <th align="right">Quantity</th>
                <th align="right">Sub Total</th>
                <th align="right">Discount</th>
                <th align="right">Line Total</th>
                <th align="right">Tax</th>
                <th align="right">Delivery</th>
                <th align="right">Total</th>
              </tr>
            </thead>

            <tbody>
              <tr class="report-body__row"></tr>
              <tr v-for="[group, record] of Object.entries(reportData.list)" :key="group">
                <td>{{ record.group }}</td>
                <td align="right"> {{ record.quantity }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.subTotal) }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.discount) }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.lineTotal) }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.tax) }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.delivery) }}</td>
                <td align="right"> {{ Utilities.formatPrice(record.total) }}</td>
              </tr>
            </tbody>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>
            <tr>
              <td colspan="1">Totals</td>
              <td align="right"> {{ reportData.total.quantity }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.subTotal) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.discount) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.lineTotal) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.tax) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.delivery) }}</td>
              <td align="right"> {{ Utilities.formatPrice(reportData.total.total) }}</td>
            </tr>
            <tr class="skip-export"></tr>
            <tr class="skip-export">
              <td colspan="100%">
                <hr class="line1" />
              </td>
            </tr>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/Header.vue";
import ReportHelper from "@/lib/report/index.js";
import { mapActions, mapGetters } from "vuex";
import ReportFilter from "@/components/ReportFilter/ReportFilter";
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  components: {
    PageHeader,
    ReportFilter,
  },
  created: function () {
    this.setPageTitle("Purchases by Group");
  },
  computed: {
    ...mapGetters({
      isLoading: "reports/isLoading",
      getData: "reports/getData",
      percentageCompleted: 'reports/percentageCompleted'
    }),
    reportData() {
      return this.getData;
    },
  },
  methods: {
    ...mapActions({
      generateReport: "reports/generate",
      clearData: "reports/clearData",
    }),
    performGetReport(filter) {
      let reportType = "inventoryPurchaseGroup";
      this.generateReport({
        reportType,
        ...filter,
      });
    },
    exportToCsv() {
      var table = document.getElementById("table-report");
      ReportHelper.exportToExcel(table, "inventoryPurchaseGroup");
    },
  },
  beforeDestroy() {
    this.clearData()
  }
};
</script>

<style lang="scss">
@import "./_inventoryPurchaseGroup.scss";
