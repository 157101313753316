<template>
  <div class="bread-crumb">
    <router-link
      tag="div"
      class="bread-crumb__link text-color-1"
      v-for="link in links"
      :key="link.name"
      :to="{ name: link.name, query: link.query }"
    >
      <span>{{ link.label }}</span>
      <span class="bread-crumb__separator">/</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    links: {
      type: Array,
    },
  },
};
</script>

<style lang="scss">
$interspacing: 3px;

.bread-crumb {
  &__separator {
    margin-left: $interspacing;
  }

  &__link {
    display: inline-block;
    margin-left: $interspacing;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      font-size: 16px;
    }

    @media screen and (max-width: 400px) {
      font-size: 12px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      > .bread-crumb__separator {
        display: none;
      }
    }
  }
}
</style>
