var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Tax Breakdown By Day","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'financeReport', label: 'Finance' },
      {
        name: 'salesTaxBreakdownDay',
        label: 'Tax Breakdown By Day',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.list)),function(ref){
    var date = ref[0];
    var value = ref[1];
return _c('tr',{key:date},[_c('td',[_vm._v(_vm._s(value.dateString))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatPrice(value.total)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.cannabisGross))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.cannabisTax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.cannabisNet))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.otherGross))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.otherTax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(value.otherNet))+" ")])])})],2),_vm._m(1),_c('tr',[_c('td',[_vm._v("Totals")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.total))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.cannabisGross))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.cannabisTax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.cannabisNet))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.otherGross))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.otherTax))+" ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.otherNet))+" ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Date")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Total Sales")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Cannabis Gross")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Cannabis Tax")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Cannabis Net")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Other Gross")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Other Tax")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Other Net")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }