// default
export const defaultSelectedReturnItem = {
  _id: "NEW",
  notes: "",
  store: {},
  organization: {},
  createdBy: {},
  updatedBy: {},
  isDeleted: false,
  item: {},
  order: {},
  product: {},
  quantity: 0,
  isRefund: false,
  returnType: "",
  isSupplierIssuingRefund: false,
};

// Page Header
export const listPageHeader = {
  title: "Order Returns",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "returnItemEditor",
};

// Page Header
export const pageHeader = {
  title: "Order Item Return",
  isEditorView: true,
  cancelPath: "returnItems",
  buttonLabel: "Save",
};

// Search Filter Criteria
export const filterCriteria = [
  {
    label: "Return Id",
    code: "_id",
    field: { type: "input", label: "Return Id" },
  },
  {
    label: "Customer Id",
    code: "order.customer",
    field: { type: "input", label: "Customer Id" },
  },
  {
    label: "Order Id",
    code: "order.id",
    field: { type: "input", label: "Order Id" },
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Created At",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Order Code",
    id: "order.code",
  },
  {
    title: "First Name",
    id: "order.externalFields.customer.name",
    size: "medium",
  },
  {
    title: "Last Name",
    id: "order.externalFields.customer.lastName",
    size: "large",
  },
  {
    title: "Quantity",
    id: "quantity",
  },
  {
    title: "Product",
    id: "product.name",
    size: "large",
  },
  {
    title: "Return Id",
    id: "_id",
  },

  {
    title: "Order Id",
    id: "order.id",
    link: true,
    url: "/orders/orderEditor?id=",
    urlId: "order._id",
    size: "large",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Posted",
    id: "isPostedToJournal",
    type: "checkbox",
  },
  {
    title: "Archived",
    id: "isArchived",
    type: "checkbox",
  },
  {
    title: "Created At",
    id: "createdAt",
    type: "date",
  },
  {
    title: "Order Code",
    id: "order.code",
  },
  {
    title: "First Name",
    id: "order.externalFields.customer.name",
  },
  {
    title: "Last Name",
    id: "order.externalFields.customer.lastName",
  },
  {
    title: "Quantity",
    id: "quantity",
  },
  {
    title: "Product",
    id: "product.name",
  },
  {
    title: "Return Id",
    id: "_id",
  },
  {
    title: "Order Id",
    id: "order.id",
    link: true,
    url: "/orders/orderEditor?id=",
    urlId: "order._id",
  },
];

export const returnItemInputs = [
  {
    id: "_id",
    placeholder: "ID #",
    type: "input",
    title: "ID #",
  },
  {
    id: "invoiceNo",
    placeholder: "Invoice #",
    type: "input",
    title: "Order",
  },
  {
    id: "invoiceItemNo",
    placeholder: "Invoice Item #",
    type: "input",
    title: "Order Item",
  },
  {
    id: "customerNo",
    placeholder: "Customer #",
    type: "input",
    title: "Customer",
  },
  {
    id: "clientCreditMemo",
    placeholder: "Client Credit Memo #",
    type: "input",
    title: "Customer Credit Memo",
  },
  {
    id: "supplierNo",
    placeholder: "Supplier #",
    type: "input",
    title: "Supplier",
  },
  {
    id: "supplierCreditMemo",
    placeholder: "Supplier Credit Memo #",
    type: "input",
    title: "Supplier Credit Memo",
  },
  {
    id: "product",
    placeholder: "Product #",
    type: "input",
    title: "Product",
  },
  {
    id: "productDescription",
    placeholder: "",
    type: "input",
    title: "Description",
  },
  {
    id: "name",
    placeholder: "Name",
    type: "input",
    title: "Name",
    focus: true,
    required: true,
  },
  {
    id: "isRefund",
    type: "checkbox",
    title: "Is Refund",
    disableFunction: (item) => {
      const isCannabis = item?.product?.externalFields?.group?.isCannabis;
      return isCannabis || false;
    },
  },
  {
    id: "supplierRefund",
    type: "checkbox",
    title: "Supplier issuing refund",
  },
  {
    id: "notes",
    placeholder: "Notes",
    type: "textarea",
    title: "Notes",
    class: "grid-row-two-column",
  },
];
