<template>
  <div class="dash-reconciliation-data-column">
    <div
      class="dash-reconciliation-data-column-row"
      :class="{
        'dash-reconciliation-data-column-row--highlight': item.isHighlight,
      }"
      v-for="(item, index) of items"
      :key="`item_${index}`"
    >
      <p class="dash-reconciliation-data-column-row__name">{{ item.name }}:</p>
      <p
        v-if="item.type == 'money'"
        class="dash-reconciliation-data-column-row__value"
      >
        {{ Utilities.formatPrice(item.value) }}
      </p>
      <p v-else class="dash-reconciliation-data-column-row__value">
        {{ item.value }}
      </p>
    </div>
  </div>
</template>

<script>
import Utilities from "@/lib/Utilities";

export default {
  data() {
    return {
      Utilities,
    };
  },
  props: {
    items: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_index.scss";
</style>
