<template>
  <div class="purchaseOrders responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="listPageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'purchaseOrders', label: 'Purchase Orders' },
        ]"
        :entity="entity"
      />
      <SearchTable :entity="entity" :sort="sort" @sortChangeEvent="updateSort"
        :pagination="pagination" @paginationChange="updatePagination" @initialFilterUpdate="updateInitialFilter"
        :initialFilter="initialFilter"/>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import SearchTable from "@/components/SearchTable.vue";
import { listPageHeader } from "./inputs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "purchaseOrders",
  data() {
    return {
      Entities,
      entity: Entities.PURCHASEORDER,
      listPageHeader,
    };
  },
  components: {
    PageHeader,
    SearchTable,
  },
  created: function () {
    this.setPageTitle("Purchase Orders");
  },
  computed: {
    ...mapGetters({
      sort: "purchaseOrderModule/sort",
      pagination: "purchaseOrderModule/pagination",
      initialFilter: "purchaseOrderModule/initialFilter"
    }),
  },
  methods: {
    ...mapActions({
      updateSort: "purchaseOrderModule/updateSort",
      updatePagination: "purchaseOrderModule/updatePagination",
      updateInitialFilter: "purchaseOrderModule/updateInitialFilter"
    }),
  }
};
</script>
