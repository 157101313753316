var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Employee Hours","links":[
        { name: 'home', label: 'Home' },
        { name: 'reports', label: 'Reports' },
        { name: 'employeeReport', label: 'Employee' },
        {
          name: 'employeeHours',
          label: 'Employee Hours',
        } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByTime":true,"isLoading":_vm.employeeTransactions,"filterBySaleType":false},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_c('thead',[_c('tr',{staticClass:"report-body__row report-body__row--header"}),_vm._m(0),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(_vm.reportData.total.datePeriod))])]),_vm._m(1),_vm._m(2),_vm._m(3)]),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((_vm.formattedData),function(data,index){return _c('tr',{key:index,class:{ total3: data.isTotal }},[(!data.isTotal)?_c('td',[_vm._v(_vm._s(data.name))]):_c('td',[_vm._v("Totals")]),_c('td',[_vm._v(_vm._s(_vm.dateFormmatter(data.stampedIn)))]),_c('td',[_vm._v(_vm._s(_vm.dateFormmatter(data.stampedOut)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(_vm.Utilities.formatQuantity(data.calculatedHours)))])])})],2),_c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"3","align":"left"}},[_vm._v("Grand Total")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatQuantity(_vm.reportData.total.calculatedHours)))])])])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v("Employee Hours")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Employee")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Stamped In")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Stamped Out")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Hours")])])}]

export { render, staticRenderFns }