<template>
  <div class="inventory-list">
    <EntityFilter
      :selectedFilter="selectedFilter"
      :filterCriteria="filterCriteria"
      :preFilters="selectedPreFilters"
      :customStyle="isModal ? 'max-width: none;' : ''"
      @criteriaChange="performSetSelectedFilter"
      @valueChange="loadData"
    />
    <div
      class="table-container margined bordered"
      :style="isModal ? 'max-width: none;' : ''"
    >
      <table cellspacing="0" class="table-element">
        <thead>
          <tr class="header">
            <th class="cell"></th>
            <th class="cell">Product</th>
            <th class="cell">Description</th>
            <th class="cell">Group</th>
            <th class="cell">Supplier</th>
            <th class="cell">Created by</th>
            <th class="cell">Updated by</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="loadingStatus.getProducts && products.length === 0"
            class="row"
          >
            <td colspan="100" class="center padded">
              <i class="fas fa-spin fa-circle-notch"></i> Loading...
            </td>
          </tr>
          <tr v-else-if="products.length === 0">
            <td colspan="100" class="center padded">
              There are no products to show.
            </td>
          </tr>
          <tr class="row" v-for="i in products" :key="i._id">
            <td class="cell">
              <inline-button
                v-if="!allowSelection"
                label="Open"
                :to="{ name: 'productEditor', query: { id: i._id } }"
                type="plain-primary responsive-column form-height wide"
              />
              <inline-button
                v-if="allowSelection"
                label="Select"
                type="plain-primary responsive-column form-height wide"
                @click="() => emitSelectedElement(i)"
              />
            </td>
            <td class="cell">{{ i.name }}</td>
            <td class="cell">{{ i.description }}</td>
            <td class="cell">{{ i.group ? i.group.name : "-" }}</td>
            <td class="cell">{{ i.supplier ? i.supplier.name : "-" }}</td>
            <td class="cell">
              {{
                i.createdBy
                  ? i.createdBy.name +
                    " " +
                    (i.createdBy.lastName ? i.createdBy.lastName : "")
                  : "-"
              }}
            </td>
            <td class="cell">
              {{
                i.updatedBy
                  ? i.updatedBy.name +
                    " " +
                    (i.updatedBy.lastName ? i.updatedBy.lastName : "")
                  : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="false" class="table-paginator-container">
        <div class="table-paginator-page">1</div>
        <div class="table-paginator-page table-paginator-page--selected">2</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EntityFilter from "@/components/EntityFilter/EntityFilter";

export default {
  name: "ProductList",
  data() {
    return {};
  },
  props: {
    allowSelection: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EntityFilter,
  },
  mounted: function () {
    this.getProducts();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "productModule/loadingStatus",
      products: "productModule/products",
      filterCriteria: "productModule/filterCriteria",
      selectedFilter: "productModule/selectedFilter",
      selectedPreFilters: "productModule/selectedPreFilters",
    }),
  },
  methods: {
    ...mapActions({
      getProducts: "productModule/getProducts",
      setSelectedFilter: "productModule/setSelectedFilter",
    }),
    loadData: function (filter) {
      this.setSelectedFilter(filter);
      this.getProducts();
    },
    performSetSelectedFilter({ filter, filter2 }) {
      this.setSelectedFilter(filter);
      this.getProducts();
    },
    emitSelectedElement(selectedElement) {
      this.$emit("elementSelected", selectedElement);
    },
  },
};
</script>

<style lang="scss"></style>
