<template>
  <div class="state-inventory-list">
    <div class="flex flex--center flex--gap">
      <inline-button :type="mode == 'summary' ? 'primary' : 'plain-primary'" @click="mode = 'summary'"
        label="Summary" />
      <inline-button :type="mode == 'detail' ? 'primary' : 'plain-primary'" @click="mode = 'detail'" label="Detail" />
    </div>
    <div class="table-container margined bordered">
      <table cellspacing="0" class="table-element" id="state-inventory-table">
        <thead>
          <tr class="header">
            <th class="cell">DF Total</th>
            <th class="cell">State Total</th>
            <th class="cell">Difference</th>
            <th class="cell">Batch Id</th>
            <th class="cell">Grams</th>
            <th class="cell">Product</th>
            <th class="cell">Group</th>
            <th class="cell">Supplier</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="
            loadingStatus.getStateInventory && stateInventory.length === 0
          " class="row">
            <td colspan="100" class="padded" style="text-align: center">
              <i class="fas fa-spin fa-circle-notch"></i> Loading...
            </td>
          </tr>

          <tr class="row" :class="{
            'text-danger bold': !i.quantityMatch && i.type == 'item',
          }" v-for="i in rows" :key="i.batchId">
            <td v-if="i.type == 'group'" colspan="8" class="padded bold">
              {{ i.name }}
            </td>

            <td v-if="i.type == 'subTotal'" class="cell bold">
              <div class="total">
                <div class="total__label">DF Total</div>
                <div class="total__content"> {{ i.totalDf }} </div>
              </div>
            </td>
            <td v-if="i.type == 'subTotal'" class="cell bold">
              <div class="total">
                <div class="total__label">State Total</div>
                <div class="total__content"> {{ i.totalState }} </div>
              </div>
            </td>
            <td v-if="i.type == 'subTotal'" class="cell bold">
              <div class="total">
                <div class="total__label">Difference</div>
                <div class="total__content"> {{ Math.abs(i.totalDf - i.totalState)}} </div>
              </div>
            </td>
            <td v-if="i.type == 'subTotal'" colspan="5" class="padded bold">
              {{ i.name }}
            </td>

            <td v-if="i.type == 'item'" class="cell">{{ i.dfQuantity }}</td>
            <td v-if="i.type == 'item'" class="cell">{{ i.stateQuantity }}</td>
            <td v-if="i.type == 'item'" class="cell">
              {{
                  Math.abs(i.stateQuantity - i.dfQuantity)
              }}
            </td>
            <td v-if="i.type == 'item'" class="cell">{{ i.batchId }}</td>
            <td v-if="i.type == 'item'" class="cell">{{ i.weight }}</td>
            <td v-if="i.type == 'item'" class="cell">{{ i.productName }}</td>
            <td v-if="i.type == 'item'" class="cell">{{ i.group }}</td>
            <td v-if="i.type == 'item'" class="cell">{{ i.supplier }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="showOptions && !this.loadingStatus.getStateInventory"
      class="padded center space-between space-between--responsive-row-reverse">
      <div class="button-info" @click="$emit('negative-answer')">Cancel</div>
      <div class="button-secondary" @click="$emit('positive-answer')">
        Continue
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import Constants from "@/resources/Constants";

export default {
  name: "StateInventoryList",
  data() {
    return {
      moment: moment,
      Constants: Constants,
      Utilities: Utilities,
      mode: "summary",
    };
  },
  props: {
    showOptions: {
      type: Boolean,
      default: false,
    },
  },
  created: function () {
    this.setPageTitle("State Inventory");
  },
  mounted: function () {
    this.getStateInventory();
  },
  computed: {
    ...mapGetters({
      loadingStatus: "stateModule/loadingStatus",
      stateInventory: "stateModule/stateInventory",
    }),
    rows() {
      if (this.mode == "summary") {
        return this.stateInventory.filter(
          (i) => !i.quantityMatch || i.type == "group" || i.type == "subTotal"
        );
      } else {
        return this.stateInventory;
      }
    },
  },
  methods: {
    ...mapActions({
      getStateInventory: "stateModule/getStateInventory",
    }),
  },
};
</script>

<style scoped lang="scss">
.total {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: bold;
    font-size: $text-size-2;
    color: $dark-info-color;
    margin-bottom: 5px !important;
    transition: color 0.2s;
  }
}
</style>
