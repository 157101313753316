import HttpService from "@/services/HttpService";
import Apis from "@/services/Apis";

export const quickbooks = {
    authorize: (isSandbox, storeId) => {
        var jsonBody = {};
        jsonBody.isSandbox = isSandbox
        jsonBody.storeId = storeId
        jsonBody.isEnabled = true

        HttpService.post(
            Apis.backendBaseUrl + Apis.integrations.quickbooksAuthUrl,
            jsonBody,
            function (response, error) {
                if (error) {
                    console.log(error)
                    return
                }

                const authUri = response.data;
                openQuickbooksPopup(authUri, storeId);
            }
        );
    },

    disconnectAccount: (storeId) => {
        var body = { storeId };

        HttpService.post(
            Apis.backendBaseUrl + Apis.integrations.quickbooksAuthUrl,
            body,
            function (response, error) {
            }
        );
    }
};

const openQuickbooksPopup = (authUri, storeId) => {
    var parameters = "location=1,width=800,height=650";
    parameters +=
        ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;
    var win = window.open(authUri, "connectPopup", parameters);
    var pollOAuthTimer = window.setInterval(function () {
        try {
            if (win.document.URL.indexOf("code") != -1) {
                window.clearInterval(pollOAuthTimer);
                processQuickbooksCallback(win, pollOAuthTimer, storeId)
            }
        } catch (e) {
            console.log(e);
        }
    }, 2000);
};

const processQuickbooksCallback = (win, pollOAuthTimer, storeId) => {
    const jsonBody = {
        url: win.document.URL,
        storeId
    }
    HttpService.post(
        Apis.backendBaseUrl + Apis.integrations.quickbooksHandleAuthCallback,
        jsonBody,
        function (response, error) {
            window.clearInterval(pollOAuthTimer);
            win.close();
            window.location.reload();
        }
    );
};