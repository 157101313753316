var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mso-group-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
      { name: 'home', label: 'Home' },
      { name: 'msoGroups', label: 'MSO Groups' },
      {
        name: 'msoGroupEditor',
        label: _vm.selectedOrganizationGroup.name,
        query: _vm.$route.query,
      } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedOrganizationGroup,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":_vm.toggleSearchModal}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered"},_vm._l((_vm.msoGroupInfoInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedOrganizationGroup[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedOrganizationGroup[input.id] = $event}}})],1)}),0)]),(_vm.selectedOrganizationGroup._id !== 'NEW')?_c('EditorSection',{attrs:{"title":"Associated Groups","bus":_vm.bus}},[_c('SearchTable',{key:_vm.id,attrs:{"entity":_vm.Entities.GROUP,"hideFilters":true,"filter":_vm.filter}})],1):_vm._e(),_c('EditorSection',{attrs:{"title":"Integrations","bus":_vm.bus}},[_c('div',{staticClass:"padded elevated-0 bordered"},_vm._l((_vm.msoGroupIntegrationInputs),function(input){return _c('div',{key:input.id,staticClass:"input-text"},[_c('Input',{attrs:{"model":_vm.selectedOrganizationGroup[input.id],"inputData":input,"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedOrganizationGroup[input.id] = $event}}})],1)}),0)]),(_vm.selectedOrganizationGroup._id !== 'NEW')?_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedOrganizationGroup.name || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedOrganizationGroup}})],1):_vm._e()],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }