<template>
  <div class="tax-setups responsive-menu">
    <div class="responsive-menu__child">
      <Header
        title="Tax Setup Order and Test"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'taxSetups', label: 'TaxSetup' },
          { name: 'taxSetupOrderAndTest', label: 'Order And Test' },
        ]"
        @save="saveHandler()"
        @cancel="cancelHandler()"
      />
      <div class="section-base section-base--spaced">
        <div class="padded elevated-0 bordered tax-container body-header">
          <div>
            <Input
              :inputData="getInput('taxType')"
              :model="taxType"
              :isEdit="true"
              @updateModel="taxType = $event"
            />
            <Input
              :inputData="getInput('date')"
              :model="date"
              :isEdit="true"
              @updateModel="date = $event"
            />
            <Input
              :inputData="getInput('batch')"
              :model="batch"
              :isEdit="true"
              @updateModel="batch = $event"
            />
          </div>
          <div class="body-header__right">
            <Input
              class="auto-width"
              :inputData="getInput('salePrice')"
              :model="salePrice"
              :isEdit="true"
              @updateModel="salePrice = $event"
            />
            <Input
              class="auto-width"
              :inputData="getInput('discount')"
              :model="discount"
              :isEdit="true"
              @updateModel="discount = $event"
            />
            <Input
              class="auto-width"
              :inputData="getInput('allInTax')"
              :model="allInTax"
              :isEdit="true"
              @updateModel="allInTax = $event"
            />
          </div>
        </div>
        <div class="table-tax-container bordered">
          <table cellspacing="0" class="table-element">
            <thead>
              <tr class="header">
                <th class="cell">Effective Date</th>
                <th class="cell">End Date</th>
                <th class="cell">Name</th>
                <th class="cell">Rate</th>
                <th class="cell">Cumulative</th>
                <th class="cell">Order #</th>
                <th class="cell">Group #</th>
              </tr>
            </thead>
            <tbody>
              <tr class="row" v-for="tax of taxes" :key="tax._id">
                <td class="cell">{{ dateFormmatter(tax.effectiveDate) }}</td>
                <td class="">
                  <Calendar
                    class="calendar-table"
                    :touchUI="true"
                    dateFormat="mm/dd/yy"
                    v-model="tax.endDate"
                    @updateModel="tax.endDate = dateFormmatter($event)"
                    :showTime="false"
                  />
                </td>
                <td class="cell">{{ tax.name }}</td>
                <td class="cell">{{ tax.taxRate }}</td>
                <td class="cell">
                  <CheckBox
                    :toggleVariable="tax.isCumulative"
                    @click="() => (tax.isCumulative = !tax.isCumulative)"
                  />
                </td>
                <td class="cell">
                  <BaseInput
                    controlType="number"
                    :isSmall="true"
                    v-model="tax.taxOrder"
                  />
                </td>
                <td class="cell">
                  <BaseInput :isSmall="true" v-model="tax.taxGroup" />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!taxes || taxes.length == 0"
            class="table-tax-container__no-found"
          >
            <p>No Taxes Found</p>
          </div>
        </div>
        <div class="section-base section-base--spaced">
          <div class="summary">
            <div
              class="padded elevated-0 bordered tax-container calculated"
              v-if="totalTax.calculated"
            >
              <div class="calculated__table calculated__title">
                <div>Tax Name</div>
                <div>Tax NAL</div>
                <div>Tax AL</div>
              </div>
              <div
                class="calculated__table calculated__table__taxes"
                v-for="(taxApplied, index) of totalTax.taxesApplied"
                :key="`taxApplied_${index}`"
              >
                <div>{{ taxApplied.name }}</div>
                <div>{{ formatPrice(taxApplied.taxValue) }}</div>
                <div>{{ formatPrice(taxApplied.taxValueAL) }}</div>
              </div>
            </div>
            <div
              class="
                padded
                elevated-0
                bordered
                tax-container
                calculated calculated--prices
              "
              v-if="totalTax.calculated"
            >
              <div class="calculated__table calculated__title">
                <div></div>
                <div>Tax NAL</div>
                <div>Tax AL</div>
              </div>
              <div class="calculated__table">
                <div class="calculated__sub-title">Sale Price</div>
                <div>{{ formatPrice(salePrice - discount) }}</div>
                <div>{{ formatPrice(salePrice) }}</div>
              </div>
              <div>
                <div class="calculated__table">
                  <div class="calculated__sub-title">Total Tax</div>
                  <div>
                    {{ totalTax.nal ? formatPrice(totalTax.nal) : "-" }}
                  </div>
                  <div>{{ totalTax.al ? formatPrice(totalTax.al) : "-" }}</div>
                </div>
              </div>
              <div class="calculated__table calculated__table__total">
                <div class="calculated__sub-title">Actual Sale Price</div>
                <div>
                  {{ formatPrice(actualSalePrice.nal) }}
                </div>
                <div>
                  {{ formatPrice(actualSalePrice.al) }}
                </div>
              </div>
            </div>
          </div>

          <BaseBtn
            iconGroup="fas"
            iconName="calculator"
            class="calculate-btn"
            label="calculate"
            @click="calculateTaxes"
            :disabled="!taxes || taxes.length == 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Input from "@/components/Input/Input.vue";
import { inputs } from "./inputs";
import BaseInput from "@/components/Base/Input/Base.vue";
import CheckBox from "@/components/CheckBox";
import Calendar from "primevue/calendar";
import BaseBtn from "@/components/Base/Button/Button.vue";
import { mapGetters, mapActions } from "vuex";
import Utilities from "@/lib/Utilities";
import Constants from "@/resources/Constants";
import _ from "lodash";
import TaxesHelper from "@/lib/TaxesHelper";
import moment from "moment";

export default {
  data() {
    return {
      Constants,
      inputs,
      taxType: "recreational",
      date: new Date(),
      batch: "",
      salePrice: 0,
      discount: 0,
      allInTax: true,
      taxes: [],
      totalTax: {
        calculated: false,
        nal: 0,
        al: 0,
        taxesApplied: [],
      },
      actualSalePrice: {
        nal: 0,
        al: 0,
      },
      thcAverage: null,
      inventoryType: null,
    };
  },
  components: {
    Header,
    Input,
    BaseInput,
    CheckBox,
    Calendar,
    BaseBtn,
  },
  watch: {
    taxType: function () {
      this.resetCalculation();
      this.filterTaxes();
    },
    date: function () {
      this.resetCalculation();
      this.filterTaxes();
    },
    salePrice() {
      this.resetCalculation();
    },
    discount() {
      this.resetCalculation();
    },
    thcAverage() {
      this.filterTaxes();
    },
    batch: _.debounce(function () {
      this.getBatch();
    }, 500),
  },
  computed: {
    ...mapGetters({
      getTaxSetups: "taxSetupModule/taxSetups",
    }),
  },
  methods: {
    ...mapActions({
      retrieveTaxes: "taxSetupModule/getAllActiveTaxes",
      setMessagePopUp: "setMessagePopUp",
      getItemsByBatch: "itemModule/getItemsByBatch",
      partialTaxSave: "taxSetupModule/partialTaxSave",
      setMessage: "setMessage",
      performFilterTaxesForTest: "taxSetupModule/filterTaxesForTest",
    }),
    getInput(field) {
      return inputs.find((i) => i.id == field);
    },
    async saveHandler() {
      await this.partialTaxSave(this.taxes)
        .then((_) => {
          this.setMessage({
            text: "Saved",
            type: "success",
          });
        })
        .catch((err) => {
          this.setMessage({
            text: "Could not save changes on taxes",
            type: "error",
          });
        });
    },
    cancelHandler() {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          this.$router.push({ name: "taxSetups" });
        },
      });
    },
    async getBatch() {
      const items = await this.getItemsByBatch({ batchId: this.batch });
      if (!items || items.length == 0) {
        this.setMessage({
          text: "Cannot find items with this batch",
          type: "error",
        });
        this.taxes = [];
        return;
      }
      const item = items[0];
      this.inventoryType = item.inventoryType;
      this.thcAverage = TaxesHelper.calculateThc(items[0]);
      this.filterTaxes();
      this.resetCalculation();
    },
    dateFormmatter(date) {
      return Utilities.formatDate(date);
    },
    async filterTaxes() {
      if (!this.taxType && !this.batch) {
        this.getAllTaxes();
        return;
      }

      const dateFormatted = this.date ? new Date(this.date) : null;
      const inventoryType = this.inventoryType;

      await this.performFilterTaxesForTest({
        type: this.taxType,
        date: dateFormatted,
        thc: this.thcAverage,
        inventoryType,
      })
        .then((data) => {
          let taxes = JSON.parse(JSON.stringify(data));
          this.prefillEntitiesBeforeRendering(taxes);
          this.taxes = taxes;
        })
        .catch((_) => {
          this.setMessage({
            text: "Error filtering taxes",
            type: "error",
          });
        });
    },
    formatPrice(value) {
      return Utilities.formatPrice(value);
    },
    calculateTaxes() {
      this.totalTax.calculated = true;

      const dateFormatted = this.date ? new Date(this.date) : null;
      const item = null;
      const { taxTotal, taxTotalAL, taxesDetails } = TaxesHelper.calculateTax(
        this.taxes,
        this.taxType,
        dateFormatted,
        this.salePrice,
        this.discount,
        item,
        item ? item.inventoryType : null,
        this.allInTax
      );
      this.totalTax.nal = taxTotal;
      this.totalTax.al = taxTotalAL;
      this.totalTax.taxesApplied = taxesDetails;

      const discounted = this.salePrice - this.discount;

      this.actualSalePrice.nal = this.allInTax
        ? discounted - taxTotal
        : discounted + taxTotal;

      this.actualSalePrice.al = this.allInTax
        ? this.salePrice - taxTotalAL
        : this.salePrice + taxTotalAL;
    },
    resetCalculation() {
      this.totalTax.calculated = false;
    },
    prefillEntitiesBeforeRendering(taxes) {
      for (let i = 0; i < taxes.length; i++) {
        const tax = taxes[i];

        if (!tax.endDate) continue;

        tax.endDate = moment(tax.endDate).format(
          Constants.dates.defaultDateFormat
        );
      }
    },
    async getAllTaxes() {
      await this.retrieveTaxes().then((data) => {
        let taxes = JSON.parse(JSON.stringify(data));

        this.prefillEntitiesBeforeRendering(taxes);
        this.taxes = taxes;
      });
    },
  },
  async mounted() {
    if (!this.getTaxSetups || this.getTaxSetups.length == 0) {
      await this.getAllTaxes();
    }
    this.filterTaxes();
  },
};
</script>

<style lang="scss" scoped>
@import "./_taxSetupOrderAndTest.scss";
</style>

<style lang="scss">
.calendar-table > .p-inputtext {
  height: 24px !important;
  font-size: $text-size-3;
}

.calendar-table > .p-datepicker-touch-ui {
  z-index: 10000;
  width: 40vw;
  min-width: 40vw;
  min-height: auto;
  height: auto;

  @include respond(tab-port) {
    z-index: 10000;
    width: 95vw;
    min-width: 95vw;
    min-height: auto;
    height: auto;
  }
}
</style>
