<template>
  <div class="payment-editor responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader
        :pageHeader="pageHeader"
        :links="[
          { name: 'home', label: 'Home' },
          { name: 'payments', label: 'Payment' },
          {
            name: 'paymentEditor',
            label: selectedPayment._id,
            query: $route.query,
          },
        ]"
        :disableDelete="true"
        :canArchive="false"
        :hideAddButton="true"
        :canEdit="false"
        :isEdit="isEdit"
        :isCollapsed="isCollapsed"
        :selectedEntity="selectedPayment"
        @updateIsEdit="isEdit = $event"
        :entity="entity"
        @toggle-edit="toggleEdit"
        @toggle-sections="forceToggle"
        @handle-adjacent="handleAdjacent"
        @show-search-modal="toggleSearchModal"
      />

      <div class="section-base">
        <div
          style="position: relative"
          class="padded elevated-0 bordered"
          :class="{
            'bordered__highlighted--primary': selectedPayment.isPostedToJournal,
            'bordered__highlighted--danger': selectedPayment.isArchived,
          }"
        >
          <transition name="fade">
            <ContainerLabel
              v-if="selectedPayment.isPostedToJournal"
              type="primary"
              text="POSTED TO JOURNAL"
            />
            <ContainerLabel
              v-if="selectedPayment.isArchived"
              type="danger"
              text="ARCHIVED"
            />
          </transition>
          <div class="two-columns-layout two-columns-layout--responsive">
            <div v-for="(inputs, index) of paymentInfoInputs" :key="`${inputs.id}_${index}`">
              <div
                class="
                  grid-two-column
                  two-columns-layout--responsive-horizontal-space-10
                "
              >
                <div
                  v-for="(input, index) of inputs.inputs"
                  :key="`${input.id}_${index}`"
                  :class="'input-text' + ' ' + input.class"
                >
                  <Input
                    :model="lodash.get(selectedPayment, input.id)"
                    @updateModel="selectedPayment[input.id] = $event"
                    :inputData="input"
                    :isEdit="false"
                    :data-cy="input.testAttribute"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditorSection
        title="Gift Card"
        v-if="selectedPayment.type == 'gift-card'"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in gitftCardInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="lodash.get(selectedPayment, input.id)"
              @updateModel="selectedPayment[input.id] = $event"
              :inputData="input"
              :isEdit="false"
            />
          </div>
        </div>
      </EditorSection>

      <EditorSection
        title="Credit Memo"
        v-if="selectedPayment.type == 'credit-memo'"
        :bus="bus"
      >
        <div class="space-between space-between--allow-wrapping">
          <div
            v-for="input in creditMemoInputs"
            :key="input.id"
            class="input-text"
          >
            <Input
              :model="lodash.get(selectedPayment, input.id)"
              @updateModel="selectedPayment[input.id] = $event"
              :inputData="input"
              :isEdit="false"
            />
          </div>
        </div>
      </EditorSection>

      <EditorSection
        :title="`About ${selectedPayment.name || ''}`"
        sectionType="about"
        v-if="selectedPayment._id !== 'NEW'"
        :bus="bus"
      >
        <About :entity="selectedPayment" />
      </EditorSection>
    </div>
    <SearchModal
      v-if="showSearchModal"
      :showSearchModal="showSearchModal"
      @toggle-modal="() => (showSearchModal = !showSearchModal)"
      :entity="entity"
      :forcedFilterCriteria="filterCriteria"
      :columnHeaders="columnHeaders"
    />
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/PageHeader.vue";
import Input from "@/components/Input/Input.vue";
import About from "@/components/About/About.vue";
import EditorSection from "@/components/EditorSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Constants from "@/resources/Constants";
import _ from "lodash";
import Utilities from "@/lib/Utilities";
import ContainerLabel from "@/components/ContainerLabel/ContainerLabel.vue";
import {
  paymentInfoInputs,
  gitftCardInputs,
  defaultSelectedPayment,
  pageHeader,
  creditMemoInputs,
  filterCriteria,
  columnHeaders,
} from "./inputs";
import Entities from "@/resources/Entities";

export default {
  name: "paymentEditor",
  data() {
    return {
      lodash: _,
      Constants,
      moment,
      Utilities,
      paymentInfoInputs,
      isEdit: false,
      defaultSelectedPayment,
      payments: [],
      paymentsResults: [],
      bus: new Vue(),
      isCollapsed: true,
      entity: Entities.PAYMENT,
      pageHeader,
      showSearchModal: false,
      filterCriteria,
      columnHeaders,
      gitftCardInputs,
      creditMemoInputs,
    };
  },
  components: {
    PageHeader,
    Input,
    EditorSection,
    About,
    ContainerLabel,
  },
  mounted: function () {
    this.setPageTitle("Payments");
    if (this.$route.query.id) {
      this.getPaymentById({
        _id: this.$route.query.id,
        positiveCallback: (p) => {
          this.setPageTitle(`Payment ${p._id}`);
        },
      });
    } else {
      // new element
      this.isEdit = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.setMessagePopUp({
        isOpen: true,
        title: Constants.strings.unsavedChangesTitle,
        message: Constants.strings.unsavedChangesConfirmation,
        positiveCallback: (data) => {
          next(true);
        },
      });
    } else {
      next(true);
    }
  },
  watch: {
    id: function () {
      if (this.id && this.id !== "NEW") {
        this.getPaymentById({
          _id: this.id,
        });
        document.getElementById("app-body").scrollTo(0, 0);
      }
    },
  },
  computed: {
    id: {
      get() {
        return this.$route.query.id;
      },
      set(id) {
        return id;
      },
    },
    ...mapGetters({
      payment: "paymentModule/payment",
    }),
    selectedPayment: {
      get() {
        this.setPageTitle("Payment " + this.payment._id);
        return this.payment;
      },
      set(entity) {
        return entity;
      },
    },
  },
  methods: {
    ...mapActions({
      getPaymentById: "paymentModule/getPaymentById",
      savePayment: "paymentModule/savePayment",
      deletePayment: "paymentModule/deletePayment",
      setMessagePopUp: "setMessagePopUp",
      setMessage: "setMessage",
      getPayments: "paymentModule/getPayments",
    }),
    toggleSections: function () {
      this.isCollapsed = !this.isCollapsed;
    },
    forceToggle() {
      this.isCollapsed = !this.isCollapsed;
      this.bus.$emit("forceToggle", this.isCollapsed);
    },
    toggleEdit() {
      if (!(this.selectedPayment._id === "NEW")) this.isEdit = !this.isEdit;
      if (this.selectedPayment._id === "NEW") {
        this.isEdit = true;
      }
    },
    handleAdjacent: async function (mode) {
      this.getAdjacent({
        createdAt: this.selectedPayment.createdAt,
        mode,
        positiveCallback: (adjacent) => {
          if (adjacent) this.selectedPayment = adjacent;
        },
      });
    },
    toggleSearchModal: function () {
      this.showSearchModal = !this.showSearchModal;
    },
    selectItemFromSearch: function (entity, item) {
      this.selectedPayment[entity] = item;
    },
  },
};
</script>
