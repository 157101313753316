// Group Editor
import INVENTORY_TYPES from "@/constants/InventoryTypes";
import * as Entities from "@/resources/Entities";
import {
  searchColumnHeaders as roomColumnHeaders,
  filterCriteria as roomFilterCriteria,
} from "../Room/inputs";
import {
  searchColumnHeaders as msoGroupColumnHeaders,
  filterCriteria as msoGroupFilterCriteria,
} from "../MSOGroup/inputs";

import {
  searchColumnHeaders as chartOfAccountColumnHeaders,
  filterCriteria as chartOfAccountFilterCriteria,
} from "../ChartOfAccount/inputs";
// Page Header
export const pageHeader = {
  title: "Group",
  isEditorView: true,
  cancelPath: "groups",
  addPath: "groupEditor",
  buttonLabel: "Save",
};

// Page Header
export const listPageHeader = {
  title: "Group",
  isEditorView: false,
  buttonLabel: "New",
  routerPath: "groupEditor",
};

export const filterCriteria = [
  {
    label: "Name",
    code: "name",
    field: { type: "input", label: "name" },
    isPartial: true,
  },
  { label: "Id", code: "_id", field: { type: "input", label: "Id" } },
  {
    label: "MSO Group",
    code: "externalFields.msoGroup.name",
    field: { type: "input", label: "MSO Group" },
  },
  {
    label: "Organization Id",
    code: "organization",
    field: { type: "input", label: "Organization Id" },
  },
];

export const columnHeaders = [
  {
    id: "open",
    type: "button",
    action: "navigateToEntity",
    iconName: "folder-open",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
    size: "large",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Cannabis",
    id: "isCannabis",
    type: "checkbox",
  },
  {
    title: "Unit Of Measure",
    id: "unitOfMeasure",
  },
  {
    title: "MSO Group Name",
    id: "externalFields.msoGroup.name",
    size: "large",
  },
  {
    title: "Inventoried",
    id: "isInventoried",
    type: "checkbox",
  },
  {
    title: "Taxable",
    id: "isTaxable",
    type: "checkbox",
  },
  {
    title: "Is For Resale",
    id: "isForResale",
    type: "checkbox",
  },
];

export const searchColumnHeaders = [
  {
    id: "select",
    type: "button",
    action: "selectItem",
    position: "sticky",
    iconName: "hand-pointer",
    ariaLabel: "Open",
  },
  {
    title: "Name",
    id: "name",
  },
  {
    title: "Id",
    id: "_id",
  },
  {
    title: "Active",
    id: "isActive",
    type: "checkbox",
  },
  {
    title: "Cannabis",
    id: "isCannabis",
    type: "checkbox",
  },
  {
    title: "Unit Of Measure",
    id: "unitOfMeasure",
  },
  {
    title: "MSO Group Name",
    id: "externalFields.msoGroup.name",
  },
  {
    title: "Inventoried",
    id: "isInventoried",
    type: "checkbox",
  },
  {
    title: "Taxable",
    id: "isTaxable",
    type: "checkbox",
  },
  {
    title: "Is For Resale",
    id: "isForResale",
    type: "checkbox",
  },
];

// Group Information - Main Section
export const groupInputs = [
  {
    id: 1,
    inputs: [
      {
        id: "msoGroup",
        placeholder: "Select MSO Group",
        type: "search",
        title: "MSO Group",
        label: "name",
        entity: Entities.MSOGROUP,
        columnHeaders: msoGroupColumnHeaders,
        filterCriteria: msoGroupFilterCriteria,
        isClearable: true,
        filter: {
          "isActive" : true,
          $and : [
            { "isArchived" : false },
            { "isDeleted" : false },
          ]
        },
      },
      {
        id: "name",
        placeholder: "Group Name",
        type: "input",
        title: "Group Name",
        required: true,
        focus: true,
        class: "grid-row-two-column",
      },
      {
        id: "unitOfMeasure",
        placeholder: "Select Unit of Measure",
        type: "select",
        title: "Unit of Measure",
        options: [
          { label: "Unit", code: "unit" },
          { label: "Grams", code: "g" },
        ],
        required: true,
      },
      {
        id: "onlineStoreWeightUoM",
        placeholder: "g or mg",
        type: "select",
        title: "Online Store Weight Unit of Measure",
        options: [
          { label: "g", code: "g" },
          { label: "mg", code: "mg" },
        ],
      },
      {
        id: "inventoryType",
        placeholder: "Select State Inventory Type",
        type: "select",
        title: "State Inventory Type",
        options: INVENTORY_TYPES,
      },
      {
        id: "nameCode",
        placeholder: "Accounting Code",
        type: "input",
        title: "Accounting Code",
      },
      {
        id: "receiptDisplayOrder",
        placeholder: "Receipt Order",
        //type: "input",
        type: "text",
        title: "Receipt Order",
        //precision: 0,
        //disabled: true,
      },
      {
        id: "room",
        placeholder: "Select Room",
        type: "search",
        title: "Room",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.ROOM,
        columnHeaders: roomColumnHeaders,
        filterCriteria: roomFilterCriteria,
      },
      {
        id: "isActive",
        placeholder: "Active",
        type: "checkBox",
        title: "Active",
      },
      {
        id: "isDeleted",
        placeholder: "Deleted",
        type: "checkBox",
        title: "Deleted",
        disabled: true,
      },
      {
        id: "isCannabis",
        placeholder: "Cannabis",
        type: "checkBox",
        title: "Cannabis",
      },
      {
        id: "isInventoried",
        placeholder: "Inventoried",
        type: "checkBox",
        title: "Inventoried",
      },
      {
        id: "isTaxable",
        placeholder: "Taxable",
        type: "checkBox",
        title: "Taxable",
      },
      {
        id: "isForResale",
        placeholder: "For Resale",
        type: "checkBox",
        title: "For Resale",
      },
      {
        id: "isService",
        placeholder: "Is Service",
        type: "checkBox",
        title: "Is Service",
      },
      {
        id: "isGiftCard",
        placeholder: "Gift Card",
        type: "checkBox",
        title: "Gift Card",
      },
      {
        id: "isPublicToOnlineStore",
        placeholder: "Online Store",
        type: "checkBox",
        title: "Online Store",
      },
      {
        id: "inventoryAccountCode",
        placeholder: "Search Inventory Account",
        type: "search",
        title: "Chart of Account Numbers:",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.CHARTOFACCOUNT,
        columnHeaders: chartOfAccountColumnHeaders,
        filterCriteria: chartOfAccountFilterCriteria,
        fieldsToDisplay: [
          { label: "Inventory Account Name", id: "name" },
          { label: "Inventory Account ID", id: "accountNo" },
        ],
      },
      {
        id: "salesAccountCode",
        placeholder: "Search Sales Account",
        type: "search",
        title: "",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.CHARTOFACCOUNT,
        columnHeaders: chartOfAccountColumnHeaders,
        filterCriteria: chartOfAccountFilterCriteria,
        fieldsToDisplay: [
          { label: "Sales Account Name", id: "name" },
          { label: "Sales Account ID", id: "accountNo" },
        ],
      },
      {
        id: "expenseAccountCode",
        placeholder: "Select Expense Account",
        type: "search",
        title: "",
        label: "name",
        class: "grid-row-two-column",
        entity: Entities.CHARTOFACCOUNT,
        columnHeaders: chartOfAccountColumnHeaders,
        filterCriteria: chartOfAccountFilterCriteria,
        fieldsToDisplay: [
          { label: "Expense Account Name", id: "name" },
          { label: "Expense Account ID", id: "accountNo" },
        ],
      },
    ],
  },
  {
    id: 2,
    inputs: [
      {
        id: "isPrintLabel",
        placeholder: "Label Required",
        type: "checkBox",
        title: "Label Required",
      },
      {
        id: "isRxRequired",
        placeholder: "Rx Required",
        type: "checkBox",
        title: "Rx Required",
      },
      {
        id: "isWarningLabelRequired",
        placeholder: "Warning Label Required",
        type: "checkBox",
        title: "Warning Label Required",
      },
      {
        id: "warningLabel",
        placeholder: "Warning Label",
        type: "textarea",
        title: "Warning Label",
        class: "grid-row-two-column",
      },
      {
        id: "notes",
        placeholder: "Notes",
        type: "textarea",
        title: "Notes",
        class: "grid-row-two-column",
      },
    ],
  },
];

export const groupIntegrationInputs = [
  {
    id: "weedmapsId",
    placeholder: "Weedmap Group Id",
    type: "input",
    title: "Weedmap Group Id",
  },
];


export const defaultSelectedGroup = {
  _id: "NEW",
  name: "",
  notes: "",
  warningLabel: "",
  room: {},
  inventoryType: "",
  unitOfMeasure: "unit",
  isActive: true,
  isDeleted: false,
  isArchived: false,
  isMedicalOnly: false,
  isPublicToOnlineStore: true,
  isCannabis: true,
  isTaxable: true,
  isService: false,
  isInventoried: true,
  isForResale: true,
  isWarningLabelRequired: false,
  isRxRequired: false,
  isPrintLabel: false,
  isGiftCard: false,
  receiptDisplayOrder: 0,
  expenseAccountCode: {},
  salesAccountCode: {},
  inventoryAccountCode: {},
  onlineStoreGroup: "",
  groupOrganization: "",
  createdBy: {},
  updatedBy: {},
  msoGroup: {},
};
