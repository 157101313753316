var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeSheet-editor responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"pageHeader":_vm.pageHeader,"links":[
        { name: 'home', label: 'Home' },
        { name: 'timeSheets', label: 'TimeSheet' },
        {
          name: 'timeSheetEditor',
          label: _vm.selectedTimeSheet._id,
          query: _vm.$route.query,
        } ],"isEdit":_vm.isEdit,"isCollapsed":_vm.isCollapsed,"selectedEntity":_vm.selectedTimeSheet,"entity":_vm.entity},on:{"updateIsEdit":function($event){_vm.isEdit = $event},"toggle-edit":_vm.toggleEdit,"toggle-sections":_vm.forceToggle,"handle-adjacent":_vm.handleAdjacent,"show-search-modal":_vm.toggleSearchModal}}),_c('div',{staticClass:"section-base"},[_c('div',{staticClass:"padded elevated-0 bordered",class:{
          'bordered__highlighted--danger': _vm.selectedTimeSheet.isDeleted,
        },staticStyle:{"position":"relative"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.selectedTimeSheet.isDeleted)?_c('ContainerLabel',{attrs:{"type":"danger","text":"DELETED"}}):_vm._e()],1),_c('div',[_c('div',{staticClass:"timeSheet-editor__info-wrapper"},[(_vm.selectedTimeSheet._id !== 'NEW')?_c('div',{staticClass:"timeSheet-editor__info-wrapper__left-wrapper"},[_c('router-link',{staticClass:"timeSheet-editor__info-wrapper__name",attrs:{"target":"_blank","to":{
                  name: 'employeeEditor',
                  query: { id: _vm.selectedTimeSheet.employee._id },
                }}},[_vm._v(" "+_vm._s(_vm.selectedTimeSheet.employee.name)+" "+_vm._s(_vm.selectedTimeSheet.employee.lastName)+" ")]),_c('div',{staticClass:"timeSheet-editor__info-wrapper__contact"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'phone']}}),_c('div',[_vm._v(_vm._s(_vm.selectedTimeSheet.employee.phone))])],1),_c('div',{staticClass:"timeSheet-editor__info-wrapper__contact"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'envelope']}}),_c('div',[_vm._v(_vm._s(_vm.selectedTimeSheet.employee.email))])],1),_c('router-link',{staticClass:"timeSheet-editor__info-wrapper__contact",attrs:{"target":"_blank","to":{
                  name: 'employeeEditor',
                  query: { id: _vm.selectedTimeSheet.employee._id },
                }}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'info-circle']}}),_c('div',[_vm._v(_vm._s(_vm.selectedTimeSheet.employee._id))])],1)],1):_c('div',[_c('Input',{attrs:{"model":_vm.selectedTimeSheet.employee,"inputData":_vm.getInput('employee'),"isEdit":_vm.isEdit},on:{"updateModel":function($event){_vm.selectedTimeSheet.employee = $event}}})],1),_c('div',{staticClass:"timeSheet-editor__hours-wrapper"},[_c('div',{staticClass:"timeSheet-editor__hours-label"},[_vm._v("Total Hours")]),_c('div',{staticClass:"timeSheet-editor__hours"},[_vm._v(_vm._s(_vm.totalHours))])])]),_c('div',{staticClass:"timeSheet-editor__date-range"},[_c('Input',{staticClass:"timeSheet-editor__date-range--date",attrs:{"model":_vm.selectedTimeSheet.stampedIn,"inputData":_vm.getInput('stampedIn'),"isEdit":_vm.isEdit,"validation":_vm.isValid('startDate')},on:{"updateModel":function (event) { return _vm.setValue('stampedIn', event); }}}),_c('Input',{staticClass:"timeSheet-editor__date-range--date",attrs:{"model":_vm.selectedTimeSheet.stampedOut,"inputData":_vm.getInput('stampedOut'),"isEdit":_vm.isEdit,"validation":_vm.isValid('endDate')},on:{"updateModel":function (event) { return _vm.setValue('stampedOut', event); }}})],1)]),_c('div',{staticClass:"timeSheet-editor__divider"})],1)]),_c('EditorSection',{attrs:{"title":("About " + (_vm.selectedTimeSheet._id || '')),"sectionType":"about","bus":_vm.bus}},[_c('About',{attrs:{"entity":_vm.selectedTimeSheet}})],1),_c('EditorSection',{attrs:{"defaultIsOpen":true,"title":_vm.timeSheetsTitle,"bus":_vm.bus}},[(_vm.selectedTimeSheet.employee._id)?_c('EmployeeTimeSheets',{attrs:{"employeeId":_vm.selectedTimeSheet.employee._id}}):_vm._e()],1)],1),(_vm.showSearchModal)?_c('SearchModal',{attrs:{"showSearchModal":_vm.showSearchModal,"entity":_vm.entity,"forcedFilterCriteria":_vm.filterCriteria,"columnHeaders":_vm.columnHeaders},on:{"toggle-modal":function () { return (_vm.showSearchModal = !_vm.showSearchModal); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }