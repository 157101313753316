import HttpService from "@/services/HttpService";
import Entities from "@/resources/Entities";
import Apis from "@/services/Apis";
import LocalStorageManager from "../../lib/LocalStorageManager";
import { defaultSelectedEmployee } from "@/views/Employee/inputs";
import { populateStores } from "@/utils/normalize-entity-before-saving/employee";
import { searchEntities } from "@/services/entityService";

const state = {
  employees: [],
  employee: { ...defaultSelectedEmployee },
  posEmployee: {},
  loadingStatus: {
    saveEmployee: false,
    getEmployees: false,
    getEmployeeById: false,
    getEmployeeByPin: false,
    deleteEmployee: false,
  },
  employeeConfirmationPopUp: {
    isOpen: false,
  },
  sort: undefined,
  pagination: { skip: 0, limit: 20 },
  initialFilter: undefined
};

const mutations = {
  setLoadingStatus: (state, payload) => {
    if (payload) {
      state.loadingStatus[payload.api] = payload.status;
    }
  },
  setEmployees: (state, payload) => {
    state.employees = payload;
  },
  setEmployee: (state, payload) => {
    if (payload._id === "NEW") {
      searchEntities({
        entity: Entities.STORE,
        positiveCallback: (data) => {
          state.employee = { ...defaultSelectedEmployee };
          state.employee.pin = parseInt(Math.random() * 100000);
          state.employee.traceabilityCredentials = []
          // state.employee.stores = populateStores(defaultSelectedEmployee, data);
        },
      });
    } else {
      state.employee = payload;
    }
  },
  setPosEmployee: (state, payload) => {
    state.posEmployee = payload;
  },
  setEmployeeConfirmationPopUp: (state, payload) => {
    state.employeeConfirmationPopUp = payload;
  },
  resetEmployee: (state, payload) => {
    state.posEmployee = {};
  },
  confirmEmployee: (state, payload) => {
    LocalStorageManager.setLocalStorageKey("employeeId", payload.employee._id);
    LocalStorageManager.setLocalStorageKey("drawer", payload.drawer);
  },
  updateSort: (state, payload) => {
    state.sort = payload;
  },
  updatePagination: (state, payload) => {
    state.pagination = payload;
  },
  updateInitialFilter: (state, payload) => {
    state.initialFilter = payload;
  },
};

const getters = {
  employees: (state) => state.employees,
  employee: (state) => state.employee,
  posEmployee: (state) => state.posEmployee,
  loadingStatus: (state) => state.loadingStatus,
  employeeConfirmationPopUp: (state) => state.employeeConfirmationPopUp,
  sort: (state) => state.sort,
  pagination: (state) => state.pagination,
  initialFilter: (state) => state.initialFilter,
};

const actions = {
  saveEmployee: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "saveEmployee", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.saveEmployee,
      payload.employee,
      function (response, error) {
        commit("setLoadingStatus", { api: "saveEmployee", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  updateEmployeePassword: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "updateEmployeePassword", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.updateEmployeePassword,
      payload.passwordInfo,
      function (response, error) {
        commit("setLoadingStatus", { api: "updateEmployeePassword", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  deleteEmployee: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "deleteEmployee", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.deleteEmployee,
      payload.employee,
      function (response, error) {
        commit("setLoadingStatus", { api: "deleteEmployee", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Deleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  undeleteEmployee: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "undeleteEmployee", status: true });
    HttpService.post(
      Apis.backendBaseUrl + Apis.undeleteEmployee,
      payload.employee,
      function (response, error) {
        commit("setLoadingStatus", { api: "undeleteEmployee", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Undeleted", type: "success" },
            { root: true }
          );
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getEmployees: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getEmployees", status: true });
    let url = Apis.backendBaseUrl + Apis.getEmployees + "?";
    if (state.selectedFilter?.criteria || payload) {
      url += `&filterCriteria=${payload.filterCriteria}`;
    }
    if (state.selectedFilter?.value || payload) {
      url += `&filterValue=${
        state.selectedFilter?.value || payload.filterValue
      }`;
    }
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getEmployees", status: false });
      var employees = {};

      if (response) {
        employees = response.data.body;

        commit("setEmployees", employees);
      }
    });
  },
  getEmployeeById: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getEmployeeById", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getEmployeeById + "?id=" + payload._id,
      function (response, error) {
        commit("setLoadingStatus", { api: "getEmployeeById", status: false });
        if (response) {
          if (payload.isPosEmployee) {
            commit("setPosEmployee", response.data.body);
          } else {
            state.employee = response.data.body;
          }
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
          console.log(error);
        }
      }
    );
  },
  getEmployeeByPin: ({ commit, state, dispatch }, payload) => {
    commit("setLoadingStatus", { api: "getEmployeeByPin", status: true });
    HttpService.get(
      Apis.backendBaseUrl + Apis.getEmployeeByPin + "?pin=" + payload.pin,
      function (response, error) {
        commit("setLoadingStatus", { api: "getEmployeeByPin", status: false });
        if (response) {
          const employee = response.data.body;
          employee.lastConfirmation = Date.now();
          localStorage.setItem("lastConfirmation", employee.lastConfirmation);
          commit("setPosEmployee", employee);
          commit("confirmEmployee", {
            employee,
          });
          if (payload && payload.positiveCallback) {
            payload.positiveCallback(employee);
          }
        } else {
          console.log(error);
          if (payload && payload.negativeCallback) {
            payload.negativeCallback(error);
          }
        }
      }
    );
  },
  setEmployeeConfirmationPopUp: ({ commit, state }, payload) => {
    commit("setEmployeeConfirmationPopUp", payload);
  },
  resetEmployee: ({ commit, state }, payload) => {
    commit("resetEmployee", payload);
  },
  // confirmEmployee: ({ commit, state }, payload) => {
  //   commit("confirmEmployee", payload);
  //   if (payload && payload.positiveCallback) {
  //     payload.positiveCallback();
  //   }
  //   commit(
  //     "setMessage",
  //     { text: `Logged as ${payload.employee.name}`, type: "success" },
  //     { root: true }
  //   );
  // },
  changeStore: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "changeStore", status: true });
    const body = {
      employeeId: LocalStorageManager.getLocalStorageKey("_id"),
      store: payload.store,
    };
    HttpService.post(
      Apis.backendBaseUrl + Apis.changeStore,
      body,
      function (response, error) {
        commit("setLoadingStatus", { api: "changeStore", status: false });
        if (response) {
          commit(
            "setMessage",
            { text: "Saved", type: "success" },
            { root: true }
          );
          commit("resetEmployee", payload);
          if (payload.positiveCallback) {
            payload.positiveCallback(response.data.body);
          }
        } else {
          console.log(error);
          commit(
            "setMessage",
            { text: error.message, type: "error" },
            { root: true }
          );
        }
      }
    );
  },
  getAdjacentEmployee: ({ commit, state }, payload) => {
    commit("setLoadingStatus", { api: "getAdjacentEmployee", status: true });
    const mode = payload.mode ? payload.mode : "next";
    var url =
      Apis.backendBaseUrl + "employee/" + Apis.getAdjacent + "?mode=" + mode;
    if (payload.createdAt) url = url + "&date=" + payload.createdAt;
    HttpService.get(url, function (response, error) {
      commit("setLoadingStatus", { api: "getAdjacentEmployee", status: false });
      if (response) {
        if (payload && payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      }
    });
  },
  clockInEmployee: ({ commit, state }, payload) => {
    const url = Apis.backendBaseUrl + "employee/clockIn";
    HttpService.post(url, payload.timeSheet, function (response, error) {
      if (response) {
        commit(
          "setMessage",
          { text: "Saved", type: "success" },
          { root: true }
        );
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        console.log(error);
        commit(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    });
  },
  clockOutEmployee: ({ commit, state }, payload) => {
    const url = Apis.backendBaseUrl + "employee/clockOut";
    HttpService.post(url, payload.timeSheet, function (response, error) {
      if (response) {
        commit(
          "setMessage",
          { text: "Saved", type: "success" },
          { root: true }
        );
        if (payload.positiveCallback) {
          payload.positiveCallback(response.data.body);
        }
      } else {
        console.log(error);
        commit(
          "setMessage",
          { text: error.message, type: "error" },
          { root: true }
        );
      }
    });
  },
  updateSort:({ commit, state }, payload) => {
    commit("updateSort", payload)
  },
  updatePagination:({ commit, state }, payload) => {
    commit("updatePagination", payload)
  },
  updateInitialFilter:({ commit, state }, payload) => {
    commit("updateInitialFilter", payload)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
