<template>
    <div class="stateInventory responsive-menu">
      <div class="responsive-menu__child">
        <PageHeader
          title="Group Listing"
          :links="[
            { name: 'home', label: 'Home' },
            { name: 'reports', label: 'Reports' },
            { name: 'inventoryReport', label: 'Inventory' },
            {
              name: 'inventoryListGroup',
              label: 'Group Listing',
            },
          ]"
          @export="exportToCsv"
          :disableExport="!reportData"
        />
        <br class="no-print" />
        <ReportFilter
          class="no-print"
          @submit-filter="performGetReport"
          :isLoading="inventoryTransactions"
        />
        <div class="report-container">
          <!-- This could be a component -->
          <div class="report-body" v-if="reportData">
            <table id="table-report">
              <thead>
                <tr class="report-body__row report-body__row--header"></tr>
                <tr>
                  <th align="left">Date</th>
                  <th align="left">Employee</th>
                  <th align="left">Name</th>
                  <th align="left">State Type</th>
                  <th align="left">Unit of Meas</th>
                  <th align="left">Weight Type</th>
                  <th align="left">Receipt Order</th>
                  <th align="left">Default Room</th>
                  <th align="left">Sales Account</th>
                  <th align="left">Inventory Account</th>
                  <th align="left">Expense Account</th>
                  <th align="left">Publish</th>
                  <th align="left">Cannabis</th>
                  <th align="left">Taxable</th>
                  <th align="left">Inventoried</th>
                  <th align="left">Resale</th>
                  <th align="left">Gift Card</th>
                  <th align="left">Service</th>
                </tr>
              </thead>

              <tbody>
                <tr class="report-body__row"></tr>
                <tr v-for="(record, index) in reportData.records" :key="index">
                    <td>{{ record.date }}</td>
                    <td>{{ record.employee }}</td>
                    <td>{{ record.name }}</td>
                    <td>{{ record.inventoryType }}</td>
                    <td>{{ record.unitOfMeasure }}</td>
                    <td>{{ record.onlineStoreWeight }}</td>
                    <td>{{ record.receiptOrder }}</td>
                    <td>{{ record.room }}</td>
                    <td>{{ record.salesAccountNo }}</td>
                    <td>{{ record.inventoryAccountNo }}</td>
                    <td>{{ record.expenseAccountNo }}</td>
                    <td align="center">{{ record.publicToOnlineStore ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.cannabis ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.taxable ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.inventoried ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.forResale ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.giftCard ? 'Y' : 'N' }}</td>
                    <td align="center">{{ record.service ? 'Y' : 'N' }}</td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import PageHeader from "@/components/Header/Header.vue";
  import ReportHelper from "@/lib/report/index.js";
  import { mapActions, mapGetters } from "vuex";
  import ReportFilter from "@/components/ReportFilter/ReportFilter";
  import Utilities from "@/lib/Utilities";

  export default {
    data() {
      return {
        reportData: undefined,
        Utilities,
        reportType: undefined,
        selectedPeriod: undefined,
        selectedReportFor: undefined,
        selectedReportSaleType: undefined,
        filterDropdown: undefined,
      };
    },
    components: {
      PageHeader,
      ReportFilter,
    },
    created: function () {
      this.setPageTitle("Group Listing");
    },
    mounted: function () {
      const today = new Date();
      this.selectedPeriod = {
        from: new Date(new Date().setDate(today.getDate() - 7)),
        to: today,
      };
    },
    computed: {
      ...mapGetters({
        loadingStatus: "reports/loadingStatus",
        inventoryTransactions: "reports/inventoryTransactions",
      }),
    },
    methods: {
      ...mapActions({
        getInventoryTransactions: "reports/getInventoryTransactions",
      }),
      performGetReport(filter) {
        let reportType = "inventoryListGroup";
        this.getInventoryTransactions({
          reportType,
          ...filter,
          positiveCallback: (data) => {
            this.reportData = data;
          },
        });
      },
      exportToCsv() {
        var table = document.getElementById("table-report");
        ReportHelper.exportToExcel(table, "inventoryListGroup");
      },
    },
  };
  </script>

  <style lang="scss">
  @import "./_inventoryListGroup.scss";