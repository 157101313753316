<template>
  <div class="fulfillment responsive-menu">
    <div class="responsive-menu__child">
      <PageHeader :pageHeader="listPageHeader" :hideAddButton="true" :links="[
  { name: 'home', label: 'Home' },
  { name: 'fulfillment', label: 'Fulfillment' },
]" :entity="entity" />
      <div class="fulfillment__body">

        <div class="fulfillment__orders">
          <FulfillmentOrder v-for="order in getUnfulfilled" :key="order._id" :order="order" @fulfill="fulfill"
            @toggleItem="toggleItem" @selectOrder="selectOrder" />
        </div>

        <div class="fulfillment__orders">
          <FulfillmentOrder v-for="order in getFulfilled" :key="order._id" :order="order" />
        </div>

      </div>
    </div>
    <BaseModal v-if="isOrderModalOpen" @toggle-modal="isOrderModalOpen = false" title="Fulfillment Order">
      <FulfillmentOrder :order="selectedOrder" @fulfill="fulfillSelectedOrder" @toggleItem="toggleItem"
        :showOpenButton="false" />
    </BaseModal>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Entities from "@/resources/Entities";
import { mapActions, mapGetters } from "vuex";
import SocketHelper from "@/lib/SocketHelper";
import { listPageHeader } from "./inputs";
import moment from "moment";
import FulfillmentOrder from "@/components/FulfillmentOrder/FulfillmentOrder.vue";
import BaseModal from "@/components/Modals/BaseModal/BaseModal.vue";

export default {
  name: "fulfillment",
  data() {
    return {
      Entities,
      entity: Entities.FULLFILLMENT,
      listPageHeader,
      socket: undefined,
      filter: {
        isPaid: true,
        isPostedToJournal: true,
        postedToJournalAt: {
          $gte: moment().format("YYYY-MM-DDT00:00:00.000Z"),
          $lte: moment().format("YYYY-MM-DDT23:59:00.000Z"),
        },
      },
      isOrderModalOpen: false,
      selectedOrder: undefined
    };
  },
  components: {
    PageHeader,
    FulfillmentOrder,
    BaseModal
  },
  mounted: function () {
    this.setPageTitle("Fulfillment");

    // Connect to socket
    this.socket = SocketHelper.generateSocket();

    this.socket.on("new_fulfillment_order", () => {
      this.getOrders({ filter: this.filter, sort: this.sort })
    });

    this.getOrders({ filter: this.filter, sort: this.sort })
  },
  computed: {
    ...mapGetters({
      sort: "fulfillmentModule/sort",
      orders: "fulfillmentModule/orders"
    }),
    getUnfulfilled() {
      return this.orders.filter(o => !o.isFilled)
    },
    getFulfilled() {
      return this.orders.filter(o => o.isFilled)
    }
  },
  methods: {
    ...mapActions({
      getOrders: "fulfillmentModule/getOrders",
      fulfill: "fulfillmentModule/fulfill",
      toggleItem: "fulfillmentModule/toggleItem",
    }),
    selectOrder(order) {
      this.isOrderModalOpen = true
      this.selectedOrder = order
    },
    fulfillSelectedOrder({order}) {
      this.fulfill({
        order, positiveCallback: () => {
          this.isOrderModalOpen = false
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.socket.disconnect();
    next(true);
  },
};
</script>

<style scoped lang="scss">
@import "./_fulfillment.scss";
</style>

