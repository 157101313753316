import { render, staticRenderFns } from "./OrderEditor.vue?vue&type=template&id=539ba2ec&scoped=true&"
import script from "./OrderEditor.vue?vue&type=script&lang=js&"
export * from "./OrderEditor.vue?vue&type=script&lang=js&"
import style0 from "./OrderEditor.vue?vue&type=style&index=0&id=539ba2ec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539ba2ec",
  null
  
)

export default component.exports