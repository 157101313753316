var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stateInventory responsive-menu"},[_c('div',{staticClass:"responsive-menu__child"},[_c('PageHeader',{attrs:{"title":"Buyer Sales for Product","links":[
      { name: 'home', label: 'Home' },
      { name: 'reports', label: 'Reports' },
      { name: 'inventoryReport', label: 'Inventory' },
      {
        name: 'inventoryBuyerProduct',
        label: 'Buyer Sales for Product',
      } ],"disableExport":!_vm.reportData},on:{"export":_vm.exportToCsv}}),_c('br',{staticClass:"no-print"}),_c('ReportFilter',{staticClass:"no-print",attrs:{"filterByItemType":true,"filterByTime":true,"isLoading":_vm.isLoading,"percentage":_vm.percentageCompleted},on:{"submit-filter":_vm.performGetReport}}),_c('div',{staticClass:"report-container"},[(_vm.reportData)?_c('div',{staticClass:"report-body"},[_c('table',{attrs:{"id":"table-report"}},[_vm._m(0),_c('tbody',[_c('tr',{staticClass:"report-body__row"}),_vm._l((Object.entries(_vm.reportData.list)),function(ref){
    var product = ref[0];
    var record = ref[1];
return _c('tr',{key:product},[_c('td',[_vm._v(_vm._s(record.product))]),_c('td',[_vm._v(_vm._s(record.grams))]),_c('td',[_vm._v(_vm._s(record.group))]),_c('td',[_vm._v(_vm._s(record.supplier))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.onHandQty))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.onHandActualCost)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(record.quantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(record.totalPreTax)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (record.onHandQty / record.quantity) * 100 ))+" % ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (record.onHandActualCost / record.totalPreTax) * 100 ))+" % ")])])})],2),_vm._m(1),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("TOTALS")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.totalItemQuantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.totalOnHandActualCost)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.reportData.total.quantity))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPrice(_vm.reportData.total.totalPreTax)))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (_vm.totalItemQuantity / _vm.reportData.total.quantity) * 100 ))+" % ")]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.Utilities.formatPercent( (_vm.totalOnHandActualCost / _vm.reportData.total.totalPreTax) * 100 ))+" % ")])]),_c('tr',{staticClass:"skip-export"}),_vm._m(2)])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"align":"left"}},[_vm._v("Product")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Grams")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Group")]),_c('th',{attrs:{"align":"left"}},[_vm._v("Supplier")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Units On Hand")]),_c('th',{attrs:{"align":"right"}},[_vm._v("On Hand Wholesale")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Sales (units)")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Sales")]),_c('th',{attrs:{"align":"right"}},[_vm._v("Units On Hand/Sales")]),_c('th',{attrs:{"align":"right"}},[_vm._v("$ On Hand/$ Sales")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"skip-export"},[_c('td',{attrs:{"colspan":"100%"}},[_c('hr',{staticClass:"line1"})])])}]

export { render, staticRenderFns }