import * as Entities from "@/resources/Entities";
import { validateEntityBeforeSaving as purchaseOrderValidateEntityBeforeSaving } from "./purchase-order";
import { validateEntityBeforeSaving as groupValidateEntityBeforeSaving } from "./group";
import { validateEntityBeforeSaving as storeValidateEntityBeforeSaving } from "./store";

const defaultNormalizeEntityBeforeSaving = (entity) => {
  return { succeeded: true };
};
export const validateEntityBeforeSaving = {
  [Entities.EMPLOYEE]: defaultNormalizeEntityBeforeSaving,
  [Entities.GROUP]: groupValidateEntityBeforeSaving,
  [Entities.CUSTOMER]: defaultNormalizeEntityBeforeSaving,
  [Entities.PRODUCT]: defaultNormalizeEntityBeforeSaving,
  [Entities.ITEM]: defaultNormalizeEntityBeforeSaving,
  [Entities.ONLINESTOREGROUP]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOGROUP]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOSUPPLIER]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOPRODUCT]: defaultNormalizeEntityBeforeSaving,
  [Entities.MSOCHARTOFACCOUNT]: defaultNormalizeEntityBeforeSaving,
  [Entities.CHARTOFACCOUNT]: defaultNormalizeEntityBeforeSaving,
  [Entities.ORDER]: defaultNormalizeEntityBeforeSaving,
  [Entities.ORGANIZATION]: defaultNormalizeEntityBeforeSaving,
  [Entities.PURCHASEORDER]: purchaseOrderValidateEntityBeforeSaving,
  [Entities.ROOM]: defaultNormalizeEntityBeforeSaving,
  [Entities.SPECIALPRICE]: defaultNormalizeEntityBeforeSaving,
  [Entities.STORE]: storeValidateEntityBeforeSaving,
  [Entities.SUPPLIER]: defaultNormalizeEntityBeforeSaving,
  [Entities.SUPPLIERAGENT]: defaultNormalizeEntityBeforeSaving,
  [Entities.COUPON]: defaultNormalizeEntityBeforeSaving,
  [Entities.CREDITMEMO]: defaultNormalizeEntityBeforeSaving,
};
